<template>
  <div>
    <v-dialog v-model="dialogTransactions" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <span class="page-heading"
            >Redeemable Points Transactions for {{ databucket.title }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-data-table
            :items="databucketRedeemableTransactions"
            :headers="headers"
            :search="search"
            :options.sync="options"
            :loading="loadingDatabucketRedeemableTransactions"
            no-data-text="No Records..."
            loading-text="Loading transactions..."
          >
            <template v-slot:[`item.created`]="{ item }">
              {{ item.created | timestamp | date }}
            </template>
            <template v-slot:[`item.points`]="{ item }">
              {{ item.points | number }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogTransactions: {
      type: Boolean,
    },
    databucket: {
      type: Object,
    },
  },
  created() {
    this.$store.dispatch("getRedeemableTransactionsByDatabucket");
  },
  data: () => {
    return {
      transactions: [],
      search: "",
      // Table columns
      headers: [
        { text: "Date", value: "created", align: "left" },
        { text: "Member", value: "member", align: "left" },
        { text: "Points", value: "points", align: "right" },
      ],

      // Table settings
      options: {
        sortBy: ["created"],
        sortDesc: [true],
        itemsPerPage: 10,
        sortable: false,
        multiSort: false,
        search: false,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
    };
  },
  computed: {
    loadingDatabucketRedeemableTransactions() {
      return this.$store.state.databucket
        .loadingDatabucketRedeemableTransactions;
    },
    databucketRedeemableTransactions() {
      return this.$store.state.databucket.databucketRedeemableTransactions;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("onCloseTransactionsDialog");
    },
  },
};
</script>

<template>
  <v-row justify="center" no-gutters class="price-subtitle pt-2 pb-6">By proceeding, you agree to our <a href="https://incentable.com/terms" target="_blank" class="mx-1 link">Terms Of Service</a> and <a href="https://incentable.com/privacy" target="_blank" class="mx-1 link">Privacy Policy</a></v-row>
</template>
<script>
export default {
  
}
</script>
<style>
.price-subtitle {
  font-family: 'Roboto', sans-serif !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  color: #4E4E4E !important;
}
</style>
/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
import _ from "lodash";
import numeral from "numeral";
import * as utils from "@/utils";

const state = {
  tables: [],
  tablesRaw: [],
  loadingTables: false,
  loadingCurrentTable: false,
  currentTable: null,
  currentTableRaw: {},
  pendingTableUpdates: {},
  pendingTableRawUpdates: {},
};

const actions = {
  async loadTables({ commit, getters }) {
    commit("setLoadingCards", true);
    commit("setTables", []);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("tables")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const tables = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      tables.push({
        id: doc.id,
        ...data,
        selectedDatabucket: data.selectedDatabucket || "",
        created: data.created.toDate(),
        updated: data.updated.toDate(),
      });
    });

    commit("setTables", tables);
    commit("setLoadingCards", false);
  },

  async loadTablesRaw({ commit, getters }) {
    commit("setTablesRaw", []);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("tablesRaw")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const tablesRaw = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      tablesRaw.push({
        id: doc.id,
        csvName: data.csvName,
        updated: data.updated,
      });
    });

    commit("setTablesRaw", tablesRaw);
  },

  async loadTablesByDatabucket({ getters, commit }, databucketId) {
    commit("setTables", []);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("tables")
        .where("selectedDatabucket", "==", databucketId)
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const tables = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      tables.push({
        ...data,
        id: doc.id,
        created: data.created && data.created.toDate(),
        updated: data.updated && data.updated.toDate(),
      });
    });

    commit("setTables", tables);
  },

  async loadCurrentTable({ commit, getters }, tableId) {
    commit("setLoadingCurrentTable", true);
    commit("setCurrentTable", null);
    commit("setCurrentTableRaw", {});
    commit("clearTablePendingUpdates");
    commit("clearTableRawPendingUpdates");

    const tableApiCall = db
      .collection("programs")
      .doc(getters.programId)
      .collection("tables")
      .doc(tableId)
      .get();

    let tableSnapshot;

    try {
      tableSnapshot = await tableApiCall;
    } catch (e) {
      throw "Error occured when fetching a table.";
    }

    const tableData = tableSnapshot.data();

    if (!tableData) {
      throw "No such Table";
    }
    const table = {
      ...tableData,
      id: tableSnapshot.id,
      participantType: tableData.participantType || "member",
      mode: tableData.mode || "dynamic",
      order: tableData.order || 0,
      cardHeaderMappings: tableData.cardHeaderMappings || [],
      runningTotalMappings: tableData.runningTotalMappings || {},
      homeCardMappings: tableData.homeCardMappings || {},
      resultsHeaderMappings: tableData.resultsHeaderMappings || [],
      remarksMappings: tableData.remarksMappings || [],
      created: tableData.created.toDate(),
      updated: tableData.updated.toDate(),
      selectedDatabucket: tableData.selectedDatabucket || "",
    };
    this.commit("setBreadCrumbDetail", table.title);
    const tableRawApiCall =
      table.mode === "static"
        ? db
            .collection("programs")
            .doc(getters.programId)
            .collection("tablesRaw")
            .doc(tableId)
            .get()
        : db
            .collection("programs")
            .doc(getters.programId)
            .collection("databuckets")
            .doc(table.selectedDatabucket || "dummyId")
            .collection("results")
            .doc("column")
            .get();

    let tableRawSnapshot;
    try {
      tableRawSnapshot = await tableRawApiCall;
    } catch (e) {
      throw "Error occured when fetching a table raw.";
    }

    const tableRawData = tableRawSnapshot.data();

    const tableRaw = {};

    if (tableRawData) {
      tableRaw.updated = tableRawData.updated.toDate();
      tableRaw.csvData = JSON.parse(tableRawData.csvData);
      tableRaw.csvName = tableRawData.csvName;

      if (tableRawData.runningTotalMappings) {
        table.runningTotalMappings = tableRawData.runningTotalMappings;
      }
    }

    commit("setCurrentTable", table);
    commit("setCurrentTableRaw", tableRaw);
    commit("setLoadingCurrentTable", false);
  },

  fakeLoadCurrentTable({ commit }, payload) {
    const { tableData, tableRawData } = payload;

    const table = {
      ...tableData,
      id: tableData.id,
      participantType: tableData.participantType || "member",
      mode: tableData.mode || "dynamic",
      order: tableData.order || 0,
      cardHeaderMappings: tableData.cardHeaderMappings || [],
      runningTotalMappings: tableData.runningTotalMappings || {},
      homeCardMappings: tableData.homeCardMappings || {},
      resultsHeaderMappings: tableData.resultsHeaderMappings || [],
      remarksMappings: tableData.remarksMappings || [],
      selectedDatabucket: tableData.selectedDatabucket || "",
    };

    const tableRaw = {};
    if (tableRawData) {
      tableRaw.csvData = JSON.parse(tableRawData.csvData);
      tableRaw.csvName = tableRawData.csvName;

      if (tableRawData.runningTotalMappings) {
        table.runningTotalMappings = tableRawData.runningTotalMappings;
      }
    }

    commit("setCurrentTable", table);
    commit("setCurrentTableRaw", tableRaw);
  },

  async selectDatabucket({ getters, commit }, databucketId) {
    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(databucketId);

    let data;
    try {
      const totalResultRef = await databucketRef
        .collection("results")
        .doc("column")
        .get();
      data = totalResultRef.data();
    } catch (e) {
      throw "Error when fetching a databucket result";
    }

    commit("patchCurrentTable", {
      selectedDatabucket: databucketId,
      runningTotalMappings: data.runningTotalMappings || {},
    });

    commit("patchCurrentTableRaw", {
      csvData: JSON.parse(data.csvData),
      csvName: data.csvName,
      updated: data.updated.toDate(),
    });
  },

  async createTable({ commit, getters, dispatch }, payload) {
    const tablesCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("tables");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await tablesCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the title.";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Title is already registered.";
    }

    const table = {
      ...payload,
      homeCard: {
        suffix: "of Target",
      },
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
      created: timestamp,
      updated: timestamp,
    };

    let newTableRef;
    try {
      newTableRef = await tablesCollection.add(table);
    } catch (e) {
      throw "Error occured when creating a new table";
    }

    // Note: server time is unavailable until we refetch.
    const tmpTable = {
      ...payload,
      id: newTableRef.id,
      createdBy: getters.user.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createTable", tmpTable);
    dispatch("setSnackbar", "Table Created.");
  },

  async updateTable({ getters, dispatch }) {
    const { hasTablePendingUpdates, hasTableRawPendingUpdates } = getters;

    if (hasTableRawPendingUpdates) {
      try {
        await Promise.all([dispatch("syncTable"), dispatch("syncTableRaw")]);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    if (hasTablePendingUpdates && !hasTableRawPendingUpdates) {
      try {
        await dispatch("syncTable");
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    dispatch("setSnackbar", "Table Saved.");
  },

  async syncTable({ commit, getters, state }) {
    const {
      resultsHeaderMappings,
      homeCardMappings,
      cardHeaderMappings,
      remarksMappings,
      runningTotalMappings,
    } = state.currentTable;

    const {
      tableAccountsMap,
      tableHeaderData,
      tableHomeData,
      tableColumnData,
      tableRemarksData,
    } = getters;

    const cardHeader = cardHeaderMappings.map((item) => item.label);
    const homeCard = {
      prefix: homeCardMappings.prefix || "",
      suffix: homeCardMappings.suffix || "",
    };
    const resultsHeader = resultsHeaderMappings.map((item) => item.label);

    const tableData = tableAccountsMap.map(
      ({ id: accountId }, accountIndex) => {
        const homeCardData = tableHomeData[accountIndex].value;
        const headerData = tableHeaderData[accountIndex].data;
        const remarksData = tableRemarksData[accountIndex].data;
        const columnData = tableColumnData[accountIndex].data;

        const remarksDataRes = remarksMappings.reduce((result, header) => {
          return [...result, remarksData[header.column]];
        }, []);

        const columnDataRes = columnData.map((rowData) => {
          const rowDataAry = resultsHeaderMappings.reduce((result, header) => {
            return [...result, rowData[header.column]];
          }, []);

          return {
            row: rowDataAry,
          };
        });

        const headerDataRes = cardHeaderMappings.reduce((result, header) => {
          return [...result, headerData[header.column]];
        }, []);

        return {
          companyId: accountId,
          homeCardValue: homeCardData || "",
          remarks: remarksDataRes,
          totals: headerDataRes,
          rows: columnDataRes,
        };
      }
    );

    const payload = {
      ...state.pendingTableUpdates,
      resultsHeaderMappings,
      resultsHeader,
      remarksMappings,
      homeCardMappings,
      homeCard,
      cardHeaderMappings,
      cardHeader,
      tableData,
      runningTotalMappings,
    };

    const clientNow = new Date();

    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("tables")
        .doc(getters.currentTable.id)
        .update({
          ...payload,
          updated: timestamp,
        });
    } catch (e) {
      console.log(e);
      throw "Error when syncing table.";
    }

    // console.error('local value updating...');
    commit("patchCurrentTable", {
      updated: clientNow,
    });
    commit("clearTablePendingUpdates");
    // console.error('all done');
  },

  async syncTableRaw({ commit, getters, state }) {
    const { pendingTableRawUpdates } = state;

    const clientNow = new Date();

    // Note: When fetching data from databucket, there is no need
    // to update the table raw.
    if (getters.currentTable.mode === "dynamic") {
      commit("clearTableRawPendingUpdates");
      return;
    }

    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("tablesRaw")
        .doc(getters.currentTable.id)
        .set(
          {
            ...pendingTableRawUpdates,
            csvData: JSON.stringify(pendingTableRawUpdates.csvData),
            updated: timestamp,
          },
          { merge: true }
        );
    } catch (e) {
      console.error(e);
      throw "Error when syncing table raw";
    }

    commit("patchCurrentTableRaw", {
      updated: clientNow,
    });
    commit("clearTableRawPendingUpdates");
  },

  async deleteTable({ commit, getters, dispatch }) {
    const currentTableId = getters.currentTable.id;

    try {
      Promise.all([
        await db
          .collection("programs")
          .doc(getters.programId)
          .collection("tables")
          .doc(currentTableId)
          .delete(),

        await db
          .collection("programs")
          .doc(getters.programId)
          .collection("tablesRaw")
          .doc(currentTableId)
          .delete(),
      ]);
    } catch (e) {
      throw "error when deleting a table";
    }
    dispatch("setSnackbar", "Table deleted");
    commit("deleteTable", currentTableId);
  },

  // Note: This is only updating the store, not persisting with db
  patchCurrentTable({ commit }, payload) {
    commit("patchCurrentTable", payload);
  },

  // Note: This is only updating the store, not persisting with db
  patchCurrentTableRaw({ commit }, payload) {
    commit("patchCurrentTableRaw", payload);
  },
};

const mutations = {
  setTables(state, payload) {
    state.tables = payload;
  },

  setTablesRaw(state, payload) {
    state.tablesRaw = payload;
  },

  createTable(state, payload) {
    state.tables = [...state.tables, payload];
  },

  deleteTable(state, payload) {
    state.tables = state.tables.filter((item) => item.id === payload);
  },

  setLoadingCurrentTable(state, payload) {
    state.loadingCurrentTable = payload;
  },

  setCurrentTable(state, payload) {
    state.currentTable = payload;
  },

  setCurrentTableRaw(state, payload) {
    state.currentTableRaw = payload;
  },

  patchCurrentTable(state, payload) {
    state.currentTable = {
      ...state.currentTable,
      ...payload,
    };

    state.pendingTableUpdates = {
      ...state.pendingTableUpdates,
      ...payload,
    };
  },

  patchCurrentTableRaw(state, payload) {
    state.currentTableRaw = {
      ...state.currentTableRaw,
      ...payload,
    };

    state.pendingTableRawUpdates = {
      ...state.pendingTableRawUpdates,
      ...payload,
    };
  },

  clearTablePendingUpdates(state) {
    state.pendingTableUpdates = {};
  },

  clearTableRawPendingUpdates(state) {
    state.pendingTableRawUpdates = {};
  },
};

const getters = {
  tables(state) {
    return state.tables;
  },

  tablesRaw(state) {
    return state.tablesRaw;
  },

  loadingCurrentTable(state) {
    return state.loadingCurrentTable;
  },

  currentTable(state) {
    return state.currentTable;
  },

  currentTableRaw(state) {
    return state.currentTableRaw;
  },

  tableEntityMap(state, getters) {
    return getters.currentTable.participantType === "member"
      ? getters.memberAccountKeysMap
      : getters.companyAccountKeysMap;
  },

  tableCsvHeaders(state) {
    const { csvData } = state.currentTableRaw;
    if (!csvData || !csvData.length) {
      return [];
    }

    return (csvData[0] || []).map((header) => {
      // Note: We havee reserved word 'Percentage'
      // If we find the same one in the csv, append trailing space so that
      // It doesn't conflict with reserved functionality
      if (header === "Percentage") {
        return "Percentage ";
      }
      return header;
    });
  },

  tableCsvBody(state, getters) {
    const { csvData } = state.currentTableRaw;
    if (!csvData || !csvData.length) {
      return [];
    }

    return csvData.slice(1).map((row) => {
      return getters.tableCsvHeaders.reduce(
        (rowResult, header, headerIndex) => {
          return {
            ...rowResult,
            [header]: row[headerIndex],
          };
        },
        {}
      );
    });
  },

  tableSystemHeaders(state) {
    const calculated = ["Percentage"];

    return state.currentTable.participantType === "member"
      ? ["Full Name", ...calculated]
      : ["Company Title", ...calculated];
  },

  tableMatchingData(state, getters) {
    const { accountKeyColumn } = state.currentTable;
    const { tableCsvBody, tableEntityMap } = getters;

    if (!tableCsvBody.length) {
      return [];
    }

    return tableCsvBody.filter((item) => {
      const accountKeyValue = item[accountKeyColumn];
      return !!(accountKeyValue && tableEntityMap[accountKeyValue]);
    });
  },

  tableAccountsMap(state, getters) {
    const { accountKeyColumn } = state.currentTable;
    const { tableMatchingData, tableEntityMap } = getters;

    if (!tableMatchingData.length) {
      return [];
    }

    const allAccountKeyValues = tableMatchingData.map(
      (item) => item[accountKeyColumn]
    );
    const uniqAccountKeyValues = _.uniq(allAccountKeyValues);

    return uniqAccountKeyValues
      .map((item) => {
        const label = tableEntityMap[item].title;

        return {
          label,
          value: item,
          ...tableEntityMap[item],
        };
      })
      .sort((a, b) => {
        if (a.titleUppercase < b.titleUppercase) {
          return -1;
        }
        if (a.titleUppercase > b.titleUppercase) {
          return 1;
        }
        return 0;
      });
  },

  tableHeaderData(state, getters) {
    const {
      accountKeyColumn,
      cardHeaderMappings,
      runningTotalMappings: {
        column: runningTotalColumn,
        row: runningTotalRow,
      },
    } = state.currentTable;

    const { tableCsvBody, tableAccountsMap } = getters;

    const specialColumns = ["Company Title", "Full Name", "Percentage"];

    return tableAccountsMap.map(
      ({ value: accountKey, label: accountLabel }) => {
        const rawData = tableCsvBody.filter(
          (row) => row[accountKeyColumn] === accountKey
        );

        // Filter the data by running total
        let filteredData = rawData;
        if (runningTotalColumn && runningTotalRow) {
          ({ filtered: filteredData } = rawData.reduce(
            (result, item) => {
              if (result.foundTarget) {
                return result;
              }
              const val = item[runningTotalColumn];
              if (val === runningTotalRow) {
                return {
                  foundTarget: true,
                  filtered: [...result.filtered, item],
                };
              }
              return {
                foundTarget: false,
                filtered: [...result.filtered, item],
              };
            },
            {
              filtered: [],
              foundTarget: false,
            }
          ));
        }

        // Get the aggregated values
        // At this stage, percent value will just be blank

        let percentMappingIndex = -1;
        const data = {};
        cardHeaderMappings.forEach((mapping, mappingIndex) => {
          const { type, column, format, rounding } = mapping;
          let value;
          if (specialColumns.includes(column)) {
            if (column === "Percentage") {
              value = "";
              percentMappingIndex = mappingIndex;
            } else {
              value = accountLabel;
            }
          } else if (type !== "number") {
            value = filteredData[0][column];
          } else {
            const sum = filteredData.reduce((result, item) => {
              // Note: Remove all special characters except numbers, '.' and '-'
              const itemVal = utils.convertToNumber(item[column]);
              return result + itemVal;
            }, 0);

            const rounded = numeral(sum).format(rounding);
            const isAppend = format !== "%";
            value = isAppend ? `${format}${rounded}` : `${rounded}${format}`;
          }

          data[column] = value;
        });

        // There is a "Percentage Column", we should calculate percent value
        if (percentMappingIndex !== -1) {
          const percentMapping = cardHeaderMappings[percentMappingIndex];
          const { column, leftOperator, rightOperator } = percentMapping;
          const { leftSum, rightSum } = filteredData.reduce(
            (result, item) => {
              return {
                leftSum:
                  result.leftSum + utils.convertToNumber(item[leftOperator]),
                rightSum:
                  result.rightSum + utils.convertToNumber(item[rightOperator]),
              };
            },
            {
              leftSum: 0,
              rightSum: 0,
            }
          );

          const percentValue = (leftSum / rightSum) * 100;
          const value = `${numeral(percentValue).format("0.0")}%`;
          data[column] = value;
        }

        return {
          accountKey,
          data,
        };
      }
    );
  },

  tableHomeData(state, getters) {
    const {
      accountKeyColumn,
      homeCardMappings,
      runningTotalMappings: {
        column: runningTotalColumn,
        row: runningTotalRow,
      },
    } = state.currentTable;

    const { tableCsvBody, tableAccountsMap } = getters;

    const specialColumns = ["Company Title", "Full Name", "Percentage"];

    return tableAccountsMap.map(
      ({ value: accountKey, label: accountLabel }) => {
        const rawData = tableCsvBody.filter(
          (row) => row[accountKeyColumn] === accountKey
        );

        // Filter the data by running total
        let filteredData = rawData;
        if (runningTotalColumn && runningTotalRow) {
          ({ filtered: filteredData } = rawData.reduce(
            (result, item) => {
              if (result.foundTarget) {
                return result;
              }
              const val = item[runningTotalColumn];
              if (val === runningTotalRow) {
                return {
                  foundTarget: true,
                  filtered: [...result.filtered, item],
                };
              }
              return {
                foundTarget: false,
                filtered: [...result.filtered, item],
              };
            },
            {
              filtered: [],
              foundTarget: false,
            }
          ));
        }

        // Get the aggregated values
        // At this stage, percent value will just be blank
        const { type, column, format, rounding } = homeCardMappings;

        let value;
        if (specialColumns.includes(column)) {
          if (column === "Percentage") {
            value = "";
          } else {
            value = accountLabel;
          }
        } else if (type !== "number") {
          value = filteredData[0][column];
        } else {
          const sum = filteredData.reduce((result, item) => {
            // Note: Remove all special characters except numbers, '.' and '-'
            const itemVal = utils.convertToNumber(item[column]);
            return result + itemVal;
          }, 0);

          const rounded = numeral(sum).format(rounding);
          const isAppend = format !== "%";
          value = isAppend ? `${format}${rounded}` : `${rounded}${format}`;
        }

        // There is a "Percentage Column", we should calculate percent value
        if (column === "Percentage") {
          const { leftOperator, rightOperator } = homeCardMappings;

          const { leftSum, rightSum } = filteredData.reduce(
            (result, item) => {
              return {
                leftSum:
                  result.leftSum + utils.convertToNumber(item[leftOperator]),
                rightSum:
                  result.rightSum + utils.convertToNumber(item[rightOperator]),
              };
            },
            {
              leftSum: 0,
              rightSum: 0,
            }
          );

          const percentValue = (leftSum / rightSum) * 100;
          value = `${numeral(percentValue).format("0.0")}%`;
        }

        return {
          accountKey,
          value,
        };
      }
    );
  },

  tableColumnData(state, getters) {
    const { accountKeyColumn, resultsHeaderMappings } = state.currentTable;

    const { tableCsvBody, tableAccountsMap } = getters;

    const specialColumns = ["Company Title", "Full Name"];

    return tableAccountsMap.map(
      ({ value: accountKey, label: accountLabel }) => {
        const rawData = tableCsvBody.filter(
          (row) => row[accountKeyColumn] === accountKey
        );

        const data = [];
        rawData.forEach((row, rowIndex) => {
          const rowData = {};
          resultsHeaderMappings.forEach((mapping) => {
            let value;
            const { type, column, format, rounding } = mapping;
            if (specialColumns.includes(column)) {
              value = accountLabel;
            } else if (type !== "number") {
              value = rawData[rowIndex][column];
            } else {
              const number = utils.convertToNumber(rawData[rowIndex][column]);
              const rounded = numeral(number).format(rounding);
              const isAppend = format !== "%";
              value = isAppend ? `${format}${rounded}` : `${rounded}${format}`;
            }
            rowData[column] = value;
          });

          data.push(rowData);
        });

        return {
          accountKey,
          data,
        };
      }
    );
  },

  tableRemarksData(state, getters) {
    const { accountKeyColumn, remarksMappings } = state.currentTable;

    const { tableCsvBody, tableAccountsMap } = getters;

    return tableAccountsMap.map(({ value: accountKey }) => {
      const rawData = tableCsvBody.filter(
        (row) => row[accountKeyColumn] === accountKey
      );
      const data = {};

      remarksMappings.forEach((mapping) => {
        const value = rawData[rawData.length - 1][mapping.column];
        data[mapping.column] = value;
      });

      return {
        accountKey,
        data,
      };
    });
  },

  hasTablePendingUpdates(state) {
    return Object.keys(state.pendingTableUpdates).length !== 0;
  },

  hasTableRawPendingUpdates(state) {
    return Object.keys(state.pendingTableRawUpdates).length !== 0;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

/* eslint-disable no-console */
<template>
  <div>
    <v-row
      no-gutters
      align="center"
      >
      <v-col sm="2">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field> 
      </v-col>
      
      <v-col
        sm="3"
        v-if="options.filter"
        >
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter"
          prepend-inner-icon="filter_list"
          class="mr-4"
        ></v-select> 
      </v-col>
      
      <v-col>
        <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
        <v-btn v-if="options.download" class="primary--text" text>Download</v-btn> 
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
      </v-col>
      
      <v-col sm="auto">
        <v-btn
          @click="handleNew()"
          color="primary"
          elevation="0"
          class="mt-n2"
          >
          <v-icon>add</v-icon>new
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="messages"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
      class="px-0 pt-6"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          left
          close-on-content-click
        >
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title @click="handleEdit(item)">
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                >
                <v-list-item-title 
                  @click="handleDeleteConfirmation(item)"
                  >Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.read`]="{ item }">
        <v-icon v-if="item.read === false" color="primary">email</v-icon>
        <v-icon v-else>drafts</v-icon>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <span @click="handleEdit(item)" style="cursor:pointer" :class="item.read ? 'read' : 'unread'">{{ item.name }}</span>
      </template>
      
      <template v-slot:[`item.from`]="{ item }">
        <span @click="handleEdit(item)" style="cursor:pointer" :class="item.read ? 'read' : 'unread'">{{ item.from }}</span>
      </template>
      
      <template v-slot:[`item.created`]="{ item }">
        <span @click="handleEdit(item)" style="cursor:pointer" :class="item.read ? 'read' : 'unread'">{{ item.created | date }}</span>
      </template>
      
      <template v-slot:[`item.subject`]="{ item }">
      <div 
        @click="handleEdit(item)"
        style="cursor:pointer"
        :class="item.read ? 'read' : 'unread'"
      >
        {{ item.subject }}
      </div>
      </template>
      
    </v-data-table>

    <message-new-or-edit
      :messageId="selectedMessageId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />
    
  <!-- Delete dialog -->

    <v-dialog 
      v-model="dialogDeleteOpen"
      max-width="400px"
      persistent
      >
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row dense>
            <v-col align="center">
              You are about to delete a message from <span class="incentable-form-bold">{{ from }}</span>
            </v-col>
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleCloseDeleteDialog()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
import MessageNewOrEdit from './MessageNewOrEdit.vue'
export default {
components: {
    MessageNewOrEdit
},
$_veeValidate: {
    validator: 'new'
},
data: () => ({
    
    // Component settings unique to this component
    tableTitle: 'Messages',
    selectedMessageId: '',
    search: '',
    from: '',

    // Table columns
    headers: [
    { text: 'Read', value: 'read', align: 'left' },
    { text: 'Name', value: 'name', align: 'left' },
    { text: 'From', value: 'from', align: 'left' },
    { text: 'Subject', value: 'subject', align: 'left' },
    { text: 'Date', value: 'created', align: 'left' },
    { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],

    // Component settings common to all components
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,
    
    // Table settings
    options: {
    sortBy: ['created'],
    sortDesc: [ true ],
    itemsPerPage: 20,
    sortable: true,
    multiSort: true,
    search: true,
    filter: false,
    upload: false,
    download: false,
    more: false,
    },
}),

created () {
},

computed: {
    // Functions specific to this table //
    
    messages () {
    return this.$store.state.message.messages
    },

    // Functions common to all tables //
    loadingTable () {
    return this.$store.getters.loadingTable
    },
    loading () {
    return this.$store.getters.loading
    },
    orgTheme () {
    return this.$store.getters.orgTheme
    },
    systemTheme () {
    return this.$store.getters.systemTheme
    },
    snackbar () {
    return this.$store.getters.snackbar
    },
},
methods: {
    handleNew() {
    // Open New form
    this.selectedMessageId = '';
    this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
    // Open Edit form
    this.selectedMessageId = item.id
    this.dialogNewOrEditOpen = true
    this.messages[this.messages.indexOf(item)].read = true
    this.$store.dispatch('markRead', item)
    },
    handleCloseNewOrEditDialog() {
    // Close New Or Edit form
    this.selectedMessageId = '';
    this.dialogNewOrEditOpen = false;
    },
    handleDeleteConfirmation (item) {
    // Delete record
    this.selectedMessageId = item.id;
    this.from = item.from;
    this.dialogDeleteOpen = true;
    },
    handleCloseDeleteDialog() {
    // Close delete form
    this.selectedMessageId = '';
    this.dialogDeleteOpen = false;
    },
    handleDelete() {
    // Delete Message
    this.$store.dispatch('setLoading', true)
    this.$store.dispatch('deleteMessage', this.selectedMessageId)
        .then(() => this.dialogDeleteOpen = false)
        .catch(() => this.dialogDeleteOpen = false)
        this.$store.dispatch('setLoading', false)
        this.$store.dispatch('loadNewMessageCount')
    },
}
}
</script>

<style>
.read {
  font-weight: 400;
}
.unread {
  font-weight: 700;
}
</style>

<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-16">
              
        <v-row dense>
          <v-col>
            {{ selectedPointsRecord }}
          </v-col>
        </v-row>

        <v-row dense>
          <v-col v-if="errorMessage">
              <v-alert
                type="error"
                :value="true"
              >
                {{errorMessage}}
              </v-alert>
            </v-col>         
        </v-row>
      
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="handleClose()"
          >Cancel</v-btn>
        <v-btn
          :class="orgTheme.buttonTextColor"
          color="primary"
          @click="handleConfirmDelete(selectedPointsRecord)"
          :loading="loading"
          elevation="0"
          :disabled="demo"
          >Confirm delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  created () {
  },
  mounted () {
  },
  props: ['open', 'selectedPointsRecord'],
  data: () => ({
    errorMessage: '',
    formTitle: 'Are you sure?',
    formIcon: 'help',
    pendingChange: false
  }),

  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    error () {
      return this.$store.getters.error
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    }
  },

  methods: {
    handleClose () {
      this.clear()
      this.$emit('onCloseDelete')
    },
    clear () {
      this.$store.dispatch('setLoading', false)
    },
    handleConfirmDelete (editedItemUpdate) {
      this.$emit('onConfirmDelete', editedItemUpdate)
    }
  },
}
</script>

<style>
</style>

<template>          
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa"
  >
  <v-row no-gutters justify="center">
    <v-col xs="12" :sm="component.settings.cols">
      <v-sheet
        tile
        :color="this.webTheme.colors.footer.hexa"
        :style="block"
      >
        <v-row 
          no-gutters 
          :justify="component.group.justify"
        >
          <v-col sm="12" :lg="component.block.cols">
            <v-card
              tile
              elevation="0"
              :class="grid.inner"
            >
              <v-row no-gutters>
                <v-col>
                  <view-text 
                    v-for="menu in primaryMenus"
                    :key="menu.id"
                    :settings="component.menu"
                    :value="menu.title" 
                  />
                </v-col>
                <v-col>
                  <view-text 
                    v-for="menu in secondaryMenus"
                    :key="menu.id"
                    :settings="component.menu"
                    :value="menu.title" 
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import ViewText from '../Shared/Text.vue'

 export default {
  components: {
    ViewText
  },
  props: [ 'component' ],
   created () {
    this.$store.dispatch('loadSamplePrimaryMenus')
    this.$store.dispatch('loadSampleSecondaryMenus')
  },
   data: function () {
    return {
    }
   },
   computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    grid() {
      return this.$store.getters.grid
    },
    primaryMenus() {
      return this.$store.getters.samplePrimaryMenus
    },
    secondaryMenus() {
      return this.$store.getters.sampleSecondaryMenus
    },
    block() {
      var padding = this.component.block.padding
      return this.grid.block + 'px ;' +
      'padding-left: ' + padding.left + 'px ;' +
      'padding-right: ' + padding.right + 'px ;' +
      'padding-top: ' + padding.top + 'px ;' +
      'padding-bottom: ' + padding.bottom + 'px ;'
    },
  },
  methods: {
  }
}
</script>

<style scoped>
.section-heading {
  margin-left: 50px;
  line-height: 1.5;
  font-size: 16px;
}
.preview-card {
  border: 1px dotted grey !important;
  background-color: transparent !important;
}
</style>


<template>
  <div>
    <v-card elevation="0">
      <v-row
        no-gutters
        align="center"
        >
        <v-col lg="3">
          <v-text-field
            v-model="search"
            solo-inverted
            dense
            flat
            hide-details
            label="Search"
            prepend-inner-icon="search"
            class="mr-4"
          ></v-text-field> 
        </v-col>
        
        <v-col
          lg="3"
          v-if="options.filter"
          >
          <v-select
            solo-inverted
            dense
            flat
            hide-details
            multiple
            label="Status"
            prepend-inner-icon="filter_list"
            class="mr-4"
            :items="statuses"
            v-model="filteredStatus"
          ></v-select> 
        </v-col>
        
        <v-col
          lg="3"
          v-if="options.filter"
          >
          <v-select
            solo-inverted
            dense
            flat
            hide-details
            multiple
            label="Pod"
            prepend-inner-icon="filter_list"
            class="mr-4"
            :items="pods"
            item-text="text"
            item-value="value"
            v-model="filteredPods"
          ></v-select> 
        </v-col>
        
        <v-col>
          <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
          <v-btn v-if="options.download" class="primary--text" text>Download</v-btn> 
          <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
        </v-col>
        
        <v-col sm="auto">
          <v-btn
            @click="handleNew()"
            color="primary"
            elevation="0"
            class="mt-n2"
            >
            <v-icon>add</v-icon>new
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        class="px-0 pt-4"
        :headers="headers"
        :items="filteredSites"
        :search="search"
        :options.sync="options"
        :loading="loadingTable"
        no-data-text="No Records..."
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
            close-on-content-click
          >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link>
                  <v-list-item-title @click="handleEdit(item)">
                    Edit
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title @click="handleDeleteConfirmation(item)">
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
        </template>
        
        <template v-slot:[`item.status`]="{ item }">
          <status-icon :status="item.status" class="pr-3"></status-icon>
          {{ item.status }}
        </template>
        
        <template v-slot:[`item.activeDomain`]="{ item }">
          <span 
            v-if="item.status === 'Live'">
            <a 
              :href="'https://' + item.activeDomain"
              target="_blank"
            >
            {{ item.activeDomain }}</a>
          </span>
          <span v-else>
            {{ item.activeDomain }}
          </span>
        </template>
        
        <template v-slot:[`item.created`]="{ item }">
          {{ item.created | date }}
        </template>

        <template v-slot:[`item.program`]="{ item }">
          <span v-if="getProgramName(item.program)">{{ getProgramName(item.program) }}</span>
          <span v-else>{{ item.program }}</span>  
        </template>
        
      </v-data-table>

    </v-card>

    <site-new-or-edit
      :open="dialogNewOrEditOpen"
      :siteId="selectedSiteId"
      :nanoid="subdomain"
      @onClose="handleCloseNewOrEditDialog"
    />

    <v-dialog 
      v-model="dialogDeleteOpen"
      max-width="400px"
      persistent
      >
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row dense>
            <v-col align="center">
              You are about to delete <span class="incentable-form-bold">{{ activeDomain }}</span>
            </v-col>
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleCloseDeleteDialog()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row no-gutters>
      <v-col lg="9" class="ml-3">
        <v-row class="page-heading mt-6">   
          Hosting Pods
        </v-row>
        <v-row class="incentable-page-subheading pb-1">Instances of Firebase projects hosting the member websites</v-row>
        <v-row class="incentable-page-subheading pb-4 primary--text"><v-icon small color="primary">warning</v-icon> There is a only 20 active domains allowed per pod due to Firebase hosting SSL limits.</v-row>
      </v-col>
    </v-row>
    <v-row class="mb-6 ml-0">
      <v-card
        v-for="pod in pods"
        :key="pod.value"
        class="el pa-2 mr-6 secondary"
        width="150px"
        height="150px"
      >
      <v-row>
        <v-col align="center" class="metric mt-0 mb-n1 primary--text">
          {{ domainCount(pod.value) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" class="incentable-form-heading mt-n6 mb-n1 primary--text">
          Domains
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" class="incentable-form-heading white--text mt-n2">
          {{ pod.text }}
        </v-col>
      </v-row>
      </v-card>
    </v-row>
    <v-row no-gutters>
      <v-col lg="12" class="ml-3">
        <v-row class="incentable-form-heading mt-3">   
          How to add Hosting Pods
        </v-row>
        <v-row class="incentable-page-subheading pb-4">When all Pods are full, new Pods can be added.</v-row>
        <v-row>1. Open the pods.js constants file in the Constants folder</v-row>
        <v-row>2. Add a new pod record to the file</v-row>
        <v-row>3. Add a new project in the
          <a href="https://console.firebase.google.com/" target="_blank" class="ml-1">
            Firebase Console
          </a>
        </v-row>
        <v-row>4. In the new project, got to the Auhtentication menu and update the >Sign-in Method>Anonymous to be [Enabled]</v-row>
        <v-row>5. In VSCode, create a new project by cloning the
          <a href="https://github.com/jaysonfrazer/incentable-member-website.git" target="_blank" class="mx-1">
            Incentable-Member-Website
          </a> 
          repo with the same name as the new pod</v-row>
        <v-row>6. In the new project files, edit the '.firebaserc' and 'firebase.json' files to point to the new Firebase Project/Pod Name (EG. incentable-pod-z) </v-row>
        <v-row>7. Execute 'npm install' to compile the build</v-row>
        <v-row>8. Execute 'npm run build' to prepare for production</v-row>
        <v-row>9. Execute 'firebase deploy' to push the site to the new hosting pod</v-row>
        <v-row>10. New Domains can now be added to the new Pod</v-row>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import { customAlphabet } from 'nanoid'
import { PODS } from '@/constants/pod'
export default {
  data: () => ({
    // Component settings
    tableTitle: 'Sites',
    tableSubtitle: 'Publish news or annoucements by creating sites',
    tableIcon: 'insert_drive_file',
    search: '',
    selectedSiteId: '',
    activeDomain: '',
    subdomain: '',
    pods: PODS,
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,
    filteredStatus: [],
    filteredPods: [],
    site: {},
    statuses: [
      { text: 'Draft', value: 'Draft' },
      { text: 'Available', value: 'Available' },
      { text: 'Processing', value: 'Processing' },
      { text: 'Live', value: 'Live' },
      { text: 'Deactivated', value: 'Deactivated' }
    ],
    
    // Table settings
    options: {
      sortBy: ['status'],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: false,
      download: false,
      more: false,
    },
    
    // Table columns
    headers: [
      { text: 'Status', value: 'status', align: 'left' },
      { text: 'Domain Type', value: 'domainType', align: 'left' },
      { text: 'Hosting Pod', value: 'pod', align: 'left' },
      { text: 'Domain', value: 'activeDomain', align: 'left' },
      { text: 'Program', value: 'program', align: 'left' },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
  }),
  
  created () {
    this.$store.dispatch('clearCurrentProgram')
    this.$store.dispatch('initialize')
    this.$store.commit('setNavMenu', false)
    this.$store.dispatch('setToolbar', true)
    this.$store.dispatch('setFooter', true)
    this.$store.dispatch('loadPrograms')
  },

  computed: {
    programs () {
      return this.$store.state.program.programs
    },
    sites () {
      return this.$store.state.site.sites
    },
    availableTempDomain() {
      return this.sites.find(
        m => m.status === 'Available' 
        && m.program === '' 
        && m.pod !== 'demo' 
        && m.domainType === 'Temp'
      )
    },
    filteredSites() {
      const filterByStatus = this.filteredStatus.length > 0 ? this.sites.filter(m => this.filteredStatus.includes(m.status)) : this.sites
      const result = this.filteredPods.length > 0 ? filterByStatus.filter(m => this.filteredPods.includes(m.pod)) : filterByStatus
      return result
    },
    programNameMap() {
      return this.programs.reduce((result, item) => {
        return {
          ...result,
          [item.id]: item.title,
        };
      }, {});
    },

    // Functions common to all tables //
    loadingTable () {
    return this.$store.getters.loadingTable
    },
    loading () {
    return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    snackbar () {
      return this.$store.getters.snackbar
    },
  },
  methods: {
    domainCount(pod) {
      return this.sites.filter(site => site.pod === pod).filter(site => site.status === 'Live').length
    },
    getProgramName(programId) {
      return this.programNameMap[programId];
    },
    getStatusIcon (item) {
      if (item === 'Active') {
        return 'check_circle'
      } else if (item === 'Inactive') {
        return 'not_interested'
      }
    },
    handleNew() {
      // Open New form
      this.dialogNewOrEditOpen = true;
      this.selectedSiteId = '';
      const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 5)
      const id = nanoid()
      this.subdomain = id
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedSiteId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedSiteId = '';
      this.dialogNewOrEditOpen = false;
      this.subdomain= ''
    },
    handleDeleteConfirmation (item) {
      // Delete record
      this.selectedSiteId = item.id;
      this.activeDomain = item.activeDomain;
      this.dialogDeleteOpen = true;
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedSiteId = '';
      this.dialogDeleteOpen = false;
    },
    handleDelete() {
      // Delete Site
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('deleteSite', this.selectedSiteId)
        .then(() => this.dialogDeleteOpen = false)
        .catch(() => this.dialogDeleteOpen = false)
        this.$store.dispatch('setLoading', false)
    },
  }
}
</script>

<style>
</style>
<template>
  <v-row no-gutters class="mt-3 mb-16 px-6">
    <v-col
      v-for="card in count"
      :key="card"
      class="pr-6 pb-3 pt-3"
      cols="12"
      xl="3"
      sm="4"
    >
      <v-card style="height: 224px" class="el mb-4 rounded-card grey lighten-4">
        <v-skeleton-loader
          type="image"
          :height="systemTheme.cardImageHeight"
          class="rounded-b-0"
        ></v-skeleton-loader>
        <v-card-text :style="`height: ${systemTheme.cardTextHeightCorrected}`">
          <v-btn
            class="mt-n11"
            elevation="0"
            right
            absolute
            fab
            color="#DFDFDF"
          >
          </v-btn>
          <v-skeleton-loader
            type="heading"
            class="ml-2 card-adjust"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="text"
            class="ml-2 mt-2 mr-16"
          ></v-skeleton-loader>
        </v-card-text>
        <v-card-actions class="pl-6">
          <v-skeleton-loader
            type="heading"
            width="55"
            class="actions-adjust"
          ></v-skeleton-loader>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
  computed: {
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },
};
</script>

<style>
.card-adjust {
  margin-top: 15px;
}
.actions-adjust {
  margin-top: -16px;
}
</style>

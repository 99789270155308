<template>
  <div>
    {{ name(id) }}
  </div>
</template>

<script>
export default {
  props: ['id'],
  computed: {
    companies () {
      return this.$store.state.company.companies
    },
  },
  methods: {
    name (id) {
      const arr = this.companies
      const obj = arr.find(el => el.id === id)
      return obj.title
    },
  },
}
</script>
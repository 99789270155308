<template>
  <div>
    <v-menu
      v-for="button in buttons"
      :key="button.id"
      offset-y
      open-on-hover
      min-width="350px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="button.color"
          slot="activator"
          v-bind="attrs"
          v-on="on"
          text
        >
          <v-icon medium class="mr-1">{{ button.icon }}</v-icon>
          {{ button.label }}
        </v-btn>
      </template>
      <component
        :is="button.component"
        :programId="programId"
        :currentSubscription="currentSubscription"
        :currentProgram="currentProgram"
        :trial="trial"
        :expired="expired"
      />
    </v-menu>
  </div>
</template>

<script>
import Subscription from "./Subscription.vue";
import Website from "./Website.vue";
import Ios from "./Ios.vue";
import Android from "./Android.vue";
import Demo from "./Demo.vue";
export default {
  components: {
    Subscription,
    Website,
    Ios,
    Android,
    Demo,
  },
  data: () => ({}),
  created() {},
  computed: {
    currentSubscription() {
      return this.$store.getters.currentSubscription;
    },
    programId() {
      return this.$store.getters.programId;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
    trial() {
      const starts = this.currentProgram.trial.starts.seconds;
      const ends = this.currentProgram.trial.ends.seconds;
      const date = new Date();
      const now = Math.floor(date.getTime() / 1000);
      const isTrial = now > starts && now < ends;
      return isTrial;
    },
    expired() {
      if (this.trial) {
        return false;
      }
      return this.currentSubscription.status === "active" ||
        this.isIncentableAdmin
        ? false
        : true;
    },
    buttons() {
      const ios = {
        true: {
          color: "green",
          label: "iOS",
          icon: "fab fa-apple",
          component: "ios",
        },
        false: {
          color: "grey",
          label: "iOS",
          icon: "fab fa-apple",
          component: "ios",
        },
        processing: {
          color: "amber",
          label: "iOS",
          icon: "fab fa-apple",
          component: "ios",
        },
      };
      const android = {
        true: {
          color: "green",
          label: "Android",
          icon: "android",
          component: "android",
        },
        false: {
          color: "grey",
          label: "Android",
          icon: "android",
          component: "android",
        },
        processing: {
          color: "amber",
          label: "Android",
          icon: "android",
          component: "android",
        },
      };
      const website = {
        true: {
          color: "green",
          label: "Website",
          icon: "desktop_windows",
          component: "website",
        },
        false: {
          color: "red",
          label: "Website",
          icon: "desktop_access_disabled",
          component: "website",
        },
      };
      const subscription = {
        trial: {
          color: "amber",
          label: "Free Trial",
          icon: "check_circle",
          component: "subscription",
        },
        demo: {
          color: "green",
          label: "Demo",
          icon: "check_circle",
          component: "demo",
        },
        true: {
          color: "green",
          label: "Subscription",
          icon: "check_circle",
          component: "subscription",
        },
        false: {
          color: "red",
          label: "Subscription",
          icon: "error",
          component: "subscription",
        },
      };

      let arr = [];

      if (this.currentProgram.iosAppStatus === "Active") {
        arr.push(ios.true);
      } else if (this.currentProgram.iosAppStatus === "Processing") {
        arr.push(ios.processing);
      } else {
        arr.push(ios.false);
      }

      if (this.currentProgram.androidAppStatus === "Active") {
        arr.push(android.true);
      } else if (this.currentProgram.androidAppStatus === "Processing") {
        arr.push(android.processing);
      } else {
        arr.push(android.false);
      }

      if (this.currentProgram) {
        if (this.currentProgram.status === "Active" || this.trial) {
          arr.push(website.true);
        } else {
          arr.push(website.false);
        }

        if (this.currentProgram.status === "Trial") {
          arr.push(subscription.trial);
        } else if (this.currentProgram.status === "Active") {
          arr.push(subscription.true);
        } else if (this.currentProgram.status === "Demo") {
          arr.push(subscription.demo);
        } else {
          arr.push(subscription.false);
        }
      }

      return arr;
    },
  },
};
</script>

<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 6em;
  z-index: 2;
}
</style>

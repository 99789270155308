<template>
  <v-dialog
    v-model="isVisible"
    :width="component.dialog.width"
    :fullscreen="component.dialog.fullscreen"
    persistent
    scrollable
  >
    <v-card :class="classes" :style="styles + ';' + gradient">
      <v-btn
        v-if="
          component.dialog.title.floatText === 'text-right' &&
          component.dialog.fullscreen === true
        "
        top
        left
        absolute
        small
        icon
        @click="onClose"
        style="z-index: 100"
      >
        <v-icon :color="component.dialog.title.color">clear</v-icon>
      </v-btn>
      <v-btn
        v-if="
          component.dialog.title.floatText !== 'text-right' &&
          component.dialog.fullscreen === true
        "
        top
        right
        absolute
        small
        icon
        @click="onClose"
        style="z-index: 100"
      >
        <v-icon :color="component.dialog.title.color">clear</v-icon>
      </v-btn>
      <v-row no-gutters>
        <v-col cols="auto">
          <view-dialog-icon
            v-if="
              component.dialog.icon.placement === 'left' &&
              component.dialog.icon.show === true
            "
            :card="offerData"
            :component="component"
          ></view-dialog-icon>
        </v-col>
        <v-col>
          <v-row
            no-gutters
            :justify="component.dialog.icon.justify"
            v-if="
              component.dialog.icon.placement === 'top' &&
              component.dialog.icon.show === true
            "
          >
            <view-dialog-icon
              :card="offerData"
              :component="component"
            ></view-dialog-icon>
          </v-row>
          <view-text
            :settings="component.dialog.opportunityTitle"
            :value="opportunityData.description"
          ></view-text>
          <view-text
            v-if="component.dialog.deadline.show"
            :settings="component.dialog.deadline"
            :value="formatDeadline(opportunityData.deadline)"
          ></view-text>
          <view-text
            v-if="component.dialog.points.show"
            :settings="component.dialog.points"
            :value="opportunityData.points + ' Points'"
          ></view-text>
          <v-row
            no-gutters
            :justify="component.dialog.icon.justify"
            v-if="
              component.dialog.icon.placement === 'bottom' &&
              component.dialog.icon.show === true
            "
          >
            <view-dialog-icon
              :card="offerData"
              :component="component"
            ></view-dialog-icon>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <view-dialog-icon
            v-if="
              component.dialog.icon.placement === 'right' &&
              component.dialog.icon.show === true
            "
            :card="offerData"
            :component="component"
          ></view-dialog-icon>
        </v-col>
      </v-row>

      <v-card-text class="ma-0 pa-0">
        <v-card color="transparent" elevation="0" :style="detailTableStyles">
          <v-tabs
            color="white"
            class="mb-6 mt-3"
            v-model="tab"
            background-color="transparent"
          >
            <v-tab class="claim-heading">New Claim</v-tab>
            <v-tab>Claim History</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <!-- Claim entry start -->
              <v-row no-gutters class="claim-heading mb-5"
                >Invoice Details</v-row
              >
              <div
                v-show="
                  this.$store.getters.currentProgram.url === 'lg.incentable.com'
                "
              >
                <v-form
                  ref="claimForm"
                  v-model="claimFormValid"
                  lazy-validation
                >
                  <v-row no-gutters align="center">
                    <v-col cols="4">
                      <v-text-field
                        name="invoiceNumber"
                        label="Invoice Number"
                        id="invoiceNumber"
                        outlined
                        filled
                        dark
                        dense
                        clearable
                        required
                        :rules="invoiceNumberRules"
                        class="mr-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        name="purchaseOrder"
                        label="Purchase Order Number"
                        id="purchaseOrder"
                        outlined
                        filled
                        dark
                        dense
                        clearable
                        required
                        :rules="purchaseOrderRules"
                        class="mr-2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            name="invoiceDate"
                            label="Invoice Date"
                            id="invoiceDate"
                            outlined
                            filled
                            dark
                            dense
                            clearable
                            required
                            :rules="dateRules"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row no-gutters class="claim-heading mb-5">Products</v-row>
                <v-form
                  ref="productForm"
                  v-model="productFormValid"
                  lazy-validation
                >
                  <v-row no-gutters align="center">
                    <v-col cols="4">
                      <v-autocomplete
                        v-show="
                          this.$store.getters.currentProgram.url ===
                          'lg.incentable.com'
                        "
                        v-model="code"
                        label="Product Code"
                        :items="products"
                        outlined
                        filled
                        dark
                        dense
                        clearable
                        class="mr-2"
                        placeholder="Start typing product code to search"
                        item-text="code"
                        item-value="code"
                        required
                        :rules="codeRules"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.code"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="data.item.title"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="serial"
                        name="serial"
                        label="Serial Number"
                        id="serial"
                        outlined
                        filled
                        dark
                        dense
                        clearable
                        class="mr-2"
                        required
                        :rules="serialRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="price"
                        name="price"
                        label="Price Each"
                        id="price"
                        outlined
                        filled
                        dark
                        dense
                        clearable
                        class="mr-2"
                        required
                        :rules="priceRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field
                        v-model="quantity"
                        name="quantity"
                        label="Quantity"
                        id="quantity"
                        outlined
                        filled
                        dark
                        dense
                        class="mr-2"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-btn
                      color="success"
                      elevation="0"
                      class="mt-n5"
                      @click="addProduct()"
                      >Add</v-btn
                    >
                  </v-row>
                </v-form>

                <v-simple-table :style="cssVars" class="table mt-n4">
                  <template v-slot:default>
                    <thead color="transparent">
                      <tr color="transparent">
                        <th class="text-left" color="transparent">
                          Product Code
                        </th>
                        <th class="text-left">Serial Number</th>
                        <th class="text-left">Price Each</th>
                        <th class="text-left">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in tempClaim.items" :key="item.serial">
                        <td>{{ item.code }}</td>
                        <td>{{ item.serial }}</td>
                        <td>{{ item.price }}</td>
                        <td>{{ item.quantity }}</td>
                      </tr>
                      <tr
                        v-if="tempClaim.items.length === 0"
                        class="mx-3 text-center"
                      >
                        <td colspan="4">No products yet...</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-row no-gutters class="claim-heading mt-6"
                  >Invoice Copy</v-row
                >
              </div>
              <v-row no-gutters class="mt-2">
                <v-file-input
                  dark
                  v-model="fileToUpload"
                  deletable-chips
                  multiple
                  label="Select files to submit a new claim"
                  hint="To upload mulitple files hold the CTRL button on your keyboard when browsing files"
                  persistent-hint
                />
              </v-row>
              <v-row no-gutters class="mt-4 ml-9">
                <v-col>
                  <v-row
                    no-gutters
                    v-for="(file, i) in fileToUpload"
                    :key="i"
                    class="white--text"
                  >
                    {{ file.name }}
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters justify="end">
                <v-btn
                  class=""
                  :disabled="disabled"
                  color="green"
                  elevation="0"
                  dark
                >
                  Submit Claim
                </v-btn>
              </v-row>
              <!-- Claim entry end -->
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="headers"
                :items="tableData"
                :style="cssVars"
                class="table"
                no-data-text="No claims yet"
                :page.sync="page"
                @page-count="pageCount = $event"
                :items-per-page="itemsPerPage"
                hide-default-footer
              >
                <template v-slot:[`item.status`]="{ item }">
                  <status-icon :status="item.status" class="pr-3"></status-icon>
                  {{ item.status }}
                </template>

                <template v-slot:[`item.totalPoints`]="{ item }">
                  <span v-if="item.totalPoints > 0">{{
                    item.totalPoints | number
                  }}</span>
                  <span v-else>-</span>
                </template>

                <template v-slot:[`item.submitted`]="{ item }">
                  {{ item.submitted | shortdate }}
                </template>

                <template v-slot:[`item.reviewed`]="{ item }">
                  <span v-if="item.reviewed">{{
                    item.reviewed | shortdate
                  }}</span>
                  <span v-else>-</span>
                </template>
              </v-data-table>

              <v-row
                justify="center"
                v-if="pageCount > 1"
                no-gutters
                class="mt-2"
                align="center"
              >
                <v-btn
                  v-if="page > 1"
                  rounded
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="page--"
                >
                  <v-icon
                    :color="this.component.dialog.content.heading.color"
                    size="20"
                    >chevron_left</v-icon
                  >
                </v-btn>
                <v-btn
                  v-for="page in pageCount"
                  :key="page"
                  x-small
                  elevation="0"
                  color="transparent"
                  rounded
                  @click="handlePage(page)"
                >
                  <span :style="cssVars" :class="paginationStyle(page)">
                    {{ page }}
                  </span>
                </v-btn>
                <v-btn
                  v-if="page < pageCount"
                  rounded
                  x-small
                  elevation="0"
                  color="transparent"
                  @click="page++"
                >
                  <v-icon
                    :color="this.component.dialog.content.heading.color"
                    size="20"
                    >chevron_right</v-icon
                  >
                </v-btn>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card-text>

      <v-card-actions class="ma-0 pa-0">
        <v-spacer></v-spacer>
        <v-btn
          v-if="component.dialog.fullscreen !== true"
          @click="close()"
          text
          :color="component.dialog.title.color"
          >close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Remeber to add "cardType: 'offer'" to the offer data function as it is needed to activate the correct icon
import ViewText from "../Text.vue";
import ViewDialogIcon from "../DialogIcon.vue";

export default {
  components: {
    ViewText,
    ViewDialogIcon,
  },
  props: ["isVisible", "onClose", "offerData", "opportunityData", "component"],
  created() {},
  data: function () {
    return {
      tab: null,
      claimFormValid: true,
      productFormValid: true,
      invoiceNumberRules: [(v) => !!v || "Invoice number is required"],
      purchaseOrderRules: [(v) => !!v || "Purchase order number is required"],
      dateRules: [(v) => !!v || "Date is required"],
      codeRules: [(v) => !!v || "Product code is required"],
      serialRules: [(v) => !!v || "Serial number is required"],
      priceRules: [(v) => !!v || "Price is required"],
      date: null,
      menu2: false,
      tempClaim: {
        items: [],
      },
      code: null,
      serial: null,
      quantity: 1,
      price: null,
      fileToUpload: [],
      dropzoneOptions: {
        url: "https://httpbin.org/post", // Dummy Post URL,
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        addRemoveLinks: true,
      },
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      show: false,
      // Table columns
      headers: [
        { text: "Status", value: "status", align: "left" },
        { text: "Submitted", value: "submitted", align: "left" },
        { text: "Reviewed", value: "reviewed", align: "left" },
        { text: "Points", value: "totalPoints", align: "right" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],
      // Table settings
      options: {
        sortBy: ["submitted"],
        sortDesc: [false],
        itemsPerPage: 5,
        sortable: true,
        multiSort: true,
        search: true,
        filter: false,
        upload: true,
        download: true,
        more: false,
      },
    };
  },
  computed: {
    products() {
      return [
        { code: "LAEC015-GN2.AAUQ", title: "LG LED All-in-One" },
        { code: "LAED05-GN.AAUQ", title: "All-in-One LED Display" },
      ];
    },
    serials() {
      return [
        "312LHEQ04252",
        "402LHPK00184",
        "402LHHL00185",
        "402LHYS00186",
        "402LHCC00187",
        "402LHQM00188",
        "404LHPK00080",
        "404LHHL00081",
        "404LHYS00082",
        "404LHCC00083",
      ];
    },
    disabled() {
      if (this.fileToUpload.length) {
        return false;
      }
      return true;
    },
    cssVars() {
      return {
        "--header-text-color": this.component.dialog.content.heading.color,
        "--header-text-size": this.component.dialog.content.heading.size + "px",
        "--header-text-style": this.component.dialog.content.heading.style,
        "--header-text-weight": this.component.dialog.content.heading.weight,
        "--header-line-height":
          this.component.dialog.content.heading.lineHeight,
        "--header-font": this.component.dialog.content.heading.font,
        "--row-text-color": this.component.dialog.content.data.color,
        "--row-text-size": this.component.dialog.content.data.size + "px",
        "--row-text-style": this.component.dialog.content.data.style,
        "--row-text-weight": this.component.dialog.content.data.weight,
        "--row-line-height": this.component.dialog.content.data.lineHeight,
        "--row-font": this.component.dialog.content.data.font,
        "--text-align": this.component.dialog.content.align,
        "--row-border-bottom": this.rowBorders,
        "--row-hover-color": this.component.dialog.rowHoverColor.hexa,
        "--current-page-color": this.component.dialog.title.color,
      };
    },
    classes() {
      if (this.component.dialog.fullscreen === true) return "rounded-0";
      return this.component.card.rounded;
    },
    grid() {
      return this.$store.getters.grid;
    },
    rowBorders() {
      return (
        this.component.dialog.rowBorderSize +
        "px" +
        " solid " +
        this.component.dialog.rowBorderColor.hexa
      );
    },
    iconAlign() {
      return this.component.dialog.content.align;
    },
    iconSize() {
      return this.component.dialog.iconSize;
    },
    gradient() {
      if (this.component.dialog.color.type === "gradient") {
        var color = this.component.dialog.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return (
          "background: " + this.component.dialog.color.solidColor.hexa + ";"
        );
      }
    },
    styles() {
      var styles =
        "padding-bottom: " +
        this.component.dialog.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.dialog.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.dialog.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.dialog.padding.right +
        "px ;" +
        "border: " +
        this.component.dialog.borderSize +
        "px solid " +
        ";";
      this.component.dialog.borderColor.hexa + "; ";
      return styles;
    },
    summaryTableStyles() {
      var styles =
        "margin-bottom: " +
        this.component.dialog.summary.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.dialog.summary.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.dialog.summary.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.dialog.summary.margin.right +
        "px ;";
      return styles;
    },
    detailTableStyles() {
      var styles =
        "margin-bottom: " +
        this.component.dialog.detail.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.dialog.detail.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.dialog.detail.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.dialog.detail.margin.right +
        "px ;";
      return styles;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    tableData() {
      const arr = this.offerData.entries || [];
      return arr.filter((el) => el.opportunity === this.opportunityData.id);
    },
  },
  methods: {
    async addProduct() {
      await this.$refs.productForm.validate();
      if (this.productFormValid) {
        this.tempClaim.items.push({
          code: this.code,
          serial: this.serial,
          price: this.price,
          quantity: this.quantity,
        });
        this.resetProductForm();
      }
      return;
    },
    resetProductFormValidation() {
      this.$refs.productForm.resetValidation();
    },
    resetProductForm() {
      this.resetProductFormValidation();
      this.code = null;
      this.serial = null;
      this.price = null;
      this.quantity = 1;
    },
    handleFileAdded(file) {
      console.log("added");
      const fileUp = file;
      return fileUp;
    },
    handlePage(page) {
      this.page = page;
    },
    paginationStyle(val) {
      if (val === this.page) return "incentable-current-page";
      return "incentable-pagination";
    },
    formatDeadline(value) {
      const date = new Date(value);
      return (
        "Offer Ends " +
        date.toLocaleDateString(["en-US"], {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
      );
    },
    close() {
      this.fileToUpload = [];
      this.page = 1;
      this.resetProductForm();
      this.resetProductFormValidation();
      (this.tempClaim = {
        items: [],
      }),
        this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.text-color {
  color: #a8a8a8;
}
.claim-heading {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
}
.dz {
  border: 1px white;
  background-color: transparent;
  border-style: dashed;
}
.animation-text {
  position: absolute !important;
  margin-top: 25px;
  font-size: 50px;
  z-index: 1000;
  font-weight: 700;
  font-family: Roboto;
  color: rgb(168, 0, 0);
}

.animated-icon {
  position: absolute !important;
  margin-top: -50px;
}
.animated-confetti {
  position: absolute !important;
  z-index: 1000;
  width: 70% !important;
  height: 70% !important;
  margin-left: -250px;
}
.svg-opacity {
  opacity: 0.5 !important;
}
.table.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
  line-height: var(--header-line-height) !important;
  background-color: transparent !important;
  text-align: var(--text-align) !important;
  border-bottom: var(--row-border-bottom) !important;
}
.incentable-pagination {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
}
.incentable-current-page {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--current-page-color) !important;
}
.btn-pagination {
  padding-top: 5px;
}
.table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: var(--row-font) !important;
  font-size: var(--row-text-size) !important;
  font-style: var(--row-text-style) !important;
  font-weight: var(--row-text-weight) !important;
  color: var(--row-text-color) !important;
  line-height: var(--row-line-height) !important;
  text-align: var(--text-align) !important;
  background-color: transparent !important;
  border-bottom: var(--row-border-bottom) !important;
}
.table.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
  line-height: var(--header-line-height) !important;
  background-color: transparent !important;
  text-align: var(--text-align) !important;
  border: none !important;
}
.table.theme--light.v-data-table {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: var(--row-hover-color) !important;
}
.v-tab--active {
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}
.v-tab {
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}
</style>

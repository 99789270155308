<template>
  <v-dialog
    v-model="open"
    width="800px"
    persistent
  >
    <v-card class="grey lighten-4 rounded-t-lg rounded-b-0" elevation="8">
      <v-card-title class="table-title">
        <v-icon class="pr-1">{{ tableIcon }}</v-icon>
        {{ tableTitle }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="handleClose"
          class="incentable-n3 mr-n2"
          ><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-card-subtitle class="table-subtitle">
        {{ tableSubtitle }}
      </v-card-subtitle> 
    </v-card>

    <v-card elevation="0">
      <v-card-title>
        <v-row
          no-gutters
          justify="space-between"
          align="center"
          cols="12"
          >
          <v-col lg="4">
            <v-text-field
              v-model="search"
              solo-inverted
              dense
              flat
              hide-details
              label="Search"
              prepend-inner-icon="search"
              class="mr-4"
            ></v-text-field> 
          </v-col>
          
          <v-col
            lg="3"
            v-if="options.filter"
            >
            <v-select
              solo-inverted
              dense
              flat
              hide-details
              label="Filter"
              prepend-inner-icon="filter_list"
              class="mr-4"
            ></v-select> 
          </v-col>
          
          <v-col>
            <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
            <v-btn v-if="options.download" class="primary--text" text>Download</v-btn> 
            <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
          </v-col>
         
        </v-row>
 
      </v-card-title>

      <v-data-table
        class="px-6 py-4"
        :headers="headers"
        :items="sentTo"
        :search="search"
        :loading="loadingTable"
        :options.sync="options"
        no-data-text="No Records..."
      >        
        
      </v-data-table>

    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data: () => ({
      
      // Component settings
      tableTitle: 'Sent To',
      tableSubtitle: 'Members who were sent this communication',
      tableIcon: 'send',
      search: '',
      loading: false,
      
      // Table settings
      options: {
        sortBy: ['title'],
        sortDesc: [false],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: true,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
      
      // Table columns
      headers: [
        { text: 'Email', value: 'email', align: 'left' },
      ],
    }),
    props: {
      sentTo: {
        type: Array,
        required: true,
      },
      open: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      loadingTable () {
        return this.$store.getters.loadingTable
      },
    },
    methods: {
      handleClose () {
        this.clear();
        this.$emit('onClose');
      },
      clear () {
      },
    }
  }
</script>

<style scoped>

</style>
/* eslint-disable no-console */
<template>
  <div class="px-4 pt-4">
    <v-row no-gutters align="center">
      <v-col sm="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field>
      </v-col>

      <v-col sm="3" v-if="options.filter">
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter"
          prepend-inner-icon="filter_list"
          class="mr-4"
        ></v-select>
      </v-col>

      <v-col>
        <v-btn
          @click="handleOpenImportDialog()"
          v-if="options.upload"
          class="primary--text"
          text
          :disabled="demo"
          >Upload</v-btn
        >
        <v-btn
          v-if="options.download"
          class="primary--text"
          text
          :href="companiesCsv"
          :download="getFilename()"
          :disabled="demo"
          >Download</v-btn
        >
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
      </v-col>

      <v-col sm="auto">
        <v-btn class="mt-n2" @click="handleNew()" color="primary" elevation="0">
          add new company
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="companies"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link :disabled="!isRoleSatisfied('Editor')">
              <v-list-item-title @click="handleEdit(item)"
                >Edit
              </v-list-item-title>
            </v-list-item>
            <v-list-item link :disabled="!isRoleSatisfied('Editor')">
              <v-list-item-title @click="handleDeleteConfirmation(item)"
                >Delete
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | convertTimestamp }}
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        {{ item.updated | convertTimestamp }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status" class="pr-3"></status-icon>
        {{ item.status }}
      </template>

      <template v-slot:item.activeCompanyTags="{ item }">
        <v-chip-group column>
          <v-chip small v-for="id in item.tags" :key="id.id" class="ma-1">
            {{ getCompanyTagTitle(id) }}
          </v-chip>
        </v-chip-group>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <div
          v-if="isRoleSatisfied('Editor')"
          @click="handleEdit(item)"
          class="primary--text"
          style="cursor: pointer"
        >
          {{ item.title }}
        </div>
        <div v-else>
          {{ item.title }}
        </div>
      </template>
    </v-data-table>

    <company-add-or-edit
      :companyId="selectedCompanyId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <!-- Import dialog -->

    <companies-import
      :open="dialogImportOpen"
      @onClose="dialogImportOpen = false"
    />

    <!-- Delete dialog -->

    <v-dialog v-model="dialogDeleteOpen" max-width="400px" persistent>
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay absolute color="red" :opacity="systemTheme.cardOpacity">
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading"> Are You Sure? </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          <v-row dense>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ title }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleCloseDeleteDialog()">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    // Component settings unique to this component
    tableTitle: "Companies",
    tableSubtitle: "Create and manage companies",
    tableIcon: "book",
    dialogImportOpen: false,
    search: "",
    title: "",
    selectedCompanyId: "",

    // Table columns
    headers: [
      { text: "Status", value: "status", align: "left" },
      { text: "Company Name", value: "title", align: "left" },
      { text: "Company Tag(s)", value: "activeCompanyTags", align: "left" },
      { text: "Data Key", value: "accountKey", align: "left" },
      { text: "Created", value: "created", align: "right" },
      { text: "Updated", value: "updated", align: "right" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],

    // Component settings common to all components
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,

    // Table settings
    options: {
      sortBy: ["title"],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: true,
      download: true,
      more: false,
    },
  }),

  created() {},

  computed: {
    // Functions specific to this component
    companies() {
      return this.$store.state.company.companies;
    },
    companyTagTitleMap() {
      return this.$store.state.companytag.companyTags.reduce((result, item) => {
        return {
          ...result,
          [item.id]: item.tag,
        };
      }, {});
    },

    // Functions common to all components
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },

    // For CSV download file
    companyTagsMap() {
      return this.$store.getters.companyTagsMap;
    },
    membersMap() {
      return this.$store.getters.membersMap;
    },
    companiesCsv() {
      const dataHeader = "data:text/csv;charset=utf-8,";
      const csvHeader =
        "Company,Account Key,Status,Members,Company Tags,Points,Created,Updated";
      const csvBody = this.companies
        .map((company) => {
          const {
            title,
            accountKey,
            status,
            members,
            tags,
            points,
            created,
            updated,
          } = company;
          const tagsTxt = tags.map((id) => this.companyTagsMap[id]).join(";");
          const membersTxt = members.map((id) => this.membersMap[id]).join(";");
          return `${title},${accountKey},${status},${membersTxt},${tagsTxt},${points},${created},${updated}`;
        })
        .join("\r\n");
      return `${dataHeader}${csvHeader}\r\n${csvBody}`;
    },
  },

  methods: {
    getCompanyTagTitle(id) {
      return this.companyTagTitleMap[id];
    },
    handleNew() {
      // Open New form
      this.selectedCompanyId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedCompanyId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedCompanyId = "";
      this.dialogNewOrEditOpen = false;
    },
    handleOpenImportDialog() {
      // Open Import Dialog
      this.dialogImportOpen = true;
    },
    handleDeleteConfirmation(item) {
      // Delete record
      this.selectedCompanyId = item.id;
      this.title = item.title;
      this.dialogDeleteOpen = true;
    },
    handleDelete() {
      // Delete record
      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("deleteCompany", this.selectedCompanyId)
        .then(() => (this.dialogDeleteOpen = false))
        .catch(() => (this.dialogDeleteOpen = false));
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedCompanyId = "";
      this.dialogDeleteOpen = false;
    },
    getFilename() {
      const program = this.$store.state.program.currentProgram;
      const date = new Date();
      const formattedDate = date.toLocaleDateString(["en-US"], {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      return program.title + " Company List " + formattedDate + ".csv";
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-row v-if="multiSelect" no-gutters class="mt-n1"
      ><v-btn text @click="selectAll" color="primary" small>select all</v-btn
      ><v-btn text @click="deselectAll" color="primary" small
        >clear all</v-btn
      ></v-row
    >
    <v-row no-gutters>
      <v-card
        v-for="option in options"
        :key="option.id"
        height="150px"
        width="150px"
        elevation="0"
        outlined
        :class="dynamicCardClass(option.id)"
        @click="add(option)"
      >
        <v-icon v-if="dynamicIconSize(option.id) === 41" class="check"
          >check_circle</v-icon
        >
        <v-row no-gutters justify="center" align="end" style="height: 80px">
          <v-icon
            :color="dynamicIconColor(option.id)"
            :size="dynamicIconSize(option.id)"
            >{{ option.icon }}</v-icon
          >
        </v-row>
        <v-row
          no-gutters
          justify="center"
          align="end"
          :class="dynamicTextClass(option.id)"
        >
          <span class="text-center mx-2 mt-2">{{ option.text }}</span>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    options: Array,
    multiSelect: Boolean,
  },
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    dynamicCardClass(val) {
      const found = this.selected.some((el) => el.id === val);
      if (found) {
        return "mr-3 mb-3 option-selected";
      }
      return "mr-3 mb-3";
    },
    dynamicTextClass(val) {
      const found = this.selected.some((el) => el.id === val);
      if (found) {
        return "option-selected-text";
      }
      return "option-text";
    },
    dynamicIconColor(val) {
      const found = this.selected.some((el) => el.id === val);
      if (found) {
        return "#00b600";
      }
      return "grey lighten-1";
    },
    dynamicIconSize(val) {
      const found = this.selected.some((el) => el.id === val);
      if (found) {
        return 41;
      }
      return 40;
    },
    add(option) {
      // Find the index of the option in the selected array
      const selectedIndex = this.selected.findIndex(
        (selectedOption) => selectedOption.id === option.id
      );

      // If the option is already selected, remove it (deselect)
      if (selectedIndex !== -1) {
        this.selected.splice(selectedIndex, 1);
        this.update();
      } else {
        // Otherwise, add it (select)
        if (this.multiSelect) {
          this.selected.push(option);
          this.update();
        } else {
          this.selected = [option]; // Reset array and add only this option
          this.update();
        }
      }
    },
    selectAll() {
      // Select all available options
      this.selected = [...this.options]; // Clone the options array into selected
      this.update();
    },
    deselectAll() {
      this.selected = [];
      this.update();
    },
    update() {
      this.$emit("updateSelection", this.selected);
    },
  },
};
</script>

<style scoped>
.option-text {
  font-size: 12px;
  color: rgb(107, 107, 107);
}
.option-selected-text {
  font-size: 13px;
  color: #00b600 !important;
  font-weight: 600;
}
.option-selected {
  border-color: #00b600 !important;
  border-width: 2px !important;
  background-color: transparent !important;
}
.check {
  color: #00b600 !important;
  position: absolute !important;
  margin-left: 119px !important;
  margin-top: 3px !important;
}
</style>

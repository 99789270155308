<template>
  <v-card class="rounded-lg el">
    <v-progress-linear
      :indeterminate="true"
      v-if="loadingOrders"
    ></v-progress-linear>
    <v-simple-table class="ml-4 mr-4">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              <span class="incentable-form-heading ml-n3">Top Rewards</span>
            </th>
            <th class="text-right">
              <span class="dash-card"> Points </span>
            </th>
            <th class="text-right">
              <span class="dash-card"> Quantity </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="reward in topRewardsPaginated" :key="reward.id">
            <td>
              <v-avatar v-if="reward.image" size="38" class="mr-4">
                <v-img
                  :src="
                    reward.externalImageUrl
                      ? reward.externalImageUrl
                      : reward.image.smallUrl
                  "
                  :lazy-src="
                    reward.externalImageUrl
                      ? reward.externalImageUrl
                      : reward.image.smallUrl
                  "
                >
                </v-img> </v-avatar
              >{{ reward.title }}
            </td>
            <td class="text-right">{{ reward.points | number }}</td>
            <td class="text-right">{{ reward.quantity | number }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row no-gutters justify="center" class="mt-n2">
      <v-btn
        color="primary"
        small
        @click="loadMoreTopRewards"
        :disabled="allTopRewardRecordsDisplayed"
        text
        >show more</v-btn
      >
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["data"],
  data: () => ({
    topRewardsToShow: 5,
    loadMoreIncrement: 2,
  }),
  computed: {
    orders() {
      return this.$store.getters.orders;
    },
    loadingOrders() {
      return this.$store.state.loadingOrders;
    },
    topRewards() {
      // Create an object to store the total quantities for each item
      const itemQuantities = {};

      // Loop through the orders array and calculate item quantities
      this.orders.forEach((order) => {
        order.items.forEach((item) => {
          const itemId = item.id;
          const quantity = item.quantity;
          const title = item.title; // Include title in the result
          const image = item.image; // Include title in the result
          const points = item.points; // Include title in the result

          // Increment the quantity for this item
          if (itemQuantities[itemId]) {
            itemQuantities[itemId].quantity += quantity;
          } else {
            itemQuantities[itemId] = {
              quantity,
              title, // Store the title for the item
              image,
              points,
            };
          }
        });
      });

      // Convert the itemQuantities object to an array of items with itemId, title, and quantity
      const itemArray = Object.keys(itemQuantities).map((itemId) => ({
        itemId,
        title: itemQuantities[itemId].title, // Include title
        image: itemQuantities[itemId].image, // Include title
        points: itemQuantities[itemId].points, // Include title
        quantity: itemQuantities[itemId].quantity,
      }));

      // Sort the itemArray by quantity in descending order
      itemArray.sort((a, b) => b.quantity - a.quantity);
      // Return the top 5 items
      return itemArray;
    },
    topRewardsPaginated() {
      return this.topRewards.slice(0, this.topRewardsToShow);
    },
    allTopRewardRecordsDisplayed() {
      return this.topRewardsToShow >= this.topRewards.length;
    },
  },
  methods: {
    loadMoreTopRewards() {
      this.topRewardsToShow = this.topRewardsToShow + this.loadMoreIncrement;
    },
  },
};
</script>

<style scoped></style>

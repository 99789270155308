<template>
  <div class="px-4">
    <div v-if="!newFileProcessActive">
      <v-row class="incentable-form-heading" no-gutters>
        <v-col> Last Targets Uploaded </v-col>
        <v-col>
          <v-row
            v-if="!newFileProcessActive"
            class="mx-2"
            no-gutters
            justify="end"
          >
            <v-btn color="primary" elevation="0" @click="newFile">
              Upload New Targets
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <div v-if="!targetDataFileName">
        <v-row no-gutters class="px-3" justify="center">
          <v-icon color="primary" size="60">move_up</v-icon>
        </v-row>
        <v-row
          no-gutters
          class="px-3 mt-2 incentable-page-subheading"
          justify="center"
        >
          No targets have been uploaded
        </v-row>
      </div>
      <div v-else>
        <v-row no-gutters class="px-3" justify="center">
          <v-icon color="green" size="60">fa-solid fa-file-csv</v-icon>
        </v-row>
        <v-row no-gutters class="incentable-form-subheading" justify="center">
          <a :href="downloadCsv" :download="downloadFileName">{{
            targetDataFileName
          }}</a>
        </v-row>
        <v-row
          no-gutters
          class="px-3 incentable-form-subheading"
          justify="center"
        >
          Uploaded : {{ targetDataDate | date }}
        </v-row>
        <v-row no-gutters justify="center" class="mt-0">
          <v-card class="mt-0 mx-1" outlined tile>
            <v-simple-table dense fixed-header max-height="400px">
              <thead>
                <tr>
                  <th class="incentable-csv-table-title-row">
                    <v-icon color="green">fa-solid fa-file-csv</v-icon>
                  </th>
                  <th
                    v-for="(heading, idx) in consumedTargetHeaders"
                    :key="idx"
                    class="incentable-csv-table-title-row"
                  >
                    {{ heading }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in targetData" :key="i">
                  <td class="incentable-csv-table-number-column" width="20">
                    {{ i + 1 }}
                  </td>
                  <td
                    v-for="(item, index) in row"
                    :key="index"
                    class="incentable-csv-table-cell"
                    style="min-width: 150px"
                  >
                    {{ item }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-row>
      </div>
    </div>
    <div v-if="newFileProcessActive">
      <v-row class="ml-3 mt-0 incentable-form-heading" no-gutters>
        Import New Targets
      </v-row>
      <v-stepper v-model="stage">
        <v-stepper-header>
          <v-stepper-step step="1"> Select CSV </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2"> Map Data Bucket to CSV </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3"> Import Targets </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <select-csv />
            <v-row no-gutters class="mt-3" justify="end">
              <v-btn elevation="0" color="primary" @click="cancelNewFile"
                >Cancel</v-btn
              >
              <v-btn
                v-if="csvStatus"
                @click="stage++"
                elevation="0"
                class="white--text"
                color="primary"
                >Next</v-btn
              >
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <map-csv
              :targetFields="targetHeaders"
              :sourceFields="csvHeaders"
              :requiredFields="requiredFields"
              :sourceData="csvBody"
              :mapping="targetMapping"
              @editingMap="onEditingMap"
              class="mb-3"
            />
            <v-row justify="end" no-gutters>
              <v-btn
                color="primary"
                @click="stage--"
                elevation="0"
                text
                :disabled="editingMap"
                >Back</v-btn
              >
              <v-btn
                color="primary"
                @click="stage++"
                elevation="0"
                :disabled="editingMap"
                >Next</v-btn
              >
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <upload-csv />
            <v-row justify="end" no-gutters>
              <v-btn color="primary" @click="stage--" elevation="0" text
                >Back</v-btn
              >
              <v-btn
                @click="handleUploadData"
                color="primary"
                elevation="0"
                :loading="isProcessing"
                >Proceed with import</v-btn
              >
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import SelectCsv from "./SelectCsv.vue";
import MapCsv from "./MapCsv.vue";
import UploadCsv from "./UploadCsv.vue";

export default {
  components: {
    SelectCsv,
    MapCsv,
    UploadCsv,
  },
  data() {
    return {
      stage: 1,
      requiredFields: ["Account Key"],
      newFileProcessActive: false,
      editingMap: false,
      isProcessing: false,
    };
  },
  created() {
    this.$store.dispatch("setCsvTargetData", []);
  },
  computed: {
    downloadCsv() {
      const arr = this.targetData.map(Object.values);
      const dataHeader = "data:text/csv;charset=utf-8,";
      const csvHeader = this.consumedTargetHeaders.join(",");
      const csv = arr.reduce((result, row, index) => {
        const rowString = row.join(",");
        if (arr.length - 1 === index) {
          return `${result}${rowString}`;
        }
        return `${result}${rowString}\r\n`;
      }, `${dataHeader}${csvHeader}\r\n`);

      return csv;
    },
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },

    databucket() {
      return this.$store.state.databucket.databucket;
    },

    entityMap() {
      return this.$store.getters.databucketEntityMap;
    },

    targetData() {
      const data = this.$store.state.databucket.targetData.data;
      let arr = [];
      data.forEach((el) => arr.push(this.sortObj(el)));
      return arr;
    },

    targetHeaders() {
      return ["Account Key", ...this.targetCodes];
    },

    consumedTargetHeaders() {
      const data = ["Account Key", ...this.consumedTargetCodes];
      const arr = data.sort();
      return arr || [];
    },

    targetDataDate() {
      if (this.$store.state.databucket.targetData) {
        const date = this.$store.state.databucket.targetData.updated;
        return date;
      } else {
        return "";
      }
    },

    targetDataFileName() {
      if (this.$store.state.databucket.targetData) {
        return this.$store.state.databucket.targetData.fileName;
      }
      return null;
    },

    downloadFileName() {
      var date = Date.now();
      return date + "_" + this.targetDataFileName;
    },

    csvData() {
      return this.$store.state.databucket.csvTargetData || [];
    },

    csvStatus() {
      if (this.csvData.length > 0) {
        return true;
      }
      return false;
    },

    targetCodes() {
      return this.databucket.targetCodes;
    },

    consumedTargetCodes() {
      return this.$store.state.databucket.targetData.consumedTargetCodes || [];
    },

    targetMapping() {
      return this.databucket.targetMapping || {};
    },

    csvHeaders() {
      return this.csvData.length ? this.csvData[0] : [];
    },

    csvBody() {
      return this.csvData.length ? this.csvData.slice(1) : [];
    },

    matched() {
      const accountKeyIndex = this.csvHeaders.findIndex(
        (header) => header === this.targetMapping["Account Key"]
      );

      return accountKeyIndex !== -1
        ? this.csvBody.filter((item) => {
            return this.entityMap[item[accountKeyIndex]];
          })
        : [];
    },

    newCsvTargetFileName() {
      return this.$store.state.databucket.newCsvTargetFileName;
    },
  },
  methods: {
    sortObj(val) {
      return Object.keys(val)
        .sort()
        .reduce((r, k) => ((r[k] = val[k]), r), {});
    },
    onEditingMap(val) {
      this.editingMap = val;
    },
    newFile() {
      this.newFileProcessActive = true;
    },
    cancelNewFile() {
      this.$store.dispatch("setCsvTargetData", []);
      this.newFileProcessActive = false;
    },
    handleUploadData() {
      this.isProcessing = true;

      const mappingEntries = Object.entries(this.targetMapping);
      const mappingIndices = mappingEntries.map(([, mVal]) => {
        return this.csvHeaders.findIndex((header) => header === mVal);
      });

      const tcEntries = mappingEntries.filter((entry) =>
        this.targetCodes.find((targetCode) => targetCode === entry[0])
      );
      const validCsvHeaders = this.csvHeaders.filter((header) => {
        return !!tcEntries.find((entry) => entry[1] === header);
      });
      const consumedTargetCodes = validCsvHeaders.reduce((result, header) => {
        const targetCodeEntries = tcEntries.filter(
          (entry) => entry[1] === header
        );
        const codes = targetCodeEntries.map((entry) => entry[0]);
        return [...result, ...codes];
      }, []);

      const mappedData = this.matched.map((row) => {
        const rowResult = mappingEntries.reduce((rowAgg, mappingEntry, idx) => {
          if (mappingIndices[idx] === -1) {
            return rowAgg;
          }
          return {
            ...rowAgg,
            [mappingEntry[0]]: row[mappingIndices[idx]],
          };
        }, {});

        return rowResult;
      });

      const payload = {
        fileName: this.newCsvTargetFileName,
        data: mappedData,
        consumedTargetCodes,
      };

      this.$store
        .dispatch("updateTargetData", payload)
        .then(() => {
          this.isProcessing = false;
          this.resetUpload();
        })
        .catch(() => (this.isProcessing = false));
    },
    resetUpload() {
      this.$store.dispatch("setCsvTargetData", []);
      this.$store.dispatch("setNewCsvTargetFileName", "");
      this.mappingErrors = {};
      this.newFileProcessActive = false;
      this.stage = 1;
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.row-number {
  text-align: center !important;
}
</style>

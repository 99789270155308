import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { store } from '../store'

const stateData = store.state.translate.translationData

Vue.use(VueI18n)
// 
const messages = stateData

const i18n = new VueI18n({
  locale: 'en', // set locale
  silentTranslationWarn: true,
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})

export default i18n
import { db } from "@/firebase";
import firebase from "firebase/compat/app";
import * as utils from "@/utils";

const state = {
  systemAdmins: [],
};

const actions = {
  async loadSystemAdmins({ commit }) {
    commit("setLoadingTable", true);
    let querySnapshot;
    try {
      querySnapshot = await db.collection("admins").get();
    } catch (e) {
      querySnapshot = [];
    }
    const admins = [];
    querySnapshot.forEach(function (doc) {
      const data = doc.data();

      admins.push({
        userId: data.userId,
        name: data.name,
        email: data.email,
        created: data.created,
        terms: data.terms,
        programs: data.programs,
      });
    });
    commit("setSystemAdmins", admins);
    commit("setLoadingTable", false);
  },

  async updateAdmin({ dispatch, commit }, payload) {
    const adminsRef = db.collection("admins");

    let storedAdmin;
    try {
      const adminDoc = await adminsRef.doc(payload.id).get();
      storedAdmin = adminDoc.data();
    } catch (e) {
      storedAdmin = null;
    }

    if (!storedAdmin) {
      throw "Error occured when fetching the admin info";
    }

    let dupCheckRequests;
    let emailDupSnapshot;

    if (payload.accountKey) {
      dupCheckRequests = [adminsRef.where("email", "==", payload.email).get()];
    } else {
      dupCheckRequests = [adminsRef.where("email", "==", payload.email).get()];
    }

    try {
      [emailDupSnapshot] = await Promise.all(dupCheckRequests);
    } catch (e) {
      throw "Error occured when checking email";
    }

    if (emailDupSnapshot.size > 0) {
      let duplicated = false;
      emailDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Email is already registered.";
      }
    }

    const { id, ...adminInput } = payload;

    try {
      await adminsRef.doc(id).update(adminInput);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a admin";
    }

    const addedPrograms = utils.getNewArrayItems(
      storedAdmin.programs,
      adminInput.programs
    );
    const deletePrograms = utils.getNewArrayItems(
      adminInput.programs,
      storedAdmin.programs
    );
    const addedProgramChanges = addedPrograms.map((programId) => {
      return { adminId: id, programId, type: "add" };
    });
    const deletedProgramChanges = deletePrograms.map((programId) => {
      return { adminId: id, programId, type: "delete" };
    });
    const programChanges = [...addedProgramChanges, ...deletedProgramChanges];

    try {
      await dispatch("syncProgramAdmins", programChanges);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating program admins.";
    }

    const tempAdmin = {
      ...storedAdmin,
      ...adminInput,
      id,
    };

    commit("updateAdmin", tempAdmin);
    dispatch("setSnackbar", "Admin Updated.");
  },

  async deleteAdmin({ dispatch, commit }, adminId) {
    console.log(adminId);
    const adminsRef = db.collection("admins");

    let storedAdmin;
    try {
      const adminDoc = await adminsRef.doc(adminId).get();
      storedAdmin = adminDoc.data();
    } catch (e) {
      storedAdmin = null;
    }

    if (!storedAdmin) {
      throw "Error occured when fetching the admin info.";
    }

    const programChanges = storedAdmin.programs.map((programId) => {
      return { adminId, programId, type: "delete" };
    });
    console.log(programChanges);

    try {
      await dispatch("syncProgramAdmins", programChanges);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating program admins.";
    }

    try {
      await adminsRef.doc(adminId).delete();
    } catch (e) {
      console.error(e);
      throw "Error occured when deleting a admin.";
    }
    console.log(adminId);
    commit("deleteAdmin", adminId);
    dispatch("setSnackbar", "Admin Deleted.");
  },

  async syncProgramAdmins(payload) {
    const programUpdatesBatch = db.batch();
    payload.forEach((change) => {
      const { adminId } = change;
      const updateProgramRef = db.collection("admins").collection("programs");
      const update = {
        admins:
          change.type === "add"
            ? firebase.firestore.FieldValue.arrayUnion(adminId)
            : firebase.firestore.FieldValue.arrayRemove(adminId),
      };
      programUpdatesBatch.update(updateProgramRef, update);
    });

    await programUpdatesBatch.commit();
  },
};

const mutations = {
  setSystemAdmins(state, payload) {
    state.systemAdmins = payload;
  },

  setLoadingTable(state, payload) {
    state.loadingTable = payload;
  },

  updateAdmin(state, payload) {
    state.admins = state.admins.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteAdmin(state, payload) {
    state.admins = state.admins.filter((item) => item.id !== payload);
  },
};

const getters = {
  systemAdmins(state) {
    return state.systemAdmins;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

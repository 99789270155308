<template>
  <v-dialog v-model="open" :max-width="batchId ? '1200px' : '500px'" persistent>
    <v-card>
      <form data-vv-scope="batch">
        <div v-if="!batchId">
          <v-img
            :src="require('@/assets/background.png')"
            :height="systemTheme.cardImageHeight"
          >
            <v-overlay
              absolute
              color="primary"
              :opacity="systemTheme.cardOpacity"
            >
            </v-overlay>
          </v-img>
          <v-row justify="center" no-gutters class="mt-n12">
            <v-avatar size="100" color="white" outline>
              <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
            </v-avatar>
          </v-row>
        </div>

        <v-row
          :justify="batchId ? 'space-between' : 'center'"
          no-gutters
          :class="batchId ? 'ml-3' : 'ml-0'"
        >
          <v-card-title class="page-heading">
            {{ batchId ? "Edit " : "Add New " }}
            {{ formTitle }}
          </v-card-title>
          <div v-if="batchId" class="mt-6 mr-6">
            <status-icon :status="status"></status-icon
            ><span class="ml-2 incentable-form-bold">{{ status }}</span>
          </div>
        </v-row>

        <v-card-text :class="batchId ? 'px-9' : 'px-12'">
          <v-row class="mt-n6">
            <v-col>
              <v-text-field
                dense
                name="title"
                label="Batch Name"
                v-validate="'required'"
                :error-messages="errors.collect('batch.title')"
                v-model="title"
                @input="handleChange"
                :readonly="hasBeenAllocated"
              ></v-text-field>

              <v-text-field
                dense
                name="note"
                label="Note on transaction (shown to members)"
                v-model="note"
                v-validate="'required'"
                :error-messages="errors.collect('batch.note')"
                @input="handleChange"
                :readonly="hasBeenAllocated"
              ></v-text-field>

              <v-radio-group
                v-show="!batchId"
                v-model="participantType"
                label="Data Type"
                v-validate="'required'"
                :error-messages="errors.collect('batch.participantType')"
                name="participantType"
                @change="handleChange"
              >
                <v-radio class="mt-4" label="Member" value="member" />
                <v-radio label="Company" value="company" />
              </v-radio-group>
            </v-col>

            <v-col v-if="batchId">
              <v-text-field
                dense
                :value="created | date"
                label="Created"
                readonly
              >
              </v-text-field>
              <v-text-field
                dense
                :value="updated | date"
                label="Updated"
                readonly
              >
              </v-text-field>
            </v-col>

            <v-col v-show="batchId" cols="2">
              <v-radio-group
                class="mt-n2"
                v-model="participantType"
                label="Data Type"
                v-validate="'required'"
                :error-messages="errors.collect('batch.participantType')"
                name="participantType"
                :disabled="hasData"
                @change="handleChange"
              >
                <v-radio label="Member" value="member" />
                <v-radio label="Company" value="company" />
              </v-radio-group>
            </v-col>

            <v-col v-show="batchId">
              <v-row no-gutters justify="end">
                <v-col cols="auto" class="pr-3">
                  <v-row no-gutters justify="end">
                    Total Points in CSV Uploaded:
                  </v-row>
                  <v-row no-gutters justify="end"> Points Matched: </v-row>
                  <v-row no-gutters justify="end"> Points Unmatched: </v-row>
                </v-col>
                <v-col cols="auto">
                  <v-row no-gutters justify="end" class="incentable-form-bold">
                    {{ totalPointsInData | number }}
                  </v-row>
                  <v-row no-gutters justify="end" class="incentable-form-bold">
                    {{ matchedPointsInData | number }}
                  </v-row>
                  <v-row no-gutters justify="end" class="incentable-form-bold">
                    {{ unmatchedPointsInData | number }}
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <div v-if="batchId && hasData">
            <v-row no-gutters>
              <v-col>
                <v-alert
                  v-if="batchId && pointsAsText.length !== 0"
                  color="primary"
                  border="left"
                  dense
                  text
                  class="mt-3"
                >
                  <v-row align="center" no-gutters>
                    <v-col cols="auto">
                      <v-icon color="primary" class="mx-2" size="26"
                        >account_circle</v-icon
                      >
                    </v-col>
                    <v-col class="pl-1 incentable-alert">
                      <div v-if="pointsAsText.length === 1">
                        There is {{ pointsAsText.length }} record with text in
                        the points field. This will be omitted from the batch
                        when allocated, unless corrected.
                      </div>
                      <div v-else>
                        There are {{ pointsAsText.length }} records with text in
                        the points field. These will be omitted from the batch
                        when allocated, unless corrected.
                      </div>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        v-if="isRoleSatisfied('Editor') && filterPointsAsText"
                        elevation="0"
                        color="primary"
                        @click="handleShowPointsAsText(false)"
                        :disabled="loading || demo"
                      >
                        reset
                      </v-btn>
                      <v-btn
                        v-if="isRoleSatisfied('Editor')"
                        elevation="0"
                        color="primary"
                        @click="handleShowPointsAsText(true)"
                        :disabled="loading || demo || filterPointsAsText"
                      >
                        filter for these records
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>

            <v-row no-gutters align="center" class="mt-6">
              <v-col sm="3">
                <v-text-field
                  v-model="search"
                  solo-inverted
                  dense
                  flat
                  hide-details
                  label="Search"
                  prepend-inner-icon="search"
                  class="mr-4"
                ></v-text-field>
              </v-col>

              <v-col sm="3" v-if="options.filter">
                <v-select
                  solo-inverted
                  dense
                  flat
                  hide-details
                  label="Filter By Quality Check"
                  prepend-inner-icon="filter_list"
                  class="mr-4"
                  multiple
                  :items="statusList"
                  v-model="filteredStatus"
                  item-text="text"
                  item-value="id"
                ></v-select>
              </v-col>

              <v-col>
                <v-btn
                  @click="handleOpenImportDialog()"
                  v-if="options.upload"
                  class="primary--text"
                  text
                  :disabled="demo"
                  >Upload</v-btn
                >
                <v-btn
                  v-if="options.download"
                  class="primary--text"
                  text
                  :href="membersCsv"
                  :download="getFilename()"
                  :disabled="demo"
                  >Download</v-btn
                >
                <v-btn v-if="options.more" class="primary--text" text
                  >More</v-btn
                >
              </v-col>

              <v-col sm="auto">
                <v-btn
                  @click="handleAllocateBatchConfirmDialog()"
                  color="primary"
                  elevation="0"
                  class="mt-n2"
                  :disabled="hasBeenAllocated || pendingChange"
                >
                  <v-icon>stars</v-icon>
                  <v-icon>chevron_right</v-icon>
                  <v-icon class="mr-2">group</v-icon>allocate batch to members
                </v-btn>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="filteredFinalData"
              :search="search"
              :options.sync="options"
              :loading="loadingTable"
              no-data-text="No Records..."
            >
              <template v-slot:[`item.exists`]="{ item }">
                <span v-if="item.exists">
                  <status-icon status="Matched"></status-icon>
                  <span class="ml-1">Matched</span>
                </span>
                <span v-else>
                  <status-icon status="Unmatched"></status-icon>
                  <span class="ml-1">Unmatched</span>
                </span>
              </template>

              <template v-slot:[`item.points`]="{ item }">
                {{ item.points }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left close-on-content-click>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      link
                      :disabled="!isRoleSatisfied('Editor') || hasBeenAllocated"
                    >
                      <v-list-item-title @click="handleEdit(item)">
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :disabled="!isRoleSatisfied('Editor') || hasBeenAllocated"
                    >
                      <v-list-item-title @click="handleDeleteDialogOpen(item)"
                        >Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </div>

          <div v-else-if="batchId">
            <v-row justify="center" class="mt-16">
              <v-icon color="primary" size="50">cloud_upload</v-icon>
            </v-row>
            <v-row justify="center">
              <v-btn @click="openUploader" elevation="0" color="primary"
                >Add Points File</v-btn
              >
            </v-row>
            <v-row justify="center" class="mb-16">
              <v-btn
                elevation="0"
                :href="templatePointsCsv"
                download="Points Import CSV Template"
                :disabled="demo"
                text
              >
                <span class="primary--text">Download CSV Template</span>
              </v-btn>
            </v-row>
          </div>

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert type="error" :value="true">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()" :disabled="loading">Close </v-btn>
          <v-btn
            @click="handleSave('batch')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            :disabled="demo || !pendingChange || hasBeenAllocated"
            elevation="0"
            >Save
          </v-btn>
        </v-card-actions>

        <CsvUpload
          :open="dialogImportOpen"
          @onClose="dialogImportOpen = false"
          @addToBatch="addCsvToBatch"
        />

        <AllocateBatchConfirm
          v-if="batchId"
          :open="dialogAllocateBatchConfirm"
          :batch="selectedBatchToAllocate"
          @onClose="dialogAllocateBatchConfirm = false"
          @onConfirmBatch="confirmBatchAllocate"
        />

        <PointsRecordEdit
          v-if="batchId"
          :open="dialogEditOpen"
          :selectedPointsRecord="selectedPointsRecord"
          @onClose="handleCloseEditDialog"
          @onSave="handleSaveEditDialog"
        />

        <PointsRecordDelete
          v-if="batchId"
          :open="dialogDeleteOpen"
          :selectedPointsRecord="selectedPointsRecord"
          @onCloseDelete="handleCloseDeleteDialog"
          @onConfirmDelete="handleConfirmDelete"
        />
      </form>

      <!-- Import dialog -->
    </v-card>
  </v-dialog>
</template>

<script>
import CsvUpload from "./CsvUpload.vue";
import AllocateBatchConfirm from "./AllocateBatchConfirmation.vue";
import PointsRecordEdit from "./PointsRecordEdit.vue";
import PointsRecordDelete from "./PointsRecordDelete.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    CsvUpload,
    AllocateBatchConfirm,
    PointsRecordEdit,
    PointsRecordDelete,
  },
  created() {
    this.$store.dispatch("loadMembers");
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    batchId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => "Batch name is required",
        },
        participantType: {
          required: () => "Participant Type is required",
        },
        note: {
          required: () => "Note is required",
        },
      },
    },
    headers: [
      { text: "Quality Check", value: "exists", align: "left" },
      { text: "First Name", value: "firstname", align: "left" },
      { text: "Last Name", value: "lastname", align: "left" },
      { text: "Email", value: "email", align: "left" },
      { text: "Points", value: "points", align: "right" },
      { text: "Data Key", value: "dataKey", align: "right" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    options: {
      sortBy: ["created"],
      sortDesc: [true],
      itemsPerPage: 5,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: false,
      download: false,
      more: false,
    },
    search: "",
    formTitle: "Batch",
    formIcon: "folder_special",
    showAlert: false,
    status: "",
    allocated: null,
    csvData: [],
    title: "",
    note: "",
    created: "",
    updated: "",
    id: "",
    errorMessage: "",
    filterPointsAsText: false,
    dialogImportOpen: false,
    dialogAllocateBatchConfirm: false,
    participantType: "",
    parsedCsv: null,
    pendingChange: false,
    statusList: [
      { id: true, text: "Matched" },
      { id: false, text: "Unmatched" },
    ],
    filteredStatus: [],
    selectedPointsRecord: {},
    dialogEditOpen: false,
    dialogDeleteOpen: false,
    selectedBatchToAllocate: {},
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    batches() {
      return this.$store.state.batch.batches;
    },
    members() {
      return this.$store.state.member.members;
    },
    hasBeenAllocated() {
      return this.status === "Completed";
    },
    templatePointsCsv() {
      const dataHeader = "data:text/csv;charset=utf-8,";
      const csvHeader = "First Name,Last Name,Data Key,Points";
      const csvBody =
        "Steve,Earle,262626,75000" +
        "\r\n" +
        "John,Prine,123456,32550" +
        "\r\n" +
        "Ketch,Secor,545454,35000" +
        "\r\n" +
        "Henry,Wagons,152760,85000" +
        "\r\n" +
        "Willie,Nelson,858585,98000" +
        "\r\n" +
        "Lucinda,Williams,474747,125000" +
        "\r\n" +
        "Ella,Fitzgerald,363636,25000" +
        "\r\n" +
        "Aretha,Franklin,121212,8500" +
        "\r\n" +
        "Sierra,Ferrell,636363,95000" +
        "\r\n" +
        "Rosanne,Cash,787878,135000" +
        "\r\n";
      return `${dataHeader}${csvHeader}\r\n${csvBody}`;
    },
    hasData() {
      if (!this.csvData.length > 0) {
        return false;
      }
      return true;
    },
    finalData() {
      if (this.allocated) {
        return this.allocated.data;
      }

      const array1 = this.csvData;
      let array2 = this.members;

      let existsArray = array1.map((item1) => {
        let item2 = array2.find((item2) => item1.dataKey === item2.accountKey);
        return Object.assign({}, item1, {
          exists: item2 !== undefined,
          balanceBeforeThisTransaction: item2 ? item2.balance : 0,
          pointsContainText: isNaN(item1.points),
        });
      });
      return existsArray;
    },
    filteredFinalData() {
      const statusArr = this.filteredStatus;
      const data = this.filterPointsAsText
        ? this.finalData.filter((e) => e.pointsContainText === true)
        : this.finalData;
      const filteredByStatus = statusArr.length
        ? data.filter((m) => statusArr.includes(m.exists))
        : data;
      return filteredByStatus;
    },
    totalPointsInData() {
      return this.finalData.reduce((sum, item) => sum + (+item.points || 0), 0);
    },
    matchedPointsInData() {
      return this.finalData
        .filter((e) => e.exists === true)
        .reduce((sum, item) => sum + (+item.points || 0), 0);
    },
    unmatchedPointsInData() {
      return this.finalData
        .filter((e) => e.exists === false)
        .reduce((sum, item) => sum + (+item.points || 0), 0);
    },
    pointsAsText() {
      return this.finalData.filter((el) => el.pointsContainText === true) || [];
    },
  },
  methods: {
    handleEdit(item) {
      this.selectedPointsRecord = Object.assign({}, item);
      this.dialogEditOpen = true;
    },
    handleCloseEditDialog() {
      this.dialogEditOpen = false;
      this.selectedPointsRecord = {};
    },
    handleSaveEditDialog(editedItemUpdate) {
      let index = this.csvData.findIndex(
        (obj) => obj.id === editedItemUpdate.id
      );
      if (index > -1) {
        Object.assign(this.csvData[index], editedItemUpdate);
      }
      this.handleSave("batch");
      this.dialogEditOpen = false;
      this.selectedPointsRecord = {};
    },
    handleConfirmDelete(item) {
      let index = this.csvData.findIndex((obj) => obj.id === item.id);
      if (index > -1) {
        this.csvData.splice(index, 1);
      }
      this.handleSave("batch");
      this.dialogDeleteOpen = false;
      this.selectedPointsRecord = {};
    },
    handleDeleteDialogOpen(item) {
      // Delete record
      console.log("open delete");
      this.dialogDeleteOpen = true;
      this.selectedPointsRecord = Object.assign({}, item);
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedPointsRecord = "";
      this.dialogDeleteOpen = false;
    },
    handleShowPointsAsText(val) {
      this.filterPointsAsText = val;
    },
    handleAllocateBatchConfirmDialog() {
      this.selectedBatchToAllocate = {
        id: this.batchId,
        status: this.status || "",
        csvData: this.csvData || [],
        finalData: this.finalData || [],
        participantType: this.participantType,
        title: this.title,
        created: this.created,
        note: this.note,
        titleUppercase: this.title.toUpperCase(),
        totalPointsInData: this.totalPointsInData,
        matchedPointsInData: this.matchedPointsInData,
        unmatchedPointsInData: this.unmatchedPointsInData,
        pointsAsText: this.pointsAsText || [],
      };
      this.dialogAllocateBatchConfirm = true;
    },
    confirmBatchAllocate() {
      this.$store.dispatch("allocateBatch", this.selectedBatchToAllocate);
      this.status = "Completed";
    },
    addCsvToBatch(data) {
      console.log(data);
      this.csvData = data;
      this.status = "Pending";
      this.handleChange();
    },
    openUploader() {
      console.log("upload clicked");
      this.dialogImportOpen = true;
    },
    handleChange() {
      this.pendingChange = true;
    },
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    clear() {
      this.parsedCsv = null;
      this.csvComponentKey = Math.random();
      this.title = "";
      this.created = "";
      this.updated = "";
      this.createdBy = "";
      this.updatedBy = "";
      this.status = "";
      this.allocated = "";
      (this.filteredStatus = []), (this.filterPointsAsText = false);
      this.note = "";
      this.csvData = [];
      this.participantType = "";
      (this.errorMessage = ""), this.$validator.reset();
      this.$store.dispatch("setLoading", false);
      this.pendingChange = false;
    },
    handleSave(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }

        this.$store.dispatch("setLoading", true);

        const payload = {
          id: this.batchId,
          status: this.status || "",
          csvData: this.csvData || [],
          // finalData: this.finalData || [],
          participantType: this.participantType,
          title: this.title,
          note: this.note,
          titleUppercase: this.title.toUpperCase(),
          created: this.created || "",
          updated: this.updated || "",
          createdBy: this.createdBy || "",
          updatedBy: this.updatedBy || "",
        };

        const action = this.batchId ? "updateBatch" : "createBatch";

        this.$store
          .dispatch(action, payload)
          .then(() => {
            this.$store.dispatch("setLoading", false);
            this.handleShowPointsAsText(false);
            this.pendingChange = false;
            if (action === "createBatch") {
              this.handleClose();
            }
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch("setLoading", false);
          });
      });
    },
  },

  watch: {
    batchId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedBatch = this.batches.find((item) => item.id === newVal);
          const {
            title,
            status,
            created,
            updated,
            id,
            createdBy,
            updatedBy,
            participantType,
            csvData,
            note,
            allocated,
          } = selectedBatch;
          this.id = id;
          this.title = title;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
          this.status = status;
          this.note = note;
          this.csvData = csvData;
          this.participantType = participantType;
          this.allocated = allocated;
        }
      },
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card class="" elevation="0">
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
        <v-overlay absolute color="primary" :opacity="systemTheme.cardOpacity">
        </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar size="100" color="white" outline>
          <v-icon
            v-if="!allocatedSuccess || loadingPoints"
            size="80"
            color="primary"
            >{{ formIcon }}</v-icon
          >
          <v-icon v-else color="primary" size="80">check_circle_outline</v-icon>
        </v-avatar>
      </v-row>
      <v-row
        justify="center"
        no-gutters
        v-if="!allocatedSuccess || loadingPoints"
      >
        <v-card-title class="page-heading">
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-16" v-if="!allocatedSuccess || loadingPoints">
        <v-alert
          v-if="
            batch.pointsAsText !== undefined
              ? batch.pointsAsText.length > 0
                ? true
                : false
              : false
          "
          color="primary"
          border="left"
          dense
          text
          class="mx-n6"
        >
          <v-row align="center" no-gutters>
            <v-col cols="auto">
              <v-icon color="primary" class="mx-1" size="26"
                >account_circle</v-icon
              >
            </v-col>
            <v-col class="pl-1 incentable-alert">
              <div v-if="batch.pointsAsText.length === 1">
                There is {{ batch.pointsAsText.length }} record with text in the
                points field. This will be omitted.
              </div>
              <div v-else>
                There are {{ batch.pointsAsText.length }} records with text in
                the points field. These will be omitted.
              </div>
            </v-col>
          </v-row>
        </v-alert>

        <v-row no-gutters justify="end">
          <v-col cols="auto" class="pr-3">
            <v-row no-gutters justify="end"> Batch Name: </v-row>
            <v-row no-gutters justify="end"> Note: </v-row>
            <v-row no-gutters justify="end">
              Total Points in CSV Uploaded:
            </v-row>
            <v-row no-gutters justify="end"> Points Matched: </v-row>
            <v-row no-gutters justify="end"> Points Unmatched: </v-row>
          </v-col>
          <v-col>
            <v-row no-gutters justify="start" class="incentable-form-bold">
              {{ batch.title }}
            </v-row>
            <v-row no-gutters justify="start" class="incentable-form-bold">
              {{ batch.note }}
            </v-row>
            <v-row no-gutters justify="start" class="incentable-form-bold">
              {{ batch.totalPointsInData | number }}
            </v-row>
            <v-row no-gutters justify="start" class="incentable-form-bold">
              {{ batch.matchedPointsInData | number }}
            </v-row>
            <v-row no-gutters justify="start" class="incentable-form-bold">
              {{ batch.unmatchedPointsInData | number }}
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="px-16" v-else>
        <v-row justify="center" class="page-heading my-12">
          Points Allocated!
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" color="primary" text :disabled="loadingPoints">
          close
        </v-btn>
        <v-btn
          v-if="!allocatedSuccess || loadingPoints"
          @click="handleAllocateBatch()"
          color="primary"
          elevation="0"
          :loading="loadingPoints"
        >
          Allocate Batch Points
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {},
  created() {
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("setLoadingPoints", false);
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    batch: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    formTitle: "Confirm Points Allocation",
    formIcon: "stars",
    allocatedSuccess: false,
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loadingPoints() {
      return this.$store.getters.loadingPoints;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },
  methods: {
    close() {
      this.$emit("onClose");
      this.allocatedSuccess = false;
    },
    handleAllocateBatch() {
      this.$store.dispatch("setLoadingPoints", true);
      this.$emit("onConfirmBatch");
      this.allocatedSuccess = true;
    },
  },
};
</script>

<template>
  <div class="transparent mt-n2 mr-3 mb-15 ml-n3">
    <div>
      <v-alert
        color="primary"
        border="left"
        dense
        text
        class="mx-3 mt-5 mb-0"
        v-if="currentProgram.status === 'Expired' && programId"
      >
        <v-row align="center">
          <v-col cols="auto">
            <v-icon class="ml-2 mr-n2" size="26" color="primary">error</v-icon>
          </v-col>
          <v-col class="incentable-alert" cols="auto">
            Your subscription has expired.
            <template v-if="!plansTable">
              <span @click="handlePlansTable(true)" class="underline"
                >View subscription plans</span
              >, or if your prefer, you can archive this program
            </template>
          </v-col>
          <v-col>
            <v-btn
              v-if="!plansTable && currentProgram.status === 'Active'"
              color="secondary"
              class="float-right ma-0 ml-2"
              elevation="0"
              @click="archiveProgram(true)"
              >Archive Program</v-btn
            >
            <v-btn
              v-if="!plansTable && currentProgram.status === 'Archive'"
              color="primary"
              class="float-right ma-0 ml-2"
              elevation="0"
              @click="archiveProgram(false)"
              >Un-Archive Program</v-btn
            >
            <v-btn
              v-if="plansTable"
              color="secondary"
              class="float-right ma-0"
              elevation="0"
              @click="handlePlansTable(false)"
              >Exit subscription plans</v-btn
            >
            <v-btn
              v-if="!plansTable && currentProgram.status === 'Active'"
              color="primary"
              class="float-right ma-0"
              elevation="0"
              @click="handlePlansTable(true)"
              >View subscription plans</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <div
      v-if="
        currentProgram.status === 'Trial' &&
        programId &&
        currentProgram.status !== 'Theme'
      "
    >
      <v-alert
        v-show="alert"
        color="primary"
        border="left"
        text
        dense
        class="mx-3 mt-5 mb-0"
      >
        <v-row align="center">
          <v-col cols="auto">
            <v-icon class="ml-2 mr-n2" size="26" color="primary">error</v-icon>
          </v-col>
          <v-col class="incentable-alert">
            Your free trial ends on
            {{ (currentProgram.trial.ends.seconds * 1000) | date }}.
            <span
              v-if="!plansTable"
              @click="handlePlansTable(true)"
              class="underline"
              >View subscription plans</span
            >
          </v-col>
          <v-col>
            <v-btn
              v-if="plansTable"
              color="secondary"
              class="float-right ma-0"
              elevation="0"
              @click="handlePlansTable(false)"
              >Exit subscription plans</v-btn
            >
            <v-btn
              v-else
              color="primary"
              class="float-right ma-0"
              elevation="0"
              @click="handlePlansTable(true)"
              >View subscription plans</v-btn
            >
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" @click="hideAlert" icon
              ><v-icon>close</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </div>

    <div v-if="plansTable" class="mt-3">
      <v-row no-gutters justify="end"
        ><v-btn
          color="primary"
          @click="handlePlansTable(false)"
          elevation="0"
          class="mr-6"
          >Exit Subscription Plans</v-btn
        ></v-row
      >
      <plans-table class="mt-10 mx-4"></plans-table>
    </div>

    <div
      v-else-if="
        !expired ||
        isIncentableAdmin ||
        currentProgram.status === 'Active' ||
        currentProgram.status === 'Trial' ||
        currentProgram.status === 'Demo'
      "
    >
      <!-- Top Row Count Cards -->
      <v-row align="start" justify="start" no-gutters>
        <v-col sm="2">
          <count
            title="Members"
            icon="person"
            :value="members.length"
            :orgTheme="orgTheme"
            :class="cardMargin"
            :iconColor="'#4CAF50'"
            :iconCardColor="'#C8E6C9'"
          />
        </v-col>
        <v-col sm="2">
          <count
            title="Companies"
            icon="business"
            :value="companies.length"
            :orgTheme="orgTheme"
            :class="cardMargin"
            :iconColor="'#2196F3'"
            :iconCardColor="'#BBDEFB'"
          />
        </v-col>
        <v-col sm="2">
          <count
            title="Points Awarded"
            icon="stars"
            :value="awarded"
            :orgTheme="orgTheme"
            :class="cardMargin"
            :iconColor="'#FF9800'"
            :iconCardColor="'#FFE0B2'"
          />
        </v-col>
        <v-col sm="2">
          <count
            title="Points Redeemed"
            icon="shopping_cart"
            :value="redeemed"
            :orgTheme="orgTheme"
            :class="cardMargin"
            :iconColor="'#F44336'"
            :iconCardColor="'#FFCDD2'"
          />
        </v-col>
        <v-col sm="2">
          <count
            title="Points Balance"
            icon="account_balance_wallet"
            :value="balance"
            :orgTheme="orgTheme"
            :class="cardMargin"
            :iconColor="'#00BCD4'"
            :iconCardColor="'#B2EBF2'"
          />
        </v-col>
        <v-col sm="2">
          <count
            title="Contingent Liability"
            icon="paid"
            :value="liability"
            :orgTheme="orgTheme"
            :class="cardMargin"
            :iconColor="'#9C27B0'"
            :iconCardColor="'#E1BEE7'"
          />
        </v-col>
      </v-row>

      <v-row align="start" justify="start" no-gutters>
        <!-- Column 1 -->
        <v-col cols="12" md="4">
          <registrations-chart
            title="Members Added"
            icon="person"
            :records="members"
            :class="cardMargin"
            :chartLineColor="'#4CAF50'"
            :chartFillColor="'#C8E6C9'"
          />
          <v-row no-gutters>
            <v-col>
              <dial
                :value="activationRate"
                :color="'green'"
                :title="'Activation Rate'"
                :subtitle="activated.length + ' Activated'"
                suffix="%"
                :class="cardMargin"
              ></dial>
            </v-col>
            <v-col>
              <dial
                :value="redemptionRate"
                :color="'red'"
                :title="'Redemption Rate'"
                :subtitle="'Points redeemed as a % of awarded'"
                suffix="%"
                :class="cardMargin"
              ></dial>
            </v-col>
          </v-row>
          <top-earning-members
            class="mt-n3 mr-3 mb-3 ml-3"
          ></top-earning-members>
          <companies-chart
            title="Companies Added"
            icon="business"
            :records="companies"
            :class="cardMargin"
            :chartLineColor="'#2196F3'"
            :chartFillColor="'#BBDEFB'"
          />
        </v-col>

        <!-- Column 2 -->
        <v-col cols="12" md="4">
          <points-awarded
            title="Points Awarded"
            icon="stars"
            :records="allUsersTransactions"
            :class="cardMargin"
            :chartLineColor="'#FF9800'"
            :chartFillColor="'#FFE0B2'"
          />

          <v-row no-gutters>
            <v-col>
              <dial
                :class="cardMargin"
                :value="totalOpenRewardOrders"
                :color="'purple'"
                :title="'Open Reward Orders'"
                :subtitle="'Reward order items Open'"
              ></dial>
            </v-col>
            <v-col>
              <dial
                :class="cardMargin"
                :value="totalCompletedRewardOrders"
                :color="'teal'"
                :title="'Completed Orders'"
                :subtitle="'Reward order items Completed'"
              ></dial>
            </v-col>
          </v-row>
          <top-rewards class="mt-n3 mr-3 mb-3 ml-3"></top-rewards>
          <rewards-chart
            title="Rewards Added"
            icon="card_giftcard"
            :records="rewards"
            :class="cardMargin"
            :chartLineColor="'#CDDC39'"
            :chartFillColor="'#F0F4C3'"
          />
        </v-col>

        <!-- Column 3 -->
        <v-col cols="12" md="4">
          <points-redeemed
            title="Points Redeemed"
            icon="shopping_cart"
            :records="allUsersRedeemedTransactions"
            :class="cardMargin"
            :chartLineColor="'#F44336'"
            :chartFillColor="'#FFCDD2'"
          />
          <registered
            :class="cardMargin"
            :members="members"
            :orgTheme="orgTheme"
          />
          <invited
            :class="cardMargin"
            :members="members"
            :orgTheme="orgTheme"
          />

          <articles-chart
            title="Articles Published"
            icon="article"
            :records="articles"
            :class="cardMargin"
            :chartLineColor="'#FF9800'"
            :chartFillColor="'#FFE0B2'"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Count from "./Components/Counts/Count.vue";
import Registered from "./Components/Registration/Registered.vue";
import Invited from "./Components/Registration/Invited.vue";
import RegistrationsChart from "./Components/Charts/Registrations.vue";
import CompaniesChart from "./Components/Charts/Companies.vue";
import ArticlesChart from "./Components/Charts/Articles.vue";
import RewardsChart from "./Components/Charts/Rewards.vue";
import PointsAwarded from "./Components/Charts/PointsAwarded.vue";
import PointsRedeemed from "./Components/Charts/PointsRedeemed.vue";
import PlansTable from "../Program/Setup/PlansTable.vue";
import Dial from "./Components/Dials/Dial.vue";
import TopRewards from "./Components/Tables/TopRewards.vue";
import TopEarningMembers from "./Components/Tables/TopEarningMembers.vue";
export default {
  components: {
    Invited,
    Registered,
    Count,
    PlansTable,
    CompaniesChart,
    ArticlesChart,
    RewardsChart,
    Dial,
    RegistrationsChart,
    TopRewards,
    PointsAwarded,
    PointsRedeemed,
    TopEarningMembers,
  },

  data: () => ({
    cardMargin: "pa-3",
    factor: 50,
    alert: true,
  }),

  computed: {
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    currentUserRole() {
      return this.$store.getters.currentUserRole;
    },

    expired() {
      return this.currentSubscription.status === "active" ? false : true;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
    subdomain() {
      return this.$store.getters.subdomain;
    },
    members() {
      return this.$store.getters.members;
    },
    balance() {
      const arr = this.members;
      var result = arr.reduce((n, { balance }) => n + balance, 0);
      return result ? result : 0;
    },
    awarded() {
      const arr = this.members;
      var result = arr.reduce((n, { awarded }) => n + awarded, 0);
      return result ? result : 0;
    },
    redeemed() {
      const arr = this.members;
      var result = arr.reduce((n, { redeemed }) => n + redeemed, 0);
      return result ? result : 0;
    },
    redemptionRate() {
      const rate = (Math.abs(this.redeemed) / this.awarded) * 100;
      return Math.round(rate * 10) / 10 || 0;
    },
    activated() {
      return this.members.filter((m) => m.status === "Active");
    },
    activationRate() {
      const rate = (this.activated.length / this.members.length) * 100;
      return Math.round(rate * 10) / 10 || 0;
    },
    allUsersTransactions() {
      if (this.$store.getters.allUsersTransactions.length > 0) {
        const data = this.$store.getters.allUsersTransactions;
        data.sort((a, b) => {
          const dateA = new Date(`20${a.monthYear}`);
          const dateB = new Date(`20${b.monthYear}`);
          return dateA - dateB;
        });
        return data;
      }
      return [];
    },
    allUsersRedeemedTransactions() {
      if (this.$store.getters.allUsersRedeemedTransactions.length > 0) {
        const data = this.$store.getters.allUsersRedeemedTransactions;
        data.sort((a, b) => {
          const dateA = new Date(`20${a.monthYear}`);
          const dateB = new Date(`20${b.monthYear}`);
          return dateA - dateB;
        });
        return data;
      }
      return [];
    },
    liability() {
      return this.balance / this.factor;
    },
    articles() {
      return this.$store.getters.articles;
    },
    companies() {
      return this.$store.getters.companies;
    },
    rewards() {
      return this.$store.getters.rewards;
    },
    orders() {
      return this.$store.getters.orders;
    },
    loadingOrders() {
      return this.$store.state.loadingOrders;
    },
    totalOpenRewardOrders() {
      let openItemCount = 0;
      this.orders.forEach((order) => {
        order.items.forEach((item) => {
          if (item.status === "Open") {
            openItemCount += item.quantity;
          }
        });
      });
      return openItemCount;
    },
    totalCompletedRewardOrders() {
      let openItemCount = 0;
      this.orders.forEach((order) => {
        order.items.forEach((item) => {
          if (item.status === "Completed") {
            openItemCount += item.quantity;
          }
        });
      });
      return openItemCount;
    },
    navmenu() {
      return this.$store.state.navmenu;
    },
    programId() {
      return this.$store.getters.programId;
    },
    plansTable() {
      return this.$store.getters.plansTable;
    },
  },

  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("setPlansTable", false);
    this.$store.dispatch("setToolbar", true);
    this.$store.dispatch("setFooter", true);
    this.$store.dispatch("setNavMenu", true);
    this.$store.dispatch("setDrawer", true);
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("loadArticles");
    this.$store.dispatch("loadRewards");
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadAllUsersTransactions");
    this.$store.dispatch("loadAllUsersRedeemedTransactions");
    // this.$i18n.locale = this.locale
  },
  methods: {
    currentSubscription(programId) {
      const sub = this.$store.dispatch("getCurrentSubscription", programId);
      return sub;
    },
    hideAlert() {
      this.alert = false;
    },
    handleReviewAwaitingApproval() {
      const programId = this.programId;
      this.$router.push({ path: `/members/${programId}` });
    },
    handlePlansTable(val) {
      if (this.programId) {
        this.$store.dispatch("setPlansTable", val);
      }
      return;
    },
    archiveProgram(action) {
      const payload = {
        status: action ? "Archive" : "Expired",
      };
      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("updateCurrentProgram", payload)
        .then(() => {
          this.$store.dispatch("setLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("setLoading", false);
        });
    },
  },
};
</script>

<style scoped>
.v-icon.icon {
  font-size: 38px;
  padding-right: 8px;
}
.fade {
  opacity: 0.2;
}
.v-btn.add {
  margin-top: 2px;
}
.dash-sub-menu {
  font-family: "Roboto", sans-serif !important;
  font-size: 11px;
  font-weight: 300 !important;
  color: #acacac !important;
}
.underline {
  text-decoration: underline;
}
</style>

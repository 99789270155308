<template>
  <v-container class="page mb-15" fluid>
    <v-tabs>
      <v-tab class="tab"> Rewards </v-tab>
      <v-tab class="tab"> Categories </v-tab>
      <v-tab class="tab"> Catalogues </v-tab>
      <v-tab class="tab"> Suppliers </v-tab>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <rewards-table></rewards-table>
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <reward-categories-table></reward-categories-table>
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <reward-catalogues-table></reward-catalogues-table>
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <RewardSuppliersTable />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import RewardSuppliersTable from "./RewardSuppliersTable.vue";
export default {
  components: {
    RewardSuppliersTable,
  },
  data: function () {
    return {
      active: "",
    };
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadRewards");
    this.$store.dispatch("loadRewardCategories");
    this.$store.dispatch("loadRewardCatalogues");
    this.$store.dispatch("loadRewardSuppliers");
    this.$store.dispatch("loadCompanyTags"); // keep
    this.$store.dispatch("loadMemberTags"); // keep
    this.$store.dispatch("setNavMenu", true);
  },
};
</script>

<style scoped></style>

<template>
  <v-snackbar
    app
    bottom
    elevation="6"
    :value="snackbarLight"
    light
  >
  {{ snackbarLight }}
</v-snackbar>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    snackbarLight () {
      return this.$store.state.snackbarLight
    }
  },
  watch: {
    snackbarLight (newVal) {
      if (newVal) {
        setTimeout(() => { this.$store.dispatch('setSnackbarLight', null) }, 5000)
      }
    }
  }
}
</script>

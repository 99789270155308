<template>
  <v-dialog v-model="open" persistent :width="dialogWidth">
    <v-card>
      <v-row justify="start" no-gutters>
        <v-card-title class="page-heading ml-2">
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-12">
        <v-row no-gutters class="incentable-form-heading">
          <v-col>
            {{ this.stepHeading }}
          </v-col>
        </v-row>
        <v-row dense class="mb-3">
          <v-col>
            {{ this.stepDescription }}
          </v-col>
        </v-row>

        <div v-if="step === 1">
          <v-select
            name="rewardCatalogue"
            :items="rewardCatalogues"
            label="Reward Catalogue"
            v-validate="'required'"
            :error-messages="errors.collect('reward.rewardCatalogue')"
            v-model="selectedRewardCatalogue"
            item-text="title"
            item-value="id"
          ></v-select>
          <v-select
            name="rewardSupplier"
            :items="rewardSuppliers"
            label="Reward Supplier"
            v-model="selectedRewardSupplier"
            v-validate="'required'"
            :error-messages="errors.collect('reward.rewardSupplier')"
            item-text="title"
            item-value="id"
          ></v-select>
        </div>

        <div v-if="step === 2">
          <v-row>
            <v-col>
              <csv-import
                class="pl-5"
                v-model="parsedCsv"
                :map-fields="mappingFields"
                :required-fields="requiredFields"
              />
            </v-col>
          </v-row>
        </div>

        <div v-if="step === 3">
          <ul class="pl-6 mb-7 incentable-form-heading">
            <li no-gutters>{{ parsedCsv.length }} Rewards found in your CSV</li>
            <li no-gutters>{{ validNewRewards.length }} are valid</li>
            <li no-gutters>
              {{ newRewardCategoriesArray.length }}
              <span v-if="newRewardCategoriesArray.length === 1" class="ml-1">
                Reward Category will be created
              </span>
              <span v-else class="ml-1">
                Reward Categories will be created
              </span>
            </li>
          </ul>

          <v-alert
            v-if="validNewRewards.length !== parsedCsv.length"
            color="primary"
            border="left"
            text
            class="my-3"
          >
            <v-row no-gutters align="center">
              <v-col cols="auto">
                <v-icon color="primary" class="mx-2" size="26"
                  >fa-solid fa-gift</v-icon
                >
              </v-col>
              <v-col class="pl-2 incentable-alert">
                {{ parsedCsv.length - validNewRewards.length }} omitted from
                import because they already exist. (Based on the title) <br />If
                you wish to update an existing reward, add it's SKU to the CSV
                and make sure the SKU exists on the reward already in the
                system. When the SKU's match, the CSV data overwrites the
                existing reward. <br />If you wish to add it as a new reward,
                its title must be unique.
              </v-col>
            </v-row>
          </v-alert>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="header in importedCsvHeaders"
                    :key="header"
                    class="text-left"
                  >
                    {{ header }}
                  </th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="reward in newRewards" :key="reward['Sku']">
                  <td v-for="header in importedCsvHeaders" :key="header">
                    {{ reward[header] | truncate(30) }}
                  </td>
                  <td>
                    <v-chip v-if="!reward.isValid" color="red" small
                      >Omit</v-chip
                    >
                    <v-chip
                      v-if="reward.isValid && reward.isUpdate"
                      color="amber"
                      small
                      >Update</v-chip
                    >
                    <v-chip
                      v-if="reward.isValid && !reward.isUpdate"
                      color="green"
                      small
                      >New</v-chip
                    >
                  </td>
                  <td class="caption">
                    {{
                      reward.isUpdate && reward.isValid
                        ? "Updating"
                        : reward.logs
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div v-if="step === 4">
          <v-row align="center" v-if="!processSuccess && !processFailed">
            <div>
              <v-progress-circular
                :size="70"
                :width="7"
                color="red"
                indeterminate
              ></v-progress-circular>
            </div>
            <div class="incentable-page-subheading red--text my-8">
              Processing
            </div>
            <v-alert color="primary" dense text border="left">
              <v-row align="center">
                <v-col lg="1">
                  <v-icon color="primary" class="mx-2" size="26"
                    >warning</v-icon
                  >
                </v-col>
                <v-col class="pl-4 incentable-alert" md="auto">
                  Do not close browser while processing
                </v-col>
              </v-row>
            </v-alert>
          </v-row>

          <v-row align="center" v-if="processSuccess">
            <div>
              <v-icon size="80" color="green" class="mt-7">check</v-icon>
            </div>
            <div class="incentable-page-subheading my-2 primary--text">
              Success
            </div>
          </v-row>

          <v-row align="center" v-if="processFailed">
            <div>
              <v-icon size="80" color="red" class="mt-7">close</v-icon>
            </div>
            <div class="incentable-page-subheading my-2 red--text">
              Import Failed
            </div>
          </v-row>

          <v-row v-for="logItem in processingDetails" :key="logItem">
            {{ logItem }}
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isLastStep"
          color="primary"
          elevation="0"
          text
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="!isLastStep"
          color="primary"
          elevation="0"
          :disabled="!isNextStepReady"
          @click="gotoNext()"
        >
          Next Step
        </v-btn>
        <v-btn
          v-if="(isLastStep && processSuccess) || (isLastStep && processFailed)"
          color="primary"
          elevation="0"
          @click="gotoNext()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as utils from "@/utils";
const TOTAL_STEP = 4;
const CSV_MAPPING = {
  TITLE: "Title",
  BODY: "Description",
  EXTERNAL_IMAGE_URL: "Image URL",
  POINTS: "Points",
  PRICE: "Price",
  SKU: "Sku",
  REWARD_CATEGORIES: "Reward Categories",
};
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    const mappingFields = [
      CSV_MAPPING.TITLE,
      CSV_MAPPING.BODY,
      CSV_MAPPING.POINTS,
      CSV_MAPPING.REWARD_CATEGORIES,
      CSV_MAPPING.SKU,
      CSV_MAPPING.PRICE,
      CSV_MAPPING.EXTERNAL_IMAGE_URL,
    ];
    const requiredFields = [
      CSV_MAPPING.TITLE,
      CSV_MAPPING.BODY,
      CSV_MAPPING.POINTS,
      CSV_MAPPING.REWARD_CATEGORIES,
    ];
    return {
      formTitle: "Upload Rewards CSV",
      formIcon: "cloud_upload",
      mappingFields,
      requiredFields,
      step: 1,
      parsedCsv: null,
      newRewards: [],
      newRewardCategoriesArray: [],
      processingDetails: [],
      processSuccess: false,
      processFailed: false,
      selectedRewardCatalogue: "",
      selectedRewardSupplier: "",
    };
  },
  computed: {
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    rewardCatalogues() {
      return this.$store.state.rewardcatalogue.rewardCatalogues;
    },
    rewardSuppliers() {
      return this.$store.state.rewardsupplier.rewardSuppliers.filter(
        (el) => el.status === "Active"
      );
    },

    rewards() {
      return this.$store.state.reward.rewards;
    },
    rewardTitlesMap() {
      return this.rewards.reduce((result, reward) => {
        return reward.title ? { ...result, [reward.title]: reward.id } : result;
      }, {});
    },

    suppliers() {
      return this.$store.state.rewardsupplier.rewardSuppliers;
    },

    rewardCategories() {
      return this.$store.state.rewardcategory.rewardCategories;
    },
    rewardCategoriesArray() {
      return this.rewardCategories.map((item) => item.title);
    },
    rewardCategoriesMap() {
      return this.rewardCategories.reduce((result, item) => {
        return item.title ? { ...result, [item.title]: item.id } : result;
      }, {});
    },

    rewardSkusMap() {
      return this.rewards.reduce((result, reward) => {
        return reward.sku ? { ...result, [reward.sku]: reward.id } : result;
      }, {});
    },

    fieldExistency() {
      if (!this.parsedCsv || !this.parsedCsv.length) {
        return {};
      }

      const firstRow = this.parsedCsv[0];
      return this.mappingFields.reduce((result, field) => {
        return {
          ...result,
          // eslint-disable-next-line no-prototype-builtins
          [field]: firstRow.hasOwnProperty(field),
        };
      }, {});
    },

    validNewRewards() {
      return this.newRewards.filter((reward) => reward.isValid);
    },

    stepHeading() {
      if (this.step === 1) {
        return "1. Select Catalogue and Supplier";
      } else if (this.step === 2) {
        return (
          "2. Upload CSV to the " +
          this.getRewardCatalogueName(this.selectedRewardCatalogue) +
          " catalogue, and link them to the supplier " +
          this.getRewardSupplierName(this.selectedRewardSupplier)
        );
      } else if (this.step === 3) {
        return "3. Review";
      } else if (this.step === 4) {
        return "3. Import Complete";
      } else {
        return "";
      }
    },

    stepDescription() {
      if (this.step === 1) {
        return "Please select the catalogue this import is for and the supplier of the rewards in the CSV. Upload one CSV for each individual supplier";
      } else if (this.step === 2) {
        return "Please upload the CSV and select the column mapping.";
      } else if (this.step === 3) {
        return "Please check below details before you proceed.";
      } else if (this.step === 4) {
        return "If import fails, please close the dialog and restart the import process.";
      } else {
        return "";
      }
    },

    dialogWidth() {
      if (this.step === 1) {
        return "400px";
      } else if (this.step === 2) {
        return "1140px";
      } else if (this.step === 4) {
        return "500px";
      }
      return "90vw";
    },

    isLastStep() {
      return this.step === TOTAL_STEP;
    },

    isStep2Ready() {
      if (
        this.selectedRewardCatalogue !== "" &&
        this.selectedRewardSupplier !== ""
      ) {
        return true;
      }
      return false;
    },

    isStep3Ready() {
      console.log("checking step 3");
      if (!this.parsedCsv || !this.parsedCsv.length) {
        return false;
      }
      const firstRow = this.parsedCsv[0];

      const hasAllRequiredFields = this.requiredFields.reduce(
        (result, field) => {
          // eslint-disable-next-line no-prototype-builtins
          return result && firstRow.hasOwnProperty(field);
        },
        true
      );

      return hasAllRequiredFields;
    },

    isStep4Ready() {
      return this.validNewRewards.length > 0;
    },

    isNextStepReady() {
      const stepReadinessFuncName = `isStep${this.step + 1}Ready`;
      return this[stepReadinessFuncName];
    },

    importedCsvHeaders() {
      return Object.keys(this.parsedCsv[0]);
    },

    rewardSupplierNameMap() {
      return this.rewardSuppliers.reduce((result, item) => {
        return {
          ...result,
          [item.id]: item.title,
        };
      }, {});
    },

    rewardCatalogueNameMap() {
      return this.rewardCatalogues.reduce((result, item) => {
        return {
          ...result,
          [item.id]: item.title,
        };
      }, {});
    },
  },

  methods: {
    clear() {
      this.parsedCsv = null;
      this.step = 1;
      this.newRewards = [];
      this.newRewardCategoriesArray = [];
      this.processingDetails = [];
      this.processSuccess = false;
      this.processFailed = false;
      (this.selectedRewardCatalogue = ""), (this.selectedRewardSupplier = "");
    },

    close() {
      this.$emit("onClose");
      this.clear();
    },

    getCsvRowError(row) {
      const errors = [];
      const titleValue = row[CSV_MAPPING.TITLE];
      const skuValue = row[CSV_MAPPING.SKU];

      if (!row[CSV_MAPPING.TITLE]) {
        errors.push(`${CSV_MAPPING.TITLE} is required`);
      }
      if (!row[CSV_MAPPING.BODY]) {
        errors.push(`${CSV_MAPPING.BODY} is required`);
      }
      if (!row[CSV_MAPPING.POINTS]) {
        errors.push(`${CSV_MAPPING.POINTS} is required`);
      }
      if (!row[CSV_MAPPING.REWARD_CATEGORIES]) {
        errors.push(`At least one category is required`);
      }
      if (skuValue && this.rewardSkusMap[skuValue]) {
        console.log("Existing SKU found");
        if (
          this.rewardTitlesMap[titleValue] &&
          this.rewardTitlesMap[titleValue] !== this.rewardSkusMap[skuValue]
        ) {
          errors.push(`${CSV_MAPPING.TITLE}} already exists`);
        }
      } else {
        console.log("Existing SKU NOT found");
        console.log("Title: ", titleValue);
        console.log(
          "No existing reward title found",
          this.rewardTitlesMap[titleValue]
        );
        if (this.rewardTitlesMap[titleValue]) {
          errors.push(`${CSV_MAPPING.TITLE} already exists`);
        }
      }
      console.log(errors);
      return errors;
    },

    getExistingReward(row) {
      const skuValue = row[CSV_MAPPING.SKU];

      if (skuValue && this.rewardSkusMap[skuValue]) {
        return this.rewardSkusMap[skuValue];
      }
      return null;
    },

    getExistingTitle(row) {
      const rewardTitle = row[CSV_MAPPING.TITLE].toLowerCase();

      if (rewardTitle && this.rewardTitlesMap[rewardTitle]) {
        return this.rewardTitlesMap[rewardTitle];
      }
      return null;
    },

    async gotoNext() {
      if (this.step === 1) {
        this.step += 1;
      } else if (this.step === 2) {
        const { newRewards, newRewardCategoriesArray } = this.parsedCsv
          .reduce((result, row) => {
            // Note: Remove duplication inside csv itself.
            const duplicated = result.find(
              (item) =>
                item[CSV_MAPPING.TITLE].toLowerCase() ===
                  row[CSV_MAPPING.TITLE].toLowerCase() ||
                (item[CSV_MAPPING.SKU] &&
                  item[CSV_MAPPING.SKU] === row[CSV_MAPPING.SKU])
            );

            return duplicated ? result : [...result, row];
          }, [])
          .reduce(
            (result, row) => {
              const errors = this.getCsvRowError(row);
              const isValid = !errors.length;
              const exRewardId = this.getExistingReward(row);
              const isUpdate = !!exRewardId;

              const tempRowRewardCategoriesArray = utils.parseByDelimeter(
                row[CSV_MAPPING.REWARD_CATEGORIES]
              );
              const rowRewardCategoriesArray = tempRowRewardCategoriesArray.map(
                (element) => {
                  return element;
                }
              );

              const newReward = {
                ...row,
                rowRewardCategoriesArray,
                isValid,
                isUpdate,
                exRewardId,
                logs: errors.join(","),
              };

              if (!isValid) {
                return {
                  ...result,
                  newRewards: [...result.newRewards, newReward],
                };
              }

              const newRewardCategoriesArray = utils.getNewArrayItems(
                this.rewardCategoriesArray.concat(
                  result.newRewardCategoriesArray
                ),
                rowRewardCategoriesArray
              );

              const logs = [
                `${newRewardCategoriesArray.length} new category(s)`,
              ];

              // Note: User id is not available until we create rewards in the db
              // Store sku which is unique to find the user id later.
              return {
                newRewards: [
                  ...result.newRewards,
                  { ...newReward, logs: logs.join(",") },
                ],
                newRewardCategoriesArray: [
                  ...result.newRewardCategoriesArray,
                  ...newRewardCategoriesArray,
                ],
              };
            },
            {
              newRewards: [],
              newRewardCategoriesArray: [],
            }
          );

        this.step += 1;
        this.newRewards = newRewards;
        this.newRewardCategoriesArray = newRewardCategoriesArray;
      } else if (this.step === 3) {
        this.step += 1;

        // Reward Categories
        this.processingDetails = ["Creating reward categories..."];
        try {
          console.log(this.newRewardCategoriesArray);
          this.$store.dispatch(
            "importRewardCategories",
            this.newRewardCategoriesArray
          ),
            (this.processingDetails = [
              ...this.processingDetails,
              "Prepared reward categories successfully.",
            ]);
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Preparing reward categories failed.",
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Saving new reward categories...",
        ];
        try {
          await this.$store.dispatch("loadRewardCategories");
          this.processingDetails = [
            ...this.processingDetails,
            "Saved new reward categories.",
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Saving reward categories failed.",
            console.log(e),
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Importing rewards...",
        ];

        const rewardsToImport = this.validNewRewards.map((rawReward) => {
          const title = rawReward[CSV_MAPPING.TITLE];
          const body = rawReward[CSV_MAPPING.BODY];
          const points = rawReward[CSV_MAPPING.POINTS];
          const sku = rawReward[CSV_MAPPING.SKU];
          const price = rawReward[CSV_MAPPING.PRICE];
          const externalImageUrl = rawReward[CSV_MAPPING.EXTERNAL_IMAGE_URL];
          const rewardCategories = rawReward.rowRewardCategoriesArray.map(
            (rawCategory) => this.rewardCategoriesMap[rawCategory]
          );

          if (rawReward.isUpdate) {
            const updateObject = {
              title: title,
              body: body,
              points: points,
              price: price ? price : "",
              externalImageUrl: externalImageUrl ? externalImageUrl : "",
              rewardSupplier: this.selectedRewardSupplier,
              rewardCatalogue: this.selectedRewardCatalogue,
              titleUppercase: title.toUpperCase(),
              sku: sku ? sku : "",
              isUpdate: rawReward.isUpdate,
              exRewardId: rawReward.exRewardId,
            };
            if (this.fieldExistency[CSV_MAPPING.REWARD_CATEGORIES]) {
              updateObject.rewardCategories = rewardCategories;
            }
            return updateObject;
          }
          return {
            status: "Inactive",
            title: title,
            body: body,
            points: points,
            price: price ? price : "",
            externalImageUrl: externalImageUrl ? externalImageUrl : "",
            rewardSupplier: this.selectedRewardSupplier,
            rewardCatalogue: this.selectedRewardCatalogue,
            titleUppercase: title.toUpperCase(),
            sku: sku ? sku : "",
            rewardCategories,
            isUpdate: rawReward.isUpdate,
            exRewardId: rawReward.exRewardId,
            companyTags: [],
            memberTags: [],
            image: {
              name: "",
              updated: "",
              smallUrl: "",
              mediumUrl: "",
              largeUrl: "",
              smallStoragePath: "",
              mediumStoragePath: "",
              largeStoragePath: "",
            },
          };
        });

        try {
          await this.$store.dispatch("importRewards", rewardsToImport);
          this.processingDetails = [
            ...this.processingDetails,
            "Prepared rewards data successfully.",
            (this.processSuccess = true),
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Preparing rewards data failed.",
            (this.processFailed = true),
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Saving new rewards...",
        ];
        try {
          await this.$store.dispatch("loadRewards");
          this.processingDetails = [
            ...this.processingDetails,
            "Saved new rewards successfully.",
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Saving new rewards failed.",
            (this.processFailed = true),
          ];
          return;
        }
      } else if (this.step === 4) {
        this.close();
      }
    },

    getRewardCatalogueName(rewardCatalogueId) {
      return this.rewardCatalogueNameMap[rewardCatalogueId];
    },

    getRewardSupplierName(rewardSupplierId) {
      return this.rewardSupplierNameMap[rewardSupplierId];
    },
  },
};
</script>

<style></style>

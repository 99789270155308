<template>
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa"
  >
    <v-row justify="center" no-gutters>
      <v-col xs="12" :sm="component.settings.cols">
        <v-sheet
          :height="height"
          tile
          :color="component.settings.color.hexa"
        >
          <template v-if="component.settings.mode === 'color'">
            <v-row 
              no-gutters
              class="fill-height"
            >
            </v-row>
          </template>
          
          <v-img
            v-if="component.settings.mode === 'image'"
            :src="component.background.image"
            :gradient="gradient"
            :height="imageHeight"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [ 'component', 'index' ],
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    gradient() {
      if (this.component.screen.type === 'gradient') {
        var screen = this.component.screen
        var gradient = 
        screen.direction + ', rgba(' +
        screen.fromColor.rgba.r + ', ' +
        screen.fromColor.rgba.g + ', ' +
        screen.fromColor.rgba.b + ', ' +
        screen.fromColor.rgba.a + ') ' + 
        '0%, rgba(' +
        screen.toColor.rgba.r + ', ' +
        screen.toColor.rgba.g + ', ' +
        screen.toColor.rgba.b + ', ' +
        screen.toColor.rgba.a + ') ' + screen.height + '%'
        return gradient
      } else if (this.component.screen.type === 'screen') {
        var screen2 = this.component.screen
        var screenColor = 
        screen2.direction + ', rgba(' +
        screen2.color.rgba.r + ', ' +
        screen2.color.rgba.g + ', ' +
        screen2.color.rgba.b + ', ' +
        screen2.color.rgba.a + '), rgba(' +
        screen2.color.rgba.r + ', ' +
        screen2.color.rgba.g + ', ' +
        screen2.color.rgba.b + ', ' +
        screen2.color.rgba.a + ')'
        return screenColor
      } else {
        return 'to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)'
      }
    },
    grid() {
      return this.$store.getters.grid
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates
    },
    height() {
      if (this.component.settings.heightContext == 'px') {
        return this.component.heightValue.px + 'px'
      } else {
        return this.component.heightValue.vh + 'vh'
      }
    },
    imageHeight() {
      if (this.component.settings.heightContext == 'px') {
        return (this.component.heightValue.px + this.component.overlap.px) + 'px'
      } else {
        return (this.component.heightValue.vh + this.component.overlap.vh) + 'vh'
      }
    },
    image() {
      if (this.component.settings.mode === 'color') {
        return ''
      } else {
        return this.component.background.image
      }
    },
  },
  methods: {
    handleEditor(val) {
      this.editing = val
      if (val === 'preview') {
        this.previewCols = 11
      } else {
        this.previewCols = 8
      }
    },
    handleSave() {
      this.$store.dispatch('updateWebTheme', this.webTheme)
      this.$store.dispatch('setHasWebThemePendingUpdates', false)
      this.$store.dispatch('editingField', {})
    },
  }
}
</script>

<style scoped>

</style>


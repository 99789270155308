<template>
  <v-dialog
    v-model="openLiveSiteDialog"
    style="z-index: 3000"
    :close-on-content-click="false"
    scrollable
    max-width="500"
    persistent
  >
    <v-card>
      <v-progress-linear
        v-if="loadingTable"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-row no-gutters>
        <v-col cols="auto" class="incentable-form-heading ml-6 mt-6">
          View Live Site
        </v-col>

        <v-col class="incentable-form-heading mt-6 mr-6">
          <v-row no-gutters justify="end">
            <a :href="domain.index" target="_blank" class="text">
              {{ domain.index }}
            </a>
            <v-icon
              @click="copyUrl(domain.index)"
              color="primary"
              class="ml-1"
              size="20"
              >content_copy</v-icon
            >
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class="incentable-form-heading mt-10" justify="center">
        <div>
          <qrcode-vue
            id="picture"
            ref="code"
            className="qrcode"
            :value="domain.index"
            size="125"
            level="H"
          ></qrcode-vue>
          <qrcode-vue
            v-show="false"
            id="hires-picture"
            ref="code"
            className="hires-qrcode"
            :value="domain.index"
            size="500"
            level="H"
          ></qrcode-vue>
        </div>
      </v-row>
      <v-row
        no-gutters
        class="incentable-form-subheading mb-n3"
        justify="center"
      >
        <span>Download QR</span>
      </v-row>
      <v-row
        no-gutters
        class="incentable-form-subheading mb-5"
        justify="center"
      >
        <span
          @click="downloadQr('picture')"
          class="primary--text"
          style="cursor: pointer"
          >Web</span
        >
        <span class="mx-2" style="cursor: pointer"> | </span>
        <span
          @click="downloadQr('hires-picture')"
          class="primary--text"
          style="cursor: pointer"
          >Print</span
        >
      </v-row>
      <v-row no-gutters class="ml-6 mt-3 mb-5">
        <v-col sm="auto" class="mr-6">
          <a
            v-if="currentProgram.signinOpen"
            :href="domain.signin"
            target="_blank"
            class="link"
          >
            <v-icon color="primary" size="20">open_in_new</v-icon>
            Signin
          </a>
          <span v-else>
            <v-icon size="20">highlight_off</v-icon>
            Signin
          </span>
        </v-col>
        <v-col sm="auto" class="mr-6">
          <a
            v-if="currentProgram.registrationOpen"
            :href="domain.register"
            target="_blank"
            class="link"
          >
            <v-icon color="primary" size="20">open_in_new</v-icon>
            Register
          </a>
          <span v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"
                  ><v-icon class="mr-1" size="20">highlight_off</v-icon
                  >Register</span
                >
              </template>
              <span
                >Registration is currently inactive<br />This can be changed in
                Admin settings</span
              >
            </v-tooltip>
          </span>
        </v-col>
      </v-row>

      <template v-if="!loadingTable">
        <v-divider></v-divider>
        <v-row v-if="!loadingTable" no-gutters class="ml-6 mt-2">
          <v-col class="incentable-form-heading mt-2">
            Open As A Member
            <v-row class="incentable-form-subheading" no-gutters>
              Select a member below to see their view
            </v-row>
          </v-col>
          <v-col>
            <v-text-field
              v-model="memberSearch"
              solo-inverted
              dense
              flat
              hide-details
              label="Search By Name"
              prepend-inner-icon="search"
              class="mr-2 mt-2 px-2"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-card-text
          v-if="membersList.length"
          class="mt-4 px-4"
          style="max-height: 313px"
        >
          <v-row
            v-for="member in membersList"
            :key="member.email"
            no-gutters
            class="ml-5 mb-4 member-link"
            @click="openWebsite(member)"
          >
            <v-col sm="auto">
              <v-icon class="mr-1 list-icon" color="primary"
                >account_circle</v-icon
              >
            </v-col>
            <v-col sm="auto">
              <span class="list-name">
                {{ member.firstname }} {{ member.lastname }}
              </span>
            </v-col>
            <v-col class="list-email">
              {{ member.email }}
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn
              color="primary"
              elevation="0"
              @click="loadMore()"
              :disabled="loaded"
              >load more</v-btn
            >
          </v-row>
        </v-card-text>
        <v-card-text v-if="!loadingTable && !members.length">
          <v-row no-gutters class="ml-3 mt-3">
            <v-col sm="auto">
              <v-icon class="mr-1 list-icon" color="primary">info</v-icon>
            </v-col>
            <v-col> No Members Found </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
      </template>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="handleClose()">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
  },
  props: ["openLiveSiteDialog"],
  data() {
    return {
      selectedMember: "",
      memberSearch: "",
      page: 1,
      perPage: 5,
    };
  },
  created() {},
  computed: {
    sites() {
      return this.$store.state.site.sites;
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    programId() {
      return this.$store.getters.programId;
    },
    members() {
      return this.$store.state.member.members;
    },
    loaded() {
      return this.membersList.length === this.members.length;
    },
    membersList() {
      const array = this.members;
      if (!this.memberSearch.length) {
        const loadedMembers = array.slice(0, this.page * this.perPage);
        return loadedMembers;
      } else {
        return array.filter((el) =>
          el.fullnameUppercase.includes(this.memberSearch.toUpperCase())
        );
      }
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    domain() {
      if (this.programId) {
        const url = this.sites.find(
          (el) => el.program === this.programId && el.status === "Live"
        ).activeDomain;
        return {
          index: "https://" + url,
          register: "https://" + url + "/signup",
          signin: "https://" + url + "/signin",
        };
      }
      return "";
    },
  },
  methods: {
    downloadQr(id) {
      let myCanvas = document.getElementById(id).getElementsByTagName("canvas");
      let a = document.createElement("a");
      a.href = myCanvas[0]
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      a.download = this.currentProgram.title + " QR Code.png";
      a.click();
    },
    loadMore() {
      this.page = this.page + 1;
    },
    copyUrl(mytext) {
      try {
        navigator.clipboard.writeText(mytext);
        this.$store.dispatch("setSnackbar", "Copied to clipboard");
      } catch (e) {
        console.log(e);
      }
    },
    openWebsite(item) {
      this.page = 1;
      this.id = item.id;
      this.$store.dispatch("openWeb", this.id);
      this.$emit("onClose");
      this.clear();
    },
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    clear() {
      this.page = 1;
      this.selectedMember = "";
      this.memberSearch = "";
    },
  },
  watch: {},
};
</script>

<style scoped>
.live-site-btn {
  z-index: 200 !important;
  margin-bottom: 56px;
  margin-right: 80px;
}
.v-dialog__content {
  align-items: flex-end;
  justify-content: flex-end;
}
.member-link {
  cursor: pointer;
}
.list-name {
  font-weight: 600 !important;
  margin-right: 6px !important;
}
.list-email {
  font-size: 13px;
  font-weight: 300 !important;
}
.list-icon {
  margin-top: -1px;
}
</style>

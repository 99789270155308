<template>    
  <div>
    <v-row no-gutters class="mt-9 mb-16" justify="center">
      <v-col cols="3">
        <v-row no-gutters class="mb-3" justify="center">
          <v-icon color="green" size="60">fa-solid fa-file-csv</v-icon>
        </v-row>
        <v-row no-gutters class="mb-3 page-heading" justify="center">
          {{ newCsvTargetFileName }}
        </v-row>
        <v-row no-gutters class="incentable-page-subheading" justify="center" align="center">
          <v-icon color="green">check_circle</v-icon> {{ csvBody.length }} records found
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row no-gutters class="mb-3" justify="center">
          <v-icon color="grey lighten-1" size="60">fa-solid fa-right-long</v-icon>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-row no-gutters class="mb-3" justify="center">
          <v-icon color="primary" size="60">fa-solid fa-database</v-icon>
        </v-row>
        <v-row no-gutters class="mb-3 page-heading" justify="center">{{ databucket.title }} Data Bucket</v-row>
        <v-row no-gutters class="incentable-page-subheading" justify="center" align="center">
          <v-icon color="green">check_circle</v-icon> 
          {{ csvBody.length - unmatched.length }} records matched
        </v-row>
        <v-row v-if="unmatched.length > 0" no-gutters class="incentable-page-subheading mt-3" justify="center" align="center">
          <v-icon color="amber">info</v-icon> 
          {{ unmatched.length }} records not matched
        </v-row>
        <v-row v-if="unmatched.length > 0" no-gutters class="incentable-page-subheading mt-3" justify="center" align="center">
          <a :href="unmatchedCsv" :download="newCsvTargetFileName">Download unmatched records</a>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  created() {
    
  },
  data: function () {
    return {
      isProcessing: false,
    }
  },

  computed: {
    csvData() {
      return this.$store.state.databucket.csvTargetData || []
    },

    newCsvTargetFileName() {
      return this.$store.state.databucket.newCsvTargetFileName
    },
    
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    
    databucket() {
      return this.$store.state.databucket.databucket;
    },

    targetCodes() {
      return this.databucket.targetCodes;
    },

    entityMap() {
      return this.$store.getters.databucketEntityMap;
    },

    targetHeaders() {
      return ['Account Key', ...this.targetCodes];
    },

    targetMapping() {
      return this.databucket.targetMapping || {};
    },

    csvHeaders() {
      return this.csvData.length ?  this.csvData[0] : [];
    },

    csvBody() {
      return this.csvData.length ?  this.csvData.slice(1) : [];
    },

    unmatched() {
      const accountKeyIndex = this.csvHeaders.findIndex(header => header === this.targetMapping['Account Key']);

      return accountKeyIndex !== -1
        ? this.csvBody.filter((item) => {
          return !this.entityMap[item[accountKeyIndex]];
        })
        : this.csvBody;
    },

    unmatchedCsv() {
      const { unmatched } = this;
      const dataHeader = 'data:text/csv;charset=utf-8,';
      const csvHeader = this.csvHeaders.join(',');
      const csv = unmatched.reduce((result, row, index) => {
        const rowString = row.join(',');
        if (unmatched.length - 1 === index) {
          return `${result}${rowString}`;
        }
        return `${result}${rowString}\r\n`;
      }, `${dataHeader}${csvHeader}\r\n`);

      return csv;
    },
  },

  methods: {  
  },
  watch: {
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="px-4 pt-4">
    <form data-vv-scope="notification">
      <v-row no-gutters>
        <v-col lg="5" class="pr-16">
          <v-row class="incentable-form-heading" no-gutters> To </v-row>
          <v-row class="incentable-form-subheading" no-gutters>
            Filter recipients by Status and Tag(s)
          </v-row>
          <v-row no-gutters>
            <v-select
              v-model="selectedMemberStatus"
              :items="statusList"
              label="Member Status"
              chips
              multiple
              deletable-chips
              append-icon="person"
            />
          </v-row>
          <v-row no-gutters>
            <v-col class="mr-3">
              <v-select
                v-model="selectedCompanyTags"
                :items="companyTags"
                item-text="tag"
                item-value="id"
                label="Company Tags"
                chips
                multiple
                deletable-chips
                append-icon="label"
              ></v-select>
            </v-col>
            <v-col class="ml-3">
              <v-select
                v-model="selectedMemberTags"
                :items="memberTags"
                item-text="tag"
                item-value="id"
                label="Member Tags"
                persistent-hint
                chips
                multiple
                deletable-chips
                append-icon="label"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="incentable-form-heading" no-gutters> Content </v-row>
          <v-row class="incentable-form-subheading" no-gutters>
            Enter content below. Click on the image preview on the right to
            change the email banner
          </v-row>
          <v-row no-gutters>
            <v-text-field
              v-validate="'required'"
              :error-messages="errors.collect('notification.heading')"
              v-model="heading"
              type="text"
              name="heading"
              label="Heading"
            />
          </v-row>
          <v-row no-gutters>
            <v-textarea
              v-validate="'required|max:160'"
              :error-messages="errors.collect('notification.body')"
              v-model="body"
              name="body"
              type="text"
              label="Message"
              counter="160"
            />
          </v-row>
          <v-row no-gutters justify="end" class="mt-4">
            <v-btn text @click="clear()">Clear</v-btn>
            <v-btn
              @click="sendNotification('notification')"
              color="primary"
              :loading="loading"
              elevation="0"
              :disabled="!disabled"
            >
              Send
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="12" lg="7">
          <v-row no-gutters>
            <v-col>
              <v-row justify="center" no-gutters>
                <v-sheet
                  width="315px"
                  height="657px"
                  class="sheet"
                  color="secondary"
                >
                  <v-img
                    class="mt-0"
                    width="315px"
                    height="657px"
                    :src="require('@/assets/lockscreen.jpg')"
                    gradient="180deg, rgba(0,0,0,0.50) 16%, rgba(255,255,255,0) 50%"
                  >
                    <v-row no-gutters>
                      <v-col>
                        <v-row class="mt-12" justify="center">
                          <v-icon color="white" size="30">lock</v-icon>
                        </v-row>
                        <v-row class="clock" justify="center">
                          {{ date | time }}
                        </v-row>
                        <v-row class="date" justify="center">
                          {{ date | longdate }}
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="phone-row">
                      <v-card width="288px" flat class="n-card">
                        <v-row class="py-4" no-gutters>
                          <v-col sm="1" class="n-program-logo pl-2">
                            <v-img
                              :src="require('@/assets/favicon-32x32.png')"
                              height="20px"
                              contain
                              left
                            ></v-img>
                          </v-col>
                          <v-col sm="11" class="n-program pl-2">
                            {{ currentProgram.title }}
                          </v-col>
                        </v-row>

                        <v-card-title class="n-heading">
                          {{ heading || "Heading" }}
                        </v-card-title>
                        <v-card-text class="n-body">
                          {{ body || "Message" }}
                        </v-card-text>
                      </v-card>
                    </v-row>
                  </v-img>
                </v-sheet>

                <v-img
                  :src="require('@/assets/app_frame.svg')"
                  width="460px"
                  class="phone"
                >
                </v-img>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import { MEMBER_STATUS } from "@/constants/member";
export default {
  $_veeValidate: {
    validator: "new",
  },

  data: () => ({
    tableTitle: "New App notification",
    tableSubtitle: "Send notifications to member with the App installed",
    tableIcon: "announcement",
    heading: "",
    body: "",
    selectedCompanyTags: [],
    selectedMemberTags: [],
    dictionary: {
      custom: {
        heading: {
          required: () => "The heading is required",
        },
        body: {
          required: () =>
            "You cannot send a blank notification. Some message text is required",
          max: "Your message must be 160 characters or less",
        },
      },
    },
    statusList: MEMBER_STATUS,
    selectedMemberStatus: ["Active"],
  }),

  mounted() {
    this.$validator.localize("en", this.dictionary);
  },

  computed: {
    disabled() {
      if (this.currentProgram.subscriptionStatus !== "Current") {
        return false;
      } else if (
        this.currentProgram.iosAppStatus === "Active" ||
        this.currentProgram.androidAppStatus === "Active"
      ) {
        return true;
      } else {
        return false;
      }
    },
    loading() {
      return this.$store.getters.loading;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    previewObj() {
      return {
        heading: this.heading,
        body: this.body,
      };
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    date() {
      return Date();
    },
    time() {
      return Date();
    },
  },

  created() {},

  methods: {
    clear() {
      this.heading = "";
      this.body = "";
      (this.selectedCompanyTags = []),
        (this.selectedMemberTags = []),
        this.$validator.reset();
    },

    sendNotification(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }

        const notification = {
          heading: this.heading,
          body: this.body,
          companyTags: this.selectedCompanyTags,
          memberTags: this.selectedMemberTags,
          memberStatus: this.selectedMemberStatus,
          type: "app",
          status: "new",
        };

        this.$store
          .dispatch("createNotification", notification)
          .then(() => this.clear())
          .catch(() => {});
      });
    },
  },
};
</script>

<style scoped>
.clock {
  font-family: "Work Sans", sans-serif;
  font-size: 60px !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  margin-top: 30px;
}
.date {
  font-family: "Work Sans", sans-serif;
  font-size: 18px !important;
  font-weight: 300 !important;
  color: #ffffff !important;
  margin-top: 20px;
}
.phone-row {
  margin-top: 20px;
  margin-left: 15px;
}
.phone {
  position: absolute;
  z-index: 2;
}
.sheet {
  z-index: 0;
  margin-left: 0px;
  margin-top: 68px;
}
.n-card {
  opacity: 0.7;
}
.n-program-logo {
  margin-top: -8px;
  margin-left: 0px;
}
.n-program {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #000000 !important;
  margin-top: -10px;
  margin-left: -5px;
}
.n-heading {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  color: #000000 !important;
  margin-top: -40px;
  margin-left: -5px;
}
.n-body {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px !important;
  overflow-wrap: break-word;
  color: #000000 !important;
  margin-top: -22px;
  margin-left: -5px;
  line-height: 1.2em;
  margin-bottom: -5px;
}
.n-footer {
  font-family: Sans-Serif !important;
  font-size: 12px !important;
  color: #616161 !important;
}
</style>

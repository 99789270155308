<template>
  <div>
    <v-navigation-drawer
      app
      right
      :value="howTo"
      :width="width"
      clipped
      stateless
      floating
      color="secondary"
      class="el"
      dark
      flat
    >
      <v-row>
        <v-row class="table-title mt-6 ml-9 white--text">How To Videos</v-row>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mr-6 mt-2 btn"
          @click="handleClose()"
          >
          <v-icon>close</v-icon>
        </v-btn>
      </v-row>

      <v-tabs>
        <v-tab class="tab">
          Apps
        </v-tab>
        <v-tab class="tab">
          Members
        </v-tab>
        <v-tab class="tab">
          Companies
        </v-tab>
        <v-tab class="tab">One</v-tab>
        <v-tab class="tab">Two</v-tab>
        <v-tab class="tab">Three</v-tab>
        <v-tab class="tab">Four</v-tab>
        <v-tab class="tab">Five</v-tab>
        <v-tab class="tab">Six</v-tab>
        <v-tab class="tab">Seven</v-tab>
        <v-tab class="tab">Eight</v-tab>
        <v-tab class="tab">Nine</v-tab>
        <v-tab class="tab">Ten</v-tab>
        <v-tab class="tab">Eleven</v-tab>
        <v-tab class="tab">Twelve</v-tab>
        <v-tab class="tab">Thirteen</v-tab>
        <v-tab class="tab">Fourteen</v-tab>
        <v-tab class="tab">Fifteen</v-tab>
        <v-tab class="tab">Sixteen</v-tab>
        <v-tab class="tab">Seventeen</v-tab>
        <v-tab class="tab">Eighteen</v-tab>
        <v-tab class="tab">Nineteen</v-tab>
        <v-tab class="tab">Twenty</v-tab>

        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
          class="mt-3 px-3"
          >
          <v-row no-gutters class="incentable-form-heading white--text">
            <v-col>
              Video
            </v-col> 
          </v-row>

          <v-row class="incentable-form-subheading" no-gutters>
            A play button will appear on the article image when a YouTube code is added below is addedd</v-row>

          <v-row>
            <v-col justify="center" lg="12">
              <iframe
                width="480"
                height="270"
                src="https://www.youtube-nocookie.com/embed/AwBSto_PBIQ"
                frameborder="0"
                allowfullscreen
                class="rounded"
                >
              </iframe>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>

      <v-footer
        absolute
        padless
        dark
        color="secondary"
        inset
        >
        <v-col
          class="caption text-center grey--text"
          cols="12"
        >Theme preview only. See live app or website for all functionality</v-col>
      </v-footer>

    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      width: 500
    }
  },
  computed: {
    howTo () {
      return this.$store.state.howTo
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    currentProgram () {
      return this.$store.state.program.currentProgram;
    },
  },
  methods: {
    handleClose () {
      this.$store.dispatch('setHowTo', false)
    },
  }
}

</script>

<style scoped>
.phone {
  position: absolute;
  z-index: 2;
}
.sheet {
  z-index: 0;
  margin-left: 0px;
  margin-top: 68px;
}
.article-preview-footer {
  font-size: 12px;
}
.btn {
  z-index: 1;
}
.article-preview-heading {
  font-family: 'Lato', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: white !important;
}
.article-preview-text {
  font-family: 'Lato', sans-serif !important;
  font-size: 12px !important;
  font-weight: 100 !important;
  color: rgb(175, 175, 175) !important;
}
.article-preview-footer {
  font-family: 'Lato', sans-serif !important;
  font-size: 10px !important;
  font-weight: 100 !important;
  color: rgb(100, 100, 100) !important;
}
.app-preview-title {
  font-family: 'Lato', sans-serif !important;
  font-size: 14px !important;
  font-weight: 100 !important;
  color: rgb(231, 231, 231) !important;
}
</style>






<template>
  <div>
    <v-card class="el rounded-lg" height="220">
      <v-progress-linear
        indeterminate
        :active="loadingMembers"
        :color="orgTheme.primary"
      >
      </v-progress-linear>

      <v-row
        justify="space-between"
        no-gutters
        class="mx-3 mt-3 mb-n2"
        style="height: 30px"
      >
        <v-col cols="auto">
          <v-icon class="mr-1 mt-n1" size="22" :color="chartLineColor">{{
            icon
          }}</v-icon>
          <span class="incentable-form-heading">
            {{ title }}
          </span>
        </v-col>
        <v-col v-if="sorted.length > 0" class="text-right">
          <span class="dash-card"> Latest </span>
          <v-icon color="#505050" class="icon" size="21">{{ icon }}</v-icon>
          <span class="dash-card">
            {{ latest().title }}
          </span>
          <v-icon color="#505050" class="icon" size="21">history</v-icon>
          <span class="dash-card mr-1">
            {{ latest().created }}
          </span>
        </v-col>
      </v-row>
      <chart-line
        v-if="values.length > 0"
        :chartData="chartData"
        :orgTheme="orgTheme"
        :styles="chartStyles"
        :chartFillColor="chartFillColor"
        :chartLineColor="chartLineColor"
      />
    </v-card>
  </div>
</template>

<script>
import ChartLine from "./ChartLine.vue";
import moment from "moment";

export default {
  components: {
    ChartLine,
  },
  props: ["records", "icon", "title", "chartFillColor", "chartLineColor"],
  data: function () {
    return {
      chartStyles: {
        height: "195px",
        padding: "16px",
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        data: this.values,
      };
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    values() {
      const reducer = (map, val) => {
        if (map[val] == null) {
          map[val] = 1;
        } else {
          ++map[val];
        }
        return map;
      };
      const result = Object.values(
        this.sorted
          .map((el) => moment(el.created).format("MMM-YY"))
          .reduce(reducer, {})
      );
      return result;
    },
    labels() {
      const reducer = (map, val) => {
        if (map[val] == null) {
          map[val] = 1;
        } else {
          ++map[val];
        }
        return map;
      };
      const result = Object.keys(
        this.sorted
          .map((el) => moment(el.created).format("MMM-YY"))
          .reduce(reducer, {})
      );
      return result;
    },
    loadingMembers() {
      return this.$store.getters.loadingMembers;
    },
    sorted() {
      const arr = [...this.records];
      var sorted = arr.sort(function (x, y) {
        return x.created - y.created;
      });
      return sorted;
    },
  },
  methods: {
    latest() {
      const obj = this.sorted[this.sorted.length - 1];
      return {
        title: obj.firstname + " " + obj.lastname || "",
        created: moment(obj.created).fromNow(),
      };
    },
  },
};
</script>

<style></style>

<template>
  <v-card 
    elevation="0"
    class="px-3"
  >

    <table-column-mapper
      :sourceColumns="sourceColumns"
      mapping="column"
      :csvColumns="csvHeaders"
      @onAdd="handleAddColumn"
    />

    <v-row no-gutters class="mt-6 mb-6" justify="center">
      <table>
        <thead>
          <draggable
            v-model="resultsHeaderMappings"
            tag="tr"
            :disabled="demo"
          >
            <th
              v-for="(header, colIndex) of resultsHeaderMappings"
              :key="header.label + colIndex"
              scope="col"
              class="incentable-leaderboard-table-title-row movable"
            >
              <v-row no-gutters>
                <v-icon class="ml-n4 mr-1 mt-n1" color="grey">drag_indicator</v-icon>
                {{ header.label }}
              </v-row>
              <v-row no-gutters class="incentable-page-subheading grey--text ml-3">
                <v-icon color="grey" size="12" class="mr-1 mt-n1">subdirectory_arrow_right</v-icon>
                {{ header.column }}
              </v-row>
            </th>
          </draggable>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIndex) of accountColumnData"
              :key="rowIndex"
          >
            <td
              v-for="(header, colIndex) of resultsHeaderMappings"
              :key="header.label + colIndex"
              align="center"
              class="table-cell"
            >
                <div v-if="header.column === 'Icon'">
                  <div v-if="row.isWinner && iconMappings.svgFile" class="mapping-icon-card secondary rounded">
                    <img :src="'/img/icons/' + iconMappings.svgFile" class="mapping-icon"/>
                  </div>
                </div>
                <div v-else-if="header.type === 'qualifier'">
                  <v-img :src="require(`@/assets/${accountColumnData[rowIndex][header.column]}`)"  class="mapping-icon-qualifier" />
                </div>
                <span v-else>
                  {{ accountColumnData[rowIndex][header.column] }}
                </span>
            </td>
          </tr>
        </tbody>
      </table>
    </v-row>
    <v-row
      v-if="!previewAccountKey || accountColumnData.length === 0"
      class="my-8"
      justify="center"
    >
      <v-icon size="40" color="primary">{{ getIcon }}</v-icon>
      <span class="incentable-page-subheading mt-1 ml-3">
        To see real data, select an account <br>in the 'Preview Data Of' field above
      </span>
    </v-row>
  </v-card>
</template>

<script>
import TableColumnMapper from './ColumnMapper.vue'
export default {
  components: {
    TableColumnMapper
  },
  data: function () {
    return {
      sortDirs: [
        { label: 'Ascending', value: 'asc' },
        { label: 'Descending', value: 'desc' },
      ],
      openIconSelector: false,
    }
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    previewAccountKey () {
      return this.$store.getters.previewAccountKey
    },
    currentTable() {
      return this.$store.state.table.currentTable;
    },

    csvHeaders() {
      return this.$store.getters.tableCsvHeaders;
    },
    systemHeaders() {
      return this.$store.getters.tableSystemHeaders
    },

    sourceColumns() {
      var label = ''
      if (this.currentTable.mode === 'dynamic') {
        label = 'Data Bucket Fields'
      } else {
        label = 'CSV Columns'
      }
      return [
        { header: 'System Fields'},
        ...this.systemHeaders,
        { header: label},
        ...this.csvHeaders,
      ];
    },

    resultsHeaderMappings: {
      get() {
        return this.currentTable.resultsHeaderMappings;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { resultsHeaderMappings: value });
      }
    },

    columnData() {
      return this.$store.getters.tableColumnData;
    },

    accountColumnData() {
      const participant = this.columnData.find(item => item.accountKey === this.previewAccountKey);
      return participant ? participant.data : [];
    },

    getIcon () {
      if (this.currentTable.participantType === 'member') {
        return 'account_circle'
      } else {
        return 'business'
      }
    },

  },

  methods: {
    handleAddColumn(newMapping) {
      const resultsHeaderMappings = [
        ...this.resultsHeaderMappings,
        newMapping,
      ]

      this.$store.dispatch('patchCurrentTable', { resultsHeaderMappings })
    },

    handleRemove(index) {
      const resultsHeaderMappings = [
        ...this.resultsHeaderMappings.slice(0, index),
        ...this.resultsHeaderMappings.slice(index + 1)
      ]

      this.$store.dispatch('patchCurrentTable', { resultsHeaderMappings });
    }
  }
}
</script>

<style scoped>
.add-line {
  padding-left: 1px
}

.ghost {
  border: 1px dotted #ccc;
}

.mapping-item {
  cursor: move;
}

.mapping-icon {
  width: 32px;
  height: 32px;
  padding-top: 0px;
}

.mapping-icon-card {
  width: 35px;
  height: 35px;
}

.mapping-icon-qualifier {
  width: 25px;
  height: 25px;
}

</style>

/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
import _ from "lodash";

const state = {
  rewards: [],
  loadingRewards: false,
  processingRewardsCsv: false,
};

const actions = {
  async loadRewards({ commit, getters }) {
    commit("setLoadingTable", true);
    commit("setLoadingRewards", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewards")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const rewards = [];
    querySnapshot.forEach(function (doc) {
      rewards.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
        image: {
          ...doc.data().image,
        },
      });
    });

    commit("setRewards", rewards);
    commit("setLoadingTable", false);
    commit("setLoadingRewards", false);
  },

  async createReward({ commit, getters }, payload) {
    const rewardsCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("rewards");

    let existingRewardResult;
    try {
      existingRewardResult = await rewardsCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "error when fetching existing reward";
    }

    if (existingRewardResult.size > 0) {
      return "duplication";
    }

    const reward = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newRewardRef;
    try {
      newRewardRef = await rewardsCollection.add(reward);
    } catch (e) {
      throw "error when creating a new reward";
    }

    // Note: server time is unavailable until we refetch.
    const tempReward = {
      ...reward,
      id: newRewardRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createReward", tempReward);
    commit("setSnackbar", "Reward added");

    return "ok";
  },

  async updateReward({ commit, getters }, payload) {
    const rewardsCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("rewards");

    let existingRewardResult;
    try {
      existingRewardResult = await rewardsCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "error when fetching existing reward";
    }

    let duplicated = false;
    existingRewardResult.forEach((doc) => {
      if (doc.id !== payload.id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    const reward = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await rewardsCollection.doc(payload.id).update(reward);
    } catch (e) {
      throw "error when updating an reward";
    }

    const rewardBeforeUpdate = getters.rewards.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempReward = {
      ...rewardBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updateReward", tempReward);
    commit("setSnackbar", "Reward updated");

    return "ok";
  },

  async deleteReward({ commit, getters, dispatch }, id) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewards")
        .doc(id)
        .delete();
    } catch (e) {
      dispatch("setLoading", false);
      throw "error when deleting an reward";
    }
    commit("deleteReward", id);
    commit("setSnackbar", "Reward deleted");
    dispatch("setLoading", false);
  },

  async importRewards({ getters }, newRewards) {
    console.log(newRewards);
    console.log(getters.programId);
    // Note: Due to firebase's limit of Max 500 writes per batch
    // We are gonna split rewards array into several chunks having 500 max rewards
    const rewardsChunks = _.chunk(newRewards, 240);
    console.log(rewardsChunks);
    for (let i = 0; i < rewardsChunks.length; i += 1) {
      const chunk = rewardsChunks[i];
      const newRewardsBatch = db.batch();
      chunk.forEach((reward) => {
        const { isUpdate, exRewardId, ...rewardData } = reward;
        if (!isUpdate) {
          const dataWithTimestamp = {
            ...rewardData,
            created: new Date(),
            updated: new Date(),
            updatedBy: getters.user.id,
            createdBy: getters.user.id,
          };
          const newRewardRef = db
            .collection("programs")
            .doc(getters.programId)
            .collection("rewards")
            .doc();
          newRewardsBatch.set(newRewardRef, dataWithTimestamp);
        } else {
          const dataWithTimestamp = {
            ...rewardData,
            updated: new Date(),
            updatedBy: getters.user.id,
          };
          const updateRewardRef = db
            .collection("programs")
            .doc(getters.programId)
            .collection("rewards")
            .doc(exRewardId);
          newRewardsBatch.update(updateRewardRef, dataWithTimestamp);
        }
      });

      try {
        await newRewardsBatch.commit();
      } catch (e) {
        console.error(e);
        throw `rewards import batch failed - ${i + 1}st batch`;
      }
    }
  },

  setProcessingRewardsCsv({ commit }, payload) {
    commit("setProcessingRewardsCsv", payload);
  },
};

const mutations = {
  setRewards(state, payload) {
    state.rewards = payload;
  },

  setLoadingRewards(state, payload) {
    state.loadingRewards = payload;
  },

  setProcessingRewardsCsv(state, payload) {
    state.processingRewardsCsv = payload;
  },

  createReward(state, payload) {
    state.rewards = [...state.rewards, payload];
  },

  updateReward(state, payload) {
    state.rewards = state.rewards.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteReward(state, payload) {
    state.rewards = state.rewards.filter((item) => item.id !== payload);
  },
};

const getters = {
  rewards(state) {
    return state.rewards;
  },
  loadingRewards(state) {
    return state.loadingRewards;
  },
  processingRewardsCsv(state) {
    return state.processingRewardsCsv;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="px-4">
    <v-card class="pa-6 el mr-6">
      <v-row no-gutters v-if="passRate" align="center">
        <v-icon color="primary" class="mr-1">stars</v-icon>
        {{ passPoints ? passPoints : 0 | number }} points will be awarded when a
        member achieves a success rate of {{ passRate }}%
      </v-row>
      <v-row no-gutters v-else>
        No points will be awarded as a pass rate is not set
      </v-row>
      <v-row no-gutters v-if="questionPoints > 0" align="center">
        <v-icon color="primary" class="mr-1">stars</v-icon>
        Up to {{ questionPoints | number }} points are available to be awarded
        on correct answers
      </v-row>
      <v-row no-gutters v-else>
        There are no points for correct answers as no points are configured on
        correct answers
      </v-row>
      <v-row no-gutters v-if="maximumPoints > 0" align="center">
        <v-icon color="primary" class="mr-1">stars</v-icon>
        A total of {{ maximumPoints | number }} points are available from this
        quiz
      </v-row>
      <v-row no-gutters class="mt-9">
        <v-col cols="3" class="mr-6">
          <v-text-field
            @input="changed()"
            label="Pass rate %"
            type="number"
            name="passRate"
            v-model="passRate"
            :rules="[rules.required]"
          />
          <v-text-field
            @input="changed()"
            label="Points awarded for passing"
            type="number"
            name="passPoints"
            v-model="passPoints"
            :rules="[rules.required]"
          />
          <v-row no-gutters>
            <v-checkbox
              @click="changed()"
              label="Redeemable points"
              v-model="redeemable"
            ></v-checkbox>
          </v-row>
          <v-row no-gutters>
            <!-- Data bucket points -->
            <v-checkbox
              @click="changed()"
              label="Add points to a Data Bucket"
              v-model="addToDatabucket"
            ></v-checkbox>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!databucketId && !targetCode && addToDatabucket">
        <v-col cols="5">
          <v-alert type="info" text color="primary" border="left">
            Please select a Data Bucket and Category to save change
          </v-alert>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3">
          <v-select
            :disabled="!addToDatabucket"
            @input="changed()"
            v-model="databucketId"
            :items="databuckets"
            item-text="title"
            item-value="id"
            label="Data Bucket"
            name="databucketId"
          />
          <v-select
            :disabled="!addToDatabucket"
            @input="changed()"
            v-model="targetCode"
            :items="targetCodes"
            label="Data Bucket Category"
            name="targetCode"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["quizId"],
  data: function () {
    return {
      passPoints: 0,
      passRate: 0,
      addToDatabucket: false,
      databucketId: null,
      targetCode: null,
      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },
  created() {
    if (this.quizId) {
      this.passRate = this.quiz.passRate;
      this.passPoints = this.quiz.passPoints;
      this.addToDatabucket = this.quiz.databucket.addToDatabucket;
      this.databucketId = this.quiz.databucket.databucketId;
      this.targetCode = this.quiz.databucket.targetCode;
      this.redeemable = this.quiz.redeemable;
    }
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    quiz() {
      return this.$store.state.quiz.quiz;
    },
    databuckets() {
      return this.$store.state.databuckets.databuckets;
    },
    targetCodes() {
      if (this.databucketId) {
        const databucket =
          this.databuckets.find((el) => el.id === this.databucketId) || "";
        return databucket.targetCodes;
      }
      return [];
    },
    questionPoints() {
      const questions = this.quiz.questions;
      return questions.reduce((totalPoints, question) => {
        if (question.answers && Array.isArray(question.answers)) {
          totalPoints += question.answers.reduce((questionTotal, answer) => {
            if (answer.correct) {
              questionTotal += answer.points || 0;
            }
            return questionTotal;
          }, 0);
        }
        return totalPoints;
      }, 0);
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    quizChanged() {
      return this.$store.state.quiz.quizChanged;
    },
    maximumPoints() {
      return +this.questionPoints + +this.passPoints;
    },
  },
  methods: {
    changed() {
      const update = {
        ...this.quizUpdates,
        passRate: +this.passRate,
        passPoints: +this.passPoints,
        redeemable: this.redeemable,
        databucket: {
          addToDatabucket: this.addToDatabucket,
          databucketId: this.databucketId,
          targetCode: this.targetCode,
        },
      };
      if (this.addToDatabucket && !this.databucketId) {
        this.$store.dispatch("setQuizChanged", false);
        return;
      } else if (this.addToDatabucket && !this.targetCode) {
        this.$store.dispatch("setQuizChanged", false);
        return;
      } else if (this.passPoints !== "" && this.passRate !== "") {
        this.$store.dispatch("setQuizChanged", true);
        this.$store.dispatch("patchQuiz", update);
      } else {
        this.$store.dispatch("setQuizChanged", false);
      }
    },
  },
  watch: {},
};
</script>

<style scoped></style>

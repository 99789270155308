<template>          
  <v-dialog 
    v-model="isVisible"
    :width="component.dialog.width"
    :fullscreen="component.dialog.fullscreen"
    persistent
    scrollable
  >
    <v-card
      :class="classes"
      :style="styles + ';' + gradient"
    >
      <v-btn
        v-if="component.dialog.title.floatText === 'text-right' && component.dialog.fullscreen === true"
        top left absolute small icon
        @click="onClose"
        style="z-index: 100"
      >
        <v-icon :color="component.dialog.title.color">clear</v-icon>
      </v-btn>  
      <v-btn
        v-if="component.dialog.title.floatText !== 'text-right' && component.dialog.fullscreen === true"
        top right absolute small icon
        @click="onClose"
        style="z-index: 100"
      >
        <v-icon :color="component.dialog.title.color">clear</v-icon>
      </v-btn>  
      <v-row no-gutters>
        <v-col cols="auto">
          <view-dialog-icon
            v-if="component.dialog.icon.placement === 'left' && component.dialog.icon.show === true" 
            :card="progressData"
            :component="component"
          ></view-dialog-icon>
        </v-col>
        <v-col>
          <v-row no-gutters :justify="component.dialog.icon.justify" v-if="component.dialog.icon.placement === 'top' && component.dialog.icon.show === true">
            <view-dialog-icon
              :card="progressData"
              :component="component"
            ></view-dialog-icon>
          </v-row>
            <view-text 
              :settings="component.dialog.title"
              :value="progressData.displayTitle"
            ></view-text>
          <v-row no-gutters :justify="component.dialog.icon.justify" v-if="component.dialog.icon.placement === 'bottom' && component.dialog.icon.show === true">
            <view-dialog-icon
              :card="progressData"
              :component="component"
            ></view-dialog-icon>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <view-dialog-icon
            v-if="component.dialog.icon.placement === 'right' && component.dialog.icon.show === true" 
            :card="progressData"
            :component="component"
          ></view-dialog-icon>
        </v-col>
      </v-row>
      <perfect-scrollbar>
        <v-card-text class="ma-0 pa-0">
          <v-card color="transparent" elevation="0" :style="summaryTableStyles">
            <v-data-table
              v-if="titleTableData.length !== 0 && databucketData.length === 0"
              :headers="titleHeaders"
              :items="titleTableData"
              :style="cssVars"
              class="table"
              hide-default-footer
            >
            </v-data-table>
          </v-card>
          <v-card color="transparent" elevation="0" :style="detailTableStyles">
              <v-data-table
                v-if="databucketData.length !== 0"
                :headers="databucketDataHeaders"
                :items="databucketData"
                :loading="loadingTable"
                no-data-text="No Records..."
                :style="cssVarsTransactions"
                class="table"
                show-expand
                single-expand
              >
                <template v-slot:[`item.created`]="{ item }">
                  {{ item.created | date }} 
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row class="ml-2 mt-4 mb-2">
                      Transactions imported on {{ item.created | date }} 
                    </v-row>
                    <v-row class="ml-6 my-6">
                      {{ item }}
                    </v-row>
                  </td>
                </template>
              </v-data-table>
            <v-data-table
              v-if="databucketData.length === 0"
              :headers="headers"
              :items="tableData"
              :style="cssVars"
              class="table"
              hide-default-footer
              :page.sync="page"
              @page-count="pageCount = $event"
              :items-per-page="itemsPerPage"
            >
            </v-data-table>
            <v-row justify="center" v-if="pageCount > 1" no-gutters class="mt-2" align="center">
              <v-btn
                v-if="page > 1"
                rounded x-small elevation="0" color="transparent" @click="page --">
                <v-icon
                  :color="this.component.dialog.content.heading.color"
                  size="20"
                >chevron_left</v-icon>
              </v-btn>
                <v-btn
                  v-for="page in pageCount"
                  :key="page"
                  x-small
                  elevation="0"
                  color="transparent"
                  rounded
                  @click="handlePage(page)"
                >
                  <span :style="cssVars" :class="paginationStyle(page)">
                    {{ page }}
                  </span>
                </v-btn>
              <v-btn
                v-if="page < pageCount"
                rounded x-small elevation="0" color="transparent" @click="page ++">
                <v-icon
                  :color="this.component.dialog.content.heading.color"
                  size="20"
                >chevron_right</v-icon>
              </v-btn>
            </v-row>
          </v-card>
        </v-card-text>
      </perfect-scrollbar>
      <v-card-actions class="ma-0 pa-0">
        <v-btn
          v-if="databucketData.length === 0 && progressData.transactionView === true"
          :color="component.dialog.title.color"
          text
          @click="getDatabucketData('JMcqyCz6qlqTPjNoE5MF')"
        >View Transactions</v-btn>
        <v-btn
          v-if="databucketData.length !== 0"
          :color="component.dialog.title.color"
          text
          @click="clearDatabucketData()"
        >Return To Summary</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="component.dialog.fullscreen !== true"
          @click="resetPage(); onClose()"
          text
          :color="component.dialog.title.color"
        >close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ViewText from '../Text.vue'
import ViewDialogIcon from '../DialogIcon.vue'

 export default {
  components: {
    ViewText,
    ViewDialogIcon
  },
  props: ['isVisible', 'onClose', 'progressData', 'component'],
  created () {
    this.clearDatabucketData()
  },
  data: function () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      databucketDataHeaders: [
        { text: 'Data Import Date', value: 'created', align: 'left' },
        { text: '', value: 'data-table-expand' }
      ]
    }
   },
   computed: {
     databucketData () {
       return this.$store.getters.databucketData
     },
     cssVars() {
      return {
        '--header-text-color': this.component.dialog.content.heading.color,
        '--header-text-size': this.component.dialog.content.heading.size + 'px',
        '--header-text-style': this.component.dialog.content.heading.style,
        '--header-text-weight': this.component.dialog.content.heading.weight,
        '--header-line-height': this.component.dialog.content.heading.lineHeight,
        '--header-font': this.component.dialog.content.heading.font,
        '--row-text-color': this.component.dialog.content.data.color,
        '--row-text-size': this.component.dialog.content.data.size + 'px',
        '--row-text-style': this.component.dialog.content.data.style,
        '--row-text-weight': this.component.dialog.content.data.weight,
        '--row-line-height': this.component.dialog.content.data.lineHeight,
        '--row-font': this.component.dialog.content.data.font,
        '--text-align': this.component.dialog.content.align,
        '--row-border-bottom': this.rowBorders,
        '--row-hover-color': this.component.dialog.rowHoverColor.hexa,
        '--current-page-color': this.component.dialog.title.color
      }
    },
     cssVarsTransactions() {
      return {
        '--header-text-color': this.component.dialog.content.heading.color,
        '--header-text-size': this.component.dialog.content.heading.size + 'px',
        '--header-text-style': this.component.dialog.content.heading.style,
        '--header-text-weight': this.component.dialog.content.heading.weight,
        '--header-line-height': this.component.dialog.content.heading.lineHeight,
        '--header-font': this.component.dialog.content.heading.font,
        '--row-text-color': this.component.dialog.content.data.color,
        '--row-text-size': this.component.dialog.content.data.size + 'px',
        '--row-text-style': this.component.dialog.content.data.style,
        '--row-text-weight': this.component.dialog.content.data.weight,
        '--row-line-height': this.component.dialog.content.data.lineHeight,
        '--row-font': this.component.dialog.content.data.font,
        '--text-align': 'left',
        '--row-border-bottom': this.rowBorders,
        '--row-hover-color': this.component.dialog.rowHoverColor.hexa,
        '--current-page-color': this.component.dialog.title.color
      }
    },
    classes () {
      if (this.component.dialog.fullscreen === true)
      return 'rounded-0'
      return this.component.card.rounded
    },
    grid() {
      return this.$store.getters.grid
    },
    rowBorders() {
      return this.component.dialog.rowBorderSize + 'px' + 
      ' solid ' + 
      this.component.dialog.rowBorderColor.hexa
    },
    iconAlign() {
      return this.component.dialog.content.align
    },
    iconSize() {
      return this.component.dialog.iconSize
    },
    gradient() {
      if (this.component.dialog.color.type === 'gradient') {
        var color = this.component.dialog.color
        var gradient = 
        'background: linear-gradient(' +
        color.direction + ', rgba(' +
        color.gradientFrom.rgba.r + ', ' +
        color.gradientFrom.rgba.g + ', ' +
        color.gradientFrom.rgba.b + ', ' +
        color.gradientFrom.rgba.a + ') ' + 
        ', rgba(' +
        color.gradientTo.rgba.r + ', ' +
        color.gradientTo.rgba.g + ', ' +
        color.gradientTo.rgba.b + ', ' +
        color.gradientTo.rgba.a + ')' + color.gradientHeight + '%)'
        return gradient
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return 'background: ' + this.component.dialog.color.solidColor.hexa + ';'
      }
    },
    styles () {
      var styles = 
        'padding-bottom: ' + this.component.dialog.padding.bottom + 'px ;' +
        'padding-top: ' + this.component.dialog.padding.top + 'px ;' +
        'padding-left: ' + this.component.dialog.padding.left + 'px ;' +
        'padding-right: ' + this.component.dialog.padding.right + 'px ;' +
        'border: ' + this.component.dialog.borderSize + 'px solid ' + ';'
        this.component.dialog.borderColor.hexa + '; '
      return styles
    },
    summaryTableStyles () {
      var styles = 
        'margin-bottom: ' + this.component.dialog.summary.margin.bottom + 'px ;' +
        'margin-top: ' + this.component.dialog.summary.margin.top + 'px ;' +
        'margin-left: ' + this.component.dialog.summary.margin.left + 'px ;' +
        'margin-right: ' + this.component.dialog.summary.margin.right + 'px ;'
      return styles
    },
    detailTableStyles () {
      var styles = 
        'margin-bottom: ' + this.component.dialog.detail.margin.bottom + 'px ;' +
        'margin-top: ' + this.component.dialog.detail.margin.top + 'px ;' +
        'margin-left: ' + this.component.dialog.detail.margin.left + 'px ;' +
        'margin-right: ' + this.component.dialog.detail.margin.right + 'px ;'
      return styles
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    headers () {
      // console.log(this.progressData)
      if (this.progressData.resultsHeader) {
        const headers = this.progressData.resultsHeader.map((heading, index) => {
          return {
            text: heading,
            value: index.toString(),
            align: 'center',
            sortable: false
          }
        })
        return headers
      } else {
        return []
      }
    },
    tableData () {
      if (this.progressData.rows) {
        const data = this.progressData.rows.map(el => {
          let acc = {}
          if (el.row) {
            el.row.forEach((val, index) => {
              acc[index] = val
            })
          }
          return acc
        })
        return data
      } else {
        return []
      }
    },
    qualifierId () {
      if (this.progressData.resultsHeader) {
        const qualifierHeader = this.progressData.resultsHeader.find(el => el.type === 'qualifier') || {}
        return qualifierHeader.uid
      } else {
        return ''
      }
    },
    iconId () {
      if (this.progressData.cardHeader) {
        const iconHeader = this.progressData.cardHeader.find(el => el.column === 'Icon') || {}
        return iconHeader.uid
      } else {
        return ''
      }
    },
    titleHeaders () {
      if (this.progressData.cardHeader) {
        const headers = this.progressData.cardHeader.map((heading, index) => {
          return {
            text: heading,
            value: index.toString(),
            align: 'center',
            sortable: false
          }
        })
        return headers
      } else {
        return []
      }
    },
    titleTableData () {
      if (this.progressData.totals) {
        // const data = this.progressData.totals.map((el, index) => {
        //   return {
        //     [index]: el
        //   }
        // })
        const data = this.progressData.totals.reduce((acc, el, index) => {
          acc[index] = el
          return acc
        }, {})
        return [data]
      } else {
        return []
      }
    }
  },
  methods: {
    getDatabucketData (databucketId) {
      this.$store.dispatch('getDatabucketData', databucketId)
    },
    clearDatabucketData () {
      this.$store.commit('setDatabucketData', [])
    },
    ordinal (n) {
      var s = ['th', 'st', 'nd', 'rd']
      var v = n % 100
      return n + (s[(v - 20) % 10] || s[v] || s[0])
    },
    handlePage(page) {
      this.page = page
    },
    paginationStyle(val) {
      if (val === this.page)
      return 'incentable-current-page'
      return 'incentable-pagination'
    },
    resetPage() {
      this.page = 1
      this.clearDatabucketData()
    }
  }
}
</script>

<style>
.svg-opacity {
  opacity: 0.5 !important;
}
.table.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
  line-height: var(--header-line-height) !important;
  background-color: transparent !important;
  text-align: var(--text-align) !important;
  border-bottom: var(--row-border-bottom) !important;
}
.incentable-pagination {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
}
.incentable-current-page {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--current-page-color) !important;
}
.btn-pagination {
  padding-top: 5px;
}
.table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: var(--row-font) !important;
  font-size: var(--row-text-size) !important;
  font-style: var(--row-text-style) !important;
  font-weight: var(--row-text-weight) !important;
  color: var(--row-text-color) !important;
  line-height: var(--row-line-height) !important;
  text-align: var(--text-align) !important;
  background-color: transparent !important;
  border-bottom: var(--row-border-bottom) !important;
}
.table.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-family: var(--header-font) !important;
  font-size:  var(--header-text-size) !important;
  font-style:  var(--header-text-style) !important;
  font-weight:  var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
  line-height: var(--header-line-height) !important;
  background-color: transparent !important;
  text-align: var(--text-align) !important;
  border: none !important;
}
.table.theme--light.v-data-table {
    background-color: transparent !important;
    color: rgba(0,0,0,.87);
}
.theme--light.v-data-table>.v-data-table__wrapper > table > tbody> tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: var(--row-hover-color) !important; 
}
</style>


<template>
  <v-card
    class="mb-4 el rounded-card gradient-card-background-dark"
    style="height: 224px"
  >
    <v-row no-gutters justify="end">
      <v-btn
        class="manage-button white--text mr-6"
        elevation="0"
        large
        rounded
        color="primary"
        @click="handleBookDemo()"
      >
        <v-icon :color="orgTheme.buttonIconColor" left size="20"
          >play_arrow</v-icon
        >
        Book A Demo
      </v-btn>
    </v-row>

    <v-card-title>
      <div class="incentable-program-card-title" @click="handleBookDemo()">
        Live Demo
      </div>
    </v-card-title>

    <div class="ml-4 mt-16">
      <div class="incentable-bullet-list">
        <v-icon color="white" size="13">check</v-icon> Meet with a product
        expert
      </div>
      <div class="incentable-bullet-list">
        <v-icon color="white" size="13">check</v-icon> Discover the Incentable
        advantage
      </div>
      <div class="incentable-bullet-list">
        <v-icon color="white" size="13">check</v-icon> View plan and feature
        options
      </div>
      <div class="incentable-bullet-list">
        <v-icon color="primary" size="13">emoji_events</v-icon> Learn how to use
        Incentable
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
  },
  methods: {
    handleBookDemo() {
      this.$store.dispatch("setBookDemo", true);
    },
  },
};
</script>

<style scoped>
.card-actions {
  position: absolute;
  bottom: 0;
}
.bullet-list {
  position: absolute;
  bottom: 19px;
}
</style>

<template>
  <div>

    <v-row no-gutters justify="center" class="mt-14">
      <v-col md="12" lg="8">
        <div 
          v-for="(section, i) in sections"
          :key="i"
          no-gutters
          class="mt-3"
        >
   
        <v-row 
          no-gutters 
          class="incentable-card-title mt-3 mb-2 ml-3"
        >
          {{ section }}
        </v-row>

        <Videos :section="section"/>

        </div>
      </v-col>
    </v-row>

    </div>
</template>

<script>
import Videos from './Videos.vue'
export default {
  components: { Videos },
  data: () => ({
    sections: [
      'Quick Start Guides',
      'Members',
      'Companies',
      'Content Management',
      'Rewards'
    ],
  }),
  created () {
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style>

</style>
<template>
  <div>
    <v-row no-gutters class="px-3">
      <v-col cols="3">
        <span class="incentable-form-heading">Data Source</span>
        <v-radio-group 
          row
          v-model="mode"
          class="mt-1"
          @change="modeChanged(mode)"
          :disabled="demo"
          >
          <v-radio label="Data Bucket" value="dynamic"/>
          <v-radio label="CSV File" value="static" />
        </v-radio-group>
        <span class="incentable-form-heading">Data Type</span>
        <span class="incentable-form-subheading ml-2">Whose data is in the raw data?</span>
        <v-radio-group
          v-model="participantType"
          row
          class="mt-1"
          :disabled="demo"
        >
          <v-radio label="Members" value="member"/>
          <v-radio label="Companies" value="company" />
        </v-radio-group>
        <v-row class="incentable-form-heading mb-0" no-gutters>Data Filter</v-row>
        <v-row no-gutters v-if="participantType === 'company'" class="incentable-form-subheading">Filter results by Company Tag</v-row>
        <v-row no-gutters v-else class="incentable-form-subheading">Filter the leaderboard by Member Tag</v-row>
        <v-select
          v-model="selectedFilters"
          :items="tags"
          item-text="tag"
          item-value="id"
          chips
          multiple
          deletable-chips
          append-icon="filter_list"
          @change="filteredEntityMapChanged()"
          outlined
          >
        </v-select>
        
      </v-col>
    </v-row>

    <v-row no-gutters align="center" class="mt-6 mb-0 incentable-form-heading">
      <v-col cols="3">
        <v-row v-if="mode === 'dynamic'" justify="center" no-gutters>
          Data Bucket
        </v-row>
        <v-row v-else justify="center" no-gutters>
          CSV File
        </v-row>
      </v-col>
      <v-col></v-col>
      <v-col cols="2">
        <v-row justify="center" no-gutters>
          Data Key
        </v-row>
      </v-col>
      <v-col></v-col>
      <v-col cols="3">
        <v-row justify="center" no-gutters>
          Leaderboard
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="mode === 'static'" class="mb-0"></v-row>

    <v-row no-gutters justify="space-between" align="center" class="px-3">
      <v-col v-if="mode === 'dynamic'" cols="3">
        <v-row class="pt-2" no-gutters>
          <v-select
            v-model="selectedDatabucket"
            name="selectedDatabucket"
            :items="databuckets"
            item-text="title"
            item-value="id"
            :disabled="demo"
            outlined
            class="mt-5"
            placeholder="Select a databucket..."
          >
            <template v-slot:prepend-inner>
              <v-icon color="primary" class="mr-2 mt-n1" size="30">fa-solid fa-database</v-icon>
            </template>
          </v-select>
        </v-row>
      </v-col>
      
      <v-col v-else cols="3">
        <div>
          <v-card
            outlined
            class="pa-1"
            style="border: 1px solid #A2A2A2"
          >
            <v-btn
              v-if="!csvFilename"
              @click="openFileBrowser"
              :disabled="demo"
              elevation="0"
              color="primary"
            >Choose file</v-btn>
            <div v-else>
              <v-row no-gutters class="mt-2" align="center" justify="center"><v-icon color="green" size="30">fa-solid fa-file-csv</v-icon><span class="filename ml-2">{{ csvFilename }}</span></v-row>
              <v-row no-gutters class="mt-3" justify="center"><a @click="openFileBrowser" class="text-decoration-underline caption">Upload a replacement CSV file</a></v-row>
            </div>
            <input
              type="file"
              style="display: none"
              ref="csvInput"
              accept=".csv"
              @change="handleFilePicked">
          </v-card>
        </div>
      </v-col>
      
      <v-col>
        <v-progress-linear
          :color="connectionStatus.color"
          height="5"
          value="100"
          striped
        ></v-progress-linear>
      </v-col>
      
      <v-col v-if="mode === 'static'" cols="2" class="mt-0">
        <div class="mt-6">
          <v-select
            v-model="accountKeyColumn"
            name="accountKeyColumn"
            :items="csvHeaders"
            prepend-inner-icon="vpn_key"
            :disabled="demo"
            outlined
            :background-color="connectionStatus.fillColor"
            dense
            class="mt-0"
          />
        </div>
      </v-col>
      <v-col v-else cols="2">
        <v-card
          outlined
          class="pa-2"
          style="border: 1px solid #A2A2A2"
          :color="connectionStatus.fillColor"
          height="39"
        >
          <v-row no-gutters justify="center" align="center" :class="connectionStatus.class">
            <v-icon class="mr-1" :color="connectionStatus.color" size="25">auto_fix_high</v-icon>Automatic
          </v-row>
        </v-card>

      </v-col>
      <v-col>
        <v-progress-linear
          :color="connectionStatus.color"
          height="5"
          value="100"
          striped
        ></v-progress-linear>
      </v-col>
      <v-col cols="3">
        <v-card
          class="pa-2"
          style="border: 1px solid #A2A2A2"
          outlined
          min-height="55px"
        >
          <v-row no-gutters justify="center" align="center" class="page-subheading">
            <v-icon class="mr-1" color="primary" size="33">emoji_events</v-icon>{{ currentLeaderboard.displayTitle }}
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row no-gutters justify="center">
      <v-icon :color="connectionStatus.color" class="mr-1">{{ connectionStatus.icon }}</v-icon><span :class="connectionStatus.class">{{ connectionStatus.text }}</span>
    </v-row>

    <v-row no-gutters class="mt-16" justify="center">
      <div>{{csvBody.length}} records found in source data</div>
    </v-row>
    <v-row no-gutters justify="center">
      <div v-if="participantType === 'company'">{{csvBody.length - unmatched.length}} records matched to companies on file</div>
      <div v-if="participantType === 'member'">{{csvBody.length - unmatched.length}} records matched to members on file</div>
    </v-row>
    <v-row no-gutters justify="center">
      <a :href="unmatchedCsv" :download="currentLeaderboardRaw.csvName"><b>{{ unmatched.length}}</b> unmatched records</a>
    </v-row>
    <v-row no-gutters class="" justify="center">
      Last Import: {{ currentLeaderboardRaw.updated | date }}
    </v-row>
    <v-row no-gutters class="mb-16"></v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import Papa from 'papaparse';
export default {
  data () {
    return {
    }
  },
  created() {
    this.selectedLeaderboardCSV = this.currentLeaderboardRaw
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    companies () {
      return this.$store.state.company.companies
    },
    companyTags () {
      return this.$store.state.companytag.companyTags
    },
    members () {
      return this.$store.state.member.members
    },
    memberTags () {
      return this.$store.state.membertag.memberTags
    },
    tags () {
      if (this.participantType === 'member') {
        return this.memberTags
      } return this.companyTags
    },
    databuckets() {
      return this.$store.state.databuckets.databuckets
    },
    currentLeaderboard() {
      return this.$store.state.leaderboard.currentLeaderboard
    },
    currentLeaderboardRaw() {
      return this.$store.state.leaderboard.currentLeaderboardRaw
    },
    entityMap() {
      return this.$store.getters.leaderboardEntityMap
    },
    memberAccountKeysMapFiltered(state) {
      let arr = []
      if (this.selectedFilters.length > 0) {
        arr = state.members.filter(x => x.tags.some(g => this.selectedFilters.includes(g)))
      } else {
        arr = state.members
      }
      return arr.reduce((result, member) => {
        return member.accountKey
          ? {
            ...result,
            [member.accountKey]: {
              id: member.id,
              firstname: member.firstname,
              lastname: member.lastname,
              fullname: member.fullname,
              title: member.fullname,
              firstLastInitial: member.firstLastInitial,
              avatar: member.avatar,
              externalImageUrl: member.externalImageUrl,
              titleUppercase: member.fullnameUppercase,
              tags: member.tags
            },
          }
          : result;
      }, {});
    },
    companyAccountKeysMapFiltered(state) {
      let arr = []
      if (this.selectedFilters.length > 0) {
        arr = state.companies.filter(x => x.tags.some(g => this.selectedFilters.includes(g)))
      } else {
        arr = state.companies
      }
      return arr.reduce((result, company) => {
        return company.accountKey
          ? {
            ...result,
            [company.accountKey]: {
              id: company.id,
              title: company.title,
              titleUppercase: company.titleUppercase,
              tags: company.tags
            },
          }
          : result;
      }, {});
    },
    csvFilename() {
      if (this.currentLeaderboardRaw.csvName === 'databucket.csv') {
        return ''
      }
      return this.currentLeaderboardRaw.csvName
    },
    connectionStatus() {
      var error = { text: 'Connection Error. Contact Support', color: 'red', textColor: 'red--text', icon: 'error', class: 'red--text font-weight-bold', fillColor: 'red lighten-4'}
      var csvGood = { text: 'CSV Connected', color: 'green', textColor: 'green--text', icon: 'check_circle', class: 'green--text font-weight-bold', fillColor: 'green lighten-4'}
      var databucketGood = { text: 'Data Bucket Connected', color: 'green', textColor: 'green--text', icon: 'check_circle', class: 'green--text font-weight-bold', fillColor: 'green lighten-4'}
      var csvBad = { text: 'CSV Not Connected', color: 'red', textColor: 'red--text', icon: 'error', class: 'red--text font-weight-bold', fillColor: 'red lighten-4'}
      var csvNone = { text: 'No CSV available. Upload a CSV then set the Data Key', color: 'red', textColor: 'red--text', icon: 'error', class: 'red--text font-weight-bold', fillColor: 'red lighten-4'}
      var databucketBad = { text: 'Data Bucket Not Connected', color: 'red', textColor: 'red--text', icon: 'error', class: 'red--text font-weight-bold', fillColor: 'red lighten-4'}
      if (this.csvFilename && this.accountKeyColumn && this.mode === 'static') return csvGood
      if (this.csvFilename && !this.accountKeyColumn && this.mode === 'static') return csvBad
      if (!this.csvFilename && !this.accountKeyColumn && this.mode === 'static') return csvNone
      if (this.selectedDatabucket === '' && this.mode === 'dynamic') return databucketBad
      if (this.selectedDatabucket !== '' && this.mode === 'dynamic') return databucketGood
      if (this.accountKeyColumn && this.mode === 'dynamic') return databucketGood
      return error
    },
    mode: {
      get() {
        return this.currentLeaderboard.mode
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { mode: value })
      }
    },
    selectedFilters: {
      get() {
        return this.currentLeaderboard.selectedFilters
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { selectedFilters: value })
      }
    },
    filteredEntityMap: {
      get() {
        return this.currentLeaderboard.filteredEntityMap
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { filteredEntityMap: value })
      }
    },
    selectedDatabucket: {
      get() {
        return this.currentLeaderboard.selectedDatabucket;
      },
      set(value) {
        this.$store.dispatch('selectDatabucket', value);
      }
    },
    participantType: {
      get() {
        return this.currentLeaderboard.participantType;
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { participantType: value });
      }
    },
    accountKeyColumn: {
      get() {
        return this.currentLeaderboard.accountKeyColumn;
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { accountKeyColumn: value })
      }
    },
    csvHeaders() {
      return this.$store.getters.leaderboardCsvHeaders;
    },
    csvBody() {
      return this.$store.getters.leaderboardCsvBody;
    },
    unmatched() {
      if (!this.accountKeyColumn || !this.csvBody.length) {
        return [];
      }

      return this.csvBody.filter(item => {
        const accountKeyValue = item[this.accountKeyColumn];
        return !accountKeyValue || !this.entityMap[accountKeyValue];
      });
    },
    unmatchedCsv() {
      const { unmatched } = this;
      const dataHeader = 'data:text/csv;charset=utf-8,';
      const csvHeader = `${this.csvHeaders.join(',')}\r\n`;
      const csv = unmatched.reduce((result, row, index) => {
        const rowArray = this.csvHeaders.reduce((result, header) => {
          return [...result, row[header]];
        }, []);
        const rowString = rowArray.join(',');
        if (unmatched.length - 1 === index) {
          return `${result}${rowString}`;
        }
        return `${result}${rowString}\r\n`;
      }, `${dataHeader}${csvHeader}`);

      return csv;
    }
  },
  methods: {
    filteredEntityMapChanged() {
      const val = (this.participantType === 'member') 
        ? this.memberAccountKeysMapFiltered 
          : this.companyAccountKeysMapFiltered
      this.$store.dispatch('patchCurrentLeaderboard', { 
        filteredEntityMap: val
      })
    },
    modeChanged(mode) {
      console.log('Source changed to: ', mode)
      if (mode === 'dynamic') {
        this.currentLeaderboard.accountKeyColumn = 'Account Id'
        this.$store.dispatch('patchCurrentLeaderboard', { 
          accountKeyColumn: 'Account Id',
          homeMappings: {},
          selectedFilters: [],
          remarkMappings: [],
          headerMappings: [],
          iconMappings: {},
          columnMappings: [],
          rankMappings: { sortDir: 'desc', winRows: 1, maxRows: 0 }, 
        })
      } else {
        this.$store.dispatch('patchCurrentLeaderboard', { 
          accountKeyColumn: null,
          selectedDatabucket: null,
          selectedFilters: [],
          homeMappings: {},
          remarkMappings: [],
          headerMappings: [],
          iconMappings: {},
          columnMappings: [],
          rankMappings: { sortDir: 'desc', winRows: 1, maxRows: 0 }, 
        })
      }
    },
    openFileBrowser() {
      this.$refs.csvInput.click();
    },
    handleFilePicked(e) {
      const file = e.target.files[0];
      const reader = new FileReader();

      if (!file) {
        return;
      }
      reader.readAsText(file, "UTF-8");
      reader.onload = (evt) => {
        const output = evt.target.result;
        const result = _.get(Papa.parse(output), "data");
        this.$store.dispatch('patchCurrentLeaderboardRaw', {
          csvData: result,
          csvName: file.name,
        });
      };
      reader.onerror = function () {
        // Handle Error
      };
    },
  },
}
</script>

<style scoped>

</style>

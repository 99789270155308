<template>
<v-dialog v-model="open" max-width="800px" persistent>
  <v-card class="pa-2">
      <div class="headline pt-3 pl-3">Re Order</div>
      <div class="caption pl-3 pb-3">Drag menu item and click save to reorder</div>
    
    <v-card-text v-if="isPrimaryMenu">
      <v-row dense no-gutters justify="center">
        <draggable
          v-model="innerItems"
          ghost-class="ghost"
          class="d-flex"
        >
          <v-row v-for="(item, index) of innerItems" :key="index">
            <v-card color="secondary" class="white--text sort-item mr-7" height="100px" width="100px">
              <v-row no-gutters justify="center" class="pt-5">
                <img :src="'/img/icons/' + item.iconFile" height="32px"> 
              </v-row>
              <v-row no-gutters align="center" justify="center">
                {{item.title}}
              </v-row>
            </v-card>
          </v-row>
        </draggable>

      </v-row>

      <v-row class="pt-9">
        <v-app-bar dark>
          <v-spacer></v-spacer>
          <v-btn text v-for="item in innerItems" :key="item.id">{{item.title}}</v-btn>
          <v-btn text>Profile</v-btn>
          <v-btn text>Sign Out</v-btn>
        </v-app-bar>
      </v-row>
    </v-card-text>

    <v-card-text v-else>
      <draggable
        v-model="innerItems"
        ghost-class="ghost"
      >
        <v-row row wrap justify-center v-for="(item, index) of innerItems" :key="index" class="sort-item">
          <v-card min-width="250px" height="55px" class="my-1 pa-2 pb-3">
            <v-row row wrap>
              <v-col xs2>
                <v-icon class="white--text pt-1">drag_indicator</v-icon>
              </v-col>
              <v-col xs5 class="pt-2">
                {{item.title}}
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </draggable>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="handleClose">
        Cancel
      </v-btn>
      <v-btn color="primary" @click="handleSave">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isPrimaryMenu: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isProcessing: false,
    innerItems: [],
  }),
  methods: {
    handleClose() {
      this.$emit('onClose');
    },
    handleSave() {
      this.$emit('onSave', this.innerItems);
    }
  },
  watch: {
    items: {
      handler(newVal) {
        this.innerItems = newVal;
      },
    },
  },
}
</script>

<style>
.ghost {
  border: 1px dotted #ccc;
}

.sort-item {
  cursor: move;
}

.horizontal {
  display: flex;
}
</style>

<template>
  <div class="px-4 pt-4">
    <v-row no-gutters align="center">
      <v-col lg="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search By Name"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field>
      </v-col>

      <v-col lg="3" v-if="options.filter">
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter By Status"
          prepend-inner-icon="filter_list"
          class="mr-4"
          multiple
          :items="statusList"
          v-model="filteredStatus"
        ></v-select>
      </v-col>

      <v-col>
        <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
        <v-btn v-if="options.download" class="primary--text" text
          >Download</v-btn
        >
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
      </v-col>

      <v-col sm="auto"> </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="filteredOrders"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link>
              <v-list-item-title @click="handleEdit(item)">
                <v-icon class="mr-2">shopping_cart</v-icon>
                View Order
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status" class="pr-3"></status-icon>
        {{ item.status }}
      </template>

      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | timestamp | date }}
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        {{ item.updated | timestamp | date }}
      </template>

      <template v-slot:[`item.cartValue`]="{ item }">
        {{ item.cartValue | number }}
      </template>

      <template v-slot:[`item.items`]="{ item }">
        <span v-for="(reward, i) in item.items" :key="i">
          <v-avatar v-if="i < 3" size="41" class="mr-n2 av-outline">
            <v-img
              :src="reward.image.smallUrl"
              :lazy-src="reward.image.smallUrl"
            ></v-img>
          </v-avatar>
        </span>
        <v-avatar
          v-if="orderItemCount(item.items) > 3"
          size="41"
          color="primary white--text"
          class="av-outline"
        >
          +{{ orderItemCount(item.items) - 3 }}
        </v-avatar>
      </template>

      <template v-slot:[`item.member.fullname`]="{ item }">
        <v-row no-gutters justfy="start" align="center">
          <v-col
            cols="auto"
            v-if="isRoleSatisfied('Editor')"
            @click="handleEdit(item)"
            class="primary--text"
            style="cursor: pointer"
          >
            <v-avatar v-if="item.member.image.smallUrl" size="38" class="mr-2">
              <v-img
                :src="item.member.image.smallUrl"
                :lazy-src="item.member.image.smallUrl"
              ></v-img>
            </v-avatar>
            <v-avatar v-else size="38" :color="item.member.color" class="mr-2">
              <span class="white--text">{{ item.member.initials }}</span>
            </v-avatar>
          </v-col>
          <v-col v-else cols="auto" justify="start">
            <v-avatar size="38" :color="item.member.color" class="mr-2">
              {{ item.initials }}
            </v-avatar>
            <span class="float-left table-link">{{
              item.member.firstname + " " + item.member.lastname
            }}</span>
          </v-col>
          <v-col v-if="isRoleSatisfied('Editor')">
            {{ item.member.firstname + " " + item.member.lastname }}
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <orders-new-or-edit
      :orderId="selectedOrderId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />
  </div>
</template>

<script>
import { ORDER_STATUS } from "@/constants/order";
import OrdersNewOrEdit from "./OrdersNewOrEdit.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    OrdersNewOrEdit,
  },
  data: () => ({
    // Component settings
    tableTitle: "Orders",
    tableSubtitle: "Add orders",
    tableIcon: "storefront",
    search: "",
    title: "",
    selectedOrderId: "",
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,
    statusList: ORDER_STATUS,
    filteredStatus: [],
    itemDeleting: "",
    // Table settings
    options: {
      sortBy: ["created"],
      sortDesc: [true],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: false,
      download: false,
      more: false,
    },

    // Table columns
    headers: [
      { text: "Status", value: "status", align: "left" },
      {
        text: "Member",
        value: "member.fullname",
        align: "left",
        sortable: false,
      },
      { text: "Items", value: "items", align: "left", sortable: false },
      { text: "Date", value: "created", align: "left" },
      { text: "Updated", value: "updated", align: "left" },
      { text: "Points", value: "cartValue", align: "right", sortable: false },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
  }),

  created() {
    this.$store.dispatch("setProcessingStatusChange", false);
  },

  computed: {
    // Functions specific to this table //
    orders() {
      return this.$store.state.order.orders;
    },
    filteredOrders() {
      const filtered =
        this.search === ""
          ? this.orders
          : this.orders.filter((el) =>
              el.name.toLowerCase().includes(this.search.toLowerCase())
            );
      const result = this.filteredStatus.length
        ? filtered.filter((m) => this.filteredStatus.includes(m.status))
        : filtered;
      return result;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    snackbar() {
      return this.$store.getters.snackbar;
    },
  },
  methods: {
    // Actions common to all tables //
    getStatusIcon(item) {
      if (item === "Active") {
        return "check_circle";
      } else if (item === "Inactive") {
        return "not_interested";
      }
    },
    orderItemCount(order) {
      const arr = order.filter((el) => el.status !== "Cancelled");
      const len = arr.length;
      return len;
    },
    handleNew() {
      // Open New form
      this.selectedOrderId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedOrderId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedOrderId = "";
      this.dialogNewOrEditOpen = false;
    },
  },
};
</script>

<style>
.v-avatar.av-outline {
  outline: 3px solid white;
}
</style>

<template>
  <v-dialog v-model="open" persistent max-width="1200px">
    <v-card>
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
        <v-overlay absolute color="primary" :opacity="systemTheme.cardOpacity">
        </v-overlay>
      </v-img>

      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar size="100" color="white" outline>
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>

      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-16">
        <v-row no-gutters class="incentable-form-heading">
          <v-col>
            {{ this.stepHeading }}
          </v-col>
        </v-row>
        <v-row dense class="incentable-form-subheading">
          <v-col>
            {{ this.stepDescription }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <csv-import
              class="pl-5"
              v-if="step === 0"
              v-model="parsedCsv"
              :key="csvComponentKey"
              :map-fields="mappingFields"
              :required-fields="requiredFields"
            />
          </v-col>
        </v-row>

        <div v-if="step === 1">
          <v-row dense>
            <v-col>
              <v-card class="pr-4">
                <v-card-title>
                  <v-icon color="primary" class="pr-4">business</v-icon>
                  {{ parsedCsv.length }}
                </v-card-title>
                <v-card-text class="incentable-page-subheading">
                  Companies found in your CSV
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pr-4">
                <v-card-title>
                  <v-icon color="primary" class="pr-4">verified_user</v-icon>
                  {{ validNewCompanies.length }}
                </v-card-title>
                <v-card-text class="incentable-page-subheading">
                  Are valid and will be imported
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pr-4">
                <v-card-title>
                  <v-icon color="primary" class="pr-4">label</v-icon>
                  {{ newCompanyTagsArray.length }}
                </v-card-title>
                <v-card-text class="incentable-page-subheading">
                  Company tag(s) will be created
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-alert
            v-if="validNewCompanies.length !== parsedCsv.length"
            color="primary"
            border="left"
            dense
            text
          >
            <v-row dense no-gutters align="center">
              <v-col cols="auto">
                <v-icon color="primary" class="mx-2" size="26">business</v-icon>
              </v-col>
              <v-col class="pl-2 incentable-alert">
                {{ parsedCsv.length - validNewCompanies.length }} omitted from
                import because they already exist <br />If you wish to change or
                update the existing company, add it's Account Key to the CSV
                <br />You can still proceed, the omitted companies will be left
                out of the import
              </v-col>
            </v-row>
          </v-alert>

          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Company</th>
                  <th class="text-left">Data Key</th>
                  <th class="text-left">Tags</th>
                  <th class="text-left">Import Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in newCompanies" :key="item.title">
                  <td>{{ item.title }}</td>
                  <td>{{ item.accountKey }}</td>
                  <td>{{ item.rowCompanyTagsArray.join(";") }}</td>
                  <td>
                    <v-chip v-if="!item.isValid" color="red" small
                      >Omitted</v-chip
                    >
                    <v-chip
                      v-if="item.isValid && item.isUpdate"
                      color="amber"
                      small
                      >Update Existing</v-chip
                    >
                    <v-chip
                      v-if="item.isValid && !item.isUpdate"
                      color="green"
                      small
                      >Add New</v-chip
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div v-if="step === 2">
          <v-row>
            <v-col align="center" v-if="!processSuccess">
              <div>
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="red"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div class="incentable-page-subheading red--text my-8">
                Processing
              </div>
              <v-alert color="red" dense text border="left">
                <v-row align="center">
                  <v-col lg="1">
                    <v-icon color="red" class="mx-2" size="26">warning</v-icon>
                  </v-col>
                  <v-col class="pl-4 incentable-alert" md="auto">
                    Do not close browser while processing
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col align="center" v-if="processSuccess">
              <div>
                <v-icon size="80" color="primary" class="mt-7">check</v-icon>
              </div>
              <div class="incentable-page-subheading my-2 primary--text">
                Success
              </div>
            </v-col>
            <v-col align="center" v-if="processFailed">
              <div>
                <v-icon size="80" color="red" class="mt-7">close</v-icon>
              </div>
              <div class="incentable-page-subheading my-2 red--text">
                Import Failed
              </div>
            </v-col>
            <v-col>
              <div v-for="logItem in processingDetails" :key="logItem">
                {{ logItem }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="!isLastStep"
          color="primary"
          text
          @click="close()"
          elevation="0"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="!isLastStep"
          color="primary"
          :disabled="!isNextStepReady"
          @click="gotoNext()"
          elevation="0"
        >
          Next Step
        </v-btn>
        <v-btn
          v-if="isLastStep && processSuccess"
          color="primary"
          @click="gotoNext()"
          elevation="0"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as utils from "@/utils";

const TOTAL_STEP = 3;

const CSV_MAPPING = {
  TITLE: "Company Name",
  ACCOUNT_KEY: "Data Key",
  TAGS: "Tags",
};

function makeCompany(title = "", accountKey = "", existingCompanies) {
  const foundCompanyByTitle = existingCompanies.find(
    (item) => item.title === title
  );
  const foundCompanyByAccountKey = existingCompanies.find(
    (item) => item.accountKey === accountKey
  );
  const isValid = !!(
    title &&
    (foundCompanyByAccountKey || !foundCompanyByTitle)
  );
  const isUpdate = !!(accountKey && foundCompanyByAccountKey);

  return {
    title,
    accountKey,
    isValid,
    isUpdate,
    id: isUpdate ? foundCompanyByAccountKey.id : null,
    rowCompanyTagsArray: [],
  };
}

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    const mappingFields = [
      CSV_MAPPING.TITLE,
      CSV_MAPPING.ACCOUNT_KEY,
      CSV_MAPPING.TAGS,
    ];
    const requiredFields = [CSV_MAPPING.TITLE];
    return {
      formTitle: "Upload Companies",
      formIcon: "cloud_upload",
      csvComponentKey: Math.random(), // Note: This trick triggers the clearing of csv import component
      step: 0,
      mappingFields,
      requiredFields,
      parsedCsv: null,
      newCompanies: [],
      newCompanyTagsArray: [],
      processingDetails: [],
      processSuccess: false,
      processFailed: false,
    };
  },
  computed: {
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    companies() {
      return this.$store.state.company.companies;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    companyTagsArray() {
      return this.companyTags.map((item) => item.tag.toUpperCase());
    },
    companyTagsMap() {
      return this.companyTags.reduce((result, item) => {
        return {
          ...result,
          [item.tag.toUpperCase()]: item.id,
        };
      }, {});
    },

    fieldExistency() {
      if (!this.parsedCsv || !this.parsedCsv.length) {
        return {};
      }

      const firstRow = this.parsedCsv[0];
      return this.mappingFields.reduce((result, field) => {
        return {
          ...result,
          // eslint-disable-next-line no-prototype-builtins
          [field]: firstRow.hasOwnProperty(field),
        };
      }, {});
    },

    validNewCompanies() {
      return this.newCompanies.filter((rawCompany) => rawCompany.isValid);
    },

    stepHeading() {
      if (this.step === 0) {
        return "1. Upload CSV file";
      } else if (this.step === 1) {
        return "2. Review";
      } else if (this.step === 2) {
        return "3. Import Complete";
      } else {
        return "";
      }
    },

    stepDescription() {
      if (this.step === 0) {
        return "Please upload the CSV and select the column mapping.";
      } else if (this.step === 1) {
        return "Please check below details before you proceed. ";
      } else if (this.step === 2) {
        return "If import fails, please close the dialog and restart the import process.";
      } else {
        return "";
      }
    },

    isLastStep() {
      return this.step === TOTAL_STEP - 1;
    },

    isStep1Ready() {
      if (!this.parsedCsv || !this.parsedCsv.length) {
        return false;
      }
      return true;
    },

    isStep2Ready() {
      return this.validNewCompanies.length > 0;
    },

    isStep3Ready() {
      return true;
    },

    isNextStepReady() {
      const stepReadinessFuncName = `isStep${this.step + 1}Ready`;
      return this[stepReadinessFuncName];
    },
  },
  methods: {
    clear() {
      this.csvComponentKey = Math.random();
      this.parsedCsv = null;
      this.newCompanyTagsArray = [];
      this.processingDetails = [];
      this.step = 0;
      this.processSuccess = false;
      this.processFailed = false;
    },
    close() {
      this.$emit("onClose");
      this.clear();
    },
    async gotoNext() {
      console.log(this.step);
      if (this.step === 0) {
        this.step += 1;
        const { newCompanies, newCompanyTagsArray } = this.parsedCsv
          .reduce((result, row) => {
            // Note: Remove duplication inside csv itself.
            const duplicated = result.find(
              (item) =>
                item[CSV_MAPPING.TITLE] === row[CSV_MAPPING.TITLE] ||
                (item[CSV_MAPPING.ACCOUNT_KEY] &&
                  item[CSV_MAPPING.ACCOUNT_KEY] ===
                    row[CSV_MAPPING.ACCOUNT_KEY])
            );

            return duplicated ? result : [...result, row];
          }, [])
          .reduce(
            (result, row) => {
              // Note: find the brand new tags
              // Get the list of valid companies.
              const title = row[CSV_MAPPING.TITLE];
              const accountKey = row[CSV_MAPPING.ACCOUNT_KEY];
              const initCompany = makeCompany(
                title,
                accountKey,
                this.companies
              );
              if (!initCompany.isValid) {
                return {
                  ...result,
                  newCompanies: [...result.newCompanies, initCompany],
                };
              }

              const tempRowCompanyTagsArray = utils.parseByDelimeter(
                row[CSV_MAPPING.TAGS]
              );
              const rowCompanyTagsArray = tempRowCompanyTagsArray.map(
                (element) => {
                  return element.toUpperCase();
                }
              );
              const newCompanyTagsArray = utils.getNewArrayItems(
                this.companyTagsArray.concat(result.newCompanyTagsArray),
                rowCompanyTagsArray
              );
              const company = { ...initCompany, rowCompanyTagsArray };

              return {
                newCompanies: [...result.newCompanies, company],
                newCompanyTagsArray: [
                  ...result.newCompanyTagsArray,
                  ...newCompanyTagsArray,
                ],
              };
            },
            {
              newCompanies: [],
              newCompanyTagsArray: [],
            }
          );

        this.newCompanies = newCompanies;
        this.newCompanyTagsArray = newCompanyTagsArray;
      } else if (this.step === 1) {
        this.step += 1;

        this.processingDetails = ["Creating tags..."];
        try {
          await Promise.all([
            this.$store.dispatch("importCompanyTags", this.newCompanyTagsArray),
          ]);

          this.processingDetails = [
            ...this.processingDetails,
            "Prepared company tags successfully.",
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Preparing comapany tags failed.",
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Saving new company tags...",
        ];
        try {
          await Promise.all([this.$store.dispatch("loadCompanyTags")]);

          this.processingDetails = [
            ...this.processingDetails,
            "Saved new company tags successfully.",
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Saving company tags failed.",
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Importing companies...",
        ];

        const importBatchId = new Date().getTime();
        const companiesToImport = this.validNewCompanies.map((rawCompany) => {
          const tags = rawCompany.rowCompanyTagsArray.map(
            (rawTag) => this.companyTagsMap[rawTag]
          );
          console.log(tags);
          const members = [];

          if (rawCompany.isUpdate) {
            const updateObject = {
              id: rawCompany.id,
              isUpdate: rawCompany.isUpdate,
              importBatchId,
            };
            if (this.fieldExistency[CSV_MAPPING.TITLE]) {
              updateObject.title = rawCompany.title;
              updateObject.titleUppercase = rawCompany.title;
            }
            if (this.fieldExistency[CSV_MAPPING.TAGS]) {
              updateObject.tags = tags;
            }
            return updateObject;
          }

          return {
            id: rawCompany.id,
            importBatchId,
            status: "Active",
            title: rawCompany.title,
            accountKey: rawCompany.accountKey,
            titleUppercase: rawCompany.title,
            points: 0,
            tags,
            members,
            isUpdate: rawCompany.isUpdate,
          };
        });
        console.log(companiesToImport);
        try {
          await this.$store.dispatch("importCompanies", companiesToImport);
          this.processingDetails = [
            ...this.processingDetails,
            "Companies imported successfully.",
          ];
          this.processSuccess = true;
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Importing companies failed.",
          ];
          this.processFailed = true;
          return;
        }
        await this.$store.dispatch("loadCompanies");
      } else if (this.step === 2) {
        this.close();
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-dialog 
      v-model="open"
      max-width="400px"
      persistent
      >
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row no-gutters>
            <v-col align="center">
              You are about to delete this transaction for <span class="incentable-form-bold">{{ points | number }} Points </span>
              from <span class="incentable-form-bold">{{ firstname }} {{ lastname }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col align="center" class="mt-3">
              There will no longer be any record of this transaction if you delete it. If you lodge an adjustment as a new transaction, using the '- Subtract Points' or '+ Add Points' option, a record of the adjustment will be created. 
            </v-col>
          </v-row>        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            :disabled="demo"
            elevation="0"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: ()=> ({
    points: '',
    notes: '',
    description: '',
    type: '',
  }),
  props: ['open', 'memberId', 'transactionId', 'firstname', 'lastname'],
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    loading () {
      return this.$store.getters.loading
    },
    transactions () {
      return this.$store.state.transaction.transactions
    },
  },
  methods: {
    handleClose () {
      this.clear();
      this.$emit('onClose');
    },
    clear () {
    },
    handleDelete() {
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('setProcessingPoints', true)
      // Delete Transaction
      const payload = {
        memberId: this.memberId,
        transactionId: this.transactionId,
        type: this.type
      }
      
      this.$store.dispatch('deleteTransaction', payload)
        .then(() => this.$emit('onClose'))
        .catch(() => this.$emit('onClose'))
        
    },
  },
  watch: {
    transactionId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedTransaction = this.transactions.find(item => item.id === newVal);
          const {
            title, created, updated, createdBy, updatedBy, points, type, description, notes
          } = selectedTransaction;
          this.title = title;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
          this.points = points;
          this.type = type;
          this.description = description;
          this.notes = notes;
        }
      },
    },
  },
}
</script>
<template>
  <div>
    <v-card class="mx-4 mb-6" dark outlined>
      <v-expansion-panels accordion flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="editor-subheading">Labels</v-expansion-panel-header>
            <v-expansion-panel-content>
              
              <v-row no-gutters>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      name="menu"
                      label="Menu Labels"
                      v-model="editingComponent.menu.value"
                      @input="handleChange()"
                      readonly
                    ></v-text-field>
                  </template>
                  <span v-html="tooltipText"></span>
                </v-tooltip>
                <v-btn
                  v-if="editingField.field !== 'menu'" 
                  icon
                  class="mt-4"
                  @click="handleEditor('menu')"
                >
                  <v-icon color="primary">
                    settings
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="editingField.field === 'menu'" 
                  icon
                  class="mt-4"
                  @click="clearFieldEditors"
                >
                  <v-icon color="primary" class="rotate180">
                    expand_circle_down
                  </v-icon>
                </v-btn>
              </v-row>

              <design-heading-editor
                v-if="editingField.field === 'menu'"
                @onClose="clearFieldEditors"
              ></design-heading-editor>
            
            </v-expansion-panel-content>
        </v-expansion-panel>
        
        <v-expansion-panel>
          <v-expansion-panel-header class="editor-subheading">Group</v-expansion-panel-header>
            <v-expansion-panel-content>

              <v-row no-gutters>
                <v-col cols="4">
                  <v-icon class="rotate icon">height</v-icon>
                  <span class="setting-label">Width</span>
                </v-col>
                <v-slider
                  v-model="editingComponent.block.cols"
                  @input="handleChange()"
                  :thumb-size="24"
                  thumb-label
                  min="1"
                  max="12"
                  class="mt-n1"
                  color="primary"
                ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.block.cols }}/12
                </v-col>
              </v-row>

              <v-row class="mt-n3" no-gutters>
                <v-col cols="4">
                  <v-icon class="icon">sync_alt</v-icon>
                  <span class="setting-label">Alignment</span>
                </v-col>
                <v-icon @click="setMenuGroupHorizontal('start')" class="icon-button">align_horizontal_left</v-icon>
                <v-icon class="ml-2 icon-button" @click="setMenuGroupHorizontal('center')">align_horizontal_center</v-icon>
                <v-icon class="ml-2 icon-button" @click="setMenuGroupHorizontal('end')">align_horizontal_right</v-icon>
              </v-row>

            </v-expansion-panel-content>
        </v-expansion-panel>
        
        <v-expansion-panel>
          <v-expansion-panel-header class="editor-subheading">Container</v-expansion-panel-header>
            <v-expansion-panel-content>
              
              <v-row no-gutters>
                <v-col cols="4">
                  <v-icon class="rotate icon">height</v-icon>
                  <span class="setting-label">Width</span>
                </v-col>
                <v-slider
                  v-model="editingComponent.settings.cols"
                  @input="handleChange()"
                  :thumb-size="24"
                  thumb-label
                  min="1"
                  max="12"
                  class="mt-n1"
                  color="primary"
                ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.settings.cols }}/12
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-n3">
                <v-col cols="4">
                  <v-icon class="rotate270 icon">padding</v-icon>
                  <span class="setting-label">Left Padding</span>
                </v-col>
                  <v-slider
                    v-model="editingComponent.block.padding.left"
                    @input="handleChange()"
                    :thumb-size="24"
                    thumb-label
                    min="0"
                    max="100"
                    color="primary"
                  ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.block.padding.left }}
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-n3">
                <v-col cols="4">
                  <v-icon class="rotate icon">padding</v-icon>
                  <span class="setting-label">Right Padding</span>
                </v-col>
                  <v-slider
                    v-model="editingComponent.block.padding.right"
                    @input="handleChange()"
                    :thumb-size="24"
                    thumb-label
                    min="0"
                    max="100"
                    class="mt-n1"
                    color="primary"
                  ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.block.padding.right }}
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-n3">
                <v-col cols="4">
                  <v-icon class="icon">padding</v-icon>
                  <span class="setting-label">Top Padding</span>
                </v-col>
                  <v-slider
                    v-model="editingComponent.block.padding.top"
                    @input="handleChange()"
                    :thumb-size="24"
                    thumb-label
                    min="0"
                    max="100"
                    class="mt-n1"
                    color="primary"
                  ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.block.padding.top }}
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-n3 mb-n6">
                <v-col cols="4">
                  <v-icon class="rotate180 icon">padding</v-icon>
                  <span class="setting-label">Bottom Padding</span>
                </v-col>
                  <v-slider
                    v-model="editingComponent.block.padding.bottom"
                    @input="handleChange()"
                    :thumb-size="24"
                    thumb-label
                    min="0"
                    max="100"
                    class="mt-n1"
                    color="primary"
                  ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.block.padding.bottom }}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row justify="end" class="mt-0 mr-2 mb-2">
        <design-save-button :small="true"></design-save-button>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      tooltipText: 'Click the green edit icon to change the text styling.<br> The actual text displayed cannot be edited here.<br>Each menu item is set in the Admin, Menu area',
      menuSample: 'Home'
    }
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    editingComponent() {
      return this.$store.getters.editingComponent
    },
    editingField() {
      return this.$store.getters.editingField || {}
    },
  },
  methods: {
    handleChange() {
      this.$store.dispatch('setHasWebThemePendingUpdates', true)
    },
    handleEditor(field) {
      this.clearFieldEditors()
      let selected = {}
      if (field === 'menu') {
        selected = this.editingComponent.menu
      } else {
        return
      }
      this.$store.dispatch('editingField', selected)
    },
    setMenuGroupHorizontal(val) {
      this.editingComponent.group.justify = val
      this.handleChange()
    },
    clearFieldEditors() {
      this.$store.dispatch('editingField', {})
    },
  },
}
</script>

<style>
  
</style>
<template>
  <div class="px-3">
    <leaderboard-column-mapper
      :sourceColumns="sourceColumns"
      mapping="column"
      @onAdd="handleAddColumn"
    />

    <v-row no-gutters class="mt-4 mb-6" justify="center">
      <table>
        <thead>
          <draggable v-model="columnMappings" tag="tr" :disabled="demo">
            <th
              v-for="header of columnMappings"
              :key="header.uid"
              scope="col"
              class="incentable-leaderboard-table-title-row movable"
            >
              <v-row no-gutters>
                <v-icon class="ml-n4 mr-1 mt-n1" color="grey"
                  >drag_indicator</v-icon
                >
                {{ header.label }}
              </v-row>
              <v-row
                no-gutters
                class="incentable-page-subheading grey--text ml-3"
              >
                <v-icon color="grey" size="12" class="mr-1 mt-n1"
                  >subdirectory_arrow_right</v-icon
                >
                {{ header.column }}
              </v-row>
            </th>
          </draggable>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) of columnData" :key="rowIndex">
            <td
              v-for="header of columnMappings"
              :key="header.uid"
              align="center"
              class="table-cell"
            >
              <div v-if="header.column === 'Icon'">
                <v-row
                  v-if="row.isWinner && iconMappings.svgFile"
                  justify="center"
                >
                  <img
                    :src="'/img/icons/black/' + iconMappings.svgFile"
                    class="mapping-icon-qualifier"
                  />
                </v-row>
              </div>
              <div v-else-if="header.column === 'Avatar'">
                <v-avatar v-if="row.values[header.uid]" size="38">
                  <v-img :src="row.values[header.uid]"></v-img>
                </v-avatar>
              </div>
              <div v-else-if="header.type === 'qualifier'">
                <v-img
                  :src="require(`@/assets/${row.values[header.uid]}`)"
                  class="mapping-icon-qualifier"
                />
              </div>
              <span v-else>
                {{ row.values[header.uid] }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </v-row>
  </div>
</template>

<script>
import LeaderboardColumnMapper from "./ColumnMapper.vue";

export default {
  components: {
    LeaderboardColumnMapper,
  },
  data: function () {
    return {
      sortDirs: [
        { label: "Ascending", value: "asc" },
        { label: "Descending", value: "desc" },
      ],
      openIconSelector: false,
    };
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    currentLeaderboard() {
      return this.$store.state.leaderboard.currentLeaderboard;
    },

    systemHeaders() {
      return this.$store.getters.leaderboardSystemHeaders;
    },

    csvHeaders() {
      return this.$store.getters.leaderboardCsvHeaders;
    },

    columnData() {
      return this.$store.getters.leaderboardColumnData;
    },

    columnMappings: {
      get() {
        return this.currentLeaderboard.columnMappings;
      },
      set(values) {
        this.$store.dispatch("patchCurrentLeaderboard", {
          columnMappings: values,
        });
      },
    },

    rankMappings() {
      return this.currentLeaderboard.rankMappings;
    },

    iconMappings() {
      return this.currentLeaderboard.iconMappings;
    },

    sourceColumns() {
      var label = "";
      if (this.currentLeaderboard.mode === "dynamic") {
        label = "Data Bucket Fields";
      } else {
        label = "CSV Columns";
      }
      return [
        { header: "System Fields" },
        ...this.systemHeaders,
        { header: label },
        ...this.csvHeaders,
      ];
    },
  },
  methods: {
    handleAddColumn(newMapping) {
      const newColumnMappings = [...this.columnMappings, newMapping];

      this.$store.dispatch("patchCurrentLeaderboard", {
        columnMappings: newColumnMappings,
      });
    },

    handleRemoveColumn(index) {
      const { columnMappings } = this;
      const newColumnMappings = [
        ...columnMappings.slice(0, index),
        ...columnMappings.slice(index + 1),
      ];

      this.$store.dispatch("patchCurrentLeaderboard", {
        columnMappings: newColumnMappings,
      });
    },

    // tbd start
    //handleOpenIconSelector() {
    //  this.openIconSelector = true;
    //},
    //
    //handleCloseIconSelector() {
    //  this.openIconSelector = false;
    //},
    //
    //handleSaveIconSelector(icon) {
    //  this.openIconSelector = false;
    //   this.$store.dispatch('patchCurrentLeaderboard', {
    //     iconMappings: icon,
    //   });
    //},
    // tbd end
  },
};
</script>

<style scoped>
.add-line {
  padding-left: 1px;
}

.ghost {
  border: 1px dotted #ccc;
}

.mapping-item {
  cursor: move;
}

.mapping-icon {
  width: 32px;
  height: 32px;
  padding-top: 0px;
}

.mapping-icon-card {
  width: 35px;
  height: 35px;
}

.mapping-icon-qualifier {
  width: 30px;
}
</style>

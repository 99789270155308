<template>
  <v-dialog
    v-model="open"
    :width="component.history.width"
    :fullscreen="component.history.fullscreen"
    persistent
    scrollable
  >
    <v-card :class="classes" :style="styles + ';' + gradient">
      <v-progress-linear
        v-if="processingPoints"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card-text>
        <v-alert
          v-if="
            variance !== 0 &&
            loadingPointsTable === false &&
            processingPoints === false
          "
          color="primary"
          border="left"
          text
          class="mx-0 pt-3"
        >
          <v-row align="center">
            <v-col cols="auto">
              <v-icon class="ml-2 mr-n2" size="26" color="primary"
                >error</v-icon
              >
            </v-col>
            <v-col class="incentable-alert">
              There is a discrepancy between your balance and the transactions
              below. Please contact support
            </v-col>
          </v-row>
        </v-alert>
        <v-row no-gutters class="incentable-name mb-5" justify="space-between">
          <v-col>
            <view-text
              :settings="component.history.historyTitle"
              :value="component.history.historyTitle.value"
            ></view-text>
          </v-col>
          <v-col
            cols="auto"
            class="pr-2"
            v-if="loadingPointsTable === false && processingPoints === false"
          >
            <view-text
              :settings="component.history.points"
              value="Balance:"
            ></view-text>
            <view-text
              :settings="component.history.subheading"
              value="Awarded:"
            ></view-text>
            <view-text
              :settings="component.history.subheading"
              value="Redeemed:"
            ></view-text>
          </v-col>
          <v-col
            cols="auto"
            v-if="loadingPointsTable === false && processingPoints === false"
          >
            <view-text
              :settings="component.history.points"
              :value="currentUser.balance | number"
            ></view-text>
            <view-text
              :settings="component.history.subheading"
              :value="currentUser.awarded | number"
            ></view-text>
            <view-text
              :settings="component.history.subheading"
              :value="currentUser.redeemed | number"
            ></view-text>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="mb-3">
          <v-col sm="3">
            <v-text-field
              v-model="search"
              solo-inverted
              dense
              flat
              hide-details
              label="Search"
              prepend-inner-icon="search"
              class="mr-4"
              :color="themePrimary"
            ></v-text-field>
          </v-col>

          <v-col sm="3" v-if="options.filter">
            <v-select
              solo-inverted
              dense
              flat
              hide-details
              label="Filter"
              prepend-inner-icon="filter_list"
              class="mr-4"
              multiple
              :items="statusList"
              v-model="filteredStatus"
              :color="themePrimary"
            ></v-select>
          </v-col>

          <v-col>
            <v-btn
              @click="handleOpenImportDialog()"
              v-if="options.upload"
              class="primary--text"
              text
              :disabled="demo"
              >Upload</v-btn
            >
            <v-btn
              v-if="options.download"
              class="primary--text"
              text
              :disabled="demo"
              >Download</v-btn
            >
            <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
          </v-col>

          <v-col sm="auto">
            <v-btn
              v-if="options.new"
              @click="handleNew()"
              color="primary"
              elevation="0"
              class="mr-n1"
            >
              <v-icon left>add</v-icon>new points transaction
            </v-btn>
          </v-col>
        </v-row>

        <v-card color="transparent" elevation="0" :style="detailTableStyles">
          <v-data-table
            :headers="headers"
            :items="filteredTransactions"
            :search="search"
            :options.sync="options"
            :loading="loadingPointsTable"
            :style="cssVars"
            class="table"
            no-data-text="No Transactions..."
          >
            <template v-slot:[`item.created`]="{ item }">
              {{ item.created }}
            </template>
            <template v-slot:[`item.points`]="{ item }">
              <v-row justify="end">
                {{ item.points | number }}
              </v-row>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="item.status === 'Adjustment'"
                color="red"
                @click="handleDeleteConfirmation(item)"
                >delete</v-icon
              >
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" dark @click="handleClose()" :color="themePrimary"
          >close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { TRANSACTION_TYPE } from "@/constants/transaction";
import { DEMO_USER } from "@/constants/webtheme/demouser";
import ViewText from "../Text.vue";

export default {
  created() {},
  props: ["open", "member", "memberId", "component"],
  components: {
    ViewText,
  },
  data: () => ({
    currentUser: DEMO_USER,
    // Table columns
    headers: [
      { text: "Date", value: "created", align: "left" },
      { text: "Status", value: "status", align: "left" },
      { text: "Description", value: "description", align: "left" },
      { text: "Notes", value: "notes", align: "left" },
      { text: "Points", value: "points", align: "right", sortable: false },
    ],
    closeDialog: false,
    statusList: TRANSACTION_TYPE,
    filteredStatus: [],
    search: "",
    selectedTransactionId: "",
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,
    notes: "",
    description: "",
    points: "",

    // Table settings
    options: {
      sortBy: [],
      sortDesc: [],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: false,
      download: false,
      more: false,
      new: false,
    },
  }),
  computed: {
    transactions() {
      return this.currentUser.transactions;
    },
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    themePrimary() {
      return this.webTheme.colors.primary.hexa;
    },
    loadingPointsTable() {
      return this.$store.getters.loadingPointsTable;
    },
    processingPoints() {
      return this.$store.getters.processingPoints;
    },
    filteredTransactions() {
      return this.filteredStatus.length
        ? this.transactions.filter((m) =>
            this.filteredStatus.includes(m.status)
          )
        : this.transactions;
    },
    arrBalance() {
      const arr = this.filteredTransactions;
      return arr.reduce((n, { points }) => n + points, 0);
    },
    variance() {
      const transBalance = this.transactions.reduce(
        (n, { points }) => n + points,
        0
      );
      return this.currentMemberPoints.balance - transBalance;
    },
    currentMemberPoints() {
      return this.currentUser;
    },
    cssVars() {
      return {
        "--header-text-color": this.component.history.content.heading.color,
        "--header-text-size":
          this.component.history.content.heading.size + "px",
        "--header-text-style": this.component.history.content.heading.style,
        "--header-text-weight": this.component.history.content.heading.weight,
        "--header-line-height":
          this.component.history.content.heading.lineHeight,
        "--header-font": this.component.history.content.heading.font,
        "--row-text-color": this.component.history.content.data.color,
        "--row-text-size": this.component.history.content.data.size + "px",
        "--row-text-style": this.component.history.content.data.style,
        "--row-text-weight": this.component.history.content.data.weight,
        "--row-line-height": this.component.history.content.data.lineHeight,
        "--row-font": this.component.history.content.data.font,
        "--text-align": this.component.history.content.align,
        "--row-border-bottom": this.rowBorders,
        "--row-hover-color": this.component.history.rowHoverColor.hexa,
        "--current-page-color": this.component.history.title.color,
      };
    },
    cssVarsTransactions() {
      return {
        "--header-text-color": this.component.history.content.heading.color,
        "--header-text-size":
          this.component.history.content.heading.size + "px",
        "--header-text-style": this.component.history.content.heading.style,
        "--header-text-weight": this.component.history.content.heading.weight,
        "--header-line-height":
          this.component.history.content.heading.lineHeight,
        "--header-font": this.component.history.content.heading.font,
        "--row-text-color": this.component.history.content.data.color,
        "--row-text-size": this.component.history.content.data.size + "px",
        "--row-text-style": this.component.history.content.data.style,
        "--row-text-weight": this.component.history.content.data.weight,
        "--row-line-height": this.component.history.content.data.lineHeight,
        "--row-font": this.component.history.content.data.font,
        "--text-align": "left",
        "--row-border-bottom": this.rowBorders,
        "--row-hover-color": this.component.history.rowHoverColor.hexa,
        "--current-page-color": this.component.history.title.color,
      };
    },
    classes() {
      if (this.component.history.fullscreen === true) return "rounded-0";
      return this.component.history.rounded;
    },
    rowBorders() {
      return (
        this.component.cart.rowBorderSize +
        "px" +
        " solid " +
        this.component.cart.rowBorderColor.hexa
      );
    },
    gradient() {
      if (this.component.history.color.type === "gradient") {
        var color = this.component.history.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return (
          "background: " + this.component.history.color.solidColor.hexa + ";"
        );
      }
    },
    styles() {
      var styles =
        "padding-bottom: " +
        this.component.history.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.history.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.history.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.history.padding.right +
        "px ;" +
        "border: " +
        this.component.history.borderSize +
        "px solid " +
        ";";
      this.component.history.borderColor.hexa + "; ";
      return styles;
    },
    detailTableStyles() {
      var styles =
        "margin-bottom: " +
        this.component.history.detail.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.history.detail.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.history.detail.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.history.detail.margin.right +
        "px ;";
      return styles;
    },
  },
  methods: {
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    clear() {
      this.$store.commit("setTransactions", []);
    },
    handleNew() {
      // Open New form
      this.selectedTransactionId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedTransactionId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedTransactionId = "";
      this.dialogNewOrEditOpen = false;
    },
    handleDeleteConfirmation(item) {
      // Delete record
      this.selectedTransactionId = item.id;
      this.points = item.points;
      this.description = item.description;
      this.notes = item.notes;
      this.dialogDeleteOpen = true;
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedTransactionId = "";
      this.dialogDeleteOpen = false;
    },
  },
};
</script>

<style scoped>
.points {
  font-size: 12px;
}
</style>

<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form
        @submit.prevent="handleSave('leaderboard')"
        data-vv-scope="leaderboard"
      >
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
            absolute
            color="primary"
            :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Add New
            {{ formTitle }}
          </v-card-title>
        </v-row>
        <v-card-text class="px-16">
          <v-text-field
            v-model="title"
            label="Leaderboard Name"
            v-validate="'required'"
            :error-messages="errors.collect('leaderboard.title')"
            name="title"
            type="text"
          />
          <span class="incentable-form-heading">Data Source</span>
          <v-radio-group
            name="mode"
            v-validate="'required'"
            :error-messages="errors.collect('leaderboard.mode')"
            column
            v-model="mode"
            :disabled="demo"
          >
            <v-radio label="Data Bucket" value="dynamic" />
            <v-radio label="CSV" value="static" />
          </v-radio-group>

          <span class="incentable-form-heading">Data Type</span>
          <span class="incentable-form-subheading ml-2"
            >Whose data is in the raw data?</span
          >
          <v-radio-group
            name="participantType"
            v-model="participantType"
            v-validate="'required'"
            :error-messages="errors.collect('leaderboard.participantType')"
            column
            class="mt-1"
            :disabled="demo"
          >
            <v-radio label="Members" value="member" />
            <v-radio label="Companies" value="company" />
          </v-radio-group>

          <v-row v-if="mode === 'dynamic'" no-gutters class="mb-3 mt-n3">
            <v-select
              v-model="selectedDatabucket"
              :items="databuckets"
              item-text="title"
              item-value="id"
              label="Select data bucket"
              :disabled="demo"
            />
          </v-row>

          <v-row xs12 v-if="errorMessage">
            <v-alert type="warning" :value="true">
              {{ errorMessage }}
            </v-alert>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4 pr-4">
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose"> Cancel </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            type="submit"
            :loading="isProcessing"
            :disabled="demo"
          >
            Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      dictionary: {
        custom: {
          title: {
            required: () => "Leaderboard Name is required",
          },
          mode: {
            required: () => "Data source is required",
          },
          participantType: {
            required: () => "Status is required",
          },
        },
      },
      title: "",
      accountKeyColumn: "",
      selectedDatabucket: "",
      selectedCompanyTags: [],
      selectedMemberTags: [],
      //
      columnData: [],
      columnMappings: [],
      //
      selectedStatus: "Active",
      statuses: ["Active", "Inactive", "Archive"],
      mode: "dynamic",
      participantType: "member",
      svgDescription: "Medal",
      svgFile: "005-medal.svg",
      isProcessing: false,
      errorMessage: "",
      formTitle: "Leaderboard",
      formIcon: "emoji_events",
    };
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    databuckets() {
      return this.$store.state.databuckets.databuckets;
    },
  },
  methods: {
    reset() {
      this.title = "";
      this.accountKeyColumn = "";
      this.selectedDatabucket = "";
      this.mode = "dynamic";
      this.selectedCompanyTags = [];
      this.selectedMemberTags = [];
      (this.columnData = []),
        (this.columnMappings = []),
        this.$validator.reset();
      this.selectedStatus = "Active";
    },

    handleClose() {
      this.reset();
      this.$emit("onClose");
    },

    handleSave(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        if (!validationResult) {
          return;
        }

        this.isProcessing = true;
        var akCol = this.mode === "dynamic" ? "Account Id" : null;
        const payload = {
          status: this.selectedStatus,
          accountKeyColumn: akCol,
          columnData: this.columnData,
          columnMappings: this.columnMappings,
          title: this.title,
          selectedDatabucket: this.selectedDatabucket || "",
          participantType: this.participantType,
          mode: this.mode,
          iconMappings: {
            svgDescription: this.svgDescription,
            svgFile: this.svgFile,
          },
          order: "0",
          companyTags: this.selectedCompanyTags,
          memberTags: this.selectedMemberTags,
          titleUppercase: this.title.toUpperCase(),
          displayTitle: this.title,
          displayTitleUppercase: this.title.toUpperCase(),
        };

        this.$store
          .dispatch("createLeaderboard", payload)
          .then(() => {
            this.isProcessing = false;
            this.handleClose();
          })
          .catch((msg) => {
            this.isProcessing = false;
            this.errorMessage = msg;
          });
      });
    },
  },
};
</script>

<style scoped></style>

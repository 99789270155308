<template>
  <div>
    <live-button></live-button>
    <!-- v-speed-dial is not avilable in Vuetify 3 -->
    <v-speed-dial
      style="margin-bottom: 74px; z-index: 200"
      fixed
      absolute
      bottom
      right
      v-model="fab"
      direction="top"
      open-on-hover
      transition="fade-transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
          elevation="0"
          @click="handleDesignDrawer()"
        >
          <v-icon v-if="fab && designDrawer"> close </v-icon>
          <v-icon v-else> settings </v-icon>
        </v-btn>
      </template>
      <v-btn
        color="secondary"
        fab
        small
        elevation="0"
        @click="handleDesignDrawer()"
      >
        <v-icon>settings</v-icon>
      </v-btn>
      <v-btn color="secondary" fab small elevation="0" @click="toggleMenu()">
        <v-icon>menu</v-icon>
      </v-btn>
      <v-btn color="secondary" fab small elevation="0" @click="toggleGrid()">
        <v-icon v-if="grid.block === 'grid-off'">grid_on</v-icon>
        <v-icon v-else>grid_off</v-icon>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import LiveButton from "../../Shared/LiveButton.vue";
export default {
  components: {
    LiveButton,
  },
  created() {},
  data: function () {
    return {
      fab: true,
      drawer: false,
    };
  },
  computed: {
    grid() {
      return this.$store.getters.grid;
    },
    toolbar() {
      return this.$store.state.toolbar;
    },
    designDrawer() {
      return this.$store.getters.designDrawer;
    },
    openDesignDrawer() {
      return this.$store.getters.openDesignDrawer;
    },
  },
  methods: {
    toggleMenu() {
      if (this.toolbar === true) {
        this.$store.dispatch("setToolbar", false);
        this.$store.dispatch("setNavMenu", false);
        this.$store.dispatch("setDrawer", false);
        this.$store.dispatch("setNavTitle", false);
      } else if (this.toolbar === false) {
        this.$store.dispatch("setToolbar", true);
        this.$store.dispatch("setNavMenu", true);
        this.$store.dispatch("setDrawer", true);
      }
      return;
    },
    handleDesignDrawer() {
      if (this.openDesignDrawer === false) {
        this.$store.dispatch("setOpenDesignDrawer", true);
      } else {
        this.$store.dispatch("setOpenDesignDrawer", false);
      }
      if (this.designDrawer === null) {
        this.$store.dispatch("setDesignDrawer", "Content");
        this.$store.dispatch("setSelectedPageTemplate", "Home");
      } else {
        return;
      }
    },
    toggleGrid() {
      if (this.grid.block === "grid-block") {
        const val = {
          block: "grid-off",
          outer: "grid-off",
          inner: "grid-off",
        };
        this.$store.dispatch("setGrid", val);
      } else {
        const val = {
          block: "grid-block",
          outer: "grid-outer",
          inner: "grid-inner",
        };
        this.$store.dispatch("setGrid", val);
      }
    },
  },
};
</script>

<style scoped>
.design-footer {
  z-index: 3000 !important;
}
</style>

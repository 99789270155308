import { db, timestamp } from "@/firebase";

const state = {
  logs: [],
};

const actions = {
  async loadLogs({ commit }) {
    commit("setLoadingTable", true);
    let querySnapshot;
    try {
      querySnapshot = await db.collection("logs").get();
    } catch (e) {
      querySnapshot = [];
    }
    const logs = [];
    querySnapshot.forEach(function (doc) {
      const data = doc.data();
      const { created } = data;
      logs.push({
        action: data.action,
        name: data.name,
        email: data.email,
        created: created && created.toDate ? created.toDate() : created,
        incentableAdmin: data.incentableAdmin,
        programName: data.programName,
        hostname: data.hostname,
        pathname: data.pathname,
        url: data.url,
        from: data.from,
        to: data.to,
      });
    });
    commit("setLogs", logs);
    commit("setLoadingTable", false);
  },

  async addLog({ commit, getters }, payload) {
    if (getters.email === "jayson@incentable.com") {
      return;
    } else {
      const logsCollection = db.collection("logs");
      const log = {
        ...payload,
        created: timestamp,
        email: getters.email,
        //name: getters.name,
        incentableAdmin: getters.isIncentableAdmin,
        hostname: window.location.hostname,
        pathname: window.location.pathname,
        url: window.location.hostname + window.location.pathname,
      };
      let newLogRef;
      try {
        newLogRef = await logsCollection.add(log);
      } catch (e) {
        throw "error when creating a new log" + e;
      }

      // Note: server time is unavailable until we refetch.
      const tempLog = {
        ...log,
        id: newLogRef.id,
        created: timestamp,
      };

      commit("addLogItem", tempLog);

      return "ok";
    }
  },
};

const mutations = {
  addLogItem(state, payload) {
    state.logs = [...state.logs, payload];
  },

  setLogs(state, payload) {
    state.logs = payload;
  },
};

const getters = {
  Logs(state) {
    return state.logs;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

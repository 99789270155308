/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  gallery: [],
  galleryDialog: false,
};

const actions = {
  async loadGallery({ commit }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db.collection("gallery").get();
    } catch (e) {
      querySnapshot = [];
    }

    const gallery = [];
    querySnapshot.forEach(function (doc) {
      gallery.push({
        id: doc.id,
        smallUrl: doc.data().smallUrl,
        mediumUrl: doc.data().mediumUrl,
        largeUrl: doc.data().largeUrl,
        smallStoragePath: doc.data().smallStoragePath,
        mediumStoragePath: doc.data().mediumStoragePath,
        largeStoragePath: doc.data().largeStoragePath,
        category: doc.data().category,
      });
    });
    commit("setGallery", gallery);
    commit("setLoadingTable", false);
  },

  async createImage({ commit, getters, dispatch }, payload) {
    var galleryRef = db.collection("gallery").doc();
    const image = {
      id: galleryRef.id,
      name: payload.name,
      smallUrl: payload.smallUrl,
      mediumUrl: payload.mediumUrl,
      largeUrl: payload.largeUrl,
      category: payload.category || "Default",
      smallStoragePath: payload.smallStoragePath,
      mediumStoragePath: payload.mediumStoragePath,
      largeStoragePath: payload.largeStoragePath,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };
    galleryRef.set(image);
    commit("createImage", image);
    dispatch("setLoading", false);
    commit("setSnackbar", "Image added");
    return "ok";
  },

  async deleteImage({ commit }, payload) {
    try {
      await db.collection("gallery").doc(payload.id).delete();
    } catch (e) {
      throw "error when deleting an image";
    }
    commit("deleteImage", payload.id);
    commit("setSnackbar", "Image deleted");
  },

  // Note: This is only updating the store, not persisting with db
  patchCurrentGallery({ commit }, payload) {
    commit("patchCurrentGallery", payload);
  },

  setGalleryDialog({ commit }, payload) {
    commit("setGalleryDialog", payload);
  },
};

const mutations = {
  setGallery(state, payload) {
    state.gallery = payload;
  },

  setGalleryDialog(state, payload) {
    state.galleryDialog = payload;
  },

  createImage(state, payload) {
    state.gallery = [...state.gallery, payload];
  },

  updateImage(state, payload) {
    state.gallery = state.gallery.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  patchCurrentGallery(state, payload) {
    state.currentGallery = {
      ...state.currentGallery,
      ...payload,
    };

    state.pendingGalleryUpdates = {
      ...state.pendingGalleryUpdates,
      ...payload,
    };
  },
  hasGalleryPendingUpdates(state) {
    return Object.keys(state.pendingGalleryUpdates).length !== 0;
  },

  deleteImage(state, payload) {
    state.gallery = state.gallery.filter((item) => item.id !== payload);
  },
};

const getters = {
  gallery(state) {
    return state.gallery;
  },

  galleryDialog(state) {
    return state.galleryDialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

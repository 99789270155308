/* eslint-disable no-console */
import { db } from "@/firebase";
import { ROLES_DICT } from "@/constants/roles";

const state = {
  organisations: [],
  currentOrganisation: {},
  organisationId: "",
  admins: [],
  orgTitleExists: false,
  orgSubdomainExists: false,
  newOrganisationDialog: false,
  isIncentableAdmin: false,
  adminIsExist: false,
  paymentError: null,
};

const actions = {
  async createOrganisation({ commit, getters }, payload) {
    commit("setLoading", true);
    commit("setOrgTitleExists", false);
    commit("setOrgSubdomainExists", false);

    // create a new organisation collection
    const organisation = {
      title: payload.title,
      titleUppercase: payload.titleUppercase,
      orgSubdomain: payload.orgSubdomain,
      primary: payload.primary,
      buttonTextColor: payload.buttonTextColor,
      subdomainConnected: false,
      createdAt: payload.createdAt,
      createdBy: getters.user.id,
      admins: payload.admins,
    };
    try {
      const titleCheck = payload.titleUppercase;
      const orgSubdomainCheck = payload.orgSubdomain;

      const collectionRef = db.collection("organisations");
      const titleCheckQuery = collectionRef
        .where("titleUppercase", "==", titleCheck)
        .get();
      const orgSubdomainCheckQuery = collectionRef
        .where("orgSubdomain", "==", orgSubdomainCheck)
        .get();
      const checkRequests = [titleCheckQuery, orgSubdomainCheckQuery];

      const [titleQuerySnapshot, orgSubdomainQuerySnapshot] = await Promise.all(
        checkRequests
      );
      if (titleQuerySnapshot.size > 0) {
        // If organisation title is duplicated...
        commit("setOrgTitleExists", true);
        commit("setLoading", false);
        return;
      }
      if (orgSubdomainQuerySnapshot.size > 0) {
        // If organisation orgSubdomain is duplicated...
        commit("setOrgSubdomainExists", true);
        commit("setLoading", false);
        return;
      }
      const docRef = await db.collection("organisations").add(organisation);
      const key = docRef.id;
      commit("setNewOrganisationDialog", false);
      commit("createOrganisation", { ...organisation, id: key });
      commit("setLoading", false);
      commit("setSnackbar", "Organisation Created");
    } catch (err) {
      console.log(err);
      commit("setLoading", false);
    }
  },

  async updateCurrentOrganisation({ commit, getters, dispatch }, payload) {
    const organisationsRef = db
      .collection("organisations")
      .doc(getters.organisationId);

    try {
      await organisationsRef.update(payload);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating organisation.";
    }

    commit("patchCurrentOrganisation", payload);
    dispatch("setSnackbar", "Organisation updated.");
  },

  async updateIncentableSettings({ commit, dispatch }, payload) {
    const organisationsRef = db.collection("organisations");

    let storedOrganisation;
    try {
      const organisationDoc = await organisationsRef.doc(payload.id).get();
      storedOrganisation = organisationDoc.data();
    } catch (e) {
      storedOrganisation = null;
    }
    if (!storedOrganisation) {
      throw "Error occured when fetching the organisation info";
    }

    let dupCheckRequests;
    let orgSubdomainDupSnapshot;

    dupCheckRequests = [
      organisationsRef.where("orgSubdomain", "==", payload.orgSubdomain).get(),
    ];

    try {
      [orgSubdomainDupSnapshot] = await Promise.all(dupCheckRequests);
    } catch (e) {
      throw "Error occured when checking subdomain";
    }

    if (orgSubdomainDupSnapshot && orgSubdomainDupSnapshot.size > 0) {
      let duplicated = false;
      orgSubdomainDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Sorry, that Subdomain is already registered or not allowed.";
      }
    }
    const { id, ...organisationInput } = payload;
    //end
    try {
      await organisationsRef.doc(id).update(organisationInput);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating organisation.";
    }

    const tempOrganisation = {
      ...storedOrganisation,
      ...organisationInput,
      id,
      updated: new Date(),
    };

    commit("patchCurrentOrganisation", tempOrganisation);
    dispatch("setSnackbar", "Organisation updated.");
  },

  async loadCurrentOrganisation({ commit }, id) {
    let organisationSnapshot;
    try {
      organisationSnapshot = await db.collection("organisations").doc(id).get();
    } catch (e) {
      commit("setOrganisationId", "");
      commit("setCurrentOrganisation", null);
      throw 500;
    }

    const organisationData = organisationSnapshot.data();
    const currentOrganisation = {
      ...organisationData,
      createdAt: organisationData.createdAt.toDate(),
    };

    if (!currentOrganisation) {
      commit("setOrganisationId", "");
      commit("setCurrentOrganisation", null);
      throw 404;
    }

    commit("setOrganisationId", id);
    commit("setCurrentOrganisation", currentOrganisation);
  },

  checkOrganisation({ dispatch }) {
    var host = location.host;
    if (host === "sqn.incentive.studio") {
      dispatch("loadCurrentOrganisation", "IFvENF0OZsgILhZ70h06");
    } else {
      dispatch("loadCurrentOrganisation", "XwkdAsn6UEPjoGttp1ER");
    }
  },

  async loadOrganisations({ commit, getters, dispatch }) {
    commit("setOrganisations", []);
    commit("setLoadingCards", true);
    commit("setIncentableAdmin", false);
    try {
      await dispatch("loadAdmins");
      const user = getters.user;
      const incentableAdminSnapshot = await db
        .collection("incentableAdmins")
        .where("userId", "==", user.id)
        .get();

      if (incentableAdminSnapshot.size > 0) {
        // user id IncentableAdmin
        commit("setIncentableAdmin", true);
      }
      const organisations = [];
      // check current use is Admin/Editor/IncentableAdmin
      if (getters.isIncentableAdmin) {
        const querySnapshot = await db
          .collection("organisations")
          .orderBy("titleUppercase")
          .get();
        querySnapshot.forEach(function (doc) {
          organisations.push({
            id: doc.id,
            title: doc.data().title,
            titleUppercase: doc.data().titleUppercase,
            orgSubdomain: doc.data().orgSubdomain,
            primary: doc.data().primary,
            buttonTextColor: doc.data().buttonTextColor,
            subdomainConnected: doc.data().subdomainConnected,
            // convert timestamp to date object
            createdAt: doc.data().createdAt.toDate(),
            createdBy: doc.data().createdBy,
            admins: doc.data().admins,
            logoUrl: doc.data().logoUrl,
            menuBarLogoUrl: doc.data().menuBarLogoUrl,
            signinLogoUrl: doc.data().signinLogoUrl,
          });
        });
      } else {
        const linkedOrgIds = getters.currentAdmin.organisations;
        const requests = linkedOrgIds.map((organisationId) => {
          return db.collection("organisations").doc(organisationId).get();
        });
        const querySnapshot = await Promise.all(requests);
        querySnapshot.forEach(function (doc) {
          organisations.push({
            id: doc.id,
            title: doc.data().title,
            titleUppercase: doc.data().titleUppercase,
            orgSubdomain: doc.data().orgSubdomain,
            primary: doc.data().primary,
            buttonTextColor: doc.data().buttonTextColor,
            subdomainConnected: doc.data().subdomainConnected,
            // convert timestamp to date object
            createdAt: doc.data().createdAt.toDate(),
            createdBy: doc.data().createdBy,
            orgAdmins: doc.data().orgAdmins,
            logoUrl: doc.data().logoUrl,
            menuBarLogoUrl: doc.data().menuBarLogoUrl,
            signinLogoUrl: doc.data().signinLogoUrl,
          });
        });
      }
      commit("setOrganisations", organisations);
      commit("setLoadingCards", false);
    } catch (error) {
      console.log(error);
      commit("setLoadingCards", false);
    }
  },

  async addOrgAdmin({ state, commit, getters, dispatch }, payload) {
    // TODO: fetch current organisation and compare with stored organisation
    // If there is difference in admins field, do not allow any kind of save
    // But notify user that he needs to refresh the browser.

    const storedAdmins = state.currentOrganisation.admins || [];
    const dupAdmin = storedAdmins.find(
      (item) => item.adminEmail === payload.adminEmail
    );
    if (dupAdmin) {
      throw "Admin already exists.";
    }

    const newAdmins = [...storedAdmins, payload];
    try {
      await db.collection("organisations").doc(getters.organisationId).update({
        admins: newAdmins,
        updatedBy: getters.user.id,
      });
    } catch (e) {
      console.error(e);
      throw "Error when updating Organisation member list.";
    }

    commit("patchCurrentOrganisation", { admins: newAdmins });
    dispatch("setSnackbar", "Admin added.");
  },

  async updateOrgAdmin({ state, commit, getters, dispatch }, payload) {
    // TODO: fetch current organisation and compare with stored organisation
    // If there is difference in orgAdmins field, do not allow any kind of save
    // But notify user that he needs to refresh the browser.

    const storedOrgAdmins = state.currentOrganisation.admins || [];

    const newOrgAdmins = storedOrgAdmins.map((storedAdmin) => {
      if (storedAdmin.adminEmail === payload.adminEmail) {
        return payload;
      }
      return storedAdmin;
    });

    try {
      await db.collection("organisations").doc(getters.organisationId).update({
        admins: newOrgAdmins,
        updatedBy: getters.user.id,
      });
    } catch (e) {
      console.error(e);
      throw "Error when updating orgOrgAdmins list.";
    }

    commit("patchCurrentOrganisation", { admins: newOrgAdmins });
    dispatch("setSnackbar", "Admin updated.");
  },

  async deleteOrgAdmin({ state, commit, getters, dispatch }, email) {
    // TODO: fetch current organisation and compare with stored organisation
    // If there is difference in admins field, do not allow any kind of save
    // But notify user that he needs to refresh the browser.

    const storedOrgAdmins = state.currentOrganisation.admins || [];

    const newOrgAdmins = storedOrgAdmins.filter((storedAdmin) => {
      return storedAdmin.adminEmail !== email;
    });

    try {
      await db.collection("organisations").doc(getters.organisationId).update({
        admins: newOrgAdmins,
        updatedBy: getters.user.id,
      });
    } catch (e) {
      console.error(e);
      throw "Error when updating Orgainisation Admins list.";
    }

    commit("patchCurrentOrganisation", { admins: newOrgAdmins });
    dispatch("setSnackbar", "Organisation Admin deleted");
  },

  deleteOrganisation({ commit }, id) {
    commit("setLoading", true);
    db.collection("organisations").doc(id).delete();
    commit("setSnackbar", {
      status: true,
      text: "Organisation deleted",
    });
    commit("setLoading", false);
  },

  selectOrganisation({ commit }, organisation) {
    commit("setCurrentOrganisation", organisation);
    const organisationId = organisation.id;
    commit("setOrganisationId", organisationId);
  },

  setOrgTitleExists({ commit }, payload) {
    commit("setOrgTitleExists", payload);
  },

  setOrgSubdomainExists({ commit }, payload) {
    commit("setOrgSubdomainExists", payload);
  },

  setNewOrganisationDialog({ commit }, payload) {
    commit("setNewOrganisationDialog", payload);
  },

  setIncentableAdmin({ commit }, payload) {
    commit("setIncentableAdmin", payload);
  },

  setOrgAdminIsExist({ commit }, payload) {
    commit("setOrgAdminIsExist", payload);
  },

  setOrgAdmins({ commit }, payload) {
    commit("setOrgAdmins", payload);
  },
};

const mutations = {
  setOrganisations(state, payload) {
    state.organisations = payload;
  },

  setAdmins(state, payload) {
    state.admins = payload;
  },

  createOrganisation(state, payload) {
    state.organisations.push(payload);
  },

  setOrganisationId(state, id) {
    state.organisationId = id;
  },

  setCurrentOrganisation(state, organisation) {
    state.currentOrganisation = organisation;
  },

  patchCurrentOrganisation(state, payload) {
    state.currentOrganisation = {
      ...state.currentOrganisation,
      ...payload,
    };
  },

  setOrgTitleExists(state, payload) {
    state.orgTitleExists = payload;
  },

  setOrgSubdomainExists(state, payload) {
    state.orgSubdomainExists = payload;
  },

  setNewOrganisationDialog(state, payload) {
    state.newOrganisationDialog = payload;
  },

  setIncentableAdmin(state, payload) {
    state.isIncentableAdmin = payload;
  },

  setOrgAdminIsExist(state, payload) {
    state.orgAdminIsExist = payload;
  },
};

const getters = {
  organisations(state) {
    return state.organisations;
  },

  organisationId(state) {
    return state.organisationId;
    // return 'p8aPVkYiGsNfIqffINqG'
  },

  currentOrganisation(state) {
    return state.currentOrganisation;
  },

  orgTheme() {
    //console.log(getters.currentOrganisation.primary)
    //if (getters.currentOrganisation.primary) {
    //  primaryColor = getters.currentOrganisation.primary
    //}
    //console.log(primaryColor)
    return {
      dark: true,
      title: "Incentable",
      buttonTextColor: "white--text",
      // buttonTextColor: 'black--text',
      buttonIconColor: "white",
      // buttonIconColor: 'black',
      // primary: '#14adff', // blue
      // primary: '#D1D1D1', // silver
      // primary: '#000000', // black
      // primary: '#00C853', // green
      primary: "#e91e63", // incentable pink
      // primary: '#FF9800', // orange
      // primary: '#CCE71F', // lime
      // primary: state.currentOrganisation.primary || '#e91e63',
      // secondary: '#14adff', // blue
      secondary: "#212121", // dark grey
      tertiary: "#37059B", // purple
    };
  },

  currentOrgUserRole(state, getters) {
    if (state.isIncentableAdmin) {
      return ROLES_DICT.super;
    }

    const admins = state.currentOrganisation.admins;
    const userEmail = getters.email;
    const adminDetail = admins.find((admin) => admin.adminEmail === userEmail);

    if (!adminDetail) {
      return null;
    }

    return adminDetail.adminRole;
  },

  orgTitleExists(state) {
    return state.orgTitleExists;
  },

  orgSubdomainExists(state) {
    return state.orgSubdomainExists;
  },

  newOrganisationDialog(state) {
    return state.newOrganisationDialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>          
  <v-dialog 
    v-model="isVisible"
    :width="dialogWidth"
    :fullscreen="component.dialog.fullscreen"
    persistent
  >
    <v-card
      :class="classes"
      :style="styles + ';' + gradient"
    >

      <v-img
        v-if="component.dialog.image.show === true"
        :height="component.dialog.image.height + 'px'"
        :lazy-src="reward.externalImageUrl ? reward.externalImageUrl : reward.image.mediumUrl"
        :src="reward.externalImageUrl ? reward.externalImageUrl : reward.image.mediumUrl"
        :class="reward.radius ? 'radius' : ''"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img> 
    
    <v-btn
      v-if="component.dialog.fullscreen === true"
      top right absolute small icon
      @click="onClose"
      style="z-index: 100"
    >
      <v-icon :color="component.dialog.title.color">clear</v-icon>
    </v-btn>  
      
        <v-card-text
          :style="textArea"
        >
         <v-row no-gutters v-if="reward.title">
          <v-col>
            <view-text :settings="component.heading" :value="reward.title" />
          </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <view-text :settings="component.points" :value="formatPoints(reward.points) + ' ' + pointsLabel" />
            </v-col>
          </v-row>
          <v-row no-gutters v-if="reward.body">
            <v-col>
              <view-text :settings="component.body" :value="reward.body"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-4 mb-n6">
          <v-row no-gutters align="center" justify="center">
            <v-col cols="auto" class="pb-7" align="end">
              <v-icon color="red" medium @click="minus()" class="mx-2">remove_circle_outline</v-icon>
            </v-col>
            <v-col cols="1" align="end">
              <v-text-field
                class="centered-input"
                v-model="quantity"
                id="quantity"
                name="Quantity"
                outlined
                dense
                background-color="white"
                color="grey"
                filled
                type="number"
                hide-spin-buttons
              />
            </v-col>
            <v-col cols="auto" class="pb-7" align="end">
              <v-icon color="green" medium @click="plus()" class="ml-2">add_circle_outline</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :class="component.dialog.buttonText.color"
          v-if="component.dialog.fullscreen !== true"
          @click="reset()"
          text
          :color="component.dialog.title.color"
        >close</v-btn>
        <v-btn
          :class="component.dialog.buttonText.color"
          elevation="0"
          :disabled="quantity < 1"
          v-if="component.dialog.fullscreen !== true"
          @click="reset()"
          :color="component.dialog.buttonColor.hexa"
        >
          {{ buttonLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import numeral from 'numeral'
import ViewText from '../Text.vue'
import moment from 'moment'

 export default {
  components: {
    ViewText
  },
  props: ['isVisible', 'onClose', 'reward', 'component'],
  created () {
  },
  data: function () {
    return {
      quantity: 1
    }
   },
   computed: {
    pointsLabel () {
      return this.$store.state.program.currentProgram.points.label
    },
    buttonLabel () {
      if (this.quantity === 1) {
        return 'add to cart'
      }
      const points = this.$options.filters.number(this.reward.points * this.quantity)
      return 'add ' + this.quantity + ' to cart for '+ points + ' points'
    },
    classes () {
      if (this.component.dialog.fullscreen === true)
      return 'rounded-0'
      return this.component.card.rounded
    },
    dialogWidth() {
      return this.component.dialog.width
    },
    grid() {
      return this.$store.getters.grid
    },
    rowBorders() {
      return this.component.dialog.rowBorderSize + 'px' + 
      ' solid ' + 
      this.component.dialog.rowBorderColor.hexa
    },
    iconAlign() {
      return this.component.dialog.content.align
    },
    iconSize() {
      return this.component.dialog.iconSize
    },
    primary() {
      return this.webTheme.colors.primary.hex
    },
    gradient() {
      if (this.component.dialog.color.type === 'gradient') {
        var color = this.component.dialog.color
        var gradient = 
        'background: linear-gradient(' +
        color.direction + ', rgba(' +
        color.gradientFrom.rgba.r + ', ' +
        color.gradientFrom.rgba.g + ', ' +
        color.gradientFrom.rgba.b + ', ' +
        color.gradientFrom.rgba.a + ') ' + 
        ', rgba(' +
        color.gradientTo.rgba.r + ', ' +
        color.gradientTo.rgba.g + ', ' +
        color.gradientTo.rgba.b + ', ' +
        color.gradientTo.rgba.a + ')' + color.gradientHeight + '%)'
        return gradient
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return 'background: ' + this.component.dialog.color.solidColor.hexa + ';'
      }
    },
    styles () {
      var styles = 
        'padding-bottom: ' + this.component.dialog.padding.bottom + 'px ;' +
        'padding-top: ' + this.component.dialog.padding.top + 'px ;' +
        'padding-left: ' + this.component.dialog.padding.left + 'px ;' +
        'padding-right: ' + this.component.dialog.padding.right + 'px ;' +
        'border: ' + this.component.dialog.borderSize + 'px solid ' +
        this.component.dialog.borderColor.hexa + '; '
      return styles
    },
    textArea () {
      var styles = 
        'padding-bottom: ' + this.component.dialog.textAreaPadding.bottom + 'px ;' +
        'padding-top: ' + this.component.dialog.textAreaPadding.top + 'px ;' +
        'padding-left: ' + this.component.dialog.textAreaPadding.left + 'px ;' +
        'padding-right: ' + this.component.dialog.textAreaPadding.right + 'px ;'
      return styles
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
  },
  methods: {
    plus() {
      if (this.quantity === isNaN) {
        this.quantity = parseInt(1, 0)
      }
      this.quantity = parseInt(this.quantity, 0) + 1
    },
    minus() {
      if (this.quantity > 1) {
        this.quantity = parseInt(this.quantity, 0) - 1
      }
      return
    },
    date (date) {
      return moment(date).format('ddd MMMM Do YYYY')
    },
    formatPoints (val) {
      return numeral(val).format('0,0')
    },
    reset() {
      this.onClose(); 
      console.log('reset')
      this.quantity = 1
    }
  }
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}
.radius {
  border-radius: 15px !important;
}
</style>


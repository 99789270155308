<template>
  <v-container class="page mb-15" fluid>
    <v-tabs>
      <v-tab class="tab"> Orders By Item </v-tab>
      <v-tab class="tab"> Orders By Member </v-tab>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <OrdersByItemTable />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <OrdersByMemberTable />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import OrdersByMemberTable from "./OrdersByMemberTable.vue";
import OrdersByItemTable from "./OrdersByItemTable.vue";
export default {
  components: {
    OrdersByMemberTable,
    OrdersByItemTable,
  },
  data() {
    return {
      active: "",
    };
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadRewardSuppliers");
    this.$store.dispatch("setNavMenu", true);
  },
};
</script>

<style scoped></style>

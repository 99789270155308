<!-- eslint-disable no-prototype-builtins -->
<template>
  <v-dialog v-model="open" persistent max-width="1200px">
    <v-card>
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
        <v-overlay absolute color="primary" :opacity="systemTheme.cardOpacity">
        </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar size="100" color="white" outline>
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-16">
        <v-row no-gutters class="incentable-form-heading">
          <v-col>
            {{ this.stepHeading }}
          </v-col>
        </v-row>
        <v-row dense class="incentable-form-subheading">
          <v-col>
            {{ this.stepDescription }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <csv-import
              class="pl-5"
              v-if="step === 0"
              v-model="parsedCsv"
              :map-fields="mappingFields"
              :required-fields="requiredFields"
            />
          </v-col>
        </v-row>

        <div v-if="step === 1">
          <v-row dense>
            <v-col>
              <v-card class="pr-4">
                <v-card-title>
                  <v-icon color="primary" class="pr-4">account_circle</v-icon>
                  {{ parsedCsv.length }}
                </v-card-title>
                <v-card-text class="incentable-page-subheading">
                  Members found in your CSV
                </v-card-text>
              </v-card>
            </v-col>

            <v-col>
              <v-card class="pr-4">
                <v-card-title>
                  <v-icon color="primary" class="pr-4">verified_user</v-icon>
                  {{ validNewMembers.length }}
                </v-card-title>
                <v-card-text class="incentable-page-subheading">
                  Are valid and will be imported
                </v-card-text>
              </v-card>
            </v-col>

            <v-col>
              <v-card class="pr-4">
                <v-card-title>
                  <v-icon color="primary" class="pr-4">label</v-icon>
                  {{ newMemberTagsArray.length }}
                </v-card-title>
                <v-card-text class="incentable-page-subheading">
                  Member tag(s) will be created
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-alert
            v-if="validNewMembers.length !== parsedCsv.length"
            color="primary"
            border="left"
            dense
            text
          >
            <v-row dense no-gutters align="center">
              <v-col cols="auto">
                <v-icon color="primary" class="mx-2" size="26"
                  >account_circle</v-icon
                >
              </v-col>
              <v-col class="pl-2 incentable-alert">
                {{ parsedCsv.length - validNewMembers.length }} omitted from
                import because they already exist <br />If you wish to change or
                update the existing member, add it's Account Key to the CSV
                <br />You can still proceed, the omitted members will be left
                out of the import
              </v-col>
            </v-row>
          </v-alert>

          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="header in importedCsvHeaders"
                    :key="header"
                    class="text-left"
                  >
                    {{ header }}
                  </th>
                  <th class="text-left">Status</th>
                  <th class="text-left">Comment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="member in newMembers" :key="member['Email']">
                  <td v-for="header in importedCsvHeaders" :key="header">
                    {{ member[header] }}
                  </td>
                  <td>
                    <v-chip v-if="!member.isValid" color="red" small
                      >Omit</v-chip
                    >
                    <v-chip
                      v-if="member.isValid && member.isUpdate"
                      color="amber"
                      small
                      >Update</v-chip
                    >
                    <v-chip
                      v-if="member.isValid && !member.isUpdate"
                      color="green"
                      small
                      >New</v-chip
                    >
                  </td>
                  <td class="caption">
                    {{ member.isUpdate ? "Updating" : member.logs }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div v-if="step === 2">
          <v-row>
            <v-col align="center" v-if="!processSuccess">
              <div>
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="red"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div class="incentable-page-subheading red--text my-8">
                Processing
              </div>
              <v-alert color="red" dense text border="left">
                <v-row align="center">
                  <v-col lg="1">
                    <v-icon color="red" class="mx-2" size="26">warning</v-icon>
                  </v-col>
                  <v-col class="pl-4 incentable-alert" md="auto">
                    Do not close browser while processing
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>

            <v-col align="center" v-if="processSuccess">
              <div>
                <v-icon size="80" color="green" class="mt-7">check</v-icon>
              </div>
              <div class="incentable-page-subheading my-2 primary--text">
                Success
              </div>
            </v-col>

            <v-col align="center" v-if="processFailed">
              <div>
                <v-icon size="80" color="red" class="mt-7">close</v-icon>
              </div>
              <div class="incentable-page-subheading my-2 red--text">
                Import Failed
              </div>
            </v-col>

            <v-col>
              <div v-for="logItem in processingDetails" :key="logItem">
                {{ logItem }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="!isLastStep"
          color="primary"
          elevation="0"
          text
          @click="close()"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="!isLastStep"
          color="primary"
          elevation="0"
          :disabled="!isNextStepReady"
          @click="gotoNext()"
        >
          Next Step
        </v-btn>
        <v-btn
          v-if="isLastStep && processSuccess"
          color="primary"
          elevation="0"
          @click="gotoNext()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as utils from "@/utils";
import { AVATAR_COLORS } from "@/constants/colors";
const TOTAL_STEP = 3;

const CSV_MAPPING = {
  FNAME: "First Name",
  LNAME: "Last Name",
  EMAIL: "Email",
  ACCOUNT_KEY: "Data Key",
  TAGS: "Tags",
  COMPANY_KEYS: "Company Keys",
  EXTERNAL_IMAGE_URL: "Profile Image URL (externally hosted)",
};

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    const mappingFields = [
      CSV_MAPPING.FNAME,
      CSV_MAPPING.LNAME,
      CSV_MAPPING.EMAIL,
      CSV_MAPPING.ACCOUNT_KEY,
      CSV_MAPPING.TAGS,
      CSV_MAPPING.COMPANY_KEYS,
      CSV_MAPPING.EXTERNAL_IMAGE_URL,
    ];
    const requiredFields = [
      CSV_MAPPING.FNAME,
      CSV_MAPPING.LNAME,
      CSV_MAPPING.EMAIL,
    ];
    return {
      formTitle: "Upload Members",
      formIcon: "cloud_upload",
      mappingFields,
      requiredFields,
      step: 0,
      parsedCsv: null,
      newMembers: [],
      newMemberTagsArray: [],
      updatingCompanies: {},
      processingDetails: [],
      processSuccess: false,
      processFailed: false,
    };
  },
  computed: {
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    members() {
      return this.$store.state.member.members;
    },

    memberEmailsMap() {
      return this.members.reduce((result, member) => {
        return member.email ? { ...result, [member.email]: member.id } : result;
      }, {});
    },

    memberAccountKeysMap() {
      return this.members.reduce((result, member) => {
        return member.accountKey
          ? { ...result, [member.accountKey]: member.id }
          : result;
      }, {});
    },

    companies() {
      return this.$store.state.company.companies;
    },

    companyAccountKeysMap() {
      return this.companies.reduce((result, company) => {
        return company.accountKey
          ? { ...result, [company.accountKey]: company.id }
          : result;
      }, {});
    },

    memberTags() {
      return this.$store.state.membertag.memberTags;
    },

    memberTagsArray() {
      return this.memberTags.map((item) => item.tag);
    },

    memberTagsMap() {
      return this.memberTags.reduce((result, tag) => {
        return tag.tag ? { ...result, [tag.tag]: tag.id } : result;
      }, {});
    },

    fieldExistency() {
      if (!this.parsedCsv || !this.parsedCsv.length) {
        return {};
      }

      const firstRow = this.parsedCsv[0];
      return this.mappingFields.reduce((result, field) => {
        return {
          ...result,
          // eslint-disable-next-line no-prototype-builtins
          [field]: firstRow.hasOwnProperty(field),
        };
      }, {});
    },

    validNewMembers() {
      return this.newMembers.filter((member) => member.isValid);
    },

    stepHeading() {
      if (this.step === 0) {
        return "1. Upload CSV file";
      } else if (this.step === 1) {
        return "2. Review";
      } else if (this.step === 2) {
        return "3. Import Complete";
      } else {
        return "";
      }
    },

    stepDescription() {
      if (this.step === 0) {
        return "Please upload the CSV and select the column mapping.";
      } else if (this.step === 1) {
        return "Please check below details before you proceed. ";
      } else if (this.step === 2) {
        return "If import fails, please close the dialog and restart the import process.";
      } else {
        return "";
      }
    },

    isLastStep() {
      return this.step === TOTAL_STEP - 1;
    },

    isStep1Ready() {
      if (!this.parsedCsv || !this.parsedCsv.length) {
        return false;
      }
      const firstRow = this.parsedCsv[0];

      const hasAllRequiredFields = this.requiredFields.reduce(
        (result, field) => {
          // eslint-disable-next-line no-prototype-builtins
          return result && firstRow.hasOwnProperty(field);
        },
        true
      );

      return hasAllRequiredFields;
    },

    isStep2Ready() {
      return this.validNewMembers.length > 0;
    },

    // isStep3Ready() {
    //   return true;
    // },

    isNextStepReady() {
      const stepReadinessFuncName = `isStep${this.step + 1}Ready`;
      return this[stepReadinessFuncName];
    },

    importedCsvHeaders() {
      return Object.keys(this.parsedCsv[0]);
    },
  },

  methods: {
    clear() {
      this.parsedCsv = null;
      this.step = 0;
      this.newMembers = [];
      this.newMemberTagsArray = [];
      this.updatingCompanies = {};
      this.processingDetails = [];
      this.processSuccess = false;
      this.processFailed = false;
    },

    close() {
      this.$emit("onClose");
      this.clear();
    },

    getCsvRowError(row) {
      const errors = [];
      const emailValue = row[CSV_MAPPING.EMAIL].toLowerCase();
      const accountKeyValue = row[CSV_MAPPING.ACCOUNT_KEY];
      console.log("emailValue", emailValue);
      console.log("accountKeyValue", accountKeyValue);

      if (!row[CSV_MAPPING.FNAME]) {
        errors.push(`${CSV_MAPPING.FNAME} is required`);
      }
      if (!row[CSV_MAPPING.LNAME]) {
        errors.push(`${CSV_MAPPING.LNAME} is required`);
      }
      if (!utils.validateEmail(emailValue)) {
        errors.push(`${CSV_MAPPING.EMAIL} is not valid`);
      }
      if (accountKeyValue && this.memberAccountKeysMap[accountKeyValue]) {
        console.log("Existing account id found");
        if (
          this.memberEmailsMap[emailValue] &&
          this.memberEmailsMap[emailValue] !==
            this.memberAccountKeysMap[accountKeyValue]
        ) {
          errors.push(`${CSV_MAPPING.EMAIL}} already exists`);
        }
      } else {
        console.log("Email: ", emailValue);
        console.log(
          "No existing account id found",
          this.memberEmailsMap[emailValue]
        );
        if (this.memberEmailsMap[emailValue]) {
          errors.push(`${CSV_MAPPING.EMAIL} already exists`);
        }
      }
      return errors;
    },

    getExistingMember(row) {
      const accountKeyValue = row[CSV_MAPPING.ACCOUNT_KEY];

      if (accountKeyValue && this.memberAccountKeysMap[accountKeyValue]) {
        return this.memberAccountKeysMap[accountKeyValue];
      }
      return null;
    },

    getExistingEmail(row) {
      const accountEmail = row[CSV_MAPPING.EMAIL].toLowerCase();

      if (accountEmail && this.memberEmailsMap[accountEmail]) {
        return this.memberEmailsMap[accountEmail];
      }
      return null;
    },

    async gotoNext() {
      if (this.step === 0) {
        const { newMembers, newMemberTagsArray, updatingCompanies } =
          this.parsedCsv
            .reduce((result, row) => {
              // Note: Remove duplication inside csv itself.
              const duplicated = result.find(
                (item) =>
                  item[CSV_MAPPING.EMAIL].toLowerCase() ===
                    row[CSV_MAPPING.EMAIL].toLowerCase() ||
                  (item[CSV_MAPPING.ACCOUNT_KEY] &&
                    item[CSV_MAPPING.ACCOUNT_KEY] ===
                      row[CSV_MAPPING.ACCOUNT_KEY])
              );

              return duplicated ? result : [...result, row];
            }, [])
            .reduce(
              (result, row) => {
                const errors = this.getCsvRowError(row);
                const isValid = !errors.length;
                const exMemberId = this.getExistingMember(row);
                const isUpdate = !!exMemberId;

                const tempRowMemberTagsArray = utils.parseByDelimeter(
                  row[CSV_MAPPING.TAGS]
                );
                const rowMemberTagsArray = tempRowMemberTagsArray.map(
                  (element) => {
                    return element;
                  }
                );
                const rowCompanyKeysArray = utils.parseByDelimeter(
                  row[CSV_MAPPING.COMPANY_KEYS]
                );

                const newMember = {
                  ...row,
                  rowMemberTagsArray,
                  rowCompanyKeysArray,
                  isValid,
                  isUpdate,
                  exMemberId,
                  logs: errors.join(","),
                };

                if (!isValid) {
                  return {
                    ...result,
                    newMembers: [...result.newMembers, newMember],
                  };
                }

                const newMemberTagsArray = utils.getNewArrayItems(
                  this.memberTagsArray.concat(result.newMemberTagsArray),
                  rowMemberTagsArray
                );

                const companyIds = rowCompanyKeysArray.reduce(
                  (ids, companyKey) => {
                    const exId = this.companyAccountKeysMap[companyKey];
                    return exId ? [...ids, exId] : [...ids];
                  },
                  []
                );

                const logs = [
                  `${newMemberTagsArray.length} new tag(s)` +
                    " " +
                    `Linking to ${companyIds.length} company(s)`,
                ];

                // Note: User id is not available until we create members in the db
                // Store email which is unique to find the user id later.
                const updatingCompanies = companyIds.reduce(
                  (companyMembersMap, companyId) => {
                    if (companyMembersMap[companyId]) {
                      return {
                        ...companyMembersMap,
                        [companyId]: [
                          ...companyMembersMap[companyId],
                          row[CSV_MAPPING.EMAIL].toLowerCase(),
                        ],
                      };
                    }
                    return {
                      ...companyMembersMap,
                      [companyId]: [row[CSV_MAPPING.EMAIL].toLowerCase()],
                    };
                  },
                  result.updatingCompanies
                );

                return {
                  newMembers: [
                    ...result.newMembers,
                    { ...newMember, companyIds, logs: logs.join(",") },
                  ],
                  newMemberTagsArray: [
                    ...result.newMemberTagsArray,
                    ...newMemberTagsArray,
                  ],
                  updatingCompanies,
                };
              },
              {
                newMembers: [],
                newMemberTagsArray: [],
                updatingCompanies: {},
              }
            );

        this.step += 1;
        this.newMembers = newMembers;
        this.newMemberTagsArray = newMemberTagsArray;
        this.updatingCompanies = updatingCompanies;
      } else if (this.step === 1) {
        this.step += 1;
        this.processingDetails = ["Creating member tags..."];
        try {
          this.$store.dispatch("importMemberTags", this.newMemberTagsArray),
            (this.processingDetails = [
              ...this.processingDetails,
              "Prepared member tags successfully.",
            ]);
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Preparing member tags failed.",
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Saving new member tags...",
        ];
        try {
          await this.$store.dispatch("loadMemberTags");
          this.processingDetails = [
            ...this.processingDetails,
            "Saved new member tags.",
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Saving member failed.",
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Importing members...",
        ];

        const membersToImport = this.validNewMembers.map((rawMember) => {
          const firstname = rawMember[CSV_MAPPING.FNAME];
          const lastname = rawMember[CSV_MAPPING.LNAME];
          const email = rawMember[CSV_MAPPING.EMAIL];
          const accountKey = rawMember[CSV_MAPPING.ACCOUNT_KEY] || null;
          const tags = rawMember.rowMemberTagsArray.map(
            (rawTag) => this.memberTagsMap[rawTag]
          );
          const externalImageUrl = rawMember[CSV_MAPPING.EXTERNAL_IMAGE_URL];

          if (rawMember.isUpdate) {
            // Todo: In case of update, do not allow company update
            // Should we allow update of emails? Member might be already authenticated.
            const name = firstname + " " + lastname;
            const initials = name
              .match(/(\b\S)?/g)
              .join("")
              .match(/(^\S|\S$)?/g)
              .join("")
              .toUpperCase();
            const updateObject = {
              exMemberId: rawMember.exMemberId,
              isUpdate: rawMember.isUpdate,
              firstname: firstname.trim(),
              lastname: lastname.trim(),
              initials: initials,
              externalImageUrl: externalImageUrl ? externalImageUrl : "",
              fullnameUppercase: (firstname + lastname).toUpperCase().trim(),
              email: email.toLowerCase().trim(),
              companies: rawMember.companyIds,
            };
            if (this.fieldExistency[CSV_MAPPING.TAGS]) {
              updateObject.tags = tags;
            }
            return updateObject;
          }
          const colors = AVATAR_COLORS;
          const random = Math.floor(Math.random() * colors.length);
          const color = colors[random];
          const name = firstname + " " + lastname;
          const initials = name
            .match(/(\b\S)?/g)
            .join("")
            .match(/(^\S|\S$)?/g)
            .join("")
            .toUpperCase();
          return {
            authId: null,
            firstname: firstname.trim(),
            lastname: lastname.trim(),
            initials: initials,
            color: color,
            fullnameUppercase: (firstname + lastname).toUpperCase().trim(),
            email: email.toLowerCase().trim(),
            accountKey,
            status: "Pending",
            tags,
            companies: rawMember.companyIds,
            externalImageUrl: externalImageUrl ? externalImageUrl : "",
            awarded: 0,
            balance: 0,
            redeemed: 0,
            isUpdate: rawMember.isUpdate,
            exMemberId: rawMember.exMemberId,
            image: {
              name: "",
              updated: "",
              smallUrl: "",
              mediumUrl: "",
              largeUrl: "",
              smallStoragePath: "",
              mediumStoragePath: "",
              largeStoragePath: "",
            },
          };
        });

        try {
          // await this.$store.dispatch('importMembers', membersToImport);
          await this.$store.dispatch("importMembersNew", membersToImport);
          this.processingDetails = [
            ...this.processingDetails,
            "Prepared members data successfully.",
            // this.processSuccess = true
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Preparing members data failed.",
            (this.processFailed = true),
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Saving new members...",
        ];
        try {
          await this.$store.dispatch("loadMembers");
          this.processingDetails = [
            ...this.processingDetails,
            "Saved new members successfully.",
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Saving new members failed.",
            (this.processFailed = true),
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Updating companies...",
        ];

        const companyUpdates = Object.keys(this.updatingCompanies).reduce(
          (companyUpdatesResult, companyId) => {
            const newMemberEmails = this.updatingCompanies[companyId];
            const newMemberUpdates = newMemberEmails.reduce(
              (newMemberUpdatesResult, email) => {
                const memberId = this.memberEmailsMap[email];
                return memberId
                  ? [...newMemberUpdatesResult, { companyId, memberId }]
                  : newMemberUpdatesResult;
              },
              []
            );

            return [...companyUpdatesResult, ...newMemberUpdates];
          },
          []
        );

        try {
          await this.$store.dispatch("updateCompanyMembers", companyUpdates);
          this.processingDetails = [
            ...this.processingDetails,
            "Updated companies successfully.",
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Updating companies failed.",
            (this.processFailed = true),
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Syncronising members to companies...",
        ];

        try {
          await this.$store.dispatch("syncMembersToCompanies");
          this.processingDetails = [
            ...this.processingDetails,
            "Syncronised members to companies successfully.",
            (this.processSuccess = true),
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Syncronising members to companies failed.",
            (this.processFailed = true),
          ];
          return;
        }

        await this.$store.dispatch("loadCompanies");
      } else if (this.step === 2) {
        this.close();
      }
    },
  },
};
</script>

<style></style>

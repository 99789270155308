<template>
  <v-dialog v-model="open" max-width="1100px" persistent>
    <v-card>
      <form data-vv-scope="subscription">
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>

      <v-row justify="center" no-gutters class="page-heading">
        {{ formTitle }}
      </v-row>      

      <v-card-text class="px-16">
        <v-data-table
          :headers="headers"
          :items="stripeInvoices"
          :search="search"
          :options.sync="options"
          no-data-text="..."
          class="px-0"
        >
          <template v-slot:[`item.created`]="{ item }">
            {{ item.created * 1000 | date }}
          </template>

          <template v-slot:[`item.currency`]="{ item }">
            <span class="uppercase">{{ item.currency }}</span>
          </template>
          
          <template v-slot:[`item.number`]="{ item }">
            <a class="link" :href="item.hosted_invoice_url" target="_blank">{{ item.number }}<v-icon size="16" color="primary" class="mt-n1 ml-1">open_in_new</v-icon></a>
          </template>
          
          <template v-slot:[`item.status`]="{ item }">
            <status-icon :status="item.status"></status-icon>
            <span class="capitalize ml-1">{{ item.status }}</span>
          </template>
          
          <template v-slot:[`item.invoice_pdf`]="{ item }">
            <a :href="item.invoice_pdf" class="link"><v-icon color="primary">fa-solid fa-file-pdf</v-icon></a>
          </template>
          
          <template v-slot:[`item.amount_paid`]="{ item }">
            <span class="uppercase">${{ item.amount_paid / 100 | number }}</span>
          </template>
          
          <template v-slot:[`item.amount_due`]="{ item }">
            <span class="uppercase">${{ item.amount_due / 100 | number }}</span>
          </template>

        </v-data-table>    
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="primary"
          @click="handleClose()"
          >Close</v-btn>
      </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
},
  props: {
    subscriptionId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    }
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data () {
    return {
      dictionary: {
        custom: {
        }
      },
      formTitle: 'Invoices',
      formIcon: 'receipt',
      created: '',
      search: '',
      showAlert: false,
      headers: [
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Date', value: 'created', align: 'left' },
        { text: 'Invoice#', value: 'number', align: 'left' },
        { text: 'Currency', value: 'currency', align: 'left' },
        { text: 'Due', value: 'amount_due', align: 'left' },
        { text: 'Paid', value: 'amount_paid', align: 'left' },
        { text: 'Download', value: 'invoice_pdf', align: 'center' },
      ],
      options: {
        sortBy: ['created'],
        sortDesc: [true],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: true,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
    }
  },
  computed: {
    stripeInvoices () {
      return this.$store.getters.stripeInvoices
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    isIncentableAdmin () {
      return this.$store.getters.isIncentableAdmin
    }
  },
  methods:{
    handleClose () {
      this.$emit('onClose');
    }
  },
  watch: {
  },
}
</script>

<style scoped>
.status {
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif !important;
  font-size: 17px;
  font-weight: 500 !important;
  color:#4d4d4d !important;
}
.label {
  font-size: 13px;
  font-weight: 300;
}
.value {
  font-size: 14px;
  font-weight: 500;
  color: #4E4E4E !important;
}
</style>
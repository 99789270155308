<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="quiz">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
            absolute
            color="primary"
            :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Add New
            {{ formTitle }}
          </v-card-title>
        </v-row>
        <v-card-text class="px-16">
          <v-text-field
            v-model="title"
            label="Quiz Name"
            v-validate="'required'"
            :error-messages="errors.collect('quiz.title')"
            name="title"
            type="text"
          />
          <v-select
            :items="categories"
            v-model="category"
            item-text="title"
            item-value="id"
            label="Category"
            name="category"
            v-validate="'required'"
            :error-messages="errors.collect('quiz.category')"
          />

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert
                color="primary"
                border="left"
                text
                type="error"
                :value="true"
              >
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Cancel </v-btn>
          <v-btn
            @click="handleSave('quiz')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            elevation="0"
            :loading="loading"
            :disabled="demo"
            >Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => "Quiz Name is required",
        },
        category: {
          required: () => "Quiz Category is required",
        },
      },
    },
    formTitle: "Quiz",
    formIcon: "fa-solid fa-clipboard-question",
    title: "",
    errorMessage: "",
    shuffle: false,
    companyTags: [],
    memberTags: [],
    description: "",
    category: "",
  }),
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    categories() {
      return this.$store.state.quizcategory.quizCategories;
    },
  },
  methods: {
    reset() {
      this.title = "";
      this.$validator.reset();
    },

    handleClose() {
      this.reset();
      this.$emit("onClose");
    },

    handleSave(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        if (!validationResult) {
          return;
        }

        this.$store.dispatch("setLoading", true);
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const payload = {
          status: "Active",
          title: this.title,
          category: this.category,
          shuffle: this.shuffle,
          companyTags: this.companyTags,
          memberTags: this.memberTags,
          description: this.description,
          titleUppercase: this.title.toUpperCase(),
          displayTitle: this.title,
          displayTitleUppercase: this.title.toUpperCase(),
          redeemable: false,
          questions: [],
          passPoints: 0,
          passRate: 0,
          rules: [],
          media: {
            youTubeId: "",
          },
          databucket: {
            addToDatabucket: false,
            databucketId: null,
            targetCode: null,
          },
          timing: {
            openDate: "",
            openTime: "",
            closeDate: "",
            closeTime: "",
            timezone: userTimezone,
          },
        };

        this.$store
          .dispatch("createQuiz", payload)
          .then(() => {
            this.$store.dispatch("setLoading", false);
            this.handleClose();
          })
          .catch((msg) => {
            this.$store.dispatch("setLoading", false);
            this.errorMessage = msg;
          });
      });
    },
  },
};
</script>

<style scoped></style>

<template>
<div>
  <v-dialog v-model="open" max-width="80vw" persistent>
    <v-card>
      <form data-vv-scope="order">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="primary"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="70" color="primary" class="ml-2">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters class="mb-3">
            <div class="page-heading">
              {{ orderId ? 'Edit ' : 'Add New '}}
              {{ formTitle }}
            </div>
        </v-row>
        <v-card-text class="px-12">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="name"
                label="Deliver To"
                dense
                @input="handleChange()"
              ></v-text-field>
              
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="address"
                    label="Address"
                    dense
                    @input="handleChange()"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="suburb"
                    label="Suburb / City"
                    dense
                    @input="handleChange()"
                  ></v-text-field>
                </v-col>
              </v-row>
              
              <v-row class="mt-n6">
                <v-col>
                  <v-text-field
                    v-model="state"
                    label="State"
                    dense
                    @input="handleChange()"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="postcode"
                    label="Postcode / Zip"
                    dense
                    @input="handleChange()"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="country"
                    label="Country"
                    dense
                    @input="handleChange()"
                  ></v-text-field>
                </v-col>
              </v-row>
  
            </v-col>

            <v-col cols="3">
              <v-text-field
                :value="this.orderedByMember.firstname + ' ' + this.orderedByMember.lastname"
                label="Ordered By"
                readonly
                dense
              ></v-text-field>
              <v-text-field
                :value="this.orderedByMember.email"
                label="Email"
                readonly
                dense
              ></v-text-field>
              <v-text-field
                :value="this.orderedByMember.phone"
                label="Phone"
                readonly
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-row>
                <v-col>
                  <v-text-field
                    :value="order.cartValue | number"
                    label="Order Points"
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="status"
                    label="Status"
                    :items="orderStatuses"
                    dense
                    @change="handleChange()"
                  ></v-select>
                </v-col>
              </v-row>
              <v-text-field
                :value="created | timestamp | date"
                label="Created"
                readonly
                dense
              ></v-text-field>
              <v-text-field
                v-if="updated"
                :value="updated | timestamp | date"
                label="Updated"
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="start" no-gutters class="mt-3">
            <v-col lg="3">
              <v-select
                v-model="selectedStatus"
                :items="orderItemStatuses"
                label="Update Status To..."
                :disabled="updateStatusDisabled"
                solo-inverted
                dense
                flat
                class="mr-4"
              ></v-select>
            </v-col>
            <v-col cols="auto" class="button-adjust ml-n1">
              <v-btn
                v-if="selectedStatusSaveButton || processingStatusChange"
                @click="handleStatusChange()"
                color="primary"
                :class="orgTheme.buttonTextColor"
                :loading="processingStatusChange"
                :disabled="updateStatusDisabled"
                elevation="0"
                >apply
              </v-btn>
            </v-col>
          </v-row>
              
          <v-divider class="mt-n3"/>

          <v-data-table
            class="mt-0"
            v-model="selected"
            :headers="headers"
            :items="items"
            :search="search"
            :options.sync="options"
            no-data-text="No Transactions..."
            show-select
          >
            
            <template v-slot:[`item.status`]="{ item }">
              <status-icon :status="item.status" class="pr-3"></status-icon>
              {{ item.status }}
            </template>
            
            <template v-slot:[`item.each`]="{ item }">
              {{ item.each | number }}
            </template>
            
            <template v-slot:[`item.subtotal`]="{ item }">
              {{ item.subtotal | number }}
            </template>
            
            <template v-slot:[`item.updated`]="{ item }">
              {{ item.updated | timestamp | date }}
            </template>
            
            <template v-slot:[`item.title`]="{ item }">
              <span  class="table-link link" @click="handleOpenRewardDetail(item)">{{ item.title | truncate(45) }}</span>
            </template>
            
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu
                bottom
                left
                close-on-content-click
              >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>                
                    
                    <v-list-item
                      link
                      :disabled="!isRoleSatisfied('Editor')"
                      class="mb-2"
                      >
                        <v-list-item-icon>
                          <v-icon size="20">fa-solid fa-gift</v-icon>
                          
                        </v-list-item-icon>
                        <v-list-item-title
                          @click="handleOpenRewardDetail(item)"
                          class="mt-1 ml-n4"
                          >
                          View Reward
                        </v-list-item-title>
                    </v-list-item>
                  </v-list>
              </v-menu>
            </template>

        </v-data-table>

        </v-card-text>
        
        <v-card-actions class="px-4 mt-n6">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            >Close
          </v-btn>
          <v-btn
            @click="saveOrder('order')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            elevation="0"
            :disabled="!hasChanged"
            >Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
    
  </v-dialog>

  <RewardDetail
    v-if="dialogRewardOpen"
    :open="dialogRewardOpen"
    :reward="selectedReward"
    @onClose="handleCloseRewardDetail"
  ></RewardDetail>

  <ConfirmStatusChange
    :open="confirmStatusChangeDialogOpen"
    :selectedStatus="selectedStatus"
    :items="selected"
    @onClose="handleCloseConfirmStatusChangeDialog"
    @onConfirm="confirmStatusChange"
  ></ConfirmStatusChange>

  </div>
</template>

<script>
import { ORDER_STATUS } from '@/constants/order'
import { ORDER_ITEM_STATUS } from '@/constants/order'
import RewardDetail from './RewardDetail.vue'
import ConfirmStatusChange from './ConfirmStatusChange.vue'
export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: { 
    RewardDetail,
    ConfirmStatusChange
  },
  props: [ 'orderId', 'open'],
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  created () {
    this.$store.dispatch('loadMembers')
  },
  data: () => ({
    dictionary: {
      custom: {
        status: {
          required: () => 'Status is required'
        },
      }
    },
    headers: [
      { text: 'Status', value: 'status', align: 'left' },
      { text: 'Item', value: 'title', align: 'left'},
      { text: 'Updated', value: 'updated', align: 'left'},
      { text: 'SKU', value: 'sku', align: 'right'},
      { text: 'Each', value: 'each', align: 'right', sortable: false},
      { text: 'Quantity', value: 'quantity', align: 'right', sortable: false},
      { text: 'Subtotal', value: 'subtotal', align: 'right', sortable: false},
      { text: 'Actions', value: 'actions', align: 'center', sortable: false},
    ],
    orderItemStatuses: ORDER_ITEM_STATUS,
    formTitle: 'Order',
    formIcon: 'shopping_cart',
    showAlert: false,
    search: '',
    status: '', 
    created: '', 
    updated: '', 
    createdBy: '', 
    updatedBy: '', 
    address: '', 
    state: '', 
    postcode: '', 
    suburb: '',
    country: '',
    name: '',
    member: '',
    cartValue: '',
    selectedReward: '',
    dialogRewardOpen: false,
    hasChanged: false,
    selected: [],
    selectedStatus: '',
    confirmStatusChangeDialogOpen: false,
    // Table settings
    options: {
      sortBy: ['created'],
      sortDesc: [true],
      itemsPerPage: 5,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: false,
      download: false,
      more: false,
      new: true
    },
  }),
  computed: {
    programId () {
      return this.$store.getters.programId;
    },
    orderStatuses () {
      return ORDER_STATUS.filter(el => el !== 'Cancelled')
    },
    loading () {
      return this.$store.getters.loading
    },
    processingStatusChange () {
      return this.$store.getters.processingStatusChange
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    orders () {
      return this.$store.state.order.orders
    },
    order() {
      const arr = this.orders
      if (this.orderId) {
        return arr.find((el) => el.id === this.orderId)
      }
      return {}
    },
    items () {
      const arr = this.orders
      if (this.orderId) {
        return arr.find((el) => el.id === this.orderId).items
      }
      return []
    },
    supplier () {
      return this.$store.state.supplier.suppliers
    },
    companyTags () {
      return this.$store.state.companytag.companyTags
    },
    memberTags () {
      return this.$store.state.membertag.memberTags
    },
    members () {
      return this.$store.state.member.members;
    },
    orderedByMember() {
      const deletedMember = {
        firstname: 'Member',
        lastname: 'Deleted',
        email: 'Not Available'
      }
      if (this.createdBy) {
        const arr = this.members
        const member = arr.find((el) => el.id === this.createdBy)
        return member || deletedMember
      }
      return ''
    },
    updateStatusDisabled () {
      if(this.selected.length > 0) {
        return false
      }
      return true
    },
    selectedStatusSaveButton () {
      if(this.selectedStatus) {
        return true
      }
      return false
    }
  },
  methods:{
    clear () {
      this.selected = []
      this.selectedStatus = '',
      this.$validator.reset();
      this.hasChanged = false
    },
    handleChange() {
      console.log('change')
      this.hasChanged = true
    },
    handleClose () {
      this.clear();
      this.$emit('onClose');
    },
    handleOpenRewardDetail(item) {
      this.selectedReward = item
      this.dialogRewardOpen = true;
    },
    handleCloseRewardDetail() {
      this.selectedReward = '';
      this.dialogRewardOpen = false;
    },
    saveOrder (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        console.log('saving...')
        this.$store.dispatch('setLoading', true)

        const payload = {
          // Add database fields here
          status: this.status,
          items: this.items,
          name: this.name,
          address: this.address,
          state: this.state,
          suburb: this.suburb,
          postcode: this.postcode,
          country: this.country,
          cartValue: this.cartValue,
          memberId: this.member._id
        };
        if (this.orderId) {
          // Update Order
          payload.id = this.orderId;
          this.$store.dispatch('updateOrder', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create Order
          this.$store.dispatch('createOrder', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        }
      });
    },
    handleStatusChange() {
      this.confirmStatusChangeDialogOpen = true
    },
    handleCloseConfirmStatusChangeDialog() {
      this.confirmStatusChangeDialogOpen = false
    },
    confirmStatusChange() {
      this.confirmStatusChangeDialogOpen = false
      this.$store.dispatch('setProcessingStatusChange', true)
      const newStatus = this.selectedStatus
      const selected = this.selected
      const changed = selected.map(el => {
        return {
          ...el,
          lastStatus: el.status, 
          status: newStatus,
        }
      })
      const updates = {
        id: this.orderId,
        memberId: this.member._id,
        changedArr: changed
      }
      this.$store.dispatch('updateSelectedStatus', updates)
      this.selectedStatus= false
      this.selected= []
    }
  },
  watch: {
    orderId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedOrder = this.order
          const {
            status, created, updated, createdBy, updatedBy, address, state, postcode, suburb,
            country, name, cartValue, member
          } = selectedOrder
          this.status = status
          this.name = name
          this.address = address
          this.state = state
          this.suburb = suburb
          this.postcode = postcode
          this.country = country
          this.created = created
          this.updated = updated
          this.createdBy = createdBy
          this.updatedBy = updatedBy
          this.cartValue = cartValue
          this.member = member
        }
      },
    },
  },
}
</script>

<style>
.button-adjust {
  margin-top: -3px
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"el"},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('div',{staticClass:"incentable-form-heading"},[_vm._v("Website")]),_c('v-spacer'),(_vm.currentSubscription.status === 'active' || _vm.trial)?_c('div',[_c('v-icon',{staticClass:"green--text pr-1",attrs:{"size":"21"}},[_vm._v("check_circle")]),_c('span',{staticClass:"dash-card green--text"},[_vm._v("Online")])],1):_c('div',[_c('v-icon',{staticClass:"red--text pr-1",attrs:{"size":"21"}},[_vm._v("desktop_access_disabled")]),_c('span',{staticClass:"dash-card red--text"},[_vm._v("Offline")])],1)],1),_c('v-card-text',_vm._l((_vm.domains),function(domain,ref){return _c('v-row',{key:ref,staticClass:"mb-1",attrs:{"no-gutters":""}},[(
            (domain.status === 'Live' &&
              _vm.currentSubscription.status === 'active') ||
            (_vm.trial && domain.status === 'Live')
          )?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green","size":"21"}},[_vm._v("check_circle")])],1),_c('v-col',[_c('a',{staticClass:"text",attrs:{"href":`https://${domain.activeDomain}`,"target":"_blank"}},[_vm._v(" "+_vm._s(domain.activeDomain)+" ")])])],1)],1):(
            (domain.status === 'Processing' &&
              _vm.currentSubscription.status === 'active') ||
            _vm.trial
          )?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"amber","size":"21"}},'v-icon',attrs,false),on),[_vm._v(" pending ")])]}}],null,true)},[_c('div',[_vm._v("This web address is currently processing")]),_c('div',[_vm._v("and should be fully set up with 24 hours.")]),_c('div',[_vm._v("But don't worry, your site is live! Use the")]),_c('div',[_vm._v("temporary domain to access it.")]),_c('div',[_vm._v("Do not publish your temporary domain to users, as")]),_c('div',[_vm._v("it will be deleted once your web address is live.")])])],1),_c('v-col',[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(domain.activeDomain)+" ")])])],1)],1):_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"red","size":"21"}},[_vm._v("cancel")])],1),_c('v-col',[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(domain.activeDomain)+" ")])])],1)],1)])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
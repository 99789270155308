<template>
  <div>
    <v-alert
      :value="csvName.length > 0"
      color="primary"
      border="left"
      text
      class="mx-1 incentable-alert"
    >
      <v-icon color="primary" class="ml-2 mr-1">fa-solid fa-file-csv</v-icon> 
      <span class="mr-1 filename">{{ csvName }}</span>
      
      <span v-if="targetDataFileName">
        will replace all of the targets from the previous file imported
        <v-icon color="primary" class="ml-2 mr-1">fa-solid fa-file-csv</v-icon>
        <span class="filename">{{ targetDataFileName }}</span>
      </span> 
      <span v-else>
        will be uploaded
      </span> 
      
    </v-alert>
    <vue-dropzone
      v-if="!csvBody.length && !demo"
      id="dropzone"
      ref="dropzone"
      :options="dropzoneOptions"
      :useCustomSlot=true
      @vdropzone-file-added="handleFileAdded"
      class="grey lighten-4 rounded"
    >
      <div class="dropzone-custom-content">
        <v-icon size="40" color="primary" class="mb-2 mt-14">fa-solid fa-upload</v-icon>
        <div class="grey--text">Drag & Drop CSV</div>
        <div class="grey--text">or</div>
        <v-btn class="primary mb-16" elevation="0">Browse Files</v-btn>
      </div>
    </vue-dropzone>
    
    <v-card
      v-if="demo"
      class="grey lighten-4 py-12"
      elevation="0"
      tile
    >
    <v-row justify="center">
      <v-icon size="40" color="primary" class="mb-2">fa-solid fa-upload</v-icon>
    </v-row>
    <v-row justify="center">
      <div class="grey--text">Drag & Drop CSV</div>
    </v-row>
    <v-row justify="center">
      <div>or</div>
    </v-row>
    <v-row justify="center">
      <v-btn class="primary" elevation="0">Browse Files</v-btn>
    </v-row>
    </v-card>

    <v-card 
      v-show="csvBody.length"
      class="mt-0 mx-1" 
      outlined
      tile
    >
      <v-simple-table
        dense
        fixed-header
        max-height="400px"
      >
        <thead>
          <tr>
            <th class="incentable-csv-table-title-row">
              <v-icon color="green">fa-solid fa-file-csv</v-icon>
            </th>
            <th 
              v-for="(heading, idx) in csvHeaders"
              :key="idx"
              class="incentable-csv-table-title-row"
            >
              {{ heading }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, i) in csvBody"
            :key="i"
          >
            <td class="incentable-csv-table-number-column" width="20">
              {{ i + 1 }}
            </td>
            <td 
              v-for="(item, index) in row"
              :key="index"
              class="incentable-csv-table-cell"
              style="min-width:150px"
            >
              {{ item }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import Papa from 'papaparse';
export default {
  data: function () {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post", // Dummy Post URL,
      },
      requiredFields: ['Account Key'],
      openColumnMapper: false,
      isProcessing: false,
      csvName: '',
      mappingErrors: {},
      newMapping: {},
    }
  },

  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    databucket() {
      return this.$store.state.databucket.databucket
    },

    targetDataFileName() {
      if (this.$store.state.databucket.targetData) { 
        return this.$store.state.databucket.targetData.fileName
      }
        return null 
    },
    
    newCsvTargetFileName() {
      return this.$store.state.databucket.newCsvTargetFileName
    },

    csvData() {
      return this.$store.state.databucket.csvTargetData || []
    },
    
    targetCodes() {
      return this.databucket.targetCodes || []
    },

    entityMap() {
      return this.$store.getters.databucketEntityMap;
    },

    targetHeaders() {
      return ['Account Key', ...this.targetCodes] || []
    },

    targetMapping() {
      return this.databucket.targetMapping || {};
    },

    csvHeaders() {
      return this.csvData.length ?  this.csvData[0] : [];
    },

    csvBody() {
      return this.csvData.length ?  this.csvData.slice(1) : [];
    },

    matched() {
      const accountKeyIndex = this.csvHeaders.findIndex(header => header === this.targetMapping['Account Key']);

      return accountKeyIndex !== -1
        ? this.csvBody.filter((item) => {
          return this.entityMap[item[accountKeyIndex]]
        })
        : [];
    },

  },

  methods: {   
    checkMap(field) {
      const arr = this.csvHeaders
      const found = arr.find(item => item === field)
      return found || ''
    },
    resetUpload() {
      console.log('SelectCsv > resetUpload')
      this.csvName = ''
      this.$store.dispatch('setCsvTargetData', [])
      this.$store.dispatch('setNewCsvTargetFileName', '')
      this.mappingErrors = {}
    },
    getSampleRow(targetField) {
      if (!this.csvBody.length) {
        return '';
      }
      const relSource = this.newMapping[targetField];
      const colIndex = this.csvHeaders.findIndex(sourceField => sourceField === relSource);
      return this.csvBody[0][colIndex];
    },
    handleFileAdded(file) {
      this.newMapping = { ...this.targetMapping }
      const reader = new FileReader();

      if (!file) {
        console.log('no file')
        return;
      }

      reader.readAsText(file, "UTF-8");
      reader.onload = (evt) => {
        const output = evt.target.result;
        const result = _.get(Papa.parse(output), "data");
        console.log(result)
        this.$store.dispatch('setCsvTargetData', result)
        // this.csvData = result;
        this.csvName = file.name;
        this.$store.dispatch('setNewCsvTargetFileName', file.name)
      };

      reader.onerror = function () {
        // Handle Error
      };

      this.$refs.dropzone.removeAllFiles();
    },

    handleOpenDataMapper() {
      this.openColumnMapper = true;
    },

    handleCloseDataMapper() {
      this.openColumnMapper = false;
    },

    handleSaveDataMapper(newMapping) {
      this.newMapping = newMapping
      this.openColumnMapper = false;
      this.$store.dispatch('updateTargetMapping', newMapping)
        .then(() => {
          this.isProcessing = false;
        })
        .catch(() => {
          this.isProcessing = false;
        });
    },

    handleUploadData() {
      this.isProcessing = true;
      
      const mappingEntries = Object.entries(this.targetMapping);
      const mappingIndices = mappingEntries.map(([, mVal]) => {
        return this.csvHeaders.findIndex(header => header === mVal);
      });

      const tcEntries = mappingEntries.filter(entry => this.targetCodes.find(targetCode => targetCode === entry[0]));
      const validCsvHeaders = this.csvHeaders.filter((header) => {
        return !!tcEntries.find(entry => entry[1] === header);
      });
      const consumedTargetCodes = validCsvHeaders.reduce((result, header) => {
        const targetCodeEntries = tcEntries.filter(entry => entry[1] === header);
        const codes = targetCodeEntries.map(entry => entry[0])
        return [...result, ...codes];
      }, []);

      const mappedData = this.matched.map((row) => {
        const rowResult = mappingEntries.reduce((rowAgg, mappingEntry, idx) => {
          if (mappingIndices[idx] === -1) {
            return rowAgg;
          }
          return {
            ...rowAgg,
            [mappingEntry[0]]: row[mappingIndices[idx]],
          };

        }, {});

        return rowResult;
      });

      const payload = {
        fileName: this.csvName,
        data: mappedData,
        consumedTargetCodes,
      };

      this.$store.dispatch('updateTargetData', payload)
        .then(() => {
          console.log(payload)
          this.isProcessing = false
          this.resetUpload()
          this.reset()
        })
        .catch(() => this.isProcessing = false);
    },
    
  },
  watch: {
  }
}
</script>

<style scoped>
.row-number {
  text-align: center !important;
}
</style>

<template>
  <v-dialog v-model="open" :width="width" persistent>
    <v-card>
      <form data-vv-scope="lang"> 
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="primary"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            {{ translationId ? selectedLang : 'Add New ' + formTitle}}
          </v-card-title>
        </v-row>

        <v-card-text class="px-16">
                
          <v-row no-gutters>
            <v-col>
              <v-select
                name="selectedLang"
                :items="langs"
                v-model="selectedLang"
                label="Language"
                v-validate="'required'"
                :error-messages="errors.collect('lang.selectedLang')"
                v-show="!translationId"
              ></v-select>
              <v-select
                name="selectedStatus"
                :items="statuses"
                label="Status"
                v-model="selectedStatus"
                v-validate="'required'"
                :error-messages="errors.collect('lang.selectedStatus')"
                append-icon="power_settings_new"
              /> 
            </v-col>
            <v-col v-if="translationId" cols="9" class="ml-12">
              <v-row
                no-gutters
                justify="space-between"
                align="center"
                cols="12"
                >
                <v-col sm="3">
                  <v-text-field
                    v-model="search"
                    solo-inverted
                    dense
                    flat
                    hide-details
                    label="Search"
                    prepend-inner-icon="search"
                    class="mr-4"
                  ></v-text-field> 
                </v-col>
                
                <v-col
                  sm="3"
                  v-if="options.filter"
                  >
                  <v-select
                    solo-inverted
                    dense
                    flat
                    hide-details
                    label="Filter"
                    prepend-inner-icon="filter_list"
                    class="mr-4"
                  ></v-select> 
                </v-col>
                
                <v-col>
                  <v-btn
                    @click="handleOpenImportDialog()"
                    v-if="options.upload"
                    class="primary--text"
                    text
                    >Upload</v-btn>
                  <v-btn 
                    v-if="options.download"
                    class="primary--text"
                    text
                    :href="translationsCsv"
                    download="Companies List.csv"
                    >Download</v-btn> 
                  <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
                </v-col>
              
                <v-col sm="auto">
                  <v-btn
                    @click="handleNew('create')"
                    color="primary"
                    elevation="0"
                    class="mt-n2"
                    >
                    <v-icon>add</v-icon>new
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="directory"
                :search="search"
                :options.sync="options"
                :loading="loadingTable"
                no-data-text="No Records..."
              >
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu
                  bottom
                  left
                  close-on-content-click
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                    <v-list>
                      <v-list-item link>
                        <v-list-item-title @click="handleEdit('update', item)">
                          Edit
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        :disabled="!isRoleSatisfied('Editor')"
                        >
                        <v-list-item-title 
                          @click="handleDeleteConfirmation(item)"
                          >Delete
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <template v-if="!translationId">
            <v-row no-gutters class="incentable-page-subheading mt-6">
              Cant find the language your require?
            </v-row>
            <v-row no-gutters class="incentable-page-subheading">
              Send a <a href="https://incentable.zendesk.com/hc/en-us/requests/new" class="px-1" target="_blank"> Support Ticket </a> to have it added
            </v-row>
          </template>

          <v-row no-gutters>
            <v-col v-if="errorMessage">
                <v-alert
                  type="error"
                  :value="true"
                >
                  {{errorMessage}}
                </v-alert>
              </v-col>         
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            >Cancel</v-btn>
          <v-btn
            :class="orgTheme.buttonTextColor"
            color="primary"
            @click="handleSave('lang')"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Save</v-btn>
        </v-card-actions>
      </form>      
    </v-card>
  
  <!-- New or Edit dialog -->

    <dictionary-add-or-edit
      :translationId="translationId"
      :dictionaryAction="dictionaryAction"
      :directory="directory"
      :dictionaryItem="selectedDictionaryItem"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <translations-import
      :open="dialogImportOpen"
      @onClose="dialogImportOpen = false"
    />

  <!-- Delete dialog -->

    <v-dialog 
      v-model="dialogDeleteOpen"
      max-width="400px"
      persistent
      >
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          <v-row dense>
            <v-col align="center">
              You are about to delete <span class="incentable-form-bold">{{ selectedDictionaryItem.english }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleCloseDeleteDialog()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog> 
</template>

<script>
import { LANGS } from '@/constants/lang'
import DictionaryAddOrEdit from './DictionaryAddOrEdit.vue'
import TranslationsImport from './TranslationsImport.vue'

export default {
  components: {
    DictionaryAddOrEdit,
    TranslationsImport
  },
  $_veeValidate: {
    validator: 'new'
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  props: [ 'translationId', 'open' ],
  data: () => ({
    dictionary: {
      custom: {
        selectedLang: {
          required: () => 'Language is required'
        },
        selectedStatus: {
          required: () => 'Status is required'
        },
      }
    },
    // Table columns
    headers: [
      { text: 'English', value: 'english', align: 'left' },
      { text: 'Translation', value: 'value', align: 'left' },
      { text: 'Actions', value: 'actions', align: 'center' },
    ],
    // Table settings
    options: {
      sortBy: ['title'],
      sortDesc: [false],
      itemsPerPage: 5,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
    statuses: [
      { text: 'Active', value: 'Active' },
      { text: 'Inactive', value: 'Inactive' }
    ],
    locale: '',
    selectedLang: '',
    selectedStatus: '',
    selectedDictionaryItem: {},
    errorMessage: '',
    formTitle: 'Language',
    formIcon: 'translate',
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,
    dialogImportOpen: false,
    dictionaryAction: '',
    directory: '',
    search: ''
  }),

  computed: {
    width() {
      if (this.translationId) {
        return '1200px'
      }
      return '400px'
    },
    langs () {
      var arr = []
      LANGS.forEach(el => arr.push(el.code.toUpperCase() + ' - ' + el.name + ' (' + el.nativeName + ') '))
      return arr
    },
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    error () {
      return this.$store.getters.error
    },
    loading () {
      return this.$store.getters.loading
    },
    loadingTable () {
      return this.$store.getters.loadingTable
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    translations () {
      return this.$store.state.translate.translations
    },
  },

  methods: {
    handleClose () {
      this.clear()
      this.$emit('onClose')
    },
    clear () {
      this.title = ''
      this.locale = ''
      this.selectedLang =  '',
      this.selectedStatus =  '',
      this.directory = [],
      this.errorMessage = '',
      this.translationId = '',
      this.$validator.reset()
      this.$store.dispatch('setLoading', false)
    },
    handleSave (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }

        this.$store.dispatch('setLoading', true)

        const payload = {
          id: this.translationId || '',
          title: this.selectedLang,
          titleUppercase: this.selectedLang.toUpperCase(),
          locale: this.selectedLang.substr(0,2).toLowerCase(),
          status: this.selectedStatus,
          directory: this.directory || [],
        };

        const action = this.translationId ? 'updateTranslation' : 'createTranslation';

        this.$store.dispatch(action, payload)
          .then(() => {
            this.$store.dispatch('setLoading', false)
            this.handleClose();
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch('setLoading', false)
          });
      });
    },
    // Actions for dictionary //
    handleNew(dictionaryAction) {
      // Open New form
      this.dictionaryAction = dictionaryAction
      this.selectedDictionaryItem = {}
      this.dialogNewOrEditOpen = true
    },
    handleEdit(dictionaryAction, item) {
      // Open Edit form
      this.dictionaryAction = dictionaryAction
      this.selectedDictionaryItem = item
      // this.index = index
      this.dialogNewOrEditOpen = true
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedDictionaryItem = {}
      this.dialogNewOrEditOpen = false
    },
    handleDeleteConfirmation (item) {
      // Delete record
      this.selectedDictionaryItem = item
      this.dialogDeleteOpen = true
    },
    handleDelete() {
      // Delete record
      this.$store.dispatch('setLoading', true)
      const arr = this.directory
      const del = this.selectedDictionaryItem
      const filteredItems = arr.filter((item) => {
        return item !== del
      })
      this.directory = filteredItems
      this.$store.dispatch('setLoading', false)
      this.dialogDeleteOpen = false
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedDictionaryItem = ''
      this.dialogDeleteOpen = false
    },
    handleOpenImportDialog() {
      // Open Import Dialog
      this.dialogImportOpen = true;
      console.log(this.dialogImportOpen)
    },
  },

  watch: {
    translationId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedTranslation = this.translations.find(item => item.id === newVal);
          const {
            title, status, locale, directory
          } = selectedTranslation
          this.selectedLang = title
          this.selectedStatus = status
          this.locale = locale
          this.directory = directory
        }
      },
    },
  },
}
</script>

<style>
</style>

<template>
  <div>
    <v-row no-gutters align="center">
      <span v-for="selection in selections" :key="selection.id">
        <v-chip class="mr-2" :color="chipColor"
          ><v-icon left :color="color" size="22">{{ selection.icon }}</v-icon
          ><span :style="'color:' + color + ';'">{{
            selection.text
          }}</span></v-chip
        >
      </span>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    selections: Array,
    color: String,
    chipColor: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>

<template>
  <div>
    <v-dialog
      v-model="open"
      scrollable
      fullscreen 
      transition="dialog-transition"
      :retain-focus="false"
    >
      <v-hover v-slot="{ hover }">
        <v-card tile elevation="0">
          <v-fade-transition>
            <v-btn
              small
              v-if="hover"
              fab
              absolute
              color="#21212140"
              @click="onClose"
              class="mt-6"
              :style="{left: '50%', transform:'translateX(-50%)'}"
            >
              <v-icon color="white">close</v-icon>
            </v-btn>
          </v-fade-transition>
          <v-container v-if="!webTheme" fill-height fluid background-color="primary">
            <v-row justify="center" align="center" fill-height>
              <v-progress-circular :size="120" :width="8" color="primary" indeterminate></v-progress-circular>
            </v-row>
          </v-container>
          <view-theme v-else></view-theme>
        </v-card>
      </v-hover>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [ 'open', 'programId' ],
  data: () => ({
  }),
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    }
  },
}
</script>

<style>
 
</style>
<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <draggable
          v-model="localfieldsArr"
          class="draggable-card w-100"
          :group="{ name: 'fields', pull: true, put: true }"
          ghost-class="ghost"
          :move="handleMove"
        >
          <div
            v-for="(field, index) in localfieldsArr"
            :key="index"
            :class="isLastChild(index) ? 'pb-7' : 'pb-0'"
          >
            <FormField
              :fieldIndex="index"
              :pageIdx="pageIdx"
              :sectionIdx="sectionIdx"
              :columnIdx="columnIdx"
              :field="field"
              @onEditField="handleEditField()"
            ></FormField>
          </div>
        </draggable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import FormField from "./FormField.vue";

export default {
  components: { FormField },
  props: {
    pageIdx: {
      type: Number,
      required: true,
    },
    sectionIdx: {
      type: Number,
      required: true,
    },
    columnIdx: {
      type: Number,
      required: true,
    },
    fieldsArr: {
      type: Array,
      required: true,
    },
  },
  data: () => ({}),
  created() {},
  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    localfieldsArr: {
      get() {
        return this.currentOffer.form.pages[this.pageIdx].sections[
          this.sectionIdx
        ].columns[this.columnIdx].items;
      },
      set(change) {
        const newItems = change.map((item) => ({
          ...item,
          idx: item.idx || nanoid(),
        }));

        this.currentOffer.form.pages[this.pageIdx].sections[
          this.sectionIdx
        ].columns[this.columnIdx].items = newItems;
        this.patchCurrentOffer();
      },
    },
  },
  methods: {
    handleMove(event) {
      const itemMovedGroup = event.draggedContext.element.group;
      const destinationGroup = event.relatedContext.component.$attrs.group.name;
      if (itemMovedGroup === destinationGroup) {
        this.patchCurrentOffer();
        return true;
      } else {
        return false;
      }
    },
    patchCurrentOffer() {
      const update = {
        form: this.currentOffer.form,
      };
      this.$store.dispatch("patchCurrentOffer", update);
    },
    isLastChild(index) {
      return index === this.localfieldsArr.length - 1;
    },
  },
  watch: {},
};
</script>

<style scoped>
.handle {
  cursor: pointer;
}
.ghost {
  border: 2px dotted rgb(134, 134, 134);
}
.draggable-card {
  background-color: #ffffff;
  display: block;
  min-height: 80px;
}
.w-100 {
  width: 100%;
}
.div-item > .last-pad:last-child {
  margin-bottom: 26px;
}
</style>

<template>
  <div>
    <v-col cols="4">
      <v-text-field
        v-model="title"
        label="Title"
        type="text"
        name="title"
      />
      <v-text-field
        v-model="displayTitle"
        label="Display Title"
        name="displayTitle"
      />
    </v-col>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
    }
  },
  computed: {
    currentLeaderboard() {
      return this.$store.state.leaderboard.currentLeaderboard;
    },
    title: {
      get() {
        return this.currentLeaderboard.title;
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { 
          title: value,
          titleUppercase: value.toUpperCase()
        })
        this.$store.dispatch('setBreadCrumbDetail', value)
      }
    },
    displayTitle: {
      get() {
        return this.currentLeaderboard.displayTitle
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { 
          displayTitle: value,
          displayTitleUppercase: value.toUpperCase()
        })
      }
    },
  },
  methods: {
  },
}
</script>

<style scoped>

</style>

export const IMAGE_DEFAULTS = {
  image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_2000x2000.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7',
  previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_300x300.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7',
  dark: true,
  shared: {
    menuHeight: 64
  },
  colors: {
    primary: {
      alpha: 0, 
      hex: '#E91E63', 
      hexa: '#E91E6300', 
      hsla: { h: 339.60591133004925, s: 0.8218623481781376, l: 0.5156862745098039, a: 0 }, 
      hsva: { h: 339.60591133004925, s: 0.871244635193133, v: 0.9137254901960784, a: 0 }, 
      hue: 339.60591133004925, 
      rgba: { r: 233, g: 30, b: 99, a: 0 } 
    },
    canvas: {
      alpha: 1, 
      hex: '#212121', 
      hexa: '#212121FF', 
      hsla: { h: 0, s: 0, l: 0.12941176470588237, a: 1 },
      hsva: { h: 0, s: 0, v: 0.12941176470588237, a: 1 }, 
      hue: 0, 
      rgba: { r: 33, g: 33, b: 33, a: 1 }
    },
    header: {
      alpha: 1, 
      hex: '#212121', 
      hexa: '#212121FF', 
      hsla: { h: 0, s: 0, l: 0.12941176470588237, a: 1 },
      hsva: { h: 0, s: 0, v: 0.12941176470588237, a: 1 }, 
      hue: 0, 
      rgba: { r: 33, g: 33, b: 33, a: 1 }
    },
    content: {
      alpha: 1, 
      hex: '#212121', 
      hexa: '#212121FF', 
      hsla: { h: 0, s: 0, l: 0.12941176470588237, a: 1 },
      hsva: { h: 0, s: 0, v: 0.12941176470588237, a: 1 }, 
      hue: 0, 
      rgba: { r: 33, g: 33, b: 33, a: 1 }
    },
    footer: {
      alpha: 1, 
      hex: '#212121', 
      hexa: '#212121FF', 
      hsla: { h: 0, s: 0, l: 0.12941176470588237, a: 1 },
      hsva: { h: 0, s: 0, v: 0.12941176470588237, a: 1 }, 
      hue: 0, 
      rgba: { r: 33, g: 33, b: 33, a: 1 }
    },
  }
}
<template>
    <div>
      <v-app-bar
        class="page-bar"
        dense
        fixed
        color="grey lighten-4"
        elevate-on-scroll
      >
        <v-row no-gutters justify="center" class="mx-0">
          <v-col cols="12" lg="8">
            <span class="page-heading">Profile</span>
          </v-col>
        </v-row>
      </v-app-bar>

      <v-row no-gutters justify="center" class="mx-4 mt-14">
        <v-col cols="12" lg="8">

          <v-row no-gutters class="mt-6">
            <v-col>
                  <v-form :key="emailKey" data-vv-scope="email">
                    <v-row>
                      <v-col lg="4">
                        <v-text-field
                          :readonly="!editEmail"
                          :outlined="editEmail"
                          v-model="email"
                          name="email"
                          label="Email"
                          v-validate="'required|email'"
                          :error-messages="errors.collect('email.email')"
                        ></v-text-field>
                      </v-col>
                      <v-col lg="3" v-if="editEmail">
                        <v-text-field
                          v-model="currentPasswordForEmail"
                          outlined
                          label="Password"
                          name="currentPasswordForEmail"
                          v-validate="'required|min:6'"
                          :error-messages="errors.collect('email.currentPasswordForEmail')"
                          :append-icon="show ? 'visibility' : 'visibility_off'"
                          :type="show ? 'text' : 'password'"
                          @click:append="show = !show"
                        ></v-text-field>
                      </v-col>

                      <v-col lg="4" class="ml-n4">
                        <v-btn
                          v-if="!editEmail"
                          class="mt-3"
                          @click="editEmail = true"
                          icon
                          >
                          <v-icon color="grey lighten-1" small>edit</v-icon>
                          </v-btn>
                        <div v-if="editEmail">
                          <v-btn
                            v-if="!loadingEmail"
                            icon
                            class="mt-3"
                            @click="onCancelEditEmail()"
                            >
                            <v-icon color="red">close</v-icon>
                            </v-btn>
                          <v-btn
                            icon
                            class="mt-3"
                            @click="onUpdateEmail('email')"
                            :disabled="loadingEmail"
                            :loading="loadingEmail"
                            >
                            <v-icon color="green">check</v-icon>
                            </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <v-alert
                      v-if="editEmail"
                      color="primary"
                      border="left"
                      text
                      class="ml-0 mt-n3 mb-6"
                      type="info"
                      >
                      Upon updating your email, you will be automatically signed out. 
                      Check your email inbox for a validation link after saving. 
                      You must revalidate before signing back in.
                    </v-alert>
                  </v-form>

                  <v-form :key="nameKey" data-vv-scope="name">
                    <v-row>
                        <v-col lg="4">
                          <v-text-field
                            v-model="profileName"
                            :readonly="!editName"
                            :outlined="editName"
                            color="red"
                            label="User Name"
                            name="name"
                            v-validate="'required'"
                            :error-messages="errors.collect('name.name')"
                          ></v-text-field>
                        </v-col>

                        <v-col lg="4" class="ml-n4">
                          <v-btn
                            v-if="!editName"
                            class="mt-3"
                            @click="editName = true"
                            icon
                            >
                            <v-icon color="grey lighten-1" small>edit</v-icon>
                            </v-btn>
                          <div v-if="editName">
                            <v-btn
                              v-if="!loadingName"
                              icon
                              class="mt-3"
                              @click="onCancelEditName()"
                              >
                              <v-icon color="red">close</v-icon>
                              </v-btn>
                            <v-btn
                              icon
                              class="mt-3"
                              @click="onUpdateName('name')"
                              :disabled="loadingName"
                              :loading="loadingName"
                              >
                              <v-icon color="green">check</v-icon>
                              </v-btn>
                          </div>
                        </v-col>
                    </v-row>
                  </v-form>

                  <v-form :key="passwordKey" data-vv-scope="password">
                    <v-row>
                      <v-col lg="4" v-if="!editPassword">
                        <v-text-field
                          value="password"
                          label="Password"
                          type="password"
                          readonly
                          name="password"
                        ></v-text-field>
                      </v-col>
                      
                      <template v-if="editPassword">
                        <v-col lg="3">
                          <v-text-field
                            v-model="currentPassword"
                            label="Current Password"
                            v-validate="'required|min:6'"
                            :error-messages="errors.collect('password.currentPassword')"
                            name="currentPassword"
                            outlined
                            :append-icon="show1 ? 'visibility' : 'visibility_off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                          ></v-text-field>
                        </v-col>
                        <v-col lg="3">
                          <v-text-field
                            v-model="newPassword"
                            label="New Password"
                            v-validate="'required|min:6'"
                            :error-messages="errors.collect('password.newPassword')"
                            name="newPassword"
                            outlined
                            :append-icon="show2 ? 'visibility' : 'visibility_off'"
                            :type="show2 ? 'text' : 'password'"
                            @click:append="show2 = !show2"
                          ></v-text-field>
                        </v-col>
                        <v-col lg="3">
                          <v-text-field
                            v-model="confirmPassword"
                            label="Confirm New Password"
                            v-validate="'required|min:6'"
                            :error-messages="errors.collect('password.confirmPassword')"
                            name="confirmPassword"
                            outlined
                            :append-icon="show3 ? 'visibility' : 'visibility_off'"
                            :type="show3 ? 'text' : 'password'"
                            @click:append="show3 = !show3"
                          ></v-text-field>
                        </v-col>
                      </template>

                      <v-col class="ml-n4" lg="3">
                          <v-btn
                            v-if="!editPassword"
                            class="mt-3"
                            @click="editPassword = true"
                            icon
                            >
                            <v-icon color="grey lighten-1" small>edit</v-icon>
                            </v-btn>
                          <div v-if="editPassword">
                            <v-btn
                              v-if="!loadingPassword"
                              icon
                              class="mt-3"
                              @click="onCancelEditPwd()"
                              >
                              <v-icon color="red">close</v-icon>
                              </v-btn>
                            <v-btn
                              icon
                              class="mt-3"
                              @click="onUpdatePwd('password')"
                              :disabled="loadingPassword"
                              :loading="loadingPassword"
                              >
                              <v-icon color="green">check</v-icon>
                              </v-btn>
                            <span class="incentable-edit-note"></span>
                          </div>
                        </v-col>
                    </v-row>
                  </v-form> 

                  <v-row no-gutters cols="12">
                    <v-col lg="4">
                      <v-text-field
                        name="id"
                        label="ID"
                        v-model="id"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="error">
                    <app-alert @dismissed="onDismissed" :text="error.message"></app-alert>
                  </v-row>

                  <v-row v-if="errorMessage">
                  <v-alert
                    :value="true"
                    color="primary"
                    border="left"
                    text
                    type="error"
                    >
                      {{errorMessage}}
                    </v-alert>
                  </v-row>

            </v-col>
          
          </v-row>

          <v-dialog v-model="profileDelete" max-width="400px" persistent>
            <v-card>
              <v-img
                :src="require('@/assets/background.png')"
                :height="systemTheme.cardImageHeight"
              >
              <v-overlay
              absolute
              color="red"
              :opacity="systemTheme.cardOpacity"
              >
              </v-overlay>
              </v-img>
              <v-row justify="center" class="mt-n12" no-gutters>
                <v-avatar
                  size="100"
                  color="white"
                  outline
                >
                <v-icon size="99" color="red">error</v-icon>
                </v-avatar>
              </v-row>
              <v-row justify="center" no-gutters>
                <v-card-title class="page-heading">
                  Are You Sure?
                </v-card-title>
              </v-row>
              
              <v-card-text class="px-16 py-10">
                <v-row dense>
                  <v-col>
                    <v-row>
                      Please confirm you wish to delete your profile. You will lose all your data and can't recover it.
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="currentPassword"
                        label="Password"
                        type="password"
                        v-validate="'required|min:6'"
                        :error-messages="errors.collect('currentPassword')"
                        name="currentPassword"
                        data-vv-name="currentPassword"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <app-alert 
                        v-if="error"
                        @dismissed="onDismissed"
                        :text="error.message"
                        >
                      </app-alert>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="closeDelProfile()"
                  text
                  >
                  Cancel
                </v-btn>
                <v-btn
                  color="red"
                  class="white--text"
                  @click="onDelete()"
                  :loading="loading"
                  >
                  Delete profile
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-col>
      </v-row>
    </div>
</template>

<script>
  export default {
    $_veeValidate: {
      validator: 'new'
    },
    created() {
      this.$store.dispatch('loadSystemAdmins')
      this.$store.dispatch('initialize')
      this.$store.commit('setNavMenu', false)
      this.$store.dispatch('setPreview', false)
      this.$store.dispatch('setToolbar', true)
      this.$store.dispatch('setFooter', true)
      
      // Starting constants
      const userId = this.$store.getters.user.id
      const userEmail = this.$store.getters.email
      const userName = this.name
      
      // Dynamic values
      this.id = userId
      this.email = userEmail
      this.profileName = userName
      
      // Reset values
      this.initialId = userId
      this.initialEmail = userEmail
      this.initialName = userName
    },

    mounted() {
      this.$validator.localize('en', this.dictionary)
    },

    data: function () {
      return {
        profileDelete: false,
        pageTitle: 'Your Profile',
        pageSubTitle: 'Manage your admin profile',
        searchActive: false,
        currentPassword: null,
        currentPasswordForEmail: null,
        newPassword: null,
        confirmPassword: null,
        show: false,
        show1: false,
        show2: false,
        show3: false,
        errorMessage: null,
        
        // Email button set
        editEmail: false,
        loadingEmail: false,
        emailKey: Date.now(),
        
        // Name button set
        editName: false,
        loadingName: false,
        nameKey: Date.now() + 1,

        // Name button set
        editPassword: false,
        loadingPassword: false,
        passwordKey: Date.now() + 2,
        
        dictionary: {
          custom: {
            currentPasswordForEmail: {
              required: () => 'Password is required',
            },
            currentPassword: {
              required: () => 'Password is required',
            },
            newPassword: {
              required: () => 'Password is required',
            },
            confirmPassword: {
              required: () => 'Confirm Password is required',
            },
            name: {
              required: () => 'Name cannot be empty',
            },
            email: {
              required: () => 'Email is required',
            }
          }
        },
      }
    },
    
    methods: {      
      // Email Update
      onUpdateEmail(scope) {
          this.$validator.validateAll(scope).then(result => {
          if (!result) {
            return
          }

          if (this.email === this.initialEmail) {
          this.$store.dispatch('setSnackbar', 'Email is unchanged. If you wish to update your email, please enter the new email')
          } else {
            this.loadingEmail = true
            const id = this.id
            const email = this.email
            const currentPasswordForEmail = this.currentPasswordForEmail
            const payload = {
              id: id,
              email: email,
              currentPassword: currentPasswordForEmail,
            };
            this.$store.dispatch('updateAdminEmail', payload)
            .then(() => {
              this.loadingEmail = false
              this.editEmail = false
              this.currentPasswordForEmail = null
              const instance = this
              instance.email = this.initialEmail
              instance.emailKey = Date.now()
            })
            .catch((e) => {
              this.errorMessage = e;
            });
          }
        })
      },
      onCancelEditEmail() {
        this.$validator.reset()
        const instance = this
        instance.email = this.initialEmail
        instance.emailKey = Date.now()
        this.editEmail = false
      },
      
      // Name Update
      onUpdateName(scope) {
        this.$validator.validateAll(scope).then(result => {
          if (!result) {
            return
          }
          this.loadingName = true
          const id = this.id
          const name = this.profileName
          const payload = {
            id: id,
            name: name,
          };
          this.$store.dispatch('updateAdminName', payload)
          .then(() => {
            this.loadingName = false
            this.editName = false
            this.$store.dispatch('loadSystemAdmins')
          })
          .catch((e) => {
            this.errorMessage = e;
            this.loadingName = false
            this.editName = false
          });
          
        })
      },

      onCancelEditName() {
        this.$validator.reset()
        const instance = this
        instance.name = this.initialName
        instance.nameKey = Date.now()
        this.editName = false
      },

      // Password Update
      onUpdatePwd(scope) {
          this.$validator.validateAll(scope).then(result => {
          if (!result) {
            return
          }
          if (this.newPassword !== this.confirmPassword) {
            this.errorMessage = 'Confirm Password must match Password'
            return
          }
          this.errorMessage = null
          this.loadingPassword = true
          this.$store.dispatch('updateAdminPwd', {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
          })
          .then(() => {
            this.$validator.reset()
            this.loadingPassword = false
            this.currentPassword = null
            this.newPassword = null
            this.confirmPassword = null
          })
          .catch((e) => {
            this.errorMessage = e;
            this.loadingPassword = false
            this.currentPassword = null
            this.newPassword = null
            this.confirmPassword = null
          });
        })
      },
      onCancelEditPwd() {
        this.$validator.reset()
        const instance = this
        instance.passwordKey = Date.now()
        this.currentPassword = null
        this.newPassword = null
        this.confirmPassword = null
        this.editPassword = false
        this.errorMessage = null
      },


      deleteDialog() {
        this.profileDelete = true
      },
      onDelete() {
        this.$validator.validate('currentPassword').then(result => {
          if (!result) {
            return
          }
          this.$store.dispatch('deleteUser', {
            currentPassword: this.currentPassword,
          })
          .then(() => {
            this.$router.push('/signin')
          })
          .catch(err => {
            console.log(err)
          })
        })
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      },
      closeDelProfile() {
        this.$store.dispatch('setLoading', false)
        this.onDismissed()
        this.profileDelete = false
        this.currentPassword = ''
        this.$validator.reset()
      }
    },

    computed: {
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      },
      profileEdit () {
        return this.$store.getters.profileEdit
      },
      orgTheme () {
      return this.$store.getters.orgTheme
      },
      systemTheme () {
        return this.$store.getters.systemTheme
      },
      systemAdmins () {
        return this.$store.state.systemadmin.systemAdmins
      },
      name () {
        if(this.systemAdmins.length > 0) {
          return this.systemAdmins.find(admin => admin.email === this.$store.getters.email).name || ''
        }
        return ''
      },
    },

    

    watch: {
      profileDelete (val) {
        val || this.closeDelProfile()
      },
      userName: {
      handler(newVal) {
        console.log(newVal)
        console.log(this.name)
        this.name = newVal
        console.log(this.name)
      }
    }
    },

  }
</script>

<style scoped>
.container {
  max-width: 1216px;
}
</style>

<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="record">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="primary"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Edit {{ formTitle }}
          </v-card-title>
        </v-row>

        <v-card-text class="px-16">
                
          <v-row dense>
            <v-col>
              <v-text-field
                name="dataKey"
                label="Data Key"
                v-validate="'required'"
                :error-messages="errors.collect('record.dataKey')"
                v-model="selectedPointsRecord.dataKey"
                @input="handleChange"
              ></v-text-field>
              <v-text-field
                name="points"
                label="Points"
                v-validate="'required'"
                :error-messages="errors.collect('record.points')"
                v-model="selectedPointsRecord.points"
                @input="handleChange"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col v-if="errorMessage">
                <v-alert
                  type="error"
                  :value="true"
                >
                  {{errorMessage}}
                </v-alert>
              </v-col>         
          </v-row>
        
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            >Cancel</v-btn>
          <v-btn
            :class="orgTheme.buttonTextColor"
            color="primary"
            @click="handleSave('record', selectedPointsRecord)"
            :loading="loading"
            elevation="0"
            :disabled="demo || !pendingChange"
            >Update</v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  created () {
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  props: ['open', 'selectedPointsRecord'],
  data: () => ({
    dictionary: {
      custom: {
        dataKey: {
          required: () => 'Data Key is required'
        },
        points: {
          required: () => 'Points are required'
        }
      }
    },
    errorMessage: '',
    formTitle: 'Points Record',
    formIcon: 'stars',
    pendingChange: false
  }),

  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    error () {
      return this.$store.getters.error
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    }
  },

  methods: {
    handleClose () {
      this.clear()
      this.pendingChange = false
      this.$emit('onClose')
    },
    handleChange () {
      this.pendingChange = true
    },
    clear () {
      this.$validator.reset()
      this.$store.dispatch('setLoading', false)
    },
    handleSave (scope, editedItemUpdate) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return
        }
        this.$emit('onSave', editedItemUpdate)
      })
    }
  },
}
</script>

<style>
</style>

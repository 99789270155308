<template>
  <v-row no-gutters>
    <v-col
      v-for="(column, index) in columns"
      :key="index"
      class="mouse fill-height no-double-border"
    >
      <div class="fill-height">
        <Fields
          :fieldsArr="column.items"
          :pageIdx="pageIdx"
          :sectionIdx="sectionIdx"
          :columnIdx="index"
        ></Fields>
      </div>
    </v-col>
  </v-row>
</template>

<script>
// import { nanoid } from "nanoid";
import Fields from "./Fields.vue";

export default {
  components: { Fields },
  props: {
    pageIdx: {
      type: Number,
      required: true,
    },
    sectionIdx: {
      type: Number,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data: () => ({}),
  created() {},
  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
  },
  methods: {},
  watch: {},
};
</script>

<style scoped>
.mouse {
  cursor: default !important;
}

.no-double-border {
  border: 1px dotted #ccc;
  border-right: none; /* Remove right border */
  box-sizing: border-box;
}

.no-double-border:last-child {
  border-right: 1px dotted #ccc; /* Add right border to the last column */
}
</style>

<template transition="scale-transition">
  <div> 
    <v-navigation-drawer
      v-if="openDesignDrawer"
      app
      right
      color="secondary"
      width="400px"
      class="drawer"
    >
    <perfect-scrollbar>
      <v-btn-toggle
        v-model="designDrawer"
        background-color="transparent"
        dark
        tile
        class="ma-0 pa-0"
      >
        <v-btn class="ma-0" value="Content" @click="setPageTemplate('Home')" width="80px">
          Content
        </v-btn>

        <v-btn class="ma-0" value="Header" @click="setPageTemplate('Header')" width="80px">
          Header
        </v-btn>

        <v-btn class="ma-0" value="Footer" @click="setPageTemplate('Footer')" width="80px">
          Footer
        </v-btn>

        <v-btn class="ma-0" value="Auth" @click="setPageTemplate('Signin')" width="80px">
          Auth
        </v-btn>
        
        <v-btn class="ma-0" value="Colors" @click="setPageTemplate('Colors')" width="80px">
          Colors
        </v-btn>
      </v-btn-toggle>
      <v-row no-gutters>
        <v-col>
          <editor/>
        </v-col>
      </v-row>
      <v-footer v-if="hasWebThemePendingUpdates" bottom fixed color="secondary">
        <design-save-button :block="true"></design-save-button>
      </v-footer>
      <v-footer v-else bottom fixed color="secondary" padless>
        <v-btn-toggle
          background-color="transparent"
          dark
          tile
          class="ma-0 pa-0"
        >
        
        <v-btn class="ma-0" @click="toggleLiveSiteDialog()" width="100px">
          <v-icon class="mr-1" color="primary">launch</v-icon>
            Site
        </v-btn>
        
        <v-btn class="ma-0" @click="toggleMenu()" width="100px">
          <v-icon class="mr-1" color="primary">menu</v-icon>
            Menu
        </v-btn>

        <v-btn class="ma-0" @click="toggleGrid()" width="100px">
          <v-icon class="mr-1" color="primary" v-if="grid.block === 'grid-off'">grid_on</v-icon>
          <v-icon class="mr-1" color="primary" v-else>grid_off</v-icon>
            Grid
        </v-btn>
        
        <v-btn class="ma-0" @click="handleDesignDrawer()" width="100px">
          <v-icon class="mr-1 rotate180" color="primary">close</v-icon>
            Close
        </v-btn>

      </v-btn-toggle>
      </v-footer>
      </perfect-scrollbar>
      <live-site-dialog :openLiveSiteDialog="openLiveSiteDialog" @onClose="onClose()"/>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DesignSaveButton from './Shared/DesignSaveButton.vue'
import LiveSiteDialog from '../../Shared/LiveSiteDialog.vue'
import Editor from './Editor.vue'

export default {
  components: {
    DesignSaveButton,
    Editor,
    LiveSiteDialog
  },
  created () {
  },
  data: function () {
    return {
      openLiveSiteDialog: false
    }
  },
  computed: {
    designDrawer: {
      set(val) {
        this.$store.dispatch('setDesignDrawer', val)
      },
      get() {
        return this.$store.getters.designDrawer
      }
    },
    openDesignDrawer() {
      return this.$store.getters.openDesignDrawer
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates
    },
    grid() {
      return this.$store.getters.grid
    },
    toolbar () {
      return this.$store.state.toolbar
    },
  },
  methods: {
    setPageTemplate(val) {
      this.$store.dispatch('setSelectedPageTemplate', val)
    },
    toggleMenu() {
      if (this.toolbar === true) {
        this.$store.dispatch('setToolbar', false)
        this.$store.dispatch('setNavMenu', false)
        this.$store.dispatch('setDrawer', false)
        this.$store.dispatch('setNavTitle', false)
      }
      else if (this.toolbar === false) {
        this.$store.dispatch('setToolbar', true)
        this.$store.dispatch('setNavMenu', true)
        this.$store.dispatch('setDrawer', true)
      }
      return
    },
    handleDesignDrawer() {
      if (this.openDesignDrawer === false) {
        this.$store.dispatch('setOpenDesignDrawer', true)
      } else {
        this.$store.dispatch('setOpenDesignDrawer', false)
      }
      if (this.designDrawer === null) {
        this.$store.dispatch('setDesignDrawer', 'Content')
        this.$store.dispatch('setSelectedPageTemplate', 'Home')
      } else {
        return
      }
    },
    toggleGrid() {
      if (this.grid.block === 'grid-block') {
        const val = {
          block: 'grid-off',
          outer: 'grid-off',
          inner: 'grid-off'
        }
        this.$store.dispatch('setGrid', val)
      } else {
        const val = {
          block: 'grid-block',
          outer: 'grid-outer',
          inner: 'grid-inner'
        }
        this.$store.dispatch('setGrid', val)
      }
    },
    toggleLiveSiteDialog() {
      if (this.openLiveSiteDialog == false) {
        this.$store.dispatch('loadMembers')
        this.openLiveSiteDialog = true
      } else {
        this.openLiveSiteDialog = false
      }
    },
    onClose() {
      this.openLiveSiteDialog = false
    }
  }
}
</script>

<style scoped>
.drawer {
  z-index: 203 !important;
  outline: 1px solid rgb(46, 46, 46) !important;
}
</style>


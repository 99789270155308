<template>
  <div>
    <view-theme />
    <design-drawer />
    <design-footer />
  </div>
</template>

<script>
import DesignDrawer from '../Editor/Drawer.vue'
import DesignFooter from './Footer.vue'
import ViewTheme from './ViewTheme.vue'

export default {
  components: {
    DesignDrawer,
    DesignFooter,
    ViewTheme
  },
  mounted () {
  },
  data: () => ({
    }
  ),
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    header() {
      return this.webTheme.header.components
    },
    content() {
      return this.webTheme.content.home.components
      // Aditional page content previews get added in here
      // and return content based on menu 
      // selected logic, Home, rewards, articles, submissions etc
    },
    footer() {
      return this.webTheme.footer.components
    },
    grid() {
      return this.$store.getters.grid
    },
  },
  methods: {
    handleDrawer(val) {
      if (val === this.$store.getters.designDrawer) {
        this.$store.dispatch('setDesignDrawer', null)
      } else {
        this.$store.dispatch('setDesignDrawer', val)
      }
    },
    toggleGrid() {
      if (this.grid.block === 'grid-block') {
        const val = {
          block: 'grid-off',
          outer: 'grid-off',
          inner: 'grid-off'
        }
        this.$store.dispatch('setGrid', val)
      } else {
        const val = {
          block: 'grid-block',
          outer: 'grid-outer',
          inner: 'grid-inner'
        }
        this.$store.dispatch('setGrid', val)
      }
    },
  }
}
</script>

<style scoped>
.dotted {
  border: 1px dashed rgb(184, 184, 184) !important;
}
.incentable-link {
  font-family: 'Roboto', sans-serif !important;
  text-decoration: none;
  font-size: 12px;
  font-weight: 300 !important;
  color: #BDBDBD;
}
</style>


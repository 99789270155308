<template>
  <div>
    <v-row no-gutters>
      <v-col
        v-for="(video, id) in videosInSection(section)"
        :key="id"
        class="pa-3"
        md="4"
        xs="12"
      >
        <v-hover v-slot="{ hover }">
          <v-card height="100%" class="el rounded-card">
            <v-img :src="require('@/assets/card-background-2.png')">
              <v-btn
                v-if="hover && !video.restricted"
                absolute
                fab
                color="primary"
                size="60"
                class="play-button"
                elevation="0"
                @click="handleOpenVideoPlayer(video)"
                ><v-icon size="30">play_arrow</v-icon></v-btn
              >
              <v-btn
                v-if="hover && video.restricted"
                absolute
                fab
                color="primary"
                size="60"
                class="play-button"
                elevation="0"
                disabled
                ><v-icon size="30">lock</v-icon></v-btn
              >
              <v-card-title>
                <v-row no-gutters>
                  <v-img
                    :src="require(`@/assets/incentable_logo_white_text.png`)"
                    max-width="83"
                    contain
                  ></v-img>
                </v-row>
              </v-card-title>
              <v-card-text class="pt-3 pl-6 pr-16">
                <span class="video-title">{{ video.title }}</span>
              </v-card-text>

              <v-row no-gutters justify="start">
                <v-card-actions class="card-actions">
                  <v-chip
                    v-if="video.restricted && hover"
                    color="primary"
                    class="ml-2 mb-1"
                    pill
                  >
                    <v-avatar left>
                      <v-icon size="18" class="mr-n2">lock</v-icon>
                    </v-avatar>
                    Subscriber Content
                  </v-chip>
                </v-card-actions>
              </v-row>
              <v-row no-gutters justify="end">
                <v-card-actions class="card-actions">
                  <v-chip color="secondary" class="mr-2 mb-1">
                    {{ video.time }}
                  </v-chip>
                </v-card-actions>
              </v-row>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <VideoPlayer
      v-if="openVideoPlayer"
      :url="selectedVideo"
      :openVideoPlayer="openVideoPlayer"
      :onClose="onClose"
    />
  </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer.vue";
export default {
  components: { VideoPlayer },
  props: ["section"],
  data: () => ({
    openVideoPlayer: false,
    selectedVideo: null,
    // restriced - for users with an active program subscription only
    videos: [
      {
        id: "fmP6wbSl_Zo",
        section: "Quick Start Guides",
        title: "Create a program",
        time: "2:47",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Quick Start Guides",
        title: "Adding content after setup",
        time: "23:26",
        restricted: true,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Quick Start Guides",
        title: "Customise the design theme",
        time: "14:56",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Members",
        title: "Add a member",
        time: "12:51",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Members",
        title: "Import members via CSV",
        time: "20:12",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Members",
        title: "Invite members by email",
        time: "3:25",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Registration",
        title: "Set up a member registration form",
        time: "14:56",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Companies",
        title: "Add a company",
        time: "15:36",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Companies",
        title: "Import companies via CSV",
        time: "12:20",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Companies",
        title: "Linking companies and members",
        time: "17:34",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Rewards",
        title: "Add a reward",
        time: "12:35",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Rewards",
        title: "Import rewards via CSV",
        time: "14:56",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Content Management",
        title: "About member tags",
        time: "6:26",
        restricted: false,
      },
      {
        id: "fmP6wbSl_Zo",
        section: "Content Management",
        title: "About company tags",
        time: "5:28",
        restricted: false,
      },
    ],
  }),
  created() {},
  computed: {},
  methods: {
    videosInSection(section) {
      return this.videos.filter((video) => video.section === section);
    },
    handleOpenVideoPlayer(video) {
      this.openVideoPlayer = true;
      this.selectedVideo =
        "https://www.youtube-nocookie.com/embed/" + video.id + "?autoplay=1";
    },
    onClose() {
      this.openVideoPlayer = false;
      this.selectedVideo = null;
    },
  },
};
</script>

<style>
.play-button {
  margin-top: 82px !important;
  margin-left: 170px !important;
}
.video-title {
  font-family: Montserrat, Tahoma, sans-serif !important;
  color: white;
  font-size: 30px;
  font-weight: 600;
  line-height: 1em;
  padding-right: 0px !important;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>

<template>
  <v-dialog v-model="questionDialogOpen" max-width="900px" persistent>
    <v-card>
      <form data-vv-scope="question">
        <v-card-title class="page-heading">
          {{ editing ? "Edit" : "Add New" }}
          {{ formTitle }}
        </v-card-title>

        <v-card-text class="px-6">
          <v-row class="file-title ml-0 mt-0"> Form Field Type </v-row>
          <v-row class="file-title ml-0 mt-n4">
            <v-radio-group v-model="question.type" row class="ml-3">
              <v-radio
                label="Radio Buttons (One answer)"
                value="radio"
              ></v-radio>
              <v-radio
                label="Checkboxes (Multiple answers)"
                value="checkboxes"
              ></v-radio>
              <v-radio label="Dropdown" value="dropdown"></v-radio>
            </v-radio-group>
          </v-row>
          <v-textarea
            v-model="question.text"
            label="Question"
            v-validate="'required'"
            :error-messages="errors.collect('question.text')"
            name="question"
            type="text"
            outlined
            auto-grow
            rows="1"
            placeholder="Enter question text"
            class="mb-n2"
          />

          <v-card elevation="0" outlined color="grey lighten-4">
            <form data-vv-scope="newAnswer">
              <v-row no-gutters class="mt-2 mx-2 mb-n2">
                <v-col cols="6">
                  <v-text-field
                    v-model="newAnswer.answer"
                    label="Enter Answer"
                    placeholder="Enter Answer Here..."
                    outlined
                    dense
                    v-validate="'required'"
                    name="answer"
                    :error-messages="errors.collect('newAnswer.answer')"
                    background-color="white"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="px-2">
                  <v-text-field
                    v-model="newAnswer.points"
                    label="Points"
                    outlined
                    dense
                    background-color="white"
                    type="number"
                    @wheel="$event.target.blur()"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-checkbox
                    v-model="newAnswer.correct"
                    label="Correct Answer"
                    outlined
                    class="mt-1 mr-2"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    :disabled="disableAdd"
                    @click="addAnswer('newAnswer')"
                    color="primary"
                    elevation="0"
                    class="mt-0"
                    >add new</v-btn
                  >
                </v-col>
              </v-row>
            </form>
            <v-divider></v-divider>
            <v-data-table
              class="grey lighten-4"
              :headers="headers"
              :items="answers"
              :search="search"
              :options.sync="options"
              :loading="loadingTable"
              no-data-text="No Answers yet. Use the form above to enter them."
              :hide-default-footer="true"
              :disable-pagination="true"
              dense
            >
              <template v-slot:[`item.correct`]="{ item }">
                <v-checkbox
                  v-model="item.correct"
                  class="mt-1 mb-n3 float-right mr-12"
                  readonly
                ></v-checkbox>
              </template>
              <template v-slot:[`item.points`]="{ item }">
                <span class="mr-2">{{ item.points | number }}</span>
              </template>
              <template v-slot:[`item.answer`]="{ item }">
                <span class="mr-2">{{ item.answer | truncate(75) }}</span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left close-on-content-click>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link>
                      <v-list-item-title @click="handleEditAnswer(item)">
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title
                        @click="handleDeleteAnswerConfirmation(item)"
                        >Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>

          <v-row class="file-title ml-0 mt-4"> Preview </v-row>
          <v-row class="ml-0">
            <v-col :cols="question.type === 'dropdown' ? 4 : 12">
              <v-select
                v-if="question.type === 'dropdown'"
                :items="question.answers"
                outlined
                dense
                item-text="answer"
                placeholder="Select answer..."
              ></v-select>
              <v-radio-group class="mt-n4" v-if="question.type === 'radio'">
                <v-radio
                  v-for="(answer, key) in answers"
                  :key="key"
                  :label="answer.answer"
                  :value="key"
                ></v-radio>
              </v-radio-group>
              <div v-if="question.type === 'checkboxes'">
                <v-checkbox
                  v-for="(answer, key) in answers"
                  :key="key"
                  :label="answer.answer"
                  :value="key"
                  class="checkbox-margins"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert
                color="primary"
                border="left"
                text
                type="error"
                :value="true"
              >
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Cancel </v-btn>
          <v-btn
            @click="handleSave('quiz')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            elevation="0"
            :loading="loading"
            :disabled="demo"
            >Save
          </v-btn>
        </v-card-actions>
      </form>

      <edit-answer
        v-if="dialogEditAnswer"
        :dialogEditAnswer="dialogEditAnswer"
        @onConfirmEditAnswer="confirmEditAnswer"
        @onCancelEditAnswer="cancelEditAnswer"
        :answer="answer"
      ></edit-answer>

      <confirm-delete-answer
        :dialogDeleteAnswer="dialogDeleteAnswer"
        @onConfirmDeleteAnswer="confirmDeleteAnswer"
        @onCancelDeleteAnswer="cancelDeleteAnswer"
        :answerText="answerText"
      ></confirm-delete-answer>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmDeleteAnswer from "./ConfirmDeleteAnswer.vue";
import EditAnswer from "./EditAnswer.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  created() {
    this.$store.dispatch("setLoading", false);
  },
  props: {
    editingItem: Object, // The item being edited, if any
    questionDialogOpen: Boolean,
  },
  components: { ConfirmDeleteAnswer, EditAnswer },
  data: () => ({
    dialogDeleteAnswer: false,
    dialogEditAnswer: false,
    errorMessage: "",
    dictionary: {
      custom: {
        text: {
          required: () => "Question is required",
        },
        answer: {
          required: () => "Answer is required",
        },
      },
    },
    formTitle: "Question",
    formIcon: "contact_support",
    newQuestion: {
      text: "",
      points: 0,
      answers: [],
      type: "radio",
    },
    newAnswer: {
      answer: "",
      points: 0,
      correct: false,
    },
    search: "",
    answerText: "",
    answer: {},
    // Table columns
    headers: [
      { text: "Answer", value: "answer", align: "left" },
      { text: "Points", value: "points", align: "center" },
      { text: "Correct Answer", value: "correct", align: "right" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],

    // Table settings
    options: {
      sortBy: ["title"],
      sortDesc: [false],
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
  }),
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    questions() {
      return this.$store.state.quiz.quiz.questions;
    },
    editing() {
      return !!this.editingItem;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    question() {
      if (this.editing) {
        return this.editingItem;
      } else {
        return this.newQuestion;
      }
    },
    answers() {
      return this.question.answers;
    },
    disableAdd() {
      return this.newAnswer.answer === "";
    },
  },
  methods: {
    reset() {
      this.newQuestion = {
        text: "",
        answers: [],
        type: "radio",
      };
      this.$validator.reset();
    },
    addAnswer(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        const answer = {
          ...this.newAnswer,
          points: +this.newAnswer.points,
        };
        if (!validationResult) {
          console.log("not valid");
          return;
        }
        this.question.answers.push(answer);
        this.resetNewAnswer();
      });
    },
    resetNewAnswer() {
      this.newAnswer = {
        answer: "",
        points: 0,
        correct: false,
      };
      this.$validator.reset();
    },
    handleClose() {
      this.reset();
      this.$store.dispatch("setLoading", false);
      this.$emit("onClose");
    },
    handleSave(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        if (!validationResult) {
          return;
        }
        this.$store.dispatch("setLoading", true);
        this.$emit("onSaveQuestion", this.question);
        this.reset();
        this.$store.dispatch("setLoading", false);
      });
    },
    handleDeleteAnswerConfirmation(item) {
      this.editedIndex = this.answers.indexOf(item);
      this.dialogDeleteAnswer = true;
      this.answerText = item.answer;
    },
    cancelDeleteAnswer() {
      this.editedIndex = -1;
      this.dialogDeleteAnswer = false;
    },
    confirmDeleteAnswer() {
      this.answers.splice(this.editedIndex, 1);
      const update = {
        ...this.quiz,
        ...this.quizUpdates,
      };
      //this.$store.dispatch("setQuizChanged", true);
      this.$store.dispatch("patchQuiz", update);
      this.dialogDeleteAnswer = false;
      this.editedIndex = -1;
    },
    handleEditAnswer(item) {
      this.answer = item;
      this.editedIndex = this.answers.indexOf(item);
      this.dialogEditAnswer = true;
    },
    cancelEditAnswer() {
      this.answer = {};
      this.editedIndex = -1;
      this.dialogEditAnswer = false;
    },
    confirmEditAnswer(editedAnswer) {
      Object.assign(this.answers[this.editedIndex], editedAnswer);
      this.editedIndex = -1;
      this.dialogEditAnswer = false;
    },
  },
};
</script>

<style scoped>
.checkbox-margins {
  margin-top: -18px !important;
}
</style>

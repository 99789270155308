<template>
  <div>
    <v-dialog v-model="dialogDelete" persistent max-width="600px">
      <v-card>
        <v-progress-linear
          :indeterminate="true"
          v-if="processing"
        ></v-progress-linear>
        <v-card-title>
          <span class="page-heading">Delete Points Rule Confirmation</span>
        </v-card-title>
        <v-row class="mx-6 mb-3" no-gutters>
          Please confirm you wish to delete this rule.
          <template v-if="databucket.pointsRedeemable"
            >Any redeemable points awarded by this rule will be revoked. This
            cannot be undone.</template
          >
        </v-row>
        <v-row class="mx-6 mb-3" no-gutters>
          <v-checkbox
            v-if="databucket.pointsRedeemable"
            label="I understand that redeemable points will be revoked"
            v-model="agreeToRevoke"
          ></v-checkbox>
        </v-row>
        <v-card-actions>
          <v-btn
            v-if="deletingLastRule && databucket.pointsRedeemable"
            color="primary"
            @click="handeleDialogTransactions(true)"
            elevation="0"
            text
            >view redeemable points transactions</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">cancel</v-btn>
          <v-btn
            color="primary"
            @click="confirmDelete()"
            elevation="0"
            :disabled="!agreeToRevoke && databucket.pointsRedeemable"
            :loading="processing"
            >yes, please delete</v-btn
          >
        </v-card-actions>
        <DatabucketRedeemableTransactions
          v-if="dialogTransactions"
          :dialogTransactions="dialogTransactions"
          :databucket="databucket"
          @onCloseTransactionsDialog="closeTransactionsDialog"
        ></DatabucketRedeemableTransactions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DatabucketRedeemableTransactions from "./RedeemablePointsTransactionsDialog.vue";
export default {
  components: { DatabucketRedeemableTransactions },
  props: {
    dialogDelete: {
      type: Boolean,
    },
  },
  created() {},
  data: () => {
    return {
      dialogTransactions: false,
      agreeToRevoke: false,
      processing: false,
    };
  },
  computed: {
    databucket() {
      return this.$store.getters.databucket;
    },
    pointsFormula() {
      return this.$store.getters.databucketPointsFormula;
    },
    pointsRedeemable() {
      return this.databucket.pointsRedeemable;
    },
    deletingLastRule() {
      return this.pointsFormula.length === 1;
    },
    revokeRedeemablePointsProcessing() {
      return this.$store.getters.revokeRedeemablePointsProcessing;
    },
  },
  methods: {
    closeDialog() {
      this.reset();
      this.$emit("onCancelDelete");
    },
    async confirmDelete() {
      this.processing = true;
      if (this.deletingLastRule && this.pointsRedeemable) {
        await this.$store.dispatch("setLastRuleDeleted", true);
      }
      this.reset();
      this.$emit("onConfirmDelete");
    },
    handeleDialogTransactions(val) {
      this.dialogTransactions = val;
    },
    closeTransactionsDialog() {
      this.dialogTransactions = false;
    },
    reset() {
      this.agreeToRevoke = false;
      this.processing = false;
    },
  },
};
</script>

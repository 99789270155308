<template>
  <v-dialog v-model="open" persistent max-width="1200px">
    <v-card>
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
        <v-overlay absolute color="primary" :opacity="systemTheme.cardOpacity">
        </v-overlay>
      </v-img>

      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar size="100" color="white" outline>
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>

      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-16">
        <v-row no-gutters class="incentable-form-heading">
          <v-col>
            {{ this.stepHeading }}
          </v-col>
        </v-row>
        <v-row dense class="incentable-form-subheading">
          <v-col>
            {{ this.stepDescription }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <csv-import
              class="pl-5"
              v-if="step === 0"
              v-model="parsedCsv"
              :key="csvComponentKey"
              :map-fields="mappingFields"
              :required-fields="requiredFields"
            />
          </v-col>
        </v-row>

        <div v-if="step === 1">
          <v-row dense>
            <v-col>
              <v-card class="pr-4">
                <v-card-title>
                  <v-icon color="primary" class="pr-4">translate</v-icon>
                  {{ parsedCsv.length }}
                </v-card-title>
                <v-card-text class="incentable-page-subheading">
                  Translations found in your CSV
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pr-4">
                <v-card-title>
                  <v-icon color="primary" class="pr-4">verified_user</v-icon>
                  {{ validNewTranslations.length }}
                </v-card-title>
                <v-card-text class="incentable-page-subheading">
                  Are valid and will be imported
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-alert
            v-if="validNewTranslations.length !== parsedCsv.length"
            color="primary"
            border="left"
            dense
            text
            class="mt-3"
          >
            <v-row dense no-gutters align="center">
              <v-col cols="auto">
                <v-icon color="primary" class="mx-2" size="26">business</v-icon>
              </v-col>
              <v-col class="pl-2 incentable-alert">
                {{ parsedCsv.length - validNewTranslations.length }} omitted
                from import because they already exist <br />If you wish to
                change or update the existing translation, add it's Account Key
                to the CSV <br />You can still proceed, the omitted translations
                will be left out of the import
              </v-col>
            </v-row>
          </v-alert>

          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">English</th>
                  <th class="text-left">Translation</th>
                  <th class="text-left">Import Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in newTranslations" :key="item.english">
                  <td>{{ item.english }}</td>
                  <td>{{ item.value }}</td>
                  <td>
                    <v-chip v-if="!item.isValid" color="red" small
                      >Omitted</v-chip
                    >
                    <v-chip
                      v-if="item.isValid && item.isUpdate"
                      color="amber"
                      small
                      >Update Existing</v-chip
                    >
                    <v-chip
                      v-if="item.isValid && !item.isUpdate"
                      color="green"
                      small
                      >Add New</v-chip
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div v-if="step === 2">
          <v-row>
            <v-col align="center" v-if="!processSuccess">
              <div>
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="red"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div class="incentable-page-subheading red--text my-8">
                Processing
              </div>
              <v-alert color="red" dense text border="left">
                <v-row align="center">
                  <v-col lg="1">
                    <v-icon color="red" class="mx-2" size="26">warning</v-icon>
                  </v-col>
                  <v-col class="pl-4 incentable-alert" md="auto">
                    Do not close browser while processing
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col align="center" v-if="processSuccess">
              <div>
                <v-icon size="80" color="primary" class="mt-7">check</v-icon>
              </div>
              <div class="incentable-page-subheading my-2 primary--text">
                Success
              </div>
            </v-col>
            <v-col align="center" v-if="processFailed">
              <div>
                <v-icon size="80" color="red" class="mt-7">close</v-icon>
              </div>
              <div class="incentable-page-subheading my-2 red--text">
                Import Failed
              </div>
            </v-col>
            <v-col>
              <div v-for="logItem in processingDetails" :key="logItem">
                {{ logItem }}
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn v-if="!isLastStep" color="primary" text @click="close()">
          Cancel
        </v-btn>
        <v-btn
          elevation="0"
          v-if="!isLastStep"
          color="primary"
          :disabled="!isNextStepReady"
          @click="gotoNext()"
        >
          Next Step
        </v-btn>
        <v-btn
          elevation="0"
          v-if="isLastStep && processSuccess"
          color="primary"
          @click="gotoNext()"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import * as utils from '@/utils';

const TOTAL_STEP = 3;

const CSV_MAPPING = {
  ENGLISH: "English",
  VALUE: "Translation",
};

function makeTranslation(english = "", value = "", existingTranslations) {
  const foundTranslationByEnglish = existingTranslations.find(
    (item) => item.english === english
  );
  const foundTranslationByValue = existingTranslations.find(
    (item) => item.value === value
  );
  const isValid = !!(
    english &&
    (foundTranslationByValue || !foundTranslationByEnglish)
  );
  const isUpdate = !!(value && foundTranslationByValue);

  return {
    english,
    value,
    isValid,
    isUpdate,
    id: isUpdate ? foundTranslationByValue.id : null,
  };
}

export default {
  props: ["open", "directory"],
  data: () => {
    const mappingFields = [CSV_MAPPING.ENGLISH, CSV_MAPPING.VALUE];
    const requiredFields = [CSV_MAPPING.ENGLISH, CSV_MAPPING.VALUE];
    return {
      formEnglish: "Upload Translations",
      formIcon: "cloud_upload",
      csvComponentKey: Math.random(), // Note: This trick triggers the clearing of csv import component
      step: 0,
      mappingFields,
      requiredFields,
      parsedCsv: null,
      newTranslations: [],
      processingDetails: [],
      processSuccess: false,
      processFailed: false,
      formTitle: "Upload",
    };
  },
  computed: {
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    fieldExistency() {
      if (!this.parsedCsv || !this.parsedCsv.length) {
        return {};
      }

      const firstRow = this.parsedCsv[0];
      return this.mappingFields.reduce((result, field) => {
        return {
          ...result,
          // eslint-disable-next-line no-prototype-builtins
          [field]: firstRow.hasOwnProperty(field),
        };
      }, {});
    },

    validNewTranslations() {
      return this.newTranslations.filter(
        (rawTranslation) => rawTranslation.isValid
      );
    },

    stepHeading() {
      if (this.step === 0) {
        return "1. Upload CSV file";
      } else if (this.step === 1) {
        return "2. Review";
      } else if (this.step === 2) {
        return "3. Import Complete";
      } else {
        return "";
      }
    },

    stepDescription() {
      if (this.step === 0) {
        return "Please upload the CSV and select the column mapping.";
      } else if (this.step === 1) {
        return "Please check below details before you proceed. ";
      } else if (this.step === 2) {
        return "If import fails, please close the dialog and restart the import process.";
      } else {
        return "";
      }
    },

    isLastStep() {
      return this.step === TOTAL_STEP - 1;
    },

    isStep1Ready() {
      if (!this.parsedCsv || !this.parsedCsv.length) {
        return false;
      }
      return true;
    },

    isStep2Ready() {
      return this.validNewTranslations.length > 0;
    },

    isStep3Ready() {
      return true;
    },

    isNextStepReady() {
      const stepReadinessFuncName = `isStep${this.step + 1}Ready`;
      return this[stepReadinessFuncName];
    },
  },
  methods: {
    clear() {
      this.csvComponentKey = Math.random();
      this.parsedCsv = null;
      this.processingDetails = [];
      this.step = 0;
      this.processSuccess = false;
      this.processFailed = false;
    },
    close() {
      this.$emit("onClose");
      this.clear();
    },
    async gotoNext() {
      if (this.step === 0) {
        this.step += 1;
        const { newTranslations } = this.parsedCsv
          .reduce((result, row) => {
            // Note: Remove duplication inside csv itself.
            const duplicated = result.find(
              (item) =>
                item[CSV_MAPPING.ENGLISH] === row[CSV_MAPPING.ENGLISH] ||
                (item[CSV_MAPPING.VALUE] &&
                  item[CSV_MAPPING.VALUE] === row[CSV_MAPPING.VALUE])
            );

            return duplicated ? result : [...result, row];
          }, [])
          .reduce(
            (result, row) => {
              // Note: find the brand new tags and translation groups
              // Get the list of valid translations.
              const english = row[CSV_MAPPING.ENGLISH];
              const value = row[CSV_MAPPING.VALUE];
              const initTranslation = makeTranslation(
                english,
                value,
                this.directory
              );
              if (!initTranslation.isValid) {
                return {
                  ...result,
                  newTranslations: [...result.newTranslations, initTranslation],
                };
              }

              const translation = { ...initTranslation };

              return {
                newTranslations: [...result.newTranslations, translation],
              };
            },
            {
              newTranslations: [],
            }
          );

        this.newTranslations = newTranslations;
      } else if (this.step === 1) {
        this.step += 1;

        this.processingDetails = ["Importing translations..."];

        const importBatchId = new Date().getTime();
        const translationsToImport = this.validNewTranslations.map(
          (rawTranslation) => {
            // const tags = rawTranslation.rowTranslationTagsArray.map(rawTag => this.translationTagsMap[rawTag]);
            // const members = [];
            // const translationGroups = rawTranslation.rowTranslationGroupsArray.map(rawGroup => this.translationGroupsMap[rawGroup]);

            if (rawTranslation.isUpdate) {
              // TODO: When updating through csv, do not allow update for translation group
              // It will break the sync between the translation and translation group
              const updateObject = {
                id: rawTranslation.id,
                isUpdate: rawTranslation.isUpdate,
                importBatchId,
              };
              if (this.fieldExistency[CSV_MAPPING.ENGLISH]) {
                updateObject.english = rawTranslation.english;
              }
              return updateObject;
            }

            return {
              id: rawTranslation.id,
              importBatchId,
              english: rawTranslation.english,
              value: rawTranslation.value,
              isUpdate: rawTranslation.isUpdate,
            };
          }
        );

        try {
          await this.$store.dispatch(
            "importTranslations",
            translationsToImport
          );
          this.processingDetails = [
            ...this.processingDetails,
            "Translations imported successfully.",
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Importing translations failed.",
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Preparing synchronization between translation and group",
        ];

        try {
          await this.$store.dispatch("loadTranslations");
          this.processingDetails = [
            ...this.processingDetails,
            "Synchronization ready to start",
          ];
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Can't start synchronization",
          ];
          return;
        }

        this.processingDetails = [
          ...this.processingDetails,
          "Processing synchronization between translation and group...",
        ];

        const translationChanges = this.directory
          .filter((translation) => translation.importBatchId === importBatchId)
          .reduce((result, translation) => {
            if (!translation.translationGroup.length) {
              return result;
            }
            const changes = translation.translationGroup.map(
              (translationGroupId) => {
                return {
                  translationId: translation.id,
                  translationGroupId,
                  type: "add",
                };
              }
            );
            return [...result, ...changes];
          }, []);

        try {
          await this.$store.dispatch(
            "syncTranslationGroups",
            translationChanges
          );
          this.processingDetails = [
            ...this.processingDetails,
            "Syncing was successful.",
          ];
          this.processSuccess = true;
        } catch (e) {
          this.processingDetails = [
            ...this.processingDetails,
            "Syncing failed. Please retry the import",
          ];
          this.processFailed = true;
          return;
        }
      } else if (this.step === 2) {
        this.close();
      }
    },
  },
};
</script>

<style></style>

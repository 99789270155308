export const ORDER_ITEM_STATUS = [
  'Open',
  'Processing',
  'Shipping',
  'Completed',
  'Cancelled',
]
export const ORDER_STATUS = [
  'Open',
  'Closed'
]
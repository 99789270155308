/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
const state = {
  quiz: null,
  loadingQuiz: false,
  savingQuiz: false,
  quizChanged: false,
  quizUpdates: {},
  reorderingQuiz: false,
};

const actions = {
  async loadQuiz({ commit, getters }, quizId) {
    commit("setQuiz", null);
    commit("setLoadingQuiz", true);

    let querySnapshot;

    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("quizzes")
        .doc(quizId)
        .get();
    } catch (e) {
      querySnapshot = [];
      throw "Error occured when loading quiz";
    }

    const quiz = {
      id: querySnapshot.id,
      ...querySnapshot.data(),
      created: querySnapshot.data().created.toDate(),
      updated: querySnapshot.data().updated.toDate(),
    };

    commit("setQuiz", quiz);
    commit("setBreadCrumbDetail", quiz.title);
    commit("setLoadingQuiz", false);
  },

  async deleteQuiz({ getters }, payload) {
    //console.log(payload);
    const quizId = payload;
    const querySnapshot = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("quizzes")
      .doc(quizId);
    try {
      await querySnapshot.delete();
    } catch (e) {
      console.log(e);
      throw "error when deleting the quiz";
    }
  },

  async reorderQuizQuestions({ commit, state }, payload) {
    // Note: Make changes locally to show user visual changes immediately
    const update = {
      ...state.quiz,
      ...payload,
    };
    commit("patchQuiz", update);
    commit("updateQuiz", update);
    commit("setQuizChanged", true);
  },

  async updateQuiz({ commit, getters, dispatch }, payload) {
    commit("setSavingQuiz", true);
    commit("setReorderingQuiz", false);
    const id = getters.quiz.id;
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("quizzes");
    } catch (e) {
      querySnapshot = [];
    }

    let existingQuizResult;
    try {
      existingQuizResult = await querySnapshot
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing quiz";
    }

    let duplicated = false;
    existingQuizResult.forEach((doc) => {
      if (doc.id !== id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    let totalPoints = 0;
    console.log(payload.questions.length);
    if (payload.questions.length > 0) {
      // Loop through each question
      payload.questions.forEach((question) => {
        // Loop through each answer in the question
        question.answers.forEach((answer) => {
          // Accumulate the points
          totalPoints += answer.points;
        });
      });
    }

    const quiz = {
      ...payload,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
      created: timestamp,
      updated: timestamp,
      pointsOffered: totalPoints + payload.passPoints,
    };
    console.log(quiz);

    try {
      await querySnapshot.doc(id).update(quiz);
    } catch (e) {
      throw "Error when updating a quiz";
    }

    const quizBeforeUpdate = getters.quizzes.find((item) => item.id === id);
    // Note: server time is unavailable until we refetch.
    const tempQuiz = {
      ...quizBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };
    dispatch("setSnackbar", "Quiz updated");
    commit("updateQuiz", tempQuiz);
    commit("setSavingQuiz", false);
    dispatch("resetQuizUpdates");
  },

  setQuizChanged({ commit }, payload) {
    commit("setQuizChanged", payload);
  },
  setSavingQuiz({ commit }, payload) {
    commit("setSavingQuiz", payload);
  },
  patchQuiz({ commit }, payload) {
    commit("patchQuiz", payload);
  },
  resetQuizUpdates({ commit }) {
    commit("resetQuizUpdates", {});
  },
  setReorderingQuiz({ commit }, payload) {
    commit("setReorderingQuiz", payload);
  },
};

const mutations = {
  setQuiz(state, payload) {
    state.quiz = payload;
  },
  setLoadingQuiz(state, payload) {
    state.loadingQuiz = payload;
  },
  setSavingQuiz(state, payload) {
    state.savingQuiz = payload;
  },
  setReorderingQuiz(state, payload) {
    state.reorderingQuiz = payload;
  },
  resetQuizUpdates(state, payload) {
    state.quizUpdates = {
      ...payload,
    };
  },
  patchQuiz(state, payload) {
    state.quizUpdates = {
      ...payload,
    };
  },
  setQuizChanged(state, payload) {
    state.quizChanged = payload;
  },
  updateQuiz(state, payload) {
    state.quiz = payload;
  },
};

const getters = {
  quiz(state) {
    return state.quiz;
  },
  quizUpdates(state) {
    return state.quizUpdates;
  },
  quizChanged(state) {
    return state.quizChanged;
  },
  loadingQuiz(state) {
    return state.loadingQuiz;
  },
  savingQuiz(state) {
    return state.savingQuiz;
  },
  reorderingQuiz(state) {
    return state.reorderingQuiz;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

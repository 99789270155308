<template>
  <div class="mt-8 mr-8 mb-8">

    <v-row
      no-gutters
      align="center"
      >
      <v-col sm="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field> 
      </v-col>
      
      <v-col
        sm="3"
        v-if="options.filter"
        >
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter"
          prepend-inner-icon="filter_list"
          class="mr-4"
        ></v-select> 
      </v-col>
      
      <v-col>
        <v-btn
          @click="handleOpenImportDialog()"
          v-if="options.upload"
          class="primary--text"
          text
          >Upload</v-btn>
        <v-btn 
          v-if="options.download"
          class="primary--text"
          text
          :href="companiesCsv"
          download="Companies List.csv"
          >Download</v-btn> 
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
      </v-col>
      
      <v-col sm="auto">
        <v-btn
          @click="handleNew()"
          color="primary"
          elevation="0"
          class="mt-n2"
          >
          <v-icon>add</v-icon>new
        </v-btn>
      </v-col>
    </v-row>

  <!-- Data table -->

    <v-data-table
      :headers="headers"
      :items="awards"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          left
          close-on-content-click
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title @click="handleEdit(item)">
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor')"
              >
                <v-list-item-title 
                  @click="handleDeleteConfirmation(item)"
                  >Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:[`item.icon`]="{ item }">
          <v-img 
            :src="item.image.smallUrl ?  item.image.smallUrl : ''"
            :lazy-src="item.image.smallUrl ?  item.image.smallUrl : ''"
            max-height="38"
            contain
          ></v-img>
        </template>

        <template v-slot:[`item.award`]="{ item }">
          <span @click="handleEdit(item)" class="table-link pointer">{{ item.award }}</span>
          <v-chip v-show="item.isDefault" class="ml-3 white--text" small color="green">Default</v-chip>
        </template>

    </v-data-table>

  <!-- New or Edit dialog -->

    <awards-add-or-edit
      :awardId="selectedAwardId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

  <!-- Delete dialog -->

    <v-dialog 
      v-model="dialogDeleteOpen"
      max-width="400px"
      persistent
      >
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
            <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row dense>
            <v-col align="center">
              You are about to delete <span class="incentable-form-bold">{{ award }}</span>
            </v-col>
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleCloseDeleteDialog()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  

  </div>

</template>

<script>
  import AwardsAddOrEdit from './AwardAddOrEdit.vue'
  export default {
    components: { AwardsAddOrEdit },
    data: () => ({
      // Component settings unique to this component
      search: '',
      selectedAwardId: '',
      award: '',
      isDefault: false,

      // Table columns
      headers: [
        { text: 'Icon', value: 'icon', align: 'center', width: 40 },
        { text: 'Award', value: 'award', align: 'left' },
        { text: 'Rules', value: 'reformattedStatement', align: 'left' },
        { text: 'Applies to', value: 'selectedParticipantType', align: 'left' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],
      
      // Component settings common to all components
      dialogNewOrEditOpen: false,
      dialogDeleteOpen: false,

      // Table settings
      options: {
        sortBy: ['award'],
        sortDesc: [false],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: true,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
    }),
    computed: {
      demo () {
        return this.$store.state.program.currentProgram.demo
      },
      awards () {
        const awards = this.$store.state.award.awards
        const updatedAwards = awards.map((award) => {
          const reformattedStatements = award.conditions.map((condition) => condition.statement
            .replace(/__percent/ig, 'Percentage')
            .replace(/__actual/ig, 'Actual')
            .replace(/__target/ig, 'Target')
            .replace(/&&/g, ' And ')
            .replace('||', ' Or ')
            );
          const joinedStatements = reformattedStatements.join(' Else ');
          return {
            ...award,
            reformattedStatement: joinedStatements
          };
        });
      return updatedAwards
      },
      loadingTable () {
      return this.$store.getters.loadingTable
      },
      loading () {
      return this.$store.getters.loading
      },
      orgTheme () {
        return this.$store.getters.orgTheme
      },
      systemTheme () {
        return this.$store.getters.systemTheme
      }
    },

    methods: {
      // Actions specific to this table //

      // Actions common to all tables //

      handleNew() {
        // Open New form
        this.selectedAwardId = '';
        this.dialogNewOrEditOpen = true;
      },
      handleEdit(item) {
        // Open Edit form
        this.selectedAwardId = item.id;
        this.dialogNewOrEditOpen = true;
      },
      handleCloseNewOrEditDialog() {
        // Close New Or Edit form
        this.selectedAwardId = '';
        this.dialogNewOrEditOpen = false;
      },
      handleDeleteConfirmation (item) {
        // Delete record
        this.selectedAwardId = item.id;
        this.award = item.award;
        this.dialogDeleteOpen = true;
      },
      handleDelete() {
        // Delete record
        this.$store.dispatch('setLoading', true)
        this.$store.dispatch('deleteAward', this.selectedAwardId)
          .then(() => this.dialogDeleteOpen = false)
          .catch(() => this.dialogDeleteOpen = false)
          this.$store.dispatch('setLoading', false)
      },
      handleCloseDeleteDialog() {
        // Close delete form
        this.selectedAwardId = '';
        this.dialogDeleteOpen = false;
      },
    }
  }
</script>

<style>


</style>

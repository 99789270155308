<template>
  <div>
    <v-row
      v-if="availableTemplates.length > 0"
      class="mt-3 mx-3 mb-n6"
      no-gutters
      justify="center"
    >
      <v-select
        dense
        filled
        dark
        elevation="0"
        :items="availableTemplates"
        v-model="selectedPageTemplate"
        label="Select the template you wish to edit"
      ></v-select>
    </v-row>

    <div v-if="designDrawer === 'Colors'">
      <colors />
    </div>

    <!-- All components except colors> -->
    <div v-else>
      <v-row
        class="menu-heading-label uppercase mb-3 mt-4"
        no-gutters
        justify="center"
      >
        Active Components
      </v-row>

      <div
        v-if="!selectedComponents.length && !hasWebThemePendingUpdates"
        class="editor-subheading pt-1 pb-7 mx-16"
      >
        <v-icon dark size="21" class="mt-n1">info</v-icon> No active components,
        let's get started by selecting one below
      </div>
      <v-row no-gutters>
        <v-col>
          <draggable v-model="selectedComponents" ghost-class="ghost">
            <v-card
              tile
              color="secondary"
              elevation="0"
              v-for="(selected, index) in selectedComponents"
              :key="selected.idx"
              width="100%"
            >
              <v-divider color="#4E4E4E" class=""></v-divider>
              <v-row no-gutters align="center" class="pr-4">
                <v-col cols="auto" class="all-scroll ml-2 mr-2">
                  <v-icon color="#4E4E4E" class="my-4">drag_indicator</v-icon>
                </v-col>
                <v-col cols="1">
                  <v-icon color="primary">{{
                    getComponent(selected.id).icon
                  }}</v-icon>
                </v-col>
                <v-col>
                  <span class="editor-label">{{
                    getComponent(selected.id).title
                  }}</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-1 icon"
                        v-if="selected.dynamic"
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        bolt
                      </v-icon>
                    </template>
                    <span
                      >Dynamic Component. This component contains data driven
                      content</span
                    >
                  </v-tooltip>
                </v-col>
                <v-col>
                  <v-row no-gutters align="center" justify="end">
                    <v-icon
                      dark
                      @click="deleteComponent(index)"
                      :disabled="newComponent && newComponent !== selected.idx"
                      class="mr-2 icon-button"
                    >
                      delete_forever
                    </v-icon>
                    <v-icon
                      dark
                      v-if="editingComponent.idx !== selected.idx"
                      @click="handleEditor(selected)"
                      :disabled="newComponent && newComponent !== selected.idx"
                      class="mr-2 icon-button"
                    >
                      settings
                    </v-icon>
                    <v-icon
                      v-if="editingComponent.idx === selected.idx"
                      @click="clearEditors"
                      class="rotate180 mr-2 icon-button"
                    >
                      expand_circle_down
                    </v-icon>
                    <design-save-button
                      v-if="selected.idx === newComponent"
                      :small="true"
                    >
                      save
                    </design-save-button>
                  </v-row>
                </v-col>
              </v-row>

              <v-component
                :is="editingComponent.id"
                v-if="editingComponent.idx === selected.idx"
              />
            </v-card>
            <v-divider color="#4E4E4E" class=""></v-divider>
          </draggable>
        </v-col>
      </v-row>

      <v-row
        class="menu-heading-label uppercase mb-4 mt-4"
        no-gutters
        justify="center"
      >
        Available Components
      </v-row>

      <v-row no-gutters class="available-group pb-2">
        <v-hover v-for="component in availableComponents" :key="component.id">
          <template v-slot:default="{ hover }">
            <v-card
              class="pa-2 available-items"
              height="99px"
              width="99px"
              tile
              dark
              elevation="0"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="component.dynamic"
                    class="bolt icon"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    bolt
                  </v-icon>
                </template>
                <span
                  >Dynamic Component. This component contains data driven
                  content</span
                >
              </v-tooltip>
              <v-row align="center" class="fill-height" no-gutters>
                <v-col>
                  <v-row no-gutters justify="center">
                    <v-icon class="icon mb-1">{{
                      getComponent(component.id).icon
                    }}</v-icon>
                    <v-icon
                      v-if="
                        component.id === 'TextTwoCol' ||
                        component.id === 'TextThreeCol'
                      "
                      class="icon mb-1"
                      >{{ getComponent(component.id).icon }}</v-icon
                    >
                    <v-icon
                      v-if="component.id === 'TextThreeCol'"
                      class="icon mb-1"
                      >{{ getComponent(component.id).icon }}</v-icon
                    >
                  </v-row>
                  <v-row no-gutters justify="center">
                    <span class="editor-subheading text-center">{{
                      getComponent(component.id).title
                    }}</span>
                  </v-row>
                </v-col>
              </v-row>
              <v-overlay v-if="hover" absolute color="grey">
                <v-btn
                  @click="addComponent(component)"
                  :disabled="isNew"
                  color="primary"
                  fab
                  x-small
                  elevation="0"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-overlay>
            </v-card>
          </template>
        </v-hover>
      </v-row>
    </div>
  </div>
</template>

<script>
import { nanoid } from "nanoid";
import { PROGRESS_DEFAULTS } from "@/constants/webtheme/content/progress";
import { AWARDS_DEFAULTS } from "@/constants/webtheme/content/awards";
import { ARTICLES_PREVIEW_DEFAULTS } from "@/constants/webtheme/content/articlespreview";
import { ARTICLES_DEFAULTS } from "@/constants/webtheme/content/articles";
import { REWARDS_PREVIEW_DEFAULTS } from "@/constants/webtheme/content/rewardspreview";
import { REWARDS_DEFAULTS } from "@/constants/webtheme/content/rewards";
import { MENUBAR_DEFAULTS } from "@/constants/webtheme/header/menubar";
import { BANNER_DEFAULTS } from "@/constants/webtheme/header/banner";
import { HEADING_DEFAULTS } from "@/constants/webtheme/shared/heading";
import { PAGE_DEFAULTS } from "@/constants/webtheme/shared/page";
import { TEXT_ONE_COL_DEFAULTS } from "@/constants/webtheme/header/text";
import { TEXT_TWO_COL_DEFAULTS } from "@/constants/webtheme/header/text";
import { TEXT_THREE_COL_DEFAULTS } from "@/constants/webtheme/header/text";
import { PAGE_TEXT_DEFAULTS } from "@/constants/webtheme/shared/pagetext";
import { PROFILE_BODY_DEFAULTS } from "@/constants/webtheme/shared/profile";
import { AVATAR_DEFAULTS } from "@/constants/webtheme/shared/profile";
import { AUTH_SIGNIN_DEFAULTS } from "@/constants/webtheme/shared/auth";
import { AUTH_SIGNUP_DEFAULTS } from "@/constants/webtheme/shared/auth";
import { MENU_DEFAULTS } from "@/constants/webtheme/footer/menu";
import { IMAGE_DEFAULTS } from "@/constants/webtheme/shared/image";
import { CLAIMS_DEFAULTS } from "@/constants/webtheme/content/claims";
import { QUIZZES_DEFAULTS } from "@/constants/webtheme/content/quizzes";
import ProgressComponent from "./Shared/Progress/Main.vue";
import AwardsComponent from "./Shared/Awards/Main.vue";
import ArticlesPreviewComponent from "./Shared/Articles/ArticlesPreview.vue";
import ArticlesComponent from "./Shared/Articles/Articles.vue";
import RewardsPreviewComponent from "./Shared/Rewards/RewardsPreview.vue";
import RewardsComponent from "./Shared/Rewards/Rewards.vue";
import DesignSaveButton from "./Shared/DesignSaveButton.vue";
import MenuBar from "./Shared/MenuBar/Main.vue";
import Banner from "./Shared/Banner/Main.vue";
import ImageComponent from "./Shared/Image/Main.vue";
import FooterMenu from "./Shared/Menu/Main.vue";
import Colors from "./Shared/Colors/Main.vue";
import ClaimsComponent from "./Shared/Claims/Main.vue";
import QuizzesComponent from "./Shared/Quiz/Main.vue";
import TextOneCol from "./Shared/TextOneColEditor.vue";
import TextTwoCol from "./Shared/TextTwoColEditor.vue";
import TextThreeCol from "./Shared/TextThreeColEditor.vue";
import HeadingSection from "./Shared/HeadingSection.vue";
import PageHeadings from "./Shared/BasicPage/PageHeadings.vue";
import PageText from "./Shared/BasicPage/PageText.vue";
import AuthSignIn from "./Shared/Authentication/SignIn.vue";
import AuthSignUp from "./Shared/Authentication/SignUp.vue";
import Avatar from "./Shared/Profile/Avatar.vue";
import ProfileDetail from "./Shared/Profile/ProfileDetail.vue";

export default {
  components: {
    ProgressComponent,
    AwardsComponent,
    ArticlesPreviewComponent,
    ArticlesComponent,
    RewardsPreviewComponent,
    RewardsComponent,
    DesignSaveButton,
    MenuBar,
    Banner,
    FooterMenu,
    Colors,
    ImageComponent,
    ClaimsComponent,
    QuizzesComponent,
    TextOneCol,
    TextTwoCol,
    TextThreeCol,
    HeadingSection,
    PageHeadings,
    PageText,
    AuthSignIn,
    AuthSignUp,
    Avatar,
    ProfileDetail,
  },
  created() {
    this.$store.dispatch("editingField", {});
    this.$store.dispatch("editingComponent", {});
    this.$store.dispatch("setNewComponent", false);
    if (!this.selectedPageTemplate) {
      this.$store.dispatch("setSelectedPageTemplate", "Home");
    }
    return;
  },
  data: function () {
    return {
      templates: {
        header: [],
        content: [
          "Articles",
          "Basic Page",
          "Claims",
          "Home",
          "Profile",
          "Quizzes",
          "Rewards",
        ],
        footer: [],
        auth: ["Signin", "Signup"],
        colors: [],
      },
      // 'Section' options must be one or more of : 'Content', 'Header', 'Footer', 'Auth', 'Colors'
      // 'Templates' options below must be one or more of : 'Articles', 'Basic Page', 'Home', 'Claims', 'Rewards', 'Signin', 'Signup'
      components: [
        {
          title: "Articles",
          icon: "ballot",
          id: "ArticlesComponent",
          dynamic: true,
          section: ["Content"],
          templates: ["Articles"],
        },
        {
          title: "Articles Preview",
          icon: "ballot",
          id: "ArticlesPreviewComponent",
          dynamic: true,
          section: ["Content"],
          templates: ["Articles", "Home", "Claims", "Rewards"],
        },
        {
          title: "Banner",
          icon: "view_compact",
          id: "Banner",
          section: ["Content", "Header", "Footer", "Auth"],
          templates: [
            "Articles",
            "Basic Page",
            "Home",
            "Claims",
            "Rewards",
            "Signin",
            "Signup",
            "Profile",
          ],
        },
        {
          title: "Claims",
          icon: "receipt_long",
          id: "ClaimsComponent",
          dynamic: true,
          section: ["Content"],
          templates: ["Claims"],
        },
        {
          title: "Quizzes",
          icon: "fa-solid fa-clipboard-question",
          id: "QuizzesComponent",
          dynamic: true,
          section: ["Content"],
          templates: ["Quizzes"],
        },
        {
          title: "Colors",
          icon: "palete",
          id: "Colors",
          section: ["Colors"],
          templates: [],
        },
        {
          title: "Heading Section",
          icon: "fa-solid fa-heading",
          id: "HeadingSection",
          section: ["Content", "Header", "Footer", "Auth"],
          templates: [
            "Articles",
            "Basic Page",
            "Home",
            "Claims",
            "Rewards",
            "Signin",
            "Signup",
            "Profile",
          ],
        },
        {
          title: "Image",
          icon: "panorama",
          id: "ImageComponent",
          section: ["Content", "Header", "Footer", "Auth"],
          templates: [
            "Articles",
            "Basic Page",
            "Home",
            "Claims",
            "Rewards",
            "Signin",
            "Signup",
            "Profile",
          ],
        },
        {
          title: "Menu Bar",
          icon: "menu",
          id: "MenuBar",
          dynamic: true,
          section: ["Header"],
          templates: [],
        },
        {
          title: "Menu",
          icon: "menu",
          id: "FooterMenu",
          dynamic: true,
          section: ["Footer"],
          templates: [],
        },
        {
          title: "Page Headings",
          icon: "fa-solid fa-heading",
          id: "PageHeadings",
          dynamic: true,
          section: ["Content"],
          templates: ["Basic Page"],
        },
        {
          title: "Page Text Body",
          icon: "view_headline",
          id: "PageText",
          dynamic: true,
          section: ["Content"],
          templates: ["Basic Page"],
        },
        {
          title: "Avatar",
          icon: "account_circle",
          id: "Avatar",
          dynamic: true,
          section: ["Content"],
          templates: ["Profile"],
        },
        {
          title: "Profile Detail",
          icon: "person_search",
          id: "ProfileDetail",
          dynamic: true,
          section: ["Content"],
          templates: ["Profile"],
        },
        {
          title: "Progress Preview",
          icon: "emoji_events",
          id: "ProgressComponent",
          dynamic: true,
          section: ["Content"],
          templates: [
            "Articles",
            "Basic Page",
            "Home",
            "Claims",
            "Rewards",
            "Profile",
          ],
        },
        {
          title: "Awards",
          icon: "fa-solid fa-medal",
          id: "AwardsComponent",
          dynamic: true,
          section: ["Content"],
          templates: [
            "Articles",
            "Basic Page",
            "Home",
            "Claims",
            "Rewards",
            "Profile",
          ],
        },
        {
          title: "Rewards Preview",
          icon: "redeem",
          id: "RewardsPreviewComponent",
          dynamic: true,
          section: ["Content"],
          templates: ["Articles", "Home", "Claims", "Rewards"],
        },
        {
          title: "Rewards",
          icon: "redeem",
          id: "RewardsComponent",
          dynamic: true,
          section: ["Content"],
          templates: ["Rewards"],
        },
        {
          title: "Sign In Form",
          icon: "verified_user",
          id: "AuthSignIn",
          section: ["Auth"],
          templates: ["Signin"],
        },
        {
          title: "Sign Up Form",
          icon: "verified_user",
          id: "AuthSignUp",
          section: ["Auth"],
          templates: ["Signup"],
        },
        {
          title: "Text 1 Col",
          icon: "view_headline",
          id: "TextOneCol",
          section: ["Content", "Header", "Footer", "Auth"],
          templates: [
            "Articles",
            "Basic Page",
            "Home",
            "Claims",
            "Rewards",
            "Signin",
            "Signup",
            "Profile",
          ],
        },
        {
          title: "Text 2 Col",
          icon: "view_headline",
          id: "TextTwoCol",
          section: ["Content", "Header", "Footer", "Auth"],
          templates: [
            "Articles",
            "Basic Page",
            "Home",
            "Claims",
            "Rewards",
            "Signin",
            "Signup",
            "Profile",
          ],
        },
        {
          title: "Text 3 Col",
          icon: "view_headline",
          id: "TextThreeCol",
          section: ["Content", "Header", "Footer", "Auth"],
          templates: [
            "Articles",
            "Basic Page",
            "Home",
            "Claims",
            "Rewards",
            "Signin",
            "Signup",
            "Profile",
          ],
        },
      ],
    };
  },
  computed: {
    selectedPageTemplate: {
      get() {
        return this.$store.getters.selectedPageTemplate;
      },
      set(val) {
        this.$store.dispatch("setSelectedPageTemplate", val);
      },
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    editingComponent() {
      return this.$store.getters.editingComponent || {};
    },
    newComponent() {
      return this.$store.getters.newComponent;
    },
    isNew() {
      if (this.newComponent) {
        return true;
      }
      return false;
    },
    designDrawer() {
      return this.$store.getters.designDrawer;
    },
    openDesignDrawer() {
      return this.$store.getters.openDesignDrawer;
    },
    selectedComponents: {
      get() {
        switch (this.selectedPageTemplate) {
          case "Header":
            return this.webTheme.header.components;
          case "Articles":
            return this.webTheme.content.articles.components;
          case "Home":
            return this.webTheme.content.home.components;
          case "Claims":
            return this.webTheme.content.claims.components;
          case "Quizzes":
            return this.webTheme.content.quizzes.components;
          case "Basic Page":
            return this.webTheme.content.pages.components;
          case "Rewards":
            return this.webTheme.content.rewards.components;
          case "Profile":
            return this.webTheme.content.profile.components;
          case "Footer":
            return this.webTheme.footer.components;
          case "Colors":
            return this.webTheme.colors;
          case "Signin":
            return this.webTheme.auth.signin.components;
          case "Signup":
            return this.webTheme.auth.signup.components;
          default:
            return console.log(
              "There was an error fetching selected components"
            );
        }
      },
      set(val) {
        if (this.selectedPageTemplate === "Header") {
          this.webTheme.header.components = val;
        } else if (this.selectedPageTemplate === "Articles") {
          this.webTheme.content.articles.components = val;
        } else if (this.selectedPageTemplate === "Home") {
          this.webTheme.content.home.components = val;
        } else if (this.selectedPageTemplate === "Claims") {
          this.webTheme.content.claims.components = val;
        } else if (this.selectedPageTemplate === "Quizzes") {
          this.webTheme.content.quizzes.components = val;
        } else if (this.selectedPageTemplate === "Basic Page") {
          return (this.webTheme.content.pages.components = val);
        } else if (this.selectedPageTemplate === "Profile") {
          return (this.webTheme.content.profile.components = val);
        } else if (this.selectedPageTemplate === "Rewards") {
          this.webTheme.content.rewards.components = val;
        } else if (this.selectedPageTemplate === "Footer") {
          this.webTheme.footer.components = val;
        } else if (this.selectedPageTemplate === "Colors") {
          this.webTheme.colors = val;
        } else if (this.selectedPageTemplate === "Signin") {
          this.webTheme.auth.signin.components = val;
        } else if (this.selectedPageTemplate === "Signup") {
          this.webTheme.auth.signup.components = val;
        }
        this.$store.dispatch("editingComponent", {});
        this.$store.dispatch("setHasWebThemePendingUpdates", true);
      },
    },
    availableTemplates() {
      switch (this.designDrawer) {
        case "Header":
          return this.templates.header;
        case "Footer":
          return this.templates.footer;
        case "Colors":
          return this.templates.colors;
        case "Auth":
          return this.templates.auth;
        // Content below is returned by default if none of the cases above are met
        default:
          return this.templates.content;
      }
    },
    availableComponents() {
      switch (this.designDrawer) {
        case "Header":
          return this.components.filter((el) =>
            el.section.some((d) => this.designDrawer.includes(d))
          );
        case "Footer":
          return this.components.filter((el) =>
            el.section.some((d) => this.designDrawer.includes(d))
          );
        case "Colors":
          return this.components.filter((el) =>
            el.section.some((d) => this.designDrawer.includes(d))
          );
        case "Auth":
          return this.components
            .filter((el) =>
              el.section.some((d) => this.designDrawer.includes(d))
            )
            .filter((el) =>
              el.templates.some((t) => this.selectedPageTemplate.includes(t))
            );
        default:
          return this.components
            .filter((el) =>
              el.section.some((d) => this.designDrawer.includes(d))
            )
            .filter((el) =>
              el.templates.some((t) => this.selectedPageTemplate.includes(t))
            );
      }
    },
  },
  methods: {
    clearEditors() {
      this.$store.dispatch("editingField", {});
      this.$store.dispatch("editingComponent", {});
    },
    handleChange() {
      this.$store.dispatch("setHasWebThemePendingUpdates", true);
    },
    getComponent(id) {
      return this.components.find((item) => item.id === id);
    },
    addComponent(component) {
      // console.log(component)
      this.clearEditors();
      let defaults = {};
      const idx = nanoid();
      switch (component.id) {
        case "AwardsComponent":
          defaults = AWARDS_DEFAULTS;
          break;
        case "ProgressComponent":
          defaults = PROGRESS_DEFAULTS;
          break;
        case "ClaimsComponent":
          defaults = CLAIMS_DEFAULTS;
          break;
        case "QuizzesComponent":
          defaults = QUIZZES_DEFAULTS;
          break;
        case "ArticlesPreviewComponent":
          defaults = ARTICLES_PREVIEW_DEFAULTS;
          break;
        case "ArticlesComponent":
          defaults = ARTICLES_DEFAULTS;
          break;
        case "RewardsPreviewComponent":
          defaults = REWARDS_PREVIEW_DEFAULTS;
          break;
        case "RewardsComponent":
          defaults = REWARDS_DEFAULTS;
          break;
        case "MenuBar":
          defaults = MENUBAR_DEFAULTS;
          break;
        case "Banner":
          defaults = BANNER_DEFAULTS;
          break;
        case "HeadingSection":
          defaults = HEADING_DEFAULTS;
          break;
        case "PageHeadings":
          defaults = PAGE_DEFAULTS;
          break;
        case "PageText":
          defaults = PAGE_TEXT_DEFAULTS;
          break;
        case "Avatar":
          defaults = AVATAR_DEFAULTS;
          break;
        case "ProfileDetail":
          defaults = PROFILE_BODY_DEFAULTS;
          break;
        case "AuthSignIn":
          defaults = AUTH_SIGNIN_DEFAULTS;
          break;
        case "AuthSignUp":
          defaults = AUTH_SIGNUP_DEFAULTS;
          break;
        case "TextOneCol":
          defaults = TEXT_ONE_COL_DEFAULTS;
          break;
        case "TextTwoCol":
          defaults = TEXT_TWO_COL_DEFAULTS;
          break;
        case "TextThreeCol":
          defaults = TEXT_THREE_COL_DEFAULTS;
          break;
        case "FooterMenu":
          defaults = MENU_DEFAULTS;
          break;
        case "Colors":
          defaults = MENU_DEFAULTS;
          break;
        case "ImageComponent":
          defaults = IMAGE_DEFAULTS;
          break;
        default:
          console.log("There was an error adding a component");
      }
      this.selectedComponents.push({
        ...defaults,
        id: component.id,
        idx: idx,
        dynamic: component.dynamic ? component.dynamic : false,
      });
      this.$store.dispatch("setNewComponent", idx);
      this.$store.dispatch("setHasWebThemePendingUpdates", true);
      this.clearEditors();
    },
    deleteComponent(index) {
      this.selectedComponents.splice(index, 1);
      this.$store.dispatch("setHasWebThemePendingUpdates", true);
      this.$store.dispatch("setNewComponent", null);
      this.clearEditors();
    },
    handleEditor(component) {
      console.log(component);
      this.clearEditors();
      this.$store.dispatch("editingComponent", component);
    },
  },
};
</script>

<style>
.ghost {
  border: 2px dotted rgb(134, 134, 134);
  opacity: 0.5;
  background: #c8ebfb;
}
.pointer {
  cursor: pointer;
}
.all-scroll {
  cursor: all-scroll;
}
.editor-heading {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: #acacac !important;
}
.editor-subheading {
  font-weight: 300 !important;
  font-size: 13px !important;
  font-family: "Roboto", sans-serif !important;
  color: #e6e6e6 !important;
}
.editor-third-heading {
  font-weight: 300 !important;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif !important;
  color: #acacac !important;
}
.editor-label {
  font-weight: 300 !important;
  font-size: 13px !important;
  font-family: "Roboto", sans-serif !important;
  color: #e6e6e6 !important;
}
.setting-label {
  color: #e6e6e6 !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  margin-left: 2px !important;
  margin-top: 4px !important;
}
.size-label {
  color: #e6e6e6 !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  margin-left: 2px !important;
  margin-top: 4px !important;
}
.editor {
  background-color: #2b2b2b !important;
}
.font-select-label {
  color: #4e4e4e !important;
  letter-spacing: normal;
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  cursor: pointer !important;
}
.icon-button {
  color: #b4b4b4 !important;
}
.available-group {
  margin-top: -1px;
  margin-left: -1px;
}
.available-group .available-items {
  outline: 1px solid; /* use instead of border */
  color: #4e4e4e !important;
  margin-top: 1px;
  margin-left: 1px;
}
.bolt {
  position: absolute !important;
  z-index: 200;
}
</style>

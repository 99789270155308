<template>
  <div>
    <v-card class="el rounded-lg" height="220">
      <v-progress-linear
        indeterminate
        :active="loadingPointsRedeemed"
        :color="orgTheme.primary"
      >
      </v-progress-linear>

      <v-row
        justify="space-between"
        no-gutters
        class="mx-3 mt-3 mb-n2"
        style="height: 30px"
      >
        <v-col cols="auto">
          <v-icon class="mr-1 mt-n1" size="22" :color="chartLineColor">{{
            icon
          }}</v-icon>
          <span class="incentable-form-heading">
            {{ title }}
          </span>
        </v-col>
      </v-row>
      <chart-line
        v-if="values.length > 0"
        :chartData="chartData"
        :orgTheme="orgTheme"
        :styles="chartStyles"
        :chartFillColor="chartFillColor"
        :chartLineColor="chartLineColor"
      />
    </v-card>
  </div>
</template>

<script>
import ChartLine from "./ChartLine.vue";

export default {
  components: {
    ChartLine,
  },
  props: ["records", "icon", "title", "chartFillColor", "chartLineColor"],
  data: function () {
    return {
      chartStyles: {
        height: "195px",
        padding: "16px",
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.labels,
        data: this.values,
      };
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    loadingPointsRedeemed() {
      return this.$store.getters.loadingPointsRedeemed;
    },
    values() {
      if (this.records.length > 0) {
        return this.records.map((obj) => obj.points);
      }
      return 0;
    },
    labels() {
      if (this.records.length > 0) {
        return this.records.map((obj) => obj.monthYear);
      }
      return 0;
    },
    loadingMembers() {
      return this.$store.getters.loadingMembers;
    },
  },
  methods: {
    latest() {},
  },
};
</script>

<style></style>

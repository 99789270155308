import { store } from "../store";

export default (to, from, next) => {
  const { meta, params } = to;
  if (store.getters.user) {
    if (!meta.requireProgramId) {
      return next();
    }
    store.dispatch("checkPermission", params.id);
    if (store.getters.allowProgram === true && params.id !== undefined) {
      store
        .dispatch("loadCurrentProgram", params.id)
        .then(() => {
          next();
        })
        .catch((errorCode) => {
          next(`/${errorCode}`);
        });
    } else {
      next("/");
    }
  } else {
    next("/signin");
  }
};

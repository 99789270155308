<template>
  <div class="px-4">
    <v-row no-gutters>
      <v-col cols="5">
        <v-select
          :items="availableStatus"
          v-model="status"
          item-text="label"
          item-value="value"
          label="Status"
        />

        <v-text-field label="Title" type="text" name="title" v-model="title" />

        <v-radio-group
          :value="participantType"
          label="Participant Type"
          disabled
        >
          <v-radio label="Member" value="member" class="mt-3" />
          <v-radio label="Company" value="company" />
        </v-radio-group>

        <v-row no-gutters align="center">
          <v-col>
            <v-alert
              v-if="redeemableDisabled"
              type="warning"
              text
              border="left"
              color="primary"
              class="mb-n3"
            >
              Cannot have redeemable points with participant type is 'Company'
            </v-alert>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-col>
            <v-checkbox
              label="Points are redeemable"
              v-model="pointsRedeemable"
              :disabled="redeemableDisabled"
            />
          </v-col>
        </v-row>

        <v-checkbox
          label="Data Bucket has Targets"
          v-model="hasTargets"
          disabled
        />

        <v-text-field
          label="Created By"
          :value="getAuthorName(createdBy)"
          type="text"
          readonly
        />

        <v-text-field
          label="Created at"
          type="text"
          readonly
          :value="created | date"
        />

        <v-text-field
          :value="getAuthorName(updatedBy)"
          label="Updated By"
          type="text"
          readonly
        />

        <v-text-field
          :value="updated | date"
          label="Updated at"
          type="text"
          readonly
        />
        <v-row no-gutters class="mb-16" justify="end">
          <v-btn
            color="secondary"
            @click="openDeleteDialog = true"
            :loading="isProcessing"
            elevation="0"
            class="mb-16"
          >
            Delete Data Bucket
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="openDeleteDialog" max-width="400px" persistent>
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay absolute color="red" :opacity="systemTheme.cardOpacity">
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>

        <v-row no-gutters justify="center" v-if="hasEntries">
          <v-card-title class="page-heading"> Sorry, no can do </v-card-title>
        </v-row>
        <v-row v-else justify="center" no-gutters>
          <v-card-title class="page-heading"> Are You Sure? </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          <v-row no-gutters v-if="hasEntries">
            <v-col align="center">
              This Data Bucket cannot be deleted as it has been used. Please
              change the status to Archive instead
            </v-col>
          </v-row>
          <v-row no-gutters v-else>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ title }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="openDeleteDialog = false" text>Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            elevation="0"
            @click="handleDelete()"
            :loading="loading"
            :disabled="demo || hasEntries"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      availableStatus: [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" },
        { label: "Archive", value: "Archive" },
      ],
      openDeleteDialog: false,
      isProcessing: false,
      formTitle: "Small",
      formIcon: "warning",
    };
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    databucket() {
      return this.$store.state.databucket.databucket;
    },
    tables() {
      return this.$store.getters.tables;
    },
    leaderboards() {
      return this.$store.getters.leaderboards;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    hasEntries() {
      if (this.databucket.targetCodes.length > 0) {
        return true;
      }
      return false;
    },
    databucketAuthorMap() {
      return this.$store.getters.admins.reduce((result, item) => {
        return {
          ...result,
          [item.userId]: item.name,
        };
      }, {});
    },
    title: {
      get() {
        return this.databucket.title;
      },
      set(value) {
        this.$store.dispatch("patchDatabucket", {
          title: value,
          titleUppercase: value.toUpperCase(),
        });
      },
    },
    status: {
      get() {
        return this.databucket.status;
      },
      set(value) {
        this.$store.dispatch("patchDatabucket", { status: value });
      },
    },
    hasTargets: {
      get() {
        return this.databucket.hasTargets;
      },
      set(value) {
        this.$store.dispatch("patchDatabucket", { hasTargets: value });
      },
    },
    pointsRedeemable: {
      get() {
        return this.databucket.pointsRedeemable;
      },
      set(value) {
        this.$store.dispatch("patchDatabucket", { pointsRedeemable: value });
      },
    },
    displayTitle: {
      get() {
        return this.databucket.displayTitle;
      },
      set(value) {
        this.$store.dispatch("patchDatabucket", {
          displayTitle: value,
          displayTitleUppercase: value.toUpperCase(),
        });
      },
    },
    created() {
      return this.databucket.created;
    },
    createdBy() {
      return this.databucket.createdBy;
    },
    updatedBy() {
      return this.databucket.updatedBy;
    },
    updated() {
      return this.databucket.updated;
    },
    participantType() {
      return this.databucket.participantType;
    },
    redeemableDisabled() {
      return this.databucket.participantType !== "member";
    },
  },
  methods: {
    handleDelete() {
      const id = this.databucket.id;
      console.log(id);
      this.isProcessing = true;
      this.openDeleteDialog = false;
      this.$store.dispatch("deleteDatabucket", id).then(() => {
        this.isProcessing = false;
        this.$router.push(`/databuckets/${this.programId}`);
      });
    },
    getAuthorName(adminId) {
      return this.databucketAuthorMap[adminId];
    },
  },
};
</script>

<style scoped></style>

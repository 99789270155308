<template>
  <div>
    <v-app-bar
      class="page-bar"
      dense
      fixed
      color="grey lighten-4"
      elevate-on-scroll
    >
      <v-row no-gutters justify="center" class="mx-0">
        <v-col cols="12" lg="8">
          <span class="page-heading">Support Email Inbox</span>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-row no-gutters justify="center" class="mx-3 mt-14">
      <v-col cols="12" lg="8">
        <messages-table class="my-6"></messages-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MessagesTable from "./MessagesTable.vue";

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: { MessagesTable },
  data() {
    return {
      dictionary: {
        custom: {},
      },
    };
  },
  created() {},
  mounted() {
    this.$store.dispatch("clearCurrentProgram"),
      this.$store.dispatch("initialize"),
      this.$store.commit("setNavMenu", false),
      this.$store.dispatch("setToolbar", true),
      this.$store.dispatch("setFooter", true),
      this.$store.dispatch("loadMessages"),
      this.$validator.localize("en", this.dictionary);
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
  },
  methods: {},
};
</script>

<style scoped></style>

<template>
  <div>
    <v-container class="page" fluid>
      <v-tabs>
        <v-tab class="tab"> Email </v-tab>
        <v-tab class="tab"> App Notification </v-tab>
        <v-tab class="tab"> Sent </v-tab>
        <v-tab class="tab"> Email Settings </v-tab>

        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <new-email></new-email>
        </v-tab-item>
        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <new-notification></new-notification>
        </v-tab-item>
        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <communications-sent></communications-sent>
        </v-tab-item>
        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <banner-settings></banner-settings>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      active: "",
      pageTitle: "Communicate",
      pageSubTitle: "Send email campaigns to members",
    };
  },
  created() {
    this.$store.dispatch("setNavMenu", true);
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadMemberTags");
    this.$store.dispatch("loadNotifications");
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-sheet :class="{ 'on-hover': hover || settingsLock }" elevation="0">
        <!-- textfield -->
        <v-col v-if="field.type === 'textfield'">
          <v-text-field
            outlined
            dense
            :label="field.label"
            :clearable="field.clearable"
            background-color="white"
            class="mb-n6"
            :placeholder="field.placeholder"
          ></v-text-field>
        </v-col>

        <!-- textarea -->
        <v-col v-if="field.type === 'textarea'">
          <v-textarea
            outlined
            dense
            :label="field.label"
            :clearable="field.clearable"
            background-color="white"
            class="mb-n6"
            :placeholder="field.placeholder"
          ></v-textarea>
        </v-col>

        <!-- dropdown -->
        <v-col v-if="field.type === 'dropdown'">
          <v-autocomplete
            :items="validationItems"
            outlined
            dense
            background-color="white"
            item-text="title"
            item-value="identifier"
            :label="field.label"
            :clearable="field.clearable"
            class="mb-n6"
            :placeholder="field.placeholder"
          >
            <template v-slot:item="data">
              <v-row
                v-if="field.selectionDataType === 'dynamic'"
                no-gutters
                class="mb-2 mt-2"
              >
                <v-col>
                  <v-row no-gutters>
                    <span class="form-option-identifier">{{
                      data.item.identifier
                    }}</span>
                  </v-row>
                  <v-row no-gutters>
                    <span class="form-option-value">{{ data.item.title }}</span>
                  </v-row>
                  <v-row no-gutters>
                    <span class="form-option-description">{{
                      data.item.description
                    }}</span>
                  </v-row>
                </v-col>
              </v-row>
              <div v-else>
                {{ data.item.value }}
              </div>
            </template>
          </v-autocomplete>
        </v-col>

        <!-- radio -->
        <v-col v-if="field.type === 'radio'">
          <v-radio-group class="radio-group-margins" :label="field.label">
            <v-radio
              v-for="(formOption, key) in formOptionsList"
              :key="key"
              :label="formOption.value"
              :value="key"
            >
              <template v-slot:label>
                <v-row no-gutters>
                  <v-col>
                    <v-row v-if="formOption.identifier" no-gutters>
                      <span class="form-option-identifier">{{
                        formOption.identifier
                      }}</span>
                    </v-row>
                    <v-row v-if="formOption.value" no-gutters>
                      <span class="form-option-value">{{
                        formOption.value
                      }}</span>
                    </v-row>
                    <v-row v-if="formOption.description" no-gutters>
                      <span class="form-option-description">{{
                        formOption.description
                      }}</span>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>

        <!-- checkboxes -->
        <v-col v-if="field.type === 'checkboxes'">
          <div class="checkbox-group-margins">
            <v-row class="mt-n3 mb-n2 custom-label" no-gutters>
              {{ field.label }}
            </v-row>
            <v-checkbox
              v-for="(formOption, key) in formOptionsList"
              :key="key"
              :label="formOption.value"
              :value="key"
              class="checkbox-margins"
            >
              <template v-slot:label>
                <v-row no-gutters>
                  <v-col>
                    <v-row v-if="formOption.identifier" no-gutters>
                      <span class="form-option-identifier">{{
                        formOption.identifier
                      }}</span>
                    </v-row>
                    <v-row v-if="formOption.value" no-gutters>
                      <span class="form-option-value">{{
                        formOption.value
                      }}</span>
                    </v-row>
                    <v-row v-if="formOption.description" no-gutters>
                      <span class="form-option-description">{{
                        formOption.description
                      }}</span>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-checkbox>
          </div>
        </v-col>

        <!-- table -->
        <v-col v-if="field.type === 'table'">
          <table-preview :field="field"></table-preview>
        </v-col>

        <!-- date -->
        <v-col v-if="field.type === 'date-picker'">
          <v-menu
            v-model="menu"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="field.label"
                prepend-inner-icon="calendar_month"
                v-bind="attrs"
                v-on="on"
                background-color="white"
                outlined
                dense
                :clearable="field.clearable"
                class="mb-n6"
                :placeholder="field.placeholder"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date"></v-date-picker>
          </v-menu>
        </v-col>

        <!-- time -->
        <v-col v-if="field.type === 'time-picker'">
          <v-menu
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
            v-model="menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                :label="field.label"
                prepend-inner-icon="calendar_month"
                v-bind="attrs"
                v-on="on"
                background-color="white"
                outlined
                dense
                :clearable="field.clearable"
                class="mb-n6"
                :placeholder="field.placeholder"
              ></v-text-field>
            </template>
            <v-time-picker v-model="time"></v-time-picker>
          </v-menu>
        </v-col>

        <!-- switch -->
        <v-col v-if="field.type === 'switch'">
          <v-switch
            outlined
            dense
            :label="field.label"
            :clearable="field.clearable"
            background-color="white"
            class="mb-n2 mt-1"
          ></v-switch>
        </v-col>

        <!-- file -->
        <v-col v-if="field.type === 'file-input'">
          <v-file-input
            outlined
            dense
            :label="field.label"
            :clearable="field.clearable"
            background-color="white"
            class="mb-n6"
          ></v-file-input>
        </v-col>

        <!-- slider -->
        <v-col v-if="field.type === 'slider'">
          <v-slider
            outlined
            dense
            :label="field.label"
            :thumb-label="field.thumbLabel"
            :min="field.min"
            :max="field.max"
            :clearable="field.clearable"
            class="mb-n3 mt-1"
          ></v-slider>
        </v-col>

        <!-- title -->
        <v-col v-if="field.type === 'title'" class="ma-0 pa-0">
          <view-text :settings="field.styles"></view-text>
        </v-col>

        <!-- subtitle -->
        <v-col v-if="field.type === 'subtitle'" class="ma-0 pa-0">
          <view-text :settings="field.styles"></view-text>
        </v-col>

        <!-- note -->
        <v-col v-if="field.type === 'note'" class="ma-0 pa-0">
          <view-text :settings="field.styles"></view-text>
        </v-col>

        <v-fade-transition>
          <v-sheet v-if="hover || settingsLock" class="hover-actions">
            <v-icon class="action-icon">drag_indicator</v-icon>

            <FieldSettings
              @onSettingsEdit="handleSettingsLock"
              @onUpdateSettings="handleUpdateSettings"
              :field="field"
            ></FieldSettings>

            <v-icon class="action-icon" @click="handleDelete(field)"
              >delete_forever</v-icon
            >
          </v-sheet>
        </v-fade-transition>
      </v-sheet>
    </v-hover>
  </div>
</template>

<script>
import TablePreview from "./TablePreview.vue";
import FieldSettings from "./FieldSettings.vue";
import ViewText from "../../../../Design/View/ViewComponents/Shared/Text.vue";
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    pageIdx: {
      type: Number,
      required: true,
    },
    sectionIdx: {
      type: Number,
      required: true,
    },
    columnIdx: {
      type: Number,
      required: true,
    },
    fieldIndex: {
      type: Number,
      required: true,
    },
  },
  components: { TablePreview, FieldSettings, ViewText },
  data: () => ({
    menu: false,
    date: null,
    time: null,
    settingsLock: false,
    validationItems: [], // New data property
  }),
  watch: {
    "field.selectionDataType": "fetchValidationData",
    "field.selectedValidationDataSet": "fetchValidationData",
  },
  mounted() {
    this.fetchValidationData();
  },
  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    formOptionsList() {
      if (this.field.selectionDataType === "static") {
        return this.field.formOptions;
      } else {
        const data = this.validationItems || [];
        const formatted = data.map((el) => {
          return {
            ...el,
            value: el.title,
            key: el.id,
          };
        });
        return formatted;
      }
    },
  },
  methods: {
    handleSettingsLock(val) {
      this.settingsLock = val;
    },
    handleUpdateSettings(update) {
      const items =
        this.currentOffer.form.pages[this.pageIdx].sections[this.sectionIdx]
          .columns[this.columnIdx].items;

      const updatedItems = [...items];
      updatedItems[this.fieldIndex] = {
        ...updatedItems[this.fieldIndex],
        ...update,
        ...(update.formOptions && { formOptions: [...update.formOptions] }),
      };
      this.currentOffer.form.pages[this.pageIdx].sections[
        this.sectionIdx
      ].columns[this.columnIdx].items = updatedItems;

      this.patchCurrentOffer();
      this.settingsLock = false;
    },
    handleDelete() {
      this.currentOffer.form.pages[this.pageIdx].sections[
        this.sectionIdx
      ].columns[this.columnIdx].items.splice(this.fieldIndex, 1);
      this.patchCurrentOffer();
    },
    patchCurrentOffer() {
      const update = {
        form: this.currentOffer.form,
      };
      this.$store.dispatch("patchCurrentOffer", update);
    },
    async fetchValidationData() {
      if (this.field.selectionDataType === "static") {
        const data = this.field.staticValidationOptions;
        this.validationItems = data || [];
        return;
      }
      try {
        const data = await this.$store.dispatch(
          "getValidationRecords",
          this.field.selectedValidationDataSet
        );
        this.validationItems = data || [];
      } catch (error) {
        this.validationItems = [];
      }
    },
  },
};
</script>

<style scoped>
.ghost {
  border: 2px dotted rgb(134, 134, 134);
}
.available-items {
  cursor: pointer;
}
.icon {
  font-size: 2rem;
}
.type-subheading {
  font-size: 0.8rem;
}
.checkbox-margins {
  margin-bottom: -30px !important;
  margin-top: 12px;
}
.checkbox-group-margins {
  margin-bottom: 4px !important;
  margin-top: 5px;
  padding: 8px;
}
.custom-label {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.radio-group-margins {
  margin-bottom: -24px;
  margin-top: -8px;
  padding: 8px;
}
.v-sheet {
  cursor: all-scroll;
  border: 2px solid #e91e63 !important;
  position: relative; /* Ensure it remains in flow for absolute children */
}
.v-sheet:not(.on-hover) {
  border: 2px solid transparent !important;
}
.hover-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  background: #e91e63 !important;
}
.action-sheet {
  outline: 1px solid #e91e63 !important;
}
.action-icon {
  color: #ffffff !important;
  font-size: 20px !important;
  margin: 3px;
}
.form-option-identifier {
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #929292 !important;
  line-height: 1;
}
.form-option-value {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000000de !important;
  line-height: 1.3;
}
.form-option-description {
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #929292 !important;
  line-height: 1;
}
</style>

/* eslint-disable no-console */
<template>
  <div class="mt-6 mb-6 mr-6">
    <v-row no-gutters>
      <draggable
        v-model="innerItems"
        ghost-class="ghost"
        class="d-flex"
      >
        <v-card
          v-for="(item, index) of innerItems" :key="index"
          class="sort-item mr-2 ml-2 el"
          height="140px"
          width="140px"
        >
          <v-row no-gutters>
            <div class="ml-2 mt-2">
              <status-icon :status="item.status"></status-icon>
            </div>
            <v-spacer></v-spacer>
              <v-menu
                bottom
                left
                close-on-content-click
              >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link>
                      <v-list-item-title @click="handleEdit(item)">
                        Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title @click="handleDeleteConfirmation(item)">
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
              </v-menu>
          </v-row>
          <v-row no-gutters align="center" justify="center" class="mt-1">
            {{ item.title }}
          </v-row>
          <v-row no-gutters align="center" justify="center" class="incentable-page-subheading grey--text">
            {{ item.contentType }}
          </v-row>
        </v-card>
      </draggable>
      <v-card
        class="el"
        height="140px"
        width="140px"
      >
        <v-row justify="center" align="center" style="height: 140px" no-gutters>
          <v-btn
            small
            color="primary"
            elevation="0"
            fab
            @click="handleNew()"
          ><v-icon :color="orgTheme.buttonIconColor">add</v-icon>
          </v-btn>
        </v-row>
      </v-card>
    </v-row>

    <v-row no-gutters class="mt-4">
      <v-btn
        class="ml-0"
        color="primary"
        @click="handleSaveSort()"
        elevation="0"
        :disabled="demo"
      >Save Order
      </v-btn>
    </v-row>
    
    <v-row no-gutters class="mt-2 incentable-page-subheading">
      Secondary menu can have unlimited menu items. Drag items to reorder then save
    </v-row>

    <v-row no-gutters class="mt-6 page-heading">
      Preview
    </v-row>
    
    <v-row no-gutters class="mt-4">
      <v-col sm="4" xl="3">
        <v-card
          class="el px-8 py-4"
          tile
          color="secondary"
        >
          <v-row
            v-for="(item, index) of innerItems"
            :key="index"
            no-gutters
            class="white--text py-3"
          >
            {{item.title}}
          </v-row>                  
        </v-card>
      </v-col>
    </v-row>

    <secondarymenu-add-or-edit
      :menuId="selectedMenuId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <v-dialog 
      v-model="dialogDeleteOpen"
      max-width="400px"
      persistent
      >
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row dense>
            <v-col align="center">
              You are about to delete <span class="incentable-form-bold">{{ title }}</span>
            </v-col>
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleCloseDeleteDialog()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            :disabled="demo"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
  export default {
    $_veeValidate: {
      validator: 'new'
    },
    data: () => ({
      
      // Component settings
      tableTitle: 'Secondary Menu',
      tableSubtitle: 'Create first level menu items and attach a page or blog',
      tableIcon: 'settings_cell',
      search: '',
      title: '',
      selectedMenuId: '',
      dialogNewOrEditOpen: false,
      dialogDeleteOpen: false,
      dialogSort: false,
      isSecondaryMenu: true,
      innerItems: [],
      
      // Table settings
      options: {
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: false,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
      
      // Table columns
      headers: [
        { text: 'Status', value: 'status', align: 'left', sortable: false },
        { text: 'Title', value: 'title', align: 'left', sortable: false },
        { text: 'Icon', value: 'icon', align: 'left', sortable: false },
        { text: 'Highlight (App only)', value: 'highlight', align: 'center', sortable: false },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],
    }),

    created () {
      this.$store.dispatch('loadSecondaryMenus')
    },

    computed: {
      demo () {
        return this.$store.state.program.currentProgram.demo
      },
      menus () {
        return this.$store.state.secondarymenu.secondaryMenus;
      },
      loadingTable () {
      return this.$store.getters.loadingTable
      },
      loading () {
      return this.$store.getters.loading
      },
      orgTheme () {
        return this.$store.getters.orgTheme
      },
      systemTheme () {
        return this.$store.getters.systemTheme
      },
      snackbar () {
        return this.$store.getters.snackbar
      },
    },

    watch: {
      menus: {
        handler(newVal) {
          this.innerItems = newVal;
        },
      },
    },
    methods: {
      getStatusIcon (item) {
        if (item === 'Active') {
          return 'check_circle'
        } else if (item === 'Inactive') {
          return 'not_interested'
        }
      },
      handleNew() {
        // Open New form
        this.selectedMenuId = '';
        this.dialogNewOrEditOpen = true;
      },
      handleEdit(item) {
        // Open Edit form
        this.selectedMenuId = item.id;
        this.dialogNewOrEditOpen = true;
      },
      handleCloseNewOrEditDialog() {
        // Close New Or Edit form
        this.selectedMenuId = '';
        this.dialogNewOrEditOpen = false;
      },
      handleDeleteConfirmation (item) {
        // Delete record
        this.selectedMenuId = item.id;
        this.title = item.title;
        this.dialogDeleteOpen = true;
      },
      handleCloseDeleteDialog() {
        // Close delete form
        this.selectedMenuId = '';
        this.dialogDeleteOpen = false;
      },
      handleDelete() {
        // Delete Menu
        this.$store.dispatch('deleteSecondaryMenu', this.selectedMenuId)
          .then(() => this.dialogDeleteOpen = false)
          .catch(() => this.dialogDeleteOpen = false)
      },
      openDialogSort() {
        this.dialogSort = true;
      },
      closeSortDialog() {
        this.dialogSort = false;
      },
      handleSaveSort() {
        this.$store.dispatch('sortSecondaryMenus', this.innerItems);
      },
    }
  }
</script>

<style>
.ghost {
  border: 1px dotted #ccc;
}

.sort-item {
  cursor: move;
}

</style>

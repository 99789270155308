export const PROGRESS_DEFAULTS = {
  title: {
    align: 'start',
    boxHeight: 40,
    color: '#E91E63FF',
    cols: 12,
    field: 'title',
    floatText: 'text-left',
    font: 'Lato, sans-serif',
    justify: 'start',
    lineHeight: 1.1,
    marginLeft: 50,
    marginRight: 0,
    size: 22,
    style: 'normal',
    value: 'Progress',
    weight: 'normal',
  },
  prefix: {
    align: 'start',
    color: '#000000FF',
    field: 'prefix',
    floatText: 'text-left',
    font: 'Quicksand, sans-serif',
    justify: 'start',
    size: 22,
    boxHeight: 10,
    lineHeight: 1.2,
    marginLeft: 0,
    marginRight: 0,
    cols: 12,
    style: 'normal',
    value: 'prefix',
    weight: 'normal',
  },
  result: {
    align: 'end',
    boxHeight: 112,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'result',
    floatText: 'text-left',
    font: 'Quicksand, sans-serif',
    justify: 'start',
    lineHeight: 1,
    marginLeft: 0,
    marginRight: 0,
    size: 43,
    style: 'normal',
    value: 'result',
    weight: 'normal',
  },
  suffix: {
    align: 'start',
    color: '#000000FF',
    field: 'suffix',
    floatText: 'text-left',
    font: 'Quicksand, sans-serif',
    justify: 'start',
    size: 22,
    boxHeight: 10,
    lineHeight: 1.2,
    marginLeft: 0,
    marginRight: 0,
    cols: 12,
    style: 'normal',
    value: 'suffix',
    weight: 'normal',
  },
  displayTitle: {
    align: 'start',
    boxHeight: 10,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'displayTitle',
    floatText: 'text-left',
    font: 'Quicksand, sans-serif',
    justify: 'start',
    lineHeight: 1.2,
    marginLeft: 0,
    marginRight: 0,
    size: 19,
    style: 'normal',
    value: 'displayTitle',
    weight: 'normal',
  },
  card: {
    icon: {
      marginLeft: 133,
      marginTop: 0,
      size: 105,
      opacity: 55
    },
    width: 250,
    height: 150,
    borderSize: 0,
    borderColor: { 
      alpha: 1, 
      hex: '#E91E63' ,
      hexa: '#E91E63FF',
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233
      } 
    },
    elevation: 6,
    rounded: 'rounded-lg',
    color: {
      type: 'gradient',
      solidColor: { 
        alpha: 1, 
        hex: '#E91E63' ,
        hexa: '#E91E63FF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233
        } 
      },
      gradientFrom: { 
        alpha: 1, 
        hex: '#E91E63' ,
        hexa: '#E91E63FF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233
        } 
      },
      gradientTo: { 
        alpha: 1, 
        hex: '#94143F' ,
        hexa: '#94143FFF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148
        } 
      },
      direction: 'to bottom',
      gradientHeight: 100
    },
    padding: {
      top: 0,
      bottom: 0,
      left: 12,
      right: 0,
    },
    margin: {
      top: 0,
      bottom: 15,
      left: 0,
      right: 20,
    },
    group: {
      cols: 12,
      slider: 'slide',
      dark: true,
      justify: 'start'
    }
  },
  settings: {
    cols: 11
  },
  block: {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  dialog: {
    content: {
      align: 'center',
      heading: {
        color: '#771033FF',
        font: 'Roboto, sans-serif',
        lineHeight: 1,
        size: 13,
        style: 'normal',
        weight: 'bold',
      },
      data: {
        color: '#FFFFFFFF',
        font: 'Work Sans, sans-serif',
        lineHeight: 1,
        size: 15,
        style: 'normal',
        weight: 'normal',
      },
    },
    iconSize: 40,
    avatarSize: 50,
    rowBorderSize: 1,
    rowHoverColor: { 
      alpha: 0.5, 
      hex: '#EC407A' ,
      hexa: '#EC407A80',
      hsla: {
        a: 0.5,
        h: 339.7674418604651,
        l: 0.5882352941176471,
        s: 0.8190476190476191,
      },
      hsva: {
        a: 0.5,
        h: 339.7674418604651,
        s: 0.728813559322034,
        v: 0.9254901960784314,
        hue: 339.7674418604651,
      },
      rgba: {
        a: 0.5,
        b: 122,
        g: 64,
        r: 236
      } 
    },
    rowBorderColor: { 
      alpha: 0.5, 
      hex: '#EC407A' ,
      hexa: '#EC407A80',
      hsla: {
        a: 0.5,
        h: 339.7674418604651,
        l: 0.5882352941176471,
        s: 0.8190476190476191,
      },
      hsva: {
        a: 0.5,
        h: 339.7674418604651,
        s: 0.728813559322034,
        v: 0.9254901960784314,
        hue: 339.7674418604651,
      },
      rgba: {
        a: 0.5,
        b: 122,
        g: 64,
        r: 236
      } 
    },
    borderSize: 0,
    borderColor: { 
      alpha: 1, 
      hex: '#E91E63' ,
      hexa: '#E91E63FF',
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233
      } 
    },
    color: {
      type: 'gradient',
      solidColor: { 
        alpha: 1, 
        hex: '#E91E63' ,
        hexa: '#E91E63FF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233
        } 
      },
      gradientFrom: { 
        alpha: 1, 
        hex: '#E91E63' ,
        hexa: '#E91E63FF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233
        } 
      },
      gradientTo: { 
        alpha: 1, 
        hex: '#94143F' ,
        hexa: '#94143FFF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148
        } 
      },
      direction: 'to bottom',
      gradientHeight: 100
    },
    width: 750,
    fullscreen: false,
    icon: {
      align: 'end',
      boxHeight: 110,
      show: true,
      justify: 'center',
      placement: 'top',
      size: 90
    },
    title: {
      align: 'center',
      boxHeight: 120,
      color: '#FFFFFFFF',
      cols: 12,
      field: 'dialogTitle',
      floatText: 'text-center',
      font: 'Quicksand, sans-serif',
      justify: 'start',
      lineHeight: 1.5,
      marginLeft: 0,
      marginRight: 0,
      size: 40,
      style: 'normal',
      value: 'Pop Up Heading',
      weight: 'normal',
    },
    padding: {
      top: 20,
      bottom: 20,
      right: 20,
      left: 20,
    },
    summary: {
      margin: {
        top: 0,
        bottom: 20,
        right: 30,
        left: 30,
      },
    },
    detail: {
      margin: {
        top: 0,
        bottom: 20,
        right: 30,
        left: 30,
      },
    }
  }
};

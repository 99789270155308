<template>
  <v-dialog v-model="open" max-width="1000px" persistent>
    <v-card>
      <form data-vv-scope="reward">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="primary"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            {{ rewardId ? 'Edit ' : 'Add New '}}
            {{ formTitle }}
          </v-card-title>
        </v-row>
        <v-card-text class="px-16">
          <v-row dense>
            <v-col lg="9" class="pr-16">
              <v-text-field
                name="title"
                v-model="title"
                label="Reward Title"
                v-validate="'required'"
                :error-messages="errors.collect('reward.title')"
              ></v-text-field>
              
              <v-text-field
                name="sku"
                v-model="sku"
                label="SKU / Product Code"
              ></v-text-field>
              
              <v-textarea
                v-model="body"
                label="Description"
                name="body"
                type="text"
                outlined
                class="mt-3"
              ></v-textarea>

              <v-row no-gutters class="incentable-form-heading">
                Reward Image
              </v-row>

     
              <media-upload
                class="mt-3 mb-0"
                :id="rewardId"
                :currentImageOnFile="image"
                :externalImageUrl="externalImageUrl"
                :showMetaData="true"
                @onImageSaved="handleImageSaved"
                @onImageProcessing="handleImageProcessing"
                :radius="radius"
              />
      

              <v-row no-gutters>
                <v-checkbox
                  v-model="radius"
                  label="Radius Image Corners (for the perfect gift card look)"
                ></v-checkbox>
              </v-row>

              <v-row no-gutters class="incentable-form-heading" align="center">
                Reward Image External  
                <v-chip v-if="externalImageUrl" small color="green" class="ml-2 white--text">Active</v-chip>
                <v-chip v-else small color="grey lighten-2 ml-2">Inactive</v-chip>
              </v-row>
              <v-row no-gutters class="page-subheading">
                Enter the URL of the image that is hosted outside of the Incentable platform. This will overide any uploaded image. This value is optional.
              </v-row>
              <v-textarea
                label="Image URL"
                v-model="externalImageUrl"
                filled
                clearable
                :disabled="loading"
              ></v-textarea>
            </v-col>

            <v-col lg="3">
              
              <v-select
                name="status"
                label="Status"
                v-validate="'required'"
                :error-messages="errors.collect('reward.status')"
                v-model="selectedStatus"
                :items="statuses"
                item-text="text"
                item-value="value"
                append-icon="power_settings_new"
              >
              </v-select>

              <v-text-field
                name="points"
                label="Points"
                v-model="points"
                append-icon="star"
                type="number"
                @wheel="$event.target.blur()"
                v-validate="'required'"
                :error-messages="errors.collect('reward.points')"
              ></v-text-field>
              
              <v-select
                name="rewardCatalogue"
                :items="rewardCatalogues"
                label="Reward Catalogue"
                v-validate="'required'"
                :error-messages="errors.collect('reward.rewardCatalogue')"
                v-model="selectedRewardCatalogue"
                item-text="title"
                item-value="id"
              ></v-select>
              
              <v-select
                name="rewardCategories"
                v-model="selectedRewardCategories"
                :items="rewardCategories"
                item-text="title"
                item-value="id"
                label="Category"
                v-validate="'required'"
                :error-messages="errors.collect('reward.rewardCategories')"
                chips
                multiple
                small-chips
                deletable-chips
                append-icon="category"
              ></v-select>
              
              <v-select
                name="rewardSupplier"
                :items="rewardSuppliers"
                label="Reward Supplier"
                v-model="selectedRewardSupplier"
                item-text="title"
                item-value="id"
              ></v-select>

              <v-text-field
                name="price"
                label="Price"
                v-model="price"
                type="number"
                prefix="$"
                @wheel="$event.target.blur()"
              ></v-text-field>

              <v-row no-gutters class="incentable-form-heading">
                <v-col>
                  Content Filters
                </v-col> 
              </v-row>
              <v-row no-gutters class="incentable-form-subheading">
                <v-col>
                  Filter this reward to specific members
                </v-col>
              </v-row>

              <v-select
                v-model="selectedCompanyTags"
                :items="companyTags"
                item-text="tag"
                item-value="id"
                label="Company Tags"
                chips
                multiple
                small-chips
                deletable-chips
                append-icon="local_offer"
              >
              </v-select>

              <v-select
                v-model="selectedMemberTags"
                :items="memberTags"
                item-text="tag"
                item-value="id"
                label="Member Tags"
                chips
                multiple
                small-chips
                deletable-chips
                append-icon="local_offer"
              >
              </v-select>

              <div v-if="rewardId">
                <v-row no-gutters class="incentable-form-heading">
                  <v-col>
                    More
                  </v-col> 
                </v-row>
                <v-row no-gutters class="incentable-form-subheading">
                  <v-col>
                    Additional information
                  </v-col>
                </v-row>

                <v-text-field
                  :value="created | date"
                  label="Created"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  :value="getAuthorName(createdBy)"
                  label="Created By"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  :value="updated | date"
                  label="Updated"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  :value="getAuthorName(updatedBy)"
                  label="Updated By"
                  readonly
                >
                </v-text-field>

              </div>

            </v-col>    
          </v-row>

          <v-row dense>
            <v-col>
              <v-alert
                v-model="showAlert"
                color="primary"
                border="left"
                text
                >
                That Reward name is already registered
              </v-alert>
            </v-col>         
          </v-row>

        </v-card-text>
        <v-card-actions class="pb-4 pr-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            :disabled="loading"
          >
            {{ rewardId ? 'Close' : 'Cancel' }}
          </v-btn>
          <v-btn
            @click="saveReward('reward', true)"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            elevation="0"
            :disabled="loading || demo"
          >
            {{ rewardId ? 'Save Reward' : 'Create Reward' }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import MediaUpload from '../Shared/MediaUpload.vue'
export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    MediaUpload
  },
  props: {
    rewardId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => 'Title is required'
        },
        rewardCatalogue: {
          required: () => 'Catalogue is required'
        },
        status: {
          required: () => 'Status is required'
        },
        rewardCategories: {
          required: () => 'Category is required'
        },
        points: {
          required: () => 'Points are required'
        }
      }
    },
    statuses: [
      { text: 'Active', value: 'Active' },
      { text: 'Inactive', value: 'Inactive' }
    ],
    formTitle: 'Reward',
    formIcon: 'insert_drive_file',
    showAlert: false,
    selectedStatus: 'Active',
    selectedRewardCategories: [],
    selectedRewardCatalogue: '',
    selectedRewardSupplier: '',
    selectedCompanyTags: [],
    selectedMemberTags: [],
    rewardSupplier: '',
    title: '',
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
    body: '',
    points: '',
    price: '',
    sku: '',
    radius: false,
    externalImageUrl: '',
    image: {
      name: '',
      updated: '',
      smallUrl: '',
      mediumUrl: '',
      largeUrl: '',
      smallStoragePath: '',
      mediumStoragePath: '',
      largeStoragePath: '',
    },
  }),
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    programId () {
      return this.$store.getters.programId;
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    rewards () {
      return this.$store.state.reward.rewards
    },
    rewardCatalogues () {
      return this.$store.state.rewardcatalogue.rewardCatalogues
    },
    rewardSuppliers () {
      return this.$store.state.rewardsupplier.rewardSuppliers.filter(el => el.status === 'Active')
    },
    rewardCategories () {
      return this.$store.state.rewardcategory.rewardCategories
    },
    companyTags () {
      return this.$store.state.companytag.companyTags
    },
    memberTags () {
      return this.$store.state.membertag.memberTags
    },
    rewardCatalogueAuthorMap() {
      return this.$store.getters.admins.reduce((result, item) => {
        return {
          ...result,
          [item.userId]: item.name,
        };
      }, {});
    },
  },
  methods:{
    handleImageSaved(image) {  // new uploader
      this.image = {
        updated: image.updated,
        name: image.name || '',
        smallUrl: image.smallUrl || '',
        mediumUrl: image.mediumUrl || '',
        largeUrl: image.largeUrl || '',
        smallStoragePath: image.smallStoragePath || '',
        mediumStoragePath: image.mediumStoragePath || '',
        largeStoragePath: image.largeStoragePath || '',
      },
      this.saveReward('reward', false)
    },
    handleImageProcessing(val) {  // new uploader
      this.$store.dispatch('setLoading', val)
    },
    clear () {
      this.title = ''
      this.sku = ''
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.body = ''
      this.points = ''
      this.price = ''
      this.radius = false
      this.image = {   // new uploader
        name: '',
        updated: '',
        smallUrl: '',
        mediumUrl: '',
        largeUrl: '',
        smallStoragePath: '',
        mediumStoragePath: '',
        largeStoragePath: '',
      },
      this.externalImageUrl= ''
      this.selectedRewardCatalogue = ''
      this.selectedRewardSupplier = ''
      this.selectedStatus = 'Active'
      this.selectedRewardCategories = []
      this.selectedCompanyTags = []
      this.selectedMemberTags = []
      this.showAlert = false;
      this.$validator.reset();
      this.$store.dispatch('setLoading', false);
    },
    handleClose () {
      this.$emit('onClose');
      this.clear();
    },
    getAuthorName(adminId) {
      return this.rewardCatalogueAuthorMap[adminId];
    },
    saveReward (scope, close) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }

        this.$store.dispatch('setLoading', true)

        const payload = {
          // Add database fields here
          status: this.selectedStatus,
          title: this.title,
          titleUppercase: this.title.toUpperCase(),
          body: this.body,
          points: this.points,
          price: this.price,
          sku: this.sku,
          externalImageUrl: this.externalImageUrl || '',
          rewardCatalogue: this.selectedRewardCatalogue,
          rewardSupplier: this.selectedRewardSupplier || '',
          rewardCategories: this.selectedRewardCategories,
          companyTags: this.selectedCompanyTags,
          memberTags: this.selectedMemberTags,
          radius: this.radius,
          image: this.image || {
            name: '',
            updated: '',
            smallUrl: '',
            mediumUrl: '',
            largeUrl: '',
            smallStoragePath: '',
            mediumStoragePath: '',
            largeStoragePath: '',
          },
        };
        if (this.rewardId) {
          // Update Reward
          payload.id = this.rewardId;
          this.$store.dispatch('updateReward', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false)
              if (createResult === 'ok') {
                if (close) {
                  this.handleClose()
                  return
                }
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create Reward
          this.$store.dispatch('createReward', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                if (close) {
                  this.handleClose()
                  return
                }
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false)
            })
        }
      });
    },
  },
  watch: {
    rewardId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedReward = this.rewards.find(item => item.id === newVal);
          const {
            status, rewardCategories, rewardCatalogue, companyTags, memberTags, title, points, price, created, 
            updated, body, createdBy, updatedBy, sku, image, rewardSupplier, externalImageUrl, radius
          } = selectedReward;
          this.selectedStatus = status;
          this.selectedRewardCategories = rewardCategories;
          this.selectedRewardCatalogue = rewardCatalogue;
          this.selectedRewardSupplier = rewardSupplier;
          this.selectedCompanyTags = companyTags;
          this.selectedMemberTags = memberTags;
          this.title = title;
          this.sku = sku;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
          this.body = body;
          this.points = points;
          this.price = price;
          this.radius = radius;
          this.externalImageUrl = externalImageUrl
          this.image = image || {
            name: '',
            updated: '',
            smallUrl: '',
            mediumUrl: '',
            largeUrl: '',
            smallStoragePath: '',
            mediumStoragePath: '',
            largeStoragePath: '',
          }
        }
      },
    },
  },
}
</script>


// import { detect } from "detect-browser";


export default (to, from, next) => {
    // const browser = detect()
    // if (browser.name === 'ie') {
    //     alert(
    //         'Our system has detected you are using an older web browser. You browser is not supported. Please update to IE 11 or install the Google Chrome web browser. Alternatively, try clicking the activate now link from you smartphone as most modern smartphones have compatible browsers.'
    //     )
    // }
    next()
}

export const MENUBAR_DEFAULTS = {
  settings: {
    cols: 12,
    overlap: true,
    previewMobile: false,
    color: {
      alpha: 0,
      hex: "#E91E63",
      hexa: "#E91E6300",
      hsla: {
        h: 339.60591133004925,
        s: 0.8218623481781376,
        l: 0.5156862745098039,
        a: 0,
      },
      hsva: {
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        a: 0,
      },
      hue: 339.60591133004925,
      rgba: { r: 233, g: 30, b: 99, a: 0 },
    },
  },
  tier: {
    imageSize: 40,
    button: {
      outlined: true,
      color: {
        alpha: 1,
        hex: "#FFFFFF",
        hexa: "#FFFFFFFF",
        hsla: {
          a: 1,
          h: 0,
          l: 1,
          s: 0,
        },
        hsva: {
          a: 1,
          h: 0,
          s: 0,
          v: 1,
          hue: 0,
        },
        rgba: {
          a: 1,
          b: 255,
          g: 255,
          r: 255,
        },
      },
    },
    tierLabel: {
      align: "center",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "tierLabel",
      floatText: "text-center",
      font: "Work Sans, sans-serif",
      justify: "center",
      lineHeight: 1,
      size: 13,
      style: "normal",
      value: "Emerald",
      weight: "normal",
      marginLeft: 0,
      marginRight: 0,
    },
  },
  cart: {
    button: {
      dark: false,
      color: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
    },
    cartTitle: {
      align: "start",
      boxHeight: 70,
      color: "#FFFFFFFF",
      cols: 12,
      field: "cartTitle",
      floatText: "text-left",
      font: "Quicksand, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 24,
      style: "normal",
      value: "Shopping Cart",
      weight: "normal",
    },
    points: {
      align: "end",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "points",
      floatText: "text-right",
      font: "Quicksand, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 20,
      style: "normal",
      value: "Order Value: 1,100",
      weight: "normal",
    },
    subheading: {
      align: "end",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "subheading",
      floatText: "text-right",
      font: "Work Sans, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 14,
      style: "normal",
      value: "Points Balance: 15,250",
      weight: "normal",
    },
    stepperLabels: {
      align: "start",
      boxHeight: 8,
      color: "#FFFFFFFF",
      cols: 12,
      field: "stepperLabels",
      floatText: "text-left",
      font: "Quicksand, sans-serif",
      justify: "start",
      lineHeight: 1.5,
      size: 15,
      style: "normal",
      value: "Rewards",
      weight: "normal",
      marginLeft: 0,
      marginRight: 0,
    },
    stepperSummary: {
      align: "start",
      boxHeight: 8,
      color: "#FFFFFFFF",
      cols: 12,
      field: "stepperSummary",
      floatText: "text-center",
      font: "Roboto, sans-serif",
      justify: "center",
      lineHeight: 1.1,
      size: 12,
      style: "normal",
      value: "Details summary",
      weight: "normal",
      marginLeft: 0,
      marginRight: 0,
    },
    content: {
      align: "center",
      heading: {
        color: "#771033FF",
        font: "Roboto, sans-serif",
        lineHeight: 1,
        size: 13,
        style: "normal",
        weight: "bold",
      },
      data: {
        color: "#FFFFFFFF",
        font: "Work Sans, sans-serif",
        lineHeight: 1,
        size: 15,
        style: "normal",
        weight: "normal",
      },
    },
    iconSize: 25,
    imageSize: 40,
    imageTile: false,
    rowBorderSize: 1,
    rowHoverColor: {
      alpha: 0.5,
      hex: "#EC407A",
      hexa: "#EC407A80",
      hsla: {
        a: 0.5,
        h: 339.7674418604651,
        l: 0.5882352941176471,
        s: 0.8190476190476191,
      },
      hsva: {
        a: 0.5,
        h: 339.7674418604651,
        s: 0.728813559322034,
        v: 0.9254901960784314,
        hue: 339.7674418604651,
      },
      rgba: {
        a: 0.5,
        b: 122,
        g: 64,
        r: 236,
      },
    },
    rowBorderColor: {
      alpha: 0.5,
      hex: "#EC407A",
      hexa: "#EC407A80",
      hsla: {
        a: 0.5,
        h: 339.7674418604651,
        l: 0.5882352941176471,
        s: 0.8190476190476191,
      },
      hsva: {
        a: 0.5,
        h: 339.7674418604651,
        s: 0.728813559322034,
        v: 0.9254901960784314,
        hue: 339.7674418604651,
      },
      rgba: {
        a: 0.5,
        b: 122,
        g: 64,
        r: 236,
      },
    },
    borderSize: 0,
    borderColor: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
    color: {
      type: "gradient",
      solidColor: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientFrom: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientTo: {
        alpha: 1,
        hex: "#94143F",
        hexa: "#94143FFF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148,
        },
      },
      direction: "to bottom",
      gradientHeight: 100,
    },
    width: 900,
    fullscreen: false,
    elevation: 6,
    rounded: "rounded-lg",
    icon: {
      align: "end",
      boxHeight: 110,
      show: true,
      justify: "center",
      placement: "top",
      size: 90,
    },
    title: {
      align: "center",
      boxHeight: 120,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dialogTitle",
      floatText: "text-center",
      font: "Quicksand, sans-serif",
      justify: "start",
      lineHeight: 1.5,
      marginLeft: 0,
      marginRight: 0,
      size: 40,
      style: "normal",
      value: "Pop Up Heading",
      weight: "normal",
    },
    padding: {
      top: 25,
      bottom: 20,
      right: 15,
      left: 15,
    },
    detail: {
      margin: {
        top: 0,
        bottom: 30,
        right: 0,
        left: 0,
      },
    },
  },
  dropdown: {
    dropdownLabel: {
      align: "start",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dropdownLabel",
      floatText: "text-right",
      font: "Work Sans, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 14,
      style: "normal",
      value: "Profile",
      weight: "normal",
    },
    color: {
      type: "gradient",
      solidColor: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientFrom: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientTo: {
        alpha: 1,
        hex: "#94143F",
        hexa: "#94143FFF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148,
        },
      },
      direction: "to bottom",
      gradientHeight: 100,
    },
  },
  history: {
    button: {
      dark: false,
      color: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
    },
    historyTitle: {
      align: "start",
      boxHeight: 70,
      color: "#FFFFFFFF",
      cols: 12,
      field: "historyTitle",
      floatText: "text-left",
      font: "Quicksand, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 24,
      style: "normal",
      value: "Points Transaction History",
      weight: "normal",
    },
    points: {
      align: "end",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "pointsHistory",
      floatText: "text-right",
      font: "Quicksand, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 20,
      style: "normal",
      value: "Balance: 15,520",
      weight: "normal",
    },
    subheading: {
      align: "end",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "subheadingHistory",
      floatText: "text-right",
      font: "Work Sans, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 14,
      style: "normal",
      value: "Awarded: 35,980",
      weight: "normal",
    },
    content: {
      align: "center",
      heading: {
        color: "#771033FF",
        font: "Roboto, sans-serif",
        lineHeight: 1,
        size: 13,
        style: "normal",
        weight: "bold",
      },
      data: {
        color: "#FFFFFFFF",
        font: "Work Sans, sans-serif",
        lineHeight: 1,
        size: 15,
        style: "normal",
        weight: "normal",
      },
    },
    rowBorderSize: 1,
    rowHoverColor: {
      alpha: 0.5,
      hex: "#EC407A",
      hexa: "#EC407A80",
      hsla: {
        a: 0.5,
        h: 339.7674418604651,
        l: 0.5882352941176471,
        s: 0.8190476190476191,
      },
      hsva: {
        a: 0.5,
        h: 339.7674418604651,
        s: 0.728813559322034,
        v: 0.9254901960784314,
        hue: 339.7674418604651,
      },
      rgba: {
        a: 0.5,
        b: 122,
        g: 64,
        r: 236,
      },
    },
    rowBorderColor: {
      alpha: 0.5,
      hex: "#EC407A",
      hexa: "#EC407A80",
      hsla: {
        a: 0.5,
        h: 339.7674418604651,
        l: 0.5882352941176471,
        s: 0.8190476190476191,
      },
      hsva: {
        a: 0.5,
        h: 339.7674418604651,
        s: 0.728813559322034,
        v: 0.9254901960784314,
        hue: 339.7674418604651,
      },
      rgba: {
        a: 0.5,
        b: 122,
        g: 64,
        r: 236,
      },
    },
    borderSize: 0,
    borderColor: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
    color: {
      type: "gradient",
      solidColor: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientFrom: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientTo: {
        alpha: 1,
        hex: "#94143F",
        hexa: "#94143FFF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148,
        },
      },
      direction: "to bottom",
      gradientHeight: 100,
    },
    width: 900,
    fullscreen: false,
    elevation: 6,
    rounded: "rounded-lg",
    title: {
      align: "center",
      boxHeight: 120,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dialogTitle",
      floatText: "text-center",
      font: "Quicksand, sans-serif",
      justify: "start",
      lineHeight: 1.5,
      marginLeft: 0,
      marginRight: 0,
      size: 40,
      style: "normal",
      value: "Pop Up Heading",
      weight: "normal",
    },
    padding: {
      top: 25,
      bottom: 20,
      right: 15,
      left: 15,
    },
    detail: {
      margin: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    },
  },
  mobile: {
    color: {
      alpha: 1,
      hex: "#212121",
      hexa: "#212121FF",
      hsla: { h: 0, s: 0, l: 0.12941176470588237, a: 0 },
      hsva: { h: 0, s: 0, v: 0.12941176470588237, a: 0 },
      hue: 0,
      rgba: { r: 33, g: 33, b: 33, a: 1 },
    },
    label: {
      align: "center",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "label",
      floatText: "text-center",
      font: "Work Sans, sans-serif",
      justify: "center",
      lineHeight: 1,
      size: 13,
      style: "normal",
      value: "Home",
      weight: "normal",
      marginLeft: 0,
      marginRight: 0,
    },
  },
  logo: {
    align: "left",
    image: "",
    previewImage: "",
    width: 100,
  },
  mobileLogo: {
    align: "center",
    justify: "center",
    image: "",
    previewImage: "",
    height: 100,
    placement: "top",
    boxHeight: 50,
  },
  menuGroup: {
    cols: 12,
    group: false,
    justify: "center",
  },
  label: {
    align: "center",
    boxHeight: 10,
    color: "#FFFFFFFF",
    cols: 12,
    field: "label",
    floatText: "text-center",
    font: "Work Sans, sans-serif",
    justify: "center",
    lineHeight: 1,
    size: 13,
    style: "normal",
    value: "Home",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  mobileLabel: {
    align: "center",
    boxHeight: 10,
    color: "#FFFFFFFF",
    cols: 12,
    field: "label",
    floatText: "text-left",
    font: "Roboto, sans-serif",
    justify: "center",
    lineHeight: 1,
    size: 13,
    style: "normal",
    value: "Home",
    weight: "normal",
    marginLeft: 0,
    marginRight: 0,
  },
  buttons: {
    left: {
      justify: "start",
    },
    right: {
      justify: "end",
    },
  },
};

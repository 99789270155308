<template>
  <v-dialog v-model="openProcessingDialog" persistent width="450px">
    <v-container fluid fill-height class="pa-0">
      <v-card width="450px">
        <v-row align="center" justify="center" style="height: 450px" no-gutters>
          <v-col align="center">
            <v-row no-gutters class="page-heading mt-n12 mb-6" justify="center">
              Processing...
            </v-row>
            <v-progress-circular
              size="100"
              width="8"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-row
              no-gutters
              class="page-heading primary--text mt-6 mb-n12"
              justify="center"
            >
              Do Not Close Browser
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: ["openProcessingDialog"],
};
</script>

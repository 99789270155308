/* eslint-disable no-console */
import { db } from "@/firebase";

const state = {
  companyTags: [],
  loadingCompanyTags: false,
};

const actions = {
  async loadCompanyTags({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("companyTags")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const companyTags = [];
    querySnapshot.forEach(function (doc) {
      companyTags.push({
        id: doc.id,
        tag: doc.data().tag,
        tagUppercase: doc.data().tagUppercase,
      });
    });

    commit("setCompanyTags", companyTags);
    commit("setLoadingTable", false);
  },

  async createCompanyTag({ dispatch, commit, getters }, payload) {
    const tagsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("companyTags");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await tagsRef
        .where("tagUppercase", "==", payload.tagUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the tag existency.";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Tag is already registered.";
    }

    const { id, ...tagInput } = payload; // eslint-disable-line no-unused-vars
    const tag = {
      ...tagInput,
    };

    let newTagRef;
    try {
      newTagRef = await tagsRef.add(tag);
    } catch (e) {
      throw "Error occured when creating a new tag.";
    }

    commit("createCompanyTag", { ...tagInput, id: newTagRef.id });
    dispatch("setSnackbar", "Tag Created.");
  },

  async updateCompanyTag({ dispatch, commit, getters }, payload) {
    const tagsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("companyTags");

    let storedTag;
    try {
      const tagDoc = await tagsRef.doc(payload.id).get();
      storedTag = tagDoc.data();
    } catch (e) {
      storedTag = null;
    }

    if (!storedTag) {
      throw "Error occured when fetching the tag info";
    }

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await tagsRef
        .where("tagUppercase", "==", payload.tagUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the tag existency.";
    }

    if (titleDupSnapshot.size > 0) {
      let duplicated = false;
      titleDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Tag is already registered.";
      }
    }

    const { id, ...tagInput } = payload;
    const tag = {
      ...tagInput,
    };

    try {
      await tagsRef.doc(id).update(tag);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a tag";
    }

    commit("updateCompanyTag", payload);
    dispatch("setSnackbar", "Tag Updated.");
  },

  async deleteCompanyTag({ dispatch, commit, getters }, tagId) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("companyTags")
        .doc(tagId)
        .delete();
    } catch (e) {
      throw "Error occured when deleting a tag";
    }
    commit("deleteCompanyTag", tagId);
    dispatch("setSnackbar", "Tag Deleted.");
  },

  async importCompanyTags({ getters }, tagsAry) {
    console.log(tagsAry);
    const newTagsBatch = db.batch();

    tagsAry.forEach((item) => {
      const data = {
        tag: item,
        tagUppercase: item.toUpperCase(),
      };
      const newTagRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("companyTags")
        .doc();
      newTagsBatch.set(newTagRef, data);
    });

    try {
      await newTagsBatch.commit();
    } catch (e) {
      throw "tags import batch failed";
    }
  },
};

const mutations = {
  setCompanyTags(state, payload) {
    state.companyTags = payload;
  },

  setLoadingTable(state, payload) {
    state.loadingCompanyTags = payload;
  },

  createCompanyTag(state, payload) {
    state.companyTags = [...state.companyTags, payload];
  },

  updateCompanyTag(state, payload) {
    state.companyTags = state.companyTags.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteCompanyTag(state, payload) {
    state.companyTags = state.companyTags.filter((item) => item.id !== payload);
  },
};

const getters = {
  companyTags(state) {
    return state.companyTags;
  },

  companyTagsMap(state) {
    return state.companyTags.reduce((result, item) => {
      return {
        ...result,
        [item.id]: item.tag,
      };
    }, {});
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <v-card class="el rounded-lg mb-6" height="220">
      <v-progress-linear
        indeterminate
        :active="loadingMembers"
        :color="orgTheme.primary"
      >
      </v-progress-linear>
      <v-card-text>
        <v-row no-gutters justify="center" align="center" style="height: 130px">
          <v-progress-circular
            :value="value"
            :color="color"
            size="120"
            rotate="270"
            width="15"
          >
            <span v-show="value ? true : false" class="guage-value"
              >{{ value }}{{ suffix }}</span
            >
          </v-progress-circular>
        </v-row>
        <v-row no-gutters justify="center">
          <div class="dial-heading">{{ title }}</div>
        </v-row>
        <v-row no-gutters justify="center">
          <div class="dial-subheading">
            {{ subtitle }}
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["value", "title", "subtitle", "color", "suffix"],
  data: function () {
    return {
      isProcessing: false,
    };
  },
  computed: {
    loadingMembers() {
      return this.$store.getters.loadingMembers;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
  },
  methods: {},
};
</script>

<style scoped>
.guage-value {
  font-weight: 700 !important;
  font-size: 27px !important;
  font-family: "Montserrat", sans-serif !important;
  color: #505050 !important;
}
.dial-heading {
  font-weight: 700 !important;
  font-size: 17px !important;
  font-family: "Montserrat", sans-serif !important;
  color: #505050 !important;
  margin-top: 6px;
}
.dial-subheading {
  font-weight: 400 !important;
  font-size: 10px !important;
  font-family: "Montserrat", sans-serif !important;
  color: #505050 !important;
}
.vertical-line {
  border-left: 1px solid #b3b3b3;
}
.v-progress-circular__underlay {
  stroke: hsla(0, 0%, 93%, 0.9) !important;
  z-index: 1;
}
</style>

<template>
  <div>
    <v-dialog
      v-model="openVideoPlayer"
      transition="dialog-transition"
      width="720"
      height="405"
      persistent
    >
      <v-row justify="end" no-gutters>
        <v-btn
          absolute
          color="transparent"
          @click="onClose()"
          class="mt-n8 mr-n8 button-adjust"
          fab
          small
          elevation="0"
        >
          <v-icon color="white" size="20">clear</v-icon>
        </v-btn>
      </v-row>
      <iframe
        class="margin-adjust"
        width="720"
        height="405"
        :src="url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["openVideoPlayer", "url", "onClose"],
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.margin-adjust {
  margin-bottom: -6px !important;
}
.button-adjust {
  z-index: 1000;
}
</style>

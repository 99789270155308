/* eslint-disable no-console */
<template>

  <div class="mt-8 mr-8 mb-8">

    <v-row
      no-gutters
      align="center"
      >
      <v-col sm="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field> 
      </v-col>
      
      <v-col
        lg="3"
        v-if="options.filter"
        >
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter"
          prepend-inner-icon="filter_list"
          class="mr-4"
        ></v-select> 
      </v-col>
      
      <v-col>
        <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
        <v-btn v-if="options.download" class="primary--text" text>Download</v-btn> 
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
      </v-col>
      
      <v-col sm="auto">
        <v-btn
          @click="handleNew()"
          color="primary"
          elevation="0"
          class="mt-n2"
          >
          <v-icon>add</v-icon>new
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="pages"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          left
          close-on-content-click
        >
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title @click="handleEdit(item)">
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="handleDeleteConfirmation(item)">
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
      </template>
      
      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status" class="pr-3"></status-icon>
        {{ item.status }}
      </template>
      
      <template v-slot:[`item.title`]="{ item }">
        <div @click="handleEdit(item)" class="primary--text" style="cursor:pointer">{{ item.title }}</div>
      </template>
              
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | date }}
      </template>
      
      <template v-slot:[`item.updated`]="{ item }">
        {{ item.updated | date }}
      </template>
      
    </v-data-table>

    <page-add-or-edit
      :pageId="selectedPageId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <v-dialog 
      v-model="dialogDeleteOpen"
      max-width="400px"
      persistent
      >
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <div v-if="filesAttached > 0">
            <v-row no-gutters class="mt-6">
              <v-col align="center">
                This page cannot be deleted as it has {{ filesAttached }} file attached in the resources section. Delete the file(s) prior to deleting this page or set it's Status to Inactive
              </v-col>
            </v-row>
            
          </div>
          
          <div v-else>
            <v-row justify="center" no-gutters>
              <v-card-title class="page-heading">
                Are You Sure?
              </v-card-title>
            </v-row>
            
            <v-row no-gutters>
              <v-col align="center">
                You are about to delete <span class="incentable-form-bold">{{ title }}</span>
              </v-col>
            </v-row>

          </div>
          
          
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleCloseDeleteDialog()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo || resources.length > 0"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  

  </div>

</template>

<script>
  export default {
    created () {
      
    },

    data: () => ({

      // Component settings
      tableTitle: 'Pages',
      tableSubtitle: 'Publish news or annoucements by creating pages',
      tableIcon: 'web',
      search: '',
      title: '',
      resources: [],
      selectedPageId: '',
      dialogNewOrEditOpen: false,
      dialogDeleteOpen: false,

      // Table settings
      options: {
        sortBy: ['title'],
        sortDesc: [false],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: true,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
      
      // Table columns
      headers: [
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Title', value: 'title', align: 'left' },
        { text: 'Created', value: 'created', align: 'right' },
        { text: 'Updated', value: 'updated', align: 'right' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],

    }),

    computed: {
      // Functions specific to this table //
      pages () {
        return this.$store.state.page.pages
      },

      // Functions common to all tables //
      loadingTable () {
      return this.$store.getters.loadingTable
      },
      loading () {
      return this.$store.getters.loading
      },
      orgTheme () {
        return this.$store.getters.orgTheme
      },
      systemTheme () {
        return this.$store.getters.systemTheme
      },
      snackbar () {
        return this.$store.getters.snackbar
      },
      demo () {
        return this.$store.state.program.currentProgram.demo
      },
      filesAttached() {
        const found = this.resources.filter(el => el.type === 'file')
        return found.length
      }
    },

    methods: {
      // Actions common to all tables //
      getStatusIcon (item) {
        if (item === 'Active') {
          return 'check_circle'
        } else if (item === 'Inactive') {
          return 'not_interested'
        }
      },
      handleNew() {
        // Open New form
        this.selectedPageId = '';
        this.dialogNewOrEditOpen = true;
      },
      handleEdit(item) {
        // Open Edit form
        this.selectedPageId = item.id;
        this.dialogNewOrEditOpen = true;
      },
      handleCloseNewOrEditDialog() {
        // Close New Or Edit form
        this.selectedPageId = '';
        this.dialogNewOrEditOpen = false;
      },
      handleDeleteConfirmation (item) {
        // Delete record
        this.selectedPageId = item.id;
        this.title = item.title;
        this.resources = item.resources;
        this.dialogDeleteOpen = true;
      },
      handleCloseDeleteDialog() {
        // Close delete form
        this.selectedPageId = '';
        this.title = '';
        this.resources = [];
        this.dialogDeleteOpen = false;
      },
      handleDelete() {
        // Delete Page
        this.$store.dispatch('setLoading', true)
        this.$store.dispatch('deletePage', this.selectedPageId)
          .then(() => this.dialogDeleteOpen = false)
          .catch(() => this.dialogDeleteOpen = false)
      }
    }
  }
</script>

<style>


</style>

<template>
  <v-dialog
    v-model="open"
    persistent
    :width="dialogWidth"
  >
    <v-card>
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
        absolute
        color="primary"
        :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="65" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          Sent {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text 
        v-if="type === 'email'"
        class="px-16">
        <email
          :notification="notification"
        ></email>
      </v-card-text>

      <v-card-text 
        v-if="type === 'app'"
        class="px-16">
        <notification
          :notification="notification"
        ></notification>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="handleClose"
        >Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data: function () {
    return {
      formIcon: 'send'
    }
  },
    props: {
      notification: {
        type: Object,
        required: true,
      },
      open: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      type () {
        return this.notification.type
      },
      formTitle () {
        if (this.notification.type === 'email') {
          return 'Email'
        } else {
          return 'App Notification'
        }
      },
      systemTheme () {
      return this.$store.getters.systemTheme
      },
      dialogWidth () {
        if (this.notification.type === 'email') {
          return '1300px'
        } else {
          return '1000px'
        }
      }
    },
    methods: {
      handleClose () {
        this.clear();
        this.$emit('onClose');
      },
      clear () {
      },
    }
  }
</script>

<style scoped>

</style>
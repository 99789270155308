<template>
  <div>
    <v-tabs v-model="tab" class="mx-0">
      <v-tab class="tab">Languages</v-tab>
      <v-tab class="tab">Settings</v-tab>
    </v-tabs>
    <v-row no-gutters justify="start">
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <translations-list></translations-list>
          </v-tab-item>
          <v-tab-item>
            <settings />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Settings from "./Settings.vue";
export default {
  components: {
    Settings,
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadTranslations");
    this.$store.dispatch("setNavMenu", true);
  },
  data() {
    return {
      pageTitle: "Translations",
      pageSubTitle:
        "Add translations here that your program members can select when using your program website",
      tab: null,
    };
  },
};
</script>

<style scoped></style>

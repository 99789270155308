<template>
<div>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">
                    Product
                </th>
                <th class="text-right">
                    Currency
                </th>
                <th class="text-right">
                    Amount
                </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in items"
                :key="item.name"
                >
                <td>{{ item.price.product.name }}</td>
                <td class="uppercase text-right">{{ item.price.currency }}</td>
                <td class="text-right">${{ item.price.unit_amount / 100 | number }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</div>
</template>

<script>
export default {
    props: ['items'],
    created () {},
    data () {
        return {
            // variables
        }  
    },
    computed: {},
    methods: {},
    watch: {}
}
</script>

<style scoped>
.uppercase {
    text-transform: uppercase;
}
</style>
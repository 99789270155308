<template>
  <div>
    <v-card class="ma-4 pt-4 pb-6 px-4" dark outlined>
      <v-row no-gutters class="pt-1 pb-3">
        <v-col>
          <v-icon color="primary" class="mt-n1">palette</v-icon>
          <span class="editor-heading ml-2">Global Colors</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="setting-label"> Primary </v-col>
        <v-col>
          <v-menu
            bottom
            nudge-bottom="25"
            :close-on-content-click="false"
            transition="slide-y-transition"
            max-width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row no-gutters align="center">
                <v-col cols="auto">
                  <v-img
                    class="rounded"
                    :src="require('@/assets/checkerboard.png')"
                    height="30px"
                    width="30px"
                  >
                    <v-card
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      height="30px"
                      width="30px"
                      :color="webTheme.colors.primary.hexa"
                    >
                    </v-card>
                  </v-img>
                </v-col>
                <v-col class="setting-label ml-2">
                  {{ webTheme.colors.primary.hexa }}
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-color-picker
                @input="handleChange()"
                dot-size="25"
                show-swatches
                mode="hexa"
                swatches-max-height="200"
                v-model="webTheme.colors.primary"
              ></v-color-picker>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="ma-4 pt-4 pb-6 px-4" dark outlined>
      <v-row no-gutters class="pt-1 pb-3">
        <v-col>
          <v-icon color="primary" class="mt-n1">palette</v-icon>
          <span class="editor-heading ml-2">Section Background Colors</span>
        </v-col>
      </v-row>
      <v-row no-gutters align="center">
        <v-col class="setting-label" cols="4"> Site </v-col>
        <v-col>
          <v-menu
            bottom
            nudge-bottom="25"
            :close-on-content-click="false"
            transition="slide-y-transition"
            max-width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row no-gutters align="center">
                <v-col cols="auto">
                  <v-img
                    class="rounded outline"
                    :src="require('@/assets/checkerboard.png')"
                    height="30px"
                    width="30px"
                  >
                    <v-card
                      v-bind="attrs"
                      v-on="on"
                      height="30px"
                      width="30px"
                      :color="webTheme.colors.canvas.hexa"
                    >
                    </v-card>
                  </v-img>
                </v-col>
                <v-col class="setting-label ml-2">
                  {{ webTheme.colors.canvas.hexa }}
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-color-picker
                @input="handleChange()"
                dot-size="25"
                show-swatches
                mode="hexa"
                swatches-max-height="200"
                v-model="webTheme.colors.canvas"
              ></v-color-picker>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" class="mt-3">
        <v-col class="setting-label" cols="4"> Header </v-col>
        <v-col>
          <v-menu
            bottom
            nudge-bottom="25"
            :close-on-content-click="false"
            transition="slide-y-transition"
            max-width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row no-gutters align="center">
                <v-col cols="auto">
                  <v-img
                    class="rounded outline"
                    :src="require('@/assets/checkerboard.png')"
                    height="30px"
                    width="30px"
                  >
                    <v-card
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      height="30px"
                      width="30px"
                      :color="webTheme.colors.header.hexa"
                      class="outline"
                    >
                    </v-card>
                  </v-img>
                </v-col>
                <v-col class="setting-label ml-2">
                  {{ webTheme.colors.header.hexa }}
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-color-picker
                @input="handleChange()"
                dot-size="25"
                show-swatches
                mode="hexa"
                swatches-max-height="200"
                v-model="webTheme.colors.header"
              ></v-color-picker>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" class="mt-3">
        <v-col class="setting-label" cols="4"> Content </v-col>
        <v-col>
          <v-menu
            bottom
            nudge-bottom="25"
            :close-on-content-click="false"
            transition="slide-y-transition"
            max-width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row no-gutters align="center">
                <v-col cols="auto">
                  <v-img
                    class="rounded outline"
                    :src="require('@/assets/checkerboard.png')"
                    height="30px"
                    width="30px"
                  >
                    <v-card
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      height="30px"
                      width="30px"
                      :color="webTheme.colors.content.hexa"
                    >
                    </v-card>
                  </v-img>
                </v-col>
                <v-col class="setting-label ml-2">
                  {{ webTheme.colors.content.hexa }}
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-color-picker
                @input="handleChange()"
                dot-size="25"
                show-swatches
                mode="hexa"
                swatches-max-height="200"
                v-model="webTheme.colors.content"
              ></v-color-picker>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row no-gutters align="center" class="mt-3">
        <v-col class="setting-label" cols="4"> Footer </v-col>
        <v-col>
          <v-menu
            bottom
            nudge-bottom="25"
            :close-on-content-click="false"
            transition="slide-y-transition"
            max-width="300px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row no-gutters align="center">
                <v-col cols="auto">
                  <v-img
                    class="rounded outline"
                    :src="require('@/assets/checkerboard.png')"
                    height="30px"
                    width="30px"
                  >
                    <v-card
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      height="30px"
                      width="30px"
                      :color="webTheme.colors.footer.hexa"
                    >
                    </v-card>
                  </v-img>
                </v-col>
                <v-col class="setting-label ml-2">
                  {{ webTheme.colors.footer.hexa }}
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-color-picker
                @input="handleChange()"
                dot-size="25"
                show-swatches
                mode="hexa"
                swatches-max-height="200"
                v-model="webTheme.colors.footer"
              ></v-color-picker>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("editingField", {});
    this.$store.dispatch("editingComponent", {});
  },
  data: function () {
    return {};
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates;
    },
  },
  methods: {
    handleChange() {
      this.$store.dispatch("setHasWebThemePendingUpdates", true);
    },
  },
};
</script>

<style>
.setting-label {
  color: #dbdbdb !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  margin-left: 2px !important;
}
.editor-heading {
  font-weight: 300 !important;
  font-size: 13px !important;
  font-family: "Roboto", sans-serif !important;
  color: #acacac !important;
}
.outline {
  outline: 1px solid;
  color: #acacac !important;
}
</style>

<template>
  <div>
    <v-card class="el">
      <v-toolbar flat dense>
        <div class="incentable-form-heading">Website</div>
        <v-spacer></v-spacer>
        <div v-if="currentSubscription.status === 'active' || trial">
          <v-icon class="green--text pr-1" size="21">check_circle</v-icon>
          <span class="dash-card green--text">Online</span>
        </div>
        <div v-else>
          <v-icon class="red--text pr-1" size="21"
            >desktop_access_disabled</v-icon
          >
          <span class="dash-card red--text">Offline</span>
        </div>
      </v-toolbar>

      <v-card-text>
        <v-row
          v-for="(domain, ref) in domains"
          :key="ref"
          no-gutters
          class="mb-1"
        >
          <div
            v-if="
              (domain.status === 'Live' &&
                currentSubscription.status === 'active') ||
              (trial && domain.status === 'Live')
            "
          >
            <v-row no-gutters>
              <v-col cols="auto">
                <v-icon color="green" size="21" class="mr-1"
                  >check_circle</v-icon
                >
              </v-col>
              <v-col>
                <a
                  :href="`https://${domain.activeDomain}`"
                  target="_blank"
                  class="text"
                >
                  {{ domain.activeDomain }}
                </a>
              </v-col>
            </v-row>
          </div>
          <div
            v-else-if="
              (domain.status === 'Processing' &&
                currentSubscription.status === 'active') ||
              trial
            "
          >
            <v-row no-gutters>
              <v-col cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="amber"
                      v-bind="attrs"
                      v-on="on"
                      size="21"
                      class="mr-1"
                    >
                      pending
                    </v-icon>
                  </template>
                  <div>This web address is currently processing</div>
                  <div>and should be fully set up with 24 hours.</div>
                  <div>But don't worry, your site is live! Use the</div>
                  <div>temporary domain to access it.</div>
                  <div>Do not publish your temporary domain to users, as</div>
                  <div>it will be deleted once your web address is live.</div>
                </v-tooltip>
              </v-col>
              <v-col>
                <span class="text">
                  {{ domain.activeDomain }}
                </span>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row no-gutters>
              <v-col cols="auto">
                <v-icon color="red" size="21" class="mr-1">cancel</v-icon>
              </v-col>
              <v-col>
                <span class="text">
                  {{ domain.activeDomain }}
                </span>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["programId", "currentSubscription", "trial", "expired"],
  data: function () {
    return {};
  },
  created() {
    this.$store.dispatch("loadSites");
  },
  computed: {
    sites() {
      return this.$store.state.site.sites;
    },
    domains() {
      return this.sites.filter((el) => el.program === this.programId);
    },
  },
  methods: {},
};
</script>

<style scoped>
.text {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: 400 !important;
  text-decoration: none;
  margin-left: 4px;
}
</style>

<template>
  <div class="mt-8 mr-8 mb-8">

        <v-row no-gutters>
          <v-col lg="3">
            <v-text-field
              readonly
              name="Program ID"
              label="Program ID"
              v-model="programId"
            ></v-text-field>
            <v-text-field
              readonly
              name="Created"
              label="Created"
              :value="currentProgram.createdAt | date"
            ></v-text-field>
            <v-text-field
              readonly
              name="Created By"
              label="Created By"
              :value="currentProgram.createdBy"
            ></v-text-field>

          </v-col>
        </v-row>

        <v-btn 
          @click="openDelete()"
          color="primary"
          elevation="0"
          dark class="mb-2">Delete Program
        </v-btn>


    <v-dialog v-model="dialogDelete" max-width="400px" persistent>
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>
        
        <v-row justify="center" class="mt-n12" no-gutters>
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
      
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>
      
        <v-card-text>
          <v-row>
            <v-col>
              To delete a program, please submit a request via email to <a href="mailto:hello@incentable.com?subject=Program Cancellation Request">hello@incentable.com</a>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-4 pr-4">
          <v-spacer></v-spacer>
          <v-btn @click="closeDelete()" text>Cancel</v-btn>
          <v-btn 
            :disabled="!isIncentableAdmin"
            color="red"
            @click="deleteProgram(programId)"
            :loading="loading"
            class="white--text"
            elevation="0"
            >
            Delete Program
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

  
</template>

<script>
export default {
  data: () => ({
    dialogDelete: false
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    programId () {
      return this.$store.getters.programId
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    isIncentableAdmin () {
      return this.$store.getters.isIncentableAdmin
    },
    orgTheme () {
    return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
  },
  watch: {
  },
  created () {
  },
  methods: {
    deleteProgram (id) {
      this.$store.dispatch('deleteProgram', id)
      setTimeout(() => this.$router.push('/'), 1000)
      this.dialogDelete = false
    },
    openDelete() {
      this.dialogDelete = true
    },
    closeDelete () {
      this.dialogDelete = false
    },
  }
}
</script>

<style scoped>

</style>

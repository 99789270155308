<template>
  <div>
    <loading-cards v-if="loadingCards" :count="cardCount" />

    <v-row v-if="!loadingCards" no-gutters class="mt-3">
      <v-col
        v-for="(program, id) in filteredPrograms"
        v-bind:key="id"
        class="pa-3"
        md="4"
        xs="12"
      >
        <v-card class="el" style="height: 224px">
          <v-img
            :src="require('@/assets/card-background-2.png')"
            min-height="224"
          >
            <v-row no-gutters justify="end">
              <v-btn
                class="manage-button white--text mr-6"
                elevation="0"
                color="primary"
                fab
                rounded
                @click="onLoadProgram(program.id)"
              >
                <v-icon :color="orgTheme.buttonIconColor">navigate_next</v-icon>
              </v-btn>
            </v-row>
            <v-card-title @click="onLoadProgram(program.id)">
              <v-clamp :max-lines="2" class="incentable-program-card-title">
                {{ program.title }}
              </v-clamp>
            </v-card-title>
            <v-card-subtitle
              class="incentable-card-subtitle"
              v-if="program.status === 'Active'"
            >
              <a :href="domain(program.id).index" class="link" target="_blank">
                {{ domain(program.id).simple }}
              </a>
            </v-card-subtitle>
            <v-card-subtitle
              v-else
              class="incentable-card-subtitle primary--text"
            >
              {{ domain(program.id).simple }}
            </v-card-subtitle>
            <v-row no-gutters justify="end">
              <v-card-actions class="pr-2 card-actions pb-2">
                <v-chip
                  class="capitalize table-subtitle white--text"
                  small
                  color="transparent"
                >
                  <v-icon
                    v-if="subscriptionStatus(program.id) === 'active'"
                    size="10"
                    class="mr-1 ml-n1"
                    color="green"
                    >circle</v-icon
                  >
                  <v-icon
                    v-else-if="subscriptionStatus(program.id) === 'canceled'"
                    size="10"
                    class="mr-1 ml-n1"
                    color="red"
                    >circle</v-icon
                  >
                  <v-icon v-else size="10" class="mr-1 ml-n1" color="amber"
                    >circle</v-icon
                  >
                  {{ subscriptionStatus(program.id) }}
                </v-chip>
              </v-card-actions>
            </v-row>
            <v-card-actions class="pl-2 card-actions pb-2">
              <v-chip
                v-if="program.subscriptionStatus === 'Demo'"
                color="primary"
                small
                dark
              >
                <span>Demo</span>
              </v-chip>
              <v-icon
                v-if="program.androidAppStatus === 'Active'"
                color="primary"
                class="mx-2"
              >
                android
              </v-icon>
              <v-icon
                v-if="program.iosAppStatus === 'Active'"
                color="primary"
                class="mx-2"
                >fab fa-apple</v-icon
              >
              <v-icon
                v-if="program.status === 'Active'"
                color="primary"
                class="mx-2"
                >desktop_windows</v-icon
              >
            </v-card-actions>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
export default {
  components: { VClamp },
  props: ["search", "programs"],
  data: () => ({
    cardCount: 9,
  }),
  created() {},
  computed: {
    loadingCards() {
      return this.$store.getters.loadingCards;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    sites() {
      return this.$store.state.site.sites;
    },
    subscriptions() {
      return this.$store.state.plans.subscriptions;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    filteredPrograms() {
      const arr = this.programs;
      const subset = arr.filter((item) => item.status.includes("Archive"));
      if (this.search.length > 0) {
        var input = this.search.toUpperCase();
        var inputLower = this.search.toLowerCase();
        var filtered = subset.filter(
          (item) =>
            item.titleUppercase.includes(input) || item.url.includes(inputLower)
        );
        return filtered;
      }
      return subset;
    },
  },
  methods: {
    onLoadProgram(id) {
      this.$store.dispatch("loadCurrentProgram", id);
      this.$store.dispatch("selectProgram", id);
      this.$store.dispatch("getCurrentSubscription", id);
      this.$router.push("/dashboard/" + id);
      // reset the table preview account when program changes
      this.$store.dispatch("setPreviewAccountKey", null);
    },
    domain(id) {
      const url = !this.sites.find((el) => el.program === id)
        ? "Site not found"
        : this.sites.find((el) => el.program === id && el.status === "Live")
            .activeDomain;
      return {
        index: "https://" + url,
        register: "https://" + url + "/register",
        signin: "https://" + url + "/signin",
        simple: url,
      };
    },
    subscriptionStatus(id) {
      const val = this.subscriptions.find((el) => el.program === id);
      return !val ? "Free Trial" : val.status;
    },
  },
};
</script>

<style scoped>
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>

// **** IMPORTANT NOTE WHEN UPDATING THIS FILE
// This file is used in both sites, app.incentable.com and incentable.com and
// Must be saved in both when updated

export const FEATURES = [
    { id: 201, title: 'Reward Points', description: 'This is a super terrific feature', group: 'features' },
    { id: 202, title: 'Rewards Catalog', description: 'This is a super terrific feature', group: 'features' },
    { id: 203, title: 'Shopping Cart', description: 'This is a super terrific feature', group: 'features' },
    { id: 204, title: 'Order Management', description: 'This is a super terrific feature', group: 'features' },
    { id: 205, title: 'Content Management', description: 'This is a super terrific feature', group: 'features' },
    { id: 206, title: 'Member Management', description: 'This is a super terrific feature', group: 'features' },
    { id: 207, title: 'Bulk Email', description: 'This is a super terrific feature', group: 'features' },
    { id: 208, title: 'Design Studio', description: 'This is a super terrific feature', group: 'features' },
    { id: 209, title: 'Leaderboards', description: 'This is a super terrific feature', group: 'features' },
    { id: 210, title: 'Target Tracker', description: 'This is a super terrific feature', group: 'features' },
    { id: 211, title: 'Claims Manager', description: 'This is a super terrific feature', group: 'features' },
    { id: 230, title: 'Custom Domain', description: 'This is a super terrific feature', group: 'features' },
    { id: 231, title: 'Sub Domain', description: 'This is a super terrific feature', group: 'features' },
    { id: 102, title: 'All Lite Features', description: 'This is a super terrific feature', group: 'features' },
    { id: 103, title: 'All Pro Features', description: 'This is a super terrific feature', group: 'features' },
    { id: 111, title: '100 Members', description: 'This is a super terrific feature', group: 'users' },
    { id: 112, title: '250 Members', description: 'This is a super terrific feature', group: 'users' },
    { id: 113, title: '500 Members', description: 'This is a super terrific feature', group: 'users' },
    { id: 114, title: '1000 Members', description: 'This is a super terrific feature', group: 'users' },
    { id: 115, title: '2000 Members', description: 'This is a super terrific feature', group: 'users' },
    { id: 116, title: '3000 Members', description: 'This is a super terrific feature', group: 'users' },
    { id: 301, title: '1 Admin', description: '', group: 'users' },
    { id: 302, title: '2 Admins', description: '', group: 'users' },
    { id: 303, title: '3 Admins', description: '', group: 'users' },
    { id: 304, title: '4 Admins', description: '', group: 'users' },
    { id: 305, title: '5 Admins', description: '', group: 'users' },
    { id: 306, title: '6 Admins', description: '', group: 'users' },
    { id: 401, title: 'Standard Support', description: 'This is a super terrific feature', group: 'support' },
    { id: 402, title: 'Priority Support', description: 'This is a super terrific feature', group: 'support' },
    { id: 403, title: 'Premium Support', description: 'This is a super terrific feature', group: 'support' },
    { id: 404, title: 'Self Serve Training Academy', description: 'This is a super terrific feature', group: 'support' },
    { id: 405, title: 'Dedicated Account Manager', description: 'This is a super terrific feature', group: 'support' },
    { id: 501, title: 'White Label', description: 'This is a super terrific feature', group: 'features' },
    { id: 502, title: 'Host Client Programs', description: 'This is a super terrific feature', group: 'features' },
    { id: 503, title: 'Reseller Pricing', description: 'This is a super terrific feature', group: 'features' },
    { id: 509, title: 'Priority Support', description: 'This is a super terrific feature', group: 'support' },
]

export const PLANS = [
    { plan: 'Lite', features: [ 201,202,203,204,205,206,207,208,231,301,111,401,404 ] },
    { plan: 'Lite+', features: [ 201,202,203,204,205,206,207,208,231,302,112,401,404 ] },
    { plan: 'Pro', features: [ 102,113,303,402,209,210,211,230,404,405 ] },
    { plan: 'Pro+', features: [ 102,114,304,402,209,210,211,230,404,405 ] },
    { plan: 'Enterprise', features: [ 103,115,403,305,404,405 ] },
    { plan: 'Enterprise+', features: [ 103,116,403,306,404,405 ] },
    { plan: 'Live Test', features: [ 201 ] },
] 
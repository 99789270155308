<!--Banner-->
<template>
  <v-card class="mx-4 mb-6" dark outlined>
    <v-expansion-panels accordion flat color="transparent" dark>
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Text Style</v-expansion-panel-header>
        <v-expansion-panel-content>         

          <v-row no-gutters>
            <v-text-field
              name="labels"
              label="Labels"
              value="Firstname"
              @input="handleChange()"
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'labels'" 
              icon
              class="mt-4"
              @click="handleEditor('labels')"
            >
              <v-icon color="primary">
                settings
              </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'labels'" 
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'labels'"
          ></design-heading-editor>
          
          <v-row no-gutters>
            <v-text-field
              name="values"
              label="Values"
              v-model="currentUser.email"
              @input="handleChange()"
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'values'" 
              icon
              class="mt-4"
              @click="handleEditor('values')"
            >
              <v-icon color="primary">
                settings
              </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'values'" 
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'values'"
          ></design-heading-editor>
          
        </v-expansion-panel-content>
      </v-expansion-panel>         
      
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Active Fields</v-expansion-panel-header>
        <v-expansion-panel-content>         

          <v-row no-gutters>
            <v-checkbox class="mr-2" v-model="editingComponent.firstname.show" @change="handleChange()"></v-checkbox>
            <v-text-field
              name="firstname"
              label="First name"
              v-model="currentUser.firstname"
              @input="handleChange()"
            ></v-text-field>
          </v-row>
          
          <v-row no-gutters>
            <v-checkbox class="mr-2" v-model="editingComponent.lastname.show" @change="handleChange()"></v-checkbox>
            <v-text-field
              name="lastname"
              label="Last name"
              v-model="currentUser.lastname"
              @input="handleChange()"
            ></v-text-field>
          </v-row>
          
          <v-row no-gutters>
            <v-checkbox class="mr-2" v-model="editingComponent.email.show" @change="handleChange()"></v-checkbox>
            <v-text-field
              name="email"
              label="Email"
              v-model="currentUser.email"
              @input="handleChange()"
            ></v-text-field>
          </v-row>
          
          <v-row no-gutters>
            <v-checkbox class="mr-2" v-model="editingComponent.phone.show" @change="handleChange()"></v-checkbox>
            <v-text-field
              name="phone"
              label="Phone"
              v-model="currentUser.phone"
              @input="handleChange()"
            ></v-text-field>
          </v-row>
          
          <v-row no-gutters>
            <v-checkbox class="mr-2" v-model="editingComponent.balance.show" @change="handleChange()"></v-checkbox>
            <v-text-field
              name="balance"
              label="Balance"
              v-model="currentUser.balance"
              @input="handleChange()"
            ></v-text-field>
          </v-row>
          
          <v-row no-gutters>
            <v-checkbox class="mr-2" v-model="editingComponent.awarded.show" @change="handleChange()"></v-checkbox>
            <v-text-field
              name="awarded"
              label="Awarded"
              v-model="currentUser.awarded"
              @input="handleChange()"
            ></v-text-field>
          </v-row>
          
          <v-row no-gutters>
            <v-checkbox class="mr-2" v-model="editingComponent.redeemed.show" @change="handleChange()"></v-checkbox>
            <v-text-field
              name="redeemed"
              label="Redeemed"
              v-model="currentUser.redeemed"
              @input="handleChange()"
            ></v-text-field>
          </v-row>
          
        </v-expansion-panel-content>
      </v-expansion-panel>         
      
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Fields Group</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="mb-3">
            <v-col cols="5">
              <v-icon class="icon">sync_alt</v-icon>
              <span class="setting-label">Alignment</span>
            </v-col>
            <v-icon class="icon-button" @click="setBlockHorizontal('start')">align_horizontal_left</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockHorizontal('center')">align_horizontal_center</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockHorizontal('end')">align_horizontal_right</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockVertical('start')">align_vertical_top</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockVertical('center')">align_vertical_center</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockVertical('end')">align_vertical_bottom</v-icon>
          </v-row>

          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">compress</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.cols"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="1"
                max="12"
                class="mt-n1"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.cols }}/12
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate270 icon">padding</v-icon>
              <span class="setting-label">Left Padding</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.padding.left"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="12"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.left }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">padding</v-icon>
              <span class="setting-label">Right Padding</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.padding.right"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="12"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.right }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">padding</v-icon>
              <span class="setting-label">Top Padding</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.padding.top"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="200"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.top }}px
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate180 icon">padding</v-icon>
              <span class="setting-label">Bottom Padding</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.padding.bottom"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="100"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.bottom }}px
            </v-col>
          </v-row>
        
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Background</v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row no-gutters>
              <v-radio-group
                v-model="editingComponent.settings.mode"
                class="mt-n1"
                row
                @change="handleChange()"
              >
                <v-radio
                  label="Image"
                  value="image"
                >
                </v-radio>
                <v-radio
                  label="Color"
                  value="color"
                >
                </v-radio>
              </v-radio-group>
            </v-row>
            
            <div v-if="editingComponent.settings.mode === 'image'">
              <v-row no-gutters justify="center">
                <v-img
                  :src="editingComponent.background.previewImage"
                  max-width="300px"
                  class="rounded outline"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-skeleton-loader
                        min-height="100px"
                        min-width="300px"
                        type="card"
                      >
                      </v-skeleton-loader>
                    </v-row>
                  </template>
                </v-img>
              </v-row>

              <v-row justify="center" no-gutters class="mt-2">

                <design-editor-gallery :saveTo="'background'"/>
                <design-editor-uploads :saveTo="'background'"/>
                
              </v-row>  
            </div>
            
          <v-row 
            v-if="editingComponent.settings.mode === 'color'"
            no-gutters
            align="center"
            class="mt-1"
          >
            <v-col cols="3" class="setting-label">
              Banner Color
            </v-col>
            <v-col>
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                  <template v-slot:activator="{ on, attrs }">
                    <v-row no-gutters align="center">
                      <v-col cols="auto">
                        <v-img
                          class="rounded outline"
                          :src="require('@/assets/checkerboard.png')"
                          height="30px"
                          width="30px"
                        >
                          <v-card
                            v-bind="attrs"
                            v-on="on"
                            elevation="0"
                            height="30px"
                            width="30px"
                            :color="editingComponent.settings.color.hexa"
                          >
                          </v-card>
                        </v-img>
                      </v-col>
                      <v-col class="setting-label ml-2">
                        {{ editingComponent.settings.color.hexa }}
                      </v-col>
                    </v-row>
                  </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.settings.color"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>        
      
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Height</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="setting-label mb-2" no-gutters>
            Banner height can be a fixed amount or a percentage of the viewers browser height (Viewport height)
          </v-row>
          <v-row no-gutters>
            <v-radio-group
              v-model="editingComponent.settings.heightContext"
              class="mt-n1"
              @change="handleChange()"
            >
              <v-radio
                label="Fixed"
                value="px"
              >
              </v-radio>
              <v-radio
                label="Variable"
                value="vh"
              >
              </v-radio>
            </v-radio-group>
          </v-row>

          <div v-if="editingComponent.settings.heightContext === 'px'">
            <v-row no-gutters>
              <v-col cols="5">
                <v-icon class="icon">expand</v-icon>
                <span class="setting-label">Height</span>
              </v-col>
              <v-slider
                v-model="editingComponent.heightValue.px"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="64"
                max="1000"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.heightValue.px }}px
              </v-col>
            </v-row>

          </div>
          
          <div v-if="editingComponent.settings.heightContext === 'vh'">
            <v-row no-gutters class="ml-1 mt-3">
              <v-col cols="5">
                <v-icon class="icon">expand</v-icon>
                <span class="setting-label">Height</span>
              </v-col>
              <v-slider
                v-model="editingComponent.heightValue.vh"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="10"
                max="150"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.heightValue.vh }}%
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>     
      
      <v-expansion-panel v-if="editingComponent.settings.mode === 'image'">
        <v-expansion-panel-header class="editor-subheading">Extend Image</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row 
            class="setting-label mb-0"
            no-gutters
          >
            Extend the banner image to overlap, and run behind, the main content area
          </v-row>
          <div v-if="editingComponent.settings.heightContext === 'px'">
            <v-row no-gutters class="ml-1 mt-3">
              <v-col cols="5">
                <v-icon class="icon">layers</v-icon>
                <span class="setting-label">Extend</span>
              </v-col>
              <v-slider
                v-model="editingComponent.overlap.px"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="1000"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.overlap.px }}px
              </v-col>
            </v-row>
          </div>
          <div v-if="editingComponent.settings.heightContext === 'vh'">
            <v-row no-gutters class="ml-1 mt-3">
              <v-col cols="5" class="setting-label">
                <v-icon class="icon">expand</v-icon>
                <span class="setting-label">Extend</span>
              </v-col>
              <v-slider
                v-model="editingComponent.overlap.vh"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="150"
                class="mt-n1"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.overlap.vh }}%
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>     
      
      <v-expansion-panel v-if="editingComponent.settings.mode === 'image'">
        <v-expansion-panel-header class="editor-subheading">Image Overlay</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="setting-label" no-gutters>
            Add a screen to darken or lighten the banner image
          </v-row>
          <v-row class="setting-label mb-3" no-gutters>
            Add a gradient to blend the banner image into the body color
          </v-row>
          <v-row no-gutters>
            <v-radio-group
              v-model="editingComponent.screen.type"
              class="mt-n1"
              row
              @change="handleChange()"
            >
              <v-radio
                label="Screen"
                value="screen"
              >
              </v-radio>
              <v-radio
                label="Gradient"
                value="gradient"
              >
              </v-radio>
            </v-radio-group>
          </v-row>

          <div v-if="editingComponent.screen.type === 'gradient'">
            <v-row 
              class="setting-label mb-n2"
              no-gutters
            >
              Gradient direction
            </v-row>
            <v-radio-group
              v-model="editingComponent.screen.direction"
              class="mt-3"
              @change="handleChange()"
            >
              <v-row no-gutters>
                <v-col>
                  <v-radio
                    label="Top to Bottom"
                    value="to bottom"
                  >
                  </v-radio>
                  <v-radio
                    label="Bottom to Top"
                    value="to top"
                  >
                  </v-radio>
                </v-col>
                <v-col>
                  <v-radio
                    label="Left to Right"
                    value="to right"
                  >
                  </v-radio>
                  <v-radio
                    label="Right to Left"
                    value="to left"
                  >
                  </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          

            <v-row no-gutters align="center" class="mt-1">
              <v-col cols="2" class="setting-label">
                From
              </v-col>
              <v-col>
                <v-menu
                  bottom
                  nudge-bottom="25"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  max-width="300px"
                  z-index="3000"
                >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="editingComponent.screen.fromColor.hexa"
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="setting-label ml-2">
                          {{ editingComponent.screen.fromColor.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                  <v-card >
                    <v-color-picker
                      @input="handleChange()"
                      dot-size="25"
                      show-swatches
                      mode="hexa"
                      swatches-max-height="200"
                      v-model="editingComponent.screen.fromColor"
                    ></v-color-picker>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
              
            <v-row no-gutters align="center" class="mt-1">
              <v-col cols="2" class="setting-label">
                To
              </v-col>
              <v-col>
                <v-menu
                  bottom
                  nudge-bottom="25"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  max-width="300px"
                  z-index="3000"
                >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="editingComponent.screen.toColor.hexa"
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="setting-label ml-2">
                          {{ editingComponent.screen.toColor.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                  <v-card>
                    <v-color-picker
                      @input="handleChange()"
                      dot-size="25"
                      show-swatches
                      mode="hexa"
                      swatches-max-height="200"
                      v-model="editingComponent.screen.toColor"
                    ></v-color-picker>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-4">
              <v-col cols="5">
                <v-icon class="icon">gradient</v-icon>
                <span class="setting-label">Size</span>
              </v-col>
              <v-slider
                v-model="editingComponent.screen.height"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="1"
                max="100"
                class="mt-n1"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.screen.height }}%
              </v-col>
            </v-row>
          </div>
          
          <v-row 
            v-if="editingComponent.screen.type === 'screen'"
            no-gutters
            align="center"
            class="mt-1"
          >
              <v-col cols="3" class="setting-label">
                Screen Color
              </v-col>
              <v-col>
                <v-menu
                  bottom
                  nudge-bottom="25"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  max-width="300px"
                  z-index="3000"
                >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="editingComponent.screen.color.hexa"
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="setting-label ml-2">
                          {{ editingComponent.screen.color.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                  <v-card >
                    <v-color-picker
                      @input="handleChange()"
                      dot-size="25"
                      show-swatches
                      mode="hexa"
                      swatches-max-height="200"
                      v-model="editingComponent.screen.color"
                    ></v-color-picker>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>     
      
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Container</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">height</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.settings.cols"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="1"
              max="12"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.settings.cols }}/12
            </v-col>
          </v-row>         
        </v-expansion-panel-content>
      </v-expansion-panel>     
    
    </v-expansion-panels>

    <v-row justify="end" class="mt-0 mr-2 mb-2">
      <design-save-button :small="true"></design-save-button>
    </v-row>
  </v-card>
</template>

<script>
import DesignEditorGallery from '../Gallery/Gallery.vue'
import DesignEditorUploads from '../Uploads/Uploads.vue'
import { DEMO_USER } from '@/constants/webtheme/demouser'

export default {
  components: {
    DesignEditorGallery,
    DesignEditorUploads,
  },
  data: () => {
    return {
      currentUser: DEMO_USER
    }
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    editingComponent() {
      return this.$store.getters.editingComponent
    },
    editingField() {
      return this.$store.getters.editingField || {}
    },
  },
  methods: {
    handleApplyImage(image) {
      console.log(image)
    },
    handleChange() {
      this.$store.dispatch('setHasWebThemePendingUpdates', true)
    },
    handleEditor(field) {
      this.clearFieldEditors()
      let selected = {}
      console.log(field)
      if (field === 'labels') {
        selected = this.editingComponent.labels
        console.log(selected)
      } else if (field === 'values') {
        selected = this.editingComponent.values
        console.log(selected)
      } else {
        return
      }
      this.$store.dispatch('editingField', selected)
    },
    clearFieldEditors() {
      this.$store.dispatch('editingField', {})
    },
    setBlockHorizontal(val) {
      this.editingComponent.block.justify = val
      this.handleChange()
    },
    setBlockVertical(val) {
      this.editingComponent.block.align = val
      this.handleChange()
    },
    setLogoHorizontal(val) {
      this.editingComponent.avatar.justify = val
      this.handleChange()
    },
    setLogoVertical(val) {
      this.editingComponent.avatar.align = val
      this.handleChange()
    },
    setAvatarPosition(val) {
      this.editingComponent.avatar.placement = val
      this.handleChange()
    },
    clearLogo() {
      this.editingComponent.avatar.image = ''
      this.handleChange()
    }
  }
}
</script>

<style>
  
</style>
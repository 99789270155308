<template>
  <div class="px-4">
    <v-row>
      <v-col cols="4">
        <v-text-field label="Offer Title" name="title" v-model="title" />

        <v-text-field
          label="Display Title"
          name="displayTitle"
          v-model="displayTitle"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          v-validate="'required'"
          :error-messages="errors.collect('description')"
          data-vv-name="description"
          label="Description"
          filled
          v-model="description"
          name="description"
          type="text"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },

  mounted() {
    this.$validator.localize("en", this.dictionary);
  },

  data: function () {
    return {
      isProcessing: false,
      openDeleteDialog: false,
      availableStatus: [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" },
        { label: "Archive", value: "Archive" },
      ],
    };
  },
  dictionary: {
    custom: {
      title: {
        required: () => "Offer Name is required",
      },
      description: {
        required: () => "Offer description is required",
      },
    },
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    loading() {
      return this.$store.getters.loading;
    },
    title: {
      get() {
        return this.currentOffer.title;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", {
          title: value,
          titleUppercase: value.toUpperCase(),
        });
      },
    },
    displayTitle: {
      get() {
        return this.currentOffer.displayTitle;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", {
          displayTitle: value,
          displayTitleUppercase: value.toUpperCase(),
        });
      },
    },
    description: {
      get() {
        return this.currentOffer.description;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", { description: value });
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>

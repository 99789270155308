<template>
  <div>
    <v-row no-gutters justify="center" class="mx-4 mt-16">
      <v-col cols="12" lg="8">
        <form data-vv-scope="message">
          <v-row no-gutters class="mt-6">
            <v-col cols="6">
              <v-text-field
                name="name"
                v-model="name"
                label="Name"
                readonly
              ></v-text-field>   
              <v-text-field
                name="email"
                v-model="email"
                label="Email"
                readonly
              ></v-text-field> 
              <v-text-field
                name="company"
                v-model="company"
                label="Company"
                v-validate="'required'"
                :error-messages="errors.collect('message.company')"
              ></v-text-field> 
              <v-text-field
                name="phone"
                v-model="phone"
                label="Phone"
                v-validate="'required'"
                :error-messages="errors.collect('message.phone')"
              ></v-text-field> 
              <v-textarea
                name="message"
                v-model="message"
                label="Message"
                v-validate="'required'"
                :error-messages="errors.collect('message.message')"
              ></v-textarea>   
            </v-col>
          </v-row>
          <v-row no-gutters justify="start">
            <v-btn
              :class="orgTheme.buttonTextColor"
              @click="saveMessage('message')"
              color="primary"
              :loading="loading"
              elevation="0"
              >Send</v-btn>
          </v-row>
        </form>
      </v-col>
    </v-row>
      </div>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    messageId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    }
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data: () => ({
    dictionary: {
      custom: {
        phone: {
          required: () => 'Phone is required'
        },
        company: {
          required: () => 'Company is required'
        },
        message: {
          required: () => 'Message is required'
        },
      }
    },
    heading: 'Support Request Confirmation',
    body: 'We have received your support request. Our team will be in contact with you soon. Our support hours are 9am to 5pm Australian Eastern Time, Monday to Friday.',
    showAlert: false,
    company: '',
    phone: '',
    message: '',
    read: false,
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
    signedIn: {name: '', email:''},
  }),
  computed: {
    url () {
      return location
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    systemAdmins () {
      return this.$store.state.systemadmin.systemAdmins
    },
    email () {
      return this.$store.getters.email
    },
    name () {
      if(this.systemAdmins.length > 0) {
        return this.systemAdmins.find(admin => admin.email === this.email).name || ''
      }
      return ''
    },
  },
  methods:{
    clear () {
      this.company = ''
      this.phone = ''
      this.message = ''
      this.read = false
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.showAlert = false;
      this.$validator.reset();
    },
    handleClose () {
      this.clear()
      this.$store.dispatch('loadNewMessageCount')
      this.$emit('onClose');
    },
    saveMessage (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        this.$store.dispatch('setLoading', true)
        const id = Date.now()
        const payload = {
          from: this.email,
          name: this.name,
          ticketId: id,
          read: this.read,
          plan: this.plan || 'None selected',
          heading: this.heading,
          body: this.body,
          message: this.message,
          subject: 'Support Request #' + id,
          phone: this.phone || '',
          company: this.company || '',
          origin: 'Support',
          signedIn: {
            name: '',
            email: ''
          },
        };
        if (this.messageId) {
          // Update Message
          payload.id = this.messageId;
          this.$store.dispatch('updateMessage', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create Message
          this.$store.dispatch('createMessage', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        }
      });
    },
  },
  watch: {
    messageId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedMessage = this.messages.find(item => item.id === newVal);
          const {
            from, name, read, message, plan, subject, created, updated, createdBy, updatedBy, signedIn, origin, phone, company
          } = selectedMessage;
          this.from = from;
          this.name = name;
          this.message = message;
          this.subject = subject;
          this.read = read;
          this.plan = plan;
          this.phone = phone;
          this.company = company;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
          this.signedIn = signedIn;
          this.origin = origin;
        }
      },
    },
  },
}
</script>
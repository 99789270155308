<!--Banner-->
<template>
  <v-card class="mx-4 mb-6" dark outlined>
    <v-expansion-panels accordion flat color="transparent" dark>
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Text Content</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="titleColOne"
              label="Title"
              v-model="editingComponent.titleColOne.value"
              @input="handleChange()"
              clearable
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'titleColOne'"
              icon
              class="mt-4"
              @click="handleEditor('titleColOne')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'titleColOne'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'titleColOne'"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-text-field
              name="textColOne"
              label="Body"
              v-model="editingComponent.textColOne.value"
              @input="handleChange()"
              clearable
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'textColOne'"
              icon
              class="mt-4"
              @click="handleEditor('textColOne')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'textColOne'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'textColOne'"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Column Group</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="mb-3">
            <v-col cols="5">
              <v-icon class="icon">sync_alt</v-icon>
              <span class="setting-label">Alignment</span>
            </v-col>
            <v-icon class="icon-button" @click="setBlockHorizontal('start')"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setBlockHorizontal('center')"
              >align_horizontal_center</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setBlockHorizontal('end')"
              >align_horizontal_right</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setBlockVertical('start')"
              >align_vertical_top</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setBlockVertical('center')"
              >align_vertical_center</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setBlockVertical('end')"
              >align_vertical_bottom</v-icon
            >
          </v-row>

          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">compress</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.block.cols"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="1"
              max="12"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.cols }}/12
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate270 icon">padding</v-icon>
              <span class="setting-label">Left Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.block.padding.left"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="12"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.left }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">padding</v-icon>
              <span class="setting-label">Right Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.block.padding.right"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="12"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.right }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">padding</v-icon>
              <span class="setting-label">Top Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.block.padding.top"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="200"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.top }}px
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate180 icon">padding</v-icon>
              <span class="setting-label">Bottom Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.block.padding.bottom"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.bottom }}px
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Symbol / Icon</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row
            v-if="editingComponent.logoColOne.image"
            no-gutters
            justify="center"
          >
            <v-card color="grey lighten-2" tile elevation="0">
              <v-img
                :src="editingComponent.logoColOne.image"
                max-width="300px"
                max-height="300px"
                contain
                class="rounded outline"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-skeleton-loader
                      min-height="300px"
                      min-width="300px"
                      type="card"
                    >
                    </v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-row>

          <v-row no-gutters class="mt-4" justify="center">
            <v-btn
              @click="handleGalleryDialog(true)"
              elevation="0"
              color="primary"
            >
              <v-icon left>image_search</v-icon>Gallery
            </v-btn>
            <v-btn
              @click="handleUploadsDialog(true)"
              elevation="0"
              color="primary"
            >
              <v-icon left>image_search</v-icon>Uploads
            </v-btn>
            <design-editor-gallery :saveTo="'logoColOne'" />
            <design-editor-uploads :saveTo="'logoColOne'" />
          </v-row>

          <v-row no-gutters class="mt-4">
            <v-col cols="5">
              <span class="setting-label">Remove Logo</span>
            </v-col>
            <v-icon @click="clearLogo()" class="icon">hide_image</v-icon>
          </v-row>

          <v-row no-gutters class="mt-3">
            <v-col cols="5">
              <span class="setting-label">Position</span>
            </v-col>
            <v-icon @click="setColOneLogoPosition('top')" class="icon-button"
              >vertical_align_top</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setColOneLogoPosition('bottom')"
              >vertical_align_bottom</v-icon
            >
            <v-icon
              class="ml-2 rotate icon-button"
              @click="setColOneLogoPosition('left')"
              >vertical_align_bottom</v-icon
            >
            <v-icon
              class="ml-2 rotate270 icon-button"
              @click="setColOneLogoPosition('right')"
              >vertical_align_bottom</v-icon
            >
          </v-row>

          <v-row no-gutters class="mt-3">
            <v-col cols="5">
              <v-icon class="icon">photo_size_select_large</v-icon>
              <span class="setting-label">Size</span>
            </v-col>
            <v-slider
              v-model="editingComponent.logoColOne.height"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="20"
              max="200"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.logoColOne.height }}
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">expand</v-icon>
              <span class="setting-label">Box Height</span>
            </v-col>
            <v-slider
              v-model="editingComponent.logoColOne.boxHeight"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="300"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.logoColOne.boxHeight }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">sync_alt</v-icon>
              <span class="setting-label">Alignment</span>
            </v-col>
            <v-icon @click="setLogoHorizontal('start')" class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setLogoHorizontal('center')"
              >align_horizontal_center</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setLogoHorizontal('end')"
              >align_horizontal_right</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setLogoVertical('start')"
              >align_vertical_top</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setLogoVertical('center')"
              >align_vertical_center</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setLogoVertical('end')"
              >align_vertical_bottom</v-icon
            >
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Background</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-radio-group
              v-model="editingComponent.settings.mode"
              class="mt-n1"
              row
              @change="handleChange()"
            >
              <v-radio label="Image" value="image"> </v-radio>
              <v-radio label="Color" value="color"> </v-radio>
            </v-radio-group>
          </v-row>

          <div v-if="editingComponent.settings.mode === 'image'">
            <v-row no-gutters justify="center">
              <v-img
                :src="editingComponent.background.previewImage"
                max-width="300px"
                class="rounded outline"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-skeleton-loader
                      min-height="100px"
                      min-width="300px"
                      type="card"
                    >
                    </v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
            </v-row>

            <v-row justify="center" no-gutters class="mt-2">
              <v-btn
                @click="handleGalleryDialog(true)"
                elevation="0"
                color="primary"
              >
                <v-icon left>image_search</v-icon>Gallery
              </v-btn>
              <design-editor-gallery :saveTo="'background'" />
              <design-editor-uploads :saveTo="'background'" />
            </v-row>
          </div>

          <v-row
            v-if="editingComponent.settings.mode === 'color'"
            no-gutters
            align="center"
            class="mt-1"
          >
            <v-col cols="3" class="setting-label"> Banner Color </v-col>
            <v-col>
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.settings.color.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="setting-label ml-2">
                      {{ editingComponent.settings.color.hexa }}
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.settings.color"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Height</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row class="setting-label mb-2" no-gutters>
            Banner height can be a fixed amount or a percentage of the viewers
            browser height (Viewport height)
          </v-row>
          <v-row no-gutters>
            <v-radio-group
              v-model="editingComponent.settings.heightContext"
              class="mt-n1"
              @change="handleChange()"
            >
              <v-radio label="Fixed" value="px"> </v-radio>
              <v-radio label="Variable" value="vh"> </v-radio>
            </v-radio-group>
          </v-row>

          <div v-if="editingComponent.settings.heightContext === 'px'">
            <v-row no-gutters>
              <v-col cols="5">
                <v-icon class="icon">expand</v-icon>
                <span class="setting-label">Height</span>
              </v-col>
              <v-slider
                v-model="editingComponent.heightValue.px"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="64"
                max="1000"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.heightValue.px }}px
              </v-col>
            </v-row>
          </div>

          <div v-if="editingComponent.settings.heightContext === 'vh'">
            <v-row no-gutters class="ml-1 mt-3">
              <v-col cols="5">
                <v-icon class="icon">expand</v-icon>
                <span class="setting-label">Height</span>
              </v-col>
              <v-slider
                v-model="editingComponent.heightValue.vh"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="10"
                max="150"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.heightValue.vh }}%
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="editingComponent.settings.mode === 'image'">
        <v-expansion-panel-header class="editor-subheading"
          >Extend Image</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row class="setting-label mb-0" no-gutters>
            Extend the banner image to overlap, and run behind, the main content
            area
          </v-row>
          <div v-if="editingComponent.settings.heightContext === 'px'">
            <v-row no-gutters class="ml-1 mt-3">
              <v-col cols="5">
                <v-icon class="icon">layers</v-icon>
                <span class="setting-label">Extend</span>
              </v-col>
              <v-slider
                v-model="editingComponent.overlap.px"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="1000"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.overlap.px }}px
              </v-col>
            </v-row>
          </div>
          <div v-if="editingComponent.settings.heightContext === 'vh'">
            <v-row no-gutters class="ml-1 mt-3">
              <v-col cols="5" class="setting-label">
                <v-icon class="icon">expand</v-icon>
                <span class="setting-label">Extend</span>
              </v-col>
              <v-slider
                v-model="editingComponent.overlap.vh"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="150"
                class="mt-n1"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.overlap.vh }}%
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="editingComponent.settings.mode === 'image'">
        <v-expansion-panel-header class="editor-subheading"
          >Image Overlay</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row class="setting-label" no-gutters>
            Add a screen to darken or lighten the banner image
          </v-row>
          <v-row class="setting-label mb-3" no-gutters>
            Add a gradient to blend the banner image into the body color
          </v-row>
          <v-row no-gutters>
            <v-radio-group
              v-model="editingComponent.screen.type"
              class="mt-n1"
              row
              @change="handleChange()"
            >
              <v-radio label="Screen" value="screen"> </v-radio>
              <v-radio label="Gradient" value="gradient"> </v-radio>
            </v-radio-group>
          </v-row>

          <div v-if="editingComponent.screen.type === 'gradient'">
            <v-row class="setting-label mb-n2" no-gutters>
              Gradient direction
            </v-row>
            <v-radio-group
              v-model="editingComponent.screen.direction"
              class="mt-3"
              @change="handleChange()"
            >
              <v-row no-gutters>
                <v-col>
                  <v-radio label="Top to Bottom" value="to bottom"> </v-radio>
                  <v-radio label="Bottom to Top" value="to top"> </v-radio>
                </v-col>
                <v-col>
                  <v-radio label="Left to Right" value="to right"> </v-radio>
                  <v-radio label="Right to Left" value="to left"> </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>

            <v-row no-gutters align="center" class="mt-1">
              <v-col cols="2" class="setting-label"> From </v-col>
              <v-col>
                <v-menu
                  bottom
                  nudge-bottom="25"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  max-width="300px"
                  z-index="3000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-row no-gutters align="center">
                      <v-col cols="auto">
                        <v-img
                          class="rounded outline"
                          :src="require('@/assets/checkerboard.png')"
                          height="30px"
                          width="30px"
                        >
                          <v-card
                            v-bind="attrs"
                            v-on="on"
                            elevation="0"
                            height="30px"
                            width="30px"
                            :color="editingComponent.screen.fromColor.hexa"
                          >
                          </v-card>
                        </v-img>
                      </v-col>
                      <v-col class="setting-label ml-2">
                        {{ editingComponent.screen.fromColor.hexa }}
                      </v-col>
                    </v-row>
                  </template>
                  <v-card>
                    <v-color-picker
                      @input="handleChange()"
                      dot-size="25"
                      show-swatches
                      mode="hexa"
                      swatches-max-height="200"
                      v-model="editingComponent.screen.fromColor"
                    ></v-color-picker>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>

            <v-row no-gutters align="center" class="mt-1">
              <v-col cols="2" class="setting-label"> To </v-col>
              <v-col>
                <v-menu
                  bottom
                  nudge-bottom="25"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  max-width="300px"
                  z-index="3000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-row no-gutters align="center">
                      <v-col cols="auto">
                        <v-img
                          class="rounded outline"
                          :src="require('@/assets/checkerboard.png')"
                          height="30px"
                          width="30px"
                        >
                          <v-card
                            v-bind="attrs"
                            v-on="on"
                            elevation="0"
                            height="30px"
                            width="30px"
                            :color="editingComponent.screen.toColor.hexa"
                          >
                          </v-card>
                        </v-img>
                      </v-col>
                      <v-col class="setting-label ml-2">
                        {{ editingComponent.screen.toColor.hexa }}
                      </v-col>
                    </v-row>
                  </template>
                  <v-card>
                    <v-color-picker
                      @input="handleChange()"
                      dot-size="25"
                      show-swatches
                      mode="hexa"
                      swatches-max-height="200"
                      v-model="editingComponent.screen.toColor"
                    ></v-color-picker>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-4">
              <v-col cols="5">
                <v-icon class="icon">gradient</v-icon>
                <span class="setting-label">Size</span>
              </v-col>
              <v-slider
                v-model="editingComponent.screen.height"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="1"
                max="100"
                class="mt-n1"
                color="primary"
              ></v-slider>
              <v-col cols="auto" class="setting-label ml-1 mr-1">
                {{ editingComponent.screen.height }}%
              </v-col>
            </v-row>
          </div>

          <v-row
            v-if="editingComponent.screen.type === 'screen'"
            no-gutters
            align="center"
            class="mt-1"
          >
            <v-col cols="3" class="setting-label"> Screen Color </v-col>
            <v-col>
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.screen.color.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="setting-label ml-2">
                      {{ editingComponent.screen.color.hexa }}
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.screen.color"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Container</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">height</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.settings.cols"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="1"
              max="12"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.settings.cols }}/12
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row justify="end" class="mt-0 mr-2 mb-2">
      <design-save-button :small="true"></design-save-button>
    </v-row>
  </v-card>
</template>

<script>
import DesignEditorGallery from "../Shared/Gallery/Gallery.vue";
import DesignEditorUploads from "../Shared/Uploads/Uploads.vue";

export default {
  components: {
    DesignEditorGallery,
    DesignEditorUploads,
  },
  data: () => {
    return {
      galleryDialog: false,
      uploadsDialog: false,
    };
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    editingComponent() {
      return this.$store.getters.editingComponent;
    },
    editingField() {
      return this.$store.getters.editingField || {};
    },
  },
  methods: {
    handleChange() {
      this.$store.dispatch("setHasWebThemePendingUpdates", true);
    },
    handleGalleryDialog(val) {
      this.$store.dispatch("setGalleryDialog", val);
    },
    handleUploadsDialog(val) {
      this.$store.dispatch("setUploadsDialog", val);
    },
    handleEditor(field) {
      this.clearFieldEditors();
      let selected = {};
      console.log(field);
      if (field === "intro") {
        selected = this.editingComponent.intro;
        console.log(selected);
      } else if (field === "textColOne") {
        selected = this.editingComponent.textColOne;
        console.log(selected);
      } else if (field === "titleColOne") {
        selected = this.editingComponent.titleColOne;
        console.log(selected);
      } else {
        return;
      }
      this.$store.dispatch("editingField", selected);
    },
    clearFieldEditors() {
      this.$store.dispatch("editingField", {});
    },
    setBlockHorizontal(val) {
      this.editingComponent.block.justify = val;
      this.handleChange();
    },
    setBlockVertical(val) {
      this.editingComponent.block.align = val;
      this.handleChange();
    },
    setLogoHorizontal(val) {
      this.editingComponent.logoColOne.justify = val;
      this.handleChange();
    },
    setLogoVertical(val) {
      this.editingComponent.logoColOne.align = val;
      this.handleChange();
    },
    setColOneLogoPosition(val) {
      this.editingComponent.logoColOne.placement = val;
      this.handleChange();
    },
    clearLogo() {
      this.editingComponent.logoColOne.image = "";
      this.editingComponent.logoColOne.previewImage = "";
      this.handleChange();
    },
  },
};
</script>

<style></style>

<template>
  <v-container fluid fill-height class="gradient-background">
    <v-row>
      <v-col align="center">
        <v-card max-width="400px" class="el">
          <v-form @submit.prevent="onRequest">
            <v-row  justify="center" class="pb-8 pt-14">
              <v-img
              :src="require('@/assets/stacked-no-background-50.png')"
              max-width="149px"

            ></v-img>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-card-title class="page-heading">
                Forgot Password
              </v-card-title>
            </v-row>
            <v-card-text class="px-16">
              <v-text-field
                v-validate="'required|email'"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                v-model="email"
                id="email"
                label="Email"
                type="email"
              ></v-text-field>
            </v-card-text>
            <v-row no-gutters class="px-16" v-if="error">
              <app-alert @dismissed="onDismissed" :text="error.message"></app-alert>
            </v-row>
            <v-card-actions class="align-center justify-center px-16">
              <v-btn
                type="submit"
                color="primary"
                block
                large
                :loading="loading"
                elevation="0"
                >
                  Send Password Reset Link
              </v-btn>
            </v-card-actions>
            <v-card-actions class="align-center justify-center pb-6">
              <v-btn to="/" text color="primary">Go to Sign In</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
  export default {
    $_veeValidate: {
      validator: 'new'
    },
    created () {
      this.$store.dispatch('clearError')
    },
    data: () => {
      return {
        email: '',
        dictionary: {
          custom: {
            email: {
              required: () => 'Email is required'
            }
          }
        }
      }
    },
    mounted () {
      this.$validator.localize('en', this.dictionary)
    },
    computed: {
      user () {
        return this.$store.getters.user
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },
    methods: {
      onRequest () {
        this.$validator.validateAll().then(result => {
          if (!result) {
            console.log('Not Valid')
          }
          if (result) {
            this.$store.dispatch('forgotPassword', {email: this.email})
          }
        })
        
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      }
    },
    props: {
      source: String
    }
  }
</script>

<style>

</style>

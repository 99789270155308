/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  uploads: [],
  uploadsDialog: false,
};

const actions = {
  async loadUploads({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("uploads")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const uploads = [];
    querySnapshot.forEach(function (doc) {
      uploads.push({
        id: doc.id,
        smallUrl: doc.data().smallUrl,
        mediumUrl: doc.data().mediumUrl,
        largeUrl: doc.data().largeUrl,
        smallStoragePath: doc.data().smallStoragePath,
        mediumStoragePath: doc.data().mediumStoragePath,
        largeStoragePath: doc.data().largeStoragePath,
      });
    });
    commit("setUploads", uploads);
    commit("setLoadingTable", false);
  },

  async createImageUpload({ commit, getters, dispatch }, payload) {
    var uploadsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("uploads")
      .doc();

    const image = {
      id: uploadsRef.id,
      name: payload.name,
      smallUrl: payload.smallUrl,
      mediumUrl: payload.mediumUrl,
      largeUrl: payload.largeUrl,
      smallStoragePath: payload.smallStoragePath,
      mediumStoragePath: payload.mediumStoragePath,
      largeStoragePath: payload.largeStoragePath,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };
    uploadsRef.set(image);
    commit("createImageUpload", image);
    dispatch("setLoading", false);
    commit("setSnackbar", "Image added");
    return "ok";
  },

  async deleteImageUpload({ commit, getters }, payload) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("uploads")
        .doc(payload.id)
        .delete();
    } catch (e) {
      throw "error when deleting an image";
    }
    commit("deleteImageUpload", payload.id);
    commit("setSnackbar", "Image deleted");
  },

  // Note: This is only updating the store, not persisting with db
  patchCurrentUploads({ commit }, payload) {
    commit("patchCurrentUploads", payload);
  },

  setUploadsDialog({ commit }, payload) {
    commit("setUploadsDialog", payload);
  },
};

const mutations = {
  setUploads(state, payload) {
    state.uploads = payload;
  },

  setUploadsDialog(state, payload) {
    state.uploadsDialog = payload;
  },

  createImageUpload(state, payload) {
    state.uploads = [...state.uploads, payload];
  },

  updateImageUpload(state, payload) {
    state.uploads = state.uploads.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  patchCurrentUploads(state, payload) {
    state.currentUploads = {
      ...state.currentUploads,
      ...payload,
    };

    state.pendingUploadsUpdates = {
      ...state.pendingUploadsUpdates,
      ...payload,
    };
  },
  hasUploadsPendingUpdates(state) {
    return Object.keys(state.pendingUploadsUpdates).length !== 0;
  },

  deleteImageUpload(state, payload) {
    state.uploads = state.uploads.filter((item) => item.id !== payload);
  },
};

const getters = {
  uploads(state) {
    return state.uploads;
  },

  uploadsDialog(state) {
    return state.uploadsDialog;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-container fluid class="page">
    <!-- Heading -->
    <v-row class="page-heading" no-gutters>{{ pageTitle }}</v-row>
    <v-row class="incentable-page-subheading" no-gutters>{{ pageSubTitle }}</v-row>
      
     <v-tabs>
      <v-tab class="tab">
        Company Reports
      </v-tab>

      <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
        <company-tables-report></company-tables-report>
      </v-tab-item>

    </v-tabs>

  </v-container>
</template>

<script>


 export default {

  data: () => ({
    pageTitle: 'Reports',
    pageSubTitle: 'Your program reports',
    isProcessing: false,
  }),

  computed: {
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
  },

  created () {
    this.$store.dispatch('initialize')
    this.$store.dispatch('loadTables')
    this.$store.dispatch('loadCompanies')
    this.$store.dispatch('setNavMenu', true)
  },

  methods: {
    handleInvite() {
      this.isProcessing = true;
      this.$store.dispatch('inviteAllPending')
        .then(() => this.isProcessing = false)
        .catch(() => this.isProcessing = false)
    },
  }

}
</script>

<style scoped>
.pad-spin {
  padding-top: 2.24em
}

</style>

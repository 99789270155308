<template>
  <v-container v-if="loadingCurrentTable" fluid fill-height>
    <v-row>
      <v-col align="center">
        <v-progress-circular
          :size="100"
          :width="8"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else fluid class="page">
    <v-toolbar dense elevation="0">
      <v-row no-gutters justify="start" align="center">
        <v-col>
          <v-tabs v-model="tab" class="mx-n4">
            <v-tab class="tab"> Builder </v-tab>
            <v-tab class="tab"> Data </v-tab>
            <v-tab class="tab"> Card </v-tab>
            <v-tab class="tab"> Titles </v-tab>
            <v-tab class="tab"> Tags </v-tab>
            <v-tab class="tab"> Other </v-tab>
          </v-tabs>
        </v-col>
        <v-col>
          <span class="float-right page-title mr-6">{{
            currentTable.title
          }}</span>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-row no-gutters justify="start" class="mt-5">
      <v-col cols="12" lg="8">
        <v-alert
          :value="noDataSource"
          color="primary"
          border="left"
          text
          class="mx-3 incentable-alert"
        >
          <v-icon color="primary" class="mx-2" size="26">warning</v-icon>
          No data source connected. On the Data tab, select a Data Bucket or
          upload a CSV file, then set the Data Key
        </v-alert>
        <v-alert
          :value="invalid"
          color="primary"
          border="left"
          text
          class="mx-3 incentable-alert"
        >
          <v-icon color="primary" class="mx-2" size="26">warning</v-icon>
          Invalid entry... Both <strong>Title</strong> and
          <strong>Display Title</strong> are required
        </v-alert>
        <v-tabs-items v-model="tab">
          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <table-header />
            <table-columns />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <table-data />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <table-card />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <table-titles />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <table-tags />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <table-settings />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <div class="sticky-footer">
      <v-btn
        color="primary"
        :disabled="
          (!hasTablePendingUpdates && !hasTableRawPendingUpdates) || demo
        "
        :loading="isProcessing"
        @click="handleSave('table')"
        elevation="0"
      >
        save table
      </v-btn>
      <span v-if="currentTable" class="incentable-form-subheading ml-3">
        Last Saved {{ currentTable.updated | date }}
      </span>
    </div>
  </v-container>
</template>

<script>
import TableHeader from "./Components/Header.vue";
import TableColumns from "./Components/Columns.vue";
import TableCard from "./Components/Card.vue";
import TableData from "./Components/Data.vue";
import TableSettings from "./Components/Settings.vue";
import TableTitles from "./Components/Titles.vue";
import TableTags from "./Components/Tags.vue";
export default {
  components: {
    TableHeader,
    TableColumns,
    TableCard,
    TableData,
    TableSettings,
    TableTitles,
    TableTags,
  },
  data: function () {
    return {
      invalid: false,
      isProcessing: false,
      tab: null,
    };
  },
  created() {
    const tableId = this.$route.params.tableId;
    this.$store.dispatch("initialize");
    this.$store.dispatch("setNavMenu", true);
    this.$store.dispatch("loadMemberTags");
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadActiveDatabuckets");
    this.$store.dispatch("loadCurrentTable", tableId);
  },
  computed: {
    noDataSource() {
      if (this.currentTable.accountKeyColumn) {
        return false;
      }
      return true;
    },

    demo() {
      return this.$store.state.program.currentProgram.demo;
    },

    currentTable() {
      return this.$store.state.table.currentTable;
    },

    loadingCurrentTable() {
      return this.$store.state.table.loadingCurrentTable;
    },

    hasTablePendingUpdates() {
      return this.$store.getters.hasTablePendingUpdates;
    },

    hasTableRawPendingUpdates() {
      return this.$store.getters.hasTableRawPendingUpdates;
    },
  },

  methods: {
    handleSave() {
      if (!this.currentTable.title || !this.currentTable.displayTitle) {
        this.invalid = true;
        return;
      }
      this.invalid = false;
      this.isProcessing = true;
      this.$store.dispatch("updateTable").then(() => {
        this.isProcessing = false;
      });
    },
  },
};
</script>

<style scoped>
.sticky-footer {
  position: fixed;
  background: transparent;
  bottom: 0px;
  margin-bottom: 20px;
  margin-left: 30px;
  z-index: 1000 !important;
}
</style>

<template>
  <div>
    <v-row no-gutters justify="center">
      <v-col cols="12">
        <v-row no-gutters>
          <v-col>
            <v-tabs v-model="tab">
              <v-tab class="tab" @click="clearSearch()">Leaderboards</v-tab>
              <v-tab class="tab" @click="clearSearch()">Inactive</v-tab>
              <v-tab class="tab" @click="clearSearch()">Archive</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              class="mt-3 mr-3 caption"
              v-model="search"
              solo-inverted
              dense
              flat
              hide-details
              prepend-inner-icon="search"
              clearable
              @click:clear="clearSearch()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters justify="start">
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <loading-cards v-if="loadingCards" :count="cardCount" />
            <leaderboards-list
              :leaderboards="filteredLeaderboards"
              status="Active"
            ></leaderboards-list>
          </v-tab-item>
          <v-tab-item>
            <loading-cards v-if="loadingCards" :count="cardCount" />
            <leaderboards-list
              :leaderboards="filteredLeaderboards"
              status="Inactive"
            ></leaderboards-list>
          </v-tab-item>
          <v-tab-item>
            <loading-cards v-if="loadingCards" :count="cardCount" />
            <leaderboards-list
              :leaderboards="filteredLeaderboards"
              status="Archive"
            ></leaderboards-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- Loading Cards Table-->
  </div>
</template>
<script>
import LeaderboardsList from "./List.vue";
export default {
  components: {
    LeaderboardsList,
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadLeaderboards");
    this.$store.dispatch("loadLeaderboardsRaw");
    this.$store.dispatch("loadDatabuckets");
    this.$store.dispatch("setNavMenu", true);
  },
  data: function () {
    return {
      search: null,
      tab: null,
    };
  },
  computed: {
    programId() {
      return this.$store.getters.programId;
    },
    cardCount() {
      return 6;
    },
    loadingCards() {
      return this.$store.getters.loadingCards;
    },
    leaderboards() {
      return this.$store.state.leaderboard.leaderboards;
    },
    filteredLeaderboards() {
      const array = this.leaderboards;
      if (this.search !== null) {
        var input = this.search.toUpperCase();
        var filtered = array.filter((item) =>
          item.titleUppercase.includes(input)
        );
        return filtered;
      }
      return array;
    },
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
  },
};
</script>

<style scoped>
.databucket-name {
  padding-left: 6px;
  color: grey !important;
  font-size: 0.85em;
}
</style>

<template>
  <div>
    <v-app-bar
      class="page-bar"
      dense
      fixed
      color="grey lighten-4"
      elevate-on-scroll
    >
      <v-row no-gutters justify="center" class="mx-0">
        <v-col cols="12" lg="8">
          <span class="page-heading">Support request</span>
        </v-col>
      </v-row>
    </v-app-bar>
      <SubmitSupportTicket />
  </div>
</template>

<script>
import SubmitSupportTicket from './SupportMessageNew.vue'
export default {
  components: { SubmitSupportTicket },
  data: () => ({
  }),
  created () {
    this.$store.commit('setNavMenu', false)
  },
  computed: {
  },
  methods: {
  },
}
</script>

<style>

</style>
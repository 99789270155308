<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="message">
        <!--
        <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="70" color="primary">email</v-icon>
        </v-avatar>
      </v-row>
      -->
      <v-row no-gutters justify="end">
        <v-btn @click="handleClose()" icon><v-icon>close</v-icon></v-btn>
      </v-row>
      <v-row v-if="!isSent" no-gutters justify="center">
        <v-icon color="primary" size="80" class="mb-6">email</v-icon>
      </v-row>
      <v-card-text v-if="!isSent" class="px-10 mt-n6">
        <v-row no-gutters class="price-title" justify="center">
          {{ title }}
        </v-row>
        <v-row no-gutters class="price-subtitle mb-6" justify="center">
          {{ subtitle }}
        </v-row>
        <v-row no-gutters>
          <v-text-field label="Name" dense outlined v-model="selectedName"></v-text-field>
          <v-text-field label="Email" dense outlined v-model="selectedEmail"></v-text-field>
          <v-text-field label="Company" dense outlined v-model="company"></v-text-field>
          <v-text-field label="Phone" dense outlined v-model="phone"></v-text-field>
        </v-row>
        <v-row no-gutters>
          <v-textarea
            class="mt-1 mb-n2"
            outlined
            name="message"
            v-model="message"
            label="Message"
            :placeholder="prompt"
            v-validate="'required'"
            :error-messages="errors.collect('message.message')"
          ></v-textarea>  
        </v-row>
      </v-card-text>
      <v-card-text v-else  class="px-10 py-16">
        <v-row no-gutters class="page-heading" justify="center">
          Sent
        </v-row>
        <v-row no-gutters class="mb-6 mt-2" justify="center">
          <v-icon color="green" size="80">task_alt</v-icon>
        </v-row>
      </v-card-text>

      <v-card-actions class="pr-4">
        <v-btn
          v-if="!isSent"
          block
          :class="orgTheme.buttonTextColor"
          @click="sendMessage('message')"
          color="primary"
          :loading="loading"
          elevation="0"
          >Send message</v-btn>
      </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  props: [ 'open', 'subject', 'plan', 'title', 'subtitle'],
  mounted () {
    this.$validator.localize('en', this.dictionary)
    this.selectedName = this.name
    this.selectedEmail = this.from
  },
  data: () => ({
    dictionary: {
      custom: {
        message: {
          required: () => 'Message is required'
        },
      }
    },
    read: false,
    message: '',
    company: '',
    phone: '',
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
    sent: false,
    selectedName: '',
    selectedEmail: '',
    signedIn: {}
  }),
  computed: {
    prompt () {
      return 'Please provide me with more information about the ' + this.plan + ' plan.'
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    messages () {
      return this.$store.state.message.messages
    },
    from () {
      return this.$store.getters.email
    },
    name () {
      return this.$store.getters.name
    },
    isSent() {
      if (this.sent === true && this.loading === false) {
        return true
      }
      return false
    }
  },
  methods:{
    clear () {
      this.message = ''
      this.read = false
      this.company = ''
      this.phone = ''
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.selectedName = this.name
      this.selectedEmail = this.from
      this.signedIn = {}
      this.showAlert = false;
      this.$validator.reset();
    },
    handleClose () {
      this.clear()
      this.$store.dispatch('loadNewMessageCount')
      this.$emit('onClose');
      this.sent = false
      this.$store.dispatch('setLoading', false)
    },
    sendMessage (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        this.$store.dispatch('setLoading', true)
        const payload = {
          from: this.selectedEmail,
          name: this.selectedName,
          company: this.company,
          phone: this.phone,
          read: false,
          plan: this.plan,
          message: this.message,
          subject: this.subject,
          location: location.href,
          signedIn: {
            email: this.from,
            name: this.name
          }
        }
        this.$store.dispatch('createMessage', payload)
        this.sent = true
      })
    },
  },
}
</script>

<style scoped>
.price-subtitle {
  font-family: 'Roboto', sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #4E4E4E !important;
}
.price-title {
  font-family: 'Roboto', sans-serif !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #4E4E4E !important;
}
</style>
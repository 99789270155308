export const QUIZZES_DEFAULTS = {
  title: {
    align: "start",
    boxHeight: 40,
    color: "#E91E63FF",
    cols: 12,
    field: "title",
    floatText: "text-left",
    font: "Lato, sans-serif",
    justify: "start",
    lineHeight: 1.1,
    marginLeft: 50,
    marginRight: 0,
    size: 22,
    style: "normal",
    value: "Bonus Points Quizzes",
    weight: "normal",
  },
  heading: {
    align: "start",
    boxHeight: 30,
    color: "#E91E63FF",
    cols: 12,
    field: "heading",
    floatText: "text-left",
    font: "Roboto, sans-serif",
    justify: "start",
    lineHeight: 2,
    marginLeft: 50,
    marginRight: 0,
    size: 18,
    style: "normal",
    value: "heading",
    weight: "normal",
  },
  quizTitle: {
    align: "end",
    boxHeight: 80,
    color: "#FFFFFFFF",
    cols: 12,
    field: "quizTitle",
    floatText: "text-left",
    font: "Work Sans, sans-serif",
    justify: "start",
    lineHeight: 1.2,
    marginLeft: 0,
    marginRight: 0,
    size: 20,
    style: "normal",
    value: "quizTitle",
    weight: "normal",
  },
  deadline: {
    align: "start",
    color: "#191919FF",
    field: "deadline",
    floatText: "text-left",
    font: "Arial, sans-serif",
    justify: "start",
    size: 12,
    boxHeight: 8,
    lineHeight: 0.9,
    marginLeft: 0,
    marginRight: 0,
    cols: 12,
    style: "normal",
    value: "Deadline",
    weight: "normal",
  },
  points: {
    align: "start",
    boxHeight: 26,
    color: "#FFFFFFFF",
    cols: 12,
    field: "points",
    floatText: "text-left",
    font: "Work Sans, sans-serif",
    justify: "start",
    lineHeight: 1.2,
    marginLeft: 0,
    marginRight: 0,
    size: 16,
    style: "normal",
    value: "points",
    weight: "normal",
  },
  card: {
    icon: {
      marginLeft: 145,
      marginTop: 5,
      size: 105,
      opacity: 55,
    },
    width: 250,
    height: 150,
    borderSize: 0,
    borderColor: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
    elevation: 6,
    rounded: "rounded-lg",
    color: {
      type: "gradient",
      solidColor: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientFrom: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientTo: {
        alpha: 1,
        hex: "#94143F",
        hexa: "#94143FFF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148,
        },
      },
      direction: "to bottom",
      gradientHeight: 100,
    },
    padding: {
      top: 0,
      bottom: 0,
      left: 12,
      right: 0,
    },
    margin: {
      top: 0,
      bottom: 15,
      left: 0,
      right: 20,
    },
    group: {
      cols: 12,
      slider: "slide",
      dark: true,
      justify: "start",
    },
  },
  settings: {
    cols: 11,
  },
  block: {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  dialog: {
    quizTitle: {
      align: "end",
      boxHeight: 8,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dialogQuizTitle",
      floatText: "text-left",
      font: "Work Sans, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 20,
      style: "normal",
      value: "Quiz Title",
      weight: "normal",
    },
    deadline: {
      align: "start",
      color: "#ffffff",
      field: "dialogDeadline",
      floatText: "text-left",
      font: "Arial, sans-serif",
      justify: "start",
      size: 11,
      boxHeight: 10,
      lineHeight: 1.2,
      marginLeft: 30,
      marginRight: 0,
      cols: 12,
      style: "normal",
      value: "Deadline",
      weight: "normal",
    },
    introduction: {
      align: "start",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dialogIntroduction",
      floatText: "text-left",
      font: "Work Sans, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 30,
      marginRight: 20,
      size: 14,
      style: "normal",
      value: "Introduction",
      weight: "normal",
    },
    question: {
      align: "end",
      boxHeight: 57,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dialogQuestion",
      floatText: "text-center",
      font: "Work Sans, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 16,
      style: "normal",
      value: "Question",
      weight: "normal",
    },
    answer: {
      align: "start",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dialogAnswer",
      floatText: "text-center",
      font: "Work Sans, sans-serif",
      justify: "center",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 14,
      style: "normal",
      value: "Answer",
      weight: "normal",
      dark: true,
    },
    result: {
      align: "end",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dialogResult",
      floatText: "text-center",
      font: "Work Sans, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 0,
      size: 15,
      style: "normal",
      value: "Result",
      weight: "normal",
      dark: true,
    },
    points: {
      align: "start",
      boxHeight: 10,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dialogPoints",
      floatText: "text-left",
      font: "Work Sans, sans-serif",
      justify: "start",
      lineHeight: 1.2,
      marginLeft: 0,
      marginRight: 30,
      size: 20,
      style: "normal",
      value: "points",
      weight: "normal",
    },
    content: {
      align: "center",
      heading: {
        color: "#771033FF",
        font: "Roboto, sans-serif",
        lineHeight: 1,
        size: 13,
        style: "normal",
        weight: "bold",
      },
      data: {
        color: "#FFFFFFFF",
        font: "Work Sans, sans-serif",
        lineHeight: 1,
        size: 15,
        style: "normal",
        weight: "normal",
      },
    },
    buttonText: {
      color: "white--text",
    },
    buttonColor: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
    iconSize: 30,
    rowBorderSize: 1,
    rowHoverColor: {
      alpha: 0.5,
      hex: "#EC407A",
      hexa: "#EC407A80",
      hsla: {
        a: 0.5,
        h: 339.7674418604651,
        l: 0.5882352941176471,
        s: 0.8190476190476191,
      },
      hsva: {
        a: 0.5,
        h: 339.7674418604651,
        s: 0.728813559322034,
        v: 0.9254901960784314,
        hue: 339.7674418604651,
      },
      rgba: {
        a: 0.5,
        b: 122,
        g: 64,
        r: 236,
      },
    },
    rowBorderColor: {
      alpha: 0.5,
      hex: "#EC407A",
      hexa: "#EC407A80",
      hsla: {
        a: 0.5,
        h: 339.7674418604651,
        l: 0.5882352941176471,
        s: 0.8190476190476191,
      },
      hsva: {
        a: 0.5,
        h: 339.7674418604651,
        s: 0.728813559322034,
        v: 0.9254901960784314,
        hue: 339.7674418604651,
      },
      rgba: {
        a: 0.5,
        b: 122,
        g: 64,
        r: 236,
      },
    },
    borderSize: 0,
    borderColor: {
      alpha: 1,
      hex: "#E91E63",
      hexa: "#E91E63FF",
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233,
      },
    },
    color: {
      type: "gradient",
      solidColor: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientFrom: {
        alpha: 1,
        hex: "#E91E63",
        hexa: "#E91E63FF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233,
        },
      },
      gradientTo: {
        alpha: 1,
        hex: "#94143F",
        hexa: "#94143FFF",
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148,
        },
      },
      direction: "to bottom",
      gradientHeight: 100,
    },
    width: 750,
    fullscreen: false,
    title: {
      align: "center",
      boxHeight: 120,
      color: "#FFFFFFFF",
      cols: 12,
      field: "dialogTitle",
      floatText: "text-center",
      font: "Quicksand, sans-serif",
      justify: "start",
      lineHeight: 1.5,
      marginLeft: 0,
      marginRight: 0,
      size: 40,
      style: "normal",
      value: "Pop Up Heading",
      weight: "normal",
    },
    padding: {
      top: 20,
      bottom: 20,
      right: 20,
      left: 20,
    },
    summary: {
      margin: {
        top: 0,
        bottom: 20,
        right: 30,
        left: 30,
      },
    },
    detail: {
      margin: {
        top: 0,
        bottom: 20,
        right: 30,
        left: 30,
      },
    },
  },
};

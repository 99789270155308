<template>
  <div class="px-4 pt-0">
    <v-row no-gutters justify="end" class="pr-6">
      <v-checkbox
        v-if="!redeemableDisabled"
        label="Points are redeemable"
        v-model="pointsRedeemable"
        class="ml-2"
      />
      <v-spacer></v-spacer>
      <v-btn color="primary" dark @click="addNewRule" elevation="0"
        >create new rule</v-btn
      >
      <rule-dialog
        :ruleDialogOpen="ruleDialogOpen"
        :editingItem="editingItem"
        @onCloseDialog="closeDialog"
        @onSaveRule="saveRule"
      />
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pr-8">
        <v-data-table
          :items="conditions"
          :headers="headers"
          :search="search"
          :options.sync="options"
          :loading="loadingTable"
          no-data-text="No Records..."
        >
          <template v-slot:[`item.statement`]="{ item }">
            <v-row align="center">
              <v-col cols="2" class="incentable-formula-heading primary--text">
                {{ getOpPrefix(item) }}
              </v-col>
              <v-col cols="10">
                {{ parseStatement(item.statement) }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.selectedFixed`]="{ item }">
            <v-checkbox
              class="mt-2 mb-n2"
              color="primary"
              v-model="item.selectedFixed"
              readonly
            ></v-checkbox>
          </template>
          <template v-slot:[`item.selectedVariable`]="{ item }">
            <v-checkbox
              class="mt-2 mb-n2"
              color="primary"
              v-model="item.selectedVariable"
              readonly
            ></v-checkbox>
          </template>
          <template v-slot:[`item.then`]="{ item }">
            <v-row align="center" no-gutters>
              <v-col
                class="incentable-formula-heading primary--text mr-2"
                cols="auto"
              >
                THEN
              </v-col>
              <v-col>
                <span v-if="item.selectedFixed">{{
                  parseStatement(item.result)
                }}</span>
                <span
                  v-if="item.selectedVariable && item.selectedFixed"
                  class="mx-1"
                  >+</span
                >
                <span v-if="item.selectedVariable">{{
                  parseStatement(item.dynamicPoints)
                }}</span>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom left close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link>
                  <v-list-item-title @click="editItem(item)">
                    Edit
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title @click="handleDeleteConfirmation(item)"
                    >Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <confirm-delete
      :dialogDelete="dialogDelete"
      @onConfirmDelete="confirmDelete"
      @onCancelDelete="cancelDelete"
    ></confirm-delete>
  </div>
</template>

<script>
import _ from "lodash";
import RuleDialog from "./RuleDialog.vue";
import ConfirmDelete from "./ConfirmDelete.vue";
export default {
  components: {
    RuleDialog,
    ConfirmDelete,
  },
  created() {
    this.conditions = this.pointsFormula;
  },
  data: () => {
    return {
      editingItem: null,
      editedIndex: -1,
      ruleDialogOpen: false,
      conditions: [],
      dialogDelete: false,
      search: "",
      parseList: [
        { label: "x", type: "*" },
        { label: "÷", type: "/" },
        { label: "+", type: "+" },
        { label: "-", type: "-" },
        { label: "Is Equal To", type: "===" },
        { label: "Is Greater Than Or Equal To", type: ">=" },
        { label: "Is Greater Than", type: ">" },
        { label: "Is Less Than Or Equal To", type: "<=" },
        { label: "Is Less Than", type: "<" },
        { type: "__target", label: "Target" },
        { type: "__actual", label: "Actual" },
        { type: "__diff", label: "Difference" },
        { type: "__percent", label: "Percentage" },
        { type: "target", label: "Target" },
        { type: "actual", label: "Actual" },
        { type: "diff", label: "Difference" },
        { type: "percent", label: "Percentage" },
        { type: "number", label: "Number" },
        { label: "And", type: "&&" },
        { label: "Or", type: "||" },
      ],
      // Table columns
      headers: [
        { text: "Rule", value: "statement", align: "left" },
        { text: "Fixed", value: "selectedFixed", align: "left" },
        { text: "Variable", value: "selectedVariable", align: "left" },
        { text: "Result", value: "then", align: "left" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ],

      // Table settings
      options: {
        sortBy: [],
        sortDesc: [false],
        itemsPerPage: 10,
        sortable: false,
        multiSort: false,
        search: false,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
    };
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    pointsFormula() {
      return this.$store.getters.databucketPointsFormula;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    databucket() {
      return this.$store.getters.databucket;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    changes() {
      return this.$store.getters.changes;
    },
    pointsRedeemable: {
      get() {
        return this.databucket.pointsRedeemable;
      },
      set(value) {
        this.$store.dispatch("patchDatabucket", { pointsRedeemable: value });
      },
    },
    redeemableDisabled() {
      return this.databucket.participantType !== "member";
    },
  },
  methods: {
    getOpPrefix(item) {
      const index = this.conditions.indexOf(item);
      return index === 0 ? "IF" : "ELSE IF";
    },
    editItem(item) {
      this.editedIndex = this.conditions.indexOf(item);
      this.editingItem = Object.assign({}, item);
      this.ruleDialogOpen = true;
    },
    addNewRule() {
      this.editingItem = null;
      this.ruleDialogOpen = true;
    },
    closeDialog() {
      this.editingItem = null;
      this.ruleDialogOpen = false;
      this.editedIndex = -1;
    },
    handleDeleteConfirmation(item) {
      this.editedIndex = this.conditions.indexOf(item);
      this.dialogDelete = true;
    },
    cancelDelete() {
      this.editedIndex = -1;
      this.dialogDelete = false;
    },
    confirmDelete() {
      this.conditions.splice(this.editedIndex, 1);
      const changes = [...this.changes, "result"];
      this.$store.dispatch("updateChanges", changes);
      const pointsFormula = this.conditions;
      this.$store.dispatch("patchDatabucket", { pointsFormula });
      this.dialogDelete = false;
      this.editedIndex = -1;
    },
    saveRule(cond) {
      // console.log(cond);
      this.editingItem = cond;
      if (this.editedIndex > -1) {
        Object.assign(this.conditions[this.editedIndex], this.editingItem);
      } else {
        const updatedCond = this.constructConditionStatement(this.editingItem);
        this.conditions.push({ ...updatedCond });
      }
      const pointsFormula = this.conditions.map((condition) => {
        const updatedCond = this.constructConditionStatement(condition);
        return updatedCond;
      });
      this.conditions = pointsFormula;
      const changes = [...this.changes, "result"];
      this.$store.dispatch("updateChanges", changes);
      this.$store.dispatch("patchDatabucket", { pointsFormula });
      this.editingItem = null;
      this.editedIndex = -1;
    },
    // reformat statement in plain english for readability on rules table
    parseStatement(statement) {
      let parsedStatement = statement;
      for (const item of this.parseList) {
        parsedStatement = parsedStatement.split(item.type).join(item.label);
      }
      return parsedStatement;
    },
    // take user input and combine to create a single expression to save on the condition
    constructConditionStatement(condition) {
      let conditionTxtRepr = "";
      let dynamicPointsTxtRepr = "";
      condition.subConditions.forEach((subCondition, subConditionIdx) => {
        const lo = `__${subCondition.leftOperand}`;
        const op = subCondition.operator;
        const ro =
          subCondition.rightOperand === "number"
            ? subCondition.rightOperandVal
            : `__${subCondition.rightOperand}`;
        const subConditionTxtRepr = `(${lo} ${op} ${ro})`;
        conditionTxtRepr = `${conditionTxtRepr} ${subConditionTxtRepr}`;
        if (subConditionIdx !== condition.subConditions.length - 1) {
          conditionTxtRepr = `${conditionTxtRepr} ${condition.logicalOperators[subConditionIdx]}`;
        }
        dynamicPointsTxtRepr = `(__${condition.dynamicLeftOperand} ${condition.dynamicCalc} ${condition.dynamicValue})`;
      });
      const updatedCond = {
        ...condition,
        statement: conditionTxtRepr,
        dynamicPoints: dynamicPointsTxtRepr,
      };
      return updatedCond;
    },
  },
  watch: {
    databucket(newVal, oldVal) {
      if (
        newVal.title !== oldVal.title ||
        newVal.targetCodes !== oldVal.targetCodes ||
        newVal.displayTitle !== oldVal.displayTitle ||
        newVal.status !== oldVal.status ||
        newVal.pointsRedeemable !== oldVal.pointsRedeemable
      ) {
        const changes = [...this.changes, "meta"];
        this.$store.dispatch("updateChanges", changes);
      }

      if (!_.isEqual(newVal.previewColumns, oldVal.previewColumns)) {
        const changes = [...this.changes, "result"];
        this.$store.dispatch("updateChanges", changes);
      }
    },
    pointsFormula(newVal, oldVal) {
      const equality = _.isEqual(newVal, oldVal);
      if (!equality) {
        const changes = [...this.changes, "result"];
        console.log("watcher", changes);
        this.$store.dispatch("updateChanges", changes);
      }
    },
  },
};
</script>

<style scoped></style>

/* eslint-disable no-console */
import { db } from "@/firebase";

const state = {
  memberTags: [],
  loadingMemberTags: false,
};

const actions = {
  async loadMemberTags({ commit, getters }) {
    commit("setLoadingMemberTags", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("memberTags")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const memberTags = [];
    querySnapshot.forEach(function (doc) {
      memberTags.push({
        id: doc.id,
        tag: doc.data().tag,
        tagUppercase: doc.data().tagUppercase,
      });
    });

    commit("setMemberTags", memberTags);
    commit("setLoadingMemberTags", false);
  },

  async createMemberTag({ dispatch, commit, getters }, payload) {
    const tagsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("memberTags");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await tagsRef
        .where("tagUppercase", "==", payload.tagUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the tag existency.";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Tag is already registered.";
    }

    const { id, ...tagInput } = payload; // eslint-disable-line no-unused-vars
    const tag = {
      ...tagInput,
    };

    let newTagRef;
    try {
      newTagRef = await tagsRef.add(tag);
    } catch (e) {
      throw "Error occured when creating a new tag.";
    }

    commit("createMemberTag", { ...tagInput, id: newTagRef.id });
    dispatch("setSnackbar", "Tag Created.");
  },

  async updateMemberTag({ dispatch, commit, getters }, payload) {
    const tagsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("memberTags");

    let storedTag;
    try {
      const tagDoc = await tagsRef.doc(payload.id).get();
      storedTag = tagDoc.data();
    } catch (e) {
      storedTag = null;
    }

    if (!storedTag) {
      throw "Error occured when fetching the tag info";
    }

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await tagsRef
        .where("tagUppercase", "==", payload.tagUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the tag existency.";
    }

    if (titleDupSnapshot.size > 0) {
      let duplicated = false;
      titleDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Tag is already registered.";
      }
    }

    const { id, ...tagInput } = payload;
    const tag = {
      ...tagInput,
    };

    try {
      await tagsRef.doc(id).update(tag);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a tag";
    }

    commit("updateMemberTag", payload);
    dispatch("setSnackbar", "Tag Updated.");
  },

  async deleteMemberTag({ dispatch, commit, getters }, tagId) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("memberTags")
        .doc(tagId)
        .delete();
    } catch (e) {
      throw "Error occured when deleting a tag";
    }
    commit("deleteMemberTag", tagId);
    dispatch("setSnackbar", "Tag Deleted.");
  },

  async importMemberTags({ getters }, tagsAry) {
    const newTagsBatch = db.batch();

    tagsAry.forEach((item) => {
      const data = {
        tag: item,
        tagUppercase: item.toUpperCase(),
      };
      const newTagRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("memberTags")
        .doc();
      newTagsBatch.set(newTagRef, data);
    });

    try {
      await newTagsBatch.commit();
    } catch (e) {
      throw "tags import batch failed";
    }
  },
};

const mutations = {
  setMemberTags(state, payload) {
    state.memberTags = payload;
  },

  setLoadingMemberTags(state, payload) {
    state.loadingMemberTags = payload;
  },

  createMemberTag(state, payload) {
    state.memberTags = [...state.memberTags, payload];
  },

  updateMemberTag(state, payload) {
    state.memberTags = state.memberTags.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteMemberTag(state, payload) {
    state.memberTags = state.memberTags.filter((item) => item.id !== payload);
  },
};

const getters = {
  memberTags(state) {
    return state.memberTags;
  },

  memberTagsMap(state) {
    return state.memberTags.reduce((result, item) => {
      return {
        ...result,
        [item.id]: item.tag,
      };
    }, {});
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-container class="page" fluid>
    <v-tabs>
      <v-tab class="tab"> Companies </v-tab>
      <v-tab class="tab"> Tags </v-tab>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <companies-list></companies-list>
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <company-tags-list></company-tags-list>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("setNavMenu", true);
  },
  data() {
    return {
      pageTitle: "Companies",
      pageSubTitle: "Manage your program member companies",
    };
  },
};
</script>

<style scoped></style>

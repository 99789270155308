<template>
  <v-container fluid fill-height class="gradient-background">
    <v-row justify="center">
      <v-col xs="12" sm="8" md="4" lg="3" align="center">
        <v-card class="el">
          <v-form @submit.prevent="onSignin">
            <v-row justify="center" class="pb-8 pt-14">
              <v-img
                :src="require('@/assets/stacked-no-background-50.png')"
                contain
                max-height="150px"
                max-width="150px"
              />
            </v-row>

            <v-card-text class="px-16">
              <v-text-field
                v-validate="'required|email'"
                :error-messages="errors.collect('email')"
                data-vv-name="email"
                v-model="email"
                id="email"
                label="Email"
                type="email"
                outlined
              ></v-text-field>
              <v-text-field
                v-validate="'required'"
                :error-messages="errors.collect('password')"
                data-vv-name="password"
                v-model="password"
                id="password"
                label="Password"
                :append-icon="show ? 'visibility' : 'visibility_off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                outlined
              ></v-text-field>
              <v-row row wrap align-center justify-center v-if="error">
                <app-alert
                  @dismissed="onDismissed"
                  :text="error.message"
                ></app-alert>
              </v-row>
              <v-btn
                type="submit"
                color="primary"
                block
                x-large
                :loading="loading"
                elevation="0"
                class="button-signin ml-0 mt-n1"
              >
                Sign In
              </v-btn>
            </v-card-text>

            <v-row no-gutters justify="center">
              <v-btn small to="/signup" text color="primary"
                >Not registered? Sign up here</v-btn
              >
            </v-row>
            <v-row no-gutters justify="center">
              <v-btn small to="/forgot" text color="primary"
                >Forgot Password?</v-btn
              >
            </v-row>
            <legal-links />
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LegalLinks from "../Shared/LegalLinks.vue";
export default {
  components: { LegalLinks },
  $_veeValidate: {
    validator: "new",
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadSystemAdmins");
    this.$store.commit("setNavMenu", false);
    this.$store.dispatch("setToolbar", false);
    this.$store.dispatch("setFooter", false);
    this.$store.dispatch("clearError");
    // this.$store.dispatch('loadOrganisations')
    this.$store.dispatch("checkOrganisation");
  },
  data: () => {
    return {
      host: location.host,
      email: "",
      password: "",
      message: "",
      show: false,
      dictionary: {
        custom: {
          email: {
            required: () => "Email is required",
          },
          password: {
            required: () => "Password is required",
          },
        },
      },
    };
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    organisationId() {
      return this.$store.getters.organisationId;
    },
    signinLogoUrl() {
      if (this.currentOrganisation) {
        return this.currentOrganisation.signinLogoUrl;
      }
      return "@/assets/incentable-logo.png";
    },
    subdomain() {
      return this.$store.getters.subdomain;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    systemAdmins() {
      return this.$store.state.systemadmin.systemAdmins;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push("/");
      }
    },
  },
  methods: {
    onSignin() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          // console.log('Not Valid')
        }
        if (result) {
          this.$store.dispatch("signUserIn", {
            email: this.email,
            password: this.password,
          });
        }
      });
    },
    onDismissed() {
      this.$store.dispatch("clearError");
    },
  },
  props: {
    source: String,
  },
};
</script>

<style scoped>
.button-signin {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  letter-spacing: 1px;
}
</style>

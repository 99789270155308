<template>
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa"
  >
    <v-row justify="center" no-gutters>
      <v-col xs="12" :sm="component.settings.cols">
        <v-sheet
          :height="height"
          tile
          :color="component.settings.color.hexa"
        >
          <template v-if="component.settings.mode === 'color'">
            <v-row 
              no-gutters
              :justify="component.block.justify"
              :align="component.block.align"
              class="fill-height"
            >
              <v-col xs="12" :sm="component.block.cols">
                <v-col>
                  <v-card
                    tile
                    elevation="0"
                    :class="titleBlock"
                    :style="topMargin + bottomMargin"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="auto" 
                        v-if="component.bannerLogo.placement === 'left' && component.bannerLogo.image"
                      >
                      <view-logo :settings="component.bannerLogo" />
                      </v-col>
                      <v-col>
                        <view-logo
                          v-if="component.bannerLogo.placement === 'top' && component.bannerLogo.image"
                          :settings="component.bannerLogo"
                        />
                        <view-text :settings="component.title" :value="sampleRecord.title"></view-text>
                        <view-text :settings="component.subheading" :value="sampleRecord.subheading"></view-text>
                        <view-logo
                          v-if="component.bannerLogo.placement === 'bottom' && component.bannerLogo.image"
                          :settings="component.bannerLogo"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                        v-if="component.bannerLogo.placement === 'right' && component.bannerLogo.image"
                      >
                        <view-logo :settings="component.bannerLogo" />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-col>
            </v-row>
          </template>
          
          <template v-if="component.settings.mode === 'image'">
            <v-img
              :src="component.background.image"
              :gradient="gradient"
              :height="imageHeight"
            >
              <v-row 
                no-gutters
                :justify="component.block.justify"
                :align="component.block.align"
                class="fill-height"
                
              >
                <v-col xs="12" :sm="component.block.cols">
                  <v-col>
                    <v-card
                      tile
                      elevation="0"
                      :class="titleBlock"
                      :style="topMargin + bottomMargin"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="auto" 
                          v-if="component.bannerLogo.placement === 'left' && component.bannerLogo.image"
                        >
                          <view-logo :settings="component.bannerLogo" />
                        </v-col>
                        <v-col>
                          <view-logo
                            v-if="component.bannerLogo.placement === 'top' && component.bannerLogo.image"
                            :settings="component.bannerLogo"
                          />
                          <view-text :settings="component.title" :value="sampleRecord.title"></view-text>
                          <view-text :settings="component.subheading" :value="sampleRecord.subheading"></view-text>
                          <view-logo
                            v-if="component.bannerLogo.placement === 'bottom' && component.bannerLogo.image"
                            :settings="component.bannerLogo"
                          />
                        </v-col>
                        <v-col
                          cols="auto"
                          v-if="component.bannerLogo.placement === 'right' && component.bannerLogo.image"
                        >
                          <view-logo 
                            :settings="component.bannerLogo"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-col>
              </v-row>
            </v-img>
          </template>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ViewLogo from '../Logo.vue'
import ViewText from '../Text.vue'
export default {
  components: {
    ViewLogo,
    ViewText,
  },
  props: [ 'component', 'index' ],
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    samplePages() {
      return this.$store.getters.samplePages
    },
    sampleRecord() {
      return this.samplePages[0]
    },
    gradient() {
      if (this.component.screen.type === 'gradient') {
        var screen = this.component.screen
        var gradient = 
        screen.direction + ', rgba(' +
        screen.fromColor.rgba.r + ', ' +
        screen.fromColor.rgba.g + ', ' +
        screen.fromColor.rgba.b + ', ' +
        screen.fromColor.rgba.a + ') ' + 
        '0%, rgba(' +
        screen.toColor.rgba.r + ', ' +
        screen.toColor.rgba.g + ', ' +
        screen.toColor.rgba.b + ', ' +
        screen.toColor.rgba.a + ') ' + screen.height + '%'
        return gradient
      } else if (this.component.screen.type === 'screen') {
        var screen2 = this.component.screen
        var screenColor = 
        screen2.direction + ', rgba(' +
        screen2.color.rgba.r + ', ' +
        screen2.color.rgba.g + ', ' +
        screen2.color.rgba.b + ', ' +
        screen2.color.rgba.a + '), rgba(' +
        screen2.color.rgba.r + ', ' +
        screen2.color.rgba.g + ', ' +
        screen2.color.rgba.b + ', ' +
        screen2.color.rgba.a + ')'
        return screenColor
      } else {
        return 'to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)'
      }
    },
    grid() {
      return this.$store.getters.grid
    },
    topMargin() {
      let val = ''
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': val = 45
          break
          case 'sm': val = 65
          break
          case 'md': val = this.component.block.padding.top
          break
          case 'lg': val = this.component.block.padding.top
          break
          case 'xl': val = this.component.block.padding.top
          break
          default: console.log('There was an error')
        }
        return 'padding-top: ' + val + 'px; '
    },
    bottomMargin() {
      let val = ''
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': val = 45
          break
          case 'sm': val = 65
          break
          case 'md': val = this.component.block.padding.bottom
          break
          case 'lg': val = this.component.block.padding.bottom
          break
          case 'xl': val = this.component.block.padding.bottom
          break
          default: console.log('There was an error')
        }
        return 'padding-bottom: ' + val + 'px; '
    },
    titleBlock() {
      var padding = this.component.block.padding
      return this.grid.block + 
      ' pl-md-' + padding.left +
      ' pr-md-' + padding.right +
      ' pl-sm-' + Math.ceil(padding.left/2) +
      ' pr-' + Math.ceil(padding.left/2)
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates
    },
    height() {
      if (this.component.settings.heightContext == 'px') {
        return this.component.heightValue.px + 'px'
      } else {
        return this.component.heightValue.vh + 'vh'
      }
    },
    imageHeight() {
      if (this.component.settings.heightContext == 'px') {
        return (this.component.heightValue.px + this.component.overlap.px) + 'px'
      } else {
        return (this.component.heightValue.vh + this.component.overlap.vh) + 'vh'
      }
    },
    image() {
      if (this.component.settings.mode === 'color') {
        return ''
      } else {
        return this.component.settings.image
      }
    },
    menuMargin() {
      let val = 0
      var height = this.webTheme.shared.menuHeight
      var arr = this.webTheme.header.components
      var result = arr.find(el => el.id === 'MenuBar')
      if (result && result.settings.overlap == true) { 
        val = 'margin-top: ' + height + 'px; '
      } else { 
        val = '' 
      }
      return val
    },
  },
  methods: {
    handleEditor(val) {
      this.editing = val
      if (val === 'preview') {
        this.previewCols = 11
      } else {
        this.previewCols = 8
      }
    },
    handleSave() {
      this.$store.dispatch('updateWebTheme', this.webTheme)
      this.$store.dispatch('setHasWebThemePendingUpdates', false)
      this.$store.dispatch('editingField', {})
    },
  }
}
</script>

<style scoped>
.theme-page {
  padding: 0px 10px 0px 0px !important;
}
.sticky-footer {
  position: fixed;
  background: transparent;
  bottom: 0px;
  left: 290px;
  margin-bottom: 10px;
  z-index: 1000 !important;
}
</style>


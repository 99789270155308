<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="rewardCategory">
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ rewardCategoryId ? 'Edit ' : 'Add New '}}
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-16">
        
        <v-row dense>
          <v-col>
            <v-text-field
              name="title"
              v-model="title"
              label="Reward Category"
              v-validate="'required'"
              :error-messages="errors.collect('rewardCategory.title')"
            ></v-text-field>   
          </v-col>
        </v-row>
        
        <v-row dense>
          <v-col>
            <v-alert
              type="warning"
              v-model="showAlert"
              >
              That Reward Category is already registered
            </v-alert>
          </v-col>         
        </v-row>
      
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="handleClose()"
          >Cancel</v-btn>
        <v-btn
          :class="orgTheme.buttonTextColor"
          @click="saveRewardCategory('rewardCategory')"
          color="primary"
          :loading="loading"
          elevation="0"
          >Save</v-btn>
      </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    rewardCategoryId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => 'Reward Category title is required'
        }
      }
    },
    statuses: [
      { text: 'Active', value: 'Active' },
      { text: 'Inactive', value: 'Inactive' }
    ],
    formTitle: 'Reward Category',
    formIcon: 'category',
    showAlert: false,
    title: '',
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    rewardCategories () {
      return this.$store.state.rewardcategory.rewardCategories
    },
    rewardCatalogues () {
      return this.$store.state.rewardcatalogue.rewardCatalogues
    },
    companyTags () {
      return this.$store.state.companytag.companyTags
    },
    memberTags () {
      return this.$store.state.membertag.memberTags
    },
  },
  methods:{
    clear () {
      this.title = ''
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.showAlert = false;
      this.$validator.reset();
    },
    handleClose () {
      this.clear();
      this.$emit('onClose');
    },
    saveRewardCategory (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        this.$store.dispatch('setLoading', true)
        const payload = {
          title: this.title,
          titleUppercase: this.title.toUpperCase(),
        };
        if (this.rewardCategoryId) {
          // Update rewardCategory
          payload.id = this.rewardCategoryId;
          this.$store.dispatch('updateRewardCategory', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create Reward Category
          this.$store.dispatch('createRewardCategory', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        }
      });
    },
  },
  watch: {
    rewardCategoryId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedRewardCategory = this.rewardCategories.find(item => item.id === newVal);
          const {
            title, created, updated, createdBy, updatedBy,
          } = selectedRewardCategory;
          this.title = title;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
        }
      },
    },
  },
}
</script>
/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  blogs: [],
};

const actions = {
  async loadBlogs({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("blogs")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const blogs = [];
    querySnapshot.forEach(function (doc) {
      // Add database fields here to customise for specific table
      blogs.push({
        id: doc.id,
        status: doc.data().status,
        title: doc.data().title,
        titleUppercase: doc.data().titleUppercase,
        created: doc.data().created.toDate(),
        createdBy: doc.data().createdBy,
        updated: doc.data().updated.toDate(),
        updatedBy: doc.data().updatedBy,
        memberTags: doc.data().memberTags,
        companyTags: doc.data().companyTags,
      });
    });

    commit("setBlogs", blogs);
    commit("setLoadingTable", false);
  },

  async createBlog({ commit, getters }, payload) {
    const blogsCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("blogs");

    let existingBlogResult;
    try {
      existingBlogResult = await blogsCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing blog";
    }

    if (existingBlogResult.size > 0) {
      return "duplication";
    }

    const blog = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newBlogRef;
    try {
      newBlogRef = await blogsCollection.add(blog);
    } catch (e) {
      throw "Error when creating a new blog";
    }

    // Note: server time is unavailable until we refetch.
    const tempBlog = {
      ...blog,
      id: newBlogRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createBlog", tempBlog);
    commit("setSnackbar", "Blog added");

    return "ok";
  },

  async updateBlog({ commit, getters }, payload) {
    const blogsCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("blogs");

    let existingBlogResult;
    try {
      existingBlogResult = await blogsCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing blog";
    }

    let duplicated = false;
    existingBlogResult.forEach((doc) => {
      if (doc.id !== payload.id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    const blog = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await blogsCollection.doc(payload.id).update(blog);
    } catch (e) {
      throw "Error when updating a blog";
    }

    const blogBeforeUpdate = getters.blogs.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempBlog = {
      ...blogBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updateBlog", tempBlog);
    commit("setSnackbar", "Blog updated");

    return "ok";
  },

  async deleteBlog({ commit, getters }, id) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("blogs")
        .doc(id)
        .delete();
    } catch (e) {
      throw "Error when deleting an blog";
    }
    commit("deleteBlog", id);
    commit("setSnackbar", "Blog deleted");
  },
};

const mutations = {
  setBlogs(state, payload) {
    state.blogs = payload;
  },

  createBlog(state, payload) {
    state.blogs = [...state.blogs, payload];
  },

  updateBlog(state, payload) {
    state.blogs = state.blogs.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteBlog(state, payload) {
    state.blogs = state.blogs.filter((item) => item.id !== payload);
  },
};

const getters = {
  blogs(state) {
    return state.blogs;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

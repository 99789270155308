<template>
  <div class="ml-n3">
    <v-row no-gutters>
      <v-col>
        <v-tabs v-model="tab">
          <v-tab v-for="(page, index) in pages" :key="index" class="tab">
            Page {{ index + 1 }}
          </v-tab>
          <v-btn fab @click="addPage()" x-small elevation="0" class="mt-2"
            ><v-icon color="primary">add</v-icon></v-btn
          >
          <v-btn
            text
            v-if="length === 0"
            class="add-note"
            @click="addPage()"
            color="primary"
            >Add a page to begin</v-btn
          >
        </v-tabs>
      </v-col>
      <v-col cols="auto">
        <v-btn :disabled="!pages.length > 1" text @click="deletePage()">
          Delete Page
          {{ tab > 0 ? tab + 1 : "" }}
        </v-btn>
      </v-col>
    </v-row>

    <v-stepper v-if="showStepper" :value="tab + 1" class="mb-n4">
      <v-stepper-header :class="editingTitleIndex !== -1 ? 'mt-n4' : ''">
        <template v-for="(page, n) in pages">
          <v-stepper-step
            :key="`${n + 1}-step`"
            :complete="tab > n"
            :step="n + 1"
            color="secondary"
          >
            <div
              v-if="page.title && editingTitleIndex !== n"
              @click="editStepTitle(n)"
              class="pointer"
            >
              {{ page.title }}
            </div>

            <div
              v-if="editingTitleIndex !== n && page.title === ''"
              @click="editStepTitle(n)"
            >
              Page {{ n + 1 }}
            </div>
            <v-row no-gutters v-if="editingTitleIndex === n" align="center">
              <v-text-field
                v-model="editingTitle"
                dense
                class="pt-2"
              ></v-text-field>
              <v-btn
                @click="saveStepTitle(n)"
                icon
                small
                color="green"
                class="mr-n2"
                ><v-icon>check_circle_outline</v-icon></v-btn
              >
              <v-btn @click="cancelEditStepTitle(n)" icon small color="red"
                ><v-icon>highlight_off</v-icon></v-btn
              >
            </v-row>
          </v-stepper-step>

          <v-divider
            v-if="n + 1 !== length && editingTitleIndex !== n"
            :key="n + 1"
          ></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(page, index) in pages" :key="index">
        <draggable
          v-model="page.sections"
          class="w-100 dragArea"
          :group="{ name: 'sections', pull: true, put: true }"
          ghost-class="ghost"
          :move="handleMove"
        >
          <Sections
            :sections="page.sections"
            :pageIdx="index"
            :showSubmit="showSubmit"
            :showBack="showBack"
            @onBackPage="backPage"
            @onNextPage="nextPage"
          ></Sections>
        </draggable>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import { nanoid } from "nanoid";
import { nanoid } from "nanoid";
import Sections from "./Sections.vue";

export default {
  components: { Sections },
  props: {
    pages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    tab: null,
    e1: 1,
    editingTitleIndex: -1,
    editingTitle: "",
  }),
  created() {},
  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    length() {
      // Return the number of pages, defaulting to 1 if pages are missing
      return this.pages.length || 0;
    },
    showStepper() {
      if (this.currentOffer.form && this.currentOffer.form.stepper) {
        return this.currentOffer.form.stepper;
      } else {
        return false;
      }
    },
    showSubmit() {
      if (this.tab + 1 === this.pages.length) {
        return true;
      }
      return false;
    },
    showBack() {
      if (this.tab === 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    handleMove(event) {
      const itemMovedGroup = event.draggedContext.element.group;
      const destinationGroup = event.relatedContext.component.$attrs.group.name;
      if (itemMovedGroup === destinationGroup) {
        this.patchCurrentOffer();
        return true;
      } else {
        return false;
      }
    },
    patchCurrentOffer() {
      const update = {
        form: this.currentOffer.form,
      };
      this.$store.dispatch("patchCurrentOffer", update);
    },
    addPage() {
      // Initialize form object and pages array if missing
      if (!this.currentOffer.form) {
        this.$set(this.currentOffer, "form", { pages: [] });
      }
      if (!this.currentOffer.form.pages) {
        this.$set(this.currentOffer.form, "pages", []);
      }
      if (!this.currentOffer.form.stepper) {
        this.$set(this.currentOffer.form, "stepper", false);
      }
      this.currentOffer.form.pages.push({
        title: "",
        sections: [
          {
            idx: nanoid(),
            columns: [{ items: [] }],
            group: "sections",
            icon: "table_chart",
            label: "1 Column Section",
            type: "section",
          },
        ],
      });
      this.patchCurrentOffer();
    },
    deletePage() {
      // Ensure pages array exists
      if (
        this.length > 1 &&
        this.currentOffer.form &&
        this.currentOffer.form.pages
      ) {
        this.currentOffer.form.pages.splice(this.tab, 1);
        this.tab = Math.max(0, this.tab - 1); // Adjust the tab index if necessary
        this.patchCurrentOffer();
      }
    },
    editStepTitle(index) {
      this.editingTitleIndex = index;
      this.editingTitle = this.currentOffer.form.pages[index].title;
    },
    saveStepTitle(index) {
      this.currentOffer.form.pages[index].title = this.editingTitle;
      this.editingTitle = "";
      this.editingTitleIndex = -1;
      this.patchCurrentOffer();
    },
    cancelEditStepTitle() {
      this.editingTitle = "";
      this.editingTitleIndex = -1;
    },
    backPage() {
      this.tab--;
    },
    nextPage() {
      this.tab++;
    },
  },
  watch: {},
};
</script>

<style scoped>
.handle {
  cursor: pointer;
}
.ghost {
  border: 2px dotted rgb(134, 134, 134);
  height: 50px;
}
.w-100 {
  width: 100%;
}
.dropzone-card {
  background-color: #ececec !important;
}
.dragArea {
  min-height: 600px;
  padding-bottom: 50px;
}
.handle {
  cursor: pointer;
}

.add-note {
  margin-top: 5px;
  margin-left: -4px;
}
.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
</style>

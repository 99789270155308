<template>
  <v-dialog v-model="open" :width="member ? '1200px' : '450px'" persistent>
    <v-card>
      <form @submit.prevent="handleSave" data-vv-scope="entry">
        <v-row no-gutters>
          <v-card-title class="page-heading mb-n3">
            {{ entryId ? "Review Claim" : "Add New Claim" }}
          </v-card-title>
        </v-row>

        <v-card-text class="px-9 pt-0">
          <v-row :no-gutters="member ? false : true" class="mt-n3">
            <v-col :cols="member ? '4' : '12'">
              <v-select
                :items="opportunities"
                v-model="opportunity"
                item-text="description"
                item-value="id"
                label="Opportunity"
                v-validate="'required'"
                :error-messages="errors.collect('entry.opportunity')"
                data-vv-name="opportunity"
                :readonly="!!id"
              />
            </v-col>
            <v-col :cols="member ? '4' : '12'">
              <v-text-field
                :value="formatDisplayDate(deadline)"
                label="Deadline"
                readonly
                prepend-icon="insert_invitation"
              ></v-text-field
            ></v-col>
            <v-col v-if="member" :cols="member ? '4' : '12'">
              <v-row no-gutters align="center">
                <status-icon :status="status"></status-icon>
                <v-text-field
                  v-model="status"
                  label="Status"
                  name="status"
                  readonly
                  class="ml-3"
                />
              </v-row>
            </v-col>
          </v-row>

          <v-row :no-gutters="member ? false : true" class="mt-n5">
            <v-col :cols="member ? '4' : '12'">
              <v-autocomplete
                :items="members"
                v-model="member"
                item-text="fullname"
                item-value="id"
                label="Member"
                v-validate="'required'"
                :error-messages="errors.collect('entry.member')"
                data-vv-name="member"
                :readonly="!!id"
              />
            </v-col>
            <v-col :cols="member ? '4' : '12'">
              <v-menu
                v-model="submittedMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatDisplayDate(submitted)"
                    label="Submitted"
                    readonly
                    v-on="on"
                    prepend-icon="event_note"
                    @click:clear="submitted = null"
                    v-validate="'required'"
                    :error-messages="errors.collect('entry.submitted')"
                    data-vv-name="submitted"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="submitted"
                  @input="submittedMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col :cols="member ? '4' : '12'">
              <v-text-field
                :value="formatDisplayDate(reviewed)"
                label="Reviewed"
                prepend-icon="event_available"
                readonly
              ></v-text-field
            ></v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-card
                  v-for="(item, index) in resources"
                  :key="index"
                  height="100px"
                  width="100px"
                  class="mr-2 mb-2 grey lighten-4"
                  flat
                >
                  <v-row
                    no-gutters
                    justify="end"
                    align="center"
                    style="height: 10px"
                  >
                    <v-btn
                      icon
                      x-small
                      @click="handleDeleteResource(item)"
                      :disabled="demo"
                    >
                      <v-icon class="float-right">close</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row justify="center" class="text-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          v-bind="attrs"
                          v-on="on"
                          :href="item.url"
                          target="_blank"
                          class="link"
                          v-if="!demo"
                        >
                          <v-icon
                            class="mt-n3"
                            size="30"
                            :color="fileIcon(item.description).color"
                            >{{ fileIcon(item.description).icon }}
                          </v-icon>
                          <v-col>
                            <v-row class="file-name" no-gutters>
                              <v-col class="mx-1">
                                {{ item.description || "No file name" }}
                              </v-col>
                            </v-row>
                          </v-col>
                        </a>
                        <a
                          v-bind="attrs"
                          v-on="on"
                          target="_blank"
                          class="link"
                          v-else
                        >
                          <v-icon
                            class="mt-n3"
                            size="30"
                            :color="fileIcon(item.description).color"
                            >{{ fileIcon(item.description).icon }}
                          </v-icon>
                          <v-col>
                            <v-row class="file-name" no-gutters>
                              <v-col class="mx-1">
                                {{ item.description || "No file name" }}
                              </v-col>
                            </v-row>
                          </v-col>
                        </a>
                      </template>
                      <span>Download File</span>
                    </v-tooltip>
                  </v-row>
                </v-card>

                <v-card
                  height="100px"
                  width="100px"
                  class="mr-2 mb-2 grey lighten-4"
                  flat
                >
                  <v-row
                    v-if="!fileToUpload"
                    justify="center"
                    align="center"
                    style="height: 105px"
                  >
                    <span class="file-caption">Attach files</span>
                    <v-btn
                      @click="handleOpenFileBrowser"
                      :disabled="fileUploading || demo"
                      class="primary"
                      elevation="0"
                      icon
                    >
                      <v-icon :color="orgTheme.buttonIconColor"
                        >attach_file</v-icon
                      >
                    </v-btn>
                  </v-row>
                  <input
                    type="file"
                    style="display: none"
                    ref="fileInput"
                    @change="handleFilePicked"
                  />
                  <div v-if="fileToUpload">
                    <div justify="center" class="filename mt-2 ml-2">
                      {{ fileToUpload.name }}
                    </div>
                    <v-row
                      v-if="!accept(fileToUpload.name)"
                      justify="center"
                      class="filename mt-2 ml-2 grey--text px-3"
                      no-gutters
                    >
                      File Type Not Supported
                      <v-btn
                        @click="clearFile"
                        small
                        color="primary"
                        elevation="0"
                        class="mt-2"
                        >clear</v-btn
                      >
                    </v-row>
                    <v-row v-else justify="center" no-gutters>
                      <v-btn
                        class="mt-2"
                        small
                        color="primary"
                        elevation="0"
                        :disabled="!fileToUpload || fileUploading"
                        @click="handleUploadFile"
                      >
                        {{
                          fileUploading ? `${fileUploadingProgress}%` : "Upload"
                        }}
                      </v-btn>
                    </v-row>
                  </div>
                </v-card>
              </v-row>
            </v-col>
          </v-row>

          <div v-if="member" class="mt-4 mb-n2">
            <v-row no-gutters align="center">
              <v-text-field
                label="Opportunity Points"
                v-model="points"
                readonly
                type="number"
                @wheel="$event.target.blur()"
                outlined
                dense
                class="mr-2"
              />
              <v-text-field
                label="Add Bonus Points"
                v-model="bonusPoints"
                type="number"
                persistent-hint
                hint="*Optional"
                @wheel="$event.target.blur()"
                outlined
                dense
                class="mr-2"
              />
              <v-text-field
                label="Comment"
                v-model="description"
                name="description"
                persistent-hint
                hint="Appears in members transaction history"
                outlined
                dense
                class="mr-1"
              />
              <v-btn
                color="red"
                class="white--text mt-n5"
                :disabled="!points || demo"
                @click.stop="reject"
                elevation="0"
              >
                Reject
              </v-btn>
              <v-btn
                color="green"
                class="white--text mt-n5"
                :disabled="!points || demo"
                @click.stop="award"
                elevation="0"
              >
                Approve {{ totalPointsCalc }} points
              </v-btn>
            </v-row>
            <v-data-table
              class="px-0 pt-0"
              :headers="historyHeaders"
              :items="history"
              :search="search"
              :options.sync="historyOptions"
              :loading="loadingTable"
              no-data-text="No records"
              dense
            >
              <template v-slot:item.redeemable="{ item }">
                <v-row justify="center">
                  <v-checkbox v-model="item.redeemable" readonly></v-checkbox>
                </v-row>
              </template>

              <template v-slot:[`item.created`]="{ item }">
                {{ formatDisplayDate(item.created) }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  icon
                  color="red"
                  @click="deleteHistory(item)"
                  :disabled="demo"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeEditDialog"> Cancel </v-btn>
          <v-btn :disabled="demo" color="primary" type="submit" elevation="0">
            Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { nanoid } from "nanoid";
import { storage, timestamp } from "@/firebase";
import moment from "moment";
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    entryId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  data: () => ({
    search: "",
    dictionary: {
      custom: {
        deadline: {
          required: () => "Deadline is required",
        },
        status: {
          required: () => "Status is required",
        },
        points: {
          required: () => "Points are required",
        },
        submitted: {
          required: () => "Submitted date is required",
        },
      },
    },
    availableStatus: [
      { label: "Pending", value: "Pending" },
      { label: "Completed", value: "Completed" },
      { label: "Rejected", value: "Rejected" },
    ],
    permittedFiles: [
      { ext: "doc", icon: "fas fa-file-word", color: "blue" },
      { ext: "docx", icon: "fas fa-file-word", color: "blue" },
      { ext: "xls", icon: "fas fa-file-excel", color: "green" },
      { ext: "xlsx", icon: "fas fa-file-excel", color: "green" },
      { ext: "ppt", icon: "fas fa-file-powerpoint", color: "orange" },
      { ext: "pptx", icon: "fas fa-file-powerpoint", color: "orange" },
      { ext: "pdf", icon: "fas fa-file-pdf", color: "red" },
      { ext: "jpg", icon: "fas fa-file-image", color: "purple" },
      { ext: "jpeg", icon: "fas fa-file-image", color: "purple" },
      { ext: "png", icon: "fas fa-file-image", color: "purple" },
    ],
    historyOptions: {
      sortBy: ["created"],
      sortDesc: [true],
      itemsPerPage: 5,
      sortable: true,
      multiSort: true,
      search: false,
      filter: false,
    },
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    historyHeaders: [
      { text: "Created", value: "created", align: "left" },
      { text: "Comments", value: "comments", align: "left", sortable: false },
      { text: "Points", value: "points", align: "right", sortable: false },
      {
        text: "Redeemable",
        value: "redeemable",
        align: "center",
        sortable: false,
      },
      { text: "Bonus", value: "bonusPoints", align: "right", sortable: false },
      { text: "Total", value: "total", align: "right", sortable: false },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    submittedMenu: false,
    bonusPoints: 0,
    description: "",
    fileDescription: "",
    fileToUpload: null,
    fileUploading: false,
    fileUploadingProgress: 0,
    fileUploadTask: null,
    history: [],
    resources: [],
    company: "",
    member: "",
    points: 0,
    opportunity: "",
    submitted: "",
    status: "",
    totalPoints: 0,
    reviewed: "",
    id: "",
  }),
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    opportunities() {
      return (
        this.currentOffer.opportunities.map((opportunity) => {
          return {
            ...opportunity,
            redeemable:
              opportunity.redeemable !== undefined
                ? opportunity.redeemable
                : false,
          };
        }) || []
      );
    },
    deadline() {
      if (this.opportunity) {
        const opportunityDetail = this.opportunities.find(
          (el) => el.id === this.opportunity
        );
        return this.formatDate(opportunityDetail.deadline);
      }
      return "";
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    members() {
      return this.$store.state.member.members;
    },

    totalPointsCalc() {
      let total = 0;
      if (this.points) {
        total += +this.points;
      }
      if (this.bonusPoints) {
        total += +this.bonusPoints;
      }
      return total;
    },
    entries: {
      get() {
        return this.currentOffer.entries || [];
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", {
          entries: value,
        });
      },
    },
  },
  methods: {
    async handleSave() {
      const validationResult = await this.$validator.validateAll("entry");
      if (!validationResult) {
        return;
      }
      const index = this.entries.findIndex((el) => el.id === this.id);

      this.totalPoints = this.history.reduce((acc, history) => {
        return acc + +history.total;
      }, 0);

      this.status = this.history.length > 0 ? "Completed" : "Pending";

      const payload = {
        history: this.history,
        resources: this.resources,
        reviewed: moment().format("YYYY-MM-DD"),
        company: this.company,
        member: this.member,
        points: parseInt(this.points),
        opportunity: this.opportunity,
        submitted: this.submitted,
        totalPoints: this.totalPoints,
        bonusPoints: this.bonusPoints,
        status: this.status,
        id: nanoid(),
      };

      if (index === -1) {
        this.entries = [...this.entries, payload];
      } else {
        console.log(payload);
        this.entries[index] = {
          ...payload,
        };
        this.entries = [...this.entries];
      }
      console.log("handleSave", payload);
      this.closeEditDialog();
    },
    formatDate(date) {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },
    formatDisplayDate(date) {
      return date ? moment(date).format("ll") : "";
    },
    closeEditDialog() {
      this.$validator.reset();
      this.reset();
      this.$emit("onCloseEditDialog");
    },
    handleOpenFileBrowser() {
      this.$refs.fileInput.click();
    },
    handleFilePicked(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      this.fileToUpload = file;
    },
    handleUploadFile() {
      if (!this.fileToUpload) {
        return;
      }
      this.fileDescription = this.fileToUpload.name;
      this.fileUploadTask = storage
        .ref(
          `uploads/${this.programId}/offerResources/entries/${timestamp}_${this.fileToUpload.name}`
        )
        .put(this.fileToUpload);
      this.fileUploadingProgress = 0;
      this.fileUploading = true;
    },
    handleFinishUpload(url) {
      this.fileUploading = false;
      this.fileUploadingProgress = 0;
      this.fileUploadTask = null;
      this.fileToUpload = null;
      if (this.resources) {
        this.resources = [
          ...this.resources,
          {
            id: Math.random(),
            type: "file",
            description: this.fileDescription,
            url,
            created: new Date(),
          },
        ];
      } else {
        this.resources = [
          {
            id: Math.random(),
            type: "file",
            description: this.fileDescription,
            url,
            created: new Date(),
          },
        ];
      }
      this.fileDescription = "";
    },
    handleDeleteResource(resource) {
      if (this.resources) {
        this.resources = this.resources.filter(
          (item) => item.id != resource.id
        );
        // this.selectedItem = { ...this.selectedItem };
      }
    },
    fileIcon(filename) {
      if (filename) {
        const ext = filename.split(".").pop();
        let arr = this.permittedFiles;
        let obj = arr.find((el) => el.ext.toLowerCase() === ext.toLowerCase());
        if (obj) return obj;
        else {
          return { icon: "fas fa-file", color: "grey" };
        }
      } else {
        return { icon: "fas fa-file", color: "grey" };
      }
    },
    async reject() {
      const validationResult = await this.$validator.validateAll("pointForm");
      if (!validationResult) {
        return;
      }
      if (this.history) {
        this.history = [
          {
            created: new Date(),
            points: 0,
            bonusPoints: 0,
            total: 0,
            comments: this.description,
            redeemable: this.redeemable,
            status: "Completed",
          },
          ...this.history,
        ];
      } else {
        this.history = [
          {
            created: new Date(),
            points: 0,
            bonusPoints: 0,
            total: 0,
            redeemable: this.redeemable,
            comments: this.description,
            status: "Completed",
          },
        ];
      }
      this.selectedItem = {
        ...this.selectedItem,
        status: "Completed",
        reviewed: moment().format("YYYY-MM-DD"),
        bonusPoints: this.bonusPoints,
        description: this.description,
      };
      this.bonusPoints = 0;
      this.description = "";
      this.$validator.reset();
      this.reset();
    },
    accept(filename) {
      if (filename) {
        const ext = filename.split(".").pop();
        let arr = this.permittedFiles;
        let obj = arr.find((el) => el.ext.toLowerCase() === ext.toLowerCase());
        if (obj) return true;
        else {
          return false;
        }
      } else {
        return false;
      }
    },
    async award() {
      console.log(this.bonusPoints);
      const validationResult = await this.$validator.validateAll("pointForm");
      if (!validationResult) {
        return;
      }
      if (this.history) {
        this.history = [
          {
            created: moment().format("YYYY-MM-DD"),
            points: this.points,
            bonusPoints:
              this.bonusPoints &&
              !isNaN(this.bonusPoints) &&
              this.bonusPoints > 0
                ? parseInt(this.bonusPoints)
                : 0,
            total:
              parseInt(this.points) +
              (this.bonusPoints &&
              !isNaN(this.bonusPoints) &&
              this.bonusPoints > 0
                ? parseInt(this.bonusPoints)
                : 0),
            comments: this.description,
            status: "Completed",
            redeemable: this.redeemable,
          },
          ...this.history,
        ];
      } else {
        this.history = [
          {
            created: moment().format("YYYY-MM-DD"),
            points: this.points,
            bonusPoints:
              this.bonusPoints &&
              !isNaN(this.bonusPoints) &&
              this.bonusPoints > 0
                ? parseInt(this.bonusPoints)
                : 0,
            total:
              parseInt(this.points) +
              (this.bonusPoints &&
              !isNaN(this.bonusPoints) &&
              this.bonusPoints > 0
                ? parseInt(this.bonusPoints)
                : 0),
            comments: this.description,
            status: "Completed",
            redeemable: this.redeemable,
          },
        ];
      }
      this.submitted = moment().format("YYYY-MM-DD");
      console.log(this.history);
      this.bonusPoints = 0;
      this.description = "";
      this.$validator.reset();
    },
    deleteHistory(value) {
      if (this.history) {
        this.history = this.history.filter(
          (item) => JSON.stringify(item) != JSON.stringify(value)
        );
        if (this.history.length === 0) {
          this.selectedItem = {
            ...this.selectedItem,
            status: this.availableStatus[0].value,
          };
        } else {
          this.selectedItem = {
            ...this.selectedItem,
            status: this.history[0].status || this.availableStatus[1].value,
          };
        }
      }
    },
    reset() {
      this.history = history;
      this.resources = [];
      this.reviewed = "";
      this.company = "";
      this.member = "";
      this.points = 0;
      this.opportunity = "";
      this.submitted = "";
      this.status = "";
      this.totalPoints = 0;
      this.bonusPoints = 0;
      this.id = "";
    },
  },
  watch: {
    fileUploadTask: function () {
      if (!this.fileUploadTask) {
        return;
      }

      this.fileUploadTask.on(
        "state_changed",
        (sp) => {
          if (sp.totalBytes <= 0) {
            return;
          }
          this.fileUploadingProgress = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        () => {
          this.fileUploading = false;
        },
        () => {
          this.fileUploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              this.handleFinishUpload(downloadURL);
            });
        }
      );
    },
    opportunity: function () {
      this.points = this.opportunity
        ? this.opportunities.find((el) => el.id === this.opportunity).points
        : 0;
      this.redeemable = this.opportunity
        ? this.opportunities.find((el) => el.id === this.opportunity).redeemable
        : false;
    },
    entryId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedEntry = this.entries.find((item) => item.id === newVal);
          const {
            history,
            resources,
            company,
            member,
            points,
            opportunity,
            submitted,
            status,
            totalPoints,
            reviewed,
            bonusPoints,
            id,
          } = selectedEntry;
          this.history = history;
          this.resources = resources;
          this.reviewed = reviewed;
          this.company = company;
          this.member = member;
          this.points = points;
          this.opportunity = opportunity;
          this.submitted = submitted;
          this.status = status;
          this.totalPoints = totalPoints;
          this.bonusPoints = bonusPoints;
          this.id = id;
        }
      },
    },
  },
};
</script>

<style scoped>
.file-name {
  font-size: 10px;
  color: rgb(34, 34, 34);
  line-height: 10px;
}
.file-caption {
  font-size: 10px;
  color: rgb(34, 34, 34);
  line-height: 10px;
  position: absolute;
  margin-top: 60px;
}
</style>

<!--Banner-->
<template>
  <v-card class="mx-4 mb-6" dark outlined>
    <v-expansion-panels accordion flat color="transparent" dark>
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Text Content</v-expansion-panel-header>
        <v-expansion-panel-content>

          <v-row no-gutters>
            <v-text-field
              name="textBody"
              label="Body"
              v-model="editingComponent.textBody.value"
              @input="handleChange()"
              clearable
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'textBody'" 
              icon
              class="mt-4"
              @click="handleEditor('textBody')"
            >
              <v-icon color="primary">
                settings
              </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'textBody'" 
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'textBody'"
          ></design-heading-editor>
          
        </v-expansion-panel-content>
      </v-expansion-panel>         
      
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Column Group</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="mb-3">
            <v-col cols="5">
              <v-icon class="icon">sync_alt</v-icon>
              <span class="setting-label">Alignment</span>
            </v-col>
            <v-icon class="icon-button" @click="setBlockHorizontal('start')">align_horizontal_left</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockHorizontal('center')">align_horizontal_center</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockHorizontal('end')">align_horizontal_right</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockVertical('start')">align_vertical_top</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockVertical('center')">align_vertical_center</v-icon>
            <v-icon class="ml-2 icon-button" @click="setBlockVertical('end')">align_vertical_bottom</v-icon>
          </v-row>

          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">compress</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.cols"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="1"
                max="12"
                class="mt-n1"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.cols }}/12
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate270 icon">padding</v-icon>
              <span class="setting-label">Left Padding</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.padding.left"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="12"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.left }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">padding</v-icon>
              <span class="setting-label">Right Padding</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.padding.right"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="12"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.right }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">padding</v-icon>
              <span class="setting-label">Top Padding</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.padding.top"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="200"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.top }}px
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate180 icon">padding</v-icon>
              <span class="setting-label">Bottom Padding</span>
            </v-col>
              <v-slider
                v-model="editingComponent.block.padding.bottom"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="0"
                max="100"
                color="primary"
              ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.bottom }}px
            </v-col>
          </v-row>
        
        </v-expansion-panel-content>
      </v-expansion-panel>


      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Background</v-expansion-panel-header>
        <v-expansion-panel-content>
            
          <v-row 
            v-if="editingComponent.settings.mode === 'color'"
            no-gutters
            align="center"
            class="mt-1"
          >
            <v-col cols="3" class="setting-label">
              Banner Color
            </v-col>
            <v-col>
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                  <template v-slot:activator="{ on, attrs }">
                    <v-row no-gutters align="center">
                      <v-col cols="auto">
                        <v-img
                          class="rounded outline"
                          :src="require('@/assets/checkerboard.png')"
                          height="30px"
                          width="30px"
                        >
                          <v-card
                            v-bind="attrs"
                            v-on="on"
                            elevation="0"
                            height="30px"
                            width="30px"
                            :color="editingComponent.settings.color.hexa"
                          >
                          </v-card>
                        </v-img>
                      </v-col>
                      <v-col class="setting-label ml-2">
                        {{ editingComponent.settings.color.hexa }}
                      </v-col>
                    </v-row>
                  </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.settings.color"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>     
      
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading">Container</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">height</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.settings.cols"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="1"
              max="12"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.settings.cols }}/12
            </v-col>
          </v-row>         
        </v-expansion-panel-content>
      </v-expansion-panel>     
    
    </v-expansion-panels>

    <v-row justify="end" class="mt-0 mr-2 mb-2">
      <design-save-button :small="true"></design-save-button>
    </v-row>
  </v-card>
</template>

<script>

export default {
  components: {
  },
  data: () => {
    return {
      galleryDialog: false,
      uploadsDialog: false
    }
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    editingComponent() {
      return this.$store.getters.editingComponent
    },
    editingField() {
      return this.$store.getters.editingField || {}
    },
    samplePages() {
      return this.$store.getters.samplePages
    },
    sampleRecord() {
      return this.samplePages[0]
    },
  },
  methods: {
    handleChange() {
      this.$store.dispatch('setHasWebThemePendingUpdates', true)
    },
    handleEditor(field) {
      this.clearFieldEditors()
      let selected = {}
      if (field === 'textBody') {
        selected = this.editingComponent.textBody
      } else {
        return
      }
      this.$store.dispatch('editingField', selected)
    },
    clearFieldEditors() {
      this.$store.dispatch('editingField', {})
    },
    setBlockHorizontal(val) {
      this.editingComponent.block.justify = val
      this.handleChange()
    },
    setBlockVertical(val) {
      this.editingComponent.block.align = val
      this.handleChange()
    }
  }
}
</script>

<style>
  
</style>
export const BANNER_DEFAULTS = {
  settings: {
    heightContext: 'px',
    mode: 'image',
    cols: 12,
    color: { 
      alpha: 1, 
      hex: '#E91E63' ,
      hexa: '#E91E6300',
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233
      } 
    },
  },
  intro: {
    align: 'center',
    boxHeight: 10,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'intro',
    floatText: 'text-center',
    font: 'Work Sans, sans-serif',
    justify: 'center',
    lineHeight: 1.1,
    size: 12,
    style: 'normal',
    value: 'Go to the Design menu to edit these titles',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  pretitle: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'pretitle',
    floatText: 'text-center',
    font: 'Work Sans, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 22,
    style: 'normal',
    value: 'Welcome to your',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  title: {
    align: 'center',
    boxHeight: 10,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'title',
    floatText: 'text-center',
    font: 'Lobster, sans-serif',
    justify: 'center',
    lineHeight: 1.3,
    size: 95,
    style: 'normal',
    value: 'Incentable Program',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  subtitle: {
    align: 'end',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'subtitle',
    floatText: 'text-center',
    font: 'Work Sans, sans-serif',
    justify: 'center',
    size: 22,
    lineHeight: 1.1,
    style: 'normal',
    value: 'Powering your success',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  screen: {
    height: 85,
    type: 'gradient',
    direction: 'to top',
    color: { 
      alpha: 1, 
      hex: '#212121' ,
      hexa: '#2121216B',
      hsla: {
        a: 0.4205607476635514,
        h: 0,
        l: 0.12941176470588237,
        s: 0,
      },
      hsva: {
        a: 0.4205607476635514,
        h: 0,
        s: 0,
        v: 0.12941176470588237,
        hue: 0,
      },
      rgba: {
        a: 0.4205607476635514,
        b: 33,
        g: 33,
        r: 33
      } 
    },
    fromColor: { 
      alpha: 1, 
      hex: '#212121', 
      hexa: '#212121FF', 
      hsla: { h: 0, s: 0, l: 0.12941176470588237, a: 1 }, 
      hsva: { h: 0, s: 0, v: 0.12941176470588237, a: 1 }, 
      hue: 0, 
      rgba: { r: 33, g: 33, b: 33, a: 1 }  
    },
    toColor: { 
      alpha: 0, 
      hex: '#212121', 
      hexa: '#21212100', 
      hsla: { h: 339.60591133004925, s: 0.8218623481781376, l: 0.5156862745098039, a: 0 }, 
      hsva: { h: 339.60591133004925, s: 0.871244635193133, v: 0.9137254901960784, a: 0 }, 
      hue: 339.60591133004925, 
      rgba: { r: 233, g: 30, b: 99, a: 0 } 
    },
  },
  heightValue: {
    px: 533,
    vh: 30,
  },
  overlap: {
    px: 172,
    vh: 36,
  },
  block: {
    cols: 6,
    align: 'start',
    justify: 'center',
    padding: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 120,
    }
  },
  bannerLogo: {
    align: 'center',
    placement: 'top',
    justify: 'center',
    height: 50,
    boxHeight: 0,
    image: '',
    previewImage: ''
  },
  logo: {
    image: '',
    previewImage: ''
  },
  background: {
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_2560x2560.png?alt=media&token=62cd0b3e-a963-42ef-a633-cf9a063f99f1',
    previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_600x600.png?alt=media&token=3a2aa0cd-b5fe-45a5-9466-ec39af90630b'
  }
};

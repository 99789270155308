<!-- Quiz -->
<template>
  <v-card class="mx-4 mb-6" dark outlined>
    <v-expansion-panels accordion flat>
      <v-row no-gutters class="px-6 py-2">
        <v-col class="pt-3">
          <v-icon color="primary" class="mt-n1">looks_one</v-icon>
          <v-icon color="primary" class="mt-n1">looks_two</v-icon>
          <span class="editor-heading ml-2">Cards</span>
        </v-col>
      </v-row>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Section Heading</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="title"
              label="Section Heading"
              v-model="editingComponent.title.value"
              @input="handleChange()"
              clearable
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'title'"
              icon
              class="mt-4"
              @click="handleEditor('title')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'title'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'title'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Quiz Group Heading</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="heading"
              label="Quiz Heading"
              value="Heading"
              @input="handleChange()"
              clearable
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'heading'"
              icon
              class="mt-4"
              @click="handleEditor('heading')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'heading'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'heading'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Text</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  name="quizTitle"
                  label="Quiz Title"
                  v-model="sampleTitle"
                  @input="handleChange()"
                  readonly
                ></v-text-field>
              </template>
              <span v-html="tooltipText"></span>
            </v-tooltip>
            <v-btn
              v-if="editingField.field !== 'quizTitle'"
              icon
              class="mt-4"
              @click="handleEditor('quizTitle')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'quizTitle'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'quizTitle'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  name="deadline"
                  label="Date & Time"
                  v-model="sampleDeadline"
                  @input="handleChange()"
                  readonly
                ></v-text-field>
              </template>
              <span v-html="tooltipText"></span>
            </v-tooltip>
            <v-btn
              v-if="editingField.field !== 'deadline'"
              icon
              class="mt-4"
              @click="handleEditor('deadline')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'deadline'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'deadline'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  name="points"
                  label="Points Value"
                  v-model="samplePoints"
                  @input="handleChange()"
                  readonly
                ></v-text-field>
              </template>
              <span v-html="tooltipText"></span>
            </v-tooltip>
            <v-btn
              v-if="editingField.field !== 'points'"
              icon
              class="mt-4"
              @click="handleEditor('points')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'points'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'points'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Size</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">mdi-land-rows-horizontal</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.width"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="10"
              max="500"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.width }}px
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">expand</v-icon>
              <span class="setting-label">Height</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.height"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="10"
              max="500"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.height }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate270 icon">flip_to_front</v-icon>
              <span class="setting-label">Elevation</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.elevation"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="24"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.elevation }}px
            </v-col>
          </v-row>
          <v-row class="editor-third-heading mt-n3" no-gutters>
            <v-col>
              <v-icon class="icon">rounded_corner</v-icon>
              <span class="setting-label">Corner Rounding</span>
            </v-col>
            <v-radio-group
              class="mt-0"
              v-model="editingComponent.card.rounded"
              @change="handleChange()"
            >
              <v-row>
                <v-col>
                  <v-radio label="None" value="rounded-0"></v-radio>
                </v-col>
                <v-col>
                  <v-radio label="Small" value="rounded-sm"></v-radio>
                  <v-radio label="Medium" value="rounded"></v-radio>
                  <v-radio label="Large" value="rounded-lg"></v-radio>
                  <v-radio label="XLarge" value="rounded-xl"></v-radio>
                </v-col>
                <v-col>
                  <v-radio label="Pill" value="rounded-pill"></v-radio>
                  <v-radio label="Circle" value="rounded-circle"></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Border</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">border_outer</v-icon>
              <span class="setting-label">Border</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.borderSize"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="5"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.borderSize }}px
            </v-col>
          </v-row>
          <v-row
            v-if="editingComponent.card.borderSize > 0"
            no-gutters
            class="mt-n3"
          >
            <v-col cols="5">
              <v-icon class="icon">palette</v-icon>
              <span class="setting-label">Border Color</span>
            </v-col>
            <v-col>
              <v-menu
                bottom
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                style="z-index: 3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.card.borderColor.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.card.borderColor"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters align="center">
            <v-radio-group
              class="mt-n2"
              v-model="editingComponent.card.color.type"
              @change="handleChange()"
              row
            >
              <v-radio label="Color" value="solid"></v-radio>
              <v-radio label="Gradient" value="gradient"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row no-gutters align="center">
            <v-col v-if="editingComponent.card.color.type === 'solid'">
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                style="z-index: 3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.card.color.solidColor.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="editor-third-heading ml-2">
                      {{ editingComponent.card.color.solidColor.hexa }}
                    </v-col>
                  </v-row>
                </template>

                <v-color-picker
                  @input="handleChange()"
                  dot-size="25"
                  show-swatches
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="editingComponent.card.color.solidColor"
                ></v-color-picker>
              </v-menu>
            </v-col>
            <v-col v-if="editingComponent.card.color.type === 'gradient'">
              <v-row class="editor-third-heading" no-gutters>
                Gradient direction
              </v-row>
              <v-radio-group
                v-model="editingComponent.card.color.direction"
                class="mt-n1"
                @change="handleChange()"
              >
                <v-row>
                  <v-col>
                    <v-radio label="Top to Bottom" value="to bottom"> </v-radio>
                    <v-radio label="Bottom to Top" value="to top"> </v-radio>
                  </v-col>
                  <v-col>
                    <v-radio label="Left to Right" value="to right"> </v-radio>
                    <v-radio label="Right to Left" value="to left"> </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>

              <v-row no-gutters align="center">
                <v-col cols="2" class="editor-third-heading"> From </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    style="z-index: 3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.card.color.gradientFrom.hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{ editingComponent.card.color.gradientFrom.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.card.color.gradientFrom"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="mt-1">
                <v-col cols="2" class="editor-third-heading"> To </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    style="z-index: 3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.card.color.gradientTo.hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{ editingComponent.card.color.gradientTo.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.card.color.gradientTo"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-5 mb-n3">
                <v-col cols="auto">
                  <v-icon class="icon">gradient</v-icon>
                  <span class="setting-label">Size</span>
                </v-col>
                <v-slider
                  v-model="editingComponent.card.color.gradientHeight"
                  @input="handleChange()"
                  :thumb-size="24"
                  thumb-label
                  min="1"
                  max="100"
                  class="mt-n1"
                  color="primary"
                ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.card.color.gradientHeight }}%
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Margins & Padding</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="editor-third-heading mb-2 mt-1"
            >Padding (Inside the card)</v-row
          >
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">padding</v-icon>
              <span class="setting-label">Top Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.padding.top"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.padding.top }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate180 icon">padding</v-icon>
              <span class="setting-label">Bottom Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.padding.bottom"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.padding.bottom }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate270 icon">padding</v-icon>
              <span class="setting-label">Left Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.padding.left"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.padding.left }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">padding</v-icon>
              <span class="setting-label">Right Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.padding.right"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.padding.right }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="editor-third-heading mb-2 mt-n3"
            >Margin (Outside the card)</v-row
          >
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">margin</v-icon>
              <span class="setting-label">Top Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.margin.top"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.margin.top }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate180 icon">margin</v-icon>
              <span class="setting-label">Bottom Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.margin.bottom"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.margin.bottom }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate270 icon">margin</v-icon>
              <span class="setting-label">Left Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.margin.left"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.margin.left }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">margin</v-icon>
              <span class="setting-label">Right Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.margin.right"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.margin.right }}px
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Group</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">mdi-land-rows-horizontal</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.card.group.cols"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="1"
              max="12"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.card.group.cols }}/12
            </v-col>
          </v-row>

          <v-row class="mt-n3" no-gutters>
            <v-col cols="5">
              <v-icon class="icon">sync_alt</v-icon>
              <span class="setting-label">Alignment</span>
            </v-col>

            <v-icon @click="setCardGroupHorizontal('start')" class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setCardGroupHorizontal('center')"
              >align_horizontal_center</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setCardGroupHorizontal('end')"
              >align_horizontal_right</v-icon
            >
          </v-row>

          <v-row no-gutters class="mb-n5 mt-3">
            <v-col cols="5">
              <v-icon class="icon">view_module</v-icon>
              <span class="setting-label">Overflow</span>
            </v-col>
            <v-col>
              <v-radio-group
                class="mt-0"
                v-model="editingComponent.card.group.slider"
                @change="handleChange()"
              >
                <v-radio value="slide" label="Single Line (Carousel)"></v-radio>
                <v-radio value="tile" label="Multi Line (Wrap Cards)"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <div v-if="editingComponent.card.group.slider === 'slide'">
            <v-row no-gutters class="incentable-form-subheading mb-n5 mt-1">
              <v-col cols="5">
                <v-icon class="icon">view_module</v-icon>
                <span class="setting-label">Arrow Contrast</span>
              </v-col>
              <v-checkbox
                v-model="editingComponent.card.group.dark"
                @change="handleChange()"
                label="Lighten"
                class="mt-0"
              >
              </v-checkbox>
            </v-row>
            <v-row no-gutters class="incentable-form-subheading mb-n5 mt-1">
              <v-col>
                <span class="setting-label"
                  >* Arrow appears when cards overflow the screen width</span
                >
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Container</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">height</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.settings.cols"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="1"
              max="12"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.settings.cols }}/12
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate270 icon">padding</v-icon>
              <span class="setting-label">Left Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.block.padding.left"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="30"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.left }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">padding</v-icon>
              <span class="setting-label">Right Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.block.padding.right"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="30"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.right }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon icon">padding</v-icon>
              <span class="setting-label">Top Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.block.padding.top"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.top }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3 mb-n6">
            <v-col cols="5">
              <v-icon class="rotate180 icon">padding</v-icon>
              <span class="setting-label">Bottom Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.block.padding.bottom"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.block.padding.bottom }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-row no-gutters class="px-6 py-3">
        <v-col>
          <v-icon color="primary" class="mt-n1">table_view</v-icon>
          <span class="editor-heading ml-2">Pop Up</span>
        </v-col>
      </v-row>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Headings</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="dialogQuizTitle"
              label="Titles"
              v-model="sampleTitle"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'dialogQuizTitle'"
              icon
              class="mt-4"
              @click="handleEditor('dialogQuizTitle')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'dialogQuizTitle'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'dialogQuizTitle'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-text-field
              name="dialogDeadline"
              label="Dates"
              v-model="sampleDeadline"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'dialogDeadline'"
              icon
              class="mt-4"
              @click="handleEditor('dialogDeadline')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'dialogDeadline'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'dialogDeadline'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-text-field
              name="dialogPoints"
              label="Points"
              v-model="samplePoints"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'dialogPoints'"
              icon
              class="mt-4"
              @click="handleEditor('dialogPoints')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'dialogPoints'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'dialogPoints'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Content</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  name="dialogIntroduction"
                  label="Introduction"
                  v-model="sampleIntroduction"
                  @input="handleChange()"
                  readonly
                ></v-text-field>
              </template>
              <span v-html="tooltipText"></span>
            </v-tooltip>
            <v-btn
              v-if="editingField.field !== 'dialogIntroduction'"
              icon
              class="mt-4"
              @click="handleEditor('dialogIntroduction')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'dialogIntroduction'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'dialogIntroduction'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  name="dialogQuestion"
                  label="Question"
                  v-model="sampleQuestion"
                  @input="handleChange()"
                  readonly
                ></v-text-field>
              </template>
              <span v-html="tooltipText"></span>
            </v-tooltip>
            <v-btn
              v-if="editingField.field !== 'dialogQuestion'"
              icon
              class="mt-4"
              @click="handleEditor('dialogQuestion')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'dialogQuestion'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'dialogQuestion'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  name="dialogAnswer"
                  label="Answer"
                  v-model="sampleAnswer"
                  @input="handleChange()"
                  readonly
                ></v-text-field>
              </template>
              <span v-html="tooltipText"></span>
            </v-tooltip>
            <v-btn
              v-if="editingField.field !== 'dialogAnswer'"
              icon
              class="mt-4"
              @click="handleEditor('dialogAnswer')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'dialogAnswer'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'dialogAnswer'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-col cols="7">
              <v-icon class="icon">contrast</v-icon>
              <span class="setting-label">Answer form elements light</span>
            </v-col>
            <v-checkbox
              v-model="editingComponent.dialog.answer.dark"
              @click="handleChange()"
              class="mt-n1"
            ></v-checkbox>
          </v-row>

          <v-row no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  name="dialogResult"
                  label="Result"
                  v-model="sampleResult"
                  @input="handleChange()"
                  readonly
                ></v-text-field>
              </template>
              <span v-html="tooltipText"></span>
            </v-tooltip>
            <v-btn
              v-if="editingField.field !== 'dialogResult'"
              icon
              class="mt-4"
              @click="handleEditor('dialogResult')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'dialogResult'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'dialogResult'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Size</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">fullscreen</v-icon>
              <span class="setting-label">Full Screen</span>
            </v-col>
            <v-checkbox
              v-model="editingComponent.dialog.fullscreen"
              @click="handleChange()"
              class="mt-n1"
            ></v-checkbox>
          </v-row>

          <v-row no-gutters class="mb-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">height</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.dialog.width"
              :disabled="editingComponent.dialog.fullscreen"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="300"
              max="2000"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.dialog.width }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters align="center">
            <v-radio-group
              class="mt-n2"
              v-model="editingComponent.dialog.color.type"
              @change="handleChange()"
              row
            >
              <v-radio label="Color" value="solid"></v-radio>
              <v-radio label="Gradient" value="gradient"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row no-gutters align="center">
            <v-col v-if="editingComponent.dialog.color.type === 'solid'">
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                style="z-index: 3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.dialog.color.solidColor.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="editor-third-heading ml-2">
                      {{ editingComponent.dialog.color.solidColor.hexa }}
                    </v-col>
                  </v-row>
                </template>

                <v-color-picker
                  @input="handleChange()"
                  dot-size="25"
                  show-swatches
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="editingComponent.dialog.color.solidColor"
                ></v-color-picker>
              </v-menu>
            </v-col>
            <v-col v-if="editingComponent.dialog.color.type === 'gradient'">
              <v-row class="editor-third-heading mt-n2 mb-n1" no-gutters>
                Gradient direction
              </v-row>
              <v-radio-group
                v-model="editingComponent.dialog.color.direction"
                class="mt-n1"
                @change="handleChange()"
              >
                <v-row>
                  <v-col>
                    <v-radio label="Top to Bottom" value="to bottom"> </v-radio>
                    <v-radio label="Bottom to Top" value="to top"> </v-radio>
                  </v-col>
                  <v-col>
                    <v-radio label="Left to Right" value="to right"> </v-radio>
                    <v-radio label="Right to Left" value="to left"> </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>

              <v-row no-gutters align="center">
                <v-col cols="2" class="editor-third-heading"> From </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    style="z-index: 3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.dialog.color.gradientFrom.hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{ editingComponent.dialog.color.gradientFrom.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.dialog.color.gradientFrom"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="mt-1">
                <v-col cols="2" class="editor-third-heading"> To </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    style="z-index: 3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.dialog.color.gradientTo.hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{ editingComponent.dialog.color.gradientTo.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.dialog.color.gradientTo"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-5 mb-n3">
                <v-col cols="auto">
                  <v-icon class="icon">gradient</v-icon>
                  <span class="setting-label">Size</span>
                </v-col>
                <v-slider
                  v-model="editingComponent.dialog.color.gradientHeight"
                  @input="handleChange()"
                  :thumb-size="24"
                  thumb-label
                  min="1"
                  max="100"
                  class="mt-n1"
                  color="primary"
                ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.dialog.color.gradientHeight }}%
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Button Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-menu
            bottom
            nudge-bottom="25"
            :close-on-content-click="false"
            transition="slide-y-transition"
            max-width="300px"
            style="z-index: 3000"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row no-gutters align="center">
                <v-col cols="auto">
                  <v-img
                    class="rounded outline"
                    :src="require('@/assets/checkerboard.png')"
                    height="30px"
                    width="30px"
                  >
                    <v-card
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      height="30px"
                      width="30px"
                      :color="editingComponent.dialog.buttonColor.hexa"
                    >
                    </v-card>
                  </v-img>
                </v-col>
                <v-col class="setting-label ml-2">
                  {{ editingComponent.dialog.buttonColor.hexa }}
                </v-col>
              </v-row>
            </template>

            <v-color-picker
              @input="handleChange()"
              dot-size="25"
              show-swatches
              mode="hexa"
              swatches-max-height="200"
              v-model="editingComponent.dialog.buttonColor.hexa"
            ></v-color-picker>
          </v-menu>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Button Text Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-radio-group
            v-model="editingComponent.dialog.buttonText.color"
            @change="handleChange()"
          >
            <v-radio label="Dark" value=""></v-radio>
            <v-radio label="Light" value="white--text"></v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Padding</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-0">
            <v-col cols="5">
              <v-icon class="rotate270 icon">padding</v-icon>
              <span class="setting-label">Left Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.dialog.padding.left"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.dialog.padding.left }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">padding</v-icon>
              <span class="setting-label">Right Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.dialog.padding.right"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.dialog.padding.right }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">padding</v-icon>
              <span class="setting-label">Top Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.dialog.padding.top"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.dialog.padding.top }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3 mb-n6">
            <v-col cols="5">
              <v-icon class="rotate180 icon">padding</v-icon>
              <span class="setting-label">Bottom Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.dialog.padding.bottom"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.dialog.padding.bottom }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="end" class="mt-0 mr-2 mb-2">
      <design-save-button :small="true"></design-save-button>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      fonts: [
        { label: "Arial", class: "Arial, sans-serif" },
        { label: "Bebas Neue", class: "Bebas Neue, sans-serif" },
        { label: "Caveat", class: "Caveat, sans-serif" },
        { label: "Cinzel", class: "Cinzel, serif" },
        { label: "Dancing Script", class: "Dancing Script, serif" },
        { label: "Lato", class: "Lato, sans-serif" },
        { label: "Lobster", class: "Lobster, sans-serif" },
        { label: "Merriweather", class: "Merriweather, serif" },
        { label: "Pacifico", class: "Pacifico, sans-serif" },
        { label: "Playfair Display", class: "Playfair Display, serif" },
        { label: "Quicksand", class: "Quicksand, sans-serif" },
        { label: "Raleway", class: "Raleway, sans-serif" },
        { label: "Roboto", class: "Roboto, sans-serif" },
        { label: "Sans Serif", class: "sans-serif" },
        { label: "Satisfy", class: "Satisfy, sans-serif" },
        { label: "Spectral", class: "Spectral, serif" },
        { label: "Work Sans", class: "Work Sans, sans-serif" },
      ],
      tooltipText:
        "Click the gear icon to change the text styling.<br> The actual text displayed cannot be edited here.<br>Each card has its own text values, and this is<br>saved in the individual Leaderboard or Table settings.<br>The text shown here is a sample for design purposes.",
    };
  },
  computed: {
    fontLabel() {
      const val = this.fonts.find(
        (el) => el.class === this.editingComponent.dialog.content.heading.font
      );
      return val.label;
    },
    dataFontLabel() {
      const val = this.fonts.find(
        (el) => el.class === this.editingComponent.dialog.content.data.font
      );
      return val.label;
    },
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    editingComponent() {
      return this.$store.getters.editingComponent;
    },
    editingField() {
      return this.$store.getters.editingField || {};
    },
    sampleQuizzes() {
      return this.$store.getters.sampleQuizzes;
    },
    sampleRecord() {
      return this.sampleQuizzes[0];
    },
    sampleDeadline() {
      return this.formatDate(this.sampleRecord.timing.closeDate);
    },
    sampleTitle() {
      return this.sampleRecord.displayTitle;
    },
    samplePoints() {
      return this.sampleRecord.pointsOffered;
    },
    sampleIntroduction() {
      return this.sampleRecord.description;
    },
    sampleQuestion() {
      return this.sampleRecord.questions[0].text;
    },
    sampleAnswer() {
      return "The answer is 42";
    },
    sampleResult() {
      return "You achieved 95%";
    },
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format("MMM Do YYYY") : "";
    },
    setDataWeight() {
      if (
        this.editingComponent.dialog.content.data.weight === "normal" ||
        this.editingComponent.dialog.content.data.weight === null ||
        this.editingComponent.dialog.content.data.weight === ""
      ) {
        this.editingComponent.dialog.content.data.weight = "bold";
      } else {
        this.editingComponent.dialog.content.data.weight = "normal";
      }
      this.handleChange();
    },
    setDataStyle() {
      if (
        this.editingComponent.dialog.content.data.style === "normal" ||
        this.editingComponent.dialog.content.data.style === null ||
        this.editingComponent.dialog.content.data.style === ""
      ) {
        this.editingComponent.dialog.content.data.style = "italic";
      } else {
        this.editingComponent.dialog.content.data.style = "normal";
      }
      this.handleChange();
    },
    setHeadingWeight() {
      if (
        this.editingComponent.dialog.content.heading.weight === "normal" ||
        this.editingComponent.dialog.content.heading.weight === null ||
        this.editingComponent.dialog.content.heading.weight === ""
      ) {
        this.editingComponent.dialog.content.heading.weight = "bold";
      } else {
        this.editingComponent.dialog.content.heading.weight = "normal";
      }
      this.handleChange();
    },
    setHeadingStyle() {
      if (
        this.editingComponent.dialog.content.heading.style === "normal" ||
        this.editingComponent.dialog.content.heading.style === null ||
        this.editingComponent.dialog.content.heading.style === ""
      ) {
        this.editingComponent.dialog.content.heading.style = "italic";
      } else {
        this.editingComponent.dialog.content.heading.style = "normal";
      }
      this.handleChange();
    },
    plusHeading(field, val) {
      if (field === "lineHeight") {
        this.editingComponent.dialog.content.heading.lineHeight =
          this.editingComponent.dialog.content.heading.lineHeight + val;
        this.handleChange();
      } else {
        return;
      }
    },
    minusHeading(field, val) {
      if (field === "lineHeight") {
        this.editingComponent.dialog.content.heading.lineHeight =
          this.editingComponent.dialog.content.heading.lineHeight - val;
        this.handleChange();
      } else {
        return;
      }
    },
    plusData(field, val) {
      if (field === "lineHeight") {
        this.editingComponent.dialog.content.data.lineHeight =
          this.editingComponent.dialog.content.data.lineHeight + val;
        this.handleChange();
      } else {
        return;
      }
    },
    minusData(field, val) {
      if (field === "lineHeight") {
        this.editingComponent.dialog.content.data.lineHeight =
          this.editingComponent.dialog.content.data.lineHeight - val;
        this.handleChange();
      } else {
        return;
      }
    },
    setHeadingFont(val) {
      this.editingComponent.dialog.content.heading.font = val.class;
      this.handleChange();
    },
    setDataFont(val) {
      this.editingComponent.dialog.content.data.font = val.class;
      this.handleChange();
    },
    setHorizontal(val) {
      this.editingComponent.card.icon.justify = val;
      this.handleChange();
    },
    setVertical(val) {
      this.editingComponent.card.icon.align = val;
      this.handleChange();
    },
    setDialogIconHorizontal(val) {
      this.editingComponent.dialog.icon.justify = val;
      this.handleChange();
    },
    setDialogIconVertical(val) {
      this.editingComponent.dialog.icon.align = val;
      this.handleChange();
    },
    setCardIconPosition(val) {
      this.editingComponent.card.icon.placement = val;
      this.handleChange();
    },
    setDialogIconPosition(val) {
      this.editingComponent.dialog.icon.placement = val;
      this.handleChange();
    },
    setTableContentHorizontal(val) {
      this.editingComponent.dialog.content.align = val;
      this.handleChange();
    },
    setCardGroupHorizontal(val) {
      this.editingComponent.card.group.justify = val;
      this.handleChange();
    },
    handleChange() {
      this.$store.dispatch("setHasWebThemePendingUpdates", true);
    },
    handleEditor(field) {
      this.clearFieldEditors();
      let selected = {};
      if (field === "title") {
        selected = this.editingComponent.title;
      } else if (field === "quizTitle") {
        selected = this.editingComponent.quizTitle;
      } else if (field === "deadline") {
        selected = this.editingComponent.deadline;
      } else if (field === "points") {
        selected = this.editingComponent.points;
      } else if (field === "displayTitle") {
        selected = this.editingComponent.displayTitle;
      } else if (field === "heading") {
        selected = this.editingComponent.heading;
      } else if (field === "dialogQuizTitle") {
        selected = this.editingComponent.dialog.quizTitle;
      } else if (field === "dialogIntroduction") {
        selected = this.editingComponent.dialog.introduction;
      } else if (field === "dialogQuestion") {
        selected = this.editingComponent.dialog.question;
      } else if (field === "dialogAnswer") {
        selected = this.editingComponent.dialog.answer;
      } else if (field === "dialogResult") {
        selected = this.editingComponent.dialog.result;
      } else if (field === "dialogDeadline") {
        selected = this.editingComponent.dialog.deadline;
      } else if (field === "dialogPoints") {
        selected = this.editingComponent.dialog.points;
      } else {
        return;
      }
      this.$store.dispatch("editingField", selected);
    },
    clearFieldEditors() {
      this.$store.dispatch("editingField", {});
    },
    ordinal(n) {
      var s = ["th", "st", "nd", "rd"];
      var v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
  },
};
</script>

<style></style>

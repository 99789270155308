/* eslint-disable no-console */
import { db } from "@/firebase";

const state = {
  validationRecords: [],
  loadingValidationRecord: false,
  loadingValidationRecords: false,
};

const actions = {
  async loadValidationRecords({ commit, getters }, dataSetId) {
    //console.log("loading validation data", dataSetId);
    commit("setLoadingValidationRecords", true);

    let querySnapshot;
    const dbRef = db.collection("programs").doc(getters.programId);
    try {
      querySnapshot = await dbRef
        .collection("validationDataSets")
        .doc(dataSetId)
        .collection("data")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const validationRecords = [];
    querySnapshot.forEach(function (doc) {
      validationRecords.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    commit("setValidationRecords", validationRecords);
    commit("setLoadingValidationRecords", false);
  },

  async getValidationRecords({ commit, getters }, dataSetId) {
    commit("setLoadingValidationRecords", true);

    let querySnapshot;
    const dbRef = db.collection("programs").doc(getters.programId);
    try {
      querySnapshot = await dbRef
        .collection("validationDataSets")
        .doc(dataSetId)
        .collection("data")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const validationRecords = [];
    querySnapshot.forEach(function (doc) {
      validationRecords.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    commit("setLoadingValidationRecords", false);
    return validationRecords;
  },

  async createValidationRecord({ dispatch, commit, getters }, payload) {
    commit("setLoadingValidationRecord", true);
    const recordRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("validationDataSets")
      .doc(payload.dataSetId)
      .collection("data");

    let idenifierDupSnapshot;
    try {
      idenifierDupSnapshot = await recordRef
        .where("identifierUppercase", "==", payload.identifierUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking if the record has been used";
    }

    if (idenifierDupSnapshot.size > 0) {
      commit("setLoadingValidationRecord", false);
      throw "Identifier is already registered.";
    }

    const { id, ...validationRecordInput } = payload; // eslint-disable-line no-unused-vars
    const identifier = {
      ...validationRecordInput,
    };

    let newRecordRef;
    try {
      newRecordRef = await recordRef.add(identifier);
    } catch (e) {
      throw "Error occured when creating a new record.";
    }

    commit("createValidationRecord", {
      ...validationRecordInput,
      id: newRecordRef.id,
    });
    commit("setLoadingValidationRecord", false);
    dispatch("setSnackbar", "Record Created");
  },

  async updateValidationRecord({ dispatch, commit, getters }, payload) {
    console.log(payload);
    commit("setLoadingValidationRecord", true);
    const validationRecordRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("validationDataSets")
      .doc(payload.dataSetId)
      .collection("data");

    let storedValidationRecord;
    try {
      const validationRecordDoc = await validationRecordRef
        .doc(payload.id)
        .get();
      storedValidationRecord = validationRecordDoc.data();
    } catch (e) {
      storedValidationRecord = null;
    }

    if (!storedValidationRecord) {
      throw "Error occured when fetching the data record";
    }

    let validationRecordDupSnapshot;
    try {
      validationRecordDupSnapshot = await validationRecordRef
        .where("identifierUppercase", "==", payload.identifierUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking if the record exists";
    }

    if (validationRecordDupSnapshot.size > 0) {
      let duplicated = false;
      validationRecordDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        commit("setLoadingValidationRecord", false);
        throw "Identifier is already registered";
      }
    }

    const { id, ...validationRecordInput } = payload;
    const identifier = {
      ...validationRecordInput,
    };

    try {
      await validationRecordRef.doc(id).update(identifier);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a record";
    }

    commit("updateValidationDataSet", payload);
    commit("setLoadingValidationRecord", false);
    dispatch("setSnackbar", "Record Updated");
  },

  async deleteValidationRecord({ dispatch, commit, getters }, payload) {
    commit("setLoading", true);
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("validationDataSets")
        .doc(payload.dataSetId)
        .collection("data")
        .doc(payload.id)
        .delete();
    } catch (e) {
      throw "Error occured when deleting a record";
    }
    commit("deleteValidationRecord", payload.id);
    commit("setLoading", false);
    dispatch("setSnackbar", "Record Deleted");
  },

  dataSetHasRecords({ getters }, dataSetId) {
    console.log(dataSetId);
    try {
      const dataRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("validationDataSets")
        .doc(dataSetId)
        .collection("data");

      const snapshot = dataRef.limit(1).get();
      if (snapshot.empty) {
        return 0;
      } else {
        return 1;
      }
    } catch (e) {
      console.error("Error checking for records:", e);
      return false;
    }
  },

  setLoadingValidationRecord({ commit }, payload) {
    commit("setLoadingValidationRecord", payload);
  },

  setLoadingValidationRecords({ commit }, payload) {
    commit("setLoadingValidationRecords", payload);
  },

  resetValidationRecords({ commit }) {
    commit("setValidationRecords", []);
  },
};

const mutations = {
  setValidationRecords(state, payload) {
    state.validationRecords = payload;
  },

  setLoadingValidationRecord(state, payload) {
    state.loadingValidationRecord = payload;
  },

  setLoadingValidationRecords(state, payload) {
    state.loadingValidationRecords = payload;
  },

  createValidationRecord(state, payload) {
    state.validationRecords = [...state.validationRecords, payload];
  },

  updateValidationRecord(state, payload) {
    state.validationRecords = state.validationRecords.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteValidationRecord(state, payload) {
    const index = state.validationRecords.findIndex(
      (item) => item.id === payload
    );
    if (index !== -1) {
      state.validationRecords.splice(index, 1);
    }
  },
};

const getters = {
  validationRecords(state) {
    return state.validationRecords;
  },
  loadingValidationRecord(state) {
    return state.loadingValidationRecord;
  },
  loadingValidationRecords(state) {
    return state.loadingValidationRecords;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

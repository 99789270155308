<template>
  <v-tabs>
    <v-tab class="tab"> Primary </v-tab>
    <v-tab class="tab"> Secondary </v-tab>

    <v-tab-item
      transition="fade-transition"
      reverse-transition="fade-transition"
    >
      <primary-menus-list />
    </v-tab-item>
    <v-tab-item
      transition="fade-transition"
      reverse-transition="fade-transition"
    >
      <secondary-menus-list />
    </v-tab-item>
  </v-tabs>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadPrimaryMenus");
    this.$store.dispatch("loadBlogs");
    this.$store.dispatch("loadPages");
    this.$store.dispatch("loadRewardCatalogues");
    this.$store.dispatch("setNavMenu", true);
  },
  data: function () {
    return {
      pageTitle: "Menus",
      pageSubTitle: "Create and manage app and web page menus",
      tab: null,
    };
  },
};
</script>

<style scoped></style>

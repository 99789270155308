<template>
  <div>
    <v-dialog
      v-model="open"
      max-width="800px"
      persistent
    >
    <v-card>
      <v-card-title>
        <span class="headline pl-2">Select Icon</span>
        <v-spacer></v-spacer>
        <v-btn 
          icon
          @click="handleClose()"
        >
          <v-icon color="primary">close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container
          grid-list-lg
          align-content-start
          >
          <v-row row wrap justify-start>
            <v-col v-for="icon in icons" :key='icon.svgFile' text-xs-center>
              <v-card
                v-if="demo"
                width="100px"
                height="100px"
                color="grey lighten-2"
                flat
                class="pt-4"
                ripple
                hover
                elevation="0"
              >
                <v-row class="pt-3" justify="center">
                  <img :src="'/img/icons/black/' + icon.svgFile" width="40px">
                </v-row>
                <v-row class="caption pt-1" justify="center">
                  {{ icon.svgDescription }}
                </v-row>
              </v-card>
              <v-card
                v-else
                width="100px"
                height="100px"
                color="grey lighten-2"
                flat
                class="pt-4"
                ripple
                hover
                elevation="0"
                @click="handleSave(icon)"
              >
                <v-row class="pt-3" justify="center">
                  <img :src="'/img/icons/black/' + icon.svgFile" width="40px">
                </v-row>
                <v-row class="caption pt-1" justify="center">
                  {{ icon.svgDescription }}
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-4 pr-4">
        <v-spacer></v-spacer>
        <v-btn elevation="0" @click="handleClose()" color="primary">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>

    </v-dialog>

  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    svgFile: '',
    svgDescription: '',
    icons: [
 {
   svgFile: '017-aeroplane.svg',
   svgDescription: 'Aeroplane '
 },
 {
   svgFile: '021-artificial-intelligence.svg',
   svgDescription: 'Ai '
 },
 {
   svgFile: '029-airplane.svg',
   svgDescription: 'Airplane '
 },
 {
   svgFile: '027-airport.svg',
   svgDescription: 'Airport '
 },
 {
   svgFile: '004-alloy-wheel.svg',
   svgDescription: 'Alloy Wheel '
 },
 {
   svgFile: '094-award.svg',
   svgDescription: 'Award '
 },
 {
   svgFile: '117-award-1.svg',
   svgDescription: 'Award 2'
 },
 {
   svgFile: '119-award-2.svg',
   svgDescription: 'Award 3'
 },
 {
   svgFile: '121-award-3.svg',
   svgDescription: 'Award 4'
 },
 {
   svgFile: '123-award-4.svg',
   svgDescription: 'Award 5'
 },
 {
   svgFile: '126-award-5.svg',
   svgDescription: 'Award 6'
 },
 {
   svgFile: '106-badge.svg',
   svgDescription: 'Badge '
 },
 {
   svgFile: '114-badge-1.svg',
   svgDescription: 'Badge 2'
 },
 {
   svgFile: '069-bag.svg',
   svgDescription: 'Bag '
 },
 {
   svgFile: '097-balloon.svg',
   svgDescription: 'Balloon '
 },
 {
   svgFile: '092-bell.svg',
   svgDescription: 'Bell '
 },
 {
   svgFile: '095-best.svg',
   svgDescription: 'Best '
 },
 {
   svgFile: '039-bike.svg',
   svgDescription: 'Bike '
 },
 {
   svgFile: '035-boat.svg',
   svgDescription: 'Boat '
 },
 {
   svgFile: '079-bookmark.svg',
   svgDescription: 'Bookmark '
 },
 {
   svgFile: '020-brain.svg',
   svgDescription: 'Brain '
 },
 {
   svgFile: '090-building.svg',
   svgDescription: 'Building '
 },
 {
   svgFile: '038-bus.svg',
   svgDescription: 'Bus '
 },
 {
   svgFile: '142-business-and-finance.svg',
   svgDescription: 'Business '
 },
 {
   svgFile: '002-calendar.svg',
   svgDescription: 'Calendar '
 },
 {
   svgFile: '056-calendar-1.svg',
   svgDescription: 'Calendar 2'
 },
 {
   svgFile: '023-call.svg',
   svgDescription: 'Call '
 },
 {
   svgFile: '061-photo-camera.svg',
   svgDescription: 'Camera '
 },
 {
   svgFile: '113-certificate.svg',
   svgDescription: 'Certificate '
 },
 {
   svgFile: '102-champagne.svg',
   svgDescription: 'Champagne '
 },
 {
   svgFile: '144-chili-pepper.svg',
   svgDescription: 'Chili '
 },
 {
   svgFile: '053-clock.svg',
   svgDescription: 'Clock '
 },
 {
   svgFile: '045-cocktail.svg',
   svgDescription: 'Cocktail '
 },
 {
   svgFile: '046-cocktail-1.svg',
   svgDescription: 'Cocktail 2'
 },
 {
   svgFile: '124-crown.svg',
   svgDescription: 'Crown '
 },
 {
   svgFile: '030-cruise.svg',
   svgDescription: 'Cruise '
 },
 {
   svgFile: '087-cup.svg',
   svgDescription: 'Cup '
 },
 {
   svgFile: '070-diamond.svg',
   svgDescription: 'Diamond '
 },
 {
   svgFile: '044-dinner.svg',
   svgDescription: 'Dinner '
 },
 {
   svgFile: '086-ebook.svg',
   svgDescription: 'Ebook '
 },
 {
   svgFile: '051-exchange.svg',
   svgDescription: 'Exchange '
 },
 {
   svgFile: '143-fire.svg',
   svgDescription: 'Fire '
 },
 {
   svgFile: '071-flag.svg',
   svgDescription: 'Flag '
 },
 {
   svgFile: '072-flag-1.svg',
   svgDescription: 'Flag 2'
 },
 {
   svgFile: '076-flag-2.svg',
   svgDescription: 'Flag 3'
 },
 {
   svgFile: '083-flag-3.svg',
   svgDescription: 'Flag 4'
 },
 {
   svgFile: '132-flag-4.svg',
   svgDescription: 'Flag 5'
 },
 {
   svgFile: '134-miscellaneous.svg',
   svgDescription: 'Flame '
 },
 {
   svgFile: '008-flash.svg',
   svgDescription: 'Flash '
 },
 {
   svgFile: '112-flask.svg',
   svgDescription: 'Flask '
 },
 {
   svgFile: '055-folder.svg',
   svgDescription: 'Folder '
 },
 {
   svgFile: '068-folder-1.svg',
   svgDescription: 'Folder 2'
 },
 {
   svgFile: '098-garland.svg',
   svgDescription: 'Garland '
 },
 {
   svgFile: '003-gift.svg',
   svgDescription: 'Gift '
 },
 {
   svgFile: '009-home.svg',
   svgDescription: 'Home '
 },
 {
   svgFile: '016-hotel.svg',
   svgDescription: 'Hotel '
 },
 {
   svgFile: '057-idea.svg',
   svgDescription: 'Idea '
 },
 {
   svgFile: '104-intelligence.svg',
   svgDescription: 'Intelligence '
 },
 {
   svgFile: '052-internet.svg',
   svgDescription: 'Internet '
 },
 {
   svgFile: '015-itinerary.svg',
   svgDescription: 'Itinerary '
 },
 {
   svgFile: '093-landmark.svg',
   svgDescription: 'Landmark '
 },
 {
   svgFile: '133-laurel-wreath.svg',
   svgDescription: 'Laurel Wreath '
 },
 {
   svgFile: '011-like.svg',
   svgDescription: 'Like '
 },
 {
   svgFile: '058-like-1.svg',
   svgDescription: 'Like 2'
 },
 {
   svgFile: '031-luggage.svg',
   svgDescription: 'Luggage '
 },
 {
   svgFile: '018-mail.svg',
   svgDescription: 'Mail '
 },
 {
   svgFile: '099-mail-1.svg',
   svgDescription: 'Mail 2'
 },
 {
   svgFile: '036-map.svg',
   svgDescription: 'Map '
 },
 {
   svgFile: '066-map-1.svg',
   svgDescription: 'Map 2'
 },
 {
   svgFile: '073-map-2.svg',
   svgDescription: 'Map 3'
 },
 {
   svgFile: '054-map-location.svg',
   svgDescription: 'Map Location '
 },
 {
   svgFile: '043-margarita.svg',
   svgDescription: 'Margarita '
 },
 {
   svgFile: '091-meal.svg',
   svgDescription: 'Meal '
 },
 {
   svgFile: '005-medal.svg',
   svgDescription: 'Medal '
 },
 {
   svgFile: '105-medal-1.svg',
   svgDescription: 'Medal 2'
 },
 {
   svgFile: '109-medal-2.svg',
   svgDescription: 'Medal 3'
 },
 {
   svgFile: '116-medal-3.svg',
   svgDescription: 'Medal 4'
 },
 {
   svgFile: '050-money.svg',
   svgDescription: 'Money '
 },
 {
   svgFile: '084-movie.svg',
   svgDescription: 'Movie '
 },
 {
   svgFile: '001-multimedia.svg',
   svgDescription: 'Multimedia '
 },
 {
   svgFile: '062-network.svg',
   svgDescription: 'Network '
 },
 {
   svgFile: '064-news.svg',
   svgDescription: 'News '
 },
 {
   svgFile: '013-news-paper.svg',
   svgDescription: 'News Paper '
 },
 {
   svgFile: '006-objective.svg',
   svgDescription: 'Objective '
 },
 {
   svgFile: '065-origami.svg',
   svgDescription: 'Origami '
 },
 {
   svgFile: '033-passport.svg',
   svgDescription: 'Passport '
 },
 {
   svgFile: '019-pdf.svg',
   svgDescription: 'Pdf '
 },
 {
   svgFile: '080-photo.svg',
   svgDescription: 'Photo '
 },
 {
   svgFile: '081-photos.svg',
   svgDescription: 'Photos '
 },
 {
   svgFile: '014-picture.svg',
   svgDescription: 'Picture '
 },
 {
   svgFile: '063-picture-1.svg',
   svgDescription: 'Picture 2'
 },
 {
   svgFile: '010-placeholder.svg',
   svgDescription: 'Placeholder '
 },
 {
   svgFile: '060-placeholder-1.svg',
   svgDescription: 'Placeholder 2'
 },
 {
   svgFile: '025-plane-ticket.svg',
   svgDescription: 'Plane Ticket '
 },
 {
   svgFile: '088-podium.svg',
   svgDescription: 'Podium '
 },
 {
   svgFile: '125-podium-1.svg',
   svgDescription: 'Podium 2'
 },
 {
   svgFile: '131-podium-2.svg',
   svgDescription: 'Podium 3'
 },
 {
   svgFile: '074-price.svg',
   svgDescription: 'Price '
 },
 {
   svgFile: '012-promotion.svg',
   svgDescription: 'Promotion '
 },
 {
   svgFile: '022-psychology.svg',
   svgDescription: 'Psychology '
 },
 {
   svgFile: '128-quality.svg',
   svgDescription: 'Quality '
 },
 {
   svgFile: '067-question.svg',
   svgDescription: 'Question '
 },
 {
   svgFile: '082-radar.svg',
   svgDescription: 'Radar '
 },
 {
   svgFile: '047-restaurant.svg',
   svgDescription: 'Restaurant '
 },
 {
   svgFile: '110-reward.svg',
   svgDescription: 'Reward '
 },
 {
   svgFile: '141-sale.svg',
   svgDescription: 'Sale '
 },
 {
   svgFile: '140-sale-label.svg',
   svgDescription: 'Sale Label '
 },
 {
   svgFile: '135-shop.svg',
   svgDescription: 'Shop '
 },
 {
   svgFile: '137-shopper.svg',
   svgDescription: 'Shopper '
 },
 {
   svgFile: '075-shout.svg',
   svgDescription: 'Shout '
 },
 {
   svgFile: '078-sign.svg',
   svgDescription: 'Sign '
 },
 {
   svgFile: '028-skyscraper.svg',
   svgDescription: 'Skyscraper '
 },
 {
   svgFile: '085-spotlight.svg',
   svgDescription: 'Spotlight '
 },
 {
   svgFile: '007-star.svg',
   svgDescription: 'Star '
 },
 {
   svgFile: '059-star-1.svg',
   svgDescription: 'Star 2'
 },
 {
   svgFile: '120-stars.svg',
   svgDescription: 'Stars '
 },
 {
   svgFile: '139-sticker.svg',
   svgDescription: 'Sticker '
 },
 {
   svgFile: '138-store.svg',
   svgDescription: 'Store '
 },
 {
   svgFile: '103-strategy.svg',
   svgDescription: 'Strategy '
 },
 {
   svgFile: '032-sunbed.svg',
   svgDescription: 'Sunbed '
 },
 {
   svgFile: '136-tag.svg',
   svgDescription: 'Tag '
 },
 {
   svgFile: '089-target.svg',
   svgDescription: 'Target '
 },
 {
   svgFile: '041-tea-cup.svg',
   svgDescription: 'Tea Cup '
 },
 {
   svgFile: '024-telephone.svg',
   svgDescription: 'Telephone '
 },
 {
   svgFile: '042-tequila.svg',
   svgDescription: 'Tequila '
 },
 {
   svgFile: '040-theater.svg',
   svgDescription: 'Theater '
 },
 {
   svgFile: '026-tickets.svg',
   svgDescription: 'Tickets '
 },
 {
   svgFile: '049-tickets-1.svg',
   svgDescription: 'Tickets 2'
 },
 {
   svgFile: '034-train.svg',
   svgDescription: 'Train '
 },
 {
   svgFile: '037-train-1.svg',
   svgDescription: 'Train 2'
 },
 {
   svgFile: '127-trophies.svg',
   svgDescription: 'Trophies '
 },
 {
   svgFile: '101-trophy.svg',
   svgDescription: 'Trophy '
 },
 {
   svgFile: '107-trophy-1.svg',
   svgDescription: 'Trophy 2'
 },
 {
   svgFile: '108-trophy-2.svg',
   svgDescription: 'Trophy 3'
 },
 {
   svgFile: '111-trophy-3.svg',
   svgDescription: 'Trophy 4'
 },
 {
   svgFile: '115-trophy-4.svg',
   svgDescription: 'Trophy 5'
 },
 {
   svgFile: '118-trophy-5.svg',
   svgDescription: 'Trophy 6'
 },
 {
   svgFile: '122-trophy-6.svg',
   svgDescription: 'Trophy 7'
 },
 {
   svgFile: '130-trophy-7.svg',
   svgDescription: 'Trophy 8'
 },
 {
   svgFile: '077-umbrella.svg',
   svgDescription: 'Umbrella '
 },
 {
   svgFile: '048-vip.svg',
   svgDescription: 'VIP'
 },
 {
   svgFile: '096-voucher.svg',
   svgDescription: 'Voucher '
 },
 {
   svgFile: '100-wine-glass.svg',
   svgDescription: 'Wine Glass '
 },
 {
   svgFile: '129-winning.svg',
   svgDescription: 'Winning '
 }
]
  }),
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
  },
  methods: {
    handleClose() {
      this.$emit('onClose');
    },
    handleSave(icon) {
      this.$emit('onSave', icon);
    },
  },
}
</script>

<style scoped>
.v-menu__content {
  overflow-y: content !important;
  overflow-x: content !important;
  contain: content !important;
}
</style>
<template>
  <div>
    <v-container fluid class="theme-page">
      <v-row no-gutters>
        <v-col cols="12">
          <view-main />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ViewMain from './View/Main.vue'

 export default {
   components: {
     ViewMain,
   },
   created () {
    this.$store.dispatch('initialize')
    this.$store.dispatch('loadTranslationData')
    this.$store.dispatch('setNavMenu', false)
    this.$store.dispatch('setDrawer', false)
    this.$store.dispatch('setToolbar', false)
    this.$store.dispatch('setFooter', true)
    this.$store.dispatch('setNavTitle', false)
    this.$store.dispatch('setHasWebThemePendingUpdates', false)
    this.$store.dispatch('editingField', {})
    this.$store.dispatch('editingComponent', {})
  },
  data: function () {
    return {
      fab: false
    }
  },
  computed: {
    navmenu () {
      return this.$store.state.navmenu
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name
    },
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    loading() {
      return this.$store.state.loading
    },
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates
    },
    side() {
      return this.$store.getters.side
    },

  },
  methods: {
    handleSave() {
      this.$store.dispatch('updateWebTheme', this.webTheme)
      this.$store.dispatch('setHasWebThemePendingUpdates', false)
      this.$store.dispatch('editingField', {})
      this.$store.dispatch('editingComponent', {})
    },
  }
}
</script>

<style scoped>
.theme-page {
  padding: 0px 0px 0px 0px !important;
}
</style>


<template>
  <v-tabs>
    <v-tab class="tab"> Articles </v-tab>
    <v-tab class="tab"> Categories </v-tab>
    <v-tab class="tab"> Blogs </v-tab>

    <v-tab-item
      transition="fade-transition"
      reverse-transition="fade-transition"
    >
      <blog-articles-table></blog-articles-table>
    </v-tab-item>
    <v-tab-item
      transition="fade-transition"
      reverse-transition="fade-transition"
    >
      <blog-categories-table></blog-categories-table>
    </v-tab-item>
    <v-tab-item
      transition="fade-transition"
      reverse-transition="fade-transition"
    >
      <blogs-table></blogs-table>
    </v-tab-item>
  </v-tabs>
</template>

<script>
export default {
  data: function () {
    return {
      active: "",
      pageTitle: "Articles",
      pageSubTitle:
        "Publish articles, group them inside blogs and make them easier to find by adding categories",
    };
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadBlogs");
    this.$store.dispatch("loadCategorys");
    this.$store.dispatch("loadArticles");
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadMemberTags");
    this.$store.dispatch("setNavMenu", true);
  },
};
</script>

<style scoped></style>

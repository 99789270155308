<template>
  <div>
    <v-row
      no-gutters
      align="center"
      class="mb-3"
    >
      <v-col class="page-heading ml-1">
        {{ mapStatus }}
      </v-col>
      <v-col sm="auto">
        <v-btn
          v-if="sourceFields.length && editingMap === false" 
          @click="onEditMap(true)"
          color="primary"
          elevation="0" 
        >
          Edit Map
        </v-btn>
        <v-btn 
          v-if="sourceFields.length && editingMap === true"
          @click="onEditMap(false)"
          color="primary"
          elevation="0"
          text
        >
          Close Edit
        </v-btn>
        <v-btn 
          v-if="sourceFields.length && editingMap"
          color="primary"
          elevation="0" 
          @click="onSave"
        >
          Save
        </v-btn>
      </v-col>
      
    </v-row>

    <v-row no-gutters justify="center">
      <v-col cols="12">
        <v-card
          outlined
          tile
        >
          <v-simple-table
          >
            <thead>
              <tr>
                <th class="incentable-csv-table-title-row">
                  Data Bucket
                </th>
                <th class="incentable-csv-table-title-row">
                  CSV
                </th>
                <th class="incentable-csv-table-title-row">
                  Sample Row
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="targetField in targetFields"
                :key="targetField"
              >
                <td class="incentable-csv-table-cell">
                  {{targetField}}
                  <span v-if="requiredFields.includes(targetField)">*</span>
                </td>
                <td v-if="editingMap" class="incentable-csv-table-cell px-2">
                  <v-select
                    class="mt-0 mb-n6"
                    dense
                    filled
                    outlined
                    v-model="newMapping[targetField]"
                    :items="sourceFields"
                    :error-messages="mappingErrors[targetField]"
                  />
                </td>
                <td v-else class="incentable-csv-table-cell">
                  {{ newMapping[targetField] }}
                </td>
                <td v-if="targetField === 'Account Key'" class="incentable-csv-table-cell">
                  {{getSampleRow(targetField) }}
                </td>
                <td v-else class="incentable-csv-table-cell">
                  {{getSampleRow(targetField) | number }}
                </td>
              </tr>
            </tbody>

          </v-simple-table>  
        </v-card>
        <div class="ml-2 mt-3 incentable-page-subheading">
          *Required field

        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
 export default {
  created() {
    this.newMapping = { ...this.mapping };
  },
  props: {
    targetFields: {
      type: Array,
      required: true,
    },
    requiredFields: {
      type: Array,
      default: () => [],
    },
    sourceFields: {
      type: Array,
      required: true,
    },
    sourceData: {
      type: Array,
      required: true,
    },
    mapping: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: function () {
    return {
      editingMap: false,
      newMapping: {},
      mappingErrors: {},
      isProcessing: false,
    };
  },
  computed: {
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    mapStatus () {
      if (this.sourceFields.length === 0) {
        return 'Please set new map'
      } else if (this.editingMap) {
        return 'Editing Map'
      } else {
        return 'Current Mapping'
      }
    },
  },
  methods: {
    getSampleRow(targetField) {
      if (!this.sourceData.length) {
        return '';
      }
      const relSource = this.newMapping[targetField];
      const colIndex = this.sourceFields.findIndex(sourceField => sourceField === relSource);
      return this.sourceData[0][colIndex];
    },

    onEditMap(val){
      if (val === true) {
        this.editingMap = true
        this.$emit('editingMap', true)
      } else {
        this.editingMap = false
        this.$emit('editingMap', false)
      }
    },

    reset() {
      this.mappingErrors = {};
    },

    onSave() {
      const errors = {};
      if (this.requiredFields.length) {
        let hasMissingRequired = false
        this.requiredFields.forEach(field => {
          if (!this.newMapping[field]) {
            hasMissingRequired = true;
            errors[field] = 'Required field.'
          }
        })
        if (hasMissingRequired) {
          this.mappingErrors = errors
          return
        }
      }
      this.$store.dispatch('updateTargetMapping', this.newMapping)
        .then(() => {
          this.isProcessing = false
          this.editingMap = false
          this.$emit('editingMap', false)
        })
        .catch(() => {
          this.isProcessing = false
          this.editingMap = false
          this.$emit('editingMap', false)
        })
      this.reset()
    },
  },

}
</script>

<style>
.select-adjust {
  position: relative
}
</style>

export const RESERVED_DOMAINS = [
  'app.incentable.com',
  'rewards.incentable.com',
  'loyalty.incentable.com',
  'incentive.incentable.com',
  'my.incentable.com',
  'admin.incentable.com',
  'signin.incentable.com',
  'signup.incentable.com',
  'login.incentable.com',
  'myapp.incentable.com',
  'myrewards.incentable.com',
  'myloyalty.incentable.com',
  'myincentive.incentable.com',
  'myadmin.incentable.com',
  'mysignin.incentable.com',
  'mysignup.incentable.com',
  'mylogin.incentable.com',
  'mail.incentable.com',
  'web.incentable.com',
  'dashboard.incentable.com',
  'www.incentable.com',
  'forum.incentable.com',
  'm.incentable.com',
  'blog.incentable.com',
  'shop.incentable.com',
  'forums.incentable.com',
  'wiki.incentable.com',
  'academy.incentable.com',
  'uni.incentable.com',
  'university.incentable.com',
  'community.incentable.com',
  'hub.incentable.com',
  'ww1.incentable.com',
  'mymail.incentable.com',
  'smtp.incentable.com',
  'imap.incentable.com',
  'pop.incentable.com',
  'pop3.incentable.com',
  'mxb.incentable.com',
  'aspmx.incentable.com',
  'mx.incentable.com',
  'alt.incentable.com',
  'inbound.incentable.com',
  'remote.incentable.com',
  'mobile.incentable.com',
  'webmail.incentable.com',
  'ns1.incentable.com',
  'ns2.incentable.com',
  'secure.incentable.com',
  'vpn.incentable.com',
  'portal.incentable.com',
  'intranet.incentable.com',
  'extranet.incentable.com',
  'net.incentable.com',
  'com.incentable.com',
  'au.incentable.com',
  'host.incentable.com',
  'test.incentable.com',
  'demo.incentable.com',
  'cloud.incentable.com',
  'api.incentable.com',
  'cdn.incentable.com',
  'news.incentable.com',
  'exchange.incentable.com',
  'store.incentable.com',
  'owa.incentable.com',
  'email.incentable.com',
  'dev.incentable.com',
  'development.incentable.com',
  'staging.incentable.com',
  'landing.incentable.com',
  'articles.incentable.com',
  'reward.incentable.com',
  'leaderboard.incentable.com',
  'leaderboards.incentable.com',
  'resources.incentable.com',
  'knowledgebase.incentable.com',
  'inbound.incentable.com',
  'cart.incentable.com',
  'checkout.incentable.com',
  'live.incentable.com',
  'online.incentable.com',
  'billing.incentable.com',
  'pay.incentable.com',
  'payment.incentable.com',
];
<template>
  <div>  
    <v-card class="pl-3" outlined>
      <v-row no-gutters align="center" style="min-height: 55px">
        
        <v-col sm="auto" class="pr-2">
          <v-icon class="rotate" color="grey">reorder</v-icon>
        </v-col>
        <v-col sm="1">
          <div class="incentable-form-heading">Columns</div>
          <div 
            v-if="mapping ==='column'"
            class="incentable-databucket-table-subtitle mt-n1"
          >Main Body</div>
          <div 
            v-else-if="mapping ==='header'"
            class="incentable-databucket-table-subtitle mt-n1"
          >Header</div>
        </v-col>

        <v-col sm="auto">
          <v-menu
            offset-y
            :close-on-content-click="false"
            v-model="columnForm"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="grey lighten-4 ml-n4 mr-3"
                icon
                elevation="0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">add</v-icon>
              </v-btn>
            </template>
              <v-card>
                <v-card-text class="px-6 pt-6">
                  <div class="incentable-form-heading mb-3">Add Column</div>
                    <v-select
                      v-model="currentColumn"
                      placeholder="Select Content"
                      :items="sourceColumns"
                      label="Column Content"
                      prepend-icon="fa-solid fa-database"
                      :error-messages="mappingError.column"
                      @change="handleChangeColumn"
                    >
                      <template slot="item" slot-scope="data">
                        <v-row no-gutters v-if="getColumnMeta(data.item).icon">
                          <v-col sm="auto" class="pr-1">
                            <v-icon>{{ getColumnMeta(data.item).icon }}</v-icon>
                          </v-col>
                          <v-col>
                            {{ data.item }}
                          </v-col>
                        </v-row>

                        <v-row no-gutters v-else>
                          <v-col sm="auto" class="pr-1">
                            <v-icon>fa-solid fa-file-csv</v-icon>
                          </v-col>
                          <v-col>
                            {{ data.item }}
                          </v-col>
                        </v-row>
                      </template>
                    </v-select>
                  <div sm="2" v-if="currentColumn">
                    <v-text-field
                      v-model="currentLabel"
                      placeholder="Add Label"
                      label="Column Label"
                      prepend-icon="fas fa-heading"
                      :error-messages="mappingError.label"
                    />
                  </div>
                  <!-- only show extra dropdowns for non incentable fields-->
                  <template v-if="!specialColumns.includes(currentColumn)">
                    <div v-if="currentLabel">
                      <v-select
                        v-model="currentType"
                        placeholder="Select Format"
                        label="Column Format"
                        prepend-icon="fas fa-question-circle"
                        :items="dataTypes"
                        item-text="label"
                        item-value="value"
                        :error-messages="mappingError.type"
                      >
                        <template slot="item" slot-scope="data">
                          <v-icon v-if="data.item.label === 'Qualifier'" class="mr-3">{{ getIcon(data.item).icon }}</v-icon>
                          <span v-else v-html="getIcon(data.item).iconCode"></span>
                          {{ data.item.label }}
                        </template>
                      </v-select>
                    </div>
                    <!-- number specific fields -->
                    <template v-if="currentType === 'number'">
                      <div >
                        <v-select
                          v-model="currentNumberFormat"
                          placeholder="Select Prefix/Suffix"
                          :items="numberFormats"
                          item-text="label"
                          item-value="value"
                          clearable
                          prepend-icon="settings_ethernet"
                          label="Prefix/Suffix"
                          :error-messages="mappingError.format"
                        />
                      </div>
                      <div sm="2">
                        <v-select
                          v-model="currentNumberRounding"
                          placeholder="Select Number Rounding"
                          :items="numberRoundings"
                          item-text="label"
                          item-value="value"
                          label="Number Rounding"
                          prepend-icon="multiple_stop"
                          :error-messages="mappingError.rounding"
                        />
                      </div>
                    </template>

                    <!-- extra fields for qualifier column -->
                    <template v-if="currentType==='qualifier'">
                      <div sm="2">
                        <v-select
                          v-model="currentQualifierFormat"
                          label="Qualifier Rule"
                          :items="qualifierFormats"
                          item-text="label"
                          item-value="value"
                          prepend-icon="flaky"
                          :error-messages="mappingError.qualifierFormat"
                        >
                          <template slot="item" slot-scope="data">
                            <span v-if="data.item.label === 'Number'" v-html="getIcon(data.item).iconCode"></span>
                            <v-icon v-else class="mr-3">{{ getIcon(data.item).icon }}</v-icon>
                            {{ data.item.label }}
                          </template>
                        </v-select>
                      </div>
                      <div sm="1">
                        <v-text-field
                          v-if="currentQualifierFormat==='number'"
                          v-model="currentQualifierComp"
                          prepend-icon="fas fa-greater-than-equal"
                          label="Greater Than Or Equal To"
                        />
                      </div>
                    </template>
                  </template>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!currentColumn || demo"
                  @click="handleAddColumn"
                  color="primary"
                  elevation="0"
                  >add</v-btn>
              </v-card-actions>

            </v-card>
          </v-menu>
        </v-col>
      
        <v-divider vertical></v-divider>
          <v-col sm="auto" class="mb-2 ml-2">
            <v-chip
              class="mr-1 mt-2"
              v-for="(header, colIndex) of getMapping()"
              :key="header.uid"
              @click:close="handleRemoveColumn(colIndex, mapping)"
              :close="!demo"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="18"
                      left
                      color="grey"
                      v-bind="attrs"
                      v-on="on"
                      >{{ getColumnMeta(header.column).icon }}</v-icon>
                  </template>
                  <span>{{ getColumnMeta(header.column).tooltip }}</span>
                </v-tooltip>
              {{ header.label }}
            </v-chip>
          </v-col>
      </v-row>
    </v-card>
    
  </div>
</template>

<script>
import shortid from 'shortid';

import { DATA_TYPES, NUMBER_FORMATS, NUMBER_ROUNDINGS, QUALIFIER_FORMATS } from '@/constants/leaderboard';
export default {
  props: {
    sourceColumns: {
      type: Array,
      required: true,
    },
    mapping: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      columnForm: false,
      specialColumns: [
        'Rank',
        'Icon',
        'Company Title',
        'Full Name',
        'Avatar',
        'First Name',
        'Last Name',
        'First Name + Last Initial'
      ],
      icons: [
        { label: 'Text', iconCode: '<span class="text-icon mr-3">ABC</span>', tooltip: 'Data Bucket field' },
        { label: 'Number', iconCode: '<span class="text-icon mr-3">123</span>', tooltip: 'Data Bucket field' },
        { label: 'Qualifier', icon: 'flaky', tooltip: 'Leaderboard configuration' },
        { label: 'Boolean', icon: 'flaky', tooltip: 'Leaderboard configuration' },
        { label: 'True/False', icon: 'flaky', tooltip: 'Leaderboard configuration' },
        { label: 'Yes/No', icon: 'flaky', tooltip: 'Leaderboard configuration' },
        { label: 'Avatar', icon: 'account_circle', tooltip: 'System field' },
        { label: 'Icon', icon: 'fas fa-medal', tooltip: 'System field' },
        { label: 'Rank', icon: 'fas fa-sort-numeric-down', tooltip: 'System field' },
        { label: 'Full Name', icon: 'person', tooltip: 'System field' },
        { label: 'First Name', icon: 'person', tooltip: 'System field' },
        { label: 'Last Name', icon: 'person', tooltip: 'System field' },
        { label: 'First Name + Last Initial', icon: 'person', tooltip: 'System field' },
        { label: 'Company Title', icon: 'business', tooltip: 'System field' },
        { label: 'Account Id', icon: 'vpn_key', tooltip: 'System field' },
        { label: 'Target Code', icon: 'vpn_key', tooltip: 'Data Bucket field' },
        { label: 'Target', icon: 'fa-solid fa-bullseye', tooltip: 'Data Bucket field' },
        { label: 'Actual', icon: 'fa-solid fa-database', tooltip: 'Data Bucket field' },
        { label: 'Difference', icon: 'functions', tooltip: 'Data Bucket field' },
        { label: 'Percentage', icon: 'functions', tooltip: 'Data Bucket field' },
        { label: 'Percentage ', icon: 'functions', tooltip: 'Data Bucket field' }, // Note: This one has an added space so not the same as record above
        { label: 'Points', icon: 'stars', tooltip: 'Data Bucket field' },
        { label: 'Claim Points', icon: 'stars', tooltip: 'Data Bucket field' },
        { label: 'Target Points', icon: 'stars', tooltip: 'Data Bucket field' },
        { label: 'Quiz Points', icon: 'stars', tooltip: 'Data Bucket field' },
        { label: 'Total Points', icon: 'stars', tooltip: 'Data Bucket field' }
      ],
      dataTypes: DATA_TYPES,
      numberFormats: NUMBER_FORMATS,
      numberRoundings: NUMBER_ROUNDINGS,
      qualifierFormats: QUALIFIER_FORMATS,
      currentColumn: '',
      currentLabel: '',
      currentType: '',
      currentNumberRounding: '',
      currentNumberFormat: '',
      currentQualifierFormat: '',
      currentQualifierComp: '',
      mappingError: {},
    };
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    currentLeaderboard() {
      return this.$store.state.leaderboard.currentLeaderboard;
    },
    columnMappings: {
      get() {
        return this.currentLeaderboard.columnMappings;
      },
      set(values) {
        this.$store.dispatch('patchCurrentLeaderboard', {
          columnMappings: values,
        });
      },
    },
    headerMappings: {
      get() {
        return this.currentLeaderboard.headerMappings;
      },
      set(values) {
        this.$store.dispatch('patchCurrentLeaderboard', {
          headerMappings: values,
        });
      },
    },
  },
  methods: {
    getMapping () {
      if (this.mapping === 'column') {
        return this.columnMappings
      } else {
        return this.headerMappings
      }
    },
    handleRemoveColumn(index, mapping) {
      if (mapping === 'column') {
        const { columnMappings } = this;
        const newColumnMappings = [
          ...columnMappings.slice(0, index),
          ...columnMappings.slice(index + 1)
        ];
  
        this.$store.dispatch('patchCurrentLeaderboard', {
          columnMappings: newColumnMappings
        });
      } else {
        const { headerMappings } = this;
      const newHeaderMappings = [
        ...headerMappings.slice(0, index),
        ...headerMappings.slice(index + 1)
      ];

      this.$store.dispatch('patchCurrentLeaderboard', {
        headerMappings: newHeaderMappings
      });
      }
    },
    getIcon (item) {
      const arr = this.icons
      const found = arr.find(el => el.label === item.label)
      return found || ''
    },
    getColumnMeta (item) {
      const arr = this.icons
      const found = arr.find(el => el.label === item)
      return found || ''
    },
    handleChangeColumn () {
      this.currentType = '';
      this.currentLabel = '';
      this.currentNumberFormat = '';
      this.currentNumberRounding ='';
      this.currentQualifierFormat = '';
      this.currentQualifierComp = '';
    },
    handleAddColumn () {
      this.mappingError = {};
      if (!this.currentColumn) {
        this.mappingError = {
          ...this.mappingError,
          column: "Column required",
        };
        return;
      }

      if (!this.currentLabel) {
        this.mappingError = {
          ...this.mappingError,
          label: "Label required",
        };
        return;
      }

      if (
        !this.specialColumns.includes(this.currentColumn) &&
        !this.currentType
      ) {
        this.mappingError = {
          ...this.mappingError,
          type: "Type required",
        };
        return;
      }

      if (this.currentType === 'qualifier' && !this.currentQualifierFormat) {
        this.mappingError = {
          ...this.mappingError,
          qualifierFormat: "Format required",
        };
        return;
      }

      if (this.currentType === 'number' && !this.currentNumberRounding) {
        this.mappingError = {
          ...this.mappingError,
          rounding: "Rounding required",
        };
        return;
      }

      const newMapping = {
        uid: shortid.generate(),
        column: this.currentColumn,
        label: this.currentLabel,
        type: this.currentType,
      };

      if (this.currentType === 'qualifier') {
        newMapping.qualifierFormat = this.currentQualifierFormat;
        newMapping.qualifierComp = this.currentQualifierComp
          ? parseFloat(this.currentQualifierComp)
          : this.currentQualifierComp;
      }

      if (this.currentType === 'number') {
        newMapping.format = this.currentNumberFormat;
        newMapping.rounding = this.currentNumberRounding;
      }

      this.$emit('onAdd', newMapping);

      this.columnForm = false;
      this.currentColumn = '';
      this.currentLabel = '';
      this.currentNumberFormat = '';
      this.currentNumberRounding = '';
      this.currentType = '';
      this.currentQualifierFormat = '';
      this.currentQualifierComp = '';
    },
  }
}
</script>

<style scoped>

</style>


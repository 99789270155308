<template>
  <div>
    <v-dialog v-model="dialogEditAnswer" persistent max-width="600px">
      <v-card>
        <v-progress-linear
          :indeterminate="true"
          v-if="processing"
        ></v-progress-linear>
        <v-card-title>
          <span class="page-heading">Edit Answer</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mx-3 mb-0" no-gutters>
            <v-textarea
              outlined
              name="answer"
              label="Answer text"
              v-model="editedAnswer.answer"
            ></v-textarea>
          </v-row>
          <v-row class="mx-3 mb-0" no-gutters>
            <v-text-field
              name="points"
              label="Points"
              v-model="editedAnswer.points"
              type="number"
              @wheel="$event.target.blur()"
            ></v-text-field>
          </v-row>
          <v-row class="mx-3 mb-0" no-gutters>
            <v-checkbox
              name="correct"
              label="Correct Answer"
              v-model="editedAnswer.correct"
            ></v-checkbox>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">cancel</v-btn>
          <v-btn
            color="primary"
            @click="saveAnswer()"
            elevation="0"
            :loading="processing"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogEditAnswer: {
      type: Boolean,
    },
    answer: {
      type: Object,
    },
  },
  created() {
    if (this.dialogEditAnswer) {
      this.editedAnswer = Object.assign({}, this.answer);
    }
  },
  data: () => {
    return {
      processing: false,
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.reset();
      this.$emit("onCancelEditAnswer");
    },
    async saveAnswer() {
      const answer = {
        ...this.editedAnswer,
        points: +this.editedAnswer.points,
      };
      this.reset();
      this.$emit("onConfirmEditAnswer", answer);
    },
    reset() {
      this.processing = false;
    },
  },
};
</script>
<style scoped>
.bold-question {
  font-weight: 700 !important;
}
</style>

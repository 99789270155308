<template>
  <div class="mr-9">
    <v-card class="pa-3 el">
      <v-row no-gutters align="center" justify="end">
        <v-col sm="auto">
          <v-btn @click="handleNew()" color="primary" elevation="0">
            <v-icon>add</v-icon>new question
          </v-btn>
        </v-col>
      </v-row>

      <div class="my-9">
        <v-row no-gutters justify="center">
          <draggable v-model="questions" tag="tbody">
            <v-row
              v-for="(question, key) in questions"
              :key="key"
              no-gutters
              align="center"
              justify="center"
            >
              <v-card
                class="movable mb-2 px-3 py-6 rounded-lg"
                elevation="0"
                width="750"
                outlined
              >
                <v-row justify="end" class="mr-2" align="center">
                  <v-icon color="primary" class="mr-1">stars</v-icon
                  >{{ questionPointsTotal(question) | number }} points
                </v-row>
                <v-row no-gutters align="center">
                  <v-col cols="auto">
                    <v-icon color="grey">drag_indicator</v-icon>
                  </v-col>
                  <v-col>
                    <v-row no-gutters class="pl-4">
                      <span class="mr-4">{{ key + 1 }}.</span>
                      {{ question.text }}
                    </v-row>
                    <v-row no-gutters class="pl-12">
                      <div v-if="question.type === 'radio'">
                        <v-radio-group>
                          <v-radio
                            v-for="(answer, id) in question.answers"
                            :key="id"
                            :label="answer.answer"
                            :value="answer"
                          >
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <div v-if="question.type === 'dropdown'">
                        <v-select
                          :items="question.answers"
                          item-text="answer"
                          item-value="currentAnswer"
                          return-object
                          outlined
                          dense
                          class="mt-5"
                        >
                        </v-select>
                      </div>
                      <div v-if="question.type === 'checkboxes'" class="mb-9">
                        <v-checkbox
                          v-for="(answer, id) in question.answers"
                          :key="id"
                          :label="answer.answer"
                          :value="answer"
                          class="mb-n9"
                          @change="handleCheckbox()"
                        >
                        </v-checkbox>
                      </div>
                    </v-row>
                  </v-col>
                  <v-col cols="auto">
                    <v-menu bottom right close-on-content-click>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"> more_vert </v-icon>
                      </template>
                      <v-list>
                        <v-list-item link @click="editItem(question)">
                          <v-icon class="mr-2">edit</v-icon>
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          link
                          @click="handleDeleteConfirmation(question)"
                        >
                          <v-icon class="mr-2">delete</v-icon>
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
          </draggable>
        </v-row>
      </div>
    </v-card>

    <question-new-or-edit
      :questionDialogOpen="questionDialogOpen"
      :editingItem="editingItem"
      @onClose="closeDialog"
      @onSaveQuestion="saveQuestion"
    />

    <confirm-delete
      :dialogDelete="dialogDelete"
      @onConfirmDelete="confirmDelete"
      @onCancelDelete="cancelDelete"
      :questionText="questionText"
    ></confirm-delete>
  </div>
</template>

<script>
import QuestionNewOrEdit from "./QuestionNewOrEdit.vue";
import ConfirmDelete from "./ConfirmDelete.vue";
export default {
  props: ["quizId"],
  components: { QuestionNewOrEdit, ConfirmDelete },
  data: () => ({
    search: "",
    editingItem: null,
    questionText: null,
    editedIndex: -1,
    questionDialogOpen: false,
    dialogDelete: false,
    shuffled: [],
  }),
  created() {},
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    quiz() {
      return this.$store.state.quiz.quiz;
    },
    questions: {
      get() {
        return this.$store.state.quiz.quiz.questions;
      },
      set(update) {
        this.$store.dispatch("reorderQuizQuestions", { questions: update });
      },
    },
    loading() {
      return this.$store.getters.loading;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    quizChanged() {
      return this.$store.state.quiz.quizChanged;
    },
    quizUpdates() {
      return this.$store.state.quiz.quizUpdates;
    },
    reordering() {
      return this.$store.state.quiz.reorderingQuiz;
    },
  },
  methods: {
    handleReordering(val) {
      this.$store.dispatch("setReorderingQuiz", val);
    },
    handleNew() {
      this.editingItem = null;
      this.questionDialogOpen = true;
    },
    editItem(item) {
      this.editedIndex = this.questions.indexOf(item);
      this.editingItem = Object.assign({}, item);
      this.questionDialogOpen = true;
    },
    closeDialog() {
      this.editingItem = null;
      this.questionDialogOpen = false;
      this.editedIndex = -1;
    },
    handleDeleteConfirmation(item) {
      this.editedIndex = this.questions.indexOf(item);
      this.dialogDelete = true;
      this.questionText = item.text;
    },
    cancelDelete() {
      this.editedIndex = -1;
      this.dialogDelete = false;
    },
    confirmDelete() {
      this.questions.splice(this.editedIndex, 1);
      const update = {
        ...this.quiz,
        ...this.quizUpdates,
      };
      this.$store.dispatch("setQuizChanged", true);
      this.$store.dispatch("patchQuiz", update);
      this.dialogDelete = false;
      this.editedIndex = -1;
    },
    saveQuestion(question) {
      this.editingItem = question;
      if (this.editedIndex > -1) {
        Object.assign(this.questions[this.editedIndex], this.editingItem);
      } else {
        this.questions.push({ ...this.editingItem });
      }
      const update = {
        ...this.quiz,
        ...this.quizUpdates,
      };
      this.$store.dispatch("setQuizChanged", true);
      this.$store.dispatch("patchQuiz", update);
      this.editingItem = null;
      this.editedIndex = -1;
      this.questionDialogOpen = false;
    },
    handleCheckbox() {
      //this.checkboxMaxAlert = false;
      //const selected = this.checkboxSelect.length;
      //const max = 2;
      //if (selected > max) {
      //  this.checkboxSelect.pop();
      //  this.checkboxMaxAlert = true;
      //}
      //this.response[this.step - 1].selection = this.checkboxSelect;
    },
    // Shuffle is not used here. This function is to be moved
    shuffle() {
      this.shuffled = [];
      const array = this.questions;
      let currentIndex = array.length,
        randomIndex,
        temporaryValue;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      this.shuffled = array;
    },
    questionPointsTotal(question) {
      const answers = question.answers;
      return answers.reduce((totalPoints, answer) => {
        if (answer.correct) {
          totalPoints += answer.points || 0;
        }
        return totalPoints;
      }, 0);
    },
  },
  watch: {},
};
</script>

<style scoped>
.pad-reordering {
  margin-top: 53px !important;
}
.handle {
  cursor: pointer;
}
.ghost {
  border: 2px dotted rgb(134, 134, 134);
}
.points-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 2px !important;
  font-size: 14px !important;
}
</style>

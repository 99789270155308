/* eslint-disable no-console */
<template>
  <div class="px-4 pt-4">
    <v-row no-gutters align="center">
      <v-col sm="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field>
      </v-col>

      <v-col sm="3" v-if="options.filter">
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter By Status"
          prepend-inner-icon="filter_list"
          class="mr-4"
          multiple
          :items="statuses"
          v-model="filteredStatus"
        ></v-select>
      </v-col>

      <v-col>
        <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
        <v-btn v-if="options.download" class="primary--text" text
          >Download</v-btn
        >
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
      </v-col>

      <v-col sm="auto">
        <v-btn @click="handleNew()" color="primary" elevation="0" class="mt-n2">
          add new supplier
        </v-btn>
      </v-col>
    </v-row>

    <!-- Data table -->

    <v-data-table
      :headers="headers"
      :items="filteredRewardSuppliers"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link>
              <v-list-item-title @click="handleEdit(item)">
                Edit
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              :disabled="getRewardCountForRewardSupplier(item.id) > 0"
            >
              <v-list-item-title @click="handleDeleteConfirmation(item)"
                >Delete
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status" class="pr-3"></status-icon>
        {{ item.status }}
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <div
          @click="handleEdit(item)"
          class="primary--text"
          style="cursor: pointer"
        >
          {{ item.title }}
        </div>
      </template>

      <template v-slot:[`item.rewards`]="{ item }">
        <div v-if="getRewardCountForRewardSupplier(item.id) > 0">
          <v-avatar size="25" color="grey lighten-2">
            <span class="">{{ getRewardCountForRewardSupplier(item.id) }}</span>
          </v-avatar>
        </div>
      </template>
    </v-data-table>

    <!-- New or Edit dialog -->

    <RewardSuppliersNewOrEdit
      :rewardSupplierId="selectedRewardSupplierId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <!-- Delete dialog -->

    <v-dialog v-model="dialogDeleteOpen" max-width="400px" persistent>
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay absolute color="red" :opacity="systemTheme.cardOpacity">
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading"> Are You Sure? </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          <v-row dense>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ title }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleCloseDeleteDialog()">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RewardSuppliersNewOrEdit from "./RewardSuppliersNewOrEdit.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    RewardSuppliersNewOrEdit,
  },
  data: () => ({
    // Component settings unique to this component
    tableTitle: "Reward Suppliers",
    tableSubtitle: "Create Reward Suppliers to contain reward items",
    tableIcon: "storefront",
    selectedRewardSupplierId: "",
    search: "",
    title: "",
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    filteredStatus: [],

    // Table columns
    headers: [
      { text: "Status", value: "status", align: "left" },
      { text: "Reward Supplier", value: "title", align: "left" },
      { text: "Email", value: "email", align: "left" },
      { text: "Rewards", value: "rewards", align: "center" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],

    // Component settings common to all components
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,

    // Table settings
    options: {
      sortBy: ["title"],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: false,
      download: false,
      more: false,
    },
  }),

  created() {},

  computed: {
    rewardSuppliers() {
      return this.$store.state.rewardsupplier.rewardSuppliers;
    },
    filteredRewardSuppliers() {
      return this.filteredStatus.length
        ? this.rewardSuppliers.filter((m) =>
            this.filteredStatus.includes(m.status)
          )
        : this.rewardSuppliers;
    },
    rewards() {
      return this.$store.state.reward.rewards;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    snackbar() {
      return this.$store.getters.snackbar;
    },
  },
  methods: {
    getRewardCountForRewardSupplier(rewardSupplierId) {
      return this.rewards.filter(
        (item) => item.rewardSupplier === rewardSupplierId
      ).length;
    },

    getMemberTagTitle(id) {
      return this.memberTagTitleMap[id];
    },

    getCompanyTagTitle(id) {
      return this.companyTagTitleMap[id];
    },
    getStatusIcon(item) {
      if (item === "Active") {
        return "check_circle";
      } else if (item === "Inactive") {
        return "not_interested";
      }
    },

    handleNew() {
      // Open New form
      this.selectedRewardSupplierId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedRewardSupplierId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedRewardSupplierId = "";
      this.dialogNewOrEditOpen = false;
    },
    handleDeleteConfirmation(item) {
      // Delete record
      this.selectedRewardSupplierId = item.id;
      this.title = item.title;
      this.dialogDeleteOpen = true;
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedRewardSupplierId = "";
      this.dialogDeleteOpen = false;
    },
    handleDelete() {
      // Delete reward Supplier
      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("deleteRewardSupplier", this.selectedRewardSupplierId)
        .then(() => (this.dialogDeleteOpen = false))
        .catch(() => (this.dialogDeleteOpen = false));
      this.$store.dispatch("setLoading", false);
    },
  },
};
</script>

<style></style>

/* eslint-disable no-console */
<template>

  <div class="mt-8 mr-8 mb-8">
    <v-card 
      class="grey lighten-4 rounded-t-lg rounded-b-0"
      elevation="8"
      >
      <v-card-title class="table-title">
        <v-icon class="pr-1">{{ tableIcon }}</v-icon>
        {{ tableTitle }}
      </v-card-title>
      <v-card-subtitle class="table-subtitle">
        {{ tableSubtitle }}
      </v-card-subtitle> 
    </v-card>

    <v-card
      class="rounded-t-0 rounded-b-lg"
      elevation="8"
      >
      <v-card-title>
        <v-row
          no-gutters
          justify="space-between"
          align="center"
          cols="12"
          >
          <v-col lg="3">
            <v-text-field
              v-model="search"
              solo-inverted
              dense
              flat
              hide-details
              label="Search"
              prepend-inner-icon="search"
              class="mr-4"
            ></v-text-field> 
          </v-col>
          
          <v-col
            lg="2"
            v-if="options.filter"
            >
            <v-select
              solo-inverted
              dense
              flat
              hide-details
              label="Filter"
              prepend-inner-icon="filter_list"
              class="mr-4"
            ></v-select> 
          </v-col>
          
          <v-col>
            <v-btn
              @click="handleOpenImportDialog()"
              v-if="options.upload"
              class="primary--text"
              text
              >Upload</v-btn>
            <v-btn 
              v-if="options.download"
              class="primary--text"
              text
              :href="companiesCsv"
              :download="getFilename()"
              >Download</v-btn> 
            <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
          </v-col>
         
          <v-col>
            <v-btn
              class="float-right mt-n16 mr-4"
              @click="handleNew()"
              color="primary"
              fab
              elevation="2"
              >
              <v-icon :color="orgTheme.buttonIconColor">add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

  <!-- Data table -->

      <v-data-table
      class="px-6 py-4"
        :headers="headers"
        :items="adminTableData"
        :search="search"
        :options.sync="options"
        :loading="loadingTable"
        no-data-text="No Records..."
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
            close-on-content-click
          >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  link
                  :disabled="!isRoleSatisfied('Editor')"
                  >
                  <v-list-item-title
                    @click="handleEdit(item)"
                    >Edit
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  :disabled="!isRoleSatisfied('Editor')"
                  >
                  <v-list-item-title 
                    @click="handleDeleteConfirmation(item)"
                    >Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
        </template>

        <template v-slot:[`item.created`]="{ item }">
          {{ item.created | date }}
        </template>

        <template v-slot:[`item.updated`]="{ item }">
          {{ item.updated | date }}
        </template>
        
        <template v-slot:[`item.status`]="{ item }">
          <status-icon :status="item.status" class="pr-3"></status-icon>
          {{ item.status }}
        </template>
        
        <template v-slot:[`item.title`]="{ item }">
          <div
            v-if="isRoleSatisfied('Editor')"
            @click="handleEdit(item)"
            class="primary--text"
            style="cursor:pointer"
            >
            {{ item.title }}
            </div>
          <div
            v-else
            >
            {{ item.title }}
          </div>
        </template>
      
      </v-data-table>
      
    </v-card>

  <!-- New or Edit dialog -->

    <org-admin-add-or-edit
      v-if="dialogNewOrEditOpen"
      :editingAdmin="editingAdmin"
      :activeCount="activeCount"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />
  
  <!-- Delete dialog -->
    
    <org-admin-delete
      v-if="deletingAdmin"
      :deletingAdmin="deletingAdmin"
      :activeCount="activeCount"
      :open="dialogDeleteOpen"
      @onClose="handleCloseDeleteDialog"
    />
    
  
  
  </div>

</template>

<script>
  export default {
    data: () => ({
      // Component settings unique to this component
      tableTitle: 'Team Members',
      tableSubtitle: 'Provide coworkers or clients administration access to your dashboard',
      tableIcon: 'face',
      search: '',
      title: '',
      editingAdmin: null,
      deletingAdmin: null,

      // Table columns
      headers: [
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Name', value: 'name', align: 'left' },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Role', value: 'role', align: 'left' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],

      // Component settings common to all components
      dialogNewOrEditOpen: false,
      dialogDeleteOpen: false,

      // Table settings
      options: {
        sortBy: ['title'],
        sortDesc: [false],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: true,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
    }),
    computed: {
      // Functions common to all components
      loadingTable () {
        return this.$store.getters.loadingTable
      },
      loading () {
        return this.$store.getters.loading
      },
      orgTheme () {
        return this.$store.getters.orgTheme
      },
      systemTheme () {
        return this.$store.getters.systemTheme
      },
      currentOrganisation () {
        return this.$store.state.organisation.currentOrganisation
      },
      admins () {
        return this.$store.getters.admins
      },
      organisationAdmins () {
        return this.currentOrganisation.admins || []
      },
      adminTableData () {
        return this.organisationAdmins.reduce((acc, item) => {
          const foundAdmin = this.admins.find(admin => admin.email === item.adminEmail)
          if (foundAdmin) {
            return [
              ...acc,
              {
                ...foundAdmin,
                role: item.adminRole,
                status: 'Active'
              },
            ];
          }
          return [
            ...acc,
            {
              role: item.adminRole,
              email: item.adminEmail,
              name: '',
              userId: '',
              status: 'Pending'
            },
          ];
        }, []);
      },
      activeCount () {
        const filt = this.adminTableData.filter((item) => {
          return item.role === 'Owner' && item.status === 'Active'
        })
        return filt.length
      },
    },

    methods: {
      handleNew() {
        // Open New form
        this.editingAdmin = {};
        this.dialogNewOrEditOpen = true;
      },
      handleEdit(item) {
        // Open Edit form
        this.editingAdmin = item;
        this.dialogNewOrEditOpen = true;
      },
      handleCloseNewOrEditDialog() {
        // Close New Or Edit form
        this.editingAdmin = null;
        this.dialogNewOrEditOpen = false;
      },
      handleDeleteConfirmation (item) {
        // Open delete form
        this.deletingAdmin = item;
        this.dialogDeleteOpen = true;
      },
      handleCloseDeleteDialog() {
        // Close delete form
        this.deletingAdmin = null;
        this.dialogDeleteOpen = false;
      },
    }
  }
</script>

<style>


</style>

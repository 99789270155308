/* eslint-disable no-console */
<template>
  <div class="mt-8 mr-8 mb-8">
    <v-row no-gutters align="center">
      <v-col sm="3">
        <v-select
          v-model="selectedLanguage"
          :items="translations"
          item-text="title"
          item-value="locale"
          label="Default language"
          prepend-inner-icon="translate"
          class="mr-4"
          @change="handleChange"
        ></v-select>
        <v-checkbox
          label="Hide translate selector"
          v-model="selectedHide"
          @change="handleChange"
        ></v-checkbox>
      </v-col>

      <v-col>
        <v-btn
          @click="save()"
          :disabled="demo || !changed"
          color="primary"
          elevation="0"
          class="mt-n5"
          >Save</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    // Component settings unique to this component
    search: "",
    title: "",
    changed: false,
    selectedLanguage: "",
    selectedHide: false,
    english: { locale: "en", title: "EN - English" },

    // Table columns
    headers: [
      { text: "Status", value: "status", align: "left" },
      { text: "Language", value: "title", align: "left" },
      { text: "Translations", value: "translations", align: "center" },
      { text: "Created", value: "created", align: "right" },
      { text: "Updated", value: "updated", align: "right" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],

    // Component settings common to all components
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,

    // Table settings
    options: {
      sortBy: ["title"],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
  }),

  created() {
    this.selectedLanguage = this.translate.locale || "en";
    this.selectedHide = this.translate.hide || false;
  },

  computed: {
    // Functions specific to this component
    translations() {
      let arr = [];
      arr = arr
        .concat(this.english)
        .concat(this.$store.state.translate.translations);
      return arr;
    },

    // Functions common to all components
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    translate() {
      return this.$store.state.program.currentProgram.translate;
    },
  },

  methods: {
    save() {
      const payload = {
        locale: this.selectedLanguage,
        hide: this.selectedHide,
      };
      this.$store.dispatch("saveDefaultLocale", payload);
      this.changed = false;
    },
    handleChange() {
      this.changed = true;
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-dialog
      v-model="previewDialog"
      persistent
      max-width="650px"
      transition="dialog-transition"
    >
      <v-card class="el">
        <v-card-title>
          <span class="page-heading">{{ quiz.title }}</span>
          <v-row no-gutters justify="end" class="incentable-form-heading"
            >{{ stageDescription }}
          </v-row>
        </v-card-title>
        <v-card-text class="mt-1 mb-4">
          <v-row
            no-gutters
            v-if="quiz.media.youTubeId && step === 0"
            justify="center"
          >
            <v-card width="590px" class="el">
              <vue-plyr :options="options">
                <div
                  data-plyr-provider="youtube"
                  :data-plyr-embed-id="quiz.media.youTubeId"
                ></div>
              </vue-plyr>
            </v-card>
          </v-row>
          <div class="mt-3 mx-2">
            {{ quiz.description }}
          </div>
          <v-row
            v-if="currentQuestion !== null"
            no-gutters
            class="mt-5"
            justify="center"
          >
            {{ currentQuestion.text }}
          </v-row>
          <v-row
            v-if="stageDescription !== 'Results' && step > 0"
            no-gutters
            justify="center"
          >
            <div v-if="currentQuestion.type === 'radio'">
              <v-radio-group v-model="response[step - 1].selection">
                <v-radio
                  v-for="(answer, id) in currentAnswer"
                  :key="id"
                  :label="answer.answer"
                  :value="answer"
                >
                </v-radio>
              </v-radio-group>
            </div>
            <div v-if="currentQuestion.type === 'dropdown'">
              <v-select
                v-model="response[step - 1].selection"
                :items="currentAnswer"
                item-text="answer"
                item-value="currentAnswer"
                return-object
                outlined
                dense
                class="mt-5"
              >
              </v-select>
            </div>
            <div v-if="currentQuestion.type === 'checkboxes'" class="mb-4">
              <v-checkbox
                v-for="(answer, id) in currentAnswer"
                :key="id"
                v-model="checkboxSelect"
                :label="answer.answer"
                :value="answer"
                class="mb-n9"
                @change="handleCheckbox()"
              >
              </v-checkbox>
            </div>
          </v-row>
          <v-row no-gutters>
            <v-alert color="primary" text :value="checkboxMaxAlert">
              Maximum reached
            </v-alert>
          </v-row>
          <div v-if="stageDescription === 'Results'">
            <v-row no-gutters justify="center">
              <v-icon v-if="passed" color="green" size="90" class="mt-6"
                >task_alt</v-icon
              >
              <v-icon v-else color="red" size="90" class="mt-6"
                >highlight_off</v-icon
              >
            </v-row>
            <v-row no-gutters class="mt-6 page-heading" justify="center">
              You scored {{ rateAchieved }}%
            </v-row>
            <v-row no-gutters class="mt-0" justify="center">
              The pass rate is {{ passRate }}%
            </v-row>
            <v-row no-gutters class="mt-6" justify="center">
              You answered {{ score.correct }} out of
              {{ numberOfQuestions }} questions correctly
            </v-row>
            <v-row
              no-gutters
              class="mt-6 incentable-form-heading"
              justify="center"
            >
              You earned {{ pointsEarned | number }} points
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="onClose">close</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="stageDescription !== 'Results' && step > 0"
            color="primary"
            @click="back"
            elevation="0"
            text
            >back</v-btn
          >
          <v-btn
            v-if="stageDescription !== 'Results'"
            color="primary"
            @click="next"
            elevation="0"
            >next</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["previewDialog"],
  created() {},
  data: () => {
    return {
      step: 0,
      initialized: false,
      selection: null,
      checkboxSelect: [],
      checkboxMaxAlert: false,
      answer: "",
      youTubeId: "",
      response: [],
      options: {
        autopause: true,
        resetOnEnd: true,
        hideYouTubeDOMError: true,
        youtube: {
          noCookie: true,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          controls: 0,
        },
      },
    };
  },
  computed: {
    quiz() {
      return this.$store.getters.quiz;
    },
    questions() {
      return this.$store.getters.quiz.questions;
    },
    count() {
      if (this.previewDialog === true) {
        const arr = this.response;
        return arr.length;
      }
      return 0;
    },
    currentQuestion() {
      const questions = this.response;
      if (this.step > 0 && this.step <= this.count) {
        return questions[this.step - 1];
      }
      return null;
    },
    currentAnswer() {
      const questions = this.response;
      if (this.step > 0 && this.step <= this.count) {
        return questions[this.step - 1].answers;
      }
      return null;
    },
    stageDescription() {
      if (this.step === 0 && this.step < this.count + 1) {
        return "Introduction";
      } else if (this.step === this.count + 1) {
        return "Results";
      } else if (this.step > 0 && this.step < this.count + 1) {
        return "Question " + this.step + " of " + this.count;
      }
      return null;
    },
    score() {
      const pointsForPass = this.quiz.passPoints;
      const totalPoints = this.sumCorrectPoints(this.response);
      const correctCount = this.countCorrectQuestions(this.response);
      return {
        points: totalPoints + pointsForPass,
        correct: correctCount,
      };
    },
    passRate() {
      return this.quiz.passRate;
    },
    passed() {
      if (this.rateAchieved >= this.passRate) {
        return true;
      }
      return false;
    },
    pointsEarned() {
      if (this.rateAchieved >= this.passRate) {
        return this.score.points;
      }
      return 0;
    },
    rateAchieved() {
      if (this.score.correct > 0) {
        const score = this.score.correct;
        const questions = this.response.length;
        const rate = score / questions;
        return Math.round(rate * 100);
      }
      return 0;
    },
    numberOfQuestions() {
      if (this.response.length > 0) {
        return this.response.length;
      }
      return 0;
    },
  },
  methods: {
    onClose() {
      this.$emit("onClosePreview", false);
      this.step = 0;
      this.initialized = false;
      this.response = [];
      this.selection = null;
      this.checkboxMaxAlert = false;
      this.checkboxSelect = [];
    },
    next() {
      this.checkboxSelect = [];
      if (this.step === 0 && this.initialized === false) {
        const arr = [...this.questions];
        const updatedArr = arr.map((obj) => {
          return {
            ...obj,
            selection: "",
          };
        });
        this.response = updatedArr;
        this.initialized = true;
      }
      this.step += 1;
      this.selection = null;
    },
    back() {
      this.step -= 1;
    },
    handleCheckbox() {
      this.checkboxMaxAlert = false;
      const selected = this.checkboxSelect.length;
      const max = 2;
      if (selected > max) {
        this.checkboxSelect.pop();
        this.checkboxMaxAlert = true;
      }
      this.response[this.step - 1].selection = this.checkboxSelect;
    },
    sumCorrectPoints(responseArray) {
      let totalPoints = 0;

      responseArray.forEach((question) => {
        if (question.type === "radio" || question.type === "dropdown") {
          // For radio and dropdown type questions, check if any of the selected answers are correct
          if (question.selection) {
            const selectedAnswers = Array.isArray(question.selection)
              ? question.selection.map((s) => s.answer)
              : [question.selection.answer];

            const correctAnswers = question.answers
              .filter((a) => a.correct)
              .map((a) => a.answer);

            const anyCorrect = selectedAnswers.some((selectedAnswer) =>
              correctAnswers.includes(selectedAnswer)
            );

            if (anyCorrect) {
              totalPoints += question.selection.points;
            }
          }
        } else if (question.type === "checkboxes") {
          // For checkboxes type questions, add points if all selected answers are correct
          if (Array.isArray(question.selection)) {
            const selectedAnswers = question.selection.map((s) => s.answer);
            const correctAnswers = question.answers
              .filter((a) => a.correct)
              .map((a) => a.answer);
            const allCorrect =
              selectedAnswers.sort().join() === correctAnswers.sort().join();
            if (allCorrect) {
              totalPoints += question.selection.reduce(
                (acc, selectedAnswer) => acc + selectedAnswer.points,
                0
              );
            }
          }
        }
      });

      return totalPoints;
    },
    countCorrectQuestions(responseArray) {
      let correctCount = 0;

      responseArray.forEach((question) => {
        if (question.type === "radio" || question.type === "dropdown") {
          // For radio and dropdown type questions, check if any of the selected answers are correct
          if (question.selection) {
            const selectedAnswers = Array.isArray(question.selection)
              ? question.selection.map((s) => s.answer)
              : [question.selection.answer];

            const correctAnswers = question.answers
              .filter((a) => a.correct)
              .map((a) => a.answer);

            const anyCorrect = selectedAnswers.some((selectedAnswer) =>
              correctAnswers.includes(selectedAnswer)
            );

            if (anyCorrect) {
              correctCount++;
            }
          }
        } else if (question.type === "checkboxes") {
          // For checkboxes type questions, check if all selected answers are correct
          if (Array.isArray(question.selection)) {
            const selectedAnswers = question.selection.map((s) => s.answer);
            const correctAnswers = question.answers
              .filter((a) => a.correct)
              .map((a) => a.answer);
            const allCorrect =
              selectedAnswers.sort().join() === correctAnswers.sort().join();
            if (allCorrect) {
              correctCount++;
            }
          }
        }
      });

      return correctCount;
    },
  },
};
</script>
<style>
.incentable-question {
  font-size: 16px;
}
</style>

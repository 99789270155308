<template>
  <div class="px-4">
    <v-card class="pa-6 el mr-6">
      <v-row no-gutters>
        <v-col md="6" class="mr-6">
          <v-text-field
            @input="changed()"
            label="Title"
            type="text"
            name="title"
            v-model="title"
          />
          <v-text-field
            @input="changed()"
            label="Display Title"
            type="text"
            name="displayTitle"
            v-model="displayTitle"
          />
          <v-textarea
            @input="changed()"
            label="Description"
            type="text"
            name="description"
            v-model="description"
            outlined
          />
          <v-select
            @input="changed()"
            v-model="companyTags"
            :items="availableCompanyTags"
            item-text="tag"
            item-value="id"
            label="Company Tags"
            chips
            multiple
            small-chips
            deletable-chips
            append-icon="local_offer"
          >
          </v-select>

          <v-select
            @input="changed()"
            v-model="memberTags"
            :items="availableMemberTags"
            item-text="tag"
            item-value="id"
            label="Member Tags"
            chips
            multiple
            small-chips
            deletable-chips
            append-icon="local_offer"
          >
          </v-select>
        </v-col>
        <v-col md="2" class="mr-6">
          <v-select
            @input="changed()"
            :items="availableStatus"
            v-model="status"
            item-text="label"
            item-value="value"
            label="Status"
          />
          <v-select
            @input="changed()"
            :items="categories"
            v-model="category"
            item-text="title"
            item-value="id"
            label="Category"
          />

          <v-checkbox
            @click="changed()"
            label="Shuffle questions"
            v-model="shuffle"
          ></v-checkbox>
        </v-col>
        <v-col md="2">
          <v-text-field
            label="Created By"
            :value="getAuthorName(createdBy)"
            readonly
          />

          <v-text-field
            label="Created at"
            type="text"
            readonly
            :value="created | date"
          />

          <v-text-field
            :value="getAuthorName(updatedBy)"
            label="Updated By"
            type="text"
            readonly
          />

          <v-text-field
            :value="updated | date"
            label="Updated at"
            type="text"
            readonly
          />
        </v-col>
      </v-row>
      <v-row no-gutters justify="start" class="mt-16">
        <v-btn
          color="secondary"
          @click="openDeleteDialog = true"
          :loading="isProcessing"
          elevation="0"
          class="mb-0 ml-0"
        >
          Delete Quiz
        </v-btn>
      </v-row>
    </v-card>

    <v-dialog v-model="openDeleteDialog" max-width="400px" persistent>
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay absolute color="red" :opacity="systemTheme.cardOpacity">
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>

        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading"> Are You Sure? </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          <v-row no-gutters>
            <v-col align="center">
              You are about to delete the quiz
              <span class="incentable-form-bold">{{ title }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="openDeleteDialog = false" text>Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            elevation="0"
            @click="handleDelete()"
            :loading="loading"
            :disabled="demo"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["quizId"],
  data: function () {
    return {
      availableStatus: [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" },
        { label: "Archive", value: "Archive" },
      ],
      openDeleteDialog: false,
      isProcessing: false,
      title: "",
      displayTitle: "",
      shuffle: false,
      created: "",
      updated: "",
      createdBy: "",
      updatedBy: "",
      status: "",
      category: "",
      description: "",
      redeemable: false,
      selectedCompanyTags: [],
      selectedMemberTags: [],
    };
  },
  created() {
    if (this.quizId) {
      this.status = this.quiz.status;
      this.category = this.quiz.category;
      this.title = this.quiz.title;
      this.displayTitle = this.quiz.displayTitle;
      this.description = this.quiz.description;
      this.shuffle = this.quiz.shuffle;
      this.created = this.quiz.created;
      this.updated = this.quiz.updated;
      this.createdBy = this.quiz.createdBy;
      this.updatedBy = this.quiz.updatedBy;
      this.companyTags = this.quiz.companyTags;
      this.memberTags = this.quiz.memberTags;
    }
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    quiz() {
      return this.$store.state.quiz.quiz;
    },
    categories() {
      return this.$store.state.quizcategory.quizCategories;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    availableCompanyTags() {
      return this.$store.state.companytag.companyTags;
    },
    availableMemberTags() {
      return this.$store.state.membertag.memberTags;
    },
    quizAuthorMap() {
      return this.$store.getters.admins.reduce((result, item) => {
        return {
          ...result,
          [item.userId]: item.name,
        };
      }, {});
    },
    quizChanged() {
      return this.$store.state.quiz.quizChanged;
    },
    quizUpdates() {
      return this.$store.state.quiz.quizUpdates;
    },
  },
  methods: {
    handleDelete() {
      const id = this.quiz.id;
      console.log(id);
      this.isProcessing = true;
      this.openDeleteDialog = false;
      this.$store.dispatch("deleteQuiz", id).then(() => {
        this.isProcessing = false;
        this.$router.push(`/quizzes/${this.programId}`);
      });
    },
    getAuthorName(adminId) {
      return this.quizAuthorMap[adminId];
    },
    changed() {
      const update = {
        ...this.quizUpdates,
        title: this.title,
        displayTitle: this.displayTitle,
        status: this.status,
        category: this.category,
        description: this.description,
        shuffle: this.shuffle,
        memberTags: this.memberTags,
        companyTags: this.companyTags,
      };
      this.$store.dispatch("setQuizChanged", true);
      this.$store.dispatch("patchQuiz", update);
    },
  },
  watch: {},
};
</script>

<style scoped></style>

<template>
  <div>
    <v-dialog
      v-model="open"
      persistent
      width="1200px"
    >
      <v-card
        elevation="0"
        >
          <v-card-title class="page-heading">
            Confirm the following changes
          </v-card-title>

          <v-alert
            v-if="blockRedemption"
            color="primary"
            border="left"
            text
            class="mx-6 incentable-alert"
          >
            <v-icon class="ml-3 mr-1" color="primary">error</v-icon>
            This status change cannot proceed because at least one member has insufficient points
          </v-alert>

          <v-alert
            v-if="redeemTotal > 0 && !blockRedemption"
            color="green"
            border="left"
            text
            class="mx-6 incentable-alert"
          >
            <v-icon color="green" class="mr-1 ml-3">stars</v-icon>
            This change will deduct points from members because you are reinstating one or more redemptions that were previously cancelled
          </v-alert>

          <v-card-text>
            
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Reward
                    </th>
                    <th class="text-left">
                      Member
                    </th>
                    <th class="text-left">
                      Status Change
                    </th>
                    <th class="text-right">
                      Points Change
                    </th>
                    <th class="text-right">
                      Points Balance
                    </th>
                    <th class="text-right"></th>
                   
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, id) in itemsFormatted"
                    :key="id"
                  >
                    <td>{{ item.title }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.status }} <v-icon>east</v-icon> {{ selectedStatus }}</td>
                    <td class="text-right">{{ item.result }}</td>
                    <td class="text-right">{{ item.balance | number }}</td>
                    <td v-if="item.check === 'rejected'"> <v-chip v-if="item.check === 'rejected'" color="red" class="white--text">Insufficient Points</v-chip></td>
                    <td v-else></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
        
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="primary"
              @click="handleClose()"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              elevation="0"
              color="primary"
              @click="handleConfirm()"
              :disabled="blockRedemption"
            >
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['open', 'selectedStatus', 'items' ],
  data: () => ({
    total: 0,
  }),
  computed: {
    redeemTotal() {
      if (this.selectedStatus === 'Open') {
        const total = this.items
          .filter(el => el.status === 'Cancelled')
          .map(el => el.each * el.quantity)
          .reduce((n, v) => n + v, 0)
        return total
      }
      return 0
    },
    members () {
      return this.$store.state.member.members
    },
    blockRedemption () {
      var arr = this.itemsFormatted
      if (arr.find(el => el.check === 'rejected')) {
        return true
      }
      return false
    },
    itemsFormatted () {
      const arr = this.items
      let items = []
      arr.forEach((item) => {
        items.push({
          ...item,
          name: this.getMember(item.memberId).firstname + ' ' + this.getMember(item.memberId).lastname,
          balance: this.getMember(item.memberId).balance,
          result: this.resultingAction(item),
          check: this.checkRedeemOk(item)
        })
      })
      return items
    }
  },
  methods: {
    handleClose () {
      this.$emit('onClose')
    },
    handleConfirm() {
      this.$emit('onConfirm')
    },
    getMember(memberId) {
      return this.members.find((el) => el.id === memberId)
    },
    checkRedeemOk(item) {
      const changeType = item.status + this.selectedStatus
      if (changeType === 'CancelledOpen' || changeType === 'CancelledProcessing' || changeType === 'CancelledShipping' || changeType === 'CancelledCompleted') {
        const val = +item.each * item.quantity
        const balance = this.getMember(item.memberId).balance
        if (val > balance) {
          return 'rejected'
        }
        return ''
      }
      return ''
    },
    resultingAction(item) {
      const changeType = item.status + this.selectedStatus
      let message
      const val = this.$options.filters.number(+item.each * item.quantity)
      
      switch(changeType) {
          
          case 'CancelledOpen' :
            message = '-' + val
          break
          
          case 'CancelledProcessing' :
            message = '-' + val
          break
          
          case 'CancelledShipping' :
            message = '-' + val
          break
          
          case 'CancelledCompleted' :
            message = '-' + val
          break
          
          case 'OpenCancelled':
            message = '+' + val
          break
          
          case 'ProcessingCancelled':
            message = '+' + val
          break
          
          case 'ShippingCancelled':
            message = '+' + val
          break
          
          case 'CompletedCancelled':
            message = '+' + val
          break
          
          default: 
            message = '0'
        }

      return message
    }
  }
}
</script>
/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  rewardCategories: [],
};

const actions = {
  async loadRewardCategories({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewardCategories")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const rewardCategories = [];
    querySnapshot.forEach(function (doc) {
      rewardCategories.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created ? doc.data().created.toDate() : "",
        updated: doc.data().updated ? doc.data().updated.toDate() : "",
      });
    });

    commit("setRewardCategories", rewardCategories);
    commit("setLoadingTable", false);
  },

  async createRewardCategory({ commit, getters }, payload) {
    const rewardCategoriesCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("rewardCategories");

    let existingRewardCategoryResult;
    try {
      existingRewardCategoryResult = await rewardCategoriesCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing reward category";
    }

    if (existingRewardCategoryResult.size > 0) {
      return "duplication";
    }

    const rewardCategory = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newCategoryRef;
    try {
      newCategoryRef = await rewardCategoriesCollection.add(rewardCategory);
    } catch (e) {
      throw "Error when creating a new category";
    }

    // Note: server time is unavailable until we refetch.
    const tempRewardCategory = {
      ...rewardCategory,
      id: newCategoryRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createRewardCategory", tempRewardCategory);
    commit("setSnackbar", "Category added");

    return "ok";
  },

  async updateRewardCategory({ commit, getters }, payload) {
    const rewardCategoriesCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("rewardCategories");

    let existingRewardCategoryResult;
    try {
      existingRewardCategoryResult = await rewardCategoriesCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing category";
    }

    let duplicated = false;
    existingRewardCategoryResult.forEach((doc) => {
      if (doc.id !== payload.id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    const rewardCategory = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await rewardCategoriesCollection.doc(payload.id).update(rewardCategory);
    } catch (e) {
      throw "Error when updating a category";
    }

    const rewardCategoryBeforeUpdate = getters.rewardCategories.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempRewardCategory = {
      ...rewardCategoryBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updateRewardCategory", tempRewardCategory);
    commit("setSnackbar", "Reward Category updated");

    return "ok";
  },

  async deleteRewardCategory({ commit, getters }, id) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewardCategories")
        .doc(id)
        .delete();
    } catch (e) {
      throw "Error when deleting an reward category";
    }
    commit("deleteRewardCategory", id);
    commit("setSnackbar", "Reward Category deleted");
  },

  async importRewardCategories({ getters }, categoriesAry) {
    console.log(categoriesAry);
    const newRewardCategoriesBatch = db.batch();

    categoriesAry.forEach((item) => {
      const data = {
        title: item,
        titleUppercase: item.toUpperCase(),
        created: timestamp,
        updated: timestamp,
        createdBy: getters.user.id,
        updatedBy: getters.user.id,
      };
      const newRewardCategoriesRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewardCategories")
        .doc();
      newRewardCategoriesBatch.set(newRewardCategoriesRef, data);
    });

    try {
      await newRewardCategoriesBatch.commit();
    } catch (e) {
      throw "Reward categories import batch failed";
    }
  },
};

const mutations = {
  setRewardCategories(state, payload) {
    state.rewardCategories = payload;
  },

  createRewardCategory(state, payload) {
    state.rewardCategories = [...state.rewardCategories, payload];
  },

  updateRewardCategory(state, payload) {
    state.rewardCategories = state.rewardCategories.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteRewardCategory(state, payload) {
    state.rewardCategories = state.rewardCategories.filter(
      (item) => item.id !== payload
    );
  },
};

const getters = {
  rewardCategories(state) {
    return state.rewardCategories;
  },
  categoriesMap(state) {
    return state.rewardCategories.reduce((result, item) => {
      return {
        ...result,
        [item.id]: item.title,
      };
    }, {});
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

export const REWARDS_PREVIEW_DEFAULTS = {
  title: {
    align: 'start',
    boxHeight: 40,
    color: '#E91E63FF',
    cols: 12,
    field: 'title',
    floatText: 'text-left',
    font: 'Lato, sans-serif',
    justify: 'start',
    lineHeight: 1.1,
    marginLeft: 50,
    marginRight: 0,
    size: 22,
    style: 'normal',
    value: 'Rewards',
    weight: 'normal',
  },
  category: {
    align: 'start',
    boxHeight: 40,
    color: '#E91E63FF',
    cols: 12,
    field: 'category',
    floatText: 'text-left',
    font: 'Lato, sans-serif',
    justify: 'start',
    lineHeight: 1.1,
    marginLeft: 50,
    marginRight: 0,
    size: 18,
    style: 'normal',
    value: 'Rewards',
    weight: 'normal',
  },
  heading: {
    align: 'start',
    boxHeight: 29,
    color: '#E91E63FF',
    cols: 12,
    field: 'heading',
    floatText: 'text-left',
    font: 'Roboto, sans-serif',
    justify: 'start',
    lineHeight: 1.6,
    marginLeft: 0,
    marginRight: 0,
    size: 20,
    style: 'normal',
    value: 'heading',
    weight: 'normal',
  },
  body: {
    align: 'start',
    color: '#FAFAFA',
    field: 'body',
    floatText: 'text-left',
    font: 'Quicksand, sans-serif',
    justify: 'start',
    size: 13,
    boxHeight: 10,
    lineHeight: 1.4,
    marginLeft: 0,
    marginRight: 0,
    cols: 12,
    style: 'normal',
    value: 'body',
    weight: 'normal',
  },
  points: {
    align: 'start',
    boxHeight: 8,
    color: '#757575FF',
    cols: 12,
    floatText: 'text-right',
    font: 'Quicksand, sans-serif',
    justify: 'start',
    lineHeight: 1.1,
    marginLeft: 0,
    marginRight: 0,
    size: 14,
    style: 'normal',
    weight: 'normal',
    field: 'points',
    value: 'points',
  },
  date: {
    align: 'start',
    boxHeight: 8,
    color: '#757575FF',
    cols: 12,
    floatText: 'text-left',
    font: 'Quicksand, sans-serif',
    justify: 'start',
    lineHeight: 1.1,
    marginLeft: 0,
    marginRight: 0,
    size: 14,
    style: 'normal',
    weight: 'normal',
    field: 'date',
    value: 'date',
  },
  image: {
    show: true,
    height: 250,
  },
  card: {
    width: 400,
    height: 450,
    autoHeight: true,
    borderSize: 0,
    borderColor: { 
      alpha: 1, 
      hex: '#E91E63' ,
      hexa: '#E91E63FF',
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233
      } 
    },
    elevation: 0,
    rounded: 'rounded-lg',
    color: {
      type: 'solid',
      solidColor: { 
        alpha: 0, 
        hex: '#000000' ,
        hexa: '#00000000',
        hsla: {
          a: 0,
          h: 0,
          l: 0,
          s: 0,
        },
        hsva: {
          a: 0,
          h: 0,
          s: 0,
          v: 0,
          hue: 0,
        },
        rgba: {
          a: 0,
          b: 0,
          g: 0,
          r: 0
        } 
      },
      gradientFrom: { 
        alpha: 1, 
        hex: '#E91E63' ,
        hexa: '#E91E63FF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233
        } 
      },
      gradientTo: { 
        alpha: 1, 
        hex: '#94143F' ,
        hexa: '#94143FFF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148
        } 
      },
      direction: 'to bottom',
      gradientHeight: 100
    },
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    textAreaPadding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    margin: {
      top: 0,
      bottom: 15,
      left: 0,
      right: 20,
    },
    group: {
      cols: 12,
      slider: 'slide',
      dark: true,
    }
  },
  settings: {
    cols: 11,
    previewRows: 3,
    selectedRewardCategory: '',
    selectedRewardCatalogue: '',
  },
  block: {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  dialog: {
    borderSize: 0,
    borderColor: { 
      alpha: 1, 
      hex: '#E91E63' ,
      hexa: '#E91E63FF',
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233
      } 
    },
    buttonText: {
      color: 'white--text'
    },
    buttonColor: { 
      alpha: 1, 
      hex: '#E91E63' ,
      hexa: '#E91E63FF',
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233
      } 
    },
    color: {
      type: 'solid',
      solidColor: { 
        alpha: 1, 
        hex: '#212121' ,
        hexa: '#212121FF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233
        } 
      },
      gradientFrom: { 
        alpha: 1, 
        hex: '#E91E63' ,
        hexa: '#E91E63FF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.5156862745098039,
          s: 0.8218623481781376,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.871244635193133,
          v: 0.9137254901960784,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 99,
          g: 30,
          r: 233
        } 
      },
      gradientTo: { 
        alpha: 1, 
        hex: '#94143F' ,
        hexa: '#94143FFF',
        hsla: {
          a: 1,
          h: 339.60591133004925,
          l: 0.3286666666666667,
          s: 0.7647058823529411,
        },
        hsva: {
          a: 1,
          h: 339.60591133004925,
          s: 0.8666666666666667,
          v: 0.5800000000000001,
          hue: 339.60591133004925,
        },
        rgba: {
          a: 1,
          b: 63,
          g: 20,
          r: 148
        } 
      },
      direction: 'to bottom',
      gradientHeight: 100
    },
    width: 700,
    fullscreen: false,
    image: {
      show: true,
      height: 300
    },
    title: {
      align: 'center',
      boxHeight: 120,
      color: '#FFFFFFFF',
      cols: 12,
      field: 'dialogTitle',
      floatText: 'text-center',
      font: 'Quicksand, sans-serif',
      justify: 'start',
      lineHeight: 1.5,
      marginLeft: 0,
      marginRight: 0,
      size: 40,
      style: 'normal',
      value: 'Pop Up Heading',
      weight: 'normal',
    },
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    textAreaPadding: {
      top: 30,
      bottom: 0,
      right: 40,
      left: 40,
    },
    summary: {
      margin: {
        top: 0,
        bottom: 20,
        right: 30,
        left: 30,
      },
    },
    detail: {
      margin: {
        top: 0,
        bottom: 20,
        right: 30,
        left: 30,
      },
    }
  }
};

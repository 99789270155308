<template>
  <div>
      
    <loading-cards 
      v-if="loadingCards"
      :count="cardCount"
    />
    
    <!-- Card Table-->
    <v-row v-if="!loadingCards" no-gutters class="mt-3">
      <v-col
        class="pa-3"
        md="4"
        xs="12"
      >
        <v-card
          style="height: 245px"
          class="el"
        >
          <v-img
            :src="require('@/assets/background.png')"
            :height="systemTheme.cardImageHeight"
          >
            <v-overlay
              absolute
              color="black"
              :opacity="systemTheme.cardOpacity"
            >
            </v-overlay>
          </v-img> 
         
            <v-btn
              class="mt-n7"
              elevation="0"
              right
              absolute
              fab
              color="primary"
              @click="open()">
              <v-icon :color="orgTheme.buttonIconColor">add</v-icon>
            </v-btn>
            <v-card-title>
              <div class="incentable-card-title" @click="open()">Create an Organisation</div>
            </v-card-title>
            <v-card-subtitle class="incentable-card-subtitle">It's fast and easy</v-card-subtitle>
            <v-card-actions style="height: 60px">
              <v-spacer></v-spacer>
              <!--
              <div class="incentable-card-action-text">Show me how</div>
              <v-btn icon @click.stop="toggleHowTo()"><v-icon color="grey lighten-1">fab fa-youtube</v-icon></v-btn>
              -->
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col
          v-for="(organisation, id) in filteredOrganisations"
          :key="id"
          class="pa-3"
          md="4"
          xs="12"
        >
          <v-card
            class="el"
            style="height: 245px"
            >
            <v-img
              :src="require('@/assets/background.png')"
              :height="systemTheme.cardImageHeight"
            >
            <v-overlay
              absolute
              color="primary"
              :opacity="systemTheme.cardOpacity"
            >
            </v-overlay>
            </v-img> 
            <v-btn
              class="mt-n7"
              elevation="0"
              right
              absolute
              fab
              color="primary"
              @click="onLoadOrganisation(organisation)">
              <v-icon :color="orgTheme.buttonIconColor">arrow_forward</v-icon>
            </v-btn>
            <v-card-title>
              <div class="incentable-card-title" @click="onLoadOrganisation(organisation)">{{organisation.title}}</div>
            </v-card-title>
            <v-card-subtitle
              v-if="organisation.subdomainConnected"
              class="incentable-card-subtitle">
              <a :href="`https://${organisation.orgSubdomain}.incentive.studio`" 
                class="link"
                target="_blank">
                {{organisation.orgSubdomain}}.incentive.studio
              </a>
            </v-card-subtitle>
            <v-card-subtitle
              v-else
              class="incentable-card-subtitle">
              {{organisation.orgSubdomain}}.incentive.studio
            </v-card-subtitle>
            <v-card-actions style="height: 60px">
              <v-spacer></v-spacer>
              <v-btn v-if="organisation.subdomainConnected" icon><v-icon color="grey lighten-1">desktop_windows</v-icon></v-btn>
            </v-card-actions>
          </v-card>
      </v-col>
    </v-row>
    <!-- End Card Table-->
    
    <organisation-add />

  </div>

</template>

<script>
export default {
  data: () => ({
    pageTitle: 'Organisations',
    pageSubTitle: 'Add your organisation to create a white labeled administration dashboard and custom subdomain',
    search: '',
    searchActive: true,
    dictionary: {
      custom: {
      }
    }
  }),
  created () {
    this.$store.dispatch('initialize')
    this.$store.commit('setNavMenu', false)
    this.$store.dispatch('setPreview', false)
    this.$store.dispatch('setToolbar', true)
    this.$store.dispatch('setFooter', true)
    this.$store.dispatch('loadOrganisations')
  },
  mounted () {
  },
  computed: {
    organisations () {
      return this.$store.state.organisation.organisations
    },
    filteredOrganisations() {
      const array = this.organisations
      if (this.search.length > 0) {
        var input = this.search.toUpperCase()
        var inputLower = this.search.toLowerCase()
        var filtered = array.filter(item => item.titleUppercase.includes(input) || item.orgSubdomain.includes(inputLower))
        return filtered
        }
        return array
    },
    cardCount () {
      return 6
    },
    loadingCards () {
      return this.$store.getters.loadingCards
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    }
  },
  methods: {
    onLoadOrganisation (organisation) {
      this.$router.push('/organisation/' + organisation.id)
      this.$store.dispatch('selectOrganisation', organisation)
    },
    open () {
      this.title = ''
      this.orgSubdomain = ''
      this.terms = ''
      this.primary = null
      this.subCheck = null,
      this.createdAt = ''
      this.createdBy = ''
      this.$validator.reset()
      this.$store.dispatch('setOrgTitleExists', false)
      this.$store.dispatch('setOrgSubdomainExists', false)
      this.$store.dispatch('setNewOrganisationDialog', true)
    },
    toggleHowTo() {
      const val = this.$store.state.howTo
      if (val == false) {
        this.$store.dispatch('setHowTo', true)
        this.$store.dispatch('setPreview', false)
      } else {
        this.$store.dispatch('setHowTo', false)
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1216px;
}
</style>

<template>
  <div class="px-4">
    <v-alert
      :value="!targetCodes.length > 0"
      color="primary"
      border="left"
      text
      class="mx-3 incentable-alert"
    >
      <v-icon color="primary" class="mx-2" size="26"
        >fa-solid fa-bullseye</v-icon
      >
      At least one Category Key is required. Add Category Keys under the Rows
      area below, then import targets for each category via the Targets tab.
    </v-alert>

    <v-row no-gutters class="mb-5 mr-3">
      <v-col class="incentable-form-heading mt-3 mr-3" sm="auto">
        Preview
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="selectedAccountKey"
          hide-details
          :placeholder="selectPlaceholder"
          no-data-text="No data has been uploaded"
          :items="participants"
          item-text="label"
          item-value="value"
          solo-inverted
          dense
          flat
          class="incentable-n1 pt-1"
          :prepend-inner-icon="getIcon"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-card class="mb-4" outlined>
      <v-row
        no-gutters
        align="center"
        style="min-height: 64px"
        justify="center"
      >
        <v-col sm="2">
          <v-row no-gutters align="center">
            <v-col md="3">
              <v-icon class="rotate mx-2" color="grey">reorder</v-icon>
            </v-col>

            <v-col md="7">
              <div class="incentable-form-heading">Columns</div>
              <div class="incentable-databucket-table-subtitle mt-n1">
                Data or Points
              </div>
            </v-col>

            <v-col md="2">
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="grey lighten-4 ml-n4 mr-3"
                    icon
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!availableColumns.length"
                  >
                    <v-icon color="primary">add</v-icon>
                  </v-btn>
                </template>
                <v-list v-if="availableColumns.length">
                  <v-list-item
                    v-for="(item, index) in availableColumns"
                    :key="index"
                    @click="toggleColumn(item)"
                    :disabled="demo"
                  >
                    <v-btn
                      icon
                      color="primary"
                      class="grey lighten-4 ml-n1 mr-3"
                    >
                      <v-icon color="primary">add</v-icon>
                    </v-btn>
                    <v-list-item-icon>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="grey" v-bind="attrs" v-on="on">{{
                            getColumnMeta(item.label).icon
                          }}</v-icon>
                        </template>
                        <span>{{ getColumnMeta(item.label).tooltip }}</span>
                      </v-tooltip>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col>
          <v-col sm="auto" class="mb-0 ml-2">
            <v-chip
              class="mr-1 my-1"
              v-for="(item, index) in selectedColumns"
              :key="index"
              @click:close="toggleColumn(item)"
              :close="!demo"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    size="18"
                    left
                    color="grey"
                    v-bind="attrs"
                    v-on="on"
                    >{{ getColumnMeta(item.label).icon }}</v-icon
                  >
                </template>
                <span>{{ getColumnMeta(item.label).tooltip }}</span>
              </v-tooltip>
              {{ item.label }}
            </v-chip>
          </v-col>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mb-4" outlined>
      <v-row no-gutters align="center" style="min-height: 64px">
        <v-col sm="2">
          <v-row no-gutters align="center">
            <v-col md="3">
              <v-icon color="grey" class="mx-2">reorder</v-icon>
            </v-col>

            <v-col md="7">
              <div class="incentable-form-heading">Rows</div>
              <div class="incentable-databucket-table-subtitle mt-n1">
                Category Key(s)
              </div>
            </v-col>

            <v-col md="2">
              <v-menu v-model="addcat" offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="grey lighten-4 ml-n4 mr-3"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-if="!addcat" color="primary">add</v-icon>
                    <v-icon v-if="addcat" color="grey">close</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-text-field
                      class="mt-2"
                      name="categoryCode"
                      label="Add Category Key"
                      clearable
                      v-model="targetCode"
                      :error-messages="targetCodeError"
                    ></v-text-field>
                    <v-btn
                      icon
                      class="ml-2 mr-n1"
                      @click="handleAddTargetCode()"
                      :disabled="demo"
                    >
                      <v-icon color="green">check</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col>
          <v-col sm="10" class="mb-0 ml-2">
            <v-chip
              class="mr-1 my-1"
              v-for="targetCode in targetCodes"
              :key="targetCode"
              :close="targetHasData(targetCode) && !demo"
              @click:close="handleDeleteTargetCode(targetCode)"
            >
              <v-icon size="20" left color="grey">vpn_key</v-icon>
              {{ targetCode }}
              <v-icon
                v-if="!targetHasData(targetCode)"
                right
                size="15"
                color="grey lighten-1"
                class="chip-icon-adjust"
              >
                lock
              </v-icon>
            </v-chip>
          </v-col>
        </v-col>
      </v-row>
    </v-card>

    <v-row no-gutters class="mt-6 mb-6">
      <table>
        <thead>
          <tr>
            <th class="table-title-row">
              <v-icon color="grey" size="20" class="mr-1">vpn_key</v-icon>
              Category Key
            </th>
            <th
              v-for="mapping in visibleColumns"
              :key="mapping.column"
              class="table-title-row"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    size="20"
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                    >{{ getColumnMeta(mapping.label).icon }}</v-icon
                  >
                </template>
                <span>{{ getColumnMeta(mapping.label).tooltip }}</span>
              </v-tooltip>
              {{ mapping.label }}
            </th>
          </tr>
        </thead>
        <draggable v-model="targetCodes" tag="tbody" :disabled="demo">
          <tr v-for="targetCode in targetCodes" :key="targetCode">
            <td class="table-cell movable">
              <v-icon class="ml-n3 mr-3" color="grey">drag_indicator</v-icon>
              {{ targetCode }}
            </td>
            <td
              v-for="mapping in visibleColumns"
              :key="mapping.column"
              align="center"
              class="table-cell movable"
            >
              {{
                formatIt(
                  selectedColumnData[`${targetCode}-${mapping.column}`],
                  mapping
                )
              }}
            </td>
          </tr>
        </draggable>
        <tfoot>
          <tr>
            <td class="table-total-row">Totals</td>
            <td
              v-for="mapping in visibleColumns"
              :key="mapping.column"
              align="center"
              class="table-total-row"
            >
              {{ formatIt(selectedTotalData[mapping.column], mapping) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </v-row>
  </div>
</template>

<script>
// Script has been refactored to only contain Builder code
import numeral from "numeral";

export default {
  created() {},
  data: function () {
    const participants = this.$store.getters.databucketParticipants;

    return {
      selectedAccountKey: participants.length ? participants[0].value : "",
      targetCode: "",
      targetCodeError: "",
      addcat: false,
      condIdx: null,
      isProcessing: false,

      columnMeta: [
        {
          label: "Category Key",
          icon: "fa-solid fa-bullseye",
          tooltip: "Key link to uploaded Data",
          style: "padding-top: 5px",
        },
        {
          label: "Target",
          icon: "fa-solid fa-bullseye",
          tooltip: "Admin Uploaded Target Data",
          style: "padding-top: 5px",
        },
        {
          label: "Actual",
          icon: "fa-solid fa-database",
          tooltip: "Admin Uploaded Actual Data",
          style: "padding-top: 5px",
        },
        {
          label: "Difference",
          icon: "functions",
          tooltip: "[Actual] - [Target]",
          style: "padding-top: 5px",
        },
        {
          label: "Percentage",
          icon: "functions",
          tooltip: "[Actual] / [Target] %",
          style: "padding-top: 5px",
        },
        {
          label: "Target Points",
          icon: "stars",
          tooltip: "Calculated by Points Formulas",
          style:
            "border-top: 5px solid" + this.$vuetify.theme.themes.light.primary,
        },
        {
          label: "Claim Points",
          icon: "stars",
          tooltip: "Calculated by Claims Manager",
          style:
            "border-top: 5px solid" + this.$vuetify.theme.themes.light.primary,
        },
        {
          label: "Quiz Points",
          icon: "stars",
          tooltip: "Calculated by Quiz feature",
          style:
            "border-top: 5px solid" + this.$vuetify.theme.themes.light.primary,
        },
        {
          label: "Total Points",
          icon: "stars",
          tooltip: "Auto Sum of all Points Columns",
          style:
            "border-top: 5px solid" + this.$vuetify.theme.themes.light.primary,
        },
      ],
    };
  },

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    databucket() {
      return this.$store.getters.databucket;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },

    selectPlaceholder() {
      if (this.databucket.participantType === "member") {
        return "Select Member To Preview";
      } else if (this.databucket.participantType === "company") {
        return "Select Company To Preview";
      } else {
        return "Select Record To Preview";
      }
    },
    previewColumns() {
      return this.$store.getters.databucketPreviewColumns;
    },
    visibleColumns() {
      return this.previewColumns.filter((item) => item.show);
    },
    selectedColumns() {
      return this.previewColumns
        .filter((item) => item.show && item.calculated)
        .map((item) => ({ ...item }));
    },
    calculatedColumns() {
      return this.previewColumns
        .filter((item) => item.calculated)
        .map((item) => ({ ...item }));
    },
    availableColumns() {
      return this.previewColumns
        .filter((item) => item.calculated && !item.show)
        .map((item) => ({ ...item }));
    },
    targetCodes: {
      get() {
        return this.databucket.targetCodes;
      },
      set(values) {
        this.$store.dispatch("reorderTargetOrders", values);
      },
    },
    allConsumedTargetCodes() {
      return this.$store.getters.allConsumedTargetCodes;
    },
    participants() {
      return this.$store.getters.databucketParticipants;
    },
    columnData() {
      return this.$store.getters.databucketColumnData;
    },
    totalData() {
      return this.$store.getters.databucketTotalData;
    },
    selectedColumnData() {
      const participant = this.columnData.find(
        (item) => item.accountKey === this.selectedAccountKey
      );
      return participant ? participant.data : {};
    },
    selectedTotalData() {
      const participant = this.totalData.find(
        (item) => item.accountKey === this.selectedAccountKey
      );
      return participant ? participant.data : {};
    },
    getIcon() {
      if (this.databucket.participantType === "member") {
        return "account_circle";
      } else {
        return "business";
      }
    },
  },

  methods: {
    getColumnMeta(val) {
      var arr = this.columnMeta;
      var found = arr.find((item) => item.label === val);
      if (found) {
        return found;
      } else {
        return "";
      }
    },
    formatIt(val, mapping) {
      if (
        mapping.column === "target" ||
        mapping.column === "actual" ||
        mapping.column === "diff"
      ) {
        return numeral(val).format("0,0.00");
      } else if (mapping.column === "percent") {
        if (numeral(val)._value === Infinity) {
          return "0.00 %";
        } else {
          return `${numeral(val).format("0,0.00")} %`;
        }
      } else if (
        mapping.column === "points" ||
        mapping.column === "offerPoints" ||
        mapping.column === "quizPoints" ||
        mapping.column === "totalPoints"
      ) {
        return numeral(val).format("0,0");
      }
      return val;
    },
    handleAddTargetCode() {
      if (!this.targetCode) {
        this.targetCodeError = "Category cannot be blank.";
        return;
      }

      const arr = this.targetCodes;
      if (arr.find((item) => item === this.targetCode)) {
        this.targetCodeError = "Category already exists.";
      } else {
        this.$store.dispatch("createTargetCode", this.targetCode).then(() => {
          this.targetCode = "";
          this.targetCodeError = "";
        });
      }
    },
    handleDeleteTargetCode(targetCode) {
      this.$store
        .dispatch("deleteTargetCode", targetCode)
        .then(() => {})
        .catch(() => {});
    },
    toggleColumn(changed) {
      const newPreviewColumns = this.previewColumns.map((item) => {
        if (item.column === changed.column) {
          if (changed.show === true) {
            changed.show = false;
          } else if (changed.show === false) {
            changed.show = true;
          }
          return { ...changed };
        } else {
          return item;
        }
      });

      this.$store.dispatch("patchDatabucket", {
        previewColumns: newPreviewColumns,
      });
    },
    targetHasData(targetCode) {
      const result = this.allConsumedTargetCodes.find(
        (item) => item === targetCode
      );
      if (result) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {},
};
</script>

<style>
.handle {
  cursor: pointer;
}
.ghost {
  border: 2px dotted rgb(134, 134, 134);
}
.points-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 2px !important;
  font-size: 14px !important;
}
</style>

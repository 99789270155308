<template>
  <div>
    <v-col cols="4">
      <v-select
        :items="memberTags"
        v-model="selectedMemberTags"
        item-text="tag"
        item-value="id"
        label="Member Tags"
        chips
        multiple
        deletable-chips
      />
      <v-select
        :items="companyTags"
        v-model="selectedCompanyTags"
        item-text="tag"
        item-value="id"
        label="Company Tags"
        chips
        multiple
        deletable-chips
      />
    </v-col>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
    };
  },
  computed: {
    companyTags () {
      return this.$store.state.companytag.companyTags;
    },
    memberTags () {
      return this.$store.state.membertag.memberTags;
    },
    currentTable() {
      return this.$store.state.table.currentTable;
    },
    selectedMemberTags: {
      get() {
        return this.currentTable.memberTags;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { memberTags: value });
      }
    },
    selectedCompanyTags: {
      get() {
        return this.currentTable.companyTags;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { companyTags: value });
      }
    },
  },
  methods: {
  },
}
</script>

<style scoped>

</style>

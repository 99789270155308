<template>
  <v-container class="page" fluid>
    <v-tabs>
      <v-tab class="tab"> Awards </v-tab>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <AwardsList />
      </v-tab-item>
    </v-tabs>
    <app-snackbar></app-snackbar>
  </v-container>
</template>

<script>
import AwardsList from "./AwardsList.vue";

export default {
  components: { AwardsList },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadDatabuckets");
    this.$store.dispatch("loadAwards");
    this.$store.dispatch("loadAllAwardData");
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("setNavMenu", true);
  },
  data() {
    return {
      search: "",
      searchActive: false,
    };
  },
};
</script>

<style scoped></style>

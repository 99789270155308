import { render, staticRenderFns } from "./ShoppingCart.vue?vue&type=template&id=1afbd790&scoped=true"
import script from "./ShoppingCart.vue?vue&type=script&lang=js"
export * from "./ShoppingCart.vue?vue&type=script&lang=js"
import style0 from "./ShoppingCart.vue?vue&type=style&index=0&id=1afbd790&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1afbd790",
  null
  
)

export default component.exports
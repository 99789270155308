<template>
  <div class="px-4 pt-4">
    <v-row no-gutters align="center">
      <v-col lg="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field>
      </v-col>

      <v-col lg="3" v-if="options.filter">
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter By Status"
          prepend-inner-icon="filter_list"
          class="mr-4"
          :items="statusList"
          v-model="filteredStatus"
          multiple
          clearable
          @click:clear="resetStatusFilter()"
        ></v-select>
      </v-col>

      <v-col lg="3" v-if="options.filter">
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter By Supplier"
          prepend-inner-icon="filter_list"
          class="mr-4"
          multiple
          :items="rewardSuppliers"
          v-model="filteredSupplier"
          clearable
          @click:clear="resetSupplierFilter()"
          item-value="id"
          item-text="title"
        ></v-select>
      </v-col>

      <v-col>
        <v-btn
          v-if="options.upload"
          class="primary--text"
          text
          :disabled="demo"
          @click="handleOpenRewardsUploadDialog()"
          >Upload</v-btn
        >
        <v-btn
          v-if="options.download"
          class="primary--text"
          text
          :disabled="demo"
          :href="rewardsCsv"
          :download="getFilename()"
          >Download</v-btn
        >
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
      </v-col>

      <v-col sm="auto">
        <v-btn @click="handleNew()" color="primary" elevation="0" class="mt-1">
          add new reward
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="filteredRewards"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link>
              <v-list-item-title @click="handleEdit(item)">
                Edit
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="handleDeleteConfirmation(item)">
                Delete
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status" class="pr-3"></status-icon>
        {{ item.status }}
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <div
          @click="handleEdit(item)"
          class="primary--text"
          style="cursor: pointer"
        >
          {{ item.title }}
        </div>
      </template>

      <template v-slot:[`item.rewardCategory`]="{ item }">
        <v-chip-group column>
          <v-chip
            small
            v-for="id in item.rewardCategories"
            :key="id.id"
            class="ma-1"
          >
            {{ getRewardCategoryName(id) }}
          </v-chip>
        </v-chip-group>
      </template>

      <template v-slot:[`item.points`]="{ item }">
        {{ formatPoints(item.points) }}
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ formatPrice(item.price) }}
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        {{ item.updated | date }}
      </template>

      <template v-slot:[`item.updatedBy`]="{ item }">
        {{ getRewardAuthorName(item.updatedBy) }}
      </template>

      <template v-slot:[`item.rewardCatalogue`]="{ item }">
        {{ getRewardCatalogueName(item.rewardCatalogue) }}
      </template>

      <template v-slot:[`item.rewardSupplier`]="{ item }">
        {{ getRewardSupplierName(item.rewardSupplier) }}
      </template>

      <template v-slot:[`item.image`]="{ item }">
        <v-avatar size="38">
          <v-img
            :src="
              item.externalImageUrl
                ? item.externalImageUrl
                : item.image.smallUrl
            "
            :lazy-src="
              item.externalImageUrl
                ? item.externalImageUrl
                : item.image.smallUrl
            "
          >
          </v-img>
        </v-avatar>
      </template>
    </v-data-table>

    <rewards-new-or-edit
      :rewardId="selectedRewardId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <RewardsImport
      :open="openRewardsUploadDialog"
      @onClose="handleCloseRewardsUploadDialog"
    />

    <v-dialog v-model="dialogDeleteOpen" max-width="400px" persistent>
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay absolute color="red" :opacity="systemTheme.cardOpacity">
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading"> Are You Sure? </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          <v-row dense>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ title }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleCloseDeleteDialog()">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import numeral from "numeral";
import { REWARD_STATUS } from "@/constants/reward";
import RewardsImport from "./RewardsImport.vue";

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    RewardsImport,
  },
  data: () => ({
    // Component settings
    tableTitle: "Rewards",
    tableSubtitle: "Add rewards",
    tableIcon: "storefront",
    search: "",
    title: "",
    sku: "",
    selectedRewardId: "",
    statusList: REWARD_STATUS,
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,
    filteredStatus: [],
    filteredSupplier: [],
    openRewardsUploadDialog: false,

    // Table settings
    options: {
      sortBy: ["title"],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: true,
      download: true,
      more: false,
    },

    // Table columns
    headers: [
      { text: "Status", value: "status", align: "left" },
      { text: "Image", value: "image", align: "center" },
      { text: "Title", value: "title", align: "left" },
      { text: "SKU", value: "sku", align: "right" },
      { text: "Points", value: "points", align: "right", sortable: false },
      { text: "Price", value: "price", align: "right", sortable: false },
      {
        text: "Category",
        value: "rewardCategory",
        align: "left",
        sortable: false,
      },
      { text: "Catalogue", value: "rewardCatalogue", align: "left" },
      { text: "Supplier", value: "rewardSupplier", align: "left" },
      { text: "Last Updated", value: "updated", align: "right" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
  }),

  created() {},

  computed: {
    // Functions specific to this table //

    rewardCatalogues() {
      return this.$store.state.rewardcatalogue.rewardCatalogues;
    },
    rewards() {
      return this.$store.state.reward.rewards;
    },
    rewardSuppliers() {
      return this.$store.state.rewardsupplier.rewardSuppliers;
    },
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    filteredRewards() {
      const filtered =
        this.search === ""
          ? this.rewards
          : this.rewards.filter((el) =>
              el.title.toLowerCase().includes(this.search.toLowerCase())
            );
      const filteredByStatus = this.filteredStatus.length
        ? filtered.filter((m) => this.filteredStatus.includes(m.status))
        : filtered;
      const result = this.filteredSupplier.length
        ? filteredByStatus.filter((m) =>
            this.filteredSupplier.includes(m.rewardSupplier)
          )
        : filteredByStatus;
      return result;
    },

    rewardSupplierNameMap() {
      return this.rewardSuppliers.reduce((result, item) => {
        return {
          ...result,
          [item.id]: item.title,
        };
      }, {});
    },

    rewardCatalogueNameMap() {
      return this.rewardCatalogues.reduce((result, item) => {
        return {
          ...result,
          [item.id]: item.title,
        };
      }, {});
    },

    rewardAuthorMap() {
      return this.$store.getters.admins.reduce((result, item) => {
        return {
          ...result,
          [item.userId]: item.name,
        };
      }, {});
    },

    rewardCategoryNameMap() {
      return this.$store.state.rewardcategory.rewardCategories.reduce(
        (result, item) => {
          return {
            ...result,
            [item.id]: item.title,
          };
        },
        {}
      );
    },

    categoriesMap() {
      return this.$store.getters.categoriesMap;
    },

    rewardsCsv() {
      const dataHeader = "data:text/csv;charset=utf-8,";
      const csvHeader =
        "Status,Product,Description,External Image URL,Created,Updated,Sku,Supplier,Points,Price,Categories";
      const csvBody = this.filteredRewards
        .map((reward) => {
          const {
            status,
            title,
            body,
            externalImageUrl,
            created,
            updated,
            sku,
            rewardSupplier,
            points,
            price,
            rewardCategories,
          } = reward;
          var createdFormatted = created;
          var updatedFormatted = updated;
          var bodyFormatted = '"' + body.replace(/(\r\n|\n|\r)/gm, "") + '"';
          var titleFormatted = '"' + title.replace(/(\r\n|\n|\r)/gm, "") + '"';
          var supplierName = this.getSupplier(rewardSupplier).title || "";
          var categoriesTxt =
            '"' +
            rewardCategories.map((id) => this.categoriesMap[id]).join(";") +
            '"';
          var formattedExternalImageUrl =
            '"' + encodeURIComponent(externalImageUrl) + '"';
          return `${status},${titleFormatted},${bodyFormatted},${formattedExternalImageUrl},${createdFormatted},${updatedFormatted},${sku},${supplierName},${points},${price},${categoriesTxt}`;
        })
        .join("\r\n");

      return `${dataHeader}${csvHeader}\r\n${csvBody}`;
    },

    // Functions common to all tables //

    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    snackbar() {
      return this.$store.getters.snackbar;
    },
  },
  methods: {
    handleOpenRewardsUploadDialog() {
      this.openRewardsUploadDialog = true;
    },
    handleCloseRewardsUploadDialog() {
      this.openRewardsUploadDialog = false;
    },
    getFilename() {
      const program = this.$store.state.program.currentProgram;
      const date = new Date();
      const formattedDate = date.toLocaleDateString(["en-US"], {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      return program.title + " Rewards " + formattedDate + ".csv";
    },
    getSupplier(id) {
      return this.rewardSuppliers.find((el) => el.id === id) || "";
    },
    resetStatusFilter() {
      this.filteredStatus = [];
    },
    resetSupplierFilter() {
      this.filteredSupplier = [];
    },

    formatPoints(val) {
      return numeral(val).format("0,0");
    },

    formatPrice(val) {
      return numeral(val).format("$0,0.00");
    },

    getRewardSupplierName(rewardSupplierId) {
      return this.rewardSupplierNameMap[rewardSupplierId];
    },

    getRewardCatalogueName(rewardCatalogueId) {
      return this.rewardCatalogueNameMap[rewardCatalogueId];
    },

    getRewardAuthorName(adminId) {
      return this.rewardAuthorMap[adminId];
    },

    getRewardCategoryName(categoryId) {
      return this.rewardCategoryNameMap[categoryId];
    },

    // Actions common to all tables //

    getStatusIcon(item) {
      if (item === "Active") {
        return "check_circle";
      } else if (item === "Inactive") {
        return "not_interested";
      }
    },

    handleNew() {
      // Open New form
      this.selectedRewardId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedRewardId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedRewardId = "";
      this.dialogNewOrEditOpen = false;
    },
    handleDeleteConfirmation(item) {
      // Delete record
      this.selectedRewardId = item.id;
      this.image = item.image;
      this.title = item.title;
      this.dialogDeleteOpen = true;
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedRewardId = "";
      this.dialogDeleteOpen = false;
    },
    async handleDelete() {
      // Delete Reward
      this.$store.dispatch("setLoading", true);
      var hasMedia = this.image.name !== "";
      if (hasMedia) {
        await this.$store
          .dispatch("deleteMediaFilesFromStorage", this.image)
          .then(
            this.$store
              .dispatch("deleteReward", this.selectedRewardId)
              .then(() => (this.dialogDeleteOpen = false))
              .catch(() => (this.dialogDeleteOpen = false))
          );
      } else {
        this.$store
          .dispatch("deleteReward", this.selectedRewardId)
          .then(() => (this.dialogDeleteOpen = false))
          .catch(() => (this.dialogDeleteOpen = false));
      }
    },
  },
};
</script>

<style></style>

<template>
<div class="mt-8 mr-8 mb-8">
  <v-card elevation="0">
    <v-row>
      <v-col sm="3">
        <v-autocomplete
          v-model="selectedCompanyId"
          :items="companies"
          item-text="title"
          item-value="id"
          label="Select Company"
        ></v-autocomplete>
      </v-col>
      <v-col sm="3">
        <v-select
          v-model="selectedTableId"
          :items="tables"
          item-text="title"
          item-value="id"
          label="Select Report"
        ></v-select>
      </v-col>
    </v-row>
    
      <v-row v-if="selectedCompanyId && selectedTableId" no-gutters>
        <v-row v-if="participantIdx > -1" no-gutters>
          <v-col v-for="(header, headerIdx) in currentTable.resultsHeader" :key="headerIdx">
            <div class="subheading font-weight-bold pb-2"> {{header}} </div>
            <div v-for="(row, rowIdx) in currentTable.tableData[participantIdx].rows" :key="rowIdx">
              {{row.row[headerIdx]}}
            </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            No Company Data
          </v-col>
        </v-row>
      </v-row>

    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    selectedTableId: '',
    selectedCompanyId: '',
  }),
  created () {
  },
  computed: {
    programId() {
      return this.$store.getters.programId
    },
    companies () {
      return this.$store.state.company.companies
    },
    tables() {
      return this.$store.getters.tables
    },
    currentTable() {
      if (!this.selectedTableId) {
        return null;
      }
      return this.tables.find(table => table.id === this.selectedTableId);
    },
    participantIdx() {
      if (!this.currentTable || !this.currentTable.tableData) {
        return -1;
      }
      return this.currentTable.tableData.findIndex(item => item.companyId === this.selectedCompanyId);
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>

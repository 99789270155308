<template>
  <div>
    <v-dialog v-model="dialogDeleteAnswer" persistent max-width="600px">
      <v-card>
        <v-progress-linear
          :indeterminate="true"
          v-if="processing"
        ></v-progress-linear>
        <v-card-title>
          <span class="page-heading">Delete Answer Confirmation</span>
        </v-card-title>
        <v-row class="mx-6 mb-3" no-gutters>
          Please confirm you wish to delete the answer...
          <span class="bold-question ml-2"> "{{ answerText }}" </span>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()">cancel</v-btn>
          <v-btn
            color="primary"
            @click="confirmDelete()"
            elevation="0"
            :loading="processing"
            >yes, please delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogDeleteAnswer: {
      type: Boolean,
    },
    answerText: {
      type: String,
    },
  },
  created() {},
  data: () => {
    return {
      processing: false,
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.reset();
      this.$emit("onCancelDeleteAnswer");
    },
    async confirmDelete() {
      this.reset();
      this.$emit("onConfirmDeleteAnswer");
    },
    reset() {
      this.processing = false;
    },
  },
};
</script>
<style scoped>
.bold-question {
  font-weight: 700 !important;
}
</style>

<template>
  <div>
    <v-system-bar
      v-if="isIncentableAdmin && availableSites < 2"
      height="30"
      color="primary"
      class="white--text"
    >
    <v-row justify="center">
      ALERT: {{ availableSites }} temp domains available. Add more now.
    </v-row>
  </v-system-bar>
    <v-toolbar
      dense
      color="grey lighten-4"
      elevation="0"
    >
      <v-row no-gutters justify="center">
        <v-col md="12" lg="8">
          <v-row>
            <v-col>
              <v-tabs
                v-model="tab"
                class="mx-0"
                background-color="grey lighten-4"
              >
                <v-tab class="tab" @click="clearSearch()">Organisations</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                class="mt-1 caption"
                v-model="search"
                solo-inverted
                dense
                flat
                hide-details
                prepend-inner-icon="search"
                clearable
                @click:clear="clearSearch()"
              ></v-text-field> 
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row no-gutters justify="center">
      <v-col md="12" lg="8">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Organisations :search="search" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Organisations from './OrganisationsList.vue'
export default {
  components: { Organisations },
  data: () => ({
    tab: null,
    demoBooking: true,
    search: '',
    trial: true,
  }),
  created () {
    this.$store.dispatch('clearCurrentProgram')
    this.$store.dispatch('initialize')
    this.$store.commit('setNavMenu', false)
    this.$store.dispatch('setToolbar', true)
    this.$store.dispatch('setFooter', true)
    this.$store.dispatch('loadPrograms')
    this.$store.dispatch('loadSites')
    this.$store.dispatch('loadSubscriptions')
    this.$store.dispatch('loadProgramThemes')
  },
  computed: {
    programs () {
      return this.$store.state.program.programs
    },
    programThemes () {
      return this.$store.state.program.programThemes
    },
    isIncentableAdmin () {
      return this.$store.getters.isIncentableAdmin
    },
    sites () {
      return this.$store.state.site.sites
    },
    availableSites() {
      return this.sites.filter(el => el.status === 'Available').length
    }
  },
  methods: {
    clearSearch() {
      this.search = ''
    }
  },
}
</script>

<style scoped>

</style>

/* eslint-disable no-console */
<template>
  <div>
    <v-card elevation="0">
        <v-row
          no-gutters
          align="center"
          >
          <v-col sm="3">
            <v-text-field
              v-model="search"
              solo-inverted
              dense
              flat
              hide-details
              label="Search"
              prepend-inner-icon="search"
              class="mr-4"
            ></v-text-field> 
          </v-col>
          
          <v-col
            sm="3"
            v-if="options.filter"
            >
            <v-select
              solo-inverted
              dense
              flat
              hide-details
              label="Filter By Status"
              prepend-inner-icon="filter_list"
              class="mr-4"
              multiple
              :items="statusList"
              v-model="filteredStatus"
            ></v-select> 
          </v-col>
          
          <v-col>
            <v-btn
              @click="handleOpenImportDialog()"
              v-if="options.upload"
              class="primary--text"
              text
              >Upload</v-btn>
            <v-btn 
              v-if="options.download"
              class="primary--text"
              text
              :href="adminsCsv"
              :download="getFilename()"
              >Download</v-btn> 
            <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
          </v-col>
          
          <v-col sm="auto">
            
          </v-col>

        </v-row>

      <!-- Data table -->
  
      <v-data-table
        :headers="headers"
        :items="systemAdmins"
        :search="search"
        :options.sync="options"
        :loading="loadingTable"
        no-data-text="No Records..."
        class="px-0 pt-6"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
            close-on-content-click
          >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>

                <v-list-item
                  link
                  :disabled="!isRoleSatisfied('Editor')"
                  >
                  <v-icon class="mr-2">edit</v-icon>
                  <v-list-item-title
                    @click="handleEdit(item)"
                    >Edit
                  </v-list-item-title>
                </v-list-item>
                
                <v-list-item
                  link
                  :disabled="!isRoleSatisfied('Editor')"
                  >
                  <v-icon class="mr-2">delete</v-icon>
                  <v-list-item-title 
                    @click="handleDeleteConfirmation(item)"
                    >Delete
                  </v-list-item-title>
                </v-list-item>

              </v-list>
          </v-menu>
        </template>
        
        <template v-slot:[`item.status`]="{ item }">
          <status-icon :status="item.status" class="pr-3"></status-icon>
          {{ item.status }}
        </template>
        
        <template v-slot:[`item.programs`]="{ item }">
          <ul>
            <li 
              v-for="(name,i) in getProgramNames(item.programs)"
              :key="i"
            >
              {{ name }}
            </li>
          </ul>
        </template>
      
      </v-data-table>
    </v-card>

    <system-admin-add-or-edit
      :adminId="selectedAdminId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />
    
    <!-- Delete dialog -->

    <v-dialog 
      v-model="dialogDeleteOpen"
      max-width="400px"
      persistent
      >
      <v-card>
        
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row dense>
            <v-col align="center">
              You are about to delete <span class="incentable-form-bold">{{ name }}</span>
            </v-col>
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleCloseDeleteDialog()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>

</template>

<script>
  import { ADMIN_STATUS } from '@/constants/admin';
  export default {
    data: () => ({
      // Component settings unique to this component
      tableTitle: 'Admins',
      tableSubtitle: 'Create and manage your program admins',
      tableIcon: 'account_circle',
      dialogImportOpen: false,
      search: '',
      name: '',
      terms: '',
      userId: '',
      selectedAdminId: '',
      statusList: ADMIN_STATUS,
      filteredStatus: [],
      statusChangingAdmin: null,
      
      // Table columns
      headers: [
        { text: 'Name', value: 'name', align: 'left'},
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Programs', value: 'programs', align: 'left' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],

      // Component settings common to all components
      dialogNewOrEditOpen: false,
      dialogDeleteOpen: false,

      // Table settings
      options: {
        sortBy: ['title'],
        sortDesc: [false],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: true,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
      
    }),
    computed: {
      // Functions specific to this component
      systemAdmins () {
        return this.$store.state.systemadmin.systemAdmins.filter(el => el.email !== null)
      },
      filteredAdmins() {
        return this.filteredStatus.length
          ? this.admins.filter(m => this.filteredStatus.includes(m.status))
          : this.admins;
      },

      // Status counts
      pendingAdmins() {
        return this.admins.filter(m => m.status === 'Pending');
      },

      activeAdmins() {
        return this.admins.filter(m => m.status === 'Active');
      },

      invitedAdmins() {
        return this.admins.filter(m => m.status === 'Invited');
      },

      awaitingAdmins() {
        return this.admins.filter(m => m.status === 'Awaiting Approval')
      },

      currentUserRole() {
        return this.$store.getters.currentUserRole;
      },

      // For CSV download file
      adminTagsMap() {
        return this.$store.getters.adminTagsMap;
      },
      programsMap() {
        return this.$store.getters.programsMap;
      },
      //adminsCsv() {
      //  const dataHeader = 'data:text/csv;charset=utf-8,'
      //  const csvHeader = 'Name,Email,Programs'
      //  const csvBody = this.systemAdmins.map(admin => {
      //    const {
      //      name,email,programs
      //    } = admin;
      //    const programsTxt = programs.map(id => this.programsMap[id]).join(';');
      //    return `${name},${email},${programsTxt}`
      //  }).join('\r\n');
//
      //  return `${dataHeader}${csvHeader}\r\n${csvBody}`;
      //},

      // Functions common to all components
      loadingTable () {
      return this.$store.getters.loadingTable
      },
      loading () {
      return this.$store.getters.loading
      },
      orgTheme () {
        return this.$store.getters.orgTheme
      },
      systemTheme () {
        return this.$store.getters.systemTheme
      },
      programs () {
        return this.$store.state.program.programs
    },
    },

    created () {
    },

    methods: {
      handleNew() {
        // Open New form
        this.selectedAdminId = '';
        this.dialogNewOrEditOpen = true;
      },
      handleEdit(item) {
        // Open Edit form
        this.selectedAdminId = item.userId;
        this.dialogNewOrEditOpen = true;
      },
      handleCloseNewOrEditDialog() {
        // Close New Or Edit form
        this.selectedAdminId = '';
        this.dialogNewOrEditOpen = false;
      },
      handleOpenImportDialog() {
        // Open Import Dialog
        this.dialogImportOpen = true;
      },
      handleDeleteConfirmation (item) {
        // Delete record
        this.selectedAdminId = item.userId;
        this.name = item.name;
        this.dialogDeleteOpen = true;
      },
      handleDelete() {
        // Delete record
        this.$store.dispatch('setLoading', true)
        this.$store.dispatch('deleteAdmin', this.selectedAdminId)
          .then(() => this.dialogDeleteOpen = false)
          .catch(() => this.dialogDeleteOpen = false)
          this.$store.dispatch('setLoading', false)
      },
      handleCloseDeleteDialog() {
        // Close delete form
        this.selectedAdminId = '';
        this.dialogDeleteOpen = false;
      },
      getNextStatus(currentStatus) {
        if (currentStatus === 'Pending') {
          return [
            { label: 'Invite', value: 'Invited' },
          ];
        } else if (currentStatus === 'Awaiting Approval') {
          return [
            { label: 'Approve', value: 'Active' },
          ];
        } else if (currentStatus === 'Invited') {
          return [
            { label: 'Re-invite', value: 'Invited' },
          ];
        }
        return [];
      },
      getFilename() {
        const program = this.$store.state.program.currentProgram;
        const date = new Date()
        const formattedDate = date.toLocaleDateString(['en-US'], {month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})
        return program.title + ' Admin List ' + formattedDate + '.csv'
      },
      handleConfirmNextStatus(admin, nextStatus) {
        this.statusChangingAdmin = admin;
        this.selectedAdminId = admin.id;
        this.nextStatus = nextStatus;
        this.fullname = admin.fullname;
      },
      handleCloseStatusDialog() {
        this.statusChangingAdmin = null;
        this.selectedAdminId = '';
        this.nextStatus = null;
        this.fullname = '';
      },
      handleNextStatus() {
        if (!this.statusChangingAdmin || !this.nextStatus) {
          return;
        }

        this.$store.dispatch('setLoading', true)

        let action = '';
        if (this.nextStatus === 'Invited') {
          action = 'inviteAdmin';
        } else if (this.nextStatus === 'Active') {
          action = 'approveAdmin';
        }
        this.$store.dispatch(action, this.selectedAdminId)
          .then(() => {
            this.$store.dispatch('setLoading', false)
            this.handleCloseStatusDialog();
          })
          .catch(() => {
            this.$store.dispatch('setLoading', false)
            this.handleCloseStatusDialog();
          })
      },
      handleInvite(status) {
        this.$store.dispatch('setLoading', true)
        this.$store.dispatch('inviteByStatus', status)
          .then(() => this.$store.dispatch('setLoading', false))
      },
      getProgramNames(ids) {
        let arr = []
        ids.forEach(item => {
          const program = this.programs.find(el => el.id === item)
          arr.push(program ? program.title : 'Program ID not found: ' + item)
        })
        return arr
      }
    }
  }
</script>

<style>
</style>

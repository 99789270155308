export const TEXT_ONE_COL_DEFAULTS = {
  settings: {
    heightContext: 'px',
    mode: 'color',
    cols: 12,
    color: { 
      alpha: 0, 
      hex: '#000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
  },
  titleColOne: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'titleColOne',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 22,
    style: 'normal',
    value: 'Column Heading',
    weight: 'bold',
    marginLeft: 0,
    marginRight: 0,
  },
  textColOne: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'textColOne',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 12,
    style: 'normal',
    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a odio consequat, volutpat massa quis, blandit est. Fusce porta ex at magna faucibus eleifend. Ut vel justo in diam porttitor cursus sed non arcu. Nulla ut mauris ornare, commodo elit quis, porta nisl. Curabitur eleifend porttitor ipsum, a hendrerit purus. Sed posuere, est nec posuere mollis, massa diam maximus est, nec lobortis enim felis in nunc. Nunc suscipit, velit eget volutpat aliquam, sem libero hendrerit elit, id consectetur lorem nunc id lorem. Sed rhoncus erat vel turpis dictum dignissim. In egestas congue ornare. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin at nibh semper, malesuada odio quis, sollicitudin libero. Ut ullamcorper, elit non posuere volutpat, quam justo volutpat justo, id fermentum sapien ex eu dolor. In ornare consequat tristique. Fusce neque dui, pellentesque ac sollicitudin et, porta ac leo. Nam et mauris cursus, finibus turpis id, volutpat lacus.',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  screen: {
    height: 85,
    type: 'screen',
    direction: 'to top',
    color: { 
      alpha: 0, 
      hex: '#00000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
    fromColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
    toColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
  },
  heightValue: {
    px: 450,
    vh: 30,
  },
  overlap: {
    px: 0,
    vh: 0,
  },
  block: {
    cols: 3,
    align: 'start',
    justify: 'center',
    padding: {
      bottom: 50,
      left: 8,
      right: 8,
      top: 50,
    }
  },
  logoColOne: {
    align: 'center',
    placement: 'top',
    justify: 'center',
    height: 50,
    boxHeight: 10,
    image: '',
    previewImage: ''
  },
  background: {
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_2560x2560.png?alt=media&token=62cd0b3e-a963-42ef-a633-cf9a063f99f1',
    previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_600x600.png?alt=media&token=3a2aa0cd-b5fe-45a5-9466-ec39af90630b'
  }
};
export const TEXT_TWO_COL_DEFAULTS = {
  settings: {
    heightContext: 'px',
    mode: 'color',
    cols: 12,
    color: { 
      alpha: 0, 
      hex: '#000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
  },
  titleColOne: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'titleColOne',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 22,
    style: 'normal',
    value: 'Column Heading',
    weight: 'bold',
    marginLeft: 0,
    marginRight: 0,
  },
  textColOne: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'textColOne',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 12,
    style: 'normal',
    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a odio consequat, volutpat massa quis, blandit est. Fusce porta ex at magna faucibus eleifend. Ut vel justo in diam porttitor cursus sed non arcu. Nulla ut mauris ornare, commodo elit quis, porta nisl. Curabitur eleifend porttitor ipsum, a hendrerit purus. Sed posuere, est nec posuere mollis, massa diam maximus est, nec lobortis enim felis in nunc. Nunc suscipit, velit eget volutpat aliquam, sem libero hendrerit elit, id consectetur lorem nunc id lorem. Sed rhoncus erat vel turpis dictum dignissim. In egestas congue ornare. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin at nibh semper, malesuada odio quis, sollicitudin libero. Ut ullamcorper, elit non posuere volutpat, quam justo volutpat justo, id fermentum sapien ex eu dolor. In ornare consequat tristique. Fusce neque dui, pellentesque ac sollicitudin et, porta ac leo. Nam et mauris cursus, finibus turpis id, volutpat lacus.',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  titleColTwo: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'titleColTwo',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 22,
    style: 'normal',
    value: 'Column Heading',
    weight: 'bold',
    marginLeft: 0,
    marginRight: 0,
  },
  textColTwo: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'textColTwo',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 12,
    style: 'normal',
    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a odio consequat, volutpat massa quis, blandit est. Fusce porta ex at magna faucibus eleifend. Ut vel justo in diam porttitor cursus sed non arcu. Nulla ut mauris ornare, commodo elit quis, porta nisl. Curabitur eleifend porttitor ipsum, a hendrerit purus. Sed posuere, est nec posuere mollis, massa diam maximus est, nec lobortis enim felis in nunc. Nunc suscipit, velit eget volutpat aliquam, sem libero hendrerit elit, id consectetur lorem nunc id lorem. Sed rhoncus erat vel turpis dictum dignissim. In egestas congue ornare. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin at nibh semper, malesuada odio quis, sollicitudin libero. Ut ullamcorper, elit non posuere volutpat, quam justo volutpat justo, id fermentum sapien ex eu dolor. In ornare consequat tristique. Fusce neque dui, pellentesque ac sollicitudin et, porta ac leo. Nam et mauris cursus, finibus turpis id, volutpat lacus.',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  screen: {
    height: 85,
    type: 'screen',
    direction: 'to top',
    color: { 
      alpha: 0, 
      hex: '#00000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
    fromColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
    toColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
  },
  heightValue: {
    px: 450,
    vh: 30,
  },
  overlap: {
    px: 0,
    vh: 0,
  },
  block: {
    cols: 6,
    align: 'start',
    justify: 'center',
    padding: {
      bottom: 50,
      left: 8,
      right: 8,
      top: 50,
    }
  },
  logoColOne: {
    align: 'center',
    placement: 'top',
    justify: 'center',
    height: 50,
    boxHeight: 10,
    image: '',
    previewImage: ''
  },
  logoColTwo: {
    align: 'center',
    placement: 'top',
    justify: 'center',
    height: 50,
    boxHeight: 10,
    image: '',
    previewImage: ''
  },
  background: {
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_2000x2000.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7',
    previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_300x300.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7'
  }
};
export const TEXT_THREE_COL_DEFAULTS = {
  settings: {
    heightContext: 'px',
    mode: 'color',
    cols: 12,
    color: { 
      alpha: 0, 
      hex: '#000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
  },
  titleColOne: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'titleColOne',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 22,
    style: 'normal',
    value: 'Column Heading',
    weight: 'bold',
    marginLeft: 0,
    marginRight: 0,
  },
  textColOne: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'textColOne',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 12,
    style: 'normal',
    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a odio consequat, volutpat massa quis, blandit est. Fusce porta ex at magna faucibus eleifend. Ut vel justo in diam porttitor cursus sed non arcu. Nulla ut mauris ornare, commodo elit quis, porta nisl. Curabitur eleifend porttitor ipsum, a hendrerit purus. Sed posuere, est nec posuere mollis, massa diam maximus est, nec lobortis enim felis in nunc. Nunc suscipit, velit eget volutpat aliquam, sem libero hendrerit elit, id consectetur lorem nunc id lorem. Sed rhoncus erat vel turpis dictum dignissim. In egestas congue ornare. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin at nibh semper, malesuada odio quis, sollicitudin libero. Ut ullamcorper, elit non posuere volutpat, quam justo volutpat justo, id fermentum sapien ex eu dolor. In ornare consequat tristique. Fusce neque dui, pellentesque ac sollicitudin et, porta ac leo. Nam et mauris cursus, finibus turpis id, volutpat lacus.',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  titleColTwo: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'titleColTwo',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 22,
    style: 'normal',
    value: 'Column Heading',
    weight: 'bold',
    marginLeft: 0,
    marginRight: 0,
  },
  textColTwo: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'textColTwo',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 12,
    style: 'normal',
    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a odio consequat, volutpat massa quis, blandit est. Fusce porta ex at magna faucibus eleifend. Ut vel justo in diam porttitor cursus sed non arcu. Nulla ut mauris ornare, commodo elit quis, porta nisl. Curabitur eleifend porttitor ipsum, a hendrerit purus. Sed posuere, est nec posuere mollis, massa diam maximus est, nec lobortis enim felis in nunc. Nunc suscipit, velit eget volutpat aliquam, sem libero hendrerit elit, id consectetur lorem nunc id lorem. Sed rhoncus erat vel turpis dictum dignissim. In egestas congue ornare. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin at nibh semper, malesuada odio quis, sollicitudin libero. Ut ullamcorper, elit non posuere volutpat, quam justo volutpat justo, id fermentum sapien ex eu dolor. In ornare consequat tristique. Fusce neque dui, pellentesque ac sollicitudin et, porta ac leo. Nam et mauris cursus, finibus turpis id, volutpat lacus.',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  titleColThree: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'titleColThree',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 22,
    style: 'normal',
    value: 'Column Heading',
    weight: 'bold',
    marginLeft: 0,
    marginRight: 0,
  },
  textColThree: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'textColThree',
    floatText: 'text-center',
    font: 'Arial, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 12,
    style: 'normal',
    value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque a odio consequat, volutpat massa quis, blandit est. Fusce porta ex at magna faucibus eleifend. Ut vel justo in diam porttitor cursus sed non arcu. Nulla ut mauris ornare, commodo elit quis, porta nisl. Curabitur eleifend porttitor ipsum, a hendrerit purus. Sed posuere, est nec posuere mollis, massa diam maximus est, nec lobortis enim felis in nunc. Nunc suscipit, velit eget volutpat aliquam, sem libero hendrerit elit, id consectetur lorem nunc id lorem. Sed rhoncus erat vel turpis dictum dignissim. In egestas congue ornare. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin at nibh semper, malesuada odio quis, sollicitudin libero. Ut ullamcorper, elit non posuere volutpat, quam justo volutpat justo, id fermentum sapien ex eu dolor. In ornare consequat tristique. Fusce neque dui, pellentesque ac sollicitudin et, porta ac leo. Nam et mauris cursus, finibus turpis id, volutpat lacus.',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  screen: {
    height: 85,
    type: 'screen',
    direction: 'to top',
    color: { 
      alpha: 0, 
      hex: '#00000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
    fromColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
    toColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
  },
  heightValue: {
    px: 450,
    vh: 30,
  },
  overlap: {
    px: 0,
    vh: 0,
  },
  block: {
    cols: 9,
    align: 'start',
    justify: 'center',
    padding: {
      bottom: 50,
      left: 8,
      right: 8,
      top: 50,
    }
  },
  logoColOne: {
    align: 'center',
    placement: 'top',
    justify: 'center',
    height: 50,
    boxHeight: 10,
    image: '',
    previewImage: ''
  },
  logoColTwo: {
    align: 'center',
    placement: 'top',
    justify: 'center',
    height: 50,
    boxHeight: 10,
    image: '',
    previewImage: ''
  },
  logoColThree: {
    align: 'center',
    placement: 'top',
    justify: 'center',
    height: 50,
    boxHeight: 10,
    image: '',
    previewImage: ''
  },
  background: {
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_2000x2000.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7',
    previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_300x300.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7'
  }
};

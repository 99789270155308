<template>
  <div>
    <v-card class="el">
      <v-toolbar flat dense>
        <div class="incentable-form-heading">iOS App</div>
        <v-spacer></v-spacer>
        <div v-if="currentProgram.iosAppStatus === 'Inactive'">
          <v-icon class="red--text pr-1" size="21">wifi</v-icon>
          <span class="dash-card">Inactive</span>
        </div>
        <div v-if="currentProgram.iosAppStatus === 'Active'">
          <v-icon class="green--text pr-1" size="21">wifi</v-icon>
          <span class="dash-card">Active</span>
        </div>
        <div v-if="currentProgram.iosAppStatus === 'Processing'">
          <v-icon class="amber--text pr-1" size="21">pending</v-icon>
          <span class="dash-card">Processing</span>
        </div>
      </v-toolbar>
      <v-card-text v-if="currentProgram.iosAppStatus === 'Active'">
        <v-row justify="center" class="pb-4">
          <a :href="currentProgram.appleStoreUrl" target="_blank">
            <img
              src="/img/apple.png"
              alt="Download on the App Store"
              height="45px"
            />
          </a>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row justify="center" class="pb-4">
          <img
            src="/img/apple.png"
            alt="Download on the App Store"
            height="45px"
          />
        </v-row>
        <v-row
          v-if="currentProgram.iosAppStatus === 'Inactive'"
          class="dash-card mb-2"
          justify="center"
        >
          <a
            href="https://incentable.zendesk.com/hc/en-us/requests/new"
            class="mr-1"
            target="_blank"
            >Get in touch</a
          >
          to add an App
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["currentProgram"],
  data: function () {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style></style>

<template>
  <div>
    <v-card class="px-3 pt-3 mt-2 editor" elevation="0">
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters>
            <v-menu offset-y transition="slide-y-transition" z-index="3000">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-card
                    elevation="0"
                    class="pl-3"
                    width="200px"
                    height="24px"
                    light
                  >
                    <span class="font-select-label">{{ fontLabel }}</span>
                    <v-icon class="float-right">arrow_drop_down</v-icon>
                  </v-card>
                </div>
              </template>
              <v-list>
                <v-list-item
                  v-for="font in fonts"
                  :key="font.label"
                  :style="'font-family: ' + font.class + ' !important;'"
                  @click="setFont(font)"
                  dense
                >
                  {{ font.label }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>

          <v-row no-gutters class="mt-3">
            <v-icon class="ml-0 icon-button" @click="setWeight()"
              >format_bold</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setStyle()"
              >format_italic</v-icon
            >

            <v-menu
              bottom
              nudge-bottom="25"
              :close-on-content-click="false"
              transition="slide-y-transition"
              max-width="300px"
              z-index="3000"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-1 icon-button"
                  >format_color_text</v-icon
                >
              </template>

              <v-color-picker
                @input="handleChange()"
                dot-size="25"
                show-swatches
                mode="hexa"
                swatches-max-height="200"
                v-model="editingField.color"
              ></v-color-picker>
            </v-menu>

            <v-icon class="ml-2 icon-button" @click="setAlign('text-left')"
              >format_align_left</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setAlign('text-center')"
              >format_align_center</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setAlign('text-right')"
              >format_align_right</v-icon
            >
          </v-row>

          <v-row no-gutters class="mt-4 px-1">
            <v-col cols="4">
              <v-icon class="icon">swap_horiz</v-icon>
              <span class="setting-label">Align</span>
            </v-col>
            <v-icon @click="setHorizontal('start')" class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setHorizontal('center')"
              >align_horizontal_center</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setHorizontal('end')"
              >align_horizontal_right</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setVertical('start')"
              >align_vertical_top</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setVertical('center')"
              >align_vertical_center</v-icon
            >
            <v-icon class="ml-2 icon-button" @click="setVertical('end')"
              >align_vertical_bottom</v-icon
            >
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-3 px-1">
        <v-col cols="4">
          <v-icon class="icon">format_size</v-icon>
          <span class="setting-label">Font Size</span>
        </v-col>
        <v-col>
          <v-slider
            v-model="editingField.size"
            @input="handleChange()"
            :thumb-size="24"
            thumb-label
            min="8"
            max="200"
            class="mt-n1 mr-2"
            color="primary"
          ></v-slider>
        </v-col>
        <v-col cols="auto" class="setting-label">
          <v-row no-gutters>
            <v-col>
              <v-icon
                size="11"
                @click="
                  handleChange();
                  editingField.size--;
                "
                class="icon"
                >remove_circle</v-icon
              >
            </v-col>
            <v-col>
              <v-sheet
                color="transparent"
                min-width="25px"
                class="text-center white--text"
              >
                {{ editingField.size }}
              </v-sheet>
            </v-col>
            <v-col>
              <v-icon
                size="11"
                @click="
                  handleChange();
                  editingField.size++;
                "
                class="icon"
                >add_circle</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-n3 px-1">
        <v-col cols="4">
          <v-icon class="icon">format_line_spacing</v-icon>
          <span class="setting-label">Line Height</span>
        </v-col>
        <v-slider
          v-model="editingField.lineHeight"
          @input="handleChange()"
          :thumb-size="24"
          thumb-label
          min="0.1"
          max="4"
          step="0.1"
          class="mt-n1 mr-2"
          color="primary"
        ></v-slider>
        <v-col cols="auto" class="setting-label">
          <v-row no-gutters>
            <v-col>
              <v-icon @click="minus('lineHeight', 0.1)" size="11" class="icon"
                >remove_circle</v-icon
              >
            </v-col>
            <v-col>
              <v-sheet
                color="transparent"
                min-width="25px"
                class="text-center white--text"
              >
                {{ editingField.lineHeight }}
              </v-sheet>
            </v-col>
            <v-col>
              <v-icon size="11" @click="plus('lineHeight', 0.1)" class="icon"
                >add_circle</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-n3 px-1">
        <v-col cols="4">
          <v-icon class="rotate icon">height</v-icon>
          <span class="setting-label">Width</span>
        </v-col>
        <v-slider
          v-model="editingField.cols"
          @input="handleChange()"
          :thumb-size="24"
          thumb-label
          min="1"
          max="12"
          class="mt-n1 mr-2"
          color="primary"
        ></v-slider>
        <v-col cols="auto" class="setting-label">
          <v-row no-gutters>
            <v-col>
              <v-icon
                @click="
                  handleChange();
                  editingField.cols--;
                "
                size="11"
                class="icon"
                >remove_circle</v-icon
              >
            </v-col>
            <v-col>
              <v-sheet
                color="transparent"
                min-width="25px"
                class="text-center white--text"
              >
                {{ editingField.cols }}
              </v-sheet>
            </v-col>
            <v-col>
              <v-icon
                size="11"
                @click="
                  handleChange();
                  editingField.cols++;
                "
                class="icon"
                >add_circle</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-n3 px-1">
        <v-col cols="4">
          <v-icon class="icon">expand</v-icon>
          <span class="setting-label">Box Height</span>
        </v-col>
        <v-slider
          v-model="editingField.boxHeight"
          @input="handleChange()"
          :thumb-size="24"
          thumb-label
          min="8"
          max="200"
          class="mt-n1 mr-2"
          color="primary"
        ></v-slider>
        <v-col cols="auto" class="setting-label">
          <v-row no-gutters>
            <v-col>
              <v-icon
                @click="
                  handleChange();
                  editingField.boxHeight--;
                "
                size="11"
                class="icon"
                >remove_circle</v-icon
              >
            </v-col>
            <v-col>
              <v-sheet
                color="transparent"
                min-width="25px"
                class="text-center white--text"
              >
                {{ editingField.boxHeight }}
              </v-sheet>
            </v-col>
            <v-col>
              <v-icon
                size="11"
                @click="
                  handleChange();
                  editingField.boxHeight++;
                "
                class="icon"
                >add_circle</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-n3 px-1">
        <v-col cols="4">
          <v-icon class="rotate270 icon">margin</v-icon>
          <span class="setting-label">Left Margin</span>
        </v-col>
        <v-slider
          v-model="editingField.marginLeft"
          @input="handleChange()"
          :thumb-size="24"
          thumb-label
          min="0"
          max="100"
          class="mt-n1 mr-2"
          color="primary"
        ></v-slider>
        <v-col cols="auto" class="setting-label">
          <v-row no-gutters>
            <v-col>
              <v-icon
                @click="
                  handleChange();
                  editingField.marginLeft--;
                "
                size="11"
                class="icon"
                >remove_circle</v-icon
              >
            </v-col>
            <v-col>
              <v-sheet
                color="transparent"
                min-width="25px"
                class="text-center white--text"
              >
                {{ editingField.marginLeft }}
              </v-sheet>
            </v-col>
            <v-col>
              <v-icon
                size="11"
                @click="
                  handleChange();
                  editingField.marginLeft++;
                "
                class="icon"
                >add_circle</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-n3 px-1">
        <v-col cols="4">
          <v-icon class="rotate icon">margin</v-icon>
          <span class="setting-label">Right Margin</span>
        </v-col>
        <v-slider
          v-model="editingField.marginRight"
          @input="handleChange()"
          :thumb-size="24"
          thumb-label
          min="0"
          max="100"
          class="mt-n1 mr-2"
          color="primary"
        ></v-slider>
        <v-col cols="auto" class="setting-label">
          <v-row no-gutters>
            <v-col>
              <v-icon
                @click="
                  handleChange();
                  editingField.marginRight--;
                "
                size="11"
                class="icon"
                >remove_circle</v-icon
              >
            </v-col>
            <v-col>
              <v-sheet
                color="transparent"
                min-width="25px"
                class="text-center white--text"
              >
                {{ editingField.marginRight }}
              </v-sheet>
            </v-col>
            <v-col>
              <v-icon
                size="11"
                @click="
                  handleChange();
                  editingField.marginRight++;
                "
                class="icon"
                >add_circle</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="!saveButton" no-gutters justify="end" class="mt-n5 pb-2">
        <design-save-button :small="true"></design-save-button>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    hideSaveButton: {
      type: Boolean,
      required: false,
    },
  },
  created() {},
  data: function () {
    return {
      fonts: [
        { label: "Arial", class: "Arial, sans-serif" },
        { label: "Bebas Neue", class: "Bebas Neue, sans-serif" },
        { label: "Caveat", class: "Caveat, sans-serif" },
        { label: "Cinzel", class: "Cinzel, serif" },
        { label: "Dancing Script", class: "Dancing Script, serif" },
        { label: "Lato", class: "Lato, sans-serif" },
        { label: "Lobster", class: "Lobster, sans-serif" },
        { label: "Merriweather", class: "Merriweather, serif" },
        { label: "Pacifico", class: "Pacifico, sans-serif" },
        { label: "Playfair Display", class: "Playfair Display, serif" },
        { label: "Quicksand", class: "Quicksand, sans-serif" },
        { label: "Raleway", class: "Raleway, sans-serif" },
        { label: "Roboto", class: "Roboto, sans-serif" },
        { label: "Sans Serif", class: "sans-serif" },
        { label: "Satisfy", class: "Satisfy, sans-serif" },
        { label: "Spectral", class: "Spectral, serif" },
        { label: "Work Sans", class: "Work Sans, sans-serif" },
      ],
    };
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    editingField() {
      return this.$store.getters.editingField;
    },
    fontLabel() {
      const val = this.fonts.find((el) => el.class === this.editingField.font);
      return val.label;
    },
    saveButton() {
      if (this.hideSaveButton) {
        return this.hideSaveButton;
      } else {
        return true;
      }
    },
  },
  methods: {
    plus(field, val) {
      if (field === "lineHeight") {
        this.editingField.lineHeight = this.editingField.lineHeight + val;
        this.handleChange();
      } else {
        return;
      }
    },
    minus(field, val) {
      if (field === "lineHeight") {
        this.editingField.lineHeight = this.editingField.lineHeight - val;
        this.handleChange();
      } else {
        return;
      }
    },
    handleChange() {
      this.$store.dispatch("setHasWebThemePendingUpdates", true);
    },
    setFont(val) {
      this.editingField.font = val.class;
      this.handleChange();
    },
    setAlign(val) {
      this.editingField.floatText = val;
      this.handleChange();
    },
    setHorizontal(val) {
      this.editingField.justify = val;
      this.handleChange();
    },
    setVertical(val) {
      this.editingField.align = val;
      this.handleChange();
    },
    setWeight() {
      if (
        this.editingField.weight === "normal" ||
        this.editingField.weight === null ||
        this.editingField.weight === ""
      ) {
        this.editingField.weight = "bold";
      } else {
        this.editingField.weight = "normal";
      }
      this.handleChange();
    },
    setStyle() {
      if (
        this.editingField.style === "normal" ||
        this.editingField.style === null ||
        this.editingField.style === ""
      ) {
        this.editingField.style = "italic";
      } else {
        this.editingField.style = "normal";
      }
      this.handleChange();
    },
  },
};
</script>

<style scoped>
.icon-button {
  color: #b4b4b4 !important;
}
.setting-label {
  color: #ffffff !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 11px !important;
  font-weight: 300 !important;
}
.size-label {
  color: #e6e6e6 !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  margin-left: 2px !important;
  margin-top: 4px !important;
}
</style>

import { db, auth, timestamp } from "@/firebase";

const state = {
  activeProgram: null,
  activatingMember: null,
};

const actions = {
  async getActivationDetails({ commit }, payload) {
    const { programId, memberId } = payload;

    commit("setActiveProgram", null);
    commit("setActivatingMember", null);
    if (!programId || !memberId) {
      return;
    }

    const programRef = db.collection("programs").doc(programId);
    const memberRef = programRef.collection("members").doc(memberId);

    let program, member;
    try {
      const [programSnapshot, memberSnapshot] = await Promise.all([
        programRef.get(),
        memberRef.get(),
      ]);
      program = programSnapshot.data();
      member = memberSnapshot.data();
    } catch (e) {
      throw "Error when fetching the program and the member";
    }

    if (!program || !member) {
      return;
    }

    program.id = programId;
    member.id = memberId;

    commit("setActiveProgram", program);
    commit("setActivatingMember", member);
  },

  async registerMember({ dispatch }, payload) {
    let authResult;

    if (!getters.activeProgram || !getters.activatingMember) {
      throw "Invalid Entry";
    }

    authResult = await auth().createUserWithEmailAndPassword(
      payload.email,
      payload.password
    );
    if (!authResult.user) {
      throw "Auth Error";
    }

    await dispatch("activateMember");
  },

  async activateMember({ getters }) {
    const { activeProgram, activatingMember } = getters;

    if (!activeProgram || !activatingMember) {
      throw "Invalid Entry";
    }

    const programRef = db.collection("programs").doc(activeProgram.id);
    const memberRef = programRef.collection("members").doc(activatingMember.id);

    await memberRef.update({
      status: "Active",
      activated: timestamp,
    });
  },
};

const getters = {
  activationError(state) {
    const { activeProgram, activatingMember } = state;
    if (!activeProgram) {
      return "Invalid Program";
    }
    if (!activatingMember) {
      return "Invalid Member";
    }
    return "";
  },

  activeProgram(state) {
    return state.activeProgram;
  },

  activatingMember(state) {
    return state.activatingMember;
  },
};

const mutations = {
  setActiveProgram(state, payload) {
    state.activeProgram = payload;
  },

  setActivatingMember(state, payload) {
    state.activatingMember = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import "@babel/polyfill";
import { auth } from "@/firebase";
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import VeeValidate from "vee-validate";
import router from "./router";
import { store } from "./store";
import * as utils from "./utils";
import VueGlow from "vue-glow";
import i18n from "@/plugins/i18n";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

// custom components for widgets in other apps
import VueCustomElement from "vue-custom-element";
//import PlansWidget from './components/Program/Setup/PlansWidget.vue'
Vue.use(VueCustomElement);
//Vue.customElement('plans-widget', PlansWidget)
// incentable\src\main.js
Vue.use(VuePlyr, {
  plyr: {},
});

//Filters
import DateFilter from "@/filters/date";
import ConvertTimestamp from "@/filters/convertTimestamp";
import NumberFilter from "@/filters/number";
import numberTwoDecimal from "@/filters/numberTwoDecimal";
import LongDateFilter from "@/filters/longdate";
import TimeFilter from "@/filters/time";
import TimeStampFilter from "@/filters/timestamp";
import ShortDate from "@/filters/shortdate";

Vue.filter("time", TimeFilter);
Vue.filter("timestamp", TimeStampFilter);
Vue.filter("date", DateFilter);
Vue.filter("convertTimestamp", ConvertTimestamp); // handles raw Firebase timestamp
Vue.filter("number", NumberFilter);
Vue.filter("numberTwoDecimal", numberTwoDecimal);
Vue.filter("longdate", LongDateFilter);
Vue.filter("shortdate", ShortDate);

// Design
import ViewTheme from "@/components/Design/View/ViewTheme.vue";
Vue.component("view-theme", ViewTheme);

// Shared
import DesignHeadingEditor from "@/components/Design/Editor/Shared/HeadingEditor.vue";
import DesignSaveButton from "@/components/Design/Editor/Shared/DesignSaveButton.vue";
import LiveButton from "@/components/Shared/LiveButton";
import PageHeading from "@/components/Shared/PageHeading";
import Alert from "@/components/Shared/Alert";
import DatePicker from "@/components/Shared/DatePicker";
import HowTo from "@/components/Shared/HowTo";
import IconSelect from "@/components/Shared/IconSelect";
import LoadingCards from "@/components/Shared/LoadingCards";
import NavDrawer from "@/components/Shared/NavDrawer";
import StatusIcon from "@/components/Shared/StatusIcon";
import SortDialog from "@/components/Shared/SortDialog";
import Snackbar from "@/components/Shared/Snackbar";
import SnackbarLight from "@/components/Shared/SnackbarLight";
import CompanyName from "@/components/Shared/CompanyName";
import MemberTagName from "@/components/Shared/MemberTagName";
import LottieAnimation from "lottie-web-vue";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueConfetti from "vue-confetti";

Vue.use(VueConfetti);
Vue.use(PerfectScrollbar);

Vue.component("design-heading-editor", DesignHeadingEditor);
Vue.component("design-save-button", DesignSaveButton);
Vue.component("live-button", LiveButton);
Vue.component("page-heading", PageHeading);
Vue.component("app-alert", Alert);
Vue.component("date-picker", DatePicker);
Vue.component("how-to", HowTo);
Vue.component("icon-selector", IconSelect);
Vue.component("loading-cards", LoadingCards);
Vue.component("nav-drawer", NavDrawer);
Vue.component("status-icon", StatusIcon);
Vue.component("sort-dialog", SortDialog);
Vue.component("app-snackbar", Snackbar);
Vue.component("app-snackbar-light", SnackbarLight);
Vue.component("vue-glow", VueGlow);
Vue.component("company-name", CompanyName);
Vue.component("member-tag-name", MemberTagName);
Vue.component("lottie-animation", LottieAnimation);

// 3rd party components
import UploadButton from "vuetify-upload-button";
import draggable from "vuedraggable";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vuetify/dist/vuetify.min.css";
import FontPicker from "font-picker-vue";

Vue.component("font-picker-vue", FontPicker);
Vue.component("upload-btn", UploadButton);
Vue.component("draggable", draggable);
Vue.component("vue-dropzone", vue2Dropzone);

Vue.use(FontPicker);
Vue.use(draggable);
Vue.use(UploadButton);
Vue.use(VeeValidate);

// CSV Import
import CsvImport from "@/components/Shared/CsvImport";
Vue.component("csv-import", CsvImport);

//Admins
import SystemAdminAddOrEdit from "@/components/SystemAdmin/SystemAdminAddOrEdit";
import SystemAdminsList from "@/components/SystemAdmin/SystemAdminsList";
Vue.component("system-admins-list", SystemAdminsList);
Vue.component("system-admin-add-or-edit", SystemAdminAddOrEdit);

//Sites
import Sites from "@/components/Site/Sites";
import SiteNewOrEdit from "@/components/Site/SiteNewOrEdit";

Vue.component("log-list", LogList);

//Logs
import LogList from "@/components/Log/LogList";

Vue.component("sites", Sites);
Vue.component("site-new-or-edit", SiteNewOrEdit);

// Data buckets
import DatabucketAdd from "@/components/Databucket/DatabucketAdd";
import DatabucketSync from "@/components/Databucket/DatabucketSync";

// Members
import MembersList from "@/components/Member/MembersList";
import MemberAddOrEdit from "@/components/Member/MemberAddOrEdit";
import MemberTagsList from "@/components/Member/MemberTagsList";
import MemberTagAddOrEdit from "@/components/Member/MemberTagAddOrEdit";
import MembersImport from "@/components/Member/MembersImport";

// Companies
import CompaniesList from "@/components/Company/CompaniesList";
import CompanyAddOrEdit from "@/components/Company/CompanyAddOrEdit";
import CompanyTagsList from "@/components/Company/CompanyTagsList";
import CompanyTagAddOrEdit from "@/components/Company/CompanyTagAddOrEdit";
import CompaniesImport from "@/components/Company/CompaniesImport";

import Translate from "@/components/Translate/TranslationsList";
Vue.component("translations-list", Translate);

// Offers
import OfferResources from "@/components/Offer/OfferResources";

// Rewards
import RewardsTable from "@/components/Reward/RewardsTable";
import RewardsNewOrEdit from "@/components/Reward/RewardsNewOrEdit";
import RewardCategoriesTable from "@/components/Reward/RewardCategoriesTable";
import RewardCategoriesNewOrEdit from "@/components/Reward/RewardCategoriesNewOrEdit";
import RewardCataloguesTable from "@/components/Reward/RewardCataloguesTable";
import RewardCataloguesNewOrEdit from "@/components/Reward/RewardCataloguesNewOrEdit";

Vue.component("rewards-table", RewardsTable);
Vue.component("rewards-new-or-edit", RewardsNewOrEdit);
Vue.component("reward-categories-new-or-edit", RewardCategoriesNewOrEdit);
Vue.component("reward-categories-table", RewardCategoriesTable);
Vue.component("reward-catalogues-table", RewardCataloguesTable);
Vue.component("reward-catalogues-new-or-edit", RewardCataloguesNewOrEdit);

// Organisation
import OrganisationAdd from "@/components/Organisation/OrganisationAdd";
import OrgSettings from "@/components/Organisation/Settings";
import OrgConfig from "@/components/Organisation/Configuration";
import OrgHiddenConfig from "@/components/Organisation/HiddenConfiguration";
import OrgAdmins from "@/components/Organisation/OrgAdminsList";
import OrgAdminAddOrEdit from "@/components/Organisation/OrgAdminAddOrEdit";
import OrgAdminDelete from "@/components/Organisation/OrgAdminDelete";

// Communication
import Email from "@/components/Communicate/Email";
import NewEmail from "@/components/Communicate/NewEmail";
import Notification from "@/components/Communicate/Notification";
import NewNotification from "@/components/Communicate/NewNotification";
import CommunicationPreview from "@/components/Communicate/Preview";
import CommunicationRecipients from "@/components/Communicate/Recipients";
import CommunicationsSent from "@/components/Communicate/CommunicationsSent";
import ReplyToSettings from "@/components/Communicate/ReplyToSettings";
import BannerSettings from "@/components/Communicate/BannerSettings";

Vue.component("email", Email);
Vue.component("new-email", NewEmail);
Vue.component("notification", Notification);
Vue.component("new-notification", NewNotification);
Vue.component("communication-recipients", CommunicationRecipients);
Vue.component("communication-preview", CommunicationPreview);
Vue.component("communications-sent", CommunicationsSent);
Vue.component("reply-to-settings", ReplyToSettings);
Vue.component("banner-settings", BannerSettings);

// Pages
import PagesList from "@/components/Page/PagesList";
import PageAddOrEdit from "@/components/Page/PageAddOrEdit";

// Menu
import PrimaryMenusList from "@/components/Menu/PrimaryMenusList";
import PrimaryMenuAddOrEdit from "@/components/Menu/PrimaryMenuAddOrEdit";
import SecondaryMenusList from "@/components/Menu/SecondaryMenusList";
import SecondaryMenuAddOrEdit from "@/components/Menu/SecondaryMenuAddOrEdit";

// Article
import BlogArticlesTable from "@/components/Blog/BlogArticlesTable";
import BlogArticlesNewOrEdit from "@/components/Blog/BlogArticlesNewOrEdit";
import BlogCategoriesTable from "@/components/Blog/BlogCategoriesTable";
import BlogCategoriesNewOrEdit from "@/components/Blog/BlogCategoriesNewOrEdit";
import BlogsTable from "@/components/Blog/BlogsTable";
import BlogsNewOrEdit from "@/components/Blog/BlogsNewOrEdit";

Vue.component("blog-articles-table", BlogArticlesTable);
Vue.component("blog-articles-new-or-edit", BlogArticlesNewOrEdit);
Vue.component("blog-categories-new-or-edit", BlogCategoriesNewOrEdit);
Vue.component("blog-categories-table", BlogCategoriesTable);
Vue.component("blogs-table", BlogsTable);
Vue.component("blogs-new-or-edit", BlogsNewOrEdit);

// Report
import CompanyTablesReport from "@/components/Report/CompanyTablesReport";

Vue.mixin({
  methods: {
    isRoleSatisfied(requiredRole) {
      return utils.isAuthReqFulfilled(
        requiredRole,
        store.getters.currentUserRole
      );
    },
  },
});

Vue.component("organisation-add", OrganisationAdd);
Vue.component("company-tables-report", CompanyTablesReport);
Vue.component("companies-list", CompaniesList);
Vue.component("company-add-or-edit", CompanyAddOrEdit);
Vue.component("company-tags-list", CompanyTagsList);
Vue.component("companytag-add-or-edit", CompanyTagAddOrEdit);
Vue.component("companies-import", CompaniesImport);
Vue.component("databucket-add", DatabucketAdd);
Vue.component("databucket-sync-dialog", DatabucketSync);
Vue.component("offer-resources", OfferResources);
Vue.component("org-admins", OrgAdmins);
Vue.component("org-admin-add-or-edit", OrgAdminAddOrEdit);
Vue.component("org-admin-delete", OrgAdminDelete);
Vue.component("org-settings", OrgSettings);
Vue.component("org-config", OrgConfig);
Vue.component("org-hidden-config", OrgHiddenConfig);
Vue.component("primary-menus-list", PrimaryMenusList);
Vue.component("primarymenu-add-or-edit", PrimaryMenuAddOrEdit);
Vue.component("secondary-menus-list", SecondaryMenusList);
Vue.component("secondarymenu-add-or-edit", SecondaryMenuAddOrEdit);
Vue.component("members-list", MembersList);
Vue.component("member-add-or-edit", MemberAddOrEdit);
Vue.component("member-tags-list", MemberTagsList);
Vue.component("membertag-add-or-edit", MemberTagAddOrEdit);
Vue.component("members-import", MembersImport);
Vue.component("pages-list", PagesList);
Vue.component("page-add-or-edit", PageAddOrEdit);

Vue.config.productionTip = false;

Vue.filter("truncate", function (text, limit) {
  if (text.length < limit) {
    return text;
  }
  return `${text.substring(0, limit)}...`;
});

/* eslint-disable no-new */
new Vue({
  i18n,
  el: "#app",
  router: router,
  store: store,
  vuetify,
  created: () => {
    auth.onAuthStateChanged((status) => {
      if (status) {
        const user = auth.currentUser;
        const emailVerified = user.emailVerified;
        // only email-verified can log in
        if (emailVerified) {
          store.dispatch("autoSignIn", user);
        }
      }
    });
  },
  render: (h) => h(App),
}).$mount("#app");

/* eslint-disable no-console */
<template>
  <div class="px-4 pt-4">
    <form data-vv-scope="replyto">
      <v-row no-gutters>
        <v-col sm="3">
          <v-text-field
            type="text"
            label="Reply Email"
            v-validate="'email'"
            v-model="replyEmail"
            :error-messages="errors.collect('replyto.email')"
            messages="noreply@incentable.com is used by default."
            name="email"
            clearable
          ></v-text-field>
          <v-btn
            @click="handleSaveReplySettings('replyto')"
            color="primary"
            elevation="0"
            :loading="replySettingProcessing"
            class="mt-3 ml-0"
            :disabled="demo"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>
<script>
import { storage, timestamp } from "@/firebase";
import { MAX_BANNER_FILE_SIZE, DEFAULT_BANNER_URL } from "@/constants/email";

export default {
  $_veeValidate: {
    validator: "new",
  },

  mounted() {
    const settings =
      this.$store.state.program.currentProgram.notificationSettings || {};
    this.replyEmail = settings.replyEmail || "";
    this.bannerUrl = settings.bannerUrl || "";
    this.$validator.localize("en", this.dictionary);
  },

  data: () => ({
    titleIcon: "reply",
    sectionTitle: "Default Reply To",
    sectionSubtitle:
      "Email recipients will see this email adress in their inbox and it will be used when they reply to your email",
    dictionary: {
      custom: {
        email: {
          email: () => "Reply Email must be a valid email address.",
        },
      },
    },
    replyEmail: "",
    replySettingProcessing: false,
    bannerSettingProcessing: false,
    defaultBannerUrl: DEFAULT_BANNER_URL,
    bannerName: "",
    bannerUrl: "",
    bannerUploadTask: null,
    bannerUploadingProgress: 0,
    bannerUploading: false,
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },

  methods: {
    openFileBrowser() {
      this.$refs.bannerFileInput.click();
    },

    handleSaveReplySettings(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }

        const payload = {
          replyEmail: this.replyEmail,
        };

        this.replySettingProcessing = true;
        this.$store.dispatch("updateNotificationSettings", payload);
        this.$store
          .dispatch("setReplyEmail", payload)
          .then(() => (this.replySettingProcessing = false))
          .catch(() => (this.replySettingProcessing = false));
      });
    },

    handleFilePicked(e) {
      const file = e.target.files[0];

      if (!file || file.size > MAX_BANNER_FILE_SIZE) {
        return;
      }

      this.bannerName = file.name;

      this.bannerUploadTask = storage
        .ref(
          `uploads/${this.programId}/emailBanners/${timestamp}_${this.bannerName}`
        )
        .put(file);

      this.bannerUploadingProgress = 0;

      this.bannerUploading = true;
    },

    handleClearBanner() {
      this.bannerName = "";
      this.bannerUploadTask = null;
      this.bannerUploadingProgress = 0;
      this.bannerUploading = false;
      this.bannerUrl = "";
    },

    handleSaveBannerSettings() {
      const payload = {
        bannerUrl: this.bannerUrl,
      };

      this.bannerSettingProcessing = true;
      this.$store
        .dispatch("updateNotificationSettings", payload)
        .then(() => (this.bannerSettingProcessing = false))
        .catch(() => (this.bannerSettingProcessing = false));
    },
  },
  watch: {
    bannerUploadTask: function () {
      if (!this.bannerUploadTask) {
        return;
      }

      this.bannerUploadTask.on(
        "state_changed",
        (sp) => {
          if (sp.totalBytes <= 0) {
            return;
          }
          this.bannerUploadingProgress = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        () => {
          this.bannerUploading = false;
        },
        () => {
          this.bannerUploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              this.bannerUrl = downloadURL;
              this.bannerUploading = false;
            });
        }
      );
    },
  },
};
</script>

<style scoped>
img {
  width: 600px;
}
</style>

<template>
  <div>
    <v-app-bar
      class="page-bar"
      dense
      fixed
      color="grey lighten-4"
      elevate-on-scroll
    >
      <v-row no-gutters justify="center" class="mx-9">
        <v-col cols="8" class="pt-2">
          <span class="page-heading">{{ pageTitle }}</span>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" elevation="0" @click="goback"
            >cancel create new program & exit
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-row no-gutters justify="center" class="mx-16 mt-14">
      <v-col cols="12" lg="10">
        <v-stepper v-model="stage" vertical>
          <!----------------------------------------------->
          <v-stepper-step step="1" :complete="stage > 1">
            <v-row no-gutters align="center">
              <v-col cols="1">
                <span class="table-title-stepper">Program Title</span>
              </v-col>
              <v-col cols="auto" class="ml-5">
                <span v-if="stage > 1" class="table-subtitle-stepper">{{
                  newProgramData.title
                }}</span>
              </v-col>
            </v-row>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-row no-gutters class="pt-1">
              <v-col md="6" xs="10">
                <v-text-field
                  outlined
                  v-model="newProgramData.title"
                  label="Program Title"
                  v-validate="'required'"
                  :error-messages="errors.first('title')"
                  data-vv-name="title"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col md="4" xs="2" class="pt-4">
                <span v-if="titleExists" class="ml-4 incentable-card-subtitle"
                  ><v-icon color="red" class="pr-2">cancel</v-icon>Not
                  Available</span
                >
                <span
                  v-else-if="!titleInvalid"
                  class="ml-4 incentable-card-subtitle"
                  ><v-icon color="green" class="pr-2">check_circle</v-icon
                  >Available</span
                >
              </v-col>
            </v-row>
            <v-row no-gutters class="ml-n1">
              <v-btn
                color="primary"
                @click="nextStep()"
                elevation="0"
                :disabled="titleInvalid || titleExists"
              >
                Next
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="2" :complete="stage > 2">
            <v-row no-gutters align="center">
              <v-col cols="1">
                <span class="table-title-stepper">Domain Name</span>
              </v-col>
              <v-col cols="auto" class="ml-5">
                <span v-if="stage > 2" class="table-subtitle-stepper"
                  >{{ newProgramData.url.toLowerCase() }}.incentable.com</span
                >
              </v-col>
            </v-row>
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-row no-gutters class="pt-1">
              <v-col md="6" xs="10">
                <v-text-field
                  v-validate="'required|alpha_num|min:2|max:50'"
                  :error-messages="errors.collect('Subdomain')"
                  data-vv-name="Subdomain"
                  v-model="newProgramData.url"
                  label="Subdomain"
                  suffix=".incentable.com"
                  type="text"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" xs="2" class="pt-4">
                <span v-if="domainExists" class="ml-4 incentable-card-subtitle"
                  ><v-icon color="red" class="pr-2">cancel</v-icon>Not
                  Available</span
                >
                <span
                  v-else-if="!domainInvalid"
                  class="ml-4 incentable-card-subtitle"
                  ><v-icon color="green" class="pr-2">check_circle</v-icon
                  >Available</span
                >
              </v-col>
            </v-row>
            <v-row no-gutters class="ml-n1">
              <v-btn
                color="primary"
                @click="nextStep()"
                elevation="0"
                :disabled="domainInvalid"
              >
                Next
              </v-btn>
              <v-btn color="primary" @click="backStep()" elevation="0" text>
                Back
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="3" :complete="stage > 3">
            <v-row no-gutters align="center">
              <v-col cols="1">
                <span class="table-title-stepper">Theme</span>
              </v-col>
              <v-col cols="auto" class="ml-5">
                <v-chip v-if="stage > 3" class="mr-2" color="#FFF3E0"
                  ><v-icon left color="#FF9800" size="18">palette</v-icon
                  ><span :style="'color: #FF9800;'">{{
                    themeName
                  }}</span></v-chip
                >
              </v-col>
            </v-row>
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-row no-gutters justify="space-between" class="mx-3">
              <v-col cols="8" class="pr-2">
                <v-select
                  class="mt-1 caption"
                  :items="themeTags"
                  v-model="tagFilter"
                  multiple
                  solo-inverted
                  dense
                  flat
                  chips
                  small-chips
                  deletable-chips
                  clearable
                  hide-details
                  prepend-inner-icon="filter_list"
                  placeholder="Filter By Tag"
                ></v-select>
              </v-col>
              <v-col cols="4" class="pl-4">
                <v-text-field
                  class="mt-1 caption"
                  v-model="search"
                  solo-inverted
                  dense
                  flat
                  hide-details
                  prepend-inner-icon="search"
                  clearable
                  placeholder="Search By Title"
                  @click:clear="clearSearch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <themes
                  mode="selector"
                  @onSelectTheme="onSelectTheme"
                  :search="search"
                  :programs="filteredProgramThemes"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="ml-n1">
              <v-btn color="primary" @click="nextStep()" elevation="0">
                Next
              </v-btn>
              <v-btn color="primary" @click="backStep()" elevation="0" text>
                Back
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="4" :complete="stage > 4">
            <v-row no-gutters align="center">
              <v-col cols="1">
                <span class="table-title-stepper">Members</span>
              </v-col>
              <v-col cols="auto" class="ml-5">
                <DisplaySelections
                  v-if="stage > 4"
                  :selections="members.selected"
                  :color="members.color"
                  :chipColor="members.chipColor"
                />
              </v-col>
            </v-row>
            <v-row v-show="stage === 4" no-gutters class="hint mt-2">{{
              members.hint
            }}</v-row>
          </v-stepper-step>
          <v-stepper-content step="4">
            <OptionsSelector
              :options="members.options"
              :multiSelect="members.multiSelect"
              @updateSelection="updateMembersSelection"
            />

            <v-row no-gutters class="ml-n1">
              <v-btn color="primary" @click="nextStep()" elevation="0">
                Next
              </v-btn>
              <v-btn color="primary" @click="backStep()" elevation="0" text>
                Back
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="5" :complete="stage > 5">
            <v-row no-gutters align="center">
              <v-col cols="1">
                <span class="table-title-stepper">Companies</span>
              </v-col>
              <v-col cols="auto" class="ml-5">
                <DisplaySelections
                  v-if="stage > 5"
                  :selections="companies.selected"
                  :color="companies.color"
                  :chipColor="companies.chipColor"
                />
              </v-col>
            </v-row>
            <v-row v-show="stage === 5" no-gutters class="hint mt-2">{{
              companies.hint
            }}</v-row>
          </v-stepper-step>
          <v-stepper-content step="5">
            <OptionsSelector
              :options="companies.options"
              :multiSelect="companies.multiSelect"
              @updateSelection="updateCompaniesSelection"
            />
            <v-row no-gutters class="ml-n1">
              <v-btn color="primary" @click="nextStep()" elevation="0">
                Next
              </v-btn>
              <v-btn color="primary" @click="backStep()" elevation="0" text>
                Back
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="6" :complete="stage > 6">
            <v-row no-gutters align="center">
              <v-col cols="1">
                <span class="table-title-stepper">Apps</span>
              </v-col>
              <v-col cols="auto" class="ml-5">
                <DisplaySelections
                  v-show="stage > 6"
                  :selections="apps.selected"
                  :color="apps.color"
                  :chipColor="apps.chipColor"
                />
              </v-col>
            </v-row>
            <v-row v-show="stage === 6" no-gutters class="hint mt-2 mb-n5">{{
              apps.hint
            }}</v-row>
          </v-stepper-step>
          <v-stepper-content step="6">
            <OptionsSelector
              :options="apps.options"
              :multiSelect="apps.multiSelect"
              @updateSelection="updateAppsSelection"
            />
            <v-row no-gutters class="ml-n1">
              <v-btn color="primary" @click="nextStep()" elevation="0">
                Next
              </v-btn>
              <v-btn color="primary" @click="backStep()" elevation="0" text>
                Back
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="7" :complete="stage > 7">
            <v-row no-gutters align="center">
              <v-col cols="1">
                <span class="table-title-stepper">Administrators</span>
              </v-col>
              <v-col cols="auto" class="ml-5">
                <DisplaySelections
                  v-show="stage > 7"
                  :selections="admins.selected"
                  :color="admins.color"
                  :chipColor="admins.chipColor"
                />
              </v-col>
            </v-row>
            <v-row v-show="stage === 7" no-gutters class="hint mt-2">{{
              admins.hint
            }}</v-row>
          </v-stepper-step>
          <v-stepper-content step="7">
            <OptionsSelector
              :options="admins.options"
              :multiSelect="admins.multiSelect"
              @updateSelection="updateAdminsSelection"
            />
            <v-row no-gutters class="ml-n1">
              <v-btn color="primary" @click="nextStep()" elevation="0">
                Next
              </v-btn>
              <v-btn color="primary" @click="backStep()" elevation="0" text>
                Back
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="8" :complete="stage > 8">
            <v-row no-gutters align="center">
              <v-col cols="1">
                <span class="table-title-stepper">Subscription Plan</span>
              </v-col>
            </v-row>
            <v-row v-show="stage === 8" no-gutters class="hint mt-2">{{
              plans.hint
            }}</v-row>
          </v-stepper-step>
          <v-stepper-content step="8" class="mx-0">
            <plans-table
              v-if="stage === 8"
              :newProgramData="newProgramData"
              :recomend="whichPlan()"
              class="mt-n3"
            />
            <v-btn
              color="primary"
              @click="backStep()"
              elevation="0"
              text
              :disabled="loading"
            >
              Back
            </v-btn>
          </v-stepper-content>
          <!----------------------------------------------->
        </v-stepper>
        <v-alert
          v-model="titleError"
          color="primary"
          border="left"
          dense
          text
          type="info"
          class="incentable-alert mx-6 mt-0"
        >
          The Program Title you have entered is not available. Use the "Back"
          buttons to go back and change it.
        </v-alert>
        <v-alert
          v-model="urlError"
          color="primary"
          border="left"
          dense
          text
          type="info"
          class="incentable-alert mx-6 mt-0"
        >
          That Subdomain you have entered is not available. Use the "Back"
          buttons to go back and change it.
        </v-alert>
        <legal-links />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { RESERVED_DOMAINS } from "@/constants/subdomain.js";
import Themes from "../Programs/Components/Themes.vue";
import PlansTable from "../Setup/PlansTable.vue";
import LegalLinks from "../../Shared/LegalLinks.vue";
import OptionsSelector from "./Components/OptionsSelector.vue";
import DisplaySelections from "./Components/DisplaySelections.vue";

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    Themes,
    PlansTable,
    LegalLinks,
    OptionsSelector,
    DisplaySelections,
  },
  data() {
    return {
      product: null,
      term: null,
      search: "",
      tagFilter: "",
      paid: false,
      page: 1,
      perPage: 9,
      stage: 1,
      themeName: "",
      recomendedPlan: "pro+",
      newProgramData: {
        title: "",
        url: "",
        webTheme: {},
      },
      dictionary: {
        custom: {
          title: {
            required: () => "A program title is required",
          },
          Subdomain: {
            required: () => "A program subdomain is required",
            alpha_num:
              "Subdomain can only contain alphabetic characters or numbers without spaces",
          },
        },
      },
      members: {
        options: [
          { id: "100", text: "1 - 100", icon: "person_outline" },
          { id: "250", text: "101 - 250", icon: "person_outline" },
          { id: "500", text: "251 - 500", icon: "person_outline" },
          { id: "1000", text: "501 - 1,000", icon: "person_outline" },
          { id: "2000", text: "1,001 - 2,000", icon: "person_outline" },
          { id: "3000", text: "2,001 - 3,000", icon: "person_outline" },
          { id: "3000+", text: "> 3,000", icon: "person_outline" },
        ],
        selected: [],
        multiSelect: false,
        hint: "How many participants do you expect to have?",
        color: "#2196F3",
        chipColor: "#E3F2FD",
      },
      companies: {
        options: [
          { id: "none", text: "No Companies", icon: "domain_disabled" },
          {
            id: "single",
            text: "Single Company",
            icon: "domain",
          },
          { id: "multiple", text: "Mulitple Companies", icon: "domain_add" },
        ],
        selected: [],
        multiSelect: false,
        hint: "Need to track members by company? Choose 'Multiple Companies' to manage content based on their company or 'Single Company' for employee programs. Not sure? Choose 'No Companies', as you can change this later if required.",
        color: "#4CAF50",
        chipColor: "#E8F5E9",
      },
      apps: {
        options: [
          {
            id: "rewards",
            text: "Rewards Catalog",
            icon: "fa-solid fa-gift",
            premium: false,
          },
          {
            id: "leaderboards",
            text: "Leaderboards",
            icon: "emoji_events",
            premium: true,
          },
          {
            id: "targets",
            text: "Target Trackers",
            icon: "fa fa-bullseye",
            premium: true,
          },
          {
            id: "awards",
            text: "Awards",
            icon: "fa-solid fa-medal",
            premium: true,
          },
          {
            id: "tiers",
            text: "Tiers",
            icon: "fa-solid fa-ranking-star",
            premium: true,
          },
          { id: "claims", text: "Claims", icon: "receipt_long", premium: true },
          {
            id: "quizzes",
            text: "Quizzes",
            icon: "fa-solid fa-clipboard-question",
            premium: true,
          },
        ],
        selected: [],
        multiSelect: true,
        color: "#E91E63",
        chipColor: "#FCE4EC",
        hint: "Activate additional features by selecting one or more apps. If you're not sure what you need, don't worry, you can add or remove apps later too.",
      },
      admins: {
        options: [
          { id: "1", text: "1", icon: "support_agent" },
          { id: "2", text: "2", icon: "support_agent" },
          { id: "3", text: "3", icon: "support_agent" },
          { id: "4", text: "4", icon: "support_agent" },
          { id: "5", text: "5", icon: "support_agent" },
          { id: "6+", text: "6+", icon: "support_agent" },
        ],
        selected: [],
        multiSelect: false,
        color: "#673AB7",
        chipColor: "#EDE7F6",
        hint: "How many program administrators (collegues) do you expect to have? Individual admin accounts are required to access training and support.",
      },
      plans: {
        options: [
          { id: "Lite", text: "Lite" },
          { id: "Lite+", text: "Lite+" },
          { id: "Pro", text: "Pro" },
          { id: "Pro+", text: "Pro+" },
          { id: "Enterprise", text: "Enterprise" },
          { id: "Enterprise+", text: "Enterprise+" },
        ],
        hint: "This plan suits your selections perfectly!",
        color: "#E91E63",
        chipColor: "#F8BBD0",
        selected: [],
        multiSelect: false,
      },
    };
  },
  created() {},
  mounted() {
    this.$store.dispatch("clearCurrentProgram"),
      this.$store.dispatch("initialize"),
      this.$store.commit("setNavMenu", false),
      this.$store.dispatch("setToolbar", true),
      this.$store.dispatch("setFooter", true),
      this.$store.dispatch("loadProgramsQuickList"),
      this.$store.commit("setLoadingCards", false),
      this.$store.dispatch("loadProgramThemes");
    this.$validator.localize("en", this.dictionary);
  },
  computed: {
    programs() {
      return this.$store.state.program.programsQuickList;
    },
    loading() {
      return this.$store.getters.loading;
    },
    programThemes() {
      return this.$store.state.program.programThemes;
    },
    titleError() {
      return this.$store.getters.prgTitleExists;
    },
    urlError() {
      return this.$store.getters.prgUrlExists;
    },
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    setupMode() {
      return this.$store.getters.setupMode;
    },
    pageTitle() {
      if (this.setupMode === "trial") {
        return "14 Day Free Trial Program Set Up";
      }
      return "Create New Program";
    },
    filteredProgramThemes() {
      const arr = this.tagFilter.length
        ? this.programThemes.filter((x) =>
            x.webTheme.tags.some((g) => this.tagFilter.includes(g))
          )
        : this.programThemes;
      const partial = arr.slice(0, this.page * this.perPage);
      return partial;
    },
    themeTags() {
      const array = this.programThemes;
      let result = [];
      array.forEach((el) => {
        result = [...result.concat(el.webTheme.tags)];
      });
      const uniq = [...new Set(result)].sort();
      console.uniq;
      if (uniq.length > 0) {
        return uniq;
      }
      return [];
    },
    currentTitles() {
      return this.programs.map((el) => {
        return el["titleUppercase"];
      });
    },
    currentUrls() {
      const programUrls = this.programs.map((el) => {
        return el["url"];
      });
      return programUrls.concat(RESERVED_DOMAINS);
    },
    titleExists() {
      var title = this.newProgramData.title.toUpperCase();
      const result = this.currentTitles.includes(title);
      if (result) {
        return true;
      }
      return false;
    },
    titleInvalid() {
      if (!this.newProgramData.title || this.$validator.errors.has("title")) {
        return true;
      } else {
        if (this.currentTitles.includes(this.newProgramData.title)) {
          return true;
        }
        return false;
      }
    },
    domainExists() {
      var completeUrl =
        this.newProgramData.url.toLowerCase() + ".incentable.com";
      const result = this.currentUrls.includes(completeUrl);
      if (result) {
        return true;
      }
      return false;
    },
    domainInvalid() {
      if (!this.newProgramData.url || this.$validator.errors.has("Subdomain")) {
        return true;
      } else {
        var completeUrl =
          this.newProgramData.url.toLowerCase() + ".incentable.com";
        if (this.currentUrls.includes(completeUrl)) {
          return true;
        }
        return false;
      }
    },
  },
  methods: {
    nextStep() {
      this.stage++;
    },
    backStep() {
      this.stage--;
    },
    goback() {
      this.$store.dispatch("setSetupMode", null);
    },
    clearSearch() {
      this.search = "";
    },
    onSelectTheme(val) {
      this.newProgramData.webTheme = val;
      this.themeName = val.title;
      this.stage++;
    },
    updateMembersSelection(val) {
      this.members.selected = val;
    },
    updateCompaniesSelection(val) {
      this.companies.selected = val;
    },
    updateAppsSelection(val) {
      this.apps.selected = val;
    },
    updateAdminsSelection(val) {
      this.admins.selected = val;
    },
    whichPlan() {
      return { id: "pro" };
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.page-bar {
  margin-top: 96px !important;
}
.table-subtitle-stepper {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #363636 !important;
}
.hint {
  font-size: 13px !important;
  font-weight: 300 !important;
  color: #363636 !important;
}
</style>

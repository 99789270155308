<template>
  <div>
    <v-app-bar
      class="page-bar"
      dense
      fixed
      color="grey lighten-4"
      elevate-on-scroll
    >
      <v-row no-gutters justify="center">
        <v-col cols="12" lg="8">
          <span class="page-heading">Billing</span>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-row no-gutters justify="center" class="ml-3 mt-14">
      <v-col cols="12" lg="8" class="mt-4">
        Incentable partners with Stripe to simplify billing.
        <br><br>Use the Stripe billing portal to update payment methods
        <br><br>For subscription details and invoices, go to the subscription tab in the program settings menu
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="mt-6 ml-2">
      <v-col cols="12" lg="8">
        <v-btn
          elevation="0"
          color="primary"
          @click="stripeCustomerPortal"
          :loading="loading"
          :disabled="loading"
        >
          Go To Billing
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="mt-6">
      <v-col cols="12" lg="8">
        <v-row no-gutters>
          <v-col cols="auto" class="powered-subtitle">
              Powered By 
          </v-col>
          <v-col> 
            <v-img
              :src="require('@/assets/stripe.png')"
              contain
              max-width="75"
            ></v-img>
          </v-col>

        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  created () {
    this.$store.commit('setNavMenu', false)
    this.$store.dispatch('setLoading', false)
    this.$store.dispatch('setToolbar', true)
    this.$store.dispatch('setFooter', true)
  },
  mounted () {
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
  },
  methods: {
    stripeCustomerPortal() {
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('stripeCustomerPortal')
    }
  },
}
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
}
.page-bar {
  margin-top: 96px !important;
}
.powered-subtitle {
  font-family: 'Roboto', sans-serif !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #888888 !important;
  margin-top: 10px;
  margin-left: 8px;
}
</style>

<template>
  <div>
    <v-dialog
      v-model="open"
      persistent
      width="500px"
    >
      <v-card
        elevation="0"
        >
          <v-img
            height="350"
            :src="reward.externalImageUrl ? reward.externalImageUrl : reward.image.mediumUrl"
            :lazy-src="reward.externalImageUrl ? reward.externalImageUrl : reward.image.mediumUrl"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-title class="page-heading">
            {{ reward.title }}
          </v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2">
                <v-row no-gutters class="menu-label">
                  SKU:
                </v-row>
                <v-row no-gutters class="menu-label">
                  Points:
                </v-row>
                <v-row no-gutters class="menu-label">
                  Price:
                </v-row>
                <v-row no-gutters class="menu-label">
                  Supplier:
                </v-row>
              </v-col>
              <v-col>
                <v-row no-gutters>
                  {{ reward.sku }}
                </v-row>
                <v-row no-gutters>
                  {{ reward.points | number }}
                </v-row>
                <v-row no-gutters>
                  ${{ reward.price | number }}
                </v-row>
                <v-row no-gutters>
                  {{ reward.supplier }}
                </v-row>
                
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3">
              {{ reward.body }}
            </v-row>
            
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="primary"
              @click="handleClose()"
            >
              close
            </v-btn>
          </v-card-actions>
        </v-card>
      
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['reward', 'open'],
  data: () => ({
  }),
  methods: {
    handleClose () {
      this.$emit('onClose');
    }
  }
}
</script>
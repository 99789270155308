<template>
  <div class="px-4 pt-4">
    <v-row cols="12">
      <v-col lg="4" class="pr-16">
        <v-row class="incentable-form-heading"> To </v-row>
        <v-row class="incentable-form-subheading">
          Status and Tag settings of this email
        </v-row>

        <v-row>
          <v-select
            v-model="notification.memberStatus"
            :items="statusList"
            item-text="tag"
            item-value="id"
            label="Member Statuses"
            chips
            multiple
            small-chips
            deletable-chips
            append-icon="label"
            readonly
          ></v-select>
        </v-row>
        <v-row>
          <v-col class="ml-n3">
            <v-select
              v-model="notification.companyTags"
              :items="companyTags"
              item-text="tag"
              item-value="id"
              label="Company Tags"
              chips
              multiple
              small-chips
              deletable-chips
              append-icon="label"
              readonly
            ></v-select>
          </v-col>
          <v-col class="mr-n3">
            <v-select
              v-model="notification.memberTags"
              :items="memberTags"
              item-text="tag"
              item-value="id"
              label="Member Tags"
              persistent-hint
              chips
              multiple
              small-chips
              deletable-chips
              append-icon="label"
              readonly
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="incentable-form-heading"> From </v-row>
        <v-row class="incentable-form-subheading">
          The email address used at the reply to
        </v-row>
        <v-row>
          <v-text-field
            readonly
            v-model="notification.replyEmail"
            label="Reply Email"
            type="text"
            name="subject"
          />
        </v-row>
        <v-row class="incentable-form-heading"> Subject </v-row>
        <v-row class="incentable-form-subheading">
          The subject line members saw in their inbox
        </v-row>
        <v-row>
          <v-text-field
            v-validate="'required'"
            :error-messages="errors.collect('email.subject')"
            v-model="notification.subject"
            label="Subject"
            type="text"
            name="subject"
            readonly
          />
        </v-row>
      </v-col>

      <v-col cols="12" lg="8">
        <v-row align-content="center" class="mt-5">
          <v-card color="grey lighten-3 rounded-0" flat width="100%">
            <v-row justify="center">
              <v-card
                flat
                class="pa-5 mt-10 mb-12 rounded-0"
                width="645px"
                justify="center"
              >
                <v-card flat class="mb-5 pt-0 mt-0 rounded-0">
                  <v-img
                    :src="notification.imageUrl"
                    :lazy-src="require('@/assets/placeholder.png')"
                    transition="fade-transition"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>

                <v-card-text class="mx-n1">
                  <v-row class="e-heading">
                    {{ notification.heading }}
                  </v-row>
                  <v-row class="e-subheading">
                    {{ notification.subheading }}
                  </v-row>
                  <v-row class="e-body">
                    {{ notification.body }}
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row justify="center" class="e-footer mb-3 mt-n5">
              Sent By {{ notification.sender }}
            </v-row>
            <v-row justify="center" class="e-footer mb-6">
              Powered by Incentable
            </v-row>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { MEMBER_STATUS } from "@/constants/member";
export default {
  data: () => ({
    statusList: MEMBER_STATUS,
  }),
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
  },
  methods: {},
};
</script>

<style scoped>
.e-heading {
  font-family: Sans-Serif !important;
  font-weight: bold !important;
  font-size: 25px !important;
  color: #616161 !important;
}
.e-subheading {
  font-family: Sans-Serif !important;
  font-weight: bold !important;
  font-size: 18px !important;
  color: #616161 !important;
  line-height: 3 !important;
  margin-top: 10px !important;
}
.e-body {
  font-family: Sans-Serif !important;
  font-size: 14px !important;
  overflow-wrap: break-word;
  color: #616161 !important;
  line-height: 1.5 !important;
}
.e-footer {
  font-family: Sans-Serif !important;
  font-size: 12px !important;
  color: #616161 !important;
}
</style>

<template>
  <v-card class="rounded-lg el">
    <v-progress-linear
      :indeterminate="true"
      v-if="loadingMembers"
    ></v-progress-linear>
    <v-simple-table class="ml-4 mr-4">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              <span class="incentable-form-heading ml-n4"
                >Top Earning Members</span
              >
            </th>
            <th class="text-right">
              <span class="dash-card"> Points Awarded</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in topMembersPaginated" :key="member.id">
            <td>
              <v-avatar v-if="member.image" size="38" class="mr-4">
                <v-img
                  :src="
                    member.externalImageUrl
                      ? member.externalImageUrl
                      : member.image.smallUrl
                  "
                  :lazy-src="
                    member.externalImageUrl
                      ? member.externalImageUrl
                      : member.image.smallUrl
                  "
                >
                </v-img> </v-avatar
              >{{ member.fullname }}
            </td>
            <td class="text-right">{{ member.awarded | number }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row no-gutters justify="center" class="mt-n2">
      <v-btn
        color="primary"
        small
        @click="loadMoreTopMembers"
        :disabled="allTopMemberRecordsDisplayed"
        text
        >show more</v-btn
      >
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["data"],
  data: () => ({
    topMembersToShow: 5,
    loadMoreIncrement: 2,
  }),
  computed: {
    members() {
      return this.$store.getters.members;
    },
    loadingMembers() {
      return this.$store.state.loadingMembers;
    },
    topMembers() {
      const arr = [...this.members];
      const result = arr.sort((a, b) => b.awarded - a.awarded);
      return result;
    },
    topMembersPaginated() {
      return this.topMembers.slice(0, this.topMembersToShow);
    },
    allTopMemberRecordsDisplayed() {
      return this.topMembersToShow >= this.topMembers.length;
    },
  },
  methods: {
    loadMoreTopMembers() {
      this.topMembersToShow = this.topMembersToShow + this.loadMoreIncrement;
    },
  },
};
</script>

<style scoped></style>

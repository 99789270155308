<template> 
  <v-dialog
    id="dialog"
    v-model="isVisible"
    :width="component.dialog.width"
    :fullscreen="component.dialog.fullscreen"
    persistent
    scrollable
  >
    <v-card
    :class="classes"
    :style="styles + ';' + gradient"
    >
        <v-btn
          v-if="component.dialog.title.floatText === 'text-right' && component.dialog.fullscreen === true"
          top left absolute small icon
          @click="onClose"
          style="z-index: 100"
        >
          <v-icon :color="component.dialog.title.color">clear</v-icon>
        </v-btn>  
        <v-btn
          v-if="component.dialog.title.floatText !== 'text-right' && component.dialog.fullscreen === true"
          top right absolute small icon
          @click="onClose"
          style="z-index: 100"
        >
          <v-icon :color="component.dialog.title.color">clear</v-icon>
        </v-btn>  
        <v-row no-gutters>
          <v-col cols="auto">
            <view-dialog-icon
              v-if="component.dialog.icon.placement === 'left' && component.dialog.icon.show === true" 
              :card="progressData"
              :component="component"
            ></view-dialog-icon>
          </v-col>
          <v-col>
            <v-row no-gutters :justify="component.dialog.icon.justify" v-if="component.dialog.icon.placement === 'top' && component.dialog.icon.show === true">
              <view-dialog-icon
                :card="progressData"
                :component="component"
              ></view-dialog-icon>
            </v-row>
              <view-text 
                :settings="component.dialog.title"
                :value="progressData.displayTitle"
              ></view-text>
            <v-row no-gutters :justify="component.dialog.icon.justify" v-if="component.dialog.icon.placement === 'bottom' && component.dialog.icon.show === true">
              <view-dialog-icon
                :card="progressData"
                :component="component"
              ></view-dialog-icon>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <view-dialog-icon
              v-if="component.dialog.icon.placement === 'right' && component.dialog.icon.show === true" 
              :card="progressData"
              :component="component"
            ></view-dialog-icon>
          </v-col>
        </v-row>
        <perfect-scrollbar>
          <v-card-text class="ma-0 pa-0">
            <v-card color="transparent" elevation="0" :style="summaryTableStyles">
              <v-data-table
                v-if="titleTableData.length !== 0"
                :headers="titleHeaders"
                :items="titleTableData"
                :style="cssVars"
                class="table"
                hide-default-footer
              >
                <template v-slot:[`item.icon`]="{ item }">
                  <v-row no-gutters :justify="iconAlign" class="pa-2">
                    <v-img
                      v-if="item.icon"
                      :src="`/img/icons/${item.icon}`"
                      :max-width="iconSize"
                      :max-height="iconSize"
                      contain
                    />
                  </v-row>
                </template>
                <template v-slot:[`item.avatar`]="{ item }">
                  <v-row no-gutters :justify="iconAlign" class="pa-2">
                    <v-avatar
                      :size="avatarSize"
                    >
                      <v-img :src="`${item[avatarId]}`" alt="alt"></v-img>
                    </v-avatar>
                  </v-row>
                </template>
                <template v-slot:[`item.qualifier`]="{ item }">
                  <v-row no-gutters :justify="iconAlign" class="pa-2">
                    <v-img
                      v-if="item[qualifierId]"
                      :src="`/img/icons/${item[qualifierId]}`"
                      :max-width="iconSize"
                      :max-height="iconSize"
                      contain
                    />
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
            <v-card color="transparent" elevation="0" :style="detailTableStyles">
              <v-data-table
                :headers="headers"
                :items="tableData"
                :style="cssVars"
                class="table"
                hide-default-footer
                :page.sync="page"
                @page-count="pageCount = $event"
                :items-per-page="itemsPerPage"
              >
                <template v-slot:[`item.icon`]="{ item }">
                  <v-row no-gutters :justify="iconAlign" class="pa-2">
                    <v-img
                      v-if="item.icon"
                      :src="`/img/icons/${item.icon}`"
                      :max-width="iconSize"
                      :max-height="iconSize"
                      contain
                    />
                  </v-row>
                </template>
                <!--
                <template v-slot:[`item.avatar`]="{ item }">
                  <v-row no-gutters :justify="iconAlign" class="pa-2">
                    {{ item.avatar }}
                  </v-row>
                </template>
                -->
                <template v-slot:[`item.avatar`]="{ item }">
                  <v-row no-gutters :justify="iconAlign" class="pa-2">
                    <v-avatar
                      :size="avatarSize"
                    >
                      <v-img :src="`${item[avatarIdx]}`" alt="alt"></v-img>
                    </v-avatar>
                  </v-row>
                </template>
                <template v-slot:[`item.qualifier`]="{ item }">
                  <v-row no-gutters :justify="iconAlign" class="pa-2">
                    <v-img
                      v-if="item[qualifierId]"
                      :src="`/img/icons/${item[qualifierId]}`"
                      :max-width="iconSize"
                      :max-height="iconSize"
                      contain
                    />
                  </v-row>
                </template>
              </v-data-table>
              <v-row justify="center" v-if="pageCount > 1" no-gutters class="mt-2" align="center">
                <v-btn
                  v-if="page > 1"
                  rounded x-small elevation="0" color="transparent" @click="page --">
                  <v-icon
                    :color="this.component.dialog.content.heading.color"
                    size="20"
                  >chevron_left</v-icon>
                </v-btn>
                  <v-btn
                    v-for="page in pageCount"
                    :key="page"
                    x-small
                    elevation="0"
                    color="transparent"
                    rounded
                    @click="handlePage(page)"
                  >
                    <span :style="cssVars" :class="paginationStyle(page)">
                      {{ page }}
                    </span>
                  </v-btn>
                <v-btn
                  v-if="page < pageCount"
                  rounded x-small elevation="0" color="transparent" @click="page ++">
                  <v-icon
                    :color="this.component.dialog.content.heading.color"
                    size="20"
                  >chevron_right</v-icon>
                </v-btn>
              </v-row>
            </v-card>
          </v-card-text>
        </perfect-scrollbar>
        <v-card-actions class="ma-0 pa-0">
          <v-spacer></v-spacer>
          <v-btn
            v-if="component.dialog.fullscreen !== true"
            @click="resetPage(); onClose()"
            text
            :color="component.dialog.title.color"
          >close</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ViewText from '../Text.vue'
import ViewDialogIcon from '../DialogIcon.vue'

 export default {
  components: {
    ViewText,
    ViewDialogIcon
  },
  props: ['isVisible', 'onClose', 'progressData', 'component', 'rankValue'],
  created () {
  },
  data: function () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      show: false,
    }
   },
   computed: {
    winnerText() {
      return 'Winner!'
      // return 'Leader'
      // return 'Top 3!!!'
      // return this.rankValue
    },
    confetti() {
      return {
        windSpeedMax: 2,
        particlesPerFrame: 40,
        canvasId: 'dialog',
        defaultDropRate: 60,
        colors: [
          'gold',
          'yellow',
          'orange'
        ],
        particles: [
          { 
            type: 'circle',
            size: 20,
          },
          { 
            type: 'rect',
            size: 20,
          }
        ]
      }
    },
    cssVars() {
      return {
        '--header-text-color': this.component.dialog.content.heading.color,
        '--header-text-size': this.component.dialog.content.heading.size + 'px',
        '--header-text-style': this.component.dialog.content.heading.style,
        '--header-text-weight': this.component.dialog.content.heading.weight,
        '--header-line-height': this.component.dialog.content.heading.lineHeight,
        '--header-font': this.component.dialog.content.heading.font,
        '--row-text-color': this.component.dialog.content.data.color,
        '--row-text-size': this.component.dialog.content.data.size + 'px',
        '--row-text-style': this.component.dialog.content.data.style,
        '--row-text-weight': this.component.dialog.content.data.weight,
        '--row-line-height': this.component.dialog.content.data.lineHeight,
        '--row-font': this.component.dialog.content.data.font,
        '--text-align': this.component.dialog.content.align,
        '--row-border-bottom': this.rowBorders,
        '--row-hover-color': this.component.dialog.rowHoverColor.hexa,
        '--current-page-color': this.component.dialog.title.color
      }
    },
    classes () {
      if (this.component.dialog.fullscreen === true)
      return 'rounded-0'
      return this.component.card.rounded
    },
    grid() {
      return this.$store.getters.grid
    },
    rowBorders() {
      return this.component.dialog.rowBorderSize + 'px' + 
      ' solid ' + 
      this.component.dialog.rowBorderColor.hexa
    },
    iconAlign() {
      return this.component.dialog.content.align
    },
    iconSize() {
      return this.component.dialog.iconSize
    },
    avatarSize() {
      return this.component.dialog.avatarSize
    },
    gradient() {
      if (this.component.dialog.color.type === 'gradient') {
        var color = this.component.dialog.color
        var gradient = 
        'background: linear-gradient(' +
        color.direction + ', rgba(' +
        color.gradientFrom.rgba.r + ', ' +
        color.gradientFrom.rgba.g + ', ' +
        color.gradientFrom.rgba.b + ', ' +
        color.gradientFrom.rgba.a + ') ' + 
        ', rgba(' +
        color.gradientTo.rgba.r + ', ' +
        color.gradientTo.rgba.g + ', ' +
        color.gradientTo.rgba.b + ', ' +
        color.gradientTo.rgba.a + ')' + color.gradientHeight + '%)'
        return gradient
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return 'background: ' + this.component.dialog.color.solidColor.hexa + ';'
      }
    },
    styles () {
      var styles = 
        'padding-bottom: ' + this.component.dialog.padding.bottom + 'px ;' +
        'padding-top: ' + this.component.dialog.padding.top + 'px ;' +
        'padding-left: ' + this.component.dialog.padding.left + 'px ;' +
        'padding-right: ' + this.component.dialog.padding.right + 'px ;' +
        'border: ' + this.component.dialog.borderSize + 'px solid ' + ';'
        this.component.dialog.borderColor.hexa + '; '
      return styles
    },
    summaryTableStyles () {
      var styles = 
        'margin-bottom: ' + this.component.dialog.summary.margin.bottom + 'px ;' +
        'margin-top: ' + this.component.dialog.summary.margin.top + 'px ;' +
        'margin-left: ' + this.component.dialog.summary.margin.left + 'px ;' +
        'margin-right: ' + this.component.dialog.summary.margin.right + 'px ;'
      return styles
    },
    detailTableStyles () {
      var styles = 
        'margin-bottom: ' + this.component.dialog.detail.margin.bottom + 'px ;' +
        'margin-top: ' + this.component.dialog.detail.margin.top + 'px ;' +
        'margin-left: ' + this.component.dialog.detail.margin.left + 'px ;' +
        'margin-right: ' + this.component.dialog.detail.margin.right + 'px ;'
      return styles
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    headers () {
      if (this.progressData.resultsHeader) {
        const headers = this.progressData.resultsHeader.map((heading) => {
          return {
            text: this.$t(heading.label),
            value: heading.column === 'Icon'
              ? 'icon'
              : heading.column === 'Avatar'
              ? 'avatar'
              : heading.type === 'qualifier'
                ? 'qualifier'
                : heading.uid,
            column: heading.column,
            type: heading.type,
            align: 'center',
            sortable: false
          }
        })
        return headers
      } else {
        return []
      }
    },
    tableData () {
      if (this.progressData.data) {
        const data = this.progressData.data.map(el => {
          return {
            ...el.values,
            icon: el.isWinner ? this.progressData.iconToRender.svgFile : ''
          }
        })
        return data
      } else {
        return []
      }
    },
    qualifierId () {
      if (this.progressData.resultsHeader) {
        const qualifierHeader = this.progressData.resultsHeader.find(el => el.type === 'qualifier') || {}
        return qualifierHeader.uid
      } else {
        return ''
      }
    },
    avatarId () {
      if (this.progressData.cardHeader) {
        const avatarHeader = this.progressData.cardHeader.find(el => el.column === 'Avatar') || {}
        return avatarHeader.uid
      } else {
        return ''
      }
    },
    avatarIdx () {
      if (this.progressData.resultsHeader) {
        const avatarHeader = this.progressData.resultsHeader.find(el => el.column === 'Avatar') || {}
        return avatarHeader.uid
      } else {
        return ''
      }
    },
    iconId () {
      if (this.progressData.cardHeader) {
        const iconHeader = this.progressData.cardHeader.find(el => el.column === 'Icon') || {}
        return iconHeader.uid
      } else {
        return ''
      }
    },
    titleHeaders () {
      if (this.progressData.cardHeader) {
        const headers = this.progressData.cardHeader.map((heading, index) => {
          console.log(index)
          return {
            text: this.$t(heading.label),
            value: heading.column === 'Icon'
              ? 'icon'
              : heading.column === 'Avatar'
              ? 'avatar'
              : heading.type === 'qualifier'
                ? 'qualifier'
                : heading.uid,
            column: heading.column,
            type: heading.type,
            align: 'center',
            sortable: false
          }
        })
        return headers
      } else {
        return []
      }
    },
    titleTableData () {
      if (this.progressData.headerData) {
        console.log(this.progressData.headerData)
        return [{
          ...this.progressData.headerData.values,
          icon: this.progressData.headerData.isWinner ? this.progressData.iconToRender.svgFile : '',
        }]
      } else {
        return []
      }
    },
    
  },
  methods: {
    showText(val) {
      this.show = val
    },
    isWinner () {
      if (this.progressData.headerData && this.progressData.headerData.isWinner) {
        return true
      }
    },
    start() {
      // this.$confetti.start({
      //   particles: this.confetti.particles,
      //   defaultColors: this.confetti.colors
      // })
    },
    stop() {
      // this.$confetti.stop()
    },
    ordinal (n) {
      var s = ['th', 'st', 'nd', 'rd']
      var v = n % 100
      return n + (s[(v - 20) % 10] || s[v] || s[0])
    },
    handlePage(page) {
      this.page = page
    },
    paginationStyle(val) {
      if (val === this.page)
      return 'incentable-current-page'
      return 'incentable-pagination'
    },
    resetPage() {
      this.showText(false)
      // this.stop()
      this.page = 1
    }
  },
  watch: {
    isVisible: {
      handler(newVal) {
        if(newVal && this.isWinner) {
          this.start()
          setTimeout(() => { this.showText(true) }, 1000)
        }
      }
    }
  }
}
</script>

<style>
#confetti {
  position: absolute !important;
  z-index: 5000;
}
.animation-text {
  position: absolute !important;
  margin-top: 25px;
  font-size: 50px;
  z-index: 1000;
  font-weight: 700;
  font-family: Roboto;
  color: rgb(168, 0, 0);
}

.animated-icon {
  position: absolute !important;
  margin-top: -50px;
}
.animated-confetti {
  position:absolute !important;
  z-index: 1000;
  width: 70% !important;
  height: 70% !important;
  margin-left: -250px;
}
.svg-opacity {
  opacity: 0.5 !important;
}
.table.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
  line-height: var(--header-line-height) !important;
  background-color: transparent !important;
  text-align: var(--text-align) !important;
  border-bottom: var(--row-border-bottom) !important;
}
.incentable-pagination {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
}
.incentable-current-page {
  font-family: var(--header-font) !important;
  font-size: var(--header-text-size) !important;
  font-style: var(--header-text-style) !important;
  font-weight: var(--header-text-weight) !important;
  color: var(--current-page-color) !important;
}
.btn-pagination {
  padding-top: 5px;
}
.table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: var(--row-font) !important;
  font-size: var(--row-text-size) !important;
  font-style: var(--row-text-style) !important;
  font-weight: var(--row-text-weight) !important;
  color: var(--row-text-color) !important;
  line-height: var(--row-line-height) !important;
  text-align: var(--text-align) !important;
  background-color: transparent !important;
  border-bottom: var(--row-border-bottom) !important;
}
.table.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-family: var(--header-font) !important;
  font-size:  var(--header-text-size) !important;
  font-style:  var(--header-text-style) !important;
  font-weight:  var(--header-text-weight) !important;
  color: var(--header-text-color) !important;
  line-height: var(--header-line-height) !important;
  background-color: transparent !important;
  text-align: var(--text-align) !important;
  border: none !important;
}
.table.theme--light.v-data-table {
    background-color: transparent !important;
    color: rgba(0,0,0,.87);
}
.theme--light.v-data-table>.v-data-table__wrapper > table > tbody> tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: var(--row-hover-color) !important; 
}
</style>


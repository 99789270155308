export const AVATAR_DEFAULTS = {
  settings: {
    heightContext: 'px',
    mode: 'color',
    cols: 12,
    color: { 
      alpha: 0, 
      hex: '#000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
  },
  name: {
    show: true,
    align: 'center',
    boxHeight: 10,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'name',
    floatText: 'text-center',
    font: 'Quicksand, sans-serif',
    justify: 'center',
    lineHeight: 1.8,
    size: 28,
    style: 'normal',
    value: 'Name',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  email: {
    show: true,
    align: 'end',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'email',
    floatText: 'text-center',
    font: 'Work Sans, sans-serif',
    justify: 'center',
    size: 16,
    lineHeight: 1.3,
    style: 'normal',
    value: 'Email',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  phone: {
    show: true,
    align: 'end',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'phone',
    floatText: 'text-center',
    font: 'Work Sans, sans-serif',
    justify: 'center',
    size: 16,
    lineHeight: 1.3,
    style: 'normal',
    value: 'Phone',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  points: {
    show: true,
    align: 'end',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'points',
    floatText: 'text-center',
    font: 'Work Sans, sans-serif',
    justify: 'center',
    size: 16,
    lineHeight: 1.3,
    style: 'normal',
    value: 'Points',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  screen: {
    height: 85,
    type: 'screen',
    direction: 'to top',
    color: { 
      alpha: 0, 
      hex: '#00000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
    fromColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
    toColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
  },
  heightValue: {
    px: 360,
    vh: 25,
  },
  overlap: {
    px: 0,
    vh: 0,
  },
  block: {
    cols: 6,
    align: 'center',
    justify: 'center',
    padding: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 20,
    }
  },
  avatar: {
    show: true,
    align: 'center',
    placement: 'top',
    justify: 'center',
    height: 150,
    boxHeight: 0,
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/avatars%2FdxkKiRMcpF2rd2ikRbv7%2FK1IxLG5gLHkA8Kon0gyId_christian-buehner-DItYlc26zVI-unsplash_300x300.jpg?alt=media&token=1aa60288-1b10-4e9e-9203-75ecf016e75c',
    previewImage: '',
    border: 10,
    color: { 
      alpha: 0, 
      hex: '#000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
  },
  background: {
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_2000x2000.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7',
    previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_300x300.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7'
  }
};

export const PROFILE_BODY_DEFAULTS = {
  settings: {
    heightContext: 'px',
    mode: 'color',
    cols: 12,
    color: { 
      alpha: 0, 
      hex: '#000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
  },
  labels: {
    align: 'end',
    boxHeight: 10,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'labels',
    floatText: 'text-right',
    font: 'Quicksand, sans-serif',
    justify: 'center',
    lineHeight: 1.8,
    size: 14,
    style: 'normal',
    value: 'Labels',
    weight: 'bold',
    marginLeft: 0,
    marginRight: 0,
  },
  values: {
    align: 'start',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'values',
    floatText: 'text-left',
    font: 'Quicksand, sans-serif',
    justify: 'start',
    size: 14,
    lineHeight: 1.8,
    style: 'normal',
    value: 'Values',
    weight: 'normal',
    marginLeft: 20,
    marginRight: 0,
  },
  lastname: {
    show: true,
  },
  firstname: {
    show: true,
  },
  email: {
    show: true,
  },
  phone: {
    show: true,
  },
  balance: {
    show: true,
  },
  awarded: {
    show: true,
  },
  redeemed: {
    show: true,
  },
  screen: {
    height: 85,
    type: 'screen',
    direction: 'to top',
    color: { 
      alpha: 0, 
      hex: '#00000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
    fromColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
    toColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
  },
  heightValue: {
    px: 250,
    vh: 25,
  },
  overlap: {
    px: 0,
    vh: 0,
  },
  block: {
    cols: 12,
    align: 'start',
    justify: 'center',
    padding: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
    }
  },
  background: {
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_2000x2000.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7',
    previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2F1613359489488_colors_300x300.jpg?alt=media&token=5a49e127-ce44-42f7-977c-b34423b8b5f7'
  }
};



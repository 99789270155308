<template>
  <v-snackbar top center elevation="0" :value="snackbar" :color="primaryColor">
    {{ snackbar }}
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({ defaultColor: "#e91e63" }),
  computed: {
    snackbar() {
      const val = this.$store.state.snackbar;
      return this.$t(val);
    },
    primaryColor() {
      return this.defaultColor;
    },
  },
  watch: {
    snackbar(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$store.dispatch("setSnackbar", null);
        }, 3200);
      }
    },
  },
};
</script>

<template>
  <v-dialog v-model="ruleDialogOpen" max-width="1030px" persistent>
    <v-card>
      <v-card-title>
        <span class="page-heading"
          >{{ editing ? "Edit" : "Add" }} Points Rule</span
        >
      </v-card-title>
      <v-card-text>
        <v-row
          v-for="(subCond, subCondIdx) in cond.subConditions"
          :key="subCondIdx"
          no-gutters
          class="mb-n2"
        >
          <v-col
            class="incentable-formula-heading pr-5 ml-0 primary--text pt-2"
            cols="auto"
            >IF</v-col
          >
          <v-col cols="2" class="mr-1">
            <v-select
              v-model="subCond.leftOperand"
              :items="leftOperandsList"
              item-text="label"
              item-value="type"
              outlined
              dense
              :name="'leftOperand' + subCondIdx"
              :error-messages="getSubConditionError(subCondIdx, 'leftOperand')"
            />
          </v-col>
          <v-col class="mr-1" cols="3">
            <v-select
              v-model="subCond.operator"
              :items="operatorList"
              item-text="label"
              item-value="type"
              outlined
              dense
              :error-messages="getSubConditionError(subCondIdx, 'operator')"
            />
          </v-col>
          <v-col class="mr-2" cols="2" v-if="!subCond.rightOperandVal">
            <v-select
              v-model="subCond.rightOperand"
              :items="rightOperandsList"
              item-text="label"
              item-value="type"
              outlined
              dense
              :error-messages="getSubConditionError(subCondIdx, 'rightOperand')"
            />
          </v-col>
          <v-col class="mr-2" v-if="subCond.rightOperand === 'number'" cols="1">
            <v-text-field
              v-model="subCond.rightOperandVal"
              placeholder="Enter Number"
              label="Number"
              outlined
              dense
              :error-messages="
                getSubConditionError(subCondIdx, 'rightOperandVal')
              "
            />
          </v-col>
          <v-col v-if="subCondIdx !== cond.subConditions.length - 1" cols="1">
            <v-select
              v-model="cond.logicalOperators[subCondIdx]"
              :items="logicOperatorList"
              item-text="label"
              item-value="type"
              placeholder="And/Or"
              dense
              outlined
            />
          </v-col>
          <v-col cols="1">
            <v-btn
              small
              icon
              color="red"
              @click="handleRemoveSubCondition(subCondIdx)"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row no-gutters class="mt-n5 ml-6 mb-5">
          <v-btn @click="handleAddSubCondition()" text color="primary">
            <v-icon>add</v-icon>
            Add condition
          </v-btn>
        </v-row>

        <v-row v-if="cond.subConditions.length" no-gutters class="mt-n1">
          <v-col class="incentable-formula-heading pr-6 ml-3" cols="auto"
            ><v-icon color="primary" size="28"
              >subdirectory_arrow_right</v-icon
            ></v-col
          >
          <v-col
            class="incentable-formula-heading pr-2 pt-2 primary--text"
            cols="auto"
            >THEN</v-col
          >
          <v-col cols="auto">
            <v-checkbox
              v-model="cond.selectedFixed"
              class="mr-n2 ml-2 mt-1"
            ></v-checkbox>
          </v-col>
          <v-col cols="1" class="ml-3">
            <v-text-field
              v-model="cond.result"
              outlined
              dense
              placeholder="Enter Points"
              :disabled="!cond.selectedFixed"
              :error-messages="getConditionError('result')"
            />
          </v-col>
          <v-col
            sm="auto"
            :class="
              cond.selectedFixed
                ? 'award mt-2 ml-2 primary--text'
                : 'award mt-2 ml-2 grey--text'
            "
            >Points (Fixed Amount)
          </v-col>
        </v-row>

        <!-- new dynamic points formula builder -->
        <v-row v-if="cond.subConditions.length" no-gutters class="mt-n2">
          <v-col cols="auto" class="col-adjust-then">
            <v-checkbox
              v-model="cond.selectedDynamic"
              class="mr-n2 ml-2 mt-1"
            ></v-checkbox>
          </v-col>
          <v-col cols="2" class="ml-3">
            <v-select
              v-model="cond.dynamicLeftOperand"
              :items="leftOperandsList"
              item-text="label"
              item-value="type"
              outlined
              dense
              :disabled="!cond.selectedDynamic"
              :error-messages="getConditionError('dynamicLeftOperand')"
            />
          </v-col>
          <v-col cols="1" class="ml-1">
            <v-select
              v-model="cond.dynamicCalc"
              :items="calcList"
              item-text="label"
              item-value="type"
              outlined
              dense
              :disabled="!cond.selectedDynamic"
              :error-messages="getConditionError('dynamicCalc')"
            />
          </v-col>
          <v-col cols="1" class="ml-1">
            <v-text-field
              v-model="cond.dynamicValue"
              outlined
              dense
              placeholder="Enter Points"
              :disabled="!cond.selectedDynamic"
              :error-messages="getConditionError('dynamicValue')"
            />
          </v-col>
          <v-col
            sm="auto"
            :class="
              cond.selectedDynamic
                ? 'award mt-2 ml-2 primary--text'
                : 'award mt-2 ml-2 grey--text'
            "
            >Points (Variable Amount)</v-col
          >
        </v-row>

        <!-- new advanced points formula builder -->
        <v-row v-if="cond.subConditions.length" no-gutters class="mt-n2">
          <v-col cols="auto" class="col-adjust-then">
            <v-checkbox
              :disabled="!isIncentableAdmin"
              v-model="cond.selectedAdvanced"
              class="mr-n2 ml-2 mt-1"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" class="ml-3">
            <v-text-field
              v-model="cond.advancedStatement"
              outlined
              dense
              placeholder="Enter Advanced Points Formula"
              :disabled="!cond.selectedAdvanced && !isIncentableAdmin"
              :error-messages="getConditionError('advancedStatement')"
            />
          </v-col>
          <v-col
            sm="auto"
            :class="
              cond.selectedDynamic
                ? 'award mt-2 ml-2 primary--text'
                : 'award mt-2 ml-2 grey--text'
            "
            >Points (Advanced)</v-col
          >
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-alert
              v-if="checkboxWarning"
              color="primary"
              text
              type="warning"
              border="left"
            >
              At least one 'THEN' calculation must be selected
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog" elevation="0"
          >Cancel</v-btn
        >
        <v-btn
          :disabled="checkboxWarning"
          elevation="0"
          color="primary"
          @click="saveRule()"
          >{{ editing ? "update rule" : "add rule" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    editingItem: Object, // The item being edited, if any
    ruleDialogOpen: Boolean,
  },
  mounted() {},
  data: () => ({
    conditionErrors: [],
    validationRun: false,
    hasErrors: false,
    newCond: {
      subConditions: [
        {
          leftOperand: "",
          operator: "",
          rightOperand: "",
        },
      ],
      logicalOperators: [],
      result: null,
      dynamicValue: null,
      advancedStatement: null,
      selectedFixed: false,
      selectedDynamic: false,
      selectedAdvanced: false,
    },
    calcList: [
      { label: "x", type: "*" },
      { label: "÷", type: "/" },
      { label: "+", type: "+" },
      { label: "-", type: "-" },
    ],
    operatorList: [
      { label: "Is Equal To", type: "===" },
      { label: "Is Greater Than Or Equal To", type: ">=" },
      { label: "Is Greater Than", type: ">" },
      { label: "Is Less Than Or Equal To", type: "<=" },
      { label: "Is Less Than", type: "<" },
    ],
    leftOperandsList: [
      { type: "target", label: "Target" },
      { type: "actual", label: "Actual" },
      { type: "diff", label: "Difference" },
      { type: "percent", label: "Percentage" },
    ],
    rightOperandsList: [
      { type: "target", label: "Target" },
      { type: "actual", label: "Actual" },
      { type: "diff", label: "Difference" },
      { type: "percent", label: "Percentage" },
      { type: "number", label: "Number" },
    ],
    logicOperatorList: [
      { label: "And", type: "&&" },
      { label: "Or", type: "||" },
    ],
  }),
  computed: {
    editing() {
      return !!this.editingItem;
    },
    pointsFormula() {
      return this.$store.getters.databucketPointsFormula;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    cond() {
      if (this.editing) {
        return this.editingItem;
      } else {
        return this.newCond;
      }
    },
    checkboxWarning() {
      if (
        this.cond.selectedFixed === false &&
        this.cond.selectedAdvanced === false &&
        this.cond.selectedDynamic === false &&
        this.validationRun
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getConditionError(key) {
      if (!this.conditionErrors) {
        return;
      }
      if (!this.conditionErrors) {
        return;
      }
      return this.conditionErrors[key];
    },
    getSubConditionError(subCondIdx, key) {
      if (!this.conditionErrors) {
        return;
      }
      if (!this.conditionErrors) {
        return;
      }
      if (!this.conditionErrors.subConditions) {
        return;
      }
      if (!this.conditionErrors.subConditions[subCondIdx]) {
        return;
      }
      return this.conditionErrors.subConditions[subCondIdx][key];
    },
    validateForm() {
      this.validationRun = true;
      const defaultErrorMsg = "Required";
      const errors = {
        subConditions: [],
      };
      if (!this.cond.result && this.cond.selectedFixed) {
        errors.result = defaultErrorMsg;
        this.hasErrors = true;
      }
      if (!this.cond.advancedStatement && this.cond.selectedAdvanced) {
        errors.advancedStatement = defaultErrorMsg;
        this.hasErrors = true;
      }
      if (!this.cond.dynamicCalc && this.cond.selectedDynamic) {
        errors.dynamicCalc = defaultErrorMsg;
        this.hasErrors = true;
      }
      if (!this.cond.dynamicLeftOperand && this.cond.selectedDynamic) {
        errors.dynamicLeftOperand = defaultErrorMsg;
        this.hasErrors = true;
      }
      if (!this.cond.dynamicValue && this.cond.selectedDynamic) {
        errors.dynamicValue = defaultErrorMsg;
        this.hasErrors = true;
      }
      this.conditionErrors = errors;
      this.cond.subConditions.forEach((subcondition, subidx) => {
        errors.subConditions[subidx] = {};
        if (!subcondition.leftOperand) {
          errors.subConditions[subidx].leftOperand = defaultErrorMsg;
          this.hasErrors = true;
        }
        if (!subcondition.operator) {
          errors.subConditions[subidx].operator = defaultErrorMsg;
          this.hasErrors = true;
        }
        if (!subcondition.rightOperand) {
          errors.subConditions[subidx].rightOperand = defaultErrorMsg;
          this.hasErrors = true;
        }
        if (
          subcondition.rightOperand === "number" &&
          !subcondition.rightOperandVal
        ) {
          errors.subConditions[subidx].rightOperandVal = defaultErrorMsg;
          this.hasErrors = true;
        }
      });
      if (this.hasErrors) {
        this.conditionErrors = errors;
        return;
      }
    },
    closeDialog() {
      this.reset();
      this.$emit("onCloseDialog");
    },
    reset() {
      this.validationRun = false;
      this.conditionErrors = [];
      this.hasErrors = false;
      this.newCond = {
        subConditions: [
          {
            leftOperand: "",
            operator: "",
            rightOperand: "",
          },
        ],
        logicalOperators: [],
        result: null,
        dynamicValue: null,
        advancedStatement: null,
        selectedFixed: false,
        selectedDynamic: false,
        selectedAdvanced: false,
      };
    },
    saveRule() {
      this.hasErrors = false;
      this.validateForm();
      if (!this.hasErrors) {
        console.log(this.cond);
        this.$emit("onSaveRule", this.cond);
        this.closeDialog();
      }
    },
    handleAddSubCondition() {
      this.cond.subConditions = [
        ...this.cond.subConditions,
        {
          leftOperand: "",
          operator: "",
          rightOperand: "",
        },
      ];
      this.cond.logicalOperators = [...this.cond.logicalOperators, "&&"];
    },
    handleRemoveSubCondition(subCondIdx) {
      this.cond.subConditions = [
        ...this.cond.subConditions.slice(0, subCondIdx),
        ...this.cond.subConditions.slice(subCondIdx + 1),
      ];
      this.cond.logicalOperators = [
        ...this.cond.logicalOperators.slice(0, subCondIdx),
        ...this.cond.logicalOperators.slice(subCondIdx + 1),
      ];
    },
  },
};
</script>
<style>
.col-adjust-then {
  padding-left: 110px !important;
}
</style>

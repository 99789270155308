<template>
  <div>    
    <v-row cols="12">
      <v-col lg="5" class="pr-16">
        <v-row class="incentable-form-heading">
          To
        </v-row>
        <v-row class="incentable-form-subheading">
          Status and Tag settings of this email
        </v-row>
            
        <v-row>
          <v-select
            v-model="notification.memberStatus"
            :items="statusList"
            item-text="tag"
            item-value="id"
            label="Member Statuses"
            chips
            multiple
            small-chips
            deletable-chips
            append-icon="label"
            readonly
          ></v-select>
        </v-row>
        <v-row>
          <v-col class="ml-n3">
            <v-select
              v-model="notification.companyTags"
              :items="companyTags"
              item-text="tag"
              item-value="id"
              label="Company Tags"
              chips
              multiple
              small-chips
              deletable-chips
              append-icon="label"
              readonly
            ></v-select>
          </v-col>
          <v-col class="mr-n3">
            <v-select
              v-model="notification.memberTags"
              :items="memberTags"
              item-text="tag"
              item-value="id"
              label="Member Tags"
              persistent-hint
              chips
              multiple
              small-chips
              deletable-chips
              append-icon="label"
              readonly
            ></v-select>

          </v-col>
        </v-row>
      </v-col>
      
      <v-col cols="12" lg="7">
        <v-row no-gutters dense class="mt-n4">
          <v-col>
            <v-row justify="center" no-gutters>
              <v-sheet
                width="315px"
                height="657px"
                class="sheet"
                color="secondary"
                >
                <v-img
                  class="mt-0"
                  width="315px"
                  height="657px"
                  :src="require('@/assets/lockscreen.jpg')"
                  gradient="180deg, rgba(0,0,0,0.50) 16%, rgba(255,255,255,0) 50%"
                >
                <v-row>
                  <v-col>
                    <v-row class="mt-12" justify="center">
                      <v-icon color="white" size="30">lock</v-icon>
                    </v-row>
                    <v-row class="clock" justify="center">
                      {{ date | time }}
                    </v-row>
                    <v-row class="date" justify="center">
                      {{ date | longdate }}
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="phone-row">
                  <v-card
                    width="288px"
                    flat
                    class="n-card">

                      <v-row class="py-4" no-gutters>
                        <v-col sm="1" class="n-program-logo pl-2">
                          <v-img :src="require('@/assets/favicon-32x32.png')" height="20px" contain left></v-img>
                        </v-col>
                        <v-col sm="11" class="n-program pl-2">
                          {{ currentProgram.title }}
                        </v-col>
                      </v-row>

                    <v-card-title class="n-heading">
                      {{ notification.heading || 'Heading'}}
                    </v-card-title>
                    <v-card-text class="n-body">
                      {{ notification.body || 'Message'}}
                    </v-card-text>                             
                  </v-card>
                </v-row>

                </v-img>
              </v-sheet>

                <v-img
                  :src="require('@/assets/app_frame.svg')"
                  width="460px"
                  class="phone"
                >
                </v-img>

            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import { MEMBER_STATUS } from '@/constants/member';
  export default {
    data: () => ({
      statusList: MEMBER_STATUS,
    }),
    props: {
      notification: {
        type: Object,
        required: true,
      },
    },
    computed: {
      companyTags () {
        return this.$store.state.companytag.companyTags;
      },
      memberTags () {
        return this.$store.state.membertag.memberTags;
      },
      currentProgram() {
        return this.$store.state.program.currentProgram;
      },
      date () {
        return this.notification.updated
      }
    },
    methods: {
    },
  }
</script>

<style scoped>
.clock {
  font-family: 'Work Sans', sans-serif;
  font-size: 60px !important;
  font-weight: 300 !important;
  color: #FFFFFF !important;
  margin-top: 30px;
}
.date {
  font-family: 'Work Sans', sans-serif;
  font-size: 18px !important;
  font-weight: 300 !important;
  color: #FFFFFF !important;
  margin-top: 20px;
}
.phone-row {
  margin-top: 20px;
  margin-left: 15px;
}
.phone {
  position: absolute;
  z-index: 2;
}
.sheet {
  z-index: 0;
  margin-left: 0px;
  margin-top: 68px;
}
.n-card {
  opacity: 0.7;
}
.n-program-logo {
  margin-top: -8px;
  margin-left: 0px;
}
.n-program {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #000000 !important;
  margin-top: -10px;
  margin-left: -5px;
}
.n-heading {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  color: #000000 !important;
  margin-top: -40px;
  margin-left: -5px;
}
.n-body {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px !important;
  overflow-wrap: break-word;
  color: #000000 !important;
  margin-top: -22px;
  margin-left: -5px;
  line-height: 1.2em;
  margin-bottom: -5px
}
.n-footer {
  font-family: Sans-Serif !important;
  font-size: 12px !important;
  color: #616161 !important;
}
</style>
<template>
  <div class="px-3">
    <v-row align="center" class="mb-4" no-gutters>
      <v-col class="incentable-form-heading">
      </v-col>
      <v-col class="incentable-form-heading pr-2" sm="auto">
        Preview Data Of
      </v-col>
      <v-col sm="4">
        <v-select
          v-model="selectedAccountKey"
          placeholder="Select ID"
          no-data-text="No data has been uploaded"
          :items="accountsMap"
          item-text="label"
          item-value="value"
          solo-inverted
          dense
          flat
          hide-details
          :prepend-inner-icon="getIcon"
          @input="setPreviewAccount()"
        />
      </v-col>
    </v-row>

    <table-column-mapper
      :sourceColumns="sourceColumns"
      mapping="header"
      :csvColumns="csvHeaders"
      @onAdd="handleAddColumn"
    />

    <v-row no-gutters class="mt-6 mb-6" justify="center">
      <table>
        <thead>
          <draggable
            v-model="cardHeaderMappings"
            tag="tr"
            :disabled="demo"
          >
            <th
              v-for="(header, index) of cardHeaderMappings"
              :key="index"
              scope="col"
              class="incentable-leaderboard-table-title-row movable"
            >
              <v-row no-gutters>
                <v-icon class="ml-n4 mr-1 mt-n1" color="grey">drag_indicator</v-icon>
                {{ header.label }}
              </v-row>
              <v-row no-gutters class="incentable-page-subheading grey--text ml-3">
                <v-icon color="grey" size="12" class="mr-1 mt-n1">subdirectory_arrow_right</v-icon>
                {{ header.column }}
              </v-row>
            </th>
          </draggable>
        </thead>
        <tbody v-if="previewAccountKey">
          <tr>  
            <td
              v-for="(header, index) of cardHeaderMappings"
              :key="index"
              align="center"
              class="table-cell"
            >
                <div v-if="header.column === 'Icon'">
                  <div v-if="accountHeaderData.isWinner && iconMappings.svgFile" class="mapping-icon-card secondary rounded">
                    <img :src="'/img/icons/' + iconMappings.svgFile" class="mapping-icon"/>
                  </div>
                </div>
                <div v-else-if="header.type === 'qualifier'">
                  <v-img :src="require(`@/assets/${accountHeaderData[header.column]}`)"  class="mapping-icon-qualifier" />
                </div>
                <span v-else>
                  {{ accountHeaderData[header.column] }}
                </span>
            </td>
          </tr>
        </tbody>
      </table>
    </v-row>
    <v-row
      v-if="!previewAccountKey || accountHeaderData.length === 0"
      class="my-8"
      justify="center"
    >
      <v-icon size="40" color="primary">{{ getIcon }}</v-icon>
      <span class="incentable-page-subheading mt-1 ml-3">
        To see real data, select an account <br>in the 'Preview Data Of' field above
      </span>
    </v-row>
  
  </div>
</template>

<script>
import TableColumnMapper from './ColumnMapper.vue'
export default {
  components: {
    TableColumnMapper
  },
  data: function () {
    const accountsMap = this.$store.getters.tableAccountsMap
    return {
      selectedAccountKey: accountsMap.length ? accountsMap[0].value : '',
    }
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    previewAccountKey () {
      return this.$store.getters.previewAccountKey
    },

    currentTable() {
      return this.$store.state.table.currentTable
    },

    currentTableRaw() {
      return this.$store.state.table.currentTableRaw
    },

    csvHeaders() {
      return this.$store.getters.tableCsvHeaders
    },

    systemHeaders() {
      return this.$store.getters.tableSystemHeaders
    },

    matchingData() {
      return this.$store.getters.tableMatchingData
    },

    columnData() {
      return this.$store.getters.tableColumnData;
    },

    sourceColumns() {
      var label = ''
      if (this.currentTable.mode === 'dynamic') {
        label = 'Data Bucket Fields'
      } else {
        label = 'CSV Columns'
      }
      return [
        { header: 'System Fields'},
        ...this.systemHeaders,
        { header: label},
        ...this.csvHeaders,
      ];
    },

    runningTotalMappings() {
      return this.currentTable.runningTotalMappings;
    },

    headerData() {
      return this.$store.getters.tableHeaderData;
    },

    cardHeaderMappings: {
      get() {
        return this.currentTable.cardHeaderMappings
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { cardHeaderMappings: value })
      }
    },

    iconMappings() {
      return this.currentTable.iconMappings;
    },

    selectedPreviewData() {
      return this.headerData.find(item => {
        return item.accountKey === this.selectedAccountKey
      });
    },

    accountHeaderData() {
      const participant = this.headerData.find(item => item.accountKey === this.previewAccountKey)
      return participant ? participant.data : []
    },
    
    getIcon () {
      if (this.currentTable.participantType === 'member') {
        return 'account_circle'
      } else {
        return 'business'
      }
    },

    loadingCurrentTable() {
      return this.$store.state.table.loadingCurrentTable;
    },

    hasTablePendingUpdates() {
      return this.$store.getters.hasTablePendingUpdates;
    },

    hasTableRawPendingUpdates() {
      return this.$store.getters.hasTableRawPendingUpdates;
    },
    accountsMap () {
      return this.$store.getters.tableAccountsMap
    }

  },
  methods: {
    setPreviewAccount () {
      this.$store.dispatch('setPreviewAccountKey', this.selectedAccountKey)
    },
    handleAddColumn(newMapping) {
      console.log('actioned')
      const cardHeaderMappings = [
        ...this.cardHeaderMappings,
        newMapping,
      ]
      this.$store.dispatch('patchCurrentTable', { 
        cardHeaderMappings })
    },

    handleRemoveColumn(index) {
      const { headerMappings } = this;
      const newHeaderMappings = [
        ...headerMappings.slice(0, index),
        ...headerMappings.slice(index + 1)
      ];

      this.$store.dispatch('patchCurrentTable', {
        headerMappings: newHeaderMappings
      })
    },
  },
}
</script>

<style scoped>
.ghost {
  border: 2px dotted rgb(100, 100, 100);
}

.mapping-item {
  cursor: move;
}

.mapping-icon {
  width: 30px;
  height: 30px;
  padding-top: 1px;
}

.mapping-icon-card {
  width: 32px;
  height: 32px;
}

.mapping-icon-qualifier {
  width: 32px;
}

</style>

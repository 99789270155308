import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import authenticationModule from "@/store/modules/authentication";
import plansModule from "@/store/modules/plans";
import programModule from "@/store/modules/program";
import systemAdminModule from "@/store/modules/systemadmin";
import organisationModule from "@/store/modules/organisation";
import databucketsModule from "@/store/modules/databuckets";
import databucketModule from "@/store/modules/databucket";
import companyModule from "@/store/modules/company";
import siteModule from "@/store/modules/site";
import activityLogModule from "@/store/modules/activitylog";
import memberModule from "@/store/modules/member";
import transactionModule from "@/store/modules/transaction";
import memberTagModule from "@/store/modules/membertag";
import companyTagModule from "@/store/modules/companytag";
import leaderboardModule from "@/store/modules/leaderboard";
import tableModule from "@/store/modules/tables";
import offerModule from "@/store/modules/offers";
import secondaryMenuModule from "@/store/modules/secondarymenu";
import primaryMenuModule from "@/store/modules/primarymenu";
import blogModule from "@/store/modules/blog";
import blogCategoryModule from "@/store/modules/category";
import rewardCatalogueModule from "@/store/modules/rewardcatalogue";
import rewardSupplierModule from "@/store/modules/rewardsupplier";
import rewardModule from "@/store/modules/reward";
import orderModule from "@/store/modules/order";
import rewardCategoryModule from "@/store/modules/rewardcategory";
import articleModule from "@/store/modules/article";
import pageModule from "@/store/modules/page";
import notificationModule from "@/store/modules/notification";
import activationModule from "@/store/modules/activation";
import templatesModule from "@/store/modules/templates";
import galleryModule from "@/store/modules/gallery";
import uploadsModule from "@/store/modules/uploads";
import webthemeModule from "@/store/modules/webtheme";
import logosModule from "@/store/modules/logos";
import translateModule from "@/store/modules/translate";
import messageModule from "@/store/modules/message";
import batchModule from "@/store/modules/batch";
import mediaModule from "@/store/modules/media";
import tierModule from "@/store/modules/tier";
import awardModule from "@/store/modules/award";
import quizzesModule from "@/store/modules/quizzes";
import quizModule from "@/store/modules/quiz";
import quizCategoryModule from "@/store/modules/quizcategory";
import dashboardModule from "@/store/modules/dashboard";
import offervalidationdatasetModule from "./modules/offervalidationdataset";
import offervalidationrecordModule from "./modules/offervalidationrecord";
import offerformbuilderModule from "./modules/offerformbuilder";

// Sample Data for Design Preview
import sampleProgressModule from "@/store/modules/sampledata/progress";

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState()],

  modules: {
    offerformbuilder: offerformbuilderModule,
    offervalidationdataset: offervalidationdatasetModule,
    offervalidationrecord: offervalidationrecordModule,
    authentication: authenticationModule,
    plans: plansModule,
    program: programModule,
    systemadmin: systemAdminModule,
    organisation: organisationModule,
    databuckets: databucketsModule,
    databucket: databucketModule,
    company: companyModule,
    site: siteModule,
    activitylog: activityLogModule,
    member: memberModule,
    transaction: transactionModule,
    membertag: memberTagModule,
    companytag: companyTagModule,
    tier: tierModule,
    award: awardModule,
    leaderboard: leaderboardModule,
    table: tableModule,
    templates: templatesModule,
    offer: offerModule,
    secondarymenu: secondaryMenuModule,
    primarymenu: primaryMenuModule,
    page: pageModule,
    article: articleModule,
    blog: blogModule,
    category: blogCategoryModule,
    reward: rewardModule,
    order: orderModule,
    translate: translateModule,
    rewardcatalogue: rewardCatalogueModule,
    rewardsupplier: rewardSupplierModule,
    rewardcategory: rewardCategoryModule,
    notification: notificationModule,
    activation: activationModule,
    gallery: galleryModule,
    uploads: uploadsModule,
    webtheme: webthemeModule,
    logos: logosModule,
    sampleprogress: sampleProgressModule,
    message: messageModule,
    batch: batchModule,
    media: mediaModule,
    quizzes: quizzesModule,
    quiz: quizModule,
    quizcategory: quizCategoryModule,
    dashboard: dashboardModule,
  },

  state: {
    appTitle: "Incentable",
    navTitle: false,
    host: window.location.host,
    loading: false,
    processingPoints: false,
    drawer: false,
    navmenu: false,
    preview: false,
    howTo: false,
    breadCrumbDetail: null,
    toolbar: false,
    footer: false,
    loadingTable: false,
    loadingCards: false,
    snackbar: null,
    snackbarLight: null,
    error: null,
    previewAccountKey: "",
    firstTimeLoad: true,
  },
  mutations: {
    setFirstTimeLoad(state, payload) {
      state.firstTimeLoad = payload;
    },
    setPreviewAccountKey(state, payload) {
      state.previewAccountKey = payload;
    },
    setNavTitle(state, payload) {
      state.navTitle = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setProcessingPoints(state, payload) {
      state.processingPoints = payload;
    },
    setNavMenu(state, payload) {
      state.navmenu = payload;
    },
    setDrawer(state, payload) {
      state.drawer = payload;
    },
    setPreview(state, payload) {
      state.preview = payload;
    },
    setHowTo(state, payload) {
      state.howTo = payload;
    },
    setBreadCrumbDetail(state, payload) {
      state.breadCrumbDetail = payload;
    },
    setToolbar(state, payload) {
      state.toolbar = payload;
    },
    setFooter(state, payload) {
      state.footer = payload;
    },
    setLoadingTable(state, payload) {
      state.loadingTable = payload;
    },
    setLoadingPointsTable(state, payload) {
      state.loadingPointsTable = payload;
    },
    setLoadingCards(state, payload) {
      state.loadingCards = payload;
    },
    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
    setSnackbarLight(state, payload) {
      state.snackbarLight = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null;
    },
    clearArrays(state) {
      state.company.companies = [];
      state.member.members = [];
      state.leaderboard.leaderboards = [];
      state.databuckets.databuckets = [];
      state.gallery.gallery = [];
      state.uploads.uploads = [];
      state.page.pages = [];
      state.blog.blogs = [];
      state.rewardcatalogue.rewardCatalogues = [];
      state.rewardsupplier.rewardSuppliers = [];
      state.reward.rewards = [];
      state.order.orders = [];
      state.article.articles = [];
      state.category.categorys = [];
      state.tier.tiers = [];
      state.rewardcategory.rewardCategories = [];
      state.table.tables = [];
      state.translate.translations = [];
      state.offer.offers = [];
      state.offer.deletedEntries = [];
      state.table.currentTable = null;
      state.table.currenTableRaw = null;
      state.secondarymenu.secondaryMenus = [];
      state.primarymenu.primaryMenus = [];
      state.notification.notifications = [];
      state.message.messages = [];
      state.quizzes.quizzes = [];
    },
  },
  actions: {
    clearError({ commit }) {
      commit("clearError");
    },
    initialize({ commit }) {
      commit("setNavTitle", false);
      commit("setToolbar", true);
      commit("setLoading", false);
      commit("setLoadingCards", false);
      commit("clearArrays");
      commit("clearError");
      commit("setSnackbar", null);
      commit("setSnackbarLight", null);
    },
    setFirstTimeLoad({ commit }, payload) {
      commit("setFirstTimeLoad", payload);
    },
    setPreviewAccountKey({ commit }, payload) {
      commit("setPreviewAccountKey", payload);
    },
    setLoading({ commit }, payload) {
      commit("setLoading", payload);
    },
    setProcessingPoints({ commit }, payload) {
      commit("setProcessingPoints", payload);
    },
    setNavTitle({ commit }, payload) {
      commit("setNavTitle", payload);
    },
    setNavMenu({ commit }, payload) {
      commit("setNavMenu", payload);
    },
    setDrawer({ commit }, payload) {
      commit("setDrawer", payload);
    },
    setPreview({ commit }, payload) {
      commit("setPreview", payload);
    },
    setHowTo({ commit }, payload) {
      commit("setHowTo", payload);
    },
    setBreadCrumbDetail({ commit }, payload) {
      commit("setBreadCrumbDetail", payload);
    },
    setToolbar({ commit }, payload) {
      commit("setToolbar", payload);
    },
    setFooter({ commit }, payload) {
      commit("setFooter", payload);
    },
    setLoadingTable({ commit }, payload) {
      commit("setLoadingTable", payload);
    },
    setLoadingPointsTable({ commit }, payload) {
      commit("setLoadingPointsTable", payload);
    },
    setLoadingCards({ commit }, payload) {
      commit("setLoadingCards", payload);
    },
    setSnackbar({ commit }, payload) {
      commit("setSnackbar", payload);
    },
    setSnackbarLight({ commit }, payload) {
      commit("setSnackbarLight", payload);
    },
  },
  getters: {
    systemTheme() {
      return {
        cardWidth: "336px",
        cardImageHeight: "90px",
        cardTextHeight: "85px",
        cardOpacity: "0.7", // Can take a value from 0.0 - 1.0. The lower value, the more transparent
        toolbarOpacity: "0.7", // Can take a value from 0.0 - 1.0. The lower value, the more transparent
      };
    },
    previewAccountKey(state) {
      return state.previewAccountKey;
    },
    loading(state) {
      return state.loading;
    },
    processingPoints(state) {
      return state.processingPoints;
    },
    navTitle(state) {
      return state.navTitle;
    },
    navmenu(state) {
      return state.navmenu;
    },
    drawer(state) {
      return state.drawer;
    },
    preview(state) {
      return state.preview;
    },
    howTo(state) {
      return state.howTo;
    },
    breadCrumbDetail(state) {
      return state.breadCrumbDetail;
    },
    toolbar(state) {
      return state.toolbar;
    },
    footer(state) {
      return state.footer;
    },
    tagCollections(state) {
      return state.tagCollections;
    },
    loadingTable(state) {
      return state.loadingTable;
    },
    loadingPointsTable(state) {
      return state.loadingPointsTable;
    },
    loadingCards(state) {
      return state.loadingCards;
    },
    snackbar(state) {
      return state.snackbar;
    },
    snackbarLight(state) {
      return state.snackbarLight;
    },
    error(state) {
      return state.error;
    },
    firstTimeLoad(state) {
      return state.firstTimeLoad;
    },
  },
});

<template>
  <div>
    <v-col cols="4">
      <v-select
        :items="availableStatus"
        v-model="status"
        item-text="label"
        item-value="value"
        label="Status"
      />
      <v-text-field
        label="Display Order"
        type="number"
        name="order"
        v-model="order"
      />
      <v-text-field
        label="Created"
        type="text"
        readonly
        :value="created | date"
      />
      <v-text-field
        :value="updated | date"
        label="Updated at"
        type="text"
        readonly
      />
      
      <v-btn
      class="ml-0"
        color="primary"
        @click="openDeleteDialog = true"
        :loading="isProcessing"
        elevation="0"
      >Delete Leaderboard</v-btn>
    </v-col>

    <v-dialog v-model="openDeleteDialog" max-width="400px" persistent>
      <v-card>
        <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="red"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row dense>
            <v-col align="center">
              You are about to delete the <span class="incentable-form-bold">{{ title }}</span> Leaderboard
            </v-col>
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="openDeleteDialog=false" text>Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            elevation="0"
            @click="handleDelete()"
            :loading="isProcessing"
            :disabled="demo"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isProcessing: false,
      openDeleteDialog: false,
      availableStatus: [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
        { label: 'Archive', value: 'Archive' }
      ],
    };
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },

    programId() {
      return this.$store.getters.programId;
    },
    companyTags () {
      return this.$store.state.companytag.companyTags;
    },
    memberTags () {
      return this.$store.state.membertag.memberTags;
    },
    currentLeaderboard() {
      return this.$store.state.leaderboard.currentLeaderboard;
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    title: {
      get() {
        return this.currentLeaderboard.title;
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { title: value });
      }
    },
    createdBy() {
      return this.currentLeaderboard.createdBy;
    },
    displayTitle: {
      get() {
        return this.currentLeaderboard.displayTitle;
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { displayTitle: value });
      }
    },
    created() {
      return this.currentLeaderboard.created;
    },
    status: {
      get() {
        return this.currentLeaderboard.status;
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { status: value });
      }
    },
    order: {
      get() {
        return this.currentLeaderboard.order;
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { order: Number(value) });
      }
    },
    updatedBy() {
      return this.currentLeaderboard.updatedBy;
    },
    selectedMemberTags: {
      get() {
        return this.currentLeaderboard.memberTags;
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { memberTags: value });
      }
    },
    updated() {
      return this.currentLeaderboard.updated;
    },
    selectedCompanyTags: {
      get() {
        return this.currentLeaderboard.companyTags;
      },
      set(value) {
        this.$store.dispatch('patchCurrentLeaderboard', { companyTags: value });
      }
    },
  },
  methods: {
    handleDelete() {
      this.isProcessing = true;
      this.$store.dispatch('deleteLeaderboard')
        .then(() => {
          this.isProcessing = false;
          this.$router.push(`/leaderboards/${this.programId}`);
        })
        .catch(() => {
        });
    },
  },
}
</script>

<style scoped>

</style>

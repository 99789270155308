/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  rewardSuppliers: [],
};

const actions = {
  async loadRewardSuppliers({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewardSuppliers")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const rewardSuppliers = [];
    querySnapshot.forEach(function (doc) {
      // Add database fields here to customise for specific table
      rewardSuppliers.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
      });
    });

    commit("setRewardSuppliers", rewardSuppliers);
    commit("setLoadingTable", false);
  },

  async createRewardSupplier({ commit, getters }, payload) {
    const rewardSuppliersCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("rewardSuppliers");

    let existingRewardSupplierResult;
    try {
      existingRewardSupplierResult = await rewardSuppliersCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing rewardSupplier";
    }

    if (existingRewardSupplierResult.size > 0) {
      return "duplication";
    }

    const rewardSupplier = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newRewardSupplierRef;
    try {
      newRewardSupplierRef = await rewardSuppliersCollection.add(
        rewardSupplier
      );
    } catch (e) {
      throw "Error when creating a new Reward Supplier";
    }

    // Note: server time is unavailable until we refetch.
    const tempRewardSupplier = {
      ...rewardSupplier,
      id: newRewardSupplierRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createRewardSupplier", tempRewardSupplier);
    commit("setSnackbar", "rewardSupplier added");

    return "ok";
  },

  async updateRewardSupplier({ commit, getters }, payload) {
    const rewardSuppliersCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("rewardSuppliers");

    let existingRewardSupplierResult;
    try {
      existingRewardSupplierResult = await rewardSuppliersCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing rewardSupplier";
    }

    let duplicated = false;
    existingRewardSupplierResult.forEach((doc) => {
      if (doc.id !== payload.id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    const rewardSupplier = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await rewardSuppliersCollection.doc(payload.id).update(rewardSupplier);
    } catch (e) {
      throw "Error when updating a Reward Supplier";
    }

    const rewardSupplierBeforeUpdate = getters.rewardSuppliers.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempRewardSupplier = {
      ...rewardSupplierBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updateRewardSupplier", tempRewardSupplier);
    commit("setSnackbar", "rewardSupplier updated");

    return "ok";
  },

  async deleteRewardSupplier({ commit, getters }, id) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewardSuppliers")
        .doc(id)
        .delete();
    } catch (e) {
      throw "Error when deleting an Reward Supplier";
    }
    commit("deleteRewardSupplier", id);
    commit("setSnackbar", "rewardSupplier deleted");
  },
};

const mutations = {
  setRewardSuppliers(state, payload) {
    state.rewardSuppliers = payload;
  },

  createRewardSupplier(state, payload) {
    state.rewardSuppliers = [...state.rewardSuppliers, payload];
  },

  updateRewardSupplier(state, payload) {
    state.rewardSuppliers = state.rewardSuppliers.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteRewardSupplier(state, payload) {
    state.rewardSuppliers = state.rewardSuppliers.filter(
      (item) => item.id !== payload
    );
  },
};

const getters = {
  rewardSuppliers(state) {
    return state.rewardSuppliers;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <v-app-bar
      class="page-bar"
      dense
      fixed
      color="grey lighten-4"
      elevate-on-scroll
    >
      <v-row no-gutters justify="center" class="mx-0">
        <v-col cols="12" lg="8">
          <span class="page-heading">Log</span>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-row no-gutters justify="center" class="mx-3 mt-14">
      <v-col cols="12" lg="8">
        <log-list class="mt-6"/>
      </v-col>
    </v-row>
  </div>
</template>

    



<script>

 export default {
  created () {
    this.$store.dispatch('initialize')
    this.$store.dispatch('loadLogs')
    this.$store.dispatch('setNavMenu', false)
    this.$store.dispatch('setPreview', false)
  },
  data: function () {
    return {
      pageTitle: 'Logs',
      pageSubTitle: 'Incentable admin system log'
    }
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.container {
  max-width: 1900px;
}
</style>

/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  articles: [],
  loadingArticles: false,
};

const actions = {
  async loadArticles({ commit, getters }) {
    commit("setLoadingTable", true);
    commit("setLoadingArticles", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("articles")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const articles = [];
    querySnapshot.forEach(function (doc) {
      articles.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
      });
    });

    commit("setArticles", articles);
    commit("setLoadingTable", false);
    commit("setLoadingArticles", false);
  },

  async createArticle({ commit, getters }, payload) {
    const articlesCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("articles");

    let existingArticleResult;
    try {
      existingArticleResult = await articlesCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "error when fetching existing article";
    }

    if (existingArticleResult.size > 0) {
      return "duplication";
    }

    const article = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newArticleRef;
    try {
      newArticleRef = await articlesCollection.add(article);
    } catch (e) {
      throw "error when creating a new article";
    }

    // Note: server time is unavailable until we refetch.
    const tempArticle = {
      ...article,
      id: newArticleRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createArticle", tempArticle);
    commit("setSnackbar", "Article added");

    return "ok";
  },

  async updateArticle({ commit, getters }, payload) {
    const articlesCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("articles");

    let existingArticleResult;
    try {
      existingArticleResult = await articlesCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "error when fetching existing article";
    }

    let duplicated = false;
    existingArticleResult.forEach((doc) => {
      if (doc.id !== payload.id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    const article = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await articlesCollection.doc(payload.id).update(article);
    } catch (e) {
      throw "error when updating an article";
    }

    const articleBeforeUpdate = getters.articles.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempArticle = {
      ...articleBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updateArticle", tempArticle);
    commit("setSnackbar", "Article updated");

    return "ok";
  },

  async deleteArticle({ commit, getters }, id) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("articles")
        .doc(id)
        .delete();
    } catch (e) {
      throw "error when deleting an article";
    }
    commit("deleteArticle", id);
    commit("setSnackbar", "Article deleted");
  },
};

const mutations = {
  setArticles(state, payload) {
    state.articles = payload;
  },

  createArticle(state, payload) {
    state.articles = [...state.articles, payload];
  },

  setLoadingArticles(state, payload) {
    state.loadingArticles = payload;
  },

  updateArticle(state, payload) {
    state.articles = state.articles.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteArticle(state, payload) {
    state.articles = state.articles.filter((item) => item.id !== payload);
  },
};

const getters = {
  articles(state) {
    return state.articles;
  },
  loadingArticles(state) {
    return state.loadingArticles;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>          
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa + ';'"
  >
    <v-row no-gutters justify="center">
      <v-col xs="12" :sm="component.settings.cols">
        <v-sheet
          tile
          :color="this.webTheme.colors.content.hexa"
          :style="block"
        >
          <v-row no-gutters justify="center">
            <v-col>
              <view-text :settings="component.title"></view-text>
            </v-col>
          </v-row>
          <v-card
            tile
            elevation="0"
            color="transparent"
            >
            <v-row
              v-if="component.card.group.slider === 'slide'"
              no-gutters
              :justify="component.card.group.justify"
            >
              <v-col :lg="component.card.group.cols">
                <v-card
                  tile
                  elevation="0"
                  :class="grid.inner"
                  background-color="transparent"
                >
                  <v-slide-group
                    :dark="component.card.group.dark"
                    show-arrows="always"
                  >
                    <v-slide-item
                      v-for="(card, i) in awardsData"
                      :key="i"
                    >
                      <v-card
                        :class="classes"
                        :style="styles + ';' + gradient"
                        :height="component.card.height"
                        :width="component.card.width"
                        :elevation="component.card.elevation"
                      >
                      <template v-if="!component.card.icon.hide">
                        <div>
                          <img
                            :style="svgStyle"
                            :src="card.image"
                            :height="component.card.icon.size"
                          />
                        </div>
                      </template>
                        <v-row no-gutters>
                          <v-col>
                            <view-text :settings="component.displayTitle" :value="card.award" />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </v-card>
              </v-col>
            </v-row>
            <v-row 
              v-else
              no-gutters
              :justify="component.card.group.justify"
            >
              <v-col :lg="component.card.group.cols">
                <v-card
                  tile
                  elevation="0"
                  :class="grid.inner"
                >
                  <v-row
                    class="mx-2 mx-lg-0"
                    :justify="component.card.group.justify"
                    
                  >
                  <v-card
                    v-for="(card, i) in awardsData"
                    :key="i"
                    :class="classes"
                    :style="styles + ';' + gradient"
                    :height="component.card.height"
                    :width="component.card.width"
                    :elevation="component.card.elevation"
                  >
                      <template v-if="!component.card.icon.hide">
                        <div>
                          <img
                            :style="svgStyle"
                            :src="card.image"
                            :height="component.card.icon.size"
                          />
                        </div>
                      </template>
                        <v-row no-gutters>
                          <v-col>
                            <view-text :settings="component.displayTitle" :value="card.award" />
                          </v-col>
                        </v-row>

                      </v-card>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import ViewText from '../Text.vue'
import { DEMO_USER } from '@/constants/webtheme/demouser'

 export default {
  components: {
    ViewText
  },
  props: [ 'component', 'index'],
  created () {
    this.$store.dispatch('getSampleProgressData', DEMO_USER)
  },
  data: function () {
  return {
    showLeaderboardData: false,
    activeLeaderboardData: {},
    showTableData: false,
    activeTableData: {},
  }
  },
  computed: {
    svgStyle()  {
      var opacity = 10/this.component.card.icon.opacity
      var marginLeft = this.component.card.icon.marginLeft
      var marginTop = this.component.card.icon.marginTop
      return 'opacity: ' + opacity + ' !important;' 
        + 'margin-left: ' + marginLeft + 'px;'
        + 'margin-top: ' + marginTop + 'px;'
        + 'position: absolute;'
    },
    classes () {
      var classes = this.component.card.rounded
      return classes
    },
    grid() {
      return this.$store.getters.grid
    },
    block() {
      var padding = this.component.block.padding
      return this.grid.block + 'px ;' +
      'padding-left: ' + padding.left + 'px ;' +
      'padding-right: ' + padding.right + 'px ;' +
      'padding-top: ' + padding.top + 'px ;' +
      'padding-bottom: ' + padding.bottom + 'px ;'
    },
    gradient() {
      if (this.component.card.color.type === 'gradient') {
        var color = this.component.card.color
        var gradient = 
        'background: linear-gradient(' +
        color.direction + ', rgba(' +
        color.gradientFrom.rgba.r + ', ' +
        color.gradientFrom.rgba.g + ', ' +
        color.gradientFrom.rgba.b + ', ' +
        color.gradientFrom.rgba.a + ') ' + 
        ', rgba(' +
        color.gradientTo.rgba.r + ', ' +
        color.gradientTo.rgba.g + ', ' +
        color.gradientTo.rgba.b + ', ' +
        color.gradientTo.rgba.a + ')' + color.gradientHeight + '%)'
        return gradient
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return 'background: ' + this.component.card.color.solidColor.hexa + ';'
      }
    },
    styles () {
      var styles = 'margin-bottom: ' + this.component.card.margin.bottom + 'px ;' +
        'margin-top: ' + this.component.card.margin.top + 'px ;' +
        'margin-left: ' + this.component.card.margin.left + 'px ;' +
        'margin-right: ' + this.component.card.margin.right + 'px ;' +
        'padding-bottom: ' + this.component.card.padding.bottom + 'px ;' +
        'padding-top: ' + this.component.card.padding.top + 'px ;' +
        'padding-left: ' + this.component.card.padding.left + 'px ;' +
        'padding-right: ' + this.component.card.padding.right + 'px ;' +
        'border: ' + this.component.card.borderSize + 'px solid ' +
        this.component.card.borderColor.hexa + '; '
      return styles
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    sampleLeaderBoardData() {
      return this.$store.getters.sampleLeaderBoardData
    },
    sampleTableData() {
      return this.$store.getters.sampleTableData
    },
    awardsData() {
      const data = [
        { award: 'Chairmans Club',
          image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/media%2FdxkKiRMcpF2rd2ikRbv7%2F0IVDTAjgSh7QI-iddJ6G7_silver-medal_600x600.png?alt=media&token=48950b7f-9415-4362-84e7-fc7b0f9a74d6'
        },
        { award: 'January Top Gun',
          image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/media%2FdxkKiRMcpF2rd2ikRbv7%2FQ1K9vEqL_ylt5FlahXdO4_medal%20(2)_600x600.png?alt=media&token=12ec9b1f-d727-4240-86d6-8b89c26f323d'
        },
        { award: 'Service Star',
          image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/media%2FdxkKiRMcpF2rd2ikRbv7%2FCKR1bf6V2kLWFRgjNJWDC_trophy_600x600.png?alt=media&token=ff5a9eeb-5f97-4015-8f1c-b03a309007ab'
        },
        { award: 'NPS Top Achiever',
          image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/media%2FdxkKiRMcpF2rd2ikRbv7%2FIjYvgWvSXSwpdEdEDd8Iu_medal%20(3)_600x600.png?alt=media&token=ebc1bb40-2579-4803-a3d8-8b80b78817b7'
        },
      ]
      // return this.sampleLeaderBoardData.concat(this.sampleTableData)
      return data
    }
  },
  methods: {
    openLeaderboardPopUp (value) {
      this.activeLeaderboardData = value
      this.showLeaderboardData = true
    },
    closeLeaderboardPopUp () {
      this.showLeaderboardData = false
    },
    openTablePopUp (value) {
      this.activeTableData = value
      this.showTableData = true
    },
    closeTablePopUp () {
      this.showTableData = false
    },
    ordinal (n) {
      var s = ['th', 'st', 'nd', 'rd']
      var v = n % 100
      return n + (s[(v - 20) % 10] || s[v] || s[0])
    },
  }
}
</script>

<style scoped>
.svg-opacity {
  opacity: 0.5 !important;
}
</style>


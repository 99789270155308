<template>
    <v-container fluid class="page">

    <!-- Heading -->

    <v-row>
      <v-col lg="9">
        <v-row class="page-heading">   
          {{ currentOrganisation.title }}
        </v-row>
      <v-row class="incentable-page-subheading">{{ pageSubTitle }}</v-row>
      </v-col>
      <v-col lg="3">
        <v-row align="end" style="height: 50px;">
          <v-text-field
            v-if="searchActive"
            v-model="search"
            solo-inverted
            dense
            flat
            hide-details
            label="Search"
            prepend-inner-icon="search"
          ></v-text-field> 
        </v-row>
      </v-col>
    </v-row>

    <!-- Tabs--> 

    <v-row class="mx-n3">
      <v-tabs>
        <v-tab class="tab">
          Team Members
        </v-tab>
        <v-tab class="tab">
          Organisation
        </v-tab>
        <v-tab class="tab">
          Settings
        </v-tab>

        <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
          <org-admins></org-admins>
        </v-tab-item>
        <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
          <org-settings></org-settings>
        </v-tab-item>
        <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
          <org-config></org-config>
          <org-hidden-config></org-hidden-config>
        </v-tab-item>
      </v-tabs>

    </v-row>

    <v-row class="incentable-page-subheading"><a @click="$router.go(-1)" class="link pr-4 py-6"><v-icon small class="mt-n1" color="primary">arrow_back</v-icon>Back To Organisations</a></v-row>

  </v-container>

</template>

<script>

 export default {
   data: function () {
     return {
       active: '',
       searchActive: false,
       pageTitle: 'Organisation',
       pageSubTitle: 'Manage your organisation profile and administrators'
     }
   },
   computed: {
     currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
  },
    created () {
    this.$store.dispatch('initialize')
  },
}
</script>

<style scoped>
.container {
  max-width: 1216px;
}
</style>

<template>
  <div class="px-3">
    <v-row align="center" class="mb-4" no-gutters>
      <v-col class="incentable-form-heading">
      </v-col>
      <v-col class="incentable-form-heading pr-2" sm="auto">
        Preview Data Of
      </v-col>
      <v-col sm="4">
        <v-select
          v-model="accountKey"
          hide-details
          placeholder="Select ID"
          no-data-text="No data has been uploaded"
          :items="accMap"
          item-text="label"
          item-value="value"
          solo-inverted
          dense
          flat
          :prepend-inner-icon="getIcon"
        />
      </v-col>
    </v-row>

    <leaderboard-column-mapper
      :sourceColumns="sourceColumns"
      mapping="header"
      @onAdd="handleAddColumn"
    />
    <v-row no-gutters class="mt-4 mb-4" justify="center">
      <table>
        <thead>
          <draggable
            v-model="headerMappings"
            tag="tr"
            :disabled="demo"
          >
            <th
              v-for="(header) of headerMappings"
              :key="header.uid"
              scope="col"
              class="incentable-leaderboard-table-title-row movable"
            >
              <v-row no-gutters>
                <v-icon class="ml-n4 mr-1 mt-n1" color="grey">drag_indicator</v-icon>
                {{ header.label }}
              </v-row>
              <v-row no-gutters class="incentable-page-subheading grey--text ml-3">
                <v-icon color="grey" size="12" class="mr-1 mt-n1">subdirectory_arrow_right</v-icon>
                {{ header.column }}
              </v-row>
            </th>
          </draggable>
        </thead>
        <tbody>
          <tr>  
            <td
              v-for="(header) of headerMappings"
              :key="header.uid"
              align="center"
              class="table-cell"
            >
                <div v-if="header.column === 'Icon'">
                  <v-row v-if="selectedPreviewData.isWinner && iconMappings.svgFile" justify="center">
                    <img :src="'/img/icons/black/' + iconMappings.svgFile" class="mapping-icon-qualifier"/>
                  </v-row>
                </div>
                <div v-else-if="header.column === 'Avatar'">
                  <v-avatar
                    v-if="selectedPreviewData.values[header.uid]"
                    size="38"
                  >
                    <v-img :src="selectedPreviewData.values[header.uid]"></v-img>
                  </v-avatar>
                </div>
                <div v-else-if="header.type === 'qualifier'">
                  <v-img :src="require(`@/assets/${selectedPreviewData.values[header.uid]}`)"  class="mapping-icon-qualifier" />
                </div>
                <span v-else>
                  {{ selectedPreviewData.values[header.uid] }}
                </span>
            </td>
          </tr>
        </tbody>
      </table>
    </v-row>
  
  </div>
</template>

<script>
import LeaderboardColumnMapper from './ColumnMapper.vue'

export default {
  components: {
    LeaderboardColumnMapper
  },
  data: function () {
    const accountsMap = this.$store.getters.leaderboardAccountsMap;
    return {
      selectedAccountKey: accountsMap.length ? accountsMap[0].value : '',
    }
  },
  computed: {
    accountKey: {
      get() {
        return this.selectedAccountKey
      },
      set(value) {
        this.selectedAccountKey = value
      }
    },
    accMap () {
      return this.$store.getters.leaderboardAccountsMap
    },
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    currentLeaderboard() {
      return this.$store.state.leaderboard.currentLeaderboard
    },

    systemHeaders() {
      return this.$store.getters.leaderboardSystemHeaders
    },

    csvHeaders() {
      return this.$store.getters.leaderboardCsvHeaders
    },

    headerData() {
      return this.$store.getters.leaderboardHeaderData
    },

    accountsMap() {
      return this.$store.getters.leaderboardAccountsMap
    },

    sourceColumns() {
      var label = ''
      if (this.currentLeaderboard.mode === 'dynamic') {
        label = 'Data Bucket Fields'
      } else {
        label = 'CSV Columns'
      }
      return [
        { header: 'System Fields'},
        ...this.systemHeaders,
        { header: label},
        ...this.csvHeaders,
      ];
    },

    headerMappings: {
      get() {
        return this.currentLeaderboard.headerMappings;
      },
      set(values) {
        this.$store.dispatch('patchCurrentLeaderboard', {
          headerMappings: values,
        });
      },
    },

    iconMappings() {
      return this.currentLeaderboard.iconMappings;
    },

    selectedPreviewData() {
      return this.headerData.find(item => {
        return item.accountKey === this.selectedAccountKey
      }) || []
    },
    
    getIcon () {
      if (this.currentLeaderboard.participantType === 'member') {
        return 'account_circle'
      } else {
        return 'business'
      }
    },

  },
  methods: {
    handleAddColumn(newMapping) {
      const newHeaderMappings = [
        ...this.headerMappings,
        newMapping,
      ]
      this.$store.dispatch('patchCurrentLeaderboard', {
        headerMappings: newHeaderMappings,
      });
    },

    handleRemoveColumn(index) {
      const { headerMappings } = this;
      const newHeaderMappings = [
        ...headerMappings.slice(0, index),
        ...headerMappings.slice(index + 1)
      ];

      this.$store.dispatch('patchCurrentLeaderboard', {
        headerMappings: newHeaderMappings
      });
    },
  },
}
</script>

<style scoped>
.ghost {
  border: 2px dotted rgb(100, 100, 100);
}

.mapping-item {
  cursor: move;
}

.mapping-icon {
  width: 30px;
  height: 30px;
  padding-top: 1px;
}

.mapping-icon-card {
  width: 32px;
  height: 32px;
}

.mapping-icon-qualifier {
  width: 30px;
}

</style>

<template>
  <v-dialog v-model="open" max-width="600px" persistent>
    <v-card>
      <form data-vv-scope="message">
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ messageId ? 'View ' : 'Add New '}}
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-16">
        
        <v-row dense>
          <v-col>
            <v-row v-if="exists" no-gutters>
              <v-col cols="3">
                Subject:
              </v-col>
              <v-col>
                {{ subject }}
              </v-col>
            </v-row>  
            <v-row v-if="exists" no-gutters>
              <v-col cols="3">
                Plan:
              </v-col>
              <v-col>
                {{ plan }}
              </v-col>
            </v-row>  
            <v-row v-if="exists" no-gutters>
              <v-col cols="3">
                Location:
              </v-col>
              <v-col>
                {{ location }}
              </v-col>
            </v-row>  
            <v-row v-if="exists" no-gutters class="mb-6">
              <v-col cols="3">
                Signed in as:
              </v-col>
              <v-col v-if="signedIn.email">
                {{ signedIn.name }} / {{ signedIn.email }}
              </v-col>
              <v-col v-else>
                Not signed in
              </v-col>
            </v-row>  
            <v-text-field
              name="name"
              v-model="name"
              label="Name"
              v-validate="'required'"
              :error-messages="errors.collect('message.name')"
              :readonly="exists"
            ></v-text-field>   
            <v-text-field
              name="from"
              v-model="from"
              label="From"
              v-validate="'required'"
              :error-messages="errors.collect('message.from')"
              :readonly="exists"
            ></v-text-field> 
            <v-text-field
              name="company"
              v-model="company"
              label="Company"
            ></v-text-field> 
            <v-text-field
              name="phone"
              v-model="phone"
              label="Phone"
            ></v-text-field> 
            <v-textarea
              name="message"
              v-model="message"
              label="Message"
              v-validate="'required'"
              :error-messages="errors.collect('message.message')"
              :readonly="exists"
            ></v-textarea>   
            <v-checkbox
              name="read"
              v-model="read"
              label="Read"
            ></v-checkbox>   
          </v-col>
        </v-row>
      
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="handleClose()"
          >Close</v-btn>
        <v-btn
          :class="orgTheme.buttonTextColor"
          @click="saveMessage('message')"
          color="primary"
          :loading="loading"
          elevation="0"
          >Save</v-btn>
      </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    messageId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    }
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data: () => ({
    dictionary: {
      custom: {
        name: {
          required: () => 'Name is required'
        },
        from: {
          required: () => 'From email is required'
        },
        message: {
          required: () => 'Message is required'
        },
      }
    },
    formTitle: 'Message',
    formIcon: 'email',
    showAlert: false,
    company: '',
    phone: '',
    name: '',
    from: '',
    message: '',
    read: false,
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
    signedIn: {name: '', email:''},
  }),
  computed: {
    exists () {
        if (this.messageId) return true
        return false
    },
    url () {
      return location
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    messages () {
      return this.$store.state.message.messages
    },
  },
  methods:{
    clear () {
      this.from = ''
      this.name = ''
      this.company = ''
      this.phone = ''
      this.message = ''
      this.read = false
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.showAlert = false;
      this.$validator.reset();
    },
    handleClose () {
      this.clear()
      this.$store.dispatch('loadNewMessageCount')
      this.$emit('onClose');
    },
    saveMessage (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        this.$store.dispatch('setLoading', true)
        const payload = {
          from: this.from.toLowerCase(),
          name: this.name,
          read: this.read,
          plan: this.plan || 'None selected',
          message: this.message,
          subject: this.subject || 'Admin',
          phone: this.phone || '',
          company: this.company || '',
          signedIn: {
            name: this.signedIn.name || '',
            email: this.signedIn.email || ''
          },
          location: this.location || 'Admin'
        };
        if (this.messageId) {
          // Update Message
          payload.id = this.messageId;
          this.$store.dispatch('updateMessage', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create Message
          this.$store.dispatch('createMessage', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        }
      });
    },
  },
  watch: {
    messageId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedMessage = this.messages.find(item => item.id === newVal);
          const {
            from, name, read, message, plan, subject, created, updated, createdBy, updatedBy, signedIn, location, phone, company
          } = selectedMessage;
          this.from = from;
          this.name = name;
          this.message = message;
          this.subject = subject;
          this.read = read;
          this.plan = plan;
          this.phone = phone;
          this.company = company;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
          this.signedIn = signedIn;
          this.location = location;
        }
      },
    },
  },
}
</script>
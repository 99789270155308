/* eslint-disable no-console */
<template>
  <div class="mt-8 mr-8 mb-8">
    <v-row
      no-gutters
      align="center"
      >
      <v-col sm="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field> 
      </v-col>
      
      <v-col
        sm="3"
        v-if="options.filter"
        >
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter"
          prepend-inner-icon="filter_list"
          class="mr-4"
        ></v-select> 
      </v-col>
      
      <v-col>
        <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
        <v-btn v-if="options.download" class="primary--text" text>Download</v-btn> 
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
      </v-col>
      
      <v-col sm="auto">
        <v-btn
          @click="handleNew()"
          color="primary"
          elevation="0"
          class="mt-n2"
          >
          <v-icon>add</v-icon>new
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="blogs"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          left
          close-on-content-click
        >
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title @click="handleEdit(item)">
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                :disabled="getArticleCountForBlog(item.id) > 0">
                <v-list-item-title 
                  @click="handleDeleteConfirmation(item)"
                  >Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
      </template>

      <template v-slot:item.blogCompanyTags="{ item }">
        <v-chip-group column> 
          <v-chip
            small
            v-for="id in item.companyTags" 
            :key="id.id"
            class="ma-1"
          >
          {{ getCompanyTagTitle(id) }}
          </v-chip>
        </v-chip-group> 
      </template>

      <template v-slot:item.blogMemberTags="{ item }">
        <v-chip-group column> 
          <v-chip
            small
            v-for="id in item.memberTags" 
            :key="id.id"
            class="ma-1"
          >
          {{ getMemberTagTitle(id) }}
          </v-chip>
        </v-chip-group> 
      </template>
      
      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status" class="pr-3"></status-icon>
        {{ item.status }}
      </template>
      
      <template v-slot:[`item.title`]="{ item }">
        <div @click="handleEdit(item)" class="primary--text" style="cursor:pointer">{{ item.title }}</div>
      </template>
                  
      <template v-slot:item.articles="{ item }">
        <div v-if="getArticleCountForBlog(item.id) > 0">
          <v-avatar
            size="25"
            color="grey lighten-2"
          >
          <span class="">{{ getArticleCountForBlog(item.id) }}</span>
          </v-avatar>
        </div>
      </template>
      
    </v-data-table>

  <!-- New or Edit dialog -->

    <blogs-new-or-edit
      :blogId="selectedBlogId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />
    
  <!-- Delete dialog -->

    <v-dialog 
      v-model="dialogDeleteOpen"
      max-width="400px"
      persistent
      >
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row dense>
            <v-col align="center">
              You are about to delete <span class="incentable-form-bold">{{ title }}</span>
            </v-col>
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleCloseDeleteDialog()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>
  export default {
    $_veeValidate: {
      validator: 'new'
    },
    data: () => ({
      
      // Component settings unique to this component
      tableTitle: 'Blogs',
      tableSubtitle: 'Create blogs to contain articles',
      tableIcon: 'book',
      selectedBlogId: '',
      search: '',
      title: '',

      
      // Table columns
      headers: [
        { text: 'Status', value: 'status', align: 'left' },
        { text: 'Blog', value: 'title', align: 'left' },
        { text: 'Company Tag(s)', value: 'blogCompanyTags', align: 'left' },
        { text: 'Member Tag(s)', value: 'blogMemberTags', align: 'left' },
        { text: 'Articles', value: 'articles', align: 'center' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],

      // Component settings common to all components
      dialogNewOrEditOpen: false,
      dialogDeleteOpen: false,
      
      // Table settings
      options: {
        sortBy: ['title'],
        sortDesc: [false],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: true,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
    }),
    
    created () {
    },

    computed: {
      // Functions specific to this table //
      
      blogs () {
        return this.$store.state.blog.blogs
      },
      
      articles () {
        return this.$store.state.article.articles
      },

      companyTagTitleMap() {
        return this.$store.state.companytag.companyTags.reduce((result, item) => {
          return {
            ...result,
            [item.id]: item.tag,
          };
        }, {});
      },

      memberTagTitleMap() {
        return this.$store.state.membertag.memberTags.reduce((result, item) => {
          return {
            ...result,
            [item.id]: item.tag,
          };
        }, {});
      },

      // Functions common to all tables //
      demo () {
        return this.$store.state.program.currentProgram.demo
      },
      loadingTable () {
      return this.$store.getters.loadingTable
      },
      loading () {
      return this.$store.getters.loading
      },
      orgTheme () {
        return this.$store.getters.orgTheme
      },
      systemTheme () {
        return this.$store.getters.systemTheme
      },
      snackbar () {
        return this.$store.getters.snackbar
      },
    },
    methods: {
      // Actions specific to this table //

      getArticleCountForBlog (blogId) {
        return this.articles.filter(item => item.blog === blogId).length
      },

      getMemberTagTitle(id) {
        return this.memberTagTitleMap[id]
      },

      getCompanyTagTitle(id) {
        return this.companyTagTitleMap[id]
      },

      // Actions common to all tables //

      getStatusIcon (item) {
        if (item === 'Active') {
          return 'check_circle'
        } else if (item === 'Inactive') {
          return 'not_interested'
        }
      },

      handleNew() {
        // Open New form
        this.selectedBlogId = '';
        this.dialogNewOrEditOpen = true;
      },
      handleEdit(item) {
        // Open Edit form
        this.selectedBlogId = item.id;
        this.dialogNewOrEditOpen = true;
      },
      handleCloseNewOrEditDialog() {
        // Close New Or Edit form
        this.selectedBlogId = '';
        this.dialogNewOrEditOpen = false;
      },
      handleDeleteConfirmation (item) {
        // Delete record
        this.selectedBlogId = item.id;
        this.title = item.title;
        this.dialogDeleteOpen = true;
      },
      handleCloseDeleteDialog() {
        // Close delete form
        this.selectedBlogId = '';
        this.dialogDeleteOpen = false;
      },
      handleDelete() {
        // Delete Blog
        this.$store.dispatch('setLoading', true)
        this.$store.dispatch('deleteBlog', this.selectedBlogId)
          .then(() => this.dialogDeleteOpen = false)
          .catch(() => this.dialogDeleteOpen = false)
          this.$store.dispatch('setLoading', false)
      },
    }
  }
</script>

<style>

</style>

<template>
  <v-dialog 
    v-model="open"
    :width="component.cart.width"
    :fullscreen="component.cart.fullscreen"
    persistent
    scrollable
  > 
    <v-card
      :class="classes"
      :style="styles + ';' + gradient"
    >
      <v-btn
        v-if="component.cart.fullscreen === true"
        top right absolute small icon
        @click="handleClose"
        style="z-index: 100"
      >
        <v-icon :color="component.cart.title.color">clear</v-icon>
      </v-btn>  
      <v-progress-linear v-if="processingPoints" indeterminate color="primary"></v-progress-linear>
      <v-card-text>
        <view-text 
          :settings="component.cart.cartTitle"
          :value="component.cart.cartTitle.value"
        ></view-text>

        <v-card color="transparent" elevation="0" :style="detailTableStyles">
          <v-data-table
            :headers="headers"
            :items="filteredTransactions"
            :search="search"
            :style="cssVars"
            class="table"
            hide-default-footer
            :loading="loadingPointsTable"
            :header-props="{ sortIcon: null }"
            no-data-text="No Items In Shopping Cart"
          >
            <template v-slot:[`item.image`]="{ item }">
               <v-avatar
                :size="component.cart.imageSize"
                :tile="component.cart.imageTile"
               >
                  <v-img
                    :src="item.image"
                  />
                </v-avatar>         
            </template>

            <template v-slot:[`item.quantity`]="{ item }">
              <v-row no-gutters align="center" justify="center">
                <v-col cols="4">
                  <v-icon color="red" :size="component.cart.iconSize">remove_circle_outline</v-icon>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    class="mt-3 mb-n3 centered-input"
                    v-model="item.quantity"
                    id="quantity"
                    name="Quantity"
                    outlined
                    dense
                    background-color="white"
                    color="grey"
                    filled
                  />
                </v-col>
                <v-col cols="4">
                  <v-icon color="green" :size="component.cart.iconSize">add_circle_outline</v-icon>
                </v-col>
              </v-row>
            </template>
            
            <template v-slot:[`item.created`]="{ item }">
              {{ item.created  }}
            </template>

            <template v-slot:[`item.each`]="{ item }">
              {{ item.each | number }}
            </template>

            <template v-slot:[`item.subtotal`]="{ item }">
              {{ item.subtotal | number }}
            </template>

            <template v-slot:[`item.points`]="{ item }">
              {{ item.points | number }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                :size="component.cart.iconSize"
                color="red"
                medium
                @click="handleDelete(item)"
              >highlight_off</v-icon>
            </template>

          </v-data-table> 
          <v-row justify="center" v-if="pageCount > 1" no-gutters class="mt-2" align="center">
            <v-btn
              v-if="page > 1"
              rounded x-small elevation="0" color="transparent" @click="page --">
              <v-icon
                :color="this.component.cart.content.heading.color"
                size="20"
              >chevron_left</v-icon>
            </v-btn>
              <v-btn
                v-for="page in pageCount"
                :key="page"
                x-small
                elevation="0"
                color="transparent"
                rounded
                @click="handlePage(page)"
              >
                <span :style="cssVars" :class="paginationStyle(page)">
                  {{ page }}
                </span>
              </v-btn>
            <v-btn
              v-if="page < pageCount"
              rounded x-small elevation="0" color="transparent" @click="page ++">
              <v-icon
                :color="this.component.cart.content.heading.color"
                size="20"
              >chevron_right</v-icon>
            </v-btn>
          </v-row>
        </v-card>
        
        <v-row no-gutters justify="end">
          <v-col cols="auto" class="pr-2" v-if="loadingPointsTable === false && processingPoints === false">
            <view-text 
              :settings="component.cart.points"
              value="Order Value:"
            ></view-text>
            <view-text 
              :settings="component.cart.subheading"
              value="Points Balance:"
            ></view-text>
            <view-text 
              :settings="component.cart.subheading"
              value="Points Remaining:"
            ></view-text>
          </v-col>
          <v-col cols="auto" v-if="loadingPointsTable === false && processingPoints === false">
            <view-text 
              :settings="component.cart.points"
              value="1,100"
            ></view-text>
            <view-text 
              :settings="component.cart.subheading"
              :value="currentUser.balance | number"
            ></view-text>
            <view-text 
              :settings="component.cart.subheading"
              :value="currentUser.balance - currentUser.cartValue | number"
            ></view-text>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="component.cart.button.color.hex"
          :dark="!component.cart.button.dark"
          elevation="0"
          @click="handleClose()"
          text
        >close</v-btn>
        <v-btn
          elevation="0"
          :color="component.cart.button.color.hex"
          @click="handleClose()"
          :dark="!component.cart.button.dark"
        >checkout</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { TRANSACTION_TYPE } from '@/constants/transaction'
import { DEMO_USER } from '@/constants/webtheme/demouser'
import ViewText from '../Text.vue'

export default {
  created () {
  },
  props: [
    'open',
    'member',
    'memberId',
    'component'
  ],
    components: {
      ViewText
  },
  data: ()=> ({
    currentUser: DEMO_USER,
    // Table columns
    headers: [
      { text: 'Image', value: 'image', align: 'left' },
      { text: 'Description', value: 'description', align: 'left' },
      { text: 'Each', value: 'each', align: 'center' },
      { text: 'Quantity', value: 'quantity', align: 'center', width: '20%' },
      { text: 'Sub Total', value: 'subtotal', align: 'center' },
      { text: 'Remove', value: 'actions', align: 'center' },
    ],
    closeCart: false,
    statusList: TRANSACTION_TYPE,
    filteredStatus: [],
    search: '',
    selectedTransactionId: '',
    cartNewOrEditOpen: false,
    cartDeleteOpen: false,
    notes: '',
    description: '',
    points:'',
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    // Table settings
    options: {
      itemsPerPage: 10,
      sortable: false,
      multiSort: false,
      search: false,
      filter: false,
      upload: false,
      download: false,
      more: false,
      new: false
    },
    
  }),
  computed : {
    transactions () {
      return this.currentUser.cart
    },
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    loadingPointsTable () {
      return this.$store.getters.loadingPointsTable
    },
    processingPoints () {
      return this.$store.getters.processingPoints
    },
    filteredTransactions() {
      return this.filteredStatus.length
        ? this.transactions.filter(m => this.filteredStatus.includes(m.status))
        : this.transactions;
    },
    arrBalance() {
      const arr = this.filteredTransactions
      return arr.reduce((n, { points }) => n + points, 0)
    },
    variance() {
      const transBalance = this.transactions.reduce((n, { points }) => n + points, 0)
      return this.currentMemberPoints.balance - transBalance
    },
    currentMemberPoints() {
      return this.currentUser
    },
    cssVars() {
      return {
        '--header-text-color': this.component.cart.content.heading.color,
        '--header-text-size': this.component.cart.content.heading.size + 'px',
        '--header-text-style': this.component.cart.content.heading.style,
        '--header-text-weight': this.component.cart.content.heading.weight,
        '--header-line-height': this.component.cart.content.heading.lineHeight,
        '--header-font': this.component.cart.content.heading.font,
        '--row-text-color': this.component.cart.content.data.color,
        '--row-text-size': this.component.cart.content.data.size + 'px',
        '--row-text-style': this.component.cart.content.data.style,
        '--row-text-weight': this.component.cart.content.data.weight,
        '--row-line-height': this.component.cart.content.data.lineHeight,
        '--row-font': this.component.cart.content.data.font,
        '--text-align': this.component.cart.content.align,
        '--row-border-bottom': this.rowBorders,
        '--row-hover-color': this.component.cart.rowHoverColor.hexa,
        '--current-page-color': this.component.cart.title.color
      }
    },
    cssVarsTransactions() {
      return {
        '--header-text-color': this.component.cart.content.heading.color,
        '--header-text-size': this.component.cart.content.heading.size + 'px',
        '--header-text-style': this.component.cart.content.heading.style,
        '--header-text-weight': this.component.cart.content.heading.weight,
        '--header-line-height': this.component.cart.content.heading.lineHeight,
        '--header-font': this.component.cart.content.heading.font,
        '--row-text-color': this.component.cart.content.data.color,
        '--row-text-size': this.component.cart.content.data.size + 'px',
        '--row-text-style': this.component.cart.content.data.style,
        '--row-text-weight': this.component.cart.content.data.weight,
        '--row-line-height': this.component.cart.content.data.lineHeight,
        '--row-font': this.component.cart.content.data.font,
        '--text-align': 'left',
        '--row-border-bottom': this.rowBorders,
        '--row-hover-color': this.component.cart.rowHoverColor.hexa,
        '--current-page-color': this.component.cart.title.color
      }
    },
    classes () {
      if (this.component.cart.fullscreen === true)
      return 'rounded-0'
      return this.component.cart.rounded
    },
    rowBorders() {
      return this.component.cart.rowBorderSize + 'px' + 
      ' solid ' + 
      this.component.cart.rowBorderColor.hexa
    },
    iconSize() {
      return this.component.cart.iconSize
    },
    gradient() {
      if (this.component.cart.color.type === 'gradient') {
        var color = this.component.cart.color
        var gradient = 
        'background: linear-gradient(' +
        color.direction + ', rgba(' +
        color.gradientFrom.rgba.r + ', ' +
        color.gradientFrom.rgba.g + ', ' +
        color.gradientFrom.rgba.b + ', ' +
        color.gradientFrom.rgba.a + ') ' + 
        ', rgba(' +
        color.gradientTo.rgba.r + ', ' +
        color.gradientTo.rgba.g + ', ' +
        color.gradientTo.rgba.b + ', ' +
        color.gradientTo.rgba.a + ')' + color.gradientHeight + '%)'
        return gradient
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return 'background: ' + this.component.cart.color.solidColor.hexa + ';'
      }
    },
    styles () {
      var styles = 
        'padding-bottom: ' + this.component.cart.padding.bottom + 'px ;' +
        'padding-top: ' + this.component.cart.padding.top + 'px ;' +
        'padding-left: ' + this.component.cart.padding.left + 'px ;' +
        'padding-right: ' + this.component.cart.padding.right + 'px ;' +
        'border: ' + this.component.cart.borderSize + 'px solid ' + ';'
        this.component.cart.borderColor.hexa + '; '
      return styles
    },
    detailTableStyles () {
      var styles = 
        'margin-bottom: ' + this.component.cart.detail.margin.bottom + 'px ;' +
        'margin-top: ' + this.component.cart.detail.margin.top + 'px ;' +
        'margin-left: ' + this.component.cart.detail.margin.left + 'px ;' +
        'margin-right: ' + this.component.cart.detail.margin.right + 'px ;'
      return styles
    },
  },
  methods: {
    handleClose() {
      this.clear()
      this.$emit('onClose')
    },
    clear() {
      this.$store.commit('setTransactions', []);
    },
    handleDeleteConfirmation (item) {
      console.log(item)
      // Delete record
    },
  },
}
</script>

<style scoped>
.sub-heading-shopping-cart {
  font-size: 12px;
}
.centered-input >>> input {
  text-align: center
}
</style>
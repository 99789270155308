/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  orders: [],
  loadingOrders: false,
  processingStatusChange: false,
};

const actions = {
  async loadOrders({ commit, getters }) {
    commit("setLoadingTable", true);
    commit("setLoadingOrders", true);

    const dbRef = db.collection("programs").doc(getters.programId);

    dbRef.collection("orders").onSnapshot(
      (querySnapshot) => {
        let orders = [];
        querySnapshot.forEach((doc) => {
          orders.push({
            id: doc.id,
            ...doc.data(),
            items: doc.data().items.map((el) => ({
              ...el,
              orderId: doc.id,
              memberId: doc.data().createdBy,
            })),
          });
        });
        commit("setOrders", orders);
      },
      (error) => {
        console.log(error);
      }
    );

    commit("setLoadingTable", false);
    commit("setLoadingOrders", false);
  },

  async createOrder() {
    // not available in admin yet. Only in member site
  },

  async updateOrder({ commit, getters }, payload) {
    const ordersCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("orders");

    const order = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await ordersCollection.doc(payload.id).update(order);
    } catch (e) {
      throw "error when updating an order";
    }

    const orderBeforeUpdate = getters.orders.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempOrder = {
      ...orderBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updateOrder", tempOrder);
    commit("setSnackbar", "Order updated");

    return "ok";
  },

  async updateSelectedStatus({ getters, commit, dispatch }, payload) {
    var updated = new Date();
    const arr = payload.changedArr;
    const changedArr = arr.map((el) => ({ ...el, updated: updated }));

    const ordersCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("orders");

    let storedOrder;
    try {
      const orderDoc = await ordersCollection.doc(payload.id).get();
      storedOrder = orderDoc.data();
    } catch (e) {
      storedOrder = null;
    }

    if (!storedOrder) {
      throw "Error occured when fetching the order info";
    }

    storedOrder.items = storedOrder.items.map((item) => {
      const item2 = changedArr.find((i2) => i2.itemId === item.itemId);
      return item2 ? { ...item, ...item2 } : item;
    });

    changedArr.forEach((el) => {
      var update = {
        memberId: payload.memberId,
        points: +el.each * el.quantity,
        notes: el.title,
      };

      const changeType = el.lastStatus + el.status;

      switch (changeType) {
        case "CancelledOpen":
          update = {
            ...update,
            type: "Reorder",
            description: "Status changed from Cancelled to Open",
          };
          dispatch("subtractMemberPoints", update);
          break;

        case "CancelledProcessing":
          update = {
            ...update,
            type: "Reorder",
            description: "Status changed from Cancelled to Processing",
          };
          dispatch("subtractMemberPoints", update);
          break;

        case "CancelledShipping":
          update = {
            ...update,
            type: "Reorder",
            description: "Status changed from Cancelled to Shipping",
          };
          dispatch("subtractMemberPoints", update);
          break;

        case "CancelledCompleted":
          update = {
            ...update,
            type: "Reorder",
            description: "Status changed from Cancelled to Completed",
          };
          dispatch("subtractMemberPoints", update);
          break;

        case "OpenCancelled":
          update = {
            ...update,
            type: "Refund",
            description: "Status changed from Open to Cancelled",
          };
          dispatch("addMemberPoints", update);
          break;

        case "ProcessingCancelled":
          update = {
            ...update,
            type: "Refund",
            description: "Status changed from Processing to Cancelled",
            subtotal: 0,
          };
          dispatch("addMemberPoints", update);
          break;

        case "ShippingCancelled":
          update = {
            ...update,
            type: "Refund",
            description: "Status changed from Shipping to Cancelled",
          };
          dispatch("addMemberPoints", update);
          break;

        case "CompletedCancelled":
          update = {
            ...update,
            type: "Refund",
            description: "Status changed from Completed to Cancelled",
          };
          dispatch("addMemberPoints", update);
          break;

        default:
          console.log("default case");
      }
    });

    const itemsRecalculateSubtotals = storedOrder.items.map((el) => {
      return el.status === "Cancelled"
        ? { ...el, subtotal: 0 }
        : { ...el, subtotal: +el.each * el.quantity };
    });

    const recalculatedCartValue = itemsRecalculateSubtotals.reduce(
      (acc, { subtotal }) => acc + subtotal,
      0
    );

    const updatedOrder = {
      ...storedOrder,
      items: itemsRecalculateSubtotals,
      cartValue: recalculatedCartValue,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await ordersCollection.doc(payload.id).update(updatedOrder);
    } catch (e) {
      console.error(e);
      commit("setProcessingStatusChange", false);
      throw "Error occured when updating a member";
    }
    commit("setProcessingStatusChange", false);
    commit("setSnackbar", "Status updated on selected item(s)");
  },

  setProcessingStatusChange({ commit }, payload) {
    commit("setProcessingStatusChange", payload);
  },
};

const mutations = {
  setOrders(state, payload) {
    state.orders = payload;
  },

  setLoadingOrders(state, payload) {
    state.loadingOrders = payload;
  },

  setProcessingStatusChange(state, payload) {
    state.processingStatusChange = payload;
  },

  createOrder(state, payload) {
    state.orders = [...state.orders, payload];
  },

  updateOrder(state, payload) {
    state.orders = state.orders.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteOrder(state, payload) {
    state.orders = state.orders.filter((item) => item.id !== payload);
  },
};

const getters = {
  orders(state) {
    return state.orders;
  },
  loadingOrders(state) {
    return state.loadingOrders;
  },
  processingStatusChange(state) {
    return state.processingStatusChange;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

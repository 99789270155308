/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  pages: [],
  loadingPages: false,
};

const actions = {
  async createPage({ dispatch, commit, getters }, payload) {
    const pagesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("pages");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await pagesRef
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the title.";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Title is already registered.";
    }

    // eslint-disable-next-line no-unused-vars
    const { id, ...pageInput } = payload;

    const page = {
      ...pageInput,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newPageRef;
    try {
      newPageRef = await pagesRef.add(page);
    } catch (e) {
      console.error(e);
      throw "error when creating a new page";
    }

    // Note: server time is unavailable until we refetch.
    const tempPage = {
      ...page,
      id: newPageRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createPage", tempPage);
    dispatch("setSnackbar", "Page Created.");
  },

  async updatePage({ dispatch, commit, getters }, payload) {
    const pagesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("pages");

    let storedPage;
    try {
      const pageDoc = await pagesRef.doc(payload.id).get();
      storedPage = pageDoc.data();
    } catch (e) {
      storedPage = null;
    }

    if (!storedPage) {
      throw "Error occured when fetching the page info";
    }

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await pagesRef
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the title.";
    }

    if (titleDupSnapshot.size > 0) {
      let duplicated = false;
      titleDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Title is already registered.";
      }
    }

    const { id, ...pageInput } = payload;

    const page = {
      ...pageInput,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await pagesRef.doc(id).update(page);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a page";
    }

    const tempPage = {
      ...storedPage,
      ...pageInput,
      id,
      created: storedPage.created.toDate(),
      updated: new Date(),
    };

    commit("updatePage", tempPage);
    dispatch("setSnackbar", "Page Updated");
  },

  async loadPages({ commit, getters }) {
    commit("setLoadingTable", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("pages")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const pages = [];
    querySnapshot.forEach(function (doc) {
      pages.push({
        id: doc.id,
        title: doc.data().title,
        heading: doc.data().heading,
        subheading: doc.data().subheading,
        body: doc.data().body,
        titleUppercase: doc.data().titleUppercase,
        status: doc.data().status,
        created: doc.data().created.toDate(),
        createdBy: doc.data().createdBy,
        updated: doc.data().updated.toDate(),
        updatedBy: doc.data().updatedBy,
        companyTags: doc.data().companyTags,
        memberTags: doc.data().memberTags,
        resources: doc.data().resources,
      });
    });
    commit("setPages", pages);
    commit("setLoadingTable", false);
  },

  async deletePage({ dispatch, commit, getters }, pageId) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("pages")
        .doc(pageId)
        .delete();
    } catch (e) {
      throw "Error occured when deleting a page";
    }
    commit("deletePage", pageId);
    dispatch("setLoading", true);
    dispatch("setSnackbar", "Page Deleted.");
  },
};

const mutations = {
  setPages(state, payload) {
    state.pages = payload;
  },

  createPage(state, payload) {
    state.pages = [...state.pages, payload];
  },

  updatePage(state, payload) {
    state.pages = state.pages.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deletePage(state, payload) {
    state.pages = state.pages.filter((item) => item.id !== payload);
  },
};

const getters = {
  pages(state) {
    return state.pages;
  },
  loadingPages(state) {
    return state.loadingPages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

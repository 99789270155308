<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="dict">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="primary"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            {{ dictionaryAction === 'update' ? 'Edit ' : 'Add New '}}
            {{ formTitle }}
          </v-card-title>
        </v-row>

        <v-card-text class="px-16">
                
          <v-row no-gutters>
            <v-text-field
              name="english"
              v-model="english"
              label="English"
              v-validate="'required'"
              :error-messages="errors.collect('dict.english')"
            ></v-text-field>   
            <v-text-field
              name="value"
              v-model="value"
              label="Translation"
              v-validate="'required'"
              :error-messages="errors.collect('dict.value')"
            ></v-text-field>   
          </v-row>

          <v-row no-gutters>
            <v-col v-if="errorMessage">
                <v-alert
                  type="error"
                  :value="true"
                >
                  {{errorMessage}}
                </v-alert>
              </v-col>         
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            >Cancel</v-btn>
          <v-btn
            :class="orgTheme.buttonTextColor"
            color="primary"
            @click="handleSave('dict')"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Save</v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  created () {
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  props: [ 'dictionaryItem', 'translationId', 'directory', 'dictionaryAction', 'open' ],
  data: () => ({
    dictionary: {
      custom: {
        english: {
          required: () => 'English value is required'
        },
        value: {
          required: () => 'Translation value required'
        }
      }
    },
    english: '',
    value: '',
    errorMessage: '',
    formTitle: 'Translation',
    formIcon: 'translate',
  }),

  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    error () {
      return this.$store.getters.error
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    }
  },

  watch: {
    dictionaryItem: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedDictionaryItem = this.directory.find(item => item === newVal);
          const {
            english, value
          } = selectedDictionaryItem
          this.english = english
          this.value = value
        }
      },
    },
  },

  methods: {
    handleClose () {
      this.clear()
      this.$emit('onClose')
    },

    clear () {
      this.dictionaryItem = {}
      this.translationId = ''
      this.dictionaryAction = ''
      this.directory = []
      this.errorMessage = '',
      this.english = '',
      this.value= '',
      this.$validator.reset()
      this.$store.dispatch('setLoading', false)
    },

    handleSave (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return
        }
        this.$store.dispatch('setLoading', true)
        const payload = {
          english: this.english,
          value: this.value,
        }

        if (this.dictionaryAction === 'create') {
          const arr = this.directory
          const found = arr.find((el) => el.english === payload.english)
          if (found !== undefined) {
            this.errorMessage = payload.english + ' is already added'
          } else {
            this.directory.push(payload)
            this.handleClose()
          }
        } else if (this.dictionaryAction === 'update') {
          const arr = this.directory
          const found = arr.filter((el) => el.english === payload.english).length
          
          if (this.dictionaryItem.english === this.english) {
            var index = arr.findIndex((el) => el.english === payload.english)
            this.directory[index] = payload
            this.dictionaryItem.english = payload.english
            this.dictionaryItem.value = payload.value
            this.handleClose()
          } else if (found > 0) {
            this.errorMessage = payload.english + ' already exists'
          } else {
            this.dictionaryItem.english = payload.english
            this.dictionaryItem.value = payload.value
            this.english = payload.english
            this.value = payload.value
            this.handleClose()
          }
        
        } else {
          this.errorMessage = 'There was an error. Please refresh the page and try again'
        }
        this.$store.dispatch('setLoading', false)
      });
    }
  },
}
</script>

<style>
</style>

<template>
  <div>
    <v-col cols="4">
      <v-card 
        height="150"
        color="secondary"
        elevation="0"
      >
        <img :src="'/img/icons/006-objective.svg'"
          class="card-icon"
        >
        <v-card-text class="pa-6">
          <v-row no-gutters style="height: 105px" align="end">
            <v-col class="white--text">
              <div class="card-suffix mb-3 mt-n2"> {{prefix}} </div>
              <div class="card-result"> {{accountHomeValue}} </div>
              <div v-if='suffix' class="card-suffix"> {{suffix}} </div>
              <div class="card-title"> {{currentTable.displayTitle}} </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-text-field
        v-model="prefix"
        placeholder="Add a Prefix"
        label="Prefix"
        class="mt-6"
      />
      <v-text-field
        v-model="suffix"
        placeholder="Add a Suffix"
        label="Suffix"
      />
      <table-column-mapper
        :current="current"
        :sourceColumns="sourceColumns"
        :csvColumns="csvHeaders"
        buttonText="Set"
        mapping="home"
        @onAdd="handleAddColumn"
      />
    </v-col>
  </div>
</template>

<script>
import TableColumnMapper from './ColumnMapper.vue'

export default {
  components: {
    TableColumnMapper
  },
  data: function () {
    const accountsMap = this.$store.getters.tableAccountsMap;
    return {
      selectedAccountKey: accountsMap.length
        ? accountsMap[0].value
        : '',
    }
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    previewAccountKey () {
      return this.$store.getters.previewAccountKey
    },
    currentTable() {
      return this.$store.state.table.currentTable;
    },
    csvHeaders() {
      return this.$store.getters.tableCsvHeaders;
    },
    systemHeaders() {
      return this.$store.getters.tableSystemHeaders;
    },
    accountsMap() {
      return this.$store.getters.tableAccountsMap;
    },
    sourceColumns() {
      return [
        { header: 'System Fields'},
        ...this.systemHeaders,
        { header: 'Data Bucket Fields'},
        ...this.csvHeaders,
      ];
    },
    homeCardMappings() {
      return this.currentTable.homeCardMappings
    },
    current() {
      return this.currentTable.homeCardMappings.column || 'Not Set'
    },
    prefix: {
      get() {
        return this.homeCardMappings.prefix
      },
      set(val) {
        const homeCardMappings = {
          ...this.homeCardMappings,
          prefix: val,
        }
        this.$store.dispatch('patchCurrentTable', { homeCardMappings })
      }
    },
    suffix: {
      get() {
        return this.homeCardMappings.suffix;
      },
      set(val) {
        const homeCardMappings = {
          ...this.homeCardMappings,
          suffix: val,
        };
        this.$store.dispatch('patchCurrentTable', { homeCardMappings });
      }
    },
    homeData() {
      return this.$store.getters.tableHomeData;
    },
    accountHomeValue() {
      const participant = this.homeData.find(item => item.accountKey === this.previewAccountKey);
      return participant ? participant.value : '';
    },
    previewCardBodyClass() {
      if (this.prefix && !this.suffix) {
        return 'card-top-prefix';
      } else if (!this.prefix && this.suffix) {
        return 'card-top-suffix';
      } else if (this.prefix && this.suffix) {
        return 'card-top-both';
      }
      return 'card-top-none';
    }
  },
  methods: {
    handleAddColumn(newMapping) {
      const homeCardMappings = {
        ...this.homeCardMappings,
        ...newMapping,
      };
      this.$store.dispatch('patchCurrentTable', { homeCardMappings })
    },
  }
}
</script>

<style scoped>
.card-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100px;
  opacity: 0.2;
}
</style>
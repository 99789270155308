<template>
  <div class="mt-8 mr-8 mb-8">  
     <v-row v-if="app" no-gutters>
      App settings TBC
     </v-row>
     <v-row v-else no-gutters>
      There is no active app attached to this program. Contact us to add an app to your program.
     </v-row>
    <v-btn
      class="mt-9 ml-0"
      color="primary"
      @click="handleSave"
      :loading="loading"
      elevation="0"
      :disabled="!app"
    >Save</v-btn>

  </div>
</template>

<script>
export default {
  created () {
    const { currentProgram } = this.$store.state.program
    this.iosAppStatus = currentProgram.iosAppStatus
    this.androidAppStatus = currentProgram.androidAppStatus
  },
  data: () => ({
    iosAppStatus: '',
    androidAppStatus: '',
  }),
  computed: {
    programId () {
      return this.$store.getters.programId
    },
    loading () {
      return this.$store.getters.loading
    },
    app () {
      return this.iosAppStatus === true || this.androidAppStatus === true
    }
  },
  methods: {
    handleSave() {
      const payload = {
      };

      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('updateCurrentProgram', payload)
        .then(() => {
          this.$store.dispatch('setLoading', false)
        })
        .catch(() => {
          this.$store.dispatch('setLoading', false)
        })
    },
  },
}
</script>

<style scoped>

</style>

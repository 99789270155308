<!-- MenuBar -->
<template>
  <v-card class="mx-4 mb-6" dark outlined>
    <v-expansion-panels accordion flat color="transparent" dark>
      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Button Labels</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="label"
              label="Heading"
              v-model="editingComponent.label.value"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'label'"
              icon
              class="mt-4"
              @click="handleEditor('label')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'label'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'label'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Button Group</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-0">
            <v-col cols="5">
              <v-icon class="icon">call_merge</v-icon>
              <span class="setting-label">Merge Button Groups</span>
            </v-col>
            <v-checkbox
              v-model="editingComponent.menuGroup.group"
              @click="handleChange()"
              class="mt-n1"
            ></v-checkbox>
            <span class="setting-label mb-4 mt-n4"
              >(Combine all buttons into one group)</span
            >
          </v-row>

          <v-row v-if="editingComponent.menuGroup.group" no-gutters>
            <v-col cols="5">
              <v-icon class="icon">compare_arrows</v-icon>
              <span class="setting-label">Merged Group Align</span>
            </v-col>
            <v-icon
              @click="setButtonsHorizontal('start', 'group')"
              class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setButtonsHorizontal('center', 'group')"
              >align_horizontal_center</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setButtonsHorizontal('end', 'group')"
              >align_horizontal_right</v-icon
            >
          </v-row>

          <v-row no-gutters v-if="!editingComponent.menuGroup.group">
            <v-col cols="5">
              <v-icon class="icon">compare_arrows</v-icon>
              <span class="setting-label">Left Group Align</span>
            </v-col>
            <v-icon
              @click="setButtonsHorizontal('start', 'left')"
              class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setButtonsHorizontal('center', 'left')"
              >align_horizontal_center</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setButtonsHorizontal('end', 'left')"
              >align_horizontal_right</v-icon
            >
          </v-row>

          <v-row
            no-gutters
            v-if="!editingComponent.menuGroup.group"
            class="mt-2"
          >
            <v-col cols="5">
              <v-icon class="icon">compare_arrows</v-icon>
              <span class="setting-label">Right Group Align</span>
            </v-col>
            <v-icon
              @click="setButtonsHorizontal('start', 'right')"
              class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setButtonsHorizontal('center', 'right')"
              >align_horizontal_center</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setButtonsHorizontal('end', 'right')"
              >align_horizontal_right</v-icon
            >
          </v-row>

          <v-row no-gutters class="mt-3">
            <v-col cols="5">
              <v-icon class="rotate icon">mdi-land-rows-horizontal</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.menuGroup.cols"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="1"
              max="12"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.menuGroup.cols }}/12
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Size</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">height</v-icon>
              <span class="setting-label">Height</span>
            </v-col>
            <v-slider
              v-model="webTheme.shared.menuHeight"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="50"
              max="150"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ webTheme.shared.menuHeight }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-0">
            <v-col cols="5">
              <v-icon class="icon">layers</v-icon>
              <span class="setting-label">Overlap Content</span>
            </v-col>
            <v-checkbox
              v-model="editingComponent.settings.overlap"
              @click="handleChange()"
              class="mt-n1"
            ></v-checkbox>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Logo</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row v-if="editingComponent.logo.image" no-gutters justify="center">
            <v-card color="grey lighten-2" tile elevation="0">
              <v-img
                :src="editingComponent.logo.image"
                max-width="300px"
                max-height="300px"
                contain
                class="rounded outline"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-skeleton-loader
                      min-height="300px"
                      min-width="300px"
                      type="card"
                    >
                    </v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-row>

          <v-row no-gutters class="mt-4" justify="center">
            <v-btn
              @click="handleGalleryDialog(true)"
              elevation="0"
              color="primary"
            >
              <v-icon left>image_search</v-icon>Gallery
            </v-btn>
            <v-btn
              @click="handleUploadsDialog(true)"
              elevation="0"
              color="primary"
            >
              <v-icon left>image_search</v-icon>Uploads
            </v-btn>
            <design-editor-gallery :saveTo="'logo'" />
            <design-editor-uploads :saveTo="'logo'" />
          </v-row>

          <v-row no-gutters class="mt-4">
            <v-col cols="4" class="setting-label">
              <span class="setting-label">Remove Logo</span>
            </v-col>
            <v-icon @click="clearLogo()" class="icon-button">hide_image</v-icon>
          </v-row>

          <v-row no-gutters class="mt-3">
            <v-col cols="4" class="setting-label">
              <span class="setting-label">Position</span>
            </v-col>
            <v-icon @click="setMenuLogoPosition('left')" class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setMenuLogoPosition('center')"
              >align_horizontal_center</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setMenuLogoPosition('right')"
              >align_horizontal_right</v-icon
            >
          </v-row>

          <v-row no-gutters class="mt-3">
            <v-col cols="4" class="setting-label">
              <v-icon class="ml-1 icon-button">photo_size_select_large</v-icon>
              <span class="setting-label">Size</span>
            </v-col>
            <v-slider
              v-model="editingComponent.logo.width"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="20"
              max="400"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.logo.width }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters align="center" class="mt-3">
            <v-col cols="2" class="setting-label"> Menu </v-col>
            <v-col>
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.settings.color.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="setting-label ml-2">
                      {{ editingComponent.settings.color.hexa }}
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.settings.color"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Container</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="rotate icon">height</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.settings.cols"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="1"
              max="12"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.settings.cols }}/12
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-row no-gutters class="px-6 py-3">
        <v-col>
          <v-icon color="primary" class="mt-n1 rotate">input</v-icon>
          <span class="editor-heading ml-2">Dropdown Menu</span>
        </v-col>
      </v-row>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Labels</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="dropdownLabel"
              label="Dropdown Label"
              :value="editingComponent.dropdown.dropdownLabel.value"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'dropdownLabel'"
              icon
              class="mt-4"
              @click="handleEditor('dropdownLabel')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'dropdownLabel'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'dropdownLabel'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Dropdown Menu Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters align="center">
            <v-radio-group
              class="mt-n2"
              v-model="editingComponent.dropdown.color.type"
              @change="handleChange()"
              row
            >
              <v-radio label="Color" value="solid"></v-radio>
              <v-radio label="Gradient" value="gradient"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row no-gutters align="center">
            <v-col v-if="editingComponent.dropdown.color.type === 'solid'">
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="
                            editingComponent.dropdown.color.solidColor.hexa
                          "
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="editor-third-heading ml-2">
                      {{ editingComponent.dropdown.color.solidColor.hexa }}
                    </v-col>
                  </v-row>
                </template>

                <v-color-picker
                  @input="handleChange()"
                  dot-size="25"
                  show-swatches
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="editingComponent.dropdown.color.solidColor"
                ></v-color-picker>
              </v-menu>
            </v-col>
            <v-col v-if="editingComponent.dropdown.color.type === 'gradient'">
              <v-row class="editor-third-heading mt-n2 mb-n1" no-gutters>
                Gradient direction
              </v-row>
              <v-radio-group
                v-model="editingComponent.dropdown.color.direction"
                class="mt-n1"
                @change="handleChange()"
              >
                <v-row>
                  <v-col>
                    <v-radio label="Top to Bottom" value="to bottom"> </v-radio>
                    <v-radio label="Bottom to Top" value="to top"> </v-radio>
                  </v-col>
                  <v-col>
                    <v-radio label="Left to Right" value="to right"> </v-radio>
                    <v-radio label="Right to Left" value="to left"> </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>

              <v-row no-gutters align="center">
                <v-col cols="2" class="editor-third-heading"> From </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    z-index="3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.dropdown.color.gradientFrom
                                  .hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{
                            editingComponent.dropdown.color.gradientFrom.hexa
                          }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.dropdown.color.gradientFrom"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="mt-1">
                <v-col cols="2" class="editor-third-heading"> To </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    z-index="3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.dropdown.color.gradientTo.hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{ editingComponent.dropdown.color.gradientTo.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.dropdown.color.gradientTo"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-5 mb-n3">
                <v-col cols="auto">
                  <v-icon class="icon">gradient</v-icon>
                  <span class="setting-label">Size</span>
                </v-col>
                <v-slider
                  v-model="editingComponent.dropdown.color.gradientHeight"
                  @input="handleChange()"
                  :thumb-size="24"
                  thumb-label
                  min="1"
                  max="100"
                  class="mt-n1"
                  color="primary"
                ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.dropdown.color.gradientHeight }}%
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-row no-gutters class="px-6 py-3">
        <v-col>
          <v-icon color="primary" class="mt-n1"
            >fa-solid fa-ranking-star</v-icon
          >
          <span class="editor-heading ml-2">Tier</span>
        </v-col>
      </v-row>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Icon</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-3 px-1 mb-n3">
            <v-col cols="5">
              <v-icon class="icon">photo_size_select_large</v-icon>
              <span class="setting-label">Size</span>
            </v-col>
            <v-col>
              <v-slider
                v-model="editingComponent.tier.imageSize"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="8"
                max="200"
                class="mt-n1 mr-2"
                color="primary"
              ></v-slider>
            </v-col>
            <v-col cols="auto" class="setting-label">
              <v-row no-gutters>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.tier.imageSize--;
                    "
                    color="grey"
                    >remove_circle</v-icon
                  >
                </v-col>
                <v-col>
                  <v-sheet
                    color="transparent"
                    min-width="25px"
                    class="text-center"
                  >
                    {{ editingComponent.tier.imageSize }}
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.tier.imageSize++;
                    "
                    color="grey"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Tier Label</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="tierLabel"
              label="Tier Label"
              :value="editingComponent.tier.tierLabel.value"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'tierLabel'"
              icon
              class="mt-4"
              @click="handleEditor('tierLabel')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'tierLabel'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'tierLabel'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Background Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">image</v-icon>
              <span class="setting-label">Outlined</span>
            </v-col>
            <v-checkbox
              v-model="editingComponent.tier.button.outlined"
              @click="handleChange()"
              class="mt-n1"
            ></v-checkbox>
          </v-row>

          <v-row no-gutters align="center">
            <v-col>
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.tier.button.color.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="editor-third-heading ml-2">
                      {{ editingComponent.tier.button.color.hexa }}
                    </v-col>
                  </v-row>
                </template>

                <v-color-picker
                  @input="handleChange()"
                  dot-size="25"
                  show-swatches
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="editingComponent.tier.button.color"
                ></v-color-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-row no-gutters class="px-6 py-3">
        <v-col>
          <v-icon color="primary" class="mt-n1">shopping_cart</v-icon>
          <span class="editor-heading ml-2">Shopping Cart</span>
        </v-col>
      </v-row>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Headings</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="cartTitle"
              label="Title"
              v-model="editingComponent.cart.cartTitle.value"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'cartTitle'"
              icon
              class="mt-4"
              @click="handleEditor('cartTitle')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'cartTitle'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'cartTitle'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-text-field
              name="points"
              label="Order Value"
              v-model="editingComponent.cart.points.value"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'points'"
              icon
              class="mt-4"
              @click="handleEditor('points')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'points'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'points'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-text-field
              name="subheading"
              label="Subheading"
              v-model="editingComponent.cart.subheading.value"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'subheading'"
              icon
              class="mt-4"
              @click="handleEditor('subheading')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'subheading'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'subheading'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Stepper Labels</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="stepperLabels"
              label="Stepper Labels"
              v-model="editingComponent.cart.stepperLabels.value"
              @input="handleChange()"
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'stepperLabels'"
              icon
              class="mt-4"
              @click="handleEditor('stepperLabels')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'stepperLabels'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'stepperLabels'"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-text-field
              name="stepperSummary"
              label="Stepper Summary"
              v-model="editingComponent.cart.stepperSummary.value"
              @input="handleChange()"
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'stepperSummary'"
              icon
              class="mt-4"
              @click="handleEditor('stepperSummary')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'stepperSummary'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'stepperSummary'"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Reward Image</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">image</v-icon>
              <span class="setting-label">Square</span>
            </v-col>
            <v-checkbox
              v-model="editingComponent.cart.imageTile"
              @click="handleChange()"
              class="mt-n1"
            ></v-checkbox>
          </v-row>

          <v-row no-gutters class="mb-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">height</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.imageSize"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="10"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.imageSize }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Size</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">fullscreen</v-icon>
              <span class="setting-label">Full Screen</span>
            </v-col>
            <v-checkbox
              v-model="editingComponent.cart.fullscreen"
              @click="handleChange()"
              class="mt-n1"
            ></v-checkbox>
          </v-row>

          <v-row no-gutters class="mb-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">height</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.width"
              :disabled="editingComponent.cart.fullscreen"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="300"
              max="2000"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.width }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters align="center">
            <v-radio-group
              class="mt-n2"
              v-model="editingComponent.cart.color.type"
              @change="handleChange()"
              row
            >
              <v-radio label="Color" value="solid"></v-radio>
              <v-radio label="Gradient" value="gradient"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row no-gutters align="center">
            <v-col v-if="editingComponent.cart.color.type === 'solid'">
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.cart.color.solidColor.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="editor-third-heading ml-2">
                      {{ editingComponent.cart.color.solidColor.hexa }}
                    </v-col>
                  </v-row>
                </template>

                <v-color-picker
                  @input="handleChange()"
                  dot-size="25"
                  show-swatches
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="editingComponent.cart.color.solidColor"
                ></v-color-picker>
              </v-menu>
            </v-col>
            <v-col v-if="editingComponent.cart.color.type === 'gradient'">
              <v-row class="editor-third-heading mt-n2 mb-n1" no-gutters>
                Gradient direction
              </v-row>
              <v-radio-group
                v-model="editingComponent.cart.color.direction"
                class="mt-n1"
                @change="handleChange()"
              >
                <v-row>
                  <v-col>
                    <v-radio label="Top to Bottom" value="to bottom"> </v-radio>
                    <v-radio label="Bottom to Top" value="to top"> </v-radio>
                  </v-col>
                  <v-col>
                    <v-radio label="Left to Right" value="to right"> </v-radio>
                    <v-radio label="Right to Left" value="to left"> </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>

              <v-row no-gutters align="center">
                <v-col cols="2" class="editor-third-heading"> From </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    z-index="3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.cart.color.gradientFrom.hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{ editingComponent.cart.color.gradientFrom.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.cart.color.gradientFrom"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="mt-1">
                <v-col cols="2" class="editor-third-heading"> To </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    z-index="3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.cart.color.gradientTo.hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{ editingComponent.cart.color.gradientTo.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.cart.color.gradientTo"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-5 mb-n3">
                <v-col cols="auto">
                  <v-icon class="icon">gradient</v-icon>
                  <span class="setting-label">Size</span>
                </v-col>
                <v-slider
                  v-model="editingComponent.cart.color.gradientHeight"
                  @input="handleChange()"
                  :thumb-size="24"
                  thumb-label
                  min="1"
                  max="100"
                  class="mt-n1"
                  color="primary"
                ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.cart.color.gradientHeight }}%
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Padding</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-0">
            <v-col cols="5">
              <v-icon class="rotate270 icon">padding</v-icon>
              <span class="setting-label">Left Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.padding.left"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.padding.left }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">padding</v-icon>
              <span class="setting-label">Right Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.padding.right"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.padding.right }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">padding</v-icon>
              <span class="setting-label">Top Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.padding.top"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.padding.top }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3 mb-n6">
            <v-col cols="5">
              <v-icon class="rotate180 icon">padding</v-icon>
              <span class="setting-label">Bottom Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.padding.bottom"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.padding.bottom }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Button</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-4 mb-3">
            <v-col cols="5">
              <v-icon class="icon">palette</v-icon>
              <span class="setting-label">Color</span>
            </v-col>
            <v-col>
              <v-menu
                bottom
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.cart.button.color.hex"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.cart.button.color"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-0">
            <v-col cols="5">
              <v-icon class="icon">contrast</v-icon>
              <span class="setting-label">Dark Text</span>
            </v-col>
            <v-col>
              <v-checkbox
                class="mt-n1"
                v-model="editingComponent.cart.button.dark"
                @change="handleChange()"
                color="primary"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Tables</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="editor-third-heading">Tables Icons</v-row>

          <v-row class="mt-2" no-gutters>
            <v-col cols="5">
              <v-icon class="icon">photo_size_select_large</v-icon>
              <span class="setting-label">Icon Size</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.iconSize"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="20"
              max="50"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.iconSize }}
            </v-col>
          </v-row>

          <v-row no-gutters class="editor-third-heading"
            >Tables Border & Alignment</v-row
          >

          <v-row class="mt-2 mb-3" no-gutters>
            <v-col cols="5">
              <v-icon class="icon">sync_alt</v-icon>
              <span class="setting-label">Align Content</span>
            </v-col>

            <v-icon
              @click="setTableContentHorizontal('start')"
              class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setTableContentHorizontal('center')"
              >align_horizontal_center</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setTableContentHorizontal('end')"
              >align_horizontal_right</v-icon
            >
          </v-row>

          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">border_outer</v-icon>
              <span class="setting-label">Border</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.rowBorderSize"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="5"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.rowBorderSize }}px
            </v-col>
          </v-row>

          <v-row
            v-if="editingComponent.cart.rowBorderSize > 0"
            no-gutters
            class="mt-n3 mb-4"
          >
            <v-col cols="5">
              <v-icon class="icon">palette</v-icon>
              <span class="setting-label">Border Color</span>
            </v-col>
            <v-col>
              <v-menu
                bottom
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          height="30px"
                          width="30px"
                          :color="editingComponent.cart.rowBorderColor.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.cart.rowBorderColor"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n2 mb-4">
            <v-col cols="5">
              <v-icon class="icon">palette</v-icon>
              <span class="setting-label">Hover Color</span>
            </v-col>
            <v-col>
              <v-menu
                bottom
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.cart.rowHoverColor.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.cart.rowHoverColor"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>

          <v-row no-gutters class="editor-third-heading mb-3">Headings</v-row>

          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-menu offset-y transition="slide-y-transition" z-index="3000">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-card
                        elevation="0"
                        class="pl-3"
                        width="175px"
                        height="24px"
                        color="grey lighten-3"
                      >
                        <span class="font-select-label">{{ fontLabel }}</span>
                        <v-icon class="float-right">arrow_drop_down</v-icon>
                      </v-card>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(font, index) in fonts"
                      :key="index + 'a'"
                      :style="'font-family: ' + font.class + ' !important;'"
                      @click="setHeadingFont(font)"
                      dense
                    >
                      {{ font.label }}
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-icon class="ml-2 icon-button" @click="setHeadingWeight()"
                  >format_bold</v-icon
                >
                <v-icon class="ml-2 icon-button" @click="setHeadingStyle()"
                  >format_italic</v-icon
                >

                <v-menu
                  bottom
                  nudge-bottom="25"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  max-width="300px"
                  z-index="3000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="ml-1 icon-button"
                      >format_color_text</v-icon
                    >
                  </template>

                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.cart.content.heading.color"
                    z-index="2000"
                  ></v-color-picker>
                </v-menu>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-3 px-1">
            <v-col cols="5">
              <v-icon class="icon">format_size</v-icon>
              <span class="setting-label">Font Size</span>
            </v-col>
            <v-col>
              <v-slider
                v-model="editingComponent.cart.content.heading.size"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="8"
                max="200"
                class="mt-n1 mr-2"
                color="primary"
              ></v-slider>
            </v-col>
            <v-col cols="auto" class="setting-label">
              <v-row no-gutters>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.cart.content.heading.size--;
                    "
                    color="grey"
                    >remove_circle</v-icon
                  >
                </v-col>
                <v-col>
                  <v-sheet
                    color="transparent"
                    min-width="25px"
                    class="text-center"
                  >
                    {{ editingComponent.cart.content.heading.size }}
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.cart.content.heading.size++;
                    "
                    color="grey"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3 px-1">
            <v-col cols="5">
              <v-icon class="icon">format_line_spacing</v-icon>
              <span class="setting-label">Line Height</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.content.heading.lineHeight"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0.1"
              max="10"
              step="0.1"
              class="mt-n1 mr-2"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              <v-row no-gutters>
                <v-col>
                  <v-icon
                    @click="minusHeading('lineHeight', 0.1)"
                    size="11"
                    color="grey"
                    >remove_circle</v-icon
                  >
                </v-col>
                <v-col>
                  <v-sheet
                    color="transparent"
                    min-width="25px"
                    class="text-center"
                  >
                    {{ editingComponent.cart.content.heading.lineHeight }}
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-icon
                    size="11"
                    @click="plusHeading('lineHeight', 0.1)"
                    color="grey"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="editor-third-heading mb-3">Data Text</v-row>

          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-menu offset-y transition="slide-y-transition" z-index="3000">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-card
                        elevation="0"
                        class="pl-3"
                        width="175px"
                        height="24px"
                        color="grey lighten-3"
                      >
                        <span class="font-select-label">{{
                          dataFontLabel
                        }}</span>
                        <v-icon class="float-right">arrow_drop_down</v-icon>
                      </v-card>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(font, index) in fonts"
                      :key="index + 'b'"
                      :style="'font-family: ' + font.class + ' !important;'"
                      @click="setDataFont(font)"
                      dense
                    >
                      {{ font.label }}
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-icon class="ml-2 icon-button" @click="setDataWeight()"
                  >format_bold</v-icon
                >
                <v-icon class="ml-2 icon-button" @click="setDataStyle()"
                  >format_italic</v-icon
                >

                <v-menu
                  bottom
                  nudge-bottom="25"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  max-width="300px"
                  z-index="3000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="ml-1 icon-button"
                      >format_color_text</v-icon
                    >
                  </template>

                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.cart.content.data.color"
                    z-index="2000"
                  ></v-color-picker>
                </v-menu>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-3 px-1">
            <v-col cols="5">
              <v-icon class="icon">format_size</v-icon>
              <span class="setting-label">Font Size</span>
            </v-col>
            <v-col>
              <v-slider
                v-model="editingComponent.cart.content.data.size"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="8"
                max="200"
                class="mt-n1 mr-2"
                color="primary"
              ></v-slider>
            </v-col>
            <v-col cols="auto" class="setting-label">
              <v-row no-gutters>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.cart.content.data.size--;
                    "
                    color="grey"
                    >remove_circle</v-icon
                  >
                </v-col>
                <v-col>
                  <v-sheet
                    color="transparent"
                    min-width="25px"
                    class="text-center"
                  >
                    {{ editingComponent.cart.content.data.size }}
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.cart.content.data.size++;
                    "
                    color="grey"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3 px-1">
            <v-col cols="5">
              <v-icon class="icon">format_line_spacing</v-icon>
              <span class="setting-label">Line Height</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.content.data.lineHeight"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0.1"
              max="10"
              step="0.1"
              class="mt-n1 mr-2"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              <v-row no-gutters>
                <v-col>
                  <v-icon
                    @click="minusData('lineHeight', 0.1)"
                    size="11"
                    color="grey"
                    >remove_circle</v-icon
                  >
                </v-col>
                <v-col>
                  <v-sheet
                    color="transparent"
                    min-width="25px"
                    class="text-center"
                  >
                    {{ editingComponent.cart.content.data.lineHeight }}
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-icon
                    size="11"
                    @click="plusData('lineHeight', 0.1)"
                    color="grey"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="editor-third-heading mb-5 mt-0"
            >Detail Table</v-row
          >
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate270 icon">margin</v-icon>
              <span class="setting-label">Left Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.detail.margin.left"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.detail.margin.left }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">margin</v-icon>
              <span class="setting-label">Right Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.detail.margin.right"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.detail.margin.right }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">margin</v-icon>
              <span class="setting-label">Top Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.detail.margin.top"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.detail.margin.top }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3 mb-n6">
            <v-col cols="5">
              <v-icon class="rotate180 icon">margin</v-icon>
              <span class="setting-label">Bottom Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.cart.detail.margin.bottom"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.cart.detail.margin.bottom }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-row no-gutters class="px-6 py-3">
        <v-col>
          <v-icon color="primary" class="mt-n1">history</v-icon>
          <span class="editor-heading ml-2">Transaction History Pop Up</span>
        </v-col>
      </v-row>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Headings</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="historyTitle"
              label="Title"
              value="Points Transaction History"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'historyTitle'"
              icon
              class="mt-4"
              @click="handleEditor('historyTitle')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'historyTitle'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'historyTitle'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-text-field
              name="points"
              label="Order Value"
              v-model="editingComponent.history.points.value"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'pointsHistory'"
              icon
              class="mt-4"
              @click="handleEditor('pointsHistory')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'pointsHistory'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'pointsHistory'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>

          <v-row no-gutters>
            <v-text-field
              name="subheading"
              label="Awarded / Redeemed"
              v-model="editingComponent.history.subheading.value"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'subheadingHistory'"
              icon
              class="mt-4"
              @click="handleEditor('subheadingHistory')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'subheadingHistory'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'subheadingHistory'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Size</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">fullscreen</v-icon>
              <span class="setting-label">Full Screen</span>
            </v-col>
            <v-checkbox
              v-model="editingComponent.history.fullscreen"
              @click="handleChange()"
              class="mt-n1"
            ></v-checkbox>
          </v-row>

          <v-row no-gutters class="mb-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">height</v-icon>
              <span class="setting-label">Width</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.width"
              :disabled="editingComponent.history.fullscreen"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="300"
              max="2000"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.width }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters align="center">
            <v-radio-group
              class="mt-n2"
              v-model="editingComponent.history.color.type"
              @change="handleChange()"
              row
            >
              <v-radio label="Color" value="solid"></v-radio>
              <v-radio label="Gradient" value="gradient"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row no-gutters align="center">
            <v-col v-if="editingComponent.history.color.type === 'solid'">
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="
                            editingComponent.history.color.solidColor.hexa
                          "
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="editor-third-heading ml-2">
                      {{ editingComponent.history.color.solidColor.hexa }}
                    </v-col>
                  </v-row>
                </template>

                <v-color-picker
                  @input="handleChange()"
                  dot-size="25"
                  show-swatches
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="editingComponent.history.color.solidColor"
                ></v-color-picker>
              </v-menu>
            </v-col>
            <v-col v-if="editingComponent.history.color.type === 'gradient'">
              <v-row class="editor-third-heading mt-n2 mb-n1" no-gutters>
                Gradient direction
              </v-row>
              <v-radio-group
                v-model="editingComponent.history.color.direction"
                class="mt-n1"
                @change="handleChange()"
              >
                <v-row>
                  <v-col>
                    <v-radio label="Top to Bottom" value="to bottom"> </v-radio>
                    <v-radio label="Bottom to Top" value="to top"> </v-radio>
                  </v-col>
                  <v-col>
                    <v-radio label="Left to Right" value="to right"> </v-radio>
                    <v-radio label="Right to Left" value="to left"> </v-radio>
                  </v-col>
                </v-row>
              </v-radio-group>

              <v-row no-gutters align="center">
                <v-col cols="2" class="editor-third-heading"> From </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    z-index="3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.history.color.gradientFrom.hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{ editingComponent.history.color.gradientFrom.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.history.color.gradientFrom"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="mt-1">
                <v-col cols="2" class="editor-third-heading"> To </v-col>
                <v-col>
                  <v-menu
                    bottom
                    nudge-bottom="25"
                    :close-on-content-click="false"
                    transition="slide-y-transition"
                    max-width="300px"
                    z-index="3000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row no-gutters align="center">
                        <v-col cols="auto">
                          <v-img
                            class="rounded outline"
                            :src="require('@/assets/checkerboard.png')"
                            height="30px"
                            width="30px"
                          >
                            <v-card
                              v-bind="attrs"
                              v-on="on"
                              elevation="0"
                              height="30px"
                              width="30px"
                              :color="
                                editingComponent.history.color.gradientTo.hexa
                              "
                            >
                            </v-card>
                          </v-img>
                        </v-col>
                        <v-col class="editor-third-heading ml-2">
                          {{ editingComponent.history.color.gradientTo.hexa }}
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-color-picker
                        @input="handleChange()"
                        dot-size="25"
                        show-swatches
                        mode="hexa"
                        swatches-max-height="200"
                        v-model="editingComponent.history.color.gradientTo"
                      ></v-color-picker>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-5 mb-n3">
                <v-col cols="auto">
                  <v-icon class="icon">gradient</v-icon>
                  <span class="setting-label">Size</span>
                </v-col>
                <v-slider
                  v-model="editingComponent.history.color.gradientHeight"
                  @input="handleChange()"
                  :thumb-size="24"
                  thumb-label
                  min="1"
                  max="100"
                  class="mt-n1"
                  color="primary"
                ></v-slider>
                <v-col cols="auto" class="setting-label">
                  {{ editingComponent.history.color.gradientHeight }}%
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Card Padding</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-0">
            <v-col cols="5">
              <v-icon class="rotate270 icon">padding</v-icon>
              <span class="setting-label">Left Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.padding.left"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.padding.left }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">padding</v-icon>
              <span class="setting-label">Right Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.padding.right"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.padding.right }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">padding</v-icon>
              <span class="setting-label">Top Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.padding.top"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.padding.top }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3 mb-n6">
            <v-col cols="5">
              <v-icon class="rotate180 icon">padding</v-icon>
              <span class="setting-label">Bottom Padding</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.padding.bottom"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.padding.bottom }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Button</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="mt-4 mb-3">
            <v-col cols="5">
              <v-icon class="icon">palette</v-icon>
              <span class="setting-label">Color</span>
            </v-col>
            <v-col>
              <v-menu
                bottom
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.history.button.color.hex"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.history.button.color"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-0">
            <v-col cols="5">
              <v-icon class="icon">contrast</v-icon>
              <span class="setting-label">Dark Text</span>
            </v-col>
            <v-col>
              <v-checkbox
                class="mt-n1"
                v-model="editingComponent.history.button.dark"
                @change="handleChange()"
                color="primary"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Tables</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters class="editor-third-heading"
            >Tables Border & Alignment</v-row
          >

          <v-row class="mt-2 mb-3" no-gutters>
            <v-col cols="5">
              <v-icon class="icon">sync_alt</v-icon>
              <span class="setting-label">Align Content</span>
            </v-col>

            <v-icon
              @click="setHistoryTableContentHorizontal('start')"
              class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setHistoryTableContentHorizontal('center')"
              >align_horizontal_center</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setHistoryTableContentHorizontal('end')"
              >align_horizontal_right</v-icon
            >
          </v-row>

          <v-row no-gutters>
            <v-col cols="5">
              <v-icon class="icon">border_outer</v-icon>
              <span class="setting-label">Border</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.rowBorderSize"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="5"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.rowBorderSize }}px
            </v-col>
          </v-row>

          <v-row
            v-if="editingComponent.history.rowBorderSize > 0"
            no-gutters
            class="mt-n3 mb-4"
          >
            <v-col cols="5">
              <v-icon class="icon">palette</v-icon>
              <span class="setting-label">Border Color</span>
            </v-col>
            <v-col>
              <v-menu
                bottom
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          height="30px"
                          width="30px"
                          :color="editingComponent.history.rowBorderColor.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.history.rowBorderColor"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n2 mb-4">
            <v-col cols="5">
              <v-icon class="icon">palette</v-icon>
              <span class="setting-label">Hover Color</span>
            </v-col>
            <v-col>
              <v-menu
                bottom
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.history.rowHoverColor.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.history.rowHoverColor"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>

          <v-row no-gutters class="editor-third-heading mb-3">Headings</v-row>

          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-menu offset-y transition="slide-y-transition" z-index="3000">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-card
                        elevation="0"
                        class="pl-3"
                        width="175px"
                        height="24px"
                        color="grey lighten-3"
                      >
                        <span class="font-select-label">{{ fontLabel }}</span>
                        <v-icon class="float-right">arrow_drop_down</v-icon>
                      </v-card>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(font, index) in fonts"
                      :key="index + 'a'"
                      :style="'font-family: ' + font.class + ' !important;'"
                      @click="setHeadingFont(font)"
                      dense
                    >
                      {{ font.label }}
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-icon class="ml-2 icon-button" @click="setHeadingWeight()"
                  >format_bold</v-icon
                >
                <v-icon class="ml-2 icon-button" @click="setHeadingStyle()"
                  >format_italic</v-icon
                >

                <v-menu
                  bottom
                  nudge-bottom="25"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  max-width="300px"
                  z-index="3000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="ml-1 icon-button"
                      >format_color_text</v-icon
                    >
                  </template>

                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.history.content.heading.color"
                    z-index="2000"
                  ></v-color-picker>
                </v-menu>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-3 px-1">
            <v-col cols="5">
              <v-icon class="icon">format_size</v-icon>
              <span class="setting-label">Font Size</span>
            </v-col>
            <v-col>
              <v-slider
                v-model="editingComponent.history.content.heading.size"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="8"
                max="200"
                class="mt-n1 mr-2"
                color="primary"
              ></v-slider>
            </v-col>
            <v-col cols="auto" class="setting-label">
              <v-row no-gutters>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.history.content.heading.size--;
                    "
                    color="grey"
                    >remove_circle</v-icon
                  >
                </v-col>
                <v-col>
                  <v-sheet
                    color="transparent"
                    min-width="25px"
                    class="text-center"
                  >
                    {{ editingComponent.history.content.heading.size }}
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.history.content.heading.size++;
                    "
                    color="grey"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3 px-1">
            <v-col cols="5">
              <v-icon class="icon">format_line_spacing</v-icon>
              <span class="setting-label">Line Height</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.content.heading.lineHeight"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0.1"
              max="10"
              step="0.1"
              class="mt-n1 mr-2"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              <v-row no-gutters>
                <v-col>
                  <v-icon
                    @click="minusHeading('lineHeight', 0.1)"
                    size="11"
                    color="grey"
                    >remove_circle</v-icon
                  >
                </v-col>
                <v-col>
                  <v-sheet
                    color="transparent"
                    min-width="25px"
                    class="text-center"
                  >
                    {{ editingComponent.history.content.heading.lineHeight }}
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-icon
                    size="11"
                    @click="plusHeading('lineHeight', 0.1)"
                    color="grey"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="editor-third-heading mb-3">Data Text</v-row>

          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-menu offset-y transition="slide-y-transition" z-index="3000">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-card
                        elevation="0"
                        class="pl-3"
                        width="175px"
                        height="24px"
                        color="grey lighten-3"
                      >
                        <span class="font-select-label">{{
                          dataFontLabel
                        }}</span>
                        <v-icon class="float-right">arrow_drop_down</v-icon>
                      </v-card>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(font, index) in fonts"
                      :key="index + 'b'"
                      :style="'font-family: ' + font.class + ' !important;'"
                      @click="setDataFont(font)"
                      dense
                    >
                      {{ font.label }}
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-icon class="ml-2 icon-button" @click="setDataWeight()"
                  >format_bold</v-icon
                >
                <v-icon class="ml-2 icon-button" @click="setDataStyle()"
                  >format_italic</v-icon
                >

                <v-menu
                  bottom
                  nudge-bottom="25"
                  :close-on-content-click="false"
                  transition="slide-y-transition"
                  max-width="300px"
                  z-index="3000"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="ml-1 icon-button"
                      >format_color_text</v-icon
                    >
                  </template>

                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.history.content.data.color"
                    z-index="2000"
                  ></v-color-picker>
                </v-menu>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-3 px-1">
            <v-col cols="5">
              <v-icon class="icon">format_size</v-icon>
              <span class="setting-label">Font Size</span>
            </v-col>
            <v-col>
              <v-slider
                v-model="editingComponent.history.content.data.size"
                @input="handleChange()"
                :thumb-size="24"
                thumb-label
                min="8"
                max="200"
                class="mt-n1 mr-2"
                color="primary"
              ></v-slider>
            </v-col>
            <v-col cols="auto" class="setting-label">
              <v-row no-gutters>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.history.content.data.size--;
                    "
                    color="grey"
                    >remove_circle</v-icon
                  >
                </v-col>
                <v-col>
                  <v-sheet
                    color="transparent"
                    min-width="25px"
                    class="text-center"
                  >
                    {{ editingComponent.history.content.data.size }}
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-icon
                    size="11"
                    @click="
                      handleChange();
                      editingComponent.history.content.data.size++;
                    "
                    color="grey"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3 px-1">
            <v-col cols="5">
              <v-icon class="icon">format_line_spacing</v-icon>
              <span class="setting-label">Line Height</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.content.data.lineHeight"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0.1"
              max="10"
              step="0.1"
              class="mt-n1 mr-2"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              <v-row no-gutters>
                <v-col>
                  <v-icon
                    @click="minusData('lineHeight', 0.1)"
                    size="11"
                    color="grey"
                    >remove_circle</v-icon
                  >
                </v-col>
                <v-col>
                  <v-sheet
                    color="transparent"
                    min-width="25px"
                    class="text-center"
                  >
                    {{ editingComponent.history.content.data.lineHeight }}
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-icon
                    size="11"
                    @click="plusData('lineHeight', 0.1)"
                    color="grey"
                    >add_circle</v-icon
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters class="editor-third-heading mb-5 mt-0"
            >Detail Table</v-row
          >
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate270 icon">margin</v-icon>
              <span class="setting-label">Left Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.detail.margin.left"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.detail.margin.left }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="rotate icon">margin</v-icon>
              <span class="setting-label">Right Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.detail.margin.right"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.detail.margin.right }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">margin</v-icon>
              <span class="setting-label">Top Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.detail.margin.top"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.detail.margin.top }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-n3 mb-n6">
            <v-col cols="5">
              <v-icon class="rotate180 icon">margin</v-icon>
              <span class="setting-label">Bottom Margin</span>
            </v-col>
            <v-slider
              v-model="editingComponent.history.detail.margin.bottom"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="100"
              class="mt-n1"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.history.detail.margin.bottom }}
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-row no-gutters class="px-6 py-3">
        <v-col>
          <v-icon color="primary" class="mt-n1">table_view</v-icon>
          <span class="editor-heading ml-2">Mobile Menu</span>
        </v-col>
      </v-row>

      <v-row no-gutters class="px-6 py-3" justify="center">
        <v-btn v-if="siteDrawer" @click="handleSiteDrawer()" class="primary"
          ><v-icon left>menu_open</v-icon>Hide Mobile Menu</v-btn
        >
        <v-btn v-if="!siteDrawer" @click="handleSiteDrawer()" class="primary"
          ><v-icon left class="rotate180">menu_open</v-icon>Show Mobile
          Menu</v-btn
        >
      </v-row>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Logo</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row
            v-if="editingComponent.mobileLogo.image"
            no-gutters
            justify="center"
          >
            <v-card color="grey lighten-2" tile elevation="0">
              <v-img
                :src="editingComponent.mobileLogo.image"
                max-width="300px"
                max-height="300px"
                contain
                class="rounded outline"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-skeleton-loader
                      min-height="300px"
                      min-width="300px"
                      type="card"
                    >
                    </v-skeleton-loader>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-row>

          <v-row no-gutters class="mt-4" justify="center">
            <v-btn
              @click="handleGalleryDialog(true)"
              elevation="0"
              color="primary"
            >
              <v-icon left>image_search</v-icon>Gallery
            </v-btn>
            <v-btn
              @click="handleUploadsDialog(true)"
              elevation="0"
              color="primary"
            >
              <v-icon left>image_search</v-icon>Uploads
            </v-btn>
            <design-editor-gallery :saveTo="'mobileLogo'" />
            <design-editor-uploads :saveTo="'mobileLogo'" />
          </v-row>

          <v-row no-gutters class="mt-4">
            <v-col cols="4" class="setting-label">
              <span class="setting-label">Remove Logo</span>
            </v-col>
            <v-icon @click="clearMobileLogo()" class="icon-button"
              >hide_image</v-icon
            >
          </v-row>

          <v-row no-gutters class="mt-3">
            <v-col cols="4" class="setting-label">
              <span class="setting-label">Position</span>
            </v-col>
            <v-icon @click="setMobileLogoPlacement('top')" class="icon-button"
              >vertical_align_top</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setMobileLogoPlacement('bottom')"
              >vertical_align_bottom</v-icon
            >
          </v-row>

          <v-row no-gutters class="mt-3">
            <v-col cols="4" class="setting-label">
              <v-icon class="ml-1 icon-button">photo_size_select_large</v-icon>
              <span class="setting-label">Size</span>
            </v-col>
            <v-slider
              v-model="editingComponent.mobileLogo.height"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="20"
              max="400"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.mobileLogo.height }}
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">expand</v-icon>
              <span class="setting-label">Box Height</span>
            </v-col>
            <v-slider
              v-model="editingComponent.mobileLogo.boxHeight"
              @input="handleChange()"
              :thumb-size="24"
              thumb-label
              min="0"
              max="300"
              color="primary"
            ></v-slider>
            <v-col cols="auto" class="setting-label">
              {{ editingComponent.mobileLogo.boxHeight }}px
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-n3">
            <v-col cols="5">
              <v-icon class="icon">sync_alt</v-icon>
              <span class="setting-label">Alignment</span>
            </v-col>
            <v-icon
              @click="setMobileLogoHorizontal('start')"
              class="icon-button"
              >align_horizontal_left</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setMobileLogoHorizontal('center')"
              >align_horizontal_center</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setMobileLogoHorizontal('end')"
              >align_horizontal_right</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setMobileLogoVertical('start')"
              >align_vertical_top</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setMobileLogoVertical('center')"
              >align_vertical_center</v-icon
            >
            <v-icon
              class="ml-2 icon-button"
              @click="setMobileLogoVertical('end')"
              >align_vertical_bottom</v-icon
            >
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Color</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters align="center" class="mt-3">
            <v-col cols="4" class="setting-label"> Menu Background </v-col>
            <v-col>
              <v-menu
                bottom
                nudge-bottom="25"
                :close-on-content-click="false"
                transition="slide-y-transition"
                max-width="300px"
                z-index="3000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row no-gutters align="center">
                    <v-col cols="auto">
                      <v-img
                        class="rounded outline"
                        :src="require('@/assets/checkerboard.png')"
                        height="30px"
                        width="30px"
                      >
                        <v-card
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          height="30px"
                          width="30px"
                          :color="editingComponent.mobile.color.hexa"
                        >
                        </v-card>
                      </v-img>
                    </v-col>
                    <v-col class="setting-label ml-2">
                      {{ editingComponent.mobile.color.hexa }}
                    </v-col>
                  </v-row>
                </template>
                <v-card>
                  <v-color-picker
                    @input="handleChange()"
                    dot-size="25"
                    show-swatches
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingComponent.mobile.color"
                  ></v-color-picker>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="editor-subheading"
          >Button Labels</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-text-field
              name="Menu"
              label="Menu"
              v-model="editingComponent.mobileLabel.value"
              @input="handleChange()"
              readonly
            ></v-text-field>
            <v-btn
              v-if="editingField.field !== 'mobileLabel'"
              icon
              class="mt-4"
              @click="handleEditor('mobileLabel')"
            >
              <v-icon color="primary"> settings </v-icon>
            </v-btn>
            <v-btn
              v-if="editingField.field === 'mobileLabel'"
              icon
              class="mt-4"
              @click="clearFieldEditors"
            >
              <v-icon color="primary" class="rotate180">
                expand_circle_down
              </v-icon>
            </v-btn>
          </v-row>

          <design-heading-editor
            v-if="editingField.field === 'label'"
            @onClose="clearFieldEditors"
          ></design-heading-editor>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="end" class="mt-0 mr-2 mb-2">
      <design-save-button :small="true"></design-save-button>
    </v-row>
  </v-card>
</template>

<script>
import DesignEditorGallery from "../Gallery/Gallery.vue";
import DesignEditorUploads from "../Uploads/Uploads.vue";
export default {
  components: {
    DesignEditorGallery,
    DesignEditorUploads,
  },
  data() {
    return {
      fonts: [
        { label: "Arial", class: "Arial, sans-serif" },
        { label: "Bebas Neue", class: "Bebas Neue, sans-serif" },
        { label: "Caveat", class: "Caveat, sans-serif" },
        { label: "Cinzel", class: "Cinzel, serif" },
        { label: "Dancing Script", class: "Dancing Script, serif" },
        { label: "Lato", class: "Lato, sans-serif" },
        { label: "Lobster", class: "Lobster, sans-serif" },
        { label: "Merriweather", class: "Merriweather, serif" },
        { label: "Pacifico", class: "Pacifico, sans-serif" },
        { label: "Playfair Display", class: "Playfair Display, serif" },
        { label: "Quicksand", class: "Quicksand, sans-serif" },
        { label: "Raleway", class: "Raleway, sans-serif" },
        { label: "Roboto", class: "Roboto, sans-serif" },
        { label: "Sans Serif", class: "sans-serif" },
        { label: "Satisfy", class: "Satisfy, sans-serif" },
        { label: "Spectral", class: "Spectral, serif" },
        { label: "Work Sans", class: "Work Sans, sans-serif" },
      ],
      tooltipText:
        "Click the green edit icon to change the text styling.<br> The actual text displayed cannot be edited here.<br>Each card has its own text values, and this is<br>saved in the individual Leaderboard or Table settings.<br>The text shown here is a sample for design purposes.",
    };
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    siteDrawer() {
      return this.$store.getters.siteDrawer;
    },
    editingComponent() {
      return this.$store.getters.editingComponent || {};
    },
    editingField() {
      return this.$store.getters.editingField || {};
    },
    fontLabel() {
      const val = this.fonts.find(
        (el) => el.class === this.editingComponent.cart.content.heading.font
      );
      return val.label;
    },
    dataFontLabel() {
      const val = this.fonts.find(
        (el) => el.class === this.editingComponent.cart.content.data.font
      );
      return val.label;
    },
  },
  methods: {
    handleGalleryDialog(val) {
      this.$store.dispatch("setGalleryDialog", val);
    },
    handleUploadsDialog(val) {
      this.$store.dispatch("setUploadsDialog", val);
    },
    handleChange() {
      this.$store.dispatch("setHasWebThemePendingUpdates", true);
    },
    handleSiteDrawer() {
      if (this.siteDrawer === true) {
        this.$store.dispatch("setSiteDrawer", false);
      } else {
        this.$store.dispatch("setSiteDrawer", true);
      }
    },
    handleEditor(field) {
      this.clearFieldEditors();
      let selected = {};
      if (field === "label") {
        selected = this.editingComponent.label;
      } else if (field === "mobileLabel") {
        selected = this.editingComponent.mobileLabel;
      } else if (field === "cartTitle") {
        selected = this.editingComponent.cart.cartTitle;
      } else if (field === "subheading") {
        selected = this.editingComponent.cart.subheading;
      } else if (field === "points") {
        selected = this.editingComponent.cart.points;
      } else if (field === "subheadingHistory") {
        selected = this.editingComponent.history.subheading;
      } else if (field === "pointsHistory") {
        selected = this.editingComponent.history.points;
      } else if (field === "historyTitle") {
        selected = this.editingComponent.history.historyTitle;
      } else if (field === "dropdownLabel") {
        selected = this.editingComponent.dropdown.dropdownLabel;
      } else if (field === "tierLabel") {
        selected = this.editingComponent.tier.tierLabel;
      } else if (field === "stepperLabels") {
        selected = this.editingComponent.cart.stepperLabels;
      } else if (field === "stepperSummary") {
        selected = this.editingComponent.cart.stepperSummary;
      } else {
        return;
      }
      this.$store.dispatch("editingField", selected);
    },
    clearFieldEditors() {
      this.$store.dispatch("editingField", {});
    },
    setButtonsHorizontal(justify, group) {
      if (group === "left") {
        this.editingComponent.buttons.left.justify = justify;
      } else if (group === "right") {
        this.editingComponent.buttons.right.justify = justify;
      } else if (group === "group") {
        this.editingComponent.menuGroup.justify = justify;
      } else {
        return;
      }
      this.handleChange();
    },
    setMenuLogoPosition(val) {
      this.editingComponent.logo.align = val;
      this.handleChange();
    },
    setMobileLogoPlacement(val) {
      this.editingComponent.mobileLogo.placement = val;
      this.handleChange();
    },
    setMobileLogoHorizontal(val) {
      this.editingComponent.mobileLogo.justify = val;
      this.handleChange();
    },
    setMobileLogoVertical(val) {
      this.editingComponent.mobileLogo.align = val;
      this.handleChange();
    },
    clearLogo() {
      this.editingComponent.logo.image = "";
      this.editingComponent.logo.previewImage = "";
      this.handleChange();
    },
    clearMobileLogo() {
      this.editingComponent.mobileLogo.image = "";
      this.editingComponent.mobileLogo.previewImage = "";
      this.handleChange();
    },
    setDataWeight() {
      if (
        this.editingComponent.cart.content.data.weight === "normal" ||
        this.editingComponent.cart.content.data.weight === null ||
        this.editingComponent.cart.content.data.weight === ""
      ) {
        this.editingComponent.cart.content.data.weight = "bold";
      } else {
        this.editingComponent.cart.content.data.weight = "normal";
      }
      this.handleChange();
    },
    setDataStyle() {
      if (
        this.editingComponent.cart.content.data.style === "normal" ||
        this.editingComponent.cart.content.data.style === null ||
        this.editingComponent.cart.content.data.style === ""
      ) {
        this.editingComponent.cart.content.data.style = "italic";
      } else {
        this.editingComponent.cart.content.data.style = "normal";
      }
      this.handleChange();
    },
    setHeadingWeight() {
      if (
        this.editingComponent.cart.content.heading.weight === "normal" ||
        this.editingComponent.cart.content.heading.weight === null ||
        this.editingComponent.cart.content.heading.weight === ""
      ) {
        this.editingComponent.cart.content.heading.weight = "bold";
      } else {
        this.editingComponent.cart.content.heading.weight = "normal";
      }
      this.handleChange();
    },
    setHeadingStyle() {
      if (
        this.editingComponent.cart.content.heading.style === "normal" ||
        this.editingComponent.cart.content.heading.style === null ||
        this.editingComponent.cart.content.heading.style === ""
      ) {
        this.editingComponent.cart.content.heading.style = "italic";
      } else {
        this.editingComponent.cart.content.heading.style = "normal";
      }
      this.handleChange();
    },
    plusHeading(field, val) {
      if (field === "lineHeight") {
        this.editingComponent.cart.content.heading.lineHeight =
          this.editingComponent.cart.content.heading.lineHeight + val;
        this.handleChange();
      } else {
        return;
      }
    },
    minusHeading(field, val) {
      if (field === "lineHeight") {
        this.editingComponent.cart.content.heading.lineHeight =
          this.editingComponent.cart.content.heading.lineHeight - val;
        this.handleChange();
      } else {
        return;
      }
    },
    plusData(field, val) {
      if (field === "lineHeight") {
        this.editingComponent.cart.content.data.lineHeight =
          this.editingComponent.cart.content.data.lineHeight + val;
        this.handleChange();
      } else {
        return;
      }
    },
    minusData(field, val) {
      if (field === "lineHeight") {
        this.editingComponent.cart.content.data.lineHeight =
          this.editingComponent.cart.content.data.lineHeight - val;
        this.handleChange();
      } else {
        return;
      }
    },
    setHeadingFont(val) {
      this.editingComponent.cart.content.heading.font = val.class;
      this.handleChange();
    },
    setDataFont(val) {
      this.editingComponent.cart.content.data.font = val.class;
      this.handleChange();
    },
    setHorizontal(val) {
      this.editingComponent.card.icon.justify = val;
      this.handleChange();
    },
    setVertical(val) {
      this.editingComponent.card.icon.align = val;
      this.handleChange();
    },
    setCartIconHorizontal(val) {
      this.editingComponent.cart.icon.justify = val;
      this.handleChange();
    },
    setCartIconVertical(val) {
      this.editingComponent.cart.icon.align = val;
      this.handleChange();
    },
    setCardIconPosition(val) {
      this.editingComponent.card.icon.placement = val;
      this.handleChange();
    },
    setCartIconPosition(val) {
      this.editingComponent.cart.icon.placement = val;
      this.handleChange();
    },
    setTableContentHorizontal(val) {
      this.editingComponent.cart.content.align = val;
      this.handleChange();
    },
    setHistoryTableContentHorizontal(val) {
      this.editingComponent.history.content.align = val;
      this.handleChange();
    },
  },
};
</script>

<style></style>

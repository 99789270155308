/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  logos: [],
};

const actions = {
  async loadLogos({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("logos")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const logos = [];
    querySnapshot.forEach(function (doc) {
      logos.push({
        id: doc.id,
        smallUrl: doc.data().smallUrl,
        mediumUrl: doc.data().mediumUrl,
        largeUrl: doc.data().largeUrl,
        smallStoragePath: doc.data().smallStoragePath,
        mediumStoragePath: doc.data().mediumStoragePath,
        largeStoragePath: doc.data().largeStoragePath,
        category: doc.data().category,
      });
    });
    commit("setLogos", logos);
    commit("setLoadingTable", false);
  },

  async createLogoUpload({ commit, getters, dispatch }, payload) {
    var logosRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("logos")
      .doc();

    const logo = {
      id: logosRef.id,
      name: payload.name,
      smallUrl: payload.smallUrl,
      mediumUrl: payload.mediumUrl,
      largeUrl: payload.largeUrl,
      smallStoragePath: payload.smallStoragePath,
      mediumStoragePath: payload.mediumStoragePath,
      largeStoragePath: payload.largeStoragePath,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };
    logosRef.set(logo);
    commit("createLogoUpload", logo);
    dispatch("setLoading", false);
    commit("setSnackbar", "Image added");
    return "ok";
  },

  async deleteLogoUpload({ commit, getters }, payload) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("logos")
        .doc(payload.id)
        .delete();
    } catch (e) {
      throw "error when deleting an logo";
    }
    commit("deleteLogoUpload", payload.id);
    commit("setSnackbar", "Logo deleted");
  },

  // Note: This is only updating the store, not persisting with db
  patchCurrentLogos({ commit }, payload) {
    commit("patchCurrentLogos", payload);
  },
};

const mutations = {
  setLogos(state, payload) {
    state.logos = payload;
  },

  createLogoUpload(state, payload) {
    state.logos = [...state.logos, payload];
  },

  updateLogoUpload(state, payload) {
    state.logos = state.logos.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  patchCurrentLogos(state, payload) {
    state.currentLogos = {
      ...state.currentLogos,
      ...payload,
    };

    state.pendingLogosUpdates = {
      ...state.pendingLogosUpdates,
      ...payload,
    };
  },
  hasLogosPendingUpdates(state) {
    return Object.keys(state.pendingLogosUpdates).length !== 0;
  },

  deleteLogoUpload(state, payload) {
    state.logos = state.logos.filter((item) => item.id !== payload);
  },
};

const getters = {
  logos(state) {
    return state.logos;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <v-col cols="4">
      <v-select
        :items="availableStatus"
        v-model="status"
        item-text="label"
        item-value="value"
        label="Status"
        dense
      />
      <v-text-field
        label="Title"
        type="text"
        name="title"
        v-model="title"
      />
      <v-text-field
        label="Display Title"
        name="displayTitle"
        v-model="displayTitle"
      />
      <v-text-field
        label="Created"
        type="text"
        readonly
        :value="created | date"
      />
      <v-text-field
        :value="updated | date"
        label="Updated at"
        type="text"
        readonly
      />
      <v-select
        :items="memberTags"
        v-model="selectedMemberTags"
        item-text="tag"
        item-value="id"
        label="Member Tags"
        chips
        multiple
        deletable-chips
        small-chips
      />
      <v-select
        :items="companyTags"
        v-model="selectedCompanyTags"
        item-text="tag"
        item-value="id"
        label="Company Tags"
        chips
        multiple
        deletable-chips
        small-chips
      />
      <v-text-field
        label="Display Order"
        type="number"
        name="order"
        v-model="order"
      />
      <!--<v-checkbox label="Enable transaction view" v-model="transactionView"></v-checkbox>-->
      <v-btn
        color="primary"
        @click="openDeleteDialog = true"
        :loading="isProcessing"
        elevation="0"
      > Delete Table</v-btn>
    </v-col>

    <delete-table 
      :open="openDeleteDialog"
      :title="title"
      @onClose="closeDelete"
    />

  </div>
</template>

<script>
import DeleteTable from './Delete.vue'

export default {
  components: {
    DeleteTable
  },
  data: function () {
    return {
      isProcessing: false,
      openDeleteDialog: false,
      availableStatus: [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
        { label: 'Archive', value: 'Archive' }
      ],
    };
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    programId() {
      return this.$store.getters.programId;
    },
    companyTags () {
      return this.$store.state.companytag.companyTags;
    },
    memberTags () {
      return this.$store.state.membertag.memberTags;
    },
    currentTable() {
      return this.$store.state.table.currentTable;
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    title: {
      get() {
        return this.currentTable.title;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { title: value });
      }
    },
    transactionView: {
      get() {
        return this.currentTable.transactionView;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { transactionView: value });
      }
    },
    createdBy() {
      return this.currentTable.createdBy;
    },
    displayTitle: {
      get() {
        return this.currentTable.displayTitle;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { displayTitle: value });
      }
    },
    created() {
      return this.currentTable.created;
    },
    status: {
      get() {
        return this.currentTable.status;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { status: value });
      }
    },
    order: {
      get() {
        return this.currentTable.order;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { order: Number(value) });
      }
    },
    updatedBy() {
      return this.currentTable.updatedBy;
    },
    selectedMemberTags: {
      get() {
        return this.currentTable.memberTags;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { memberTags: value });
      }
    },
    updated() {
      return this.currentTable.updated;
    },
    selectedCompanyTags: {
      get() {
        return this.currentTable.companyTags;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { companyTags: value });
      }
    },
  },
  methods: {
    handleDelete() {
      this.isProcessing = true;
      this.$store.dispatch('deleteTable')
        .then(() => {
          this.isProcessing = false;
          this.$router.push(`/tables/${this.programId}`);
        })
        .catch(() => {
        });
    },
    closeDelete() {
      this.openDeleteDialog = false
    }
  },
}
</script>

<style scoped>

</style>

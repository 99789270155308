/* eslint-disable no-console */
import { db } from "@/firebase";
import { ROLES_DICT } from "@/constants/roles";
import { REPLY_TO_EMAIL } from "@/constants/email";

const state = {
  programs: [],
  programsQuickList: [],
  programThemes: [],
  currentProgram: {},
  programId: "",
  admins: [],
  prgTitleExists: false,
  prgUrlExists: false,
  newProgramDialog: false,
  isIncentableAdmin: false,
  adminIsExist: false,
  setupMode: null,
  bookDemo: false,
  bookTrialConsultation: false,
  showPrograms: true,
  loadingProgram: false,
};

const actions = {
  async updateCurrentProgram({ commit, getters, dispatch }, payload) {
    const programRef = db.collection("programs").doc(getters.programId);

    try {
      await programRef.update(payload);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating program.";
    }
    commit("patchCurrentProgram", payload);
    dispatch("setSnackbar", "Program Updated");
  },

  clearCurrentProgram({ commit }) {
    commit("setProgramId", "");
    commit("setCurrentProgram", {});
  },

  async loadCurrentProgram({ commit }, id) {
    let programSnapshot;
    try {
      programSnapshot = await db.collection("programs").doc(id).get();
    } catch (e) {
      commit("setProgramId", "");
      commit("setCurrentProgram", {});
      throw 500;
    }

    const programData = programSnapshot.data();
    const currentProgram = {
      ...programData,
      createdAt: programData.createdAt.toDate(),
    };

    if (!currentProgram) {
      commit("setProgramId", "");
      commit("setCurrentProgram", {});
      throw 404;
    }

    commit("setProgramId", id);
    commit("setCurrentProgram", currentProgram);
  },

  async loadPrograms({ commit, getters, dispatch }) {
    console.log("loadPrograms run");
    commit("setPrograms", []);
    commit("setLoadingCards", true);
    commit("setIncentableAdmin", false);
    try {
      await dispatch("loadAdmins");
      const user = getters.user;
      const incentableAdminSnapshot = await db
        .collection("incentableAdmins")
        .where("userId", "==", user.id)
        .get();

      if (incentableAdminSnapshot.size > 0) {
        // user id IncentableAdmin
        commit("setIncentableAdmin", true);
      }
      const programs = [];
      // check current use is Admin/Editor/IncentableAdmin
      if (getters.isIncentableAdmin) {
        const querySnapshot = await db
          .collection("programs")
          .orderBy("titleUppercase")
          .get();
        querySnapshot.forEach(function (doc) {
          programs.push({
            id: doc.id,
            title: doc.data().title,
            status: doc.data().status,
            iosAppStatus: doc.data().iosAppStatus,
            androidAppStatus: doc.data().androidAppStatus,
            titleUppercase: doc.data().titleUppercase,
            // convert timestamp to date object
            createdAt: doc.data().createdAt.toDate(),
            createdBy: doc.data().createdBy,
            registrationOpen: doc.data().registrationOpen,
            // webTheme: doc.data().webTheme,
          });
        });
      } else {
        const linkedPrgIds = getters.currentAdmin.programs || [];
        const requests = linkedPrgIds.map((programId) => {
          return db.collection("programs").doc(programId).get();
        });
        const querySnapshot = await Promise.all(requests);
        querySnapshot.forEach(function (doc) {
          programs.push({
            id: doc.id,
            title: doc.data().title,
            status: doc.data().status,
            iosAppStatus: doc.data().iosAppStatus,
            androidAppStatus: doc.data().androidAppStatus,
            titleUppercase: doc.data().titleUppercase,
            // convert timestamp to date object
            createdAt: doc.data().createdAt.toDate(),
            createdBy: doc.data().createdBy,
            registrationOpen: doc.data().registrationOpen,
            // webTheme: doc.data().webTheme,
          });
        });
      }
      commit("setPrograms", programs);
      commit("setLoadingCards", false);
    } catch (error) {
      console.log(error);
      commit("setLoadingCards", false);
    }
  },

  async loadProgramsQuickList({ commit }) {
    commit("setProgramsQuickList", []);
    try {
      const programs = [];
      const querySnapshot = await db
        .collection("programs")
        .orderBy("titleUppercase")
        .get();
      querySnapshot.forEach(function (doc) {
        programs.push({
          id: doc.id,
          title: doc.data().title,
          titleUppercase: doc.data().titleUppercase,
        });
      });
      commit("setProgramsQuickList", programs);
    } catch (error) {
      console.log(error);
    }
  },

  async loadProgramThemes({ commit }) {
    console.log("loading program themes");
    commit("setProgramThemes", []);
    try {
      const programs = [];
      const querySnapshot = await db
        .collection("programs")
        .orderBy("titleUppercase")
        .where("status", "==", "Theme")
        .get();
      querySnapshot.forEach(function (doc) {
        programs.push({
          id: doc.id,
          status: doc.data().status,
          title: doc.data().title,
          titleUppercase: doc.data().titleUppercase,
          webTheme: doc.data().webTheme, // added to test, removed below
          // webTheme: {
          //   previewImage: doc.data().webTheme.previewImage,
          //   title: doc.data().webTheme.title,
          //   tags: doc.data().webTheme.tags,
          // },
        });
      });
      // console.log(programs);
      commit("setProgramThemes", programs);
    } catch (error) {
      console.log(error);
    }
  },

  async loadAdmins({ commit }) {
    commit("setIncentableAdmin", false);
    try {
      const querySnapshot = await db.collection("admins").get();
      const admins = [];
      querySnapshot.forEach(function (doc) {
        // user is admin
        admins.push({
          userId: doc.data().userId,
          email: doc.data().email,
          name: doc.data().name,
          programs: doc.data().programs || [],
          organisations: doc.data().organisations || [],
          customerToken: doc.data().customerToken,
        });
      });
      commit("setAdmins", admins);
    } catch (error) {
      console.log(error);
    }
  },

  async updateNotificationSettings({ commit, getters }, payload) {
    const programRef = db.collection("programs").doc(getters.programId);

    const notificationSettingsUpdate = {};

    Object.keys(payload).forEach((updateKey) => {
      notificationSettingsUpdate[`notificationSettings.${updateKey}`] =
        payload[updateKey];
    });

    try {
      await programRef.update(notificationSettingsUpdate);
    } catch (e) {
      throw "updating notification settings failed";
    }

    const newPrograms = getters.programs.map((program) => {
      if (program.id === getters.programId) {
        const exSettings = program.notificationSettings;
        const newSettings = {
          ...exSettings,
          ...payload,
        };
        return {
          ...program,
          notificationSettings: newSettings,
        };
      }
      return program;
    });
    const newReplyEmail = payload.replyEmail;
    if (newReplyEmail) {
      commit("setReplyEmail", newReplyEmail);
    } else {
      commit("setReplyEmail", REPLY_TO_EMAIL);
    }
    commit("setPrograms", newPrograms);
    commit("setSnackbar", {
      status: true,
      text: "Notification settings updated.",
    });
  },

  async addAdmin({ state, commit, getters, dispatch }, payload) {
    // TODO: fetch current program and compare with stored program
    // If there is difference in admins field, do not allow any kind of save
    // But notify user that he needs to refresh the browser.

    const storedAdmins = state.currentProgram.admins || [];
    const dupAdmin = storedAdmins.find(
      (item) => item.adminEmail === payload.adminEmail
    );
    if (dupAdmin) {
      throw "Admin already exists.";
    }

    const newAdmins = [...storedAdmins, payload];
    try {
      await db.collection("programs").doc(getters.programId).update({
        admins: newAdmins,
        updatedBy: getters.user.id,
      });
    } catch (e) {
      console.error(e);
      throw "Error when updating admins list.";
    }

    commit("patchCurrentProgram", { admins: newAdmins });
    dispatch("setSnackbar", "Admin added.");
  },

  async updateAdmin({ state, commit, getters, dispatch }, payload) {
    // TODO: fetch current program and compare with stored program
    // If there is difference in admins field, do not allow any kind of save
    // But notify user that he needs to refresh the browser.

    const storedAdmins = state.currentProgram.admins || [];

    const newAdmins = storedAdmins.map((storedAdmin) => {
      if (storedAdmin.adminEmail === payload.adminEmail) {
        return payload;
      }
      return storedAdmin;
    });

    try {
      await db.collection("programs").doc(getters.programId).update({
        admins: newAdmins,
        updatedBy: getters.user.id,
      });
    } catch (e) {
      console.error(e);
      throw "Error when updating admins list.";
    }

    commit("patchCurrentProgram", { admins: newAdmins });
    dispatch("setSnackbar", "Admin updated.");
  },

  async deleteAdminFromProgram({ state, commit, getters, dispatch }, email) {
    // TODO: fetch current program and compare with stored program
    // If there is difference in admins field, do not allow any kind of save
    // But notify user that he needs to refresh the browser.

    const storedAdmins = state.currentProgram.admins || [];

    const newAdmins = storedAdmins.filter((storedAdmin) => {
      return storedAdmin.adminEmail !== email;
    });

    try {
      await db.collection("programs").doc(getters.programId).update({
        admins: newAdmins,
        updatedBy: getters.user.id,
      });
    } catch (e) {
      console.error(e);
      throw "Error when updating admins list.";
    }

    commit("patchCurrentProgram", { admins: newAdmins });
    dispatch("setSnackbar", "Admin deleted.");
  },

  deleteProgram({ commit }, id) {
    commit("setLoading", true);
    db.collection("programs").doc(id).delete();
    commit("setLoading", false);
  },

  selectProgram({ commit }, programId) {
    commit("setProgramId", programId);
  },

  setPrgTitleExists({ commit }, payload) {
    commit("setPrgTitleExists", payload);
  },

  setReplyEmail({ commit }, payload) {
    commit("setReplyEmail", payload);
  },

  setPrgUrlExists({ commit }, payload) {
    commit("setPrgUrlExists", payload);
  },

  setSetupMode({ commit }, payload) {
    commit("setSetupMode", payload);
  },

  setBookDemo({ commit }, payload) {
    commit("setBookDemo", payload);
  },

  setBookTrialConsultation({ commit }, payload) {
    commit("setBookTrialConsultation", payload);
  },

  setShowPrograms({ commit }, payload) {
    commit("setShowPrograms", payload);
  },

  setNewProgramDialog({ commit }, payload) {
    commit("setNewProgramDialog", payload);
  },

  setIncentableAdmin({ commit }, payload) {
    commit("setIncentableAdmin", payload);
  },

  setAdminIsExist({ commit }, payload) {
    commit("setAdminIsExist", payload);
  },

  setAdmins({ commit }, payload) {
    commit("setAdmins", payload);
  },
  setLoadingProgram({ commit }, payload) {
    commit("setLoadingProgram", payload);
  },
};

const mutations = {
  setPrograms(state, payload) {
    state.programs = payload;
  },

  setProgramsQuickList(state, payload) {
    state.programsQuickList = payload;
  },

  setProgramThemes(state, payload) {
    state.programThemes = payload;
  },

  setDemoPrograms(state, payload) {
    state.demoPrograms = payload;
  },

  setAdmins(state, payload) {
    state.admins = payload;
  },

  createProgram(state, payload) {
    state.programs.push(payload);
  },

  setProgramId(state, id) {
    state.programId = id;
  },

  setSetupMode(state, val) {
    state.setupMode = val;
  },

  setBookDemo(state, val) {
    state.bookDemo = val;
  },

  setBookTrialConsultation(state, val) {
    state.bookTrialConsultation = val;
  },

  setShowPrograms(state, val) {
    state.showPrograms = val;
  },

  setReplyEmail(state, payload) {
    state.replyEmail = payload;
  },

  setCurrentProgram(state, program) {
    state.currentProgram = program;
  },

  patchCurrentProgram(state, payload) {
    state.currentProgram = {
      ...state.currentProgram,
      ...payload,
    };
  },

  setPrgTitleExists(state, payload) {
    state.prgTitleExists = payload;
  },

  setPrgUrlExists(state, payload) {
    state.prgUrlExists = payload;
  },

  setNewProgramDialog(state, payload) {
    state.newProgramDialog = payload;
  },

  setIncentableAdmin(state, payload) {
    state.isIncentableAdmin = payload;
  },

  setAdminIsExist(state, payload) {
    state.adminIsExist = payload;
  },

  setLoadingProgram(state, payload) {
    state.loadingProgram = payload;
  },
};

const getters = {
  programs(state) {
    return state.programs;
  },

  programsQuickList(state) {
    return state.programsQuickList;
  },

  programThemes(state) {
    return state.programThemes;
  },

  demoPrograms(state) {
    return state.demoPrograms;
  },

  programId(state) {
    return state.programId;
  },

  setupMode(state) {
    return state.setupMode;
  },

  bookDemo(state) {
    return state.bookDemo;
  },

  bookTrialConsultation(state) {
    return state.bookTrialConsultation;
  },

  showPrograms(state) {
    return state.showPrograms;
  },

  replyEmail(state) {
    return state.replyEmail;
  },

  currentProgram(state) {
    return state.currentProgram || {};
  },

  currentUserRole(state, getters) {
    if (state.isIncentableAdmin) {
      return ROLES_DICT.super;
    }

    const admins = state.currentProgram.admins;
    const userEmail = getters.email;
    const adminDetail = admins.find((admin) => admin.adminEmail === userEmail);

    if (!adminDetail) {
      return null;
    }

    return adminDetail.adminRole;
  },

  prgTitleExists(state) {
    return state.prgTitleExists;
  },

  prgUrlExists(state) {
    return state.prgUrlExists;
  },

  newProgramDialog(state) {
    return state.newProgramDialog;
  },

  isIncentableAdmin(state) {
    return state.isIncentableAdmin;
  },

  admins(state) {
    return state.admins;
  },

  adminIsExist(state) {
    return state.adminIsExist;
  },

  currentAdmin(state, getters) {
    const admins = state.admins;
    const email = getters.email;
    return admins.find((admin) => admin.email === email) || {};
  },

  loadingProgram(state) {
    return state.loadingProgram;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-dialog v-model="open" max-width="900px" persistent>
    <v-card>
      <form data-vv-scope="subscription">
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="90" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>

      <v-row justify="center" no-gutters class="page-heading">
        {{ subscriptionId ? '' : 'Add New '}}
        {{ formTitle }}
      </v-row>      
        
      <v-row class="pl-15 mt-6 mb-0" no-gutters align="center">
        
        <v-col>
          <v-row no-gutters>
            <status-icon :status="status"></status-icon>
            <span class="status ml-1">{{ status }}</span>
          </v-row>
        </v-col>
        
        <v-col v-if="status === 'active'">
          <v-row v-if="cancel_at_period_end === false" no-gutters class="my-4 mr-16" justify="end">
            <v-icon color="green" class="mr-1">autorenew</v-icon>
            <span class="status ml-1">Auto renews {{ current_period_end | date }}</span> 
          </v-row>

          <v-row v-else no-gutters class="my-4 mr-16" justify="end">
            <v-icon color="red">cancel</v-icon>
            <span class="status ml-1">Ends {{ current_period_end | date }}</span> 
          </v-row>
        </v-col>
      
      </v-row>

      <v-card-text class="px-16">
        
        <v-row no-gutters>
          <v-col cols="7">     
            
            <v-row no-gutters>
              <v-col cols="4">
                Plan:
              </v-col>
              <v-col class="value">
                {{ plan }}
              </v-col>
            </v-row>      
            
            <v-row no-gutters>
              <v-col cols="4">
                Billed:
              </v-col>
              <v-col class="value">
                {{ frequency }}
              </v-col>
            </v-row>      
            
            <v-row no-gutters>
              <v-col cols="4">
                Program:
              </v-col>
              <v-col class="value">
                {{ programName(program) }}
              </v-col>
            </v-row>      
            
            <v-row no-gutters>
              <v-col cols="4">
                Created By:
              </v-col>
              <v-col class="value">
                {{ adminName(createdBy) }}
              </v-col>
            </v-row>      
            
            <v-row no-gutters>
              <v-col cols="4">
                Created:
              </v-col>
              <v-col class="value">
                {{ created | date }}
              </v-col>
            </v-row>
            
            <v-row no-gutters class="mt-4">
               <v-col cols="4" class="capitalize">
                current period starts:
              </v-col>
              <v-col class="value">
                {{ current_period_start | date  }}
              </v-col>
            </v-row>

            <v-row no-gutters>
               <v-col cols="4" class="capitalize">
                current period ends:
              </v-col>
              <v-col class="value">
                {{ current_period_end | date  }}
              </v-col>
            </v-row>            
            
            <v-row no-gutters v-if="canceled_at" class="mt-3">
               <v-col cols="4" class="capitalize primary--text">
                canceled at:
              </v-col>
              <v-col class="value primary--text">
                {{ canceled_at | date }}
              </v-col>
            </v-row>
            
            <v-row no-gutters v-if="ended_at">
               <v-col cols="4" class="capitalize primary--text">
                Ends at:
              </v-col>
              <v-col class="value primary--text">
                {{ ended_at | date }}
              </v-col>
            </v-row>
 
          </v-col>
          
          <v-col>     
            
            <v-row no-gutters>
              <v-col cols="3">
                <strong>Items</strong>
              </v-col>
            </v-row>      
            
            <v-row no-gutters class="mt-3">
              <v-col>
                <v-card elevation="0" outlined>
                  <items-table :items="items" />
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3 mr-4" justify="end">
                <strong class="mr-6">Total :</strong>
                <strong>${{ total | number }}</strong>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="isIncentableAdmin" no-gutters class="mt-4">
          <v-col cols="auto" class="capitalize mr-2 label">
            View in Stripe:
          </v-col>
          <v-col>
            <a :href="stripeLink" target="_blank">{{ stripeLink }}</a>
          </v-col>
        </v-row>      
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="primary"
          @click="handleClose()"
          >Close</v-btn>
        <v-btn
          v-if="isIncentableAdmin" 
          :class="orgTheme.buttonTextColor"
          @click="saveSubscription('subscription')"
          color="primary"
          :loading="loading"
          elevation="0"
          disabled
          >Save</v-btn>
      </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import ItemsTable from './ItemsTable.vue'
export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    ItemsTable
  },
  props: {
    subscriptionId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    }
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data () {
    return {
      dictionary: {
        custom: {
        }
      },
      formTitle: 'Subscription',
      formIcon: 'paid',
      status: '',
      program: '',
      createdBy: '',
      cancel_at_period_end: '',
      canceled_at: '',
      created: '',
      current_period_end: '',
      current_period_start: '',
      ended_at: '',
      items: '',
      quantity: '',
      role: '',
      plan: '',
      interval: '',
      interval_count: '',
      stripeLink: '',
      trial_end: '',
      trial_start: '',
      total: 0,
      showAlert: false,
    }
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    subscriptions () {
      return this.$store.state.plans.subscriptions
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    admins () {
        return this.$store.getters.admins
    },
    programs () {
        return this.$store.getters.programsQuickList
    },
    isIncentableAdmin () {
      return this.$store.getters.isIncentableAdmin
    },
    frequency () {
      if (this.interval_count === '1' && this.interval === 'month') {
        return 'Monthly'
      }
      else if (this.interval_count === '3' && this.interval === 'month') {
        return 'Quarterly'
      }
      else if (this.interval_count === '1' && this.interval === 'year') {
        return 'Annually'
      }
      return ''
    }
  },
  methods:{
    adminName(id) {
        const admin = this.admins.find((el) => el.userId === id)
        if (admin) {
            return admin.name
        }
        return '** Admin deleted **'
    },
    programName(id) {
        const program = this.programs.find((el) => el.id === id)
        if (program) {
            return program.title
        }
        return '** Program deleted **'
    },
    clear () {
      this.showAlert = false;
      this.$validator.reset();
    },
    handleClose () {
      this.clear()
      this.$emit('onClose');
    },
    saveSubscription (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        this.$store.dispatch('setLoading', true)
        const payload = {
        };
        if (this.subscriptionId) {
          // Update Subscription
          payload.id = this.subscriptionId;
          this.$store.dispatch('updateSubscription', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create Subscription
          this.$store.dispatch('createSubscription', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        }
      });
    },
  },
  watch: {
    subscriptionId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedSubscription = this.subscriptions.find(item => item.id === newVal);
          const {
            status, program, createdBy, cancel_at, cancel_at_period_end, canceled_at, created, plan, interval, interval_count,
            current_period_end, current_period_start, ended_at, items, quantity, role, stripeLink,
            trial_end, trial_start, total
          } = selectedSubscription;
          this.status = status
          this.plan = plan
          this.interval = interval
          this.interval_count = interval_count
          this.program = program
          this.createdBy = createdBy
          this.cancel_at = cancel_at
          this.cancel_at_period_end = cancel_at_period_end
          this.canceled_at = canceled_at
          this.created = created
          this.current_period_end = current_period_end
          this.current_period_start = current_period_start
          this.ended_at = ended_at
          this.items = items
          this.total = total
          this.quantity = quantity
          this.role = role
          this.stripeLink = stripeLink
          this.trial_end = trial_end
          this.trial_start = trial_start
  }
      },
    },
  },
}
</script>

<style scoped>
.capitalize {
  text-transform: capitalize; 
}
.status {
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif !important;
  font-size: 17px;
  font-weight: 500 !important;
  color:#4d4d4d !important;
}
.label {
  font-size: 13px;
  font-weight: 300;
}
.value {
  font-size: 14px;
  font-weight: 500;
  color: #4E4E4E !important;
}
</style>
<template>
  <div class="ma-3">
    <v-card elevation="0">
      <v-row no-gutters align="center">
        <v-col sm="3">
          <v-text-field
            v-model="search"
            solo-inverted
            dense
            flat
            hide-details
            label="Search"
            prepend-inner-icon="search"
            class="mr-4"
          ></v-text-field>
        </v-col>

        <v-col sm="3" v-if="options.filter">
          <v-select
            solo-inverted
            dense
            flat
            hide-details
            label="Filter"
            prepend-inner-icon="filter_list"
            class="mr-4"
          ></v-select>
        </v-col>

        <v-col>
          <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
        </v-col>

        <v-col sm="auto">
          <v-btn
            @click="openAddOrEditDialog()"
            color="primary"
            elevation="0"
            class="mt-n2 mr-6"
            :disabled="processingOffer"
          >
            <v-icon>add</v-icon>new
          </v-btn>
        </v-col>
      </v-row>

      <!-- Data table -->

      <v-data-table
        class="pr-6 pt-4"
        :headers="headers"
        :items="opportunities"
        :search="search"
        :options.sync="options"
        :loading="loadingTable"
        no-data-text="No Opportunities. Click new to create one..."
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left close-on-content-click>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor')"
                @click="openAddOrEditDialog(item)"
              >
                <v-list-item-title>Edit </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor')"
                @click="openDeleteDialog(item)"
              >
                <v-list-item-title>Delete </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.dueDate="{ item }">
          {{ formatDate(item.deadline) }}
        </template>

        <template v-slot:item.redeemable="{ item }">
          <v-row justify="center">
            <v-checkbox v-model="item.redeemable" readonly></v-checkbox>
          </v-row>
        </template>

        <template v-slot:item.databucket="{ item }">
          {{ getDatabucketTitle(item.databucket) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="400px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Are you sure?</span>
        </v-card-title>
        <v-card-text>
          <v-row row>
            <v-col xs3>
              <div class="text-xs-center">
                <v-icon color="primary" class="mega-icon">error_outline</v-icon>
              </div>
            </v-col>
            <v-col xs9>
              Please confirm you wish to delete this opportunity. This cannot be
              undone.
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4 pr-4">
          <v-spacer></v-spacer>
          <v-btn @click="delDialog = false" flat>Cancel</v-btn>
          <v-btn
            color="primary"
            @click="handleDeleteResource()"
            :disabled="isProcessing"
            :loading="isProcessing"
          >
            Delete Opportunity<v-icon right>delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <opportunity-add-or-edit
      :selectedItem="selectedItem"
      :addOrEditDialogOpen="addOrEditDialogOpen"
      @onCloseAddOrEditDialog="closeAddOrEditDialog"
      @onHandleSave="handleSave"
    ></opportunity-add-or-edit>

    <opportunity-confirm-delete
      :selectedItem="selectedItem"
      :deleteDialogOpen="deleteDialogOpen"
      @onCloseDeleteDialog="closeDeleteDialog"
      @onHandleDelete="handleDeleteOpportunity"
    ></opportunity-confirm-delete>
  </div>
</template>

<script>
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import OpportunityAddOrEdit from "./OpportunityAddOrEdit.vue";
import OpportunityConfirmDelete from "./OpportunityConfirmDelete.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    OpportunityAddOrEdit,
    OpportunityConfirmDelete,
  },
  data: () => ({
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    headers: [
      { text: "Description", value: "description", align: "left" },
      { text: "Due date", value: "dueDate", align: "right" },
      {
        text: "Points Value",
        value: "points",
        align: "right",
        sortable: false,
      },
      { text: "Redeemable Points", value: "redeemable", align: "center" },
      { text: "Data Bucket", value: "databucket", align: "left" },
      { text: "Data Bucket Category", value: "targetCode", align: "left" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    pagination: {
      sortBy: "created",
      descending: true,
      rowsPerPage: 5,
    },
    isProcessing: false,
    addOrEditDialogOpen: false,
    deleteDialogOpen: false,
    selectedItem: {
      redeemable: false,
    },
    search: "",
    formIcon: "edit",

    // Table settings
    options: {
      sortBy: ["title"],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: true,
      download: true,
      more: false,
    },
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    processingOffer() {
      return this.$store.getters.processingOffer;
    },
    opportunities: {
      get() {
        return (
          this.currentOffer.opportunities.map((opportunity) => {
            return {
              ...opportunity,
              redeemable:
                opportunity.redeemable !== undefined
                  ? opportunity.redeemable
                  : false,
            };
          }) || []
        );
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", {
          opportunities: value,
        });
      },
    },
    databuckets() {
      return this.$store.state.databuckets.databuckets;
    },
    targetCodes() {
      if (this.selectedItem.databucket) {
        const databucket =
          this.databuckets.find(
            (el) => el.id === this.selectedItem.databucket
          ) || "";
        return databucket.targetCodes;
      }
      return [];
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },

  methods: {
    formatDate(date) {
      return date ? moment(date).format("ll") : "";
    },
    getDatabucketTitle(item) {
      const found = this.databuckets.find((el) => el.id === item) || "";
      return found.title;
    },
    openDeleteDialog(item) {
      console.log("selected...", item);
      this.selectedItem = Object.assign({}, item);
      this.deleteDialogOpen = true;
    },
    closeDeleteDialog() {
      this.selectedItem = {};
      this.deleteDialogOpen = false;
    },
    handleDeleteOpportunity(item) {
      const index = this.opportunities.indexOf(item);
      this.opportunities.splice(index, 1);
      this.opportunities = [...this.opportunities];
      this.closeDeleteDialog();
    },
    openAddOrEditDialog(item) {
      if (item) {
        this.selectedItem = Object.assign({}, item);
      }
      this.addOrEditDialogOpen = true;
    },
    closeAddOrEditDialog() {
      this.selectedItem = {};
      this.addOrEditDialogOpen = false;
    },
    async handleSave(item) {
      const index = this.opportunities.findIndex((el) => el.id === item.id);
      if (index === -1) {
        this.opportunities = [
          ...this.opportunities,
          {
            id: uuidv4(),
            redeemable: false,
            ...item,
          },
        ];
      } else {
        this.opportunities[index] = { redeemable: false, ...item };
        this.opportunities = [...this.opportunities];
      }
      this.closeAddOrEditDialog();
    },
  },
};
</script>

<style></style>

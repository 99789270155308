/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
//
const state = {
  batches: [],
  loadingBatches: false,
  batch: null,
  loadingPoints: false,
};

const actions = {
  async loadBatches({ commit, getters }) {
    commit("setLoadingTable", true);
    commit("setLoadingBatches", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("batches")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const batches = [];
    querySnapshot.forEach(function (doc) {
      batches.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
      });
    });

    commit("setBatches", batches);
    commit("setLoadingTable", false);
    commit("setLoadingBatches", false);
  },

  async createBatch({ dispatch, commit, getters }, payload) {
    const newBatch = payload;
    console.log(newBatch);
    const batchesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("batches");

    let dupCheckRequests;
    let titleDupSnapshot;

    dupCheckRequests = [
      batchesRef.where("titleUppercase", "==", newBatch.titleUppercase).get(),
    ];

    try {
      [titleDupSnapshot] = await Promise.all(dupCheckRequests);
    } catch (e) {
      throw "Error occured when checking batch title";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Batch name is already registered.";
    }

    // eslint-disable-next-line no-unused-vars
    const { id, ...batchInput } = newBatch;

    const batch = {
      ...batchInput,
      status: "Open",
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newBatchRef;
    try {
      newBatchRef = await batchesRef.add(batch);
    } catch (e) {
      console.error(e);
      throw "Error when creating a new batch";
    }

    // Note: server time is unavailable until we refetch.
    const tempBatch = {
      ...batch,
      id: newBatchRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createBatch", tempBatch);
    dispatch("setSnackbar", "Batch Created.");
  },

  async updateBatch({ dispatch, commit, getters }, payload) {
    const batchesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("batches");

    let storedBatch;
    try {
      const batchDoc = await batchesRef.doc(payload.id).get();
      storedBatch = batchDoc.data();
    } catch (e) {
      storedBatch = null;
    }

    if (!storedBatch) {
      throw "Error occured when fetching the batch info";
    }

    let dupCheckRequests;
    let titleDupSnapshot;

    dupCheckRequests = [
      batchesRef.where("titleUppercase", "==", payload.titleUppercase).get(),
    ];
    try {
      [titleDupSnapshot] = await Promise.all(dupCheckRequests);
    } catch (e) {
      throw "Error occured when checking title";
    }

    if (titleDupSnapshot.size > 0) {
      let duplicated = false;
      titleDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Batch Name is already registered.";
      }
    }

    const batchInput = {
      ...payload,
      updatedBy: getters.user.id,
      updated: timestamp,
    };

    try {
      await batchesRef.doc(payload.id).update(batchInput);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a batch";
    }

    const tempBatch = {
      ...storedBatch,
      ...batchInput,
      id: payload.id,
      created: payload.created,
      updatedBy: getters.user.id,
      updated: new Date(),
    };

    commit("updateBatch", tempBatch);
    dispatch("setSnackbar", "Batch Updated");
    commit("setLoadingPoints", false);
  },

  async deleteBatch({ dispatch, commit, getters }, batchId) {
    console.log(batchId);
    const batchesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("batches");

    let storedBatch;
    try {
      const batchDoc = await batchesRef.doc(batchId).get();
      storedBatch = batchDoc.data();
    } catch (e) {
      storedBatch = null;
    }

    if (!storedBatch) {
      throw "Error occured when fetching the batch info.";
    }

    try {
      await batchesRef.doc(batchId).delete();
    } catch (e) {
      console.error(e);
      throw "Error occured when deleting a batch.";
    }

    commit("deleteBatch", batchId);
    dispatch("setLoading", false);
    dispatch("setSnackbar", "Batch Deleted");
  },

  allocateBatch({ dispatch, getters, commit }, payload) {
    commit("setLoadingPoints", true);
    const data = payload.finalData;
    console.log(data);

    const pointsArr = data
      .map((el) => ({
        points: Math.abs(el.points),
        action: el.points > 0 ? "addMemberPoints" : "subtractMemberPoints",
        type: "Award",
        notes: payload.note,
        description: "Batch",
        batchId: payload.id,
        exists: el.exists,
        balanceBeforeThisTransaction: el.balanceBeforeThisTransaction,
        pointsContainText: el.pointsContainText,
        memberId: getters.members.find((m) => m.accountKey === el.dataKey)
          ? getters.members.find((m) => m.accountKey === el.dataKey).id
          : null,
      }))
      .filter((item) => item.memberId !== null);

    const finalArr = pointsArr.filter(
      (el) => el.pointsContainText === false && el.exists === true
    );

    finalArr.forEach((obj) => dispatch(obj.action, obj));

    // once points have been allocated, we update the bacth history
    const batchHistory = {
      id: payload.id,
      status: "Completed",
      titleUppercase: payload.titleUppercase,
      created: payload.created,
      allocated: {
        totalPoints: payload.matchedPointsInData,
        note: payload.note,
        createdBy: getters.user.id,
        created: timestamp,
        data: payload.finalData,
      },
    };
    console.log(batchHistory);
    dispatch("updateBatch", batchHistory);
  },

  setLoadingPoints({ commit }, payload) {
    console.log("setLoadingPoints", payload);
    commit("setLoadingPoints", payload);
  },
};

const mutations = {
  setBatches(state, payload) {
    state.batches = payload;
  },

  setLoadingBatches(state, payload) {
    state.loadingBatches = payload;
  },

  setLoadingPoints(state, payload) {
    state.loadingPoints = payload;
  },

  createBatch(state, payload) {
    state.batches = [...state.batches, payload];
  },

  updateBatch(state, payload) {
    state.batches = state.batches.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteBatch(state, payload) {
    state.batches = state.batches.filter((item) => item.id !== payload);
  },
};

const getters = {
  batches(state) {
    return state.batches;
  },

  loadingBatches(state) {
    return state.loadingBatches;
  },

  loadingPoints(state) {
    return state.loadingPoints;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

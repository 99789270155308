<template>
<v-dialog v-model="open" max-width="400px" persistent>
  <v-card>
    <form data-vv-scope="admin">
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ adminId ? 'Edit ' : 'Add New '}}
          {{ formTitle }}
        </v-card-title>
      </v-row>

        <v-card-text class="px-16">

          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="name"
                label="Name"
                v-validate="'required'"
                :error-messages="errors.collect('admin.name')"
                name="name"
                type="text"
              />
              
              <v-checkbox
                v-model="terms"
                label="Terms"
                name="terms"
              />
              <v-divider class="mb-12 mt-6"></v-divider>
              <v-text-field
                v-model="email"
                label="Email"
                v-validate="'required|email'"
                :error-messages="errors.collect('admin.email')"
                name="email"
                type="email"
                append-icon="email"
                readonly
              />
              
              <v-text-field
                v-model="userId"
                label="Id"
                name="Id"
                type="text"
                readonly
              />
              
              <v-text-field
                v-model="created"
                label="Created"
                name="created"
                type="text"
                readonly
              />

            </v-col>    
          </v-row>

          <v-row dense>
            <v-col v-if="errorMessage">
                <v-alert
                  type="error"
                  :value="true"
                >
                  {{errorMessage}}
                </v-alert>
              </v-col>         
          </v-row>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            >Cancel
          </v-btn>
          <v-btn
            @click="handleSave('admin')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            elevation="0"
            disabled
            >Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>


<script>
// Save is disabled becuase it does not work. Has not bee fully refactored from
// the program admin code from which it was copied
export default {
  $_veeValidate: {
    validator: 'new'
  },

  created () {
  },

  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  props: {
    adminId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    dictionary: {
      custom: {
        firstname: {
          required: () => 'First name is required'
        },
        lastname: {
          required: () => 'Last name is required'
        },
        email: {
          required: () => 'Email is required'
        }
      }
    },
    formTitle: 'Admin',
    formIcon: 'account_circle',
    showAlert: false,
    terms: '',
    name: '',
    email: '',
    status: '',
    created: '',
    userId: '',
    errorMessage: '',
  }),

  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin
    },
    error () {
      return this.$store.getters.error
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    companies () {
      return this.$store.state.company.companies
    },
    systemAdmins () {
      return this.$store.state.systemadmin.systemAdmins
    }
  },

  watch: {
    adminId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedAdmin = this.systemAdmins.find(item => item.userId === newVal);
          const {
            email, terms, trialActivated, name, programs, userId, created
          } = selectedAdmin
          this.name = name
          this.email = email
          this.terms = terms
          this.trialActivated = trialActivated
          this.programs = programs
          this.userId = userId
          this.created = created.toDate()
        }
      },
    },
  },

  methods: {
    handleClose () {
      this.clear();
      this.$emit('onClose');
    },

    clear () {
      this.firstname = ''
      this.lastname = ''
      this.created = ''
      this.updated = ''
      this.activated = ''
      this.email = ''
      this.points = 0
      this.status = ''
      this.accountKey = ''
      this.selectedTags = []
      this.selectedCompanies = []
      this.errorMessage = '',
      this.$validator.reset();
      this.$store.dispatch('setLoading', false)
    },

    handleSave (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return
        }

        this.$store.dispatch('setLoading', true)

        const payload = {
          id: this.userId,
          name: this.name.trim(),
          terms: this.terms
        }

        // If create mode
        // not required for admins. this was left over from program admin feature
        // if (!this.adminId) {
        //  payload.status = 'Pending';
        //  payload.points = 0;
        // }

        const action = this.adminId ? 'updateAdmin' : 'createAdmin'

        this.$store.dispatch(action, payload)
          .then(() => {
            this.$store.dispatch('setLoading', false)
            this.handleClose();
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch('setLoading', false)
          });
      });
    }
  },
}
</script>

<style>
</style>

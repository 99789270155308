<template>
  <div>
    <v-card elevation="0">
      <v-toolbar flat dense>
        <div class="incentable-form-heading">Subscription</div>
        <v-spacer></v-spacer>
        <div v-if="currentSubscription.status === 'active'">
          <v-icon class="green--text pr-1" size="21">check_circle</v-icon>
          <span class="green--text dash-card">Active</span>
        </div>
        <div v-else-if="trial">
          <v-icon class="amber--text pr-1" size="21">check_circle</v-icon>
          <span class="amber--text dash-card">Free Trial</span>
        </div>
        <div v-else-if="expired">
          <v-icon class="red--text pr-1" size="21">error</v-icon>
          <span class="red--text dash-card">No Subscription</span>
        </div>
      </v-toolbar>
      <div class="px-4 pb-4 pt-2">
        <template v-if="currentSubscription.status === 'active'">
          <v-row
            v-if="currentSubscription.cancel_at_period_end === false"
            no-gutters
            class="mb-3"
            justify="center"
          >
            <v-icon color="green" class="mr-1">autorenew</v-icon>
            <span
              v-if="currentSubscription.subType === 'card'"
              class="incentable-form-heading green--text"
              >Auto renews
              {{ currentSubscription.current_period_end | date }}</span
            >
            <span
              v-if="currentSubscription.subType === 'bank'"
              class="incentable-form-heading green--text"
              >Auto renews
              {{
                currentSubscription.current_period_end | convertTimestamp
              }}</span
            >
          </v-row>
          <v-row v-else no-gutters class="mb-3" justify="center">
            <v-icon color="red" class="mr-1">cancel</v-icon>
            <span class="incentable-form-heading red--text"
              >Ends
              {{
                currentSubscription.current_period_end | convertTimestamp
              }}</span
            >
          </v-row>
        </template>

        <template v-if="currentSubscription.status === 'trial'">
          <v-row no-gutters>
            <v-col cols="6" class="capitalize label"> trial start: </v-col>
            <v-col class="incentable-form-heading">
              {{ (currentProgram.trial.starts.seconds * 1000) | date }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" class="capitalize label"> trial end: </v-col>
            <v-col class="incentable-form-heading">
              {{ (currentProgram.trial.ends.seconds * 1000) | date }}
            </v-col>
          </v-row>
        </template>

        <template v-if="currentSubscription.status !== 'trial'">
          <div>
            <v-row no-gutters>
              <v-col cols="6" class="capitalize label"> Plan: </v-col>
              <v-col
                v-if="currentSubscription.plan"
                class="incentable-form-heading"
              >
                {{ currentSubscription.plan }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="capitalize label"> Billed: </v-col>
              <v-col
                v-if="currentSubscription.plan"
                class="incentable-form-heading"
              >
                {{ frequency }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="capitalize label">
                current period starts:
              </v-col>
              <v-col
                v-if="
                  currentSubscription.current_period_start &&
                  currentSubscription.subType === 'bank'
                "
                class="incentable-form-heading"
              >
                {{
                  currentSubscription.current_period_start | convertTimestamp
                }}
              </v-col>
              <v-col
                v-if="
                  currentSubscription.current_period_start &&
                  currentSubscription.subType === 'card'
                "
                class="incentable-form-heading"
              >
                {{ currentSubscription.current_period_start | date }}
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="6" class="capitalize label">
                current period ends:
              </v-col>
              <v-col
                v-if="
                  currentSubscription.current_period_start &&
                  currentSubscription.subType === 'bank'
                "
                class="incentable-form-heading"
              >
                {{ currentSubscription.current_period_end | convertTimestamp }}
              </v-col>
              <v-col
                v-if="
                  currentSubscription.current_period_start &&
                  currentSubscription.subType === 'card'
                "
                class="incentable-form-heading"
              >
                {{ currentSubscription.current_period_end | date }}
              </v-col>
            </v-row>

            <v-row no-gutters v-if="currentSubscription.canceled_at">
              <v-col cols="6" class="capitalize label"> canceled at: </v-col>
              <v-col
                v-if="currentSubscription.canceled_at"
                class="incentable-form-heading"
              >
                {{ currentSubscription.canceled_at | date }}
              </v-col>
            </v-row>

            <v-row no-gutters v-if="currentSubscription.ended_at">
              <v-col cols="6" class="capitalize label"> ends at: </v-col>
              <v-col class="incentable-form-heading">
                {{ currentSubscription.ended_at | date }}
              </v-col>
            </v-row>
          </div>
        </template>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="currentSubscription.status !== 'active'"
          color="primary"
          elevation="0"
          @click="upgrade()"
          >Upgrade Plan</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: [
    "programId",
    "currentSubscription",
    "currentProgram",
    "trial",
    "expired",
  ],
  data: function () {
    return {
      status: "",
      ended_at: "",
    };
  },
  computed: {
    frequency() {
      if (
        this.currentSubscription.interval_count === "1" &&
        this.currentSubscription.interval === "month"
      ) {
        return "Monthly";
      } else if (
        this.currentSubscription.interval_count === "3" &&
        this.currentSubscription.interval === "month"
      ) {
        return "Quarterly";
      } else if (
        this.currentSubscription.interval_count === "1" &&
        this.currentSubscription.interval === "year"
      ) {
        return "Annually";
      }
      return "";
    },
  },
  methods: {
    upgrade() {
      this.$store.dispatch("setPlansTable", true);
    },
  },
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
.status {
  text-transform: capitalize;
  font-family: "Roboto", sans-serif !important;
  font-size: 17px;
  font-weight: 500 !important;
  color: #4d4d4d !important;
}
.label {
  font-size: 13px;
  font-weight: 300;
}
</style>

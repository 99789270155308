<template>
  <div>
    <v-dialog
      persistent
      v-model="galleryDialog"
      max-width="700px"
      transition="dialog-transition"
      z-index="3000"
    >      
      <v-card>
        <v-row no-gutters justify="space-between">
          <v-col class="pt-4">
            <span class="incentable-card-title ml-5">Media Gallery</span>
          </v-col>
          <v-col cols="auto">
            <v-btn
              :disabled="loading"
              elevation="0"
              fab
              small
              color="transparent"
              @click="handleGalleryDialog(false)"
            >
                <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isIncentableAdmin" no-gutters class="px-5">
          <v-col cols="6">
          <v-text-field
            placeholder="Enter new category or select an existing below"
            v-model="newCategory"
            clearable
          ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-0 px-5">
          <v-col cols="6">
            <v-select
              :items="categories"
              v-model="category"
              label="Image Gallery Category"
              solo-inverted
              dense
              flat
              hide-details
              clearable
              @input="clearNewCategory()"
            ></v-select>
          </v-col>
          <v-col
            v-if="isIncentableAdmin"
            cols="6"
          >
            <div
              v-if="!category && !newCategory"
              class="ml-3 mt-2 grey--text"
              tile
            >
              Select or add a category before uploading image
            </div>
            <media-upload
              v-else
              class="ml-2"
              :showMetaData="true"
              :isGallery="true"
              :height="38"
              :externalImageUrl="externalImageUrl"
              :currentImageOnFile="currentImageOnFile"
              @onImageSaved="handleImageSaved"
              @onImageProcessing="handleImageProcessing"
            />
          </v-col>
        </v-row>
        <v-row no-gutters class="px-3 pb-3 pt-2">
          <v-col
            cols="4"
            class="d-flex child-flex"
            v-for="(image, key) in filteredGallery"
            :key="key"
          >
            <v-img      
              :src="image.mediumUrl"
              aspect-ratio="1"
              class="ma-2"
              @mouseover="hover = key"
              @mouseleave="hover = null"
            >
              <v-overlay v-if="hover === key"
                absolute
                opacity="0.2"
              >
                <v-row no-gutters justify="center">
                  <v-btn small icon @click="openImageView(image.largeUrl)" class="primary">
                    <v-icon>visibility</v-icon>
                  </v-btn>
                  <v-btn v-if="isIncentableAdmin" small icon @click="handleDelete(image)" class="primary">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-row>
                <v-row no-gutters class="mt-3">
                  <v-btn 
                    :disabled="demo" 
                    small
                    @click="handleApplyImage(saveTo, image)"
                    class="primary"
                    elevation="0"
                  >
                    Apply this image
                  </v-btn>
                </v-row>
              </v-overlay>
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-skeleton-loader
                    min-height="100px"
                    min-width="100%"
                    type="card"
                  >
                  </v-skeleton-loader>
                </v-row>
              </template>
            </v-img> 
          </v-col>
        </v-row>
        
        <v-dialog
          v-model="dialogImageView"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          style="z-index: 5000"
        >
          <v-card>
            <v-img
              :src="viewImage"
            >
              <template v-slot:placeholder>
                <v-row
                  no-gutters
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                </v-row>
              </template>
              
              <v-btn
                :disabled="demo"
                @click="handleApplyImage(saveTo, image)"
                class="primary ma-4"
                elevation="0"
              >
                apply this image
              </v-btn>
              <v-btn
                icon
                class="primary float-right ma-4"
                @click="closeImageView()"
              >
                <v-icon :color="orgTheme.buttonIconColor">clear</v-icon>
              </v-btn>
            </v-img>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
import { IMAGE_DEFAULTS } from '@/constants/program'
import MediaUpload from '../../../../Shared/MediaUpload.vue'

export default {
  props: [ 'saveTo' ],
  components: {
    MediaUpload
  },
  data: () => ({
    lazyActive: false,
    dialogImageView: false,
    viewImage: null,
    hover: null,
    category: '',
    newCategory: '',
    selectedImageId: '',
    // new
    name: '',
    updated: '',
    smallUrl: '',
    mediumUrl: '',
    largeUrl: '',
    smallStoragePath: '',
    mediumStoragePath: '',
    largeStoragePath: '',
    currentImageOnFile: {},
    externalImageUrl: ''
  }),
  created () {
    this.$store.dispatch('setGalleryDialog', false)
    this.$store.dispatch('loadGallery')
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    loading() {
      return this.$store.state.loading
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates
    },
    editingComponent() {
      return this.$store.getters.editingComponent
    },
    isIncentableAdmin () {
      return this.$store.state.program.isIncentableAdmin
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    currentProgram() {
      return this.$store.getters.currentProgram
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme
    },
    gallery () {
      return this.$store.state.gallery.gallery
    },
    galleryDialog () {
      return this.$store.state.gallery.galleryDialog
    },
    categories () {
      const list = []
      const gallery = this.gallery
      gallery.forEach(el => list.push(el.category))
      return list
    },
    filteredGallery () {
      var filtered = []
      const list = []
      const gallery = this.gallery
      gallery.forEach(el => list.push({
        ...el,
        }))
      if (this.category) {
        filtered = list.filter(item => item.category === this.category)
      } else {
        filtered = list
      }
      return filtered
    }
  },
  methods: {
    handleImageSaved(image) {  // new uploader
      image.category = this.newCategory ? this.newCategory : this.category
      this.$store.dispatch('createImage', image)
    },
    handleImageProcessing(val) {  // new uploader
      this.$store.dispatch('setLoading', val)
    },
    handleGalleryDialog(val) {  // new uploader
      this.$store.dispatch('setGalleryDialog', val)
    },
    clearNewCategory() {
      this.newCategory = null
    },
    handleChange() {
      this.$store.dispatch('setHasWebThemePendingUpdates', true)
    },
    openImageView(image) {
      this.image = image
      this.dialogImageView = true
      this.viewImage = image
    },
    closeImageView() {
      this.dialogImageView = false
      this.viewImage = null
    },
    handleApplyImage(saveTo, image) {
      // console.log(saveTo, image)
      if (saveTo === 'bannerLogo') {
        this.editingComponent.bannerLogo.image = image.largeUrl
        this.editingComponent.bannerLogo.previewImage = image.mediumUrl
      }
      else if (saveTo === 'background') {
        this.editingComponent.background.image = image.largeUrl
        this.editingComponent.background.previewImage = image.mediumUrl
      }
      else if (saveTo === 'logoColOne') {
        this.editingComponent.logoColOne.image = image.largeUrl
        this.editingComponent.logoColOne.previewImage = image.smallUrl
      }
      else if (saveTo === 'logoColTwo') {
        this.editingComponent.logoColTwo.image = image.largeUrl
        this.editingComponent.logoColTwo.previewImage = image.smallUrl
      }
      else if (saveTo === 'logoColThree') {
        this.editingComponent.logoColThree.image = image.largeUrl
        this.editingComponent.logoColThree.previewImage = image.smallUrl
      }
      else if (saveTo === 'logo') {
        this.editingComponent.logo.image = image.largeUrl
        this.editingComponent.logo.previewImage = image.smallUrl
      }
      else if (saveTo === 'mobileLogo') {
        this.editingComponent.mobileLogo.image = image.largeUrl
        this.editingComponent.mobileLogo.previewImage = image.smallUrl
      }
      else if (saveTo === 'tier') {
        // console.log(saveTo, image)
        this.$emit('onImageSelected', image)
        this.handleGalleryDialog(false)
      }
      else if (saveTo === 'award') {
        // console.log(saveTo, image)
        this.$emit('onImageSelected', image)
        this.handleGalleryDialog(false)
      }
      this.dialogImageView = false
      this.handleChange()
    },
    handleDelete(payload) {
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('deleteMediaFilesFromStorage', payload)
      .then(() => {
        // Check if delected image was in use and replace with default
        if (payload.largeUrl === this.editingComponent.settings.image) {
          this.editingComponent.settings.image = IMAGE_DEFAULTS.image
          this.editingComponent.settings.previewImage = IMAGE_DEFAULTS.previewImage
          this.handleChange()
        } else {
          return
        }
        // console.log('file removed from storage')
      }).catch((error) => {
        console.log('there was an error: ', error)
      })
      // Delete image record from database
      this.$store.dispatch('deleteImage', payload)
    },
  },
  watch: {
  },
}
</script>

<style>

</style>

<template>
  <v-btn
    color="primary"
    elevation="0"
    :disabled="!hasWebThemePendingUpdates || demo"
    :loading="loading"
    :small="small"
    :block="block"
    @click="handleSave"
    >save</v-btn
  >
</template>

<script>
export default {
  props: ["small", "block"],
  created() {},
  data: function () {
    return {};
  },
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    loading() {
      return this.$store.state.loading;
    },
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates;
    },
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
  },
  methods: {
    async handleSave() {
      //console.log(this.webTheme)
      await this.$store.dispatch("updateWebTheme", this.webTheme);
      this.$store.dispatch("setHasWebThemePendingUpdates", false);
      this.$store.dispatch("editingField", {});
      this.$store.dispatch("editingComponent", {});
      this.$store.dispatch("setNewComponent", null);
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="open" persistent max-width="1150px">
    <v-card>
      <v-row no-gutters>
        <v-card-title class="page-heading ml-3">
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-10">
        <v-stepper v-model="stage">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="stage > 1">
              Select CSV & Map Columns
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="stage > 2">
              Preview & Save
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <csv-import
                v-model="parsedCsv"
                :key="csvComponentKey"
                :map-fields="mappingFields"
                :required-fields="requiredFields"
                :vertical="true"
              />
              <v-row no-gutters class="mt-3" justify="end">
                <v-btn text @click="close()" elevation="0" color="primary"
                  >Cancel</v-btn
                >
                <v-btn
                  @click="stage++"
                  elevation="0"
                  class="white--text"
                  color="primary"
                  >Next</v-btn
                >
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-data-table
                :headers="headers"
                :items="parsedCsv"
                :search="search"
                :options.sync="options"
                :loading="loadingTable"
                no-data-text="No Records..."
              >
              </v-data-table>
              <v-row justify="end" no-gutters>
                <v-btn color="primary" @click="stage--" elevation="0" text
                  >Back</v-btn
                >
                <v-btn @click="addToBatch()" color="primary" elevation="0"
                  >add to batch</v-btn
                >
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { nanoid } from "nanoid";
const CSV_MAPPING = {
  ACCOUNT_KEY: "dataKey",
  FIRSTNAME: "firstname",
  LASTNAME: "lastname",
  EMAIL: "email",
  POINTS: "points",
};
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const mappingFields = [
      CSV_MAPPING.ACCOUNT_KEY,
      CSV_MAPPING.FIRSTNAME,
      CSV_MAPPING.LASTNAME,
      CSV_MAPPING.EMAIL,
      CSV_MAPPING.POINTS,
    ];
    const requiredFields = [CSV_MAPPING.ACCOUNT_KEY, CSV_MAPPING.POINTS];
    return {
      csvComponentKey: Math.random(), // Note: This trick triggers the clearing of csv import component
      mappingFields,
      requiredFields,
      parsedCsv: [],
      formTitle: "Upload Points CSV File",
      stage: 1,
      search: "",
      headers: [
        { text: "First Name", value: "firstname", align: "left" },
        { text: "Last Name", value: "lastname", align: "left" },
        { text: "Email", value: "email", align: "left" },
        { text: "Points", value: "points", align: "right" },
        { text: "Data Key", value: "dataKey", align: "right" },
      ],
      options: {
        sortBy: ["created"],
        sortDesc: [true],
        itemsPerPage: 5,
        sortable: true,
        multiSort: true,
        search: true,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
    };
  },
  computed: {
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    members() {
      return this.$store.state.member.members;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
  },
  methods: {
    clear() {
      this.csvComponentKey = Math.random();
      this.parsedCsv = [];
      this.stage = 1;
      this.processSuccess = false;
      this.processFailed = false;
    },
    close() {
      this.$emit("onClose");
      this.clear();
      this.stage = 1;
    },
    addToBatch() {
      const newBatch = this.parsedCsv.map((obj) => ({ ...obj, id: nanoid() }));
      this.$emit("addToBatch", newBatch);
      this.$emit("onClose");
      this.clear();
      this.stage = 1;
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
</style>

export const IMAGE_DEFAULTS = {
  settings: {
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_2560x2560.png?alt=media&token=62cd0b3e-a963-42ef-a633-cf9a063f99f1',
    heightContext: 'px',
    mode: 'image',
    previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_600x600.png?alt=media&token=3a2aa0cd-b5fe-45a5-9466-ec39af90630b',
    cols: 12,
    color: { 
      alpha: 1, 
      hex: '#E91E63' ,
      hexa: '#E91E6300',
      hsla: {
        a: 1,
        h: 339.60591133004925,
        l: 0.5156862745098039,
        s: 0.8218623481781376,
      },
      hsva: {
        a: 1,
        h: 339.60591133004925,
        s: 0.871244635193133,
        v: 0.9137254901960784,
        hue: 339.60591133004925,
      },
      rgba: {
        a: 1,
        b: 99,
        g: 30,
        r: 233
      } 
    },
  },
  screen: {
    height: 85,
    type: 'screen',
    direction: 'to top',
    color: { 
      alpha: 0, 
      hex: '#000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
    fromColor: { 
      alpha: 1, 
      hex: '#212121', 
      hexa: '#212121FF', 
      hsla: { h: 0, s: 0, l: 0.12941176470588237, a: 1 }, 
      hsva: { h: 0, s: 0, v: 0.12941176470588237, a: 1 }, 
      hue: 0, 
      rgba: { r: 33, g: 33, b: 33, a: 1 }  
    },
    toColor: { 
      alpha: 0, 
      hex: '#212121', 
      hexa: '#21212100', 
      hsla: { h: 339.60591133004925, s: 0.8218623481781376, l: 0.5156862745098039, a: 0 }, 
      hsva: { h: 339.60591133004925, s: 0.871244635193133, v: 0.9137254901960784, a: 0 }, 
      hue: 339.60591133004925, 
      rgba: { r: 233, g: 30, b: 99, a: 0 } 
    },
  },
  heightValue: {
    px: 200,
    vh: 0,
  },
  overlap: {
    px: 0,
    vh: 0,
  },
  background: {
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_2560x2560.png?alt=media&token=62cd0b3e-a963-42ef-a633-cf9a063f99f1',
    previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_600x600.png?alt=media&token=3a2aa0cd-b5fe-45a5-9466-ec39af90630b'
  }
};

<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["chartData", "orgTheme", "chartFillColor", "chartLineColor"],
  data: () => ({
    pointOptions: {
      pointStyle: "circle",
      radius: 2,
      hitRadius: 7,
      borderWidth: 1,
    },
    lineOptions: {
      borderColor: "#F5F5F5",
      borderWidth: 1,
      fill: true,
      radius: 4,
      tension: 0.4,
    },
  }),
  mounted() {
    this.renderChart(
      {
        labels: this.chartData.labels,
        datasets: [
          {
            label: this.chartData.label,
            borderColor: this.chartLineColor,
            backgroundColor: this.createGradient(
              this.$refs.canvas,
              this.chartFillColor
            ),
            pointBackgroundColor: this.chartLineColor,
            pointBorderColor: this.chartLineColor,
            pointRadius: this.pointOptions.radius,
            lineTension: this.lineOptions.tension,
            pointBorderWidth: this.pointOptions.borderWidth,
            lineBorderWidth: this.lineOptions.borderWidth,
            data: this.chartData.data,
          },
        ],
      },

      {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              title: {
                font: {
                  size: 8,
                },
              },
              display: true,
              ticks: {
                beginAtZero: true,
                fontColor: "grey",
                fontSize: 9,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                fontColor: "grey",
                fontSize: 9,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        elements: {
          line: {
            borderColor: this.chartLineColor,
            borderWidth: 1,
            fill: true,
            backgroundColor: this.chartLineColor,
          },
          point: {
            radius: 2,
            hitRadius: 2,
            borderWidth: 2,
            borderColor: "#fff",
            backgroundColor: this.chartLineColor,
          },
        },
      }
    );
  },
  computed: {},
  methods: {
    createGradient(canvas, color) {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, color);
      gradient.addColorStop(0.5, "rgba(255, 255, 255, 0)");
      gradient.addColorStop(1, color);
      return gradient;
    },
  },
};
</script>

<style></style>

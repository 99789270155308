/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  categorys: [],
};

const actions = {
  async loadCategorys({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("categorys")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const categorys = [];
    querySnapshot.forEach(function (doc) {
      // Add database fields here to customise for specific table
      categorys.push({
        id: doc.id,
        title: doc.data().title,
        titleUppercase: doc.data().titleUppercase,
        created: doc.data().created.toDate(),
        createdBy: doc.data().createdBy,
        updatedBy: doc.data().updatedBy,
        updated: doc.data().updated.toDate(),
      });
    });

    commit("setCategorys", categorys);
    commit("setLoadingTable", false);
  },

  async createCategory({ commit, getters }, payload) {
    const categorysCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("categorys");

    let existingCategoryResult;
    try {
      existingCategoryResult = await categorysCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing category";
    }

    if (existingCategoryResult.size > 0) {
      return "duplication";
    }

    const category = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newCategoryRef;
    try {
      newCategoryRef = await categorysCollection.add(category);
    } catch (e) {
      throw "Error when creating a new category";
    }

    // Note: server time is unavailable until we refetch.
    const tempCategory = {
      ...category,
      id: newCategoryRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createCategory", tempCategory);
    commit("setSnackbar", "Category added");

    return "ok";
  },

  async updateCategory({ commit, getters }, payload) {
    const categorysCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("categorys");

    let existingCategoryResult;
    try {
      existingCategoryResult = await categorysCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing category";
    }

    let duplicated = false;
    existingCategoryResult.forEach((doc) => {
      if (doc.id !== payload.id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    const category = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await categorysCollection.doc(payload.id).update(category);
    } catch (e) {
      throw "Error when updating a category";
    }

    const categoryBeforeUpdate = getters.categorys.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempCategory = {
      ...categoryBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updateCategory", tempCategory);
    commit("setSnackbar", "Category updated");

    return "ok";
  },

  async deleteCategory({ commit, getters }, id) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("categorys")
        .doc(id)
        .delete();
    } catch (e) {
      throw "Error when deleting an category";
    }
    commit("deleteCategory", id);
    commit("setSnackbar", "Category deleted");
  },
};

const mutations = {
  setCategorys(state, payload) {
    state.categorys = payload;
  },

  createCategory(state, payload) {
    state.categorys = [...state.categorys, payload];
  },

  updateCategory(state, payload) {
    state.categorys = state.categorys.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteCategory(state, payload) {
    state.categorys = state.categorys.filter((item) => item.id !== payload);
  },
};

const getters = {
  categorys(state) {
    return state.categorys;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

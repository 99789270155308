<template>
  <v-container v-if="!loading" fluid fill-height class="activation-page">
    <v-snackbar v-model="showProcessingResult" :top="true" :timeout="0">
      {{ processingResult }}
    </v-snackbar>
    <v-row justify="center">
      <v-col sm="3">
        <v-card flat class="pa-16">
          <v-alert
            v-if="loadingError"
            color="primary"
            border="left"
            text
            type="error"
            :value="true"
          >
            {{ loadingError }}
          </v-alert>
          <v-form @submit.prevent="handleForm" v-if="!loadingError">
            <v-row class="auth-title" justify="center">
              {{ activeProgram.displayTitle }}
            </v-row>
            <v-row justify="center auth-subtitle" class="my-11">
              {{ $t("Enter your new password") }}
            </v-row>
            <v-text-field
              v-model="activatingMember.email"
              v-validate="'required|email'"
              :error-messages="errors.collect('email')"
              id="email"
              name="Email"
              :label="$t('Email')"
              type="email"
              disabled
            />
            <v-text-field
              v-model="password"
              :rules="rules.password"
              id="password"
              name="password"
              :label="$t('Password')"
              type="password"
              required
              :color="primaryColor"
            />

            <v-btn
              type="submit"
              block
              large
              dark
              class="ml-0"
              elevation="0"
              :color="primaryColor"
              :loading="isProcessing"
            >
              {{ $t("Reset Password") }}
              <span slot="loader" class="custom-loader">
                <v-icon light>cached</v-icon>
              </span>
            </v-btn>
          </v-form>

          <v-row
            v-if="
              activeProgram.status === 'Active' ||
              activeProgram.status === 'Demo' ||
              activeProgram.status === 'Trial'
            "
            justify="center"
            class="incentable-page-subheading mt-9"
            no-gutters
          >
            <a
              :href="'https://' + domain"
              target="_blank"
              class="text--secondary"
              >{{ domain }}
            </a>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else fluid fill-height class="activation-page">
    <v-row justify="center">
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
        size="130"
        width="10"
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import jwt from "jsonwebtoken";
import axios from "axios";
import { webApi } from "@/firebase";
import { DEFAULT_BANNER_URL } from "../../constants/email";
export default {
  async created() {
    const { token } = this.$route.query;
    const json = jwt.verify(token, "shh");
    const url = `${webApi}members/getMemberById/?programId=${json.programId}&memberId=${json.memberId}`;
    var programId = "";
    await axios
      .get(url)
      .then((result) => {
        const { program, member } = result.data;
        this.$i18n.locale = program.translate.locale;
        programId = program.id;
        this.defaultColor = program.webTheme.colors.primary.hex;
        this.activeProgram = program;
        this.activatingMember = member;
      })
      .catch((e) => {
        this.loadingError = e.response.data;
        this.loading = false;
      });
    await this.$store.dispatch("loadTranslations", programId);
    await this.$store.dispatch("loadTranslationData", programId);
    await this.$store.dispatch("loadSites");
    this.getDomain(programId);
    console.log("first", this.$store.getters.firstTimeLoad);
    if (this.$store.getters.firstTimeLoad) {
      await this.$store.dispatch("setFirstTimeLoad", false);
      await this.$router.go(0);
    }
    this.loading = false;
  },
  beforeRouteEnter(to, from, next) {
    const { token } = to.query;
    try {
      jwt.verify(token, "shh");
      next();
    } catch (e) {
      next("/404");
    }
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
    this.$store.commit("setNavMenu", false);
    this.$store.dispatch("setToolbar", false);
    this.$store.dispatch("setFooter", false);
  },
  data() {
    return {
      activeProgram: null,
      activatingMember: null,
      password: "",
      isProcessing: false,
      loading: true,
      loadingError: "",
      processingResult: "",
      defaultColor: "#212121",
      domain: "",
      rules: {
        password: [
          (v) => !!v || this.$t("Password is required"),
          (v) =>
            (v && v.length >= 6) ||
            this.$t("Password must be less than 6 characters"),
        ],
      },
    };
  },
  computed: {
    bannerUrl() {
      const notificationSettings =
        this.activeProgram.notificationSettings || {};
      return notificationSettings.bannerUrl || DEFAULT_BANNER_URL;
    },
    primaryColor() {
      return this.defaultColor;
    },
    showProcessingResult() {
      return !!this.processingResult;
    },
  },
  methods: {
    async getDomain(id) {
      const arr = this.$store.state.site.sites;
      this.domain = arr.find((el) => el.program === id).activeDomain || "";
    },
    handleForm() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        this.isProcessing = true;

        const url = `${webApi}auth/reset-password/`;
        const payload = {
          programId: this.activeProgram.id,
          memberId: this.activatingMember.id,
          password: this.password,
        };

        axios
          .post(url, payload)
          .then(() => {
            this.isProcessing = false;
            this.processingResult = this.$t(
              "Your password was reset successfully."
            );
          })
          .catch((e) => {
            this.processingResult = e.response.data;
            this.isProcessing = false;
          });
      });
    },
  },
};
</script>

<style>
.activation-page {
  background-color: #f6f6f6; /* For browsers that do not support gradients */
}
.auth-title {
  font-size: 1.2em;
  font-weight: 600;
}
.auth-subtitle {
  font-size: 0.8em;
  font-weight: 300;
}
</style>

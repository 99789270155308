<template>
  <v-container fluid class="primary">
    <v-row row wrap align-center justify-center>
      <v-col xs12 sm10 md8 lg6 xl4>
        <v-card class="pa-12">
          This page is no longer available. Check with support
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {},
  methods: {},
};
</script>

<style></style>

export const DATA_TYPES = [
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
];
export const NUMBER_ROUNDINGS = [
  { label: '0', value: '0,0' },
  { label: '0.0', value: '0,0.0' },
  { label: '0.00', value: '0,0.00' },
  { label: '0.000', value: '0,0.000' },
];
export const NUMBER_FORMATS = [
  { label: 'None', value: '' },
  { label: '$', value: '$' },
  { label: '%', value: '%' },
  { label: 'e', value: 'e' },
  { label: 'Y', value: 'y' },
  { label: 'c', value: 'c' },
]

<template>
  <v-dialog v-model="open" max-width="1000px" persistent>
    <v-card>
      <form data-vv-scope="article">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="primary"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            {{ articleId ? 'Edit ' : 'Add New '}}
            {{ formTitle }}
          </v-card-title>
        </v-row>
        <v-card-text class="px-16">

          <v-row dense>
            <v-col lg="9" class="pr-16">
              <v-text-field
                name="title"
                v-model="title"
                label="Article Title"
                v-validate="'required'"
                :error-messages="errors.collect('article.title')"
              ></v-text-field>
              
              <v-textarea
                v-model="body"
                label="Article Text"
                name="body"
                type="text"
                filled
              ></v-textarea>

              <v-row no-gutters class="incentable-form-heading">
                Featured Image
              </v-row>

              <media-upload
                class="mt-3 mb-0"
                :id="articleId"
                :currentImageOnFile="image"
                :externalImageUrl="externalImageUrl"
                :showMetaData="true"
                @onImageSaved="handleImageSaved"
                @onImageProcessing="handleImageProcessing"
                :radius="radius"
              />              

              <v-row no-gutters class="incentable-form-heading">
                <v-col>
                  Video
                </v-col> 
              </v-row>

                <v-row class="incentable-form-subheading" no-gutters>
                  A play button will appear on the article image when a YouTube code is added below
                </v-row>

                <v-row class="incentable-form-subheading mt-n2" no-gutters>
                  Only enter the YouTube video code, not the full web address
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        size="20"
                      >help</v-icon>
                    </template>
                    <span>
                      Do not enter the full web address of the YouTube video you wish to display 
                      <br>https://www.youtube.com/watch?v=hJdbCCYxxAA
                      <br><br>Only enter the video id, which is the part after '=' hJdbCCYxxAA
                    </span>
                  </v-tooltip>

              </v-row>
              <v-row dense>
                <v-col lg="4">
                  <v-text-field
                    v-model="videoUrl"
                    label="YouTube Video ID"
                    type="text"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense v-if="videoUrl">
                <v-col>
                  <iframe
                    width="592"
                    height="333"
                    :src="videoCode"
                    frameborder="0"
                    allowfullscreen
                    class="rounded"
                    >
                  </iframe>

                </v-col>
              </v-row>
              <v-row dense v-else>
                <v-col>
                  <v-card
                    height="333px"
                    flat
                    class="mb-5 pt-0 mt-0 grey lighten-3"
                    >
                    <v-row justify="center" align="stretch" style="height: 333px">
                      <v-icon
                        color="primary"
                        size="80"
                        >
                        play_circle_filled
                      </v-icon>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

            <v-col lg="3">
              
              <v-select
                name="status"
                label="Status"
                v-validate="'required'"
                :error-messages="errors.collect('article.status')"
                v-model="selectedStatus"
                :items="statuses"
                item-text="text"
                item-value="value"
                append-icon="power_settings_new"
              >
              </v-select>

              <v-select
                name="blog"
                :items="blogs"
                label="Blog"
                v-validate="'required'"
                :error-messages="errors.collect('article.blog')"
                v-model="selectedBlog"
                item-text="title"
                item-value="id"
              ></v-select>

              <v-select
                name="categorys"
                v-model="selectedCategorys"
                :items="categorys"
                item-text="title"
                item-value="id"
                label="Category"
                v-validate="'required'"
                :error-messages="errors.collect('article.categorys')"
                chips
                multiple
                small-chips
                deletable-chips
                append-icon="category"
              ></v-select>

              <v-row no-gutters class="incentable-form-heading">
                <v-col>
                  Content Filters
                </v-col> 
              </v-row>
              <v-row no-gutters class="incentable-form-subheading">
                <v-col>
                  Filter this article to specific members
                </v-col>
              </v-row>

              <v-select
                v-model="selectedCompanyTags"
                :items="companyTags"
                item-text="tag"
                item-value="id"
                label="Company Tags"
                chips
                multiple
                small-chips
                deletable-chips
                append-icon="local_offer"
              >
              </v-select>

              <v-select
                v-model="selectedMemberTags"
                :items="memberTags"
                item-text="tag"
                item-value="id"
                label="Member Tags"
                chips
                multiple
                small-chips
                deletable-chips
                append-icon="local_offer"
              >
              </v-select>

              <div v-if="articleId">
                <v-row no-gutters class="incentable-form-heading">
                  <v-col>
                    More
                  </v-col> 
                </v-row>
                <v-row no-gutters class="incentable-form-subheading">
                  <v-col>
                    Additional information
                  </v-col>
                </v-row>

                <v-text-field
                  :value="created | date"
                  label="Created"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  :value="getAuthorName(createdBy)"
                  label="Created By"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  :value="updated | date"
                  label="Updated"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  :value="getAuthorName(updatedBy)"
                  label="Updated By"
                  readonly
                >
                </v-text-field>

              </div>

            </v-col>    
          </v-row>

          <v-row dense>
            <v-col>
              <v-alert
                v-model="showAlert"
                color="primary"
                border="left"
                text
                >
                That Article name is already registered
              </v-alert>
            </v-col>         
          </v-row>

        </v-card-text>
        <v-card-actions class="pb-4 pr-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            :disabled="loading"
            >
              {{ articleId ? 'Close' : 'Cancel' }}
          </v-btn>
          <v-btn
            @click="saveArticle('article')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            :disabled="loading || demo"
            elevation="0"
            >
              {{ articleId ? 'Save Article' : 'Create Article' }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import MediaUpload from '../Shared/MediaUpload.vue'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    MediaUpload
  },
  props: {
    articleId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => 'Article title is required'
        },
        blog: {
          required: () => 'Please assign this article to a Blog'
        },
        status: {
          required: () => 'Status is required'
        },
        categorys: {
          required: () => 'Category is required'
        }
      }
    },
    statuses: [
      { text: 'Active', value: 'Active' },
      { text: 'Inactive', value: 'Inactive' }
    ],
    formTitle: 'Article',
    formIcon: 'insert_drive_file',
    showAlert: false,
    selectedStatus: 'Active',
    selectedCategorys: [],
    selectedBlog: '',
    selectedCompanyTags: [],
    selectedMemberTags: [],
    title: '',
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
    body: '',
    videoUrl: '',
    // image uploader
    radius: false,
    externalImageUrl: '',
    image: {
      name: '',
      updated: '',
      smallUrl: '',
      mediumUrl: '',
      largeUrl: '',
      smallStoragePath: '',
      mediumStoragePath: '',
      largeStoragePath: '',
    },
  }),
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    programId () {
      return this.$store.getters.programId;
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    articles () {
      return this.$store.state.article.articles
    },
    blogs () {
      return this.$store.state.blog.blogs
    },
    categorys () {
      return this.$store.state.category.categorys
    },
    companyTags () {
      return this.$store.state.companytag.companyTags
    },
    memberTags () {
      return this.$store.state.membertag.memberTags
    },
    videoCode () {
      return "https://www.youtube-nocookie.com/embed/" + this.videoUrl
    },
    blogAuthorMap() {
      return this.$store.getters.admins.reduce((result, item) => {
        return {
          ...result,
          [item.userId]: item.name,
        };
      }, {});
    },
  },
  methods:{
    handleImageSaved(image) {
      this.image = {
        updated: image.updated,
        name: image.name || '',
        smallUrl: image.smallUrl || '',
        mediumUrl: image.mediumUrl || '',
        largeUrl: image.largeUrl || '',
        smallStoragePath: image.smallStoragePath || '',
        mediumStoragePath: image.mediumStoragePath || '',
        largeStoragePath: image.largeStoragePath || '',
      },
      this.saveArticle('article', false)
    },
    handleImageProcessing(val) {
      this.$store.dispatch('setLoading', val)
    },
    clear () {
      this.title = ''
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.body = ''
      this.selectedBlog = ''
      this.selectedStatus = 'Active'
      this.selectedCategorys = []
      this.selectedCompanyTags = []
      this.selectedMemberTags = []
      this.showAlert = false;
      this.videoUrl =  '';
      this.image = {   // new uploader
        name: '',
        updated: '',
        smallUrl: '',
        mediumUrl: '',
        largeUrl: '',
        smallStoragePath: '',
        mediumStoragePath: '',
        largeStoragePath: '',
      },
      this.$validator.reset();
      this.$store.dispatch('setLoading', false);
    },
    handleClose () {
      this.clear()
      this.$emit('onClose')
    },
    getAuthorName(adminId) {
      return this.blogAuthorMap[adminId];
    },
    saveArticle (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }

        this.$store.dispatch('setLoading', true)

        const payload = {
          // Add database fields here
          status: this.selectedStatus,
          title: this.title,
          titleUppercase: this.title.toUpperCase(),
          body: this.body,
          blog: this.selectedBlog,
          categorys: this.selectedCategorys,
          companyTags: this.selectedCompanyTags,
          memberTags: this.selectedMemberTags,
          videoUrl: this.videoUrl || '',
          image: this.image || {
            name: '',
            updated: '',
            smallUrl: '',
            mediumUrl: '',
            largeUrl: '',
            smallStoragePath: '',
            mediumStoragePath: '',
            largeStoragePath: '',
          },
        };
        if (this.articleId) {
          // Update Article
          payload.id = this.articleId;
          this.$store.dispatch('updateArticle', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create Article
          this.$store.dispatch('createArticle', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                if (close) {
                  this.handleClose()
                  return
                }
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        }
      });
    },
  },
  watch: {
    bannerUploadTask: function () {
      if (!this.bannerUploadTask) {
        return;
      }
      this.bannerUploadTask.on('state_changed', sp => {
        if (sp.totalBytes <= 0) {
          return;
        }
        this.bannerUploadingProgress = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
        this.bannerSize = Math.round(sp.bytesTransferred / 1000)
      },
      () => {
        this.bannerUploading = false;
      },
      () => {
        this.bannerUploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.bannerUrl = downloadURL;
          this.bannerUploading = false;
        });
      });
    },
    articleId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedArticle = this.articles.find(item => item.id === newVal);
          const {
            status, categorys, blog, companyTags, memberTags, title, created, updated, body, 
            image, videoUrl, createdBy, updatedBy
          } = selectedArticle;
          this.selectedStatus = status;
          this.selectedCategorys = categorys;
          this.selectedBlog = blog;
          this.selectedCompanyTags = companyTags;
          this.selectedMemberTags = memberTags;
          this.title = title;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
          this.body = body;
          this.image = image || {
            name: '',
            updated: '',
            smallUrl: '',
            mediumUrl: '',
            largeUrl: '',
            smallStoragePath: '',
            mediumStoragePath: '',
            largeStoragePath: '',
          }
          this.videoUrl = videoUrl;
        }
      },
    },
  },
}
</script>
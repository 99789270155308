import { db, stripePortalRef } from "@/firebase";
// import router from '../../router'

const state = {
  products: [],
  subscription: {},
  subscriptions: [],
  manualSubscriptions: [],
  stripeInvoices: [],
  plansTable: false,
  currentSubscription: {},
};

const actions = {
  setPlansTable({ commit }, payload) {
    commit("setPlansTable", payload);
  },

  async getCurrentSubscription({ commit, getters, dispatch }, programId) {
    await dispatch("loadManualSubscriptions", programId);
    commit("setCurrentSubscription", {});
    let result = {};
    try {
      // check for credit card subscription
      const creditCardSubscriptions = getters.subscriptions;
      const ccSub = creditCardSubscriptions.find(
        (el) => el.program === programId
      );
      if (ccSub) {
        result = ccSub;
      }

      // check for bank transfer subscription
      const bankTransferSubscriptions = getters.manualSubscriptions;
      const btSub = bankTransferSubscriptions.find(
        (el) => el.status === "active"
      );
      if (btSub) {
        result = btSub;
      }
      commit("setCurrentSubscription", result);
    } catch (error) {
      console.log(error);
    }
  },

  async loadSubscriptions({ commit }) {
    commit("setLoadingTable", true);
    commit("setSubscriptions", []);
    try {
      const subscriptions = [];
      await db
        .collection("admins")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            const subscriptionSnap = await doc.ref
              .collection("subscriptions")
              .orderBy("created", "desc")
              .get();
            subscriptionSnap.docs.forEach((doc) => {
              const sub = doc.data();
              // calculate subscription total
              var total = 0;
              var currency = "";
              var plan = "";
              var interval = "";
              var interval_count = "";
              sub.items.forEach((el) => {
                (total = total + el.price.unit_amount),
                  (currency = currency + el.price.currency);
                plan = plan + el.price.product.name;

                interval = interval + el.plan.interval;
                interval_count = interval_count + el.plan.interval_count;
              });
              subscriptions.push({
                id: doc.id,
                subType: "card",
                paymentStatus: "paid",
                cancel_at: !sub.cancel_at ? null : sub.cancel_at.seconds * 1000,
                cancel_at_period_end: sub.cancel_at_period_end,
                canceled_at: !sub.canceled_at
                  ? null
                  : sub.canceled_at.seconds * 1000,
                created: sub.created.seconds * 1000,
                current_period_end: !sub.current_period_end
                  ? null
                  : sub.current_period_end.seconds * 1000,
                current_period_start: !sub.current_period_start
                  ? null
                  : sub.current_period_start.seconds * 1000,
                ended_at: !sub.ended_at ? null : sub.ended_at.seconds * 1000,
                items: sub.items,
                total: total / 100,
                currency: currency,
                plan: plan,
                interval: interval,
                interval_count: interval_count,
                createdBy: sub.metadata.createdBy,
                program: sub.metadata.incentableProgramId,
                quantity: sub.quantity,
                role: sub.role,
                status: sub.status,
                stripeLink: sub.stripeLink,
                trial_end: !sub.trial_end ? null : sub.trial_end.seconds * 1000,
                trial_start: !sub.trial_start
                  ? null
                  : sub.trial_start.seconds * 1000,
              });
            });
          });
        });
      commit("setSubscriptions", subscriptions);
      commit("setLoadingTable", false);
    } catch (e) {
      console.log(e);
      commit("setLoadingTable", false);
    }
  },

  async loadManualSubscriptions({ commit, getters }, programId) {
    commit("setLoadingTable", true);
    commit("setManualSubscriptions", []);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(programId)
        .collection("manualSubscriptions")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const manualSubscriptions = [];
    querySnapshot.forEach(function (doc) {
      manualSubscriptions.push({
        ...doc.data(),
        id: doc.id,
        program: getters.programId,
        current_period_start: doc.data().starts,
        current_period_end: doc.data().ends,
        cancel_at_period_end: false,
        subType: "bank",
      });
    });
    commit("setManualSubscriptions", manualSubscriptions);
    commit("setLoadingTable", false);
  },

  async loadStripeInvoices({ commit }, payload) {
    commit("setStripeInvoices", []);
    try {
      const invoices = [];
      await db
        .collection("admins")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            const invoicesSnap = await doc.ref
              .collection("subscriptions")
              .doc(payload)
              .collection("invoices")
              .get();

            invoicesSnap.docs.forEach((inv) => {
              invoices.push({
                id: inv.data().id,
                ...inv.data(),
              });
            });
          });
        });
      commit("setStripeInvoices", invoices);
    } catch (e) {
      console.log(e);
    }
  },

  async loadProducts({ commit }) {
    commit("setLoadingCards", true);
    commit("setProducts", []);
    try {
      const products = [];
      await db
        .collection("products")
        .where("active", "==", true)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            const product = doc.data();
            const priceSnap = await doc.ref
              .collection("prices")
              .where("active", "==", true)
              .get();
            priceSnap.docs.forEach((doc) => {
              const rec = doc.data();
              products.push({
                amount: rec.unit_amount / 100,
                currency: rec.currency,
                interval: rec.interval,
                testMode: product.metadata.testMode || false,
                onWebsite: product.metadata.onWebsite || false,
                contactSales: product.metadata.contactSales || false,
                order: parseFloat(product.metadata.order) || 0,
                name: product.name,
                priceId: doc.id,
                productId: rec.product,
                intervalCount: rec.interval_count,
                taxBehavior: rec.tax_benhavior,
                type: rec.type,
              });
            });
          });
        });
      commit("setProducts", products);
      commit("setLoadingCards", false);
    } catch (error) {
      commit("setLoadingCards", false);
      console.log(error);
    }
  },

  async handleCheckoutNewProgram({ commit, dispatch, getters }, payload) {
    commit("setPrgTitleExists", false);
    commit("setPrgUrlExists", false);
    // final check that program name and url do not exist
    try {
      const titleCheck = payload.titleUppercase;
      const urlCheck = payload.url;

      const collectionRef = db.collection("programs");
      const titleCheckQuery = collectionRef
        .where("titleUppercase", "==", titleCheck)
        .get();
      const urlCheckQuery = collectionRef.where("url", "==", urlCheck).get();
      const checkRequests = [titleCheckQuery, urlCheckQuery];

      const [titleQuerySnapshot, urlQuerySnapshot] = await Promise.all(
        checkRequests
      );
      if (titleQuerySnapshot.size > 0) {
        // If program title is duplicated...
        commit("setPrgTitleExists", true);
        commit("setLoading", false);
        return;
      }
      if (urlQuerySnapshot.size > 0) {
        // If program url is duplicated...
        commit("setPrgUrlExists", true);
        commit("setLoading", false);
        return;
      }
    } catch (err) {
      console.log(err);
    }

    // now create the program
    try {
      // create the program then fetch the program id to add to the subscription meta data
      const programRef = await db.collection("programs").add(payload);
      const id = programRef.id;

      // assign the program to a temporary sub domain
      // need to create an object here to include both the id and the subdomain
      const siteObj = {
        id: id,
        incentableSubdomain: payload.url, // URL IS BRINING OVER THE FULL URL , NEEDS TO BE JUST THE SUBDOMAIN
      };
      dispatch("assignSite", siteObj);

      // commit new program to state and log
      commit("createProgram", { ...payload, id: id });
      dispatch("addLog", { action: "Finish Create App" });

      // create the stripe checkout session inside the admin document
      const docRef = await db
        .collection("admins")
        .doc(getters.user.id)
        .collection("checkout_sessions")
        .add({
          metadata: {
            incentableProgramId: id,
            createdBy: getters.user.id,
          },
          automatic_tax: true, // Automatically calculate tax based on the customer's address
          price: payload.priceId,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });

      if (this.getters.isIncentableAdmin) {
        // console.log('is an incentable admin')
        // router.push('/')
        dispatch("setSetupMode", null);
      } else {
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, url } = snap.data();
          if (error) {
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
          setTimeout(() => {
            this.$store.dispatch("setLoading", false);
          }, 15000);
        });
      }
    } catch (err) {
      console.log(err);
      setTimeout(() => {
        this.$store.dispatch("setLoading", false);
      }, 15000);
    }
  },

  async handleCheckoutExistingProgram({ dispatch, getters }, payload) {
    // commit new log
    dispatch("addLog", { action: "Re subscribe" });

    // create the stripe checkout session inside the admin document
    const docRef = await db
      .collection("admins")
      .doc(getters.user.id)
      .collection("checkout_sessions")
      .add({
        metadata: {
          incentableProgramId: payload.incentableProgramId,
          createdBy: getters.user.id,
        },
        automatic_tax: true, // Automatically calculate tax based on the customer's address
        price: payload.priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });

    if (this.getters.isIncentableAdmin) {
      // console.log('is an incentable admin')
      // router.push('/')
      dispatch("setSetupMode", null);
    } else {
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        if (error) {
          alert(`An error occured: ${error.message}`);
        }
        if (url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(url);
        }
        setTimeout(() => {
          this.$store.dispatch("setLoading", false);
        }, 15000);
      });
    }
  },

  async stripeCustomerPortal({ commit }) {
    commit("setLoading", true);
    const { data } = await stripePortalRef({
      returnUrl: window.location.origin,
    });
    window.location.assign(data.url);
    setTimeout(() => {
      this.$store.dispatch("setLoading", false);
    }, 10000);
  },
};

const mutations = {
  setProducts(state, payload) {
    state.products = payload;
  },
  setCurrentSubscription(state, payload) {
    state.currentSubscription = payload;
  },
  setSubscriptions(state, payload) {
    state.subscriptions = payload;
  },
  setManualSubscriptions(state, payload) {
    state.manualSubscriptions = payload;
  },
  setStripeInvoices(state, payload) {
    state.stripeInvoices = payload;
  },
  setPlansTable(state, payload) {
    state.plansTable = payload;
  },
};
const getters = {
  products(state) {
    return state.products;
  },
  currentSubscription(state) {
    return state.currentSubscription;
  },
  subscriptions(state) {
    return state.subscriptions;
  },
  manualSubscriptions(state) {
    return state.manualSubscriptions;
  },
  stripeInvoices(state) {
    return state.stripeInvoices;
  },
  plansTable(state) {
    return state.plansTable;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

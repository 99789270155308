<template>
  <div>
    <v-row no-gutters class="mb-2 custom-label">{{ field.label }}</v-row>
    <v-row no-gutters>
      <v-col
        v-for="(field, key) in activeTableFields"
        :key="key"
        :cols="field.cols"
        class="pr-2"
      >
        <v-text-field
          v-show="field.type === 'textfield'"
          outlined
          dense
          :label="field.label"
          :clearable="field.clearable"
          background-color="white"
        ></v-text-field>
        <v-autocomplete
          v-show="field.type === 'dropdown'"
          outlined
          dense
          :label="field.label"
          :clearable="field.clearable"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="1">
        <v-btn color="green" class="button-adjust" elevation="0" dark
          >add</v-btn
        >
      </v-col>
    </v-row>
    <v-sheet class="mb-6" outlined rounded color="grey">
      <v-data-table :headers="headers"> </v-data-table>
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: ["field"],
  data: () => ({}),
  computed: {
    activeTableFields() {
      if (this.field.tableFields) {
        return this.field.tableFields.filter((el) => el.active === true);
      }
      return [];
    },
    headers() {
      const headers = this.activeTableFields.map((obj) => {
        const { label: text } = obj;
        return {
          text,
        };
      });
      return headers;
    },
    validationRecords() {
      return this.$store.getters.validationRecords;
    },
    loadingValidationRecords() {
      return this.$store.getters.loadingValidationRecords;
    },
  },
  methods: {},
};
</script>

<style>
.custom-label {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: rgb(0, 0, 0) !important;
}
.button-adjust {
  height: 39px !important;
  margin-top: 1px !important;
  margin-left: -0px !important;
}
</style>

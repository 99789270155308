<template>
  <div>
    <v-system-bar
      v-if="isIncentableAdmin && availableSites < 2"
      height="30"
      color="primary"
      class="white--text"
    >
      <v-row justify="center">
        ALERT: {{ availableSites }} temp domains available. Add more now.
      </v-row>
    </v-system-bar>
    <v-toolbar dense elevation="0" color="transparent">
      <v-row no-gutters justify="center">
        <v-col md="12" lg="8">
          <v-row no-gutters justify="center">
            <v-col>
              <v-tabs v-model="tab" class="mx-0 mt-3">
                <v-tab class="tab" @click="clearSearch()">Programs</v-tab>
                <v-tab class="tab" @click="clearSearch()">Themes</v-tab>
                <v-tab class="tab" @click="clearSearch()">Archive</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="auto">
              <v-checkbox
                v-show="tab === 0"
                class="mt-7 mr-3"
                label="My Programs"
                v-model="createdByMe"
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-text-field
                class="mt-6 caption"
                v-model="search"
                solo-inverted
                dense
                flat
                hide-details
                prepend-inner-icon="search"
                clearable
                @click:clear="clearSearch()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row no-gutters justify="center">
      <v-col md="12" lg="8">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <my-apps
              :search="search"
              :programs="programs"
              :createdByMe="createdByMe"
            />
          </v-tab-item>
          <v-tab-item>
            <themes :search="search" :programs="programThemes" />
          </v-tab-item>
          <v-tab-item>
            <archive :search="search" :programs="programs" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MyApps from "./Components/MyApps.vue";
import Themes from "./Components/Themes.vue";
import Archive from "./Components/Archive.vue";

export default {
  components: { MyApps, Themes, Archive },
  data: () => ({
    tab: null,
    demoBooking: true,
    search: "",
    trial: true,
    createdByMe: false,
  }),
  created() {
    this.$store.dispatch("clearCurrentProgram");
    this.$store.dispatch("initialize");
    this.$store.commit("setNavMenu", false);
    this.$store.dispatch("setToolbar", true);
    this.$store.dispatch("setFooter", true);
    this.$store.dispatch("loadPrograms");
    this.$store.dispatch("loadSites");
    this.$store.dispatch("loadSubscriptions");
    this.$store.dispatch("loadProgramThemes");
  },
  computed: {
    programs() {
      return this.$store.state.program.programs;
    },
    programThemes() {
      return this.$store.state.program.programThemes;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    sites() {
      return this.$store.state.site.sites;
    },
    availableSites() {
      return this.sites.filter((el) => el.status === "Available").length;
    },
  },
  methods: {
    clearSearch() {
      this.search = "";
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="px-4">
    <v-card class="pa-6 el mr-6">
      <v-row no-gutters>
        <v-col lg="9" class="pr-16">
          <v-row no-gutters class="incentable-form-heading">
            <v-col> Video </v-col>
          </v-row>

          <v-row class="incentable-form-subheading" no-gutters>
            This video wiil be added to the quiz when a YouTube code is entered
            below
          </v-row>

          <v-row class="incentable-form-subheading mt-n2" no-gutters>
            Only enter the YouTube video code, not the full web address
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on" size="20"
                  >help</v-icon
                >
              </template>
              <span>
                Do not enter the full web address of the YouTube video you wish
                to display
                <br />https://www.youtube.com/watch?v=hJdbCCYxxAA
                <br /><br />Only enter the video id, which is the part after '='
                hJdbCCYxxAA
              </span>
            </v-tooltip>
          </v-row>
          <v-row dense>
            <v-col lg="4">
              <v-text-field
                v-model="youTubeId"
                label="YouTube Video ID"
                type="text"
                @input="changed()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="youTubeId">
            <v-col cols="8">
              <vue-plyr :options="options">
                <div
                  data-plyr-provider="youtube"
                  :data-plyr-embed-id="videoCode"
                ></div>
              </vue-plyr>
            </v-col>
          </v-row>
          <v-row no-gutters v-else>
            <v-col>
              <v-card
                height="333px"
                width="592px"
                flat
                class="mb-5 pt-0 mt-0 grey lighten-3"
              >
                <v-row justify="center" align="stretch" style="height: 333px">
                  <v-icon color="primary" size="80">
                    play_circle_filled
                  </v-icon>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["quizId"],
  data: function () {
    return {
      youTubeId: "",
      options: {
        autopause: true,
        resetOnEnd: true,
        hideYouTubeDOMError: true,
        youtube: {
          noCookie: true,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          controls: 0,
        },
      },
    };
  },
  created() {
    if (this.quizId) {
      this.youTubeId = this.quiz.media.youTubeId;
    }
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    quiz() {
      return this.$store.state.quiz.quiz;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    quizChanged() {
      return this.$store.state.quiz.quizChanged;
    },
    quizUpdates() {
      return this.$store.state.quiz.quizUpdates;
    },
    videoCode() {
      return "https://www.youtube-nocookie.com/embed/" + this.youTubeId;
    },
  },
  methods: {
    changed() {
      const update = {
        ...this.quizUpdates,
        media: {
          youTubeId: this.youTubeId,
        },
      };
      this.$store.dispatch("setQuizChanged", true);
      this.$store.dispatch("patchQuiz", update);
    },
  },
  watch: {},
};
</script>

<style scoped>
.player {
  --plyr-color-main: #1ac266;
}
</style>

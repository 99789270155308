<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
        <v-overlay absolute color="primary" :opacity="systemTheme.cardOpacity">
        </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar size="100" color="white" outline>
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ menuId ? "Edit " : "Add New " }}
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <form data-vv-scope="menu">
        <v-card-text class="px-16">
          <v-row dense>
            <v-text-field
              v-model="title"
              label="Menu Label"
              v-validate="'required'"
              :error-messages="errors.collect('menu.title')"
              name="title"
            />

            <v-select
              name="contentType"
              label="Content Type"
              :items="contentTypes"
              v-model="selectedContentType"
              v-validate="'required'"
              :error-messages="errors.collect('menu.contentType')"
              @change="selectedContent = ''"
              item-text="text"
              item-value="value"
            />

            <v-select
              v-show="selectedContentType === 'Custom Page'"
              name="content"
              label="Page"
              :items="pages"
              v-model="selectedContent"
              v-validate="'required'"
              :error-messages="errors.collect('menu.content')"
              item-text="title"
              item-value="id"
            ></v-select>

            <v-select
              v-show="selectedContentType === 'Blog'"
              name="content"
              label="Blog"
              :items="blogs"
              v-model="selectedContent"
              v-validate="'required'"
              :error-messages="errors.collect('menu.content')"
              item-text="title"
              item-value="id"
            ></v-select>

            <v-select
              v-show="selectedContentType === 'Reward Catalogue'"
              name="content"
              label="Reward Catalogue"
              :items="rewardCatalogues"
              v-model="selectedContent"
              v-validate="'required'"
              :error-messages="errors.collect('menu.content')"
              item-text="title"
              item-value="id"
            ></v-select>

            <v-select
              v-show="selectedContentType === 'System Page'"
              name="content"
              label="System Page"
              :items="systemPages"
              v-model="selectedContent"
              v-validate="'required'"
              :error-messages="errors.collect('menu.content')"
              item-text="text"
              item-value="value"
            ></v-select>

            <v-select
              name="status"
              :items="statuses"
              label="Status"
              v-model="selectedStatus"
              v-validate="'required'"
              :error-messages="errors.collect('menu.status')"
              item-text="text"
              item-value="value"
              append-icon="power_settings_new"
            ></v-select>

            <v-checkbox
              label="Highlight in App"
              v-model="highlight"
            ></v-checkbox>
          </v-row>
          <v-row justify="center">
            <v-hover>
              <v-card
                width="100px"
                height="100px"
                color="secondary"
                flat
                class="pt-3"
                ripple
                hover
                elevation="0"
                v-on:click="handleOpenIconSelector"
                slot-scope="{ hover }"
              >
                <v-fade-transition>
                  <v-overlay v-if="hover" opacity="0.3" absolute>
                    <v-btn icon class="primary" fab elevation="0" large>
                      <v-icon>find_replace</v-icon>
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
                <div>
                  <v-row class="pt-3" justify="center">
                    <img :src="'/img/icons/' + iconFile" width="45px" />
                  </v-row>
                </div>
              </v-card>
            </v-hover>
          </v-row>

          <v-alert v-if="errorMessage" type="warning" :value="true">
            {{ errorMessage }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()"> Cancel </v-btn>
          <v-btn
            @click="handleSave('menu')"
            color="primary"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>

    <icon-selector
      :open="openIconSelector"
      @onClose="handleCloseIconSelector"
      @onSave="handleSaveIconSelector"
    />
  </v-dialog>
</template>
<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  created() {},
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    menuId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => "Menu name is required",
        },
        contentType: {
          required: () => "Select Content Type",
        },
        content: {
          required: () => "Select Content",
        },
        status: {
          required: () => "Status is required",
        },
      },
    },
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    systemPages: [
      { text: "Home", value: "Home" },
      { text: "Contact", value: "Contact" },
      { text: "Offers", value: "Offers" },
      { text: "Quizzes", value: "Quizzes" },
    ],
    contentTypes: [
      { text: "Blog", value: "Blog" },
      { text: "Reward Catalogue", value: "Reward Catalogue" },
      { text: "Custom Page", value: "Custom Page" },
      { text: "System Page", value: "System Page" },
    ],
    openIconSelector: false,
    title: "",
    formTitle: "Menu Item",
    formIcon: "menu",
    selectedStatus: "Active",
    selectedContentType: "",
    selectedContent: "",
    iconFile: "",
    highlight: false,
    errorMessage: "",
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    pages() {
      return this.$store.state.page.pages;
    },
    blogs() {
      return this.$store.state.blog.blogs;
    },
    rewardCatalogues() {
      return this.$store.state.rewardcatalogue.rewardCatalogues;
    },
    menus() {
      return this.$store.state.primarymenu.primaryMenus;
    },

    // Functions common to all tables //
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    snackbar() {
      return this.$store.getters.snackbar;
    },
  },

  methods: {
    handleOpenIconSelector() {
      this.openIconSelector = true;
    },

    handleCloseIconSelector() {
      this.openIconSelector = false;
    },

    handleSaveIconSelector(icon) {
      this.iconFile = icon.svgFile;
      this.openIconSelector = false;
    },

    handleClose() {
      this.clear();
      this.$emit("onClose");
    },

    clear() {
      this.title = "";
      this.selectedStatus = "";
      this.selectedContentType = "";
      this.selectedContent = "";
      this.iconFile = "";
      this.highlight = false;
      (this.errorMessage = ""), this.$validator.reset();
      this.$store.dispatch("setLoading", false);
    },

    handleSave(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }
        if (!this.iconFile) {
          this.errorMessage = "Icon is required";
          return;
        }

        this.$store.dispatch("setLoading", true);

        const payload = {
          id: this.menuId,
          status: this.selectedStatus,
          title: this.title,
          titleUppercase: this.title.toUpperCase(),
          highlight: this.highlight || false,
          contentType: this.selectedContentType,
          content: this.selectedContent,
          iconFile: this.iconFile || "",
        };

        const action = this.menuId ? "updatePrimaryMenu" : "createPrimaryMenu";

        this.$store
          .dispatch(action, payload)
          .then(() => {
            this.$store.dispatch("setLoading", false);
            this.handleClose();
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch("setLoading", false);
          });
      });
    },
  },
  watch: {
    menuId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedMenu = this.menus.find((item) => item.id === newVal);
          const { title, status, content, contentType, iconFile, highlight } =
            selectedMenu;
          this.title = title;
          this.selectedStatus = status || "Active";
          this.selectedContentType = contentType;
          this.selectedContent = content;
          this.iconFile = iconFile || "";
          this.highlight = highlight || false;
        }
      },
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <v-app-bar
      class="page-bar"
      dense
      fixed
      color="grey lighten-4"
      elevate-on-scroll
    >
      <v-row no-gutters justify="center" class="mx-0">
        <v-col cols="12" lg="8">
          <span class="page-heading">Subscriptions</span>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-row no-gutters justify="center" class="mx-3 mt-14">
      <v-col cols="12" lg="8">
        <subscriptions-table :subscriptions="subscriptions" class="my-6"></subscriptions-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubscriptionsTable from './SubscriptionsTable.vue'

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: { SubscriptionsTable },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
    this.$store.dispatch('clearCurrentProgram'),
    this.$store.dispatch('initialize'),
    this.$store.dispatch('loadSubscriptions')
    this.$store.commit('setNavMenu', false),
    this.$store.dispatch('setToolbar', true),
    this.$store.dispatch('setFooter', true),
    this.$store.dispatch('loadProgramsQuickList'),
    this.$validator.localize('en', this.dictionary)
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    subscriptions () {
      return this.$store.state.plans.subscriptions
    },
  },
  methods: {
  },
}
</script>

<style scoped>

</style>

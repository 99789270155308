<template>
<v-dialog v-model="open" max-width="1000px" persistent>
    <v-card>
      <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="primary"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            {{ pageId ? 'Edit' : 'Add New'}} Page
          </v-card-title>
        </v-row>

        <v-card-text class="px-16">
          <form data-vv-scope="page">
            <v-tabs
              v-model="tab"
              class="pb-10"
            >
              <v-tab class="tab">
                Content
              </v-tab>
              <v-tab class="tab">
                Resources
              </v-tab>
              
            </v-tabs>
            <v-tabs-items v-model="tab">

              <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
                <v-row dense>
                  <v-col lg="9" class="pr-16">
                    <v-text-field
                      v-model="title"
                      label="Page Title"
                      v-validate="'required'"
                      :error-messages="errors.collect('page.title')"
                      name="title"
                    />
                    <v-text-field
                      v-model="subheading"
                      label="Page Subheading"
                      name="subheading"
                    />
                    <v-textarea
                      v-model="body"
                      label="Page Text"
                      name="body"
                      type="text"
                      filled
                    />
                  </v-col>
                  
                  <v-col lg="3">
                    
                    <v-select
                      name="status"
                      :items="statuses"
                      label="Status"
                      v-model="selectedStatus"
                      v-validate="'required'"
                      :error-messages="errors.collect('page.status')"
                      item-text="text"
                      item-value="value"
                      append-icon="power_settings_new"
                    />
            
                    <v-select
                      v-model="selectedCompanyTags"
                      :items="companyTags"
                      item-text="tag"
                      item-value="id"
                      label="Company Tags"
                      small-chips
                      chips
                      multiple
                      deletable-chips
                      append-icon="local_offer"
                    />
                  
                    <v-select
                      v-model="selectedMemberTags"
                      :items="memberTags"
                      item-text="tag"
                      item-value="id"
                      label="Member Tags"
                      small-chips
                      chips
                      multiple
                      deletable-chips
                      append-icon="local_offer"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              
              <v-tab-item transition="fade-transition" reverse-transition="fade-transition">
                <v-row no-gutters>
                  <v-col lg="6" class="pr-6">                   
                    <v-card
                      class="grey lighten-3 pt-3"
                      elevation="0"
                    >
                      <v-alert
                        v-if="!permitted"
                        class="ml-2 mr-2"
                        border="left" elevation="0" color="primary" type="error"
                      >
                        <span class="pl-1 incentable-alert">Invalid file type. Images must be .pdf .jpg, .jpeg or .png</span> 
                      </v-alert>
                      <v-alert
                        v-if="fileToUpload && !pageId"
                        class="ml-2 mr-2"
                        border="left" elevation="0" color="primary" type="error"
                      >
                        <span class="pl-1 incentable-alert">The page needs to be saved before files can be attached</span> 
                      </v-alert>
                      <v-row 
                        class="incentable-form-heading ml-3"
                        no-gutters
                      >
                        Add File
                      </v-row>
                      <v-row 
                        class="caption ml-3"
                        no-gutters
                      >
                        Accepted file types are .pdf .jpg, .jpeg or .png
                      </v-row>
                      
                      <form data-vv-scope="attachment">

                        <v-card-text class="px-6">
                          <v-text-field
                            v-model="fileDescription"
                            label="Description"
                            name="description"
                            v-validate="'required'"
                            :error-messages="errors.collect('attachment.description')"
                          />

                          <v-file-input
                            v-model="fileToUpload"
                            accept="image/png, image/jpg, image/jpeg, .pdf"
                            label="File input"
                          ></v-file-input>

                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            @click="resetAttachmentForm()"
                            :disabled="demo"
                          >
                            reset
                          </v-btn>
                          <v-btn
                            color="primary"
                            :disabled="!fileToUpload  || fileUploading || demo || !pageId"
                            @click="handleUploadFile('attachment')"
                            elevation="0"
                          >
                            {{fileUploading ? `Uploading - ${fileUploadingProgress}%` : 'Add File'}}
                          </v-btn>
                        </v-card-actions>
                      </form> 
                    </v-card>
                  </v-col>
                  
                  <v-col lg="6" class="pl-6">                   
                    <v-card
                      class="grey lighten-3 mb-6"
                      elevation="0"
                    >
                      <v-row 
                        class="incentable-form-heading pt-3 ml-3"
                        no-gutters
                      >
                        Add Link
                      </v-row>

                      <form data-vv-scope="link">

                        <v-card-text class="px-6">
                          <v-text-field
                            v-model="externalDescription"
                            label="Description"
                            v-validate="'required'"
                            name="linkDescription"
                            :error-messages="errors.collect('link.linkDescription')"
                          />

                          <v-text-field
                            v-model="externalUrl"
                            label="Web Address"
                            hint="Enter the full URL (including https:// or http://)"
                            persistent-hint
                          />
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                            <v-btn
                              text
                              @click="resetLinkForm()"
                              :disabled="demo"
                              >
                              reset
                            </v-btn>
                            <v-btn
                              color="primary"
                              @click="handleAddLink('link')"
                              :disabled="!externalUrl || demo"
                              elevation="0"
                            >
                              Add Link
                            </v-btn>
                        </v-card-actions>
                      </form>
 
                    </v-card>
                  </v-col>
                </v-row>

                <v-row
                  no-gutters
                  class="incentable-form-heading ml-3"
                >
                  Resources
                </v-row>

                <v-data-table
                  class="px-6 py-4"
                  :headers="headers"
                  :items="resources"
                  :search="search"
                  :options.sync="options"
                  no-data-text="No Records..."
                >
                  <template v-slot:item.type="{ item }">
                    <div v-if="item.type === 'file'">
                      <v-icon>attach_file</v-icon>
                      File 
                    </div>
                    <div v-if="item.type === 'external'">
                      <v-icon>launch</v-icon>
                      Link
                    </div>
                  </template>

                  <template v-slot:item.filename="{ item }">
                    <span
                      v-if="demo"
                    >
                      {{ item.filename }}
                    </span>
                    <a
                      v-else
                      :href="item.url"
                      target="_blank"
                      class="link"
                    >
                      {{ item.filename }}
                    </a>
                  </template>
                  
                  <template v-slot:[`item.created`]="{ item }">
                    {{ item.created }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      icon
                      @click="handleDeleteResource(item)"
                      small
                      color="primary"
                      :disabled="demo"
                      >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              
              </v-tab-item>
            </v-tabs-items>
        
            <v-alert
              v-if="errorMessage"
                type="error"
                text
                border="left"
              >
              {{ errorMessage }}
            </v-alert>

            
          
          </form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            :disabled="fileUploading"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="fileUploading || demo"
            @click="handleSave('page')"
            elevation="0"
            >Save
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>

  
</template>

<script>
import { storage } from '@/firebase'
import { nanoid } from 'nanoid'
export default {
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    pageId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  created () {
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },

  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => 'Page title is required'
        },
        status: {
          required: () => 'Status is required'
        },
        heading: {
          required: () => 'A page heading is required'
        },
        description: {
          required: () => 'A file description is required'
        },
        linkDescription: {
          required: () => 'A link description is required'
        }
      }
    },
    permittedFiles: [
      { ext: 'jpg', icon: 'fas fa-file-file', color: 'purple' },
      { ext: 'jpeg', icon: 'fas fa-file-file', color: 'purple' },
      { ext: 'png', icon: 'fas fa-file-file', color: 'purple' },
      { ext: 'PNG', icon: 'fas fa-file-file', color: 'purple' },
      { ext: 'JPG', icon: 'fas fa-file-file', color: 'purple' },
      { ext: 'JPEG', icon: 'fas fa-file-file', color: 'purple' },
      { ext: 'PDF', icon: 'fas fa-file-file', color: 'purple' },
      { ext: 'pdf', icon: 'fas fa-file-file', color: 'purple' }
    ],
    statuses: [
      { text: 'Active', value: 'Active' },
      { text: 'Inactive', value: 'Inactive' }
    ],
    tab: null,
    fileType: '',
    heading: '',
    subheading: '',
    body: '',
    selectedStatus: 'Inactive',
    selectedMemberTags: [],
    selectedCompanyTags: [],
    resources: [],
    isProcessing: false,
    errorMessage: '',
    externalDescription: '',
    externalUrl: '',
    id: '',
    permitted: true,
    fileDescription: '',
    fileToUpload: null,
    fileUploading: false,
    fileUploadingProgress: 0,
    fileUploadTask: null,

    // Component settings
      formTitle: 'Page',
      formIcon: 'web',
      tableTitle: 'Resources',
      tableSubtitle: 'Add documents or links',
      tableIcon: 'insert_drive_file',
      search: '',
      title: '',
      selectedResourceId: '',
      dialogNewOrEditOpen: false,
      dialogDeleteOpen: false,
    
    // Table settings
      options: {
        sortBy: ['title'],
        sortDesc: [false],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: true,
        filter: true,
        upload: true,
        download: true,
        more: true,
      },
      
    // Table columns
    headers: [
      { text: 'Type', value: 'type', align: 'left' },
      { text: 'Description', value: 'description', align: 'left' },
      { text: 'File', value: 'filename', align: 'left' },
      { text: 'Remove', value: 'actions', align: 'center', sortable: false }
    ],
  }),

  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    programId () {
      return this.$store.getters.programId
    },
    companyTags () {
      return this.$store.state.companytag.companyTags
    },
    memberTags () {
      return this.$store.state.membertag.memberTags
    },
    pages () {
      return this.$store.state.page.pages
    },

    // Functions common to all tables //
    loadingTable () {
    return this.$store.getters.loadingTable
    },
    loading () {
    return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    snackbar () {
      return this.$store.getters.snackbar
    },
  },

  methods: {

    resetAttachmentForm() {
      this.fileDescription = '',
      this.fileToUpload = null,
      this.fileUploading = false,
      this.fileUploadingProgress = 0,
      this.fileUploadTask = null,
      this.permitted = true
      this.fileType = ''
      this.$validator.reset();
    },

    resetLinkForm() {
      this.externalDescription = '',
      this.externalUrl = '',
      this.$validator.reset();
    },

    handleUploadFile(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        if (!this.fileToUpload) {
          return;
        }
        this.fileType = this.fileToUpload.name.split('.').pop()
        if (this.permittedFiles.find(el => el.ext === this.fileType)) {
          console.log(this.fileType , ' permitted')
          this.permitted = true
          this.id = nanoid()
          this.fileUploadTask = storage.ref(`uploads/${this.programId}/pageResources/${this.id}_${this.fileToUpload.name}`).put(this.fileToUpload);
          this.fileUploadingProgress = 0;
          this.fileUploading = true;
        } else {
          console.log(this.fileType , ' not permitted')
          this.permitted = false
        }

      });
    },

    handleFinishUpload(url) {
      var filename = this.fileToUpload.name
      this.fileUploading = false;
      this.fileUploadingProgress = 0;
      this.fileUploadTask = null;
      this.fileToUpload = null;

      this.resources = [
        ...this.resources,
        {
          id: this.id,
          type: 'file',
          description: this.fileDescription,
          url,
          ext: this.fileType || '',
          created: new Date(),
          filename: filename,
          storagePath: '/uploads/' + this.programId + '/pageResources/' + this.id + '_' + filename
        },
      ];
      this.handleSave('page', true)
      this.resetAttachmentForm()
    },

    handleAddLink(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        if (!this.externalUrl) {
          return;
        }
        var id = nanoid()
        this.resources = [
          ...this.resources,
          {
            type: 'external',
            id: id,
            description: this.externalDescription,
            url: this.externalUrl,
            created: new Date(),
          },
        ];
        this.resetLinkForm();
      });
    },

    async handleDeleteResource(resource) {
      await this.$store.dispatch('deleteResourceFilesFromStorage', resource)
      this.resources = this.resources.filter(item => item.id !== resource.id)
      this.handleSave('page', true)
    },

    handleClose () {
      this.clear();
      this.$emit('onClose');
    },

    clear () {
      this.id,
      this.heading = '',
      this.subheading = '',
      this.created = '',
      this.body = '',
      this.selectedStatus =  '',
      this.selectedMemberTags = [],
      this.selectedCompanyTags = [],
      this.resources = [],
      this.errorMessage = '',
      this.externalDescription = '',
      this.externalUrl = '',
      this.fileDescription = '',
      this.fileToUpload = null,
      this.fileUploading = false,
      this.fileUploadingProgress = 0,
      this.fileUploadTask = null,
      this.title = ''
      this.tab = null,
      this.$validator.reset();
      this.$store.dispatch('setLoading', false);
    },

    handleSave (scope, keepOpen) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }

        this.$store.dispatch('setLoading', true);

        const payload = {
          id: this.pageId,
          title: this.title || '',
          titleUppercase: this.title.toUpperCase(),
          heading: this.heading || '',
          subheading: this.subheading || '',
          body: this.body || '',
          status: this.selectedStatus,
          memberTags: this.selectedMemberTags || [],
          companyTags: this.selectedCompanyTags || [],
          resources: this.resources || [],
        };

        const action = this.pageId ? 'updatePage' : 'createPage';

        this.$store.dispatch(action, payload)
          .then(() => {
            this.$store.dispatch('setLoading', false);
            if(!keepOpen) {
              this.handleClose();
            }
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch('setLoading', false);
          });
      });
    },
  },
  watch: {
    fileUploadTask: function () {
      if (!this.fileUploadTask) {
        return;
      }

      this.fileUploadTask.on('state_changed', sp => {
        if (sp.totalBytes <= 0) {
          return;
        }
        this.fileUploadingProgress = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      () => {
        this.fileUploading = false;
      },
      () => {
        this.fileUploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.handleFinishUpload(downloadURL);
        });
      });
    },
    pageId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedPage = this.pages.find(item => item.id === newVal);
          const {
            status, companyTags, memberTags, title, heading, subheading, body, resources
          } = selectedPage;
          this.title = title;
          this.heading = heading;
          this.subheading = subheading;
          this.body = body;
          this.selectedStatus = status || 'Active';
          this.selectedMemberTags = memberTags || [];
          this.selectedCompanyTags = companyTags || [];
          this.resources = resources || []; 
        }
      },
    },
  },
}
</script>

<style>

</style>

/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  databuckets: [],
  loadingDatabuckets: false,
  databucketTotals: [], // used for tiers and badges
};

const actions = {
  async loadDatabuckets({ commit, getters }) {
    //console.log('loading databuckets')
    commit("setLoadingCards", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("databuckets")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const databuckets = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      databuckets.push({
        ...data,
        id: doc.id,
        status: data.status,
        title: data.title,
        displayTitle: data.displayTitle,
        hasTargets: data.hasTargets,
        pointsRedeemable: data.pointsRedeemable,
        participantType: data.participantType,
        created: data.created.toDate(),
        updated: data.updated.toDate(),
      });
    });

    commit("setDatabuckets", databuckets);
    commit("setLoadingCards", false);
  },

  async loadActiveDatabuckets({ commit, getters }) {
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("databuckets")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const databuckets = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      databuckets.push({
        ...data,
        id: doc.id,
        status: data.status,
        title: data.title,
        displayTitle: data.displayTitle,
        hasTargets: data.hasTargets,
        pointsRedeemable: data.pointsRedeemable,
        participantType: data.participantType,
        created: data.created.toDate(),
        updated: data.updated.toDate(),
      });
    });

    commit("setDatabuckets", databuckets);
  },

  async createDatabucket({ dispatch, commit, getters }, payload) {
    const databucketsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await databucketsRef
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the title.";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Title is already registered.";
    }

    const databucket = {
      ...payload,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
      created: timestamp,
      updated: timestamp,
    };

    let newDatabucketRef;
    try {
      newDatabucketRef = await databucketsRef.add(databucket);
    } catch (e) {
      console.error(e);
      throw "error when creating a new data bucket";
    }

    // Note: server time is unavailable until we refetch.
    const tmpDatabucket = {
      ...databucket,
      id: newDatabucketRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createDatabucket", tmpDatabucket);
    dispatch("setSnackbar", "Databucket Created.");
  },
};

const mutations = {
  setDatabuckets(state, payload) {
    state.databuckets = payload;
  },

  setDatabucketTotals(state, payload) {
    state.databucketTotals = payload;
  },

  setLoadingDatabuckets(state, payload) {
    state.loadingDatabuckets = payload;
  },

  createDatabucket(state, payload) {
    state.databuckets = [...state.databuckets, payload];
  },
};

const getters = {
  databuckets(state) {
    return state.databuckets;
  },
  databucketTotals(state) {
    return state.databucketTotals;
  },
  loadingDatabuckets(state) {
    return state.loadingDatabuckets;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <v-app-bar
      class="page-bar"
      dense
      fixed
      color="grey lighten-4"
      elevate-on-scroll
    >
      <v-row no-gutters justify="center">
        <v-col cols="7" class="pt-2">
          <span class="page-heading">Book A Live Demo</span>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" elevation="0" @click="goback">go back</v-btn>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-row no-gutters justify="center">
      <v-col cols="5">
        <v-row no-gutters class="mt-16">
          <span class="mt-6 incentable-demo-card-subtitle" justify="center">
            <v-icon color="primary" class="mr-2">support_agent</v-icon>
            Meet with a Product Specialist to discover how easy it is to build an incentive program website with Incentable
          </span>
        </v-row>
        <v-row no-gutters>
          <span class="mt-1 mb-md-n9 incentable-demo-card-subtitle" justify="center">
            <v-icon color="primary" class="mr-1">videocam</v-icon>
            On the call, we'll provide a product tour and to teach you how to use Incentable, setting you up for success
          </span>
        </v-row>
      </v-col>
    </v-row>
    

    <!-- Calendly inline widget begin -->
    <div class="calendly-inline-widget" data-url="https://calendly.com/incentable/book-a-demo" style="min-width:320px;height:950px;"></div>
    <!-- Calendly inline widget end -->
  </div>
</template>
<script>
export default {
  mounted() {
    const calendlyScript = document.createElement("script");
    calendlyScript.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    document.head.appendChild(calendlyScript);
  },
  methods: {
    goback() {
      this.$store.dispatch('setBookDemo', false)
    }
  }
};
</script>
<style scoped>
.page-bar {
  margin-top: 96px !important;
}
.incentable-demo-card-subtitle {
    font-family: 'Roboto', sans-serif !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 1.5;
    color: #4E4E4E !important;
}
</style>

<template>
  <div class="mt-8 mr-8 mb-8">
    <v-card 
      class="grey lighten-4 rounded-t-lg rounded-b-0"
      elevation="8"
      >
      <v-card-title class="table-title">
        <v-icon class="pr-1">build</v-icon>Settings
      </v-card-title>
      <v-card-subtitle class="table-subtitle">
        Manage your organisation settings
      </v-card-subtitle>
    </v-card>

    <v-card
      class="rounded-t-0 rounded-b-lg"
      elevation="8"
      >

      <v-card-text class="px-16">

        <v-row dense>
          <v-col lg="6" class="pr-6">
              <v-text-field
                label="Organisation Name"
                v-model="currentOrganisation.title"
              />

            <v-row class="incentable-form-heading" no-gutters>  
              Primary Color            
            </v-row>
            <v-row class="incentable-form-subheading" no-gutters>
              Set your company primary color
            </v-row>
            <v-row>
              <v-col lg="10">
                <v-color-picker
                  mode="hexa"
                  v-model="currentOrganisation.primary"
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="6" class="pl-6">
            <v-row class="incentable-form-heading" no-gutters>  
              Button Text Color            
            </v-row>
            <v-row class="incentable-form-subheading" no-gutters>
              Choose the button text color to best suit your primary color
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-radio-group 
                  v-model="currentOrganisation.buttonTextColor">
                  <v-radio label="White" value="white--text"></v-radio>
                  <v-radio label="Black" value="black--text"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6" class="mt-5">
                <v-btn 
                  :color="currentOrganisation.primary"
                  :class="currentOrganisation.buttonTextColor"
                  >Button Color Sample
                </v-btn>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="handleSave"
          :loading="loading"
          color="primary"
          >Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  created () {
    this.$store.dispatch('initialize')
  },
  data: () => ({
    title: '',
    titleUppercase: '',
    primary: '',
    buttonTextColor: 'white--text',
    isProcessing: false,
  }),
  computed: {
    organisationId () {
      return this.$store.getters.organisationId
    },
    loading () {
      return this.$store.getters.loading
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme;
    },
  },
  methods: {
    handleSave() {
      const payload = {
        title: this.currentOrganisation.title,
        buttonTextColor: this.currentOrganisation.buttonTextColor,
        primary: this.currentOrganisation.primary,
        titleUppercase: this.currentOrganisation.title.toUpperCase(),
      };
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('updateCurrentOrganisation', payload)
      this.$store.dispatch('checkOrganisation')
        .then(() => {
          this.$store.dispatch('setLoading', false)
        })
        .catch(() => {
          this.$store.dispatch('setLoading', false)
        })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <v-dialog v-model="deleteDialogOpen" max-width="400px" persistent>
      <v-card>
        <v-row no-gutters class="page-heading ml-4 pt-3">
          Delete Opportunity Confirmation
        </v-row>

        <v-card-text class="px-16 pb-6 pt-6">
          <v-row no-gutters v-if="hasEntries">
            <v-col align="center">
              Opportunity cannot be deleted as it already has claims allocated
              to it
            </v-col>
          </v-row>
          <v-row no-gutters v-else>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{
                selectedItem.description
              }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDeleteDialog">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="primary"
            @click="handleDeleteOpportunity()"
            :loading="loading"
            elevation="0"
            :disabled="demo || hasEntries"
            >Yes, please delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["deleteDialogOpen", "selectedItem"],
  date: () => ({}),
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    hasEntries() {
      if (this.deleteDialogOpen) {
        return this.$store.state.offer.currentOffer.entries.some(
          (el) => el.opportunity === this.selectedItem.id
        );
      }
      return "";
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },
  methods: {
    closeDeleteDialog() {
      this.$emit("onCloseDeleteDialog");
    },
    handleDeleteOpportunity() {
      this.$emit("onHandleDelete", this.selectedItem);
    },
  },
};
</script>

<style></style>

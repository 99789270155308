<template>
  <div v-if="isLoggedIn">
    
    <div class="title"> Oops, Page not found. </div>
  </div>
  <div v-else>
    <div class="title"> Oops, Page not found. </div>
  </div>
</template>

<script>
export default {
  computed: {
    isLoggedIn () {
      return this.$store.getters.user;
    },
    programId () {
      return this.$store.getters.programId;
    },
  }
}
</script>

/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
import _ from "lodash";

const state = {
  primaryMenus: [],
  loadingPrimaryMenus: false,
};

const actions = {
  async loadPrimaryMenus({ commit, getters }) {
    commit("setLoadingPrimaryMenus", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("primaryMenus")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const primaryMenus = [];
    querySnapshot.forEach(function (doc) {
      primaryMenus.push({
        id: doc.id,
        title: doc.data().title,
        titleUppercase: doc.data().titleUppercase,
        status: doc.data().status,
        iconFile: doc.data().iconFile,
        highlight: doc.data().highlight,
        order: doc.data().order,
        contentType: doc.data().contentType,
        content: doc.data().content,
        created: doc.data().created,
        updated: doc.data().updated,
      });
    });

    // Note: Firebase 's orderby doesn't send the results if order by field doesn't exist
    const sorted = _.sortBy(primaryMenus, (item) => item.order);

    commit("setPrimaryMenus", sorted);
    commit("setLoadingPrimaryMenus", false);
  },

  async createPrimaryMenu({ dispatch, commit, getters }, payload) {
    const menusRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("primaryMenus");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await menusRef
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the title.";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Title is already registered.";
    }

    // eslint-disable-next-line no-unused-vars
    const { id, ...menuInput } = payload;

    const menu = {
      ...menuInput,
      order: getters.primaryMenus.length,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newMenuRef;
    try {
      newMenuRef = await menusRef.add(menu);
    } catch (e) {
      console.error(e);
      throw "error when creating a new menu";
    }

    // Note: server time is unavailable until we refetch.
    const tempMenu = {
      ...menu,
      id: newMenuRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createPrimaryMenu", tempMenu);
    dispatch("setSnackbar", "Menu Created.");
  },

  async updatePrimaryMenu({ dispatch, commit, getters }, payload) {
    const menusRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("primaryMenus");

    let storedMenu;
    try {
      const menuDoc = await menusRef.doc(payload.id).get();
      storedMenu = menuDoc.data();
    } catch (e) {
      storedMenu = null;
    }

    if (!storedMenu) {
      throw "Error occured when fetching the menu info";
    }

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await menusRef
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the title.";
    }

    if (titleDupSnapshot.size > 0) {
      let duplicated = false;
      titleDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Title is already registered.";
      }
    }

    const { id, ...menuInput } = payload;

    const menu = {
      ...menuInput,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await menusRef.doc(id).update(menu);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a menu";
    }

    const tempMenu = {
      ...storedMenu,
      ...menuInput,
      id,
      created: storedMenu.created.toDate(),
      updated: new Date(),
    };

    commit("updatePrimaryMenu", tempMenu);
    dispatch("setSnackbar", "Menu Updated");
  },

  async deletePrimaryMenu({ dispatch, commit, getters }, menuId) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("primaryMenus")
        .doc(menuId)
        .delete();
    } catch (e) {
      throw "Error occured when deleting a menu";
    }
    commit("deletePrimaryMenu", menuId);
    dispatch("setSnackbar", "Menu Deleted");
  },

  async sortPrimaryMenus({ dispatch, commit, getters }, payload) {
    const menusUpdateBatch = db.batch();
    payload.forEach((menu, index) => {
      const { id } = menu;
      const updateMenuRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("primaryMenus")
        .doc(id);
      const update = {
        order: index,
      };
      menusUpdateBatch.update(updateMenuRef, update);
    });

    await menusUpdateBatch.commit();

    commit("setPrimaryMenus", payload);
    dispatch("setSnackbar", "Menu Saved");
  },
};

const mutations = {
  setPrimaryMenus(state, payload) {
    state.primaryMenus = payload;
  },

  setLoadingPrimaryMenus(state, payload) {
    state.loadingPrimaryMenus = payload;
  },

  createPrimaryMenu(state, payload) {
    state.primaryMenus = [...state.primaryMenus, payload];
  },

  updatePrimaryMenu(state, payload) {
    state.primaryMenus = state.primaryMenus.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deletePrimaryMenu(state, payload) {
    state.primaryMenus = state.primaryMenus.filter(
      (item) => item.id !== payload
    );
  },
};

const getters = {
  primaryMenus(state) {
    return state.primaryMenus;
  },
  loadingPrimaryMenus(state) {
    return state.loadingPrimaryMenus;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="mt-8 mr-8 mb-8">
    
    <v-card 
      class="grey lighten-4 rounded-t-lg rounded-b-0"
      elevation="8"
      >
      <v-card-title>
        <div class="table-title"><v-icon class="pr-1">location_city</v-icon>Organisation</div>
      </v-card-title>
      <v-card-subtitle >
        <div class="table-subtitle">Manage your organisation</div>
      </v-card-subtitle> 
    </v-card>

    <v-card
      class="rounded-t-0 rounded-b-lg"
      elevation="8"
      >    

    <v-dialog v-model="dialogDelete" max-width="400px" persistent>
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="red"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" class="mt-n12" no-gutters>
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>
        
        <v-card-text class="px-16 py-10">
          <v-row dense>
            This cannot be undone. This organisation will be permanently deleted.
          </v-row>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDelete()" text>Cancel</v-btn>
          <v-btn 
            @click="deleteOrganisation(currentOrganisation.id)"
            :loading="loading"
            color="red"
            class="white--text"
            >Delete Organisation
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-card-text class="px-16">

      <v-row no-gutters class="incentable-form-heading">
        Organisation Details
      </v-row>

      <v-row dense>
        <v-col lg="4">
          <v-text-field
            name="Organisation Name"
            label="Organisation Name"
            v-model="currentOrganisation.title"
            readonly
            disabled
          ></v-text-field>
        </v-col>
        <v-col lg="4">
          <v-text-field
            name="Created"
            label="Created"
            :value="currentOrganisation.createdAt | date"
            readonly
            disabled
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters class="incentable-form-heading">
        Organisation Website Address
      </v-row>

        <v-row v-if="currentOrganisation.subdomainConnected" no-gutters  class="my-3">
          <v-icon color="green" class="mt-n1 pr-2">wifi</v-icon>Connected
            <a
              :href="`https://${currentOrganisation.orgSubdomain}.incentive.studio`"
              class="primary--text url ml-3"
              target="_blank"
            >
              {{currentOrganisation.orgSubdomain}}.incentive.studio
                <v-icon class="launch" size="18">launch</v-icon>
            </a>
        </v-row>

        <div v-else>
          <v-row no-gutters class="my-3">
            <v-icon color="amber" class="mt-n1 pr-2">wifi</v-icon>
            <span class="mr-3">Processing</span>
            {{currentOrganisation.orgSubdomain}}.incentive.studio
          </v-row>
          <v-row no-gutters class="primary--text">Please allow up to 48 hours for the website address connection to be resolved</v-row>
        </div>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          @click="openDelete()"
          color="primary"
          >Delete Organisation
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// import { db } from '@/main'

export default {
  data: () => ({
    dialogDelete: false,
  }),
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    loading () {
      return this.$store.getters.loading
    },
  },
  watch: {
  },
  created () {
    this.$store.dispatch('initialize')
  },
  methods: {
    deleteOrganisation (id) {
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('deleteOrganisation', id)
      this.$router.push('/organisations')
      this.$store.dispatch('setLoading', false)
      this.$store.dispatch('checkOrganisation')
      this.closeDelete()
    },

    initialize () {
    },

    closeDelete () {
      this.dialogDelete = false
    },

    openDelete () {
      this.dialogDelete = true
    },

    save () {
    }
  }
}
</script>

<style scoped>
.no-padding{
  padding: 0px !important;
  padding-top: 24px !important;
}
.launch {
  position: absolute;
  margin-top: -3px !important;
  padding-left: 1px;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 100;
}
.url {
  text-decoration: none;
}
</style>

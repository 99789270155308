<template>
  <div>
    <programs v-if="showPrograms" />
    <setup v-if="showSetup" :mode="setupMode"/>
    <demo-booking-calendar v-if="bookDemo"></demo-booking-calendar>
    <trial-consultation-booking-calendar v-if="bookTrialConsultation"></trial-consultation-booking-calendar>
  </div>
</template>

<script>
import Programs from './Programs/Programs.vue'
import Setup from './Setup/Setup.vue'
import DemoBookingCalendar from './DemoBookingCalendar.vue'
import TrialConsultationBookingCalendar from './TrailConsultationBookingCalendar.vue'
export default {
  components: { Programs, Setup, DemoBookingCalendar, TrialConsultationBookingCalendar },
  data: () => ({
  }),
  created () {
    this.$store.dispatch('setSetupMode', null)
    this.$store.dispatch('setBookTrialConsultation', false)
    this.$store.dispatch('setBookDemo', false)
    this.$store.dispatch('setShowPrograms', true)
  },
  computed: {
    isIncentableAdmin () {
      return this.$store.getters.isIncentableAdmin
    },
    setupMode() {
      return this.$store.getters.setupMode
    },
    showPrograms() {
      if(this.bookDemo || this.bookTrialConsultation || this.setupMode !== null) {
        return false
      } else if (this.isIncentableAdmin && this.setupMode === null) {
        return true
      }
      return this.$store.getters.showPrograms
    },
    showSetup() {
      if(this.setupMode) {
        return true
      }
      return false
    },
    bookDemo() {
      return this.$store.getters.bookDemo
    },
    bookTrialConsultation() {
      return this.$store.getters.bookTrialConsultation
    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>

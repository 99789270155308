<template>
<v-alert
  @input="onClose"
  dismissible
  type="warning"
  :value="true"
  color="primary"
  border="left"
  text
  style="z-index: 9000 !important"
  >
    {{ text }}
  </v-alert>
</template>

<script>
export default {
  props: ['text'],
  methods: {
    onClose () {
      this.$emit('dismissed')
    }
  }
}
</script>

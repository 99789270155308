/* eslint-disable no-console */
import { db, webApi, timestamp } from "@/firebase";
import firebase from "firebase/compat/app";
import axios from "axios";
import * as utils from "@/utils";
import { nanoid } from "nanoid";
import moment from "moment";
import _ from "lodash";
import { AVATAR_COLORS } from "@/constants/colors";

const state = {
  members: [],
  loadingMembers: false,
  currentMemberPoints: {},
};

const actions = {
  async loadMembers({ commit, getters }) {
    commit("setLoadingTable", true);
    commit("setLoadingMembers", true);

    const dbRef = db.collection("programs").doc(getters.programId);

    const membersData = dbRef
      .collection("members")
      .orderBy("fullnameUppercase")
      .onSnapshot(
        (querySnapshot) => {
          let members = [];
          querySnapshot.forEach((doc) => {
            members.push({
              id: doc.id,
              ...doc.data(),
              color: doc.data().color || null,
              avatar: doc.data().externalImageUrl
                ? doc.data().externalImageUrl
                : doc.data().image.smallUrl,
              fullname: `${doc.data().firstname} ${doc.data().lastname}`,
              firstLastInitial: `${doc.data().firstname} ${doc
                .data()
                .lastname.slice(0, 1)}`,
              created:
                doc.data().created && doc.data().created.toDate
                  ? doc.data().created.toDate()
                  : doc.data().created,
              updated:
                doc.data().updated && doc.data().updated.toDate
                  ? doc.data().updated.toDate()
                  : doc.data().updated,
              activated:
                doc.data().activated && doc.data().activated.toDate
                  ? doc.data().activated.toDate()
                  : doc.data().activated,
              balance: doc.data().balance ? doc.data().balance : 0,
              redeemed: doc.data().redeemed ? doc.data().redeemed : 0,
              awarded: doc.data().awarded ? doc.data().awarded : 0,
            });
          });
          commit("setMembers", members);
        },
        (error) => {
          console.log(error);
          if (membersData) {
            membersData();
          }
        }
      );

    commit("setLoadingTable", false);
    commit("setLoadingMembers", false);
  },

  async createMember({ dispatch, commit, getters }, payload) {
    const membersRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("members");

    let dupCheckRequests;
    let emailDupSnapshot, accountKeyDupSnapshot;

    if (payload.accountKey) {
      dupCheckRequests = [
        membersRef.where("email", "==", payload.email).get(),
        membersRef.where("accountKey", "==", payload.accountKey).get(),
      ];
    } else {
      dupCheckRequests = [membersRef.where("email", "==", payload.email).get()];
    }

    try {
      [emailDupSnapshot, accountKeyDupSnapshot] = await Promise.all(
        dupCheckRequests
      );
    } catch (e) {
      throw "Error occured when checking email and account key.";
    }

    if (emailDupSnapshot.size > 0) {
      throw "Email is already registered.";
    }

    if (accountKeyDupSnapshot && accountKeyDupSnapshot.size > 0) {
      throw "The account key is already registered.";
    }

    // eslint-disable-next-line no-unused-vars
    const { id, ...memberInput } = payload;

    const name = payload.firstname + " " + payload.lastname;
    const initials = name
      .match(/(\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toUpperCase();
    const colors = AVATAR_COLORS;
    const random = Math.floor(Math.random() * colors.length);
    const color = colors[random];

    const member = {
      ...memberInput,
      created: timestamp,
      updated: timestamp,
      initials: initials,
      color: color,
      balance: 0,
      awarded: 0,
      redeemed: 0,
    };

    let newMemberRef;
    try {
      newMemberRef = await membersRef.add(member);
    } catch (e) {
      console.error(e);
      throw "Error when creating a new member.";
    }

    const companyChanges = payload.companies.reduce((result, companyId) => {
      const change = {
        companyId,
        memberId: newMemberRef.id,
        type: "add",
      };
      return [...result, change];
    }, []);

    // Note: server time is unavailable until we refetch.
    const tempMember = {
      ...member,
      fullname: `${member.firstname} ${member.lastname}`,
      id: newMemberRef.id,
      created: new Date(),
      updated: new Date(),
    };

    try {
      await dispatch("syncCompanyMembers", companyChanges);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating company members.";
    }

    commit("createMember", tempMember);
    dispatch("setSnackbar", "Member Created");
  },

  async syncMembersToCompanies({ commit, getters }) {
    console.log("Sync started....");
    commit("setLoadingTable", true);
    commit("setLoadingMembers", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("members")
        .orderBy("fullnameUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const members = [];
    querySnapshot.forEach(function (doc) {
      const data = doc.data();
      data.companies.forEach(function (company) {
        members.push({
          companyId: company,
          memberId: doc.id,
        });
      });
    });

    const updates = _(members)
      .groupBy("companyId")
      .map((v, companyId) => ({
        ref: companyId,
        members: _.map(v, "memberId"),
      }))
      .value();

    let companiesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("companies");
    try {
      let batch = db.batch();
      const documentSnapshotArray = await companiesRef.get();
      const records = documentSnapshotArray.docs;
      const index = documentSnapshotArray.size;
      console.log(`Syncing ${index} company records`);
      for (let i = 0; i < index; i++) {
        const record = records[i].ref;
        // console.log('Company Id: ', record.id)
        const update = updates.find((el) => el.ref === record.id);
        let arr;
        if (!update) {
          arr = [];
        } else {
          arr = update.members;
        }
        const date = new Date();
        batch.update(record, { members: arr, updated: date });
        if ((i + 1) % 499 === 0) {
          await batch.commit();
          batch = db.batch();
        }
      }
      // For committing final batch
      if (!(index % 499) == 0) {
        await batch.commit();
      }
    } catch (e) {
      console.error(e);
    }

    console.log("Sync complete");
    commit("setLoadingTable", false);
    commit("setLoadingMembers", false);
  },

  async updateMember({ dispatch, commit, getters }, payload) {
    //console.log(payload)
    const membersRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("members");

    let storedMember;
    try {
      const memberDoc = await membersRef.doc(payload.id).get();
      storedMember = memberDoc.data();
    } catch (e) {
      storedMember = null;
    }

    if (!storedMember) {
      throw "Error occured when fetching the member info";
    }

    let dupCheckRequests;
    let emailDupSnapshot, accountKeyDupSnapshot;

    if (payload.accountKey) {
      dupCheckRequests = [
        membersRef.where("email", "==", payload.email).get(),
        membersRef.where("accountKey", "==", payload.accountKey).get(),
      ];
    } else {
      dupCheckRequests = [membersRef.where("email", "==", payload.email).get()];
    }

    try {
      [emailDupSnapshot, accountKeyDupSnapshot] = await Promise.all(
        dupCheckRequests
      );
    } catch (e) {
      throw "Error occured when checking email and account key.";
    }

    if (emailDupSnapshot.size > 0) {
      let duplicated = false;
      emailDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Email is already registered.";
      }
    }

    if (accountKeyDupSnapshot && accountKeyDupSnapshot.size > 0) {
      let duplicated = false;
      accountKeyDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Account Key is already registered.";
      }
    }

    const { id, ...memberInput } = payload;
    const name = payload.firstname + " " + payload.lastname;
    const initials = name
      .match(/(\b\S)?/g)
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toUpperCase();

    const member = {
      ...memberInput,
      initials: initials,
    };

    try {
      await membersRef.doc(id).update(member);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a member";
    }

    const addedCompanies = utils.getNewArrayItems(
      storedMember.companies,
      member.companies
    );

    const deleteCompanies = utils.getNewArrayItems(
      member.companies,
      storedMember.companies
    );

    const addeCompanyChanges = addedCompanies.map((companyId) => {
      return { memberId: id, companyId, type: "add" };
    });

    const deletedCompanyChanges = deleteCompanies.map((companyId) => {
      return { memberId: id, companyId, type: "delete" };
    });

    const companyChanges = [...addeCompanyChanges, ...deletedCompanyChanges];

    try {
      await dispatch("syncCompanyMembers", companyChanges);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating company members.";
    }

    const tempMember = {
      ...storedMember,
      ...memberInput,
      id,
      initials: initials,
      created:
        storedMember.created && storedMember.created.toDate
          ? storedMember.created.toDate()
          : storedMember.created,
      activated:
        storedMember.activated && storedMember.activated.toDate
          ? storedMember.activated.toDate()
          : storedMember.activated,
      updated: new Date(),
    };

    tempMember.fullname = `${tempMember.firstname} ${tempMember.lastname}`;

    commit("updateMember", tempMember);
    dispatch("setSnackbar", "Member Updated");
  },

  async deleteMember({ dispatch, commit, getters }, memberId) {
    const membersRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("members");

    let storedMember;
    try {
      const memberDoc = await membersRef.doc(memberId).get();
      storedMember = memberDoc.data();
    } catch (e) {
      storedMember = null;
    }

    if (!storedMember) {
      throw "Error occured when fetching the member info";
    }

    const companyChanges = storedMember.companies.map((companyId) => {
      return { memberId, companyId, type: "delete" };
    });

    try {
      await dispatch("syncCompanyMembers", companyChanges);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating company members";
    }

    try {
      await membersRef.doc(memberId).delete();
    } catch (e) {
      console.error(e);
      throw "Error occured when deleting a member";
    }

    commit("deleteMember", memberId);
    dispatch("setLoading", false);
    dispatch("setSnackbar", "Member Deleted");
  },

  async openWeb({ getters }, id) {
    const token = nanoid();
    const expiry = moment().add(3, "h").toDate();
    const activeDomain = getters.sites.find(
      (el) => el.program === getters.programId && el.status === "Live"
    ).activeDomain;
    const data = {
      member: id,
      token: token,
      expiry: expiry,
    };
    let tokensRef;

    try {
      tokensRef = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("tokens");
    } catch (e) {
      console.error(e);
    }

    try {
      await tokensRef.add(data);
    } catch (e) {
      console.error(e);
      throw "Error when creating token";
    }

    const url =
      "http://" +
      activeDomain +
      "/demo/" +
      getters.programId +
      "/" +
      data.token;
    window.open(url, "_blank");
  },

  async syncCompanyMembers({ getters }, payload) {
    const companyUpdatesBatch = db.batch();
    payload.forEach((change) => {
      const { companyId, memberId } = change;
      const updateCompanyRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("companies")
        .doc(companyId);
      const update = {
        members:
          change.type === "add"
            ? firebase.firestore.FieldValue.arrayUnion(memberId)
            : firebase.firestore.FieldValue.arrayRemove(memberId),
      };
      companyUpdatesBatch.update(updateCompanyRef, update);
    });

    await companyUpdatesBatch.commit();
  },

  async inviteByStatus({ getters, dispatch }, status) {
    try {
      await axios.post(webApi + "members/inviteByStatus/", {
        programId: getters.programId,
        status,
      });
    } catch (e) {
      throw "Error when inviting all members.";
    }
    dispatch("setSnackbar", "Member Invitation Sent.");
    await dispatch("loadMembers");
  },

  async inviteMember({ getters, commit, dispatch }, memberId) {
    try {
      await axios.post(webApi + "members/invite/", {
        programId: getters.programId,
        memberId: memberId,
      });
    } catch (e) {
      console.error(e.response);
      dispatch("setSnackbar", "ERROR. Inviting a member Failed.");
      throw "Error occured when inviting a member.";
    }

    commit("patchMember", {
      id: memberId,
      status: "Invited",
    });
    dispatch("setSnackbar", "Member Invited.");
  },

  async approveMember({ getters, commit, dispatch }, memberId) {
    try {
      await axios.post(webApi + "members/approve/", {
        programId: getters.programId,
        memberId: memberId,
      });
    } catch (e) {
      console.error(e);
      dispatch("setSnackbar", "ERROR. Approving a member Failed.");
      throw "Error occured when approving a member.";
    }

    commit("patchMember", {
      id: memberId,
      status: "Invited",
    });
    dispatch("setSnackbar", "Member Approved.");
  },

  async setCurrentMemberPoints({ commit }, payload) {
    commit("setCurrentMemberPoints", payload);
  },

  //  async importMembers({ getters }, importingMembers) {
  //    const membersBatch = db.batch();
  //    // Note: Firebase is counting serverTimestamp as one write.
  //    // We need to reduce the write counts because there is Max 500 writes limit.
  //    //
  //
  //    const membersCollection = db.collection('programs').doc(getters.programId).collection('members');
  //    importingMembers.forEach(member => {
  //      const { isUpdate, exMemberId, ...memberData } = member;
  //      if (!isUpdate) {
  //        const dataWithTimestamp = {
  //          ...memberData,
  //          created: new Date(),
  //          updated: new Date(),
  //        }
  //        const newMemberRef = membersCollection.doc();
  //        membersBatch.set(newMemberRef, dataWithTimestamp);
  //      } else {
  //
  //        const dataWithTimestamp = {
  //          ...memberData,
  //          updated: new Date(),
  //        };
  //
  //        const updateMemberRef = membersCollection.doc(exMemberId);
  //        membersBatch.update(updateMemberRef, dataWithTimestamp);
  //      }
  //    });
  //
  //    try {
  //      await membersBatch.commit();
  //    } catch (e) {
  //      throw 'members import batch failed';
  //    }
  //  },

  async importMembersNew({ getters }, newMembers) {
    // Note: Due to firebase's limit of Max 500 writes per batch
    // We are gonna split members array into several chunks having 500 max members
    const membersChunks = _.chunk(newMembers, 240);
    for (let i = 0; i < membersChunks.length; i += 1) {
      const chunk = membersChunks[i];
      const newMembersBatch = db.batch();
      chunk.forEach((member) => {
        const { isUpdate, exMemberId, ...memberData } = member;
        if (!isUpdate) {
          const dataWithTimestamp = {
            ...memberData,
            created: new Date(),
            updated: new Date(),
          };
          const newMemberRef = db
            .collection("programs")
            .doc(getters.programId)
            .collection("members")
            .doc();
          newMembersBatch.set(newMemberRef, dataWithTimestamp);
        } else {
          const dataWithTimestamp = {
            ...memberData,
            updated: new Date(),
          };
          const updateMemberRef = db
            .collection("programs")
            .doc(getters.programId)
            .collection("members")
            .doc(exMemberId);
          newMembersBatch.update(updateMemberRef, dataWithTimestamp);
        }
      });

      try {
        await newMembersBatch.commit();
      } catch (e) {
        console.error(e);
        throw `members import batch failed - ${i + 1}st batch`;
      }
    }
  },
};

const mutations = {
  setMembers(state, payload) {
    state.members = payload;
  },

  setCurrentMemberPoints(state, payload) {
    // payload model should be = points { balance: 50, awarded: 100, redeemed: 50 }
    state.currentMemberPoints = payload;
  },

  setLoadingMembers(state, payload) {
    state.loadingMembers = payload;
  },

  createMember(state, payload) {
    state.members = [...state.members, payload];
  },

  updateMember(state, payload) {
    //console.log(payload)
    state.members = state.members.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  patchMember(state, payload) {
    console.log(payload);
    const { id, ...data } = payload;
    state.members = state.members.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });
  },

  deleteMember(state, payload) {
    state.members = state.members.filter((item) => item.id !== payload);
  },
};

const getters = {
  members(state) {
    return state.members;
  },

  currentMemberPoints(state) {
    return state.currentMemberPoints;
  },

  loadingMembers(state) {
    return state.loadingMembers;
  },

  membersMap(state) {
    return state.members.reduce((result, item) => {
      return {
        ...result,
        [item.id]: `${item.firstname} ${item.lastname}`,
      };
    }, {});
  },

  memberAccountKeysMap(state) {
    return state.members.reduce((result, member) => {
      return member.accountKey
        ? {
            ...result,
            [member.accountKey]: {
              id: member.id,
              firstname: member.firstname,
              lastname: member.lastname,
              fullname: member.fullname,
              title: member.fullname,
              firstLastInitial: member.firstLastInitial,
              avatar: member.image.smallUrl,
              titleUppercase: member.fullnameUppercase,
              tags: member.tags,
            },
          }
        : result;
    }, {});
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="rewardSupplier">
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ rewardSupplierId ? 'Edit ' : 'Add New '}}
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-16">
        
        <v-select
          name="status"
          v-model="selectedStatus"
          label="Status"
          v-validate="'required'"
          :error-messages="errors.collect('rewardSupplier.status')"
          :items="statuses"
          item-text="text"
          item-value="value"
        >
        </v-select>
      
        <v-text-field
          name="title"
          v-model="title"
          label="Reward Supplier Title"
          v-validate="'required'"
          :error-messages="errors.collect('rewardSupplier.title')"
        ></v-text-field> 

        <v-text-field
          name="accountKey"
          v-model="accountKey"
          label="Reward Supplier Id"
        ></v-text-field>   
        
        <v-text-field
          name="name"
          v-model="name"
          label="Contact Name"
        ></v-text-field>   
        
        <v-text-field
          name="email"
          v-model="email"
          label="Email"
        ></v-text-field>   
        
        <v-text-field
          name="phone"
          v-model="phone"
          label="Phone"
        ></v-text-field>   
        
        <v-row no-gutters>
          <v-col>
            <v-alert
              type="warning"
              v-model="showAlert"
              >
              That rewardSupplier is already registered
            </v-alert>
          </v-col>         
        </v-row>
      
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="handleClose()"
          >Cancel</v-btn>
        <v-btn
          :class="orgTheme.buttonTextColor"
          @click="saveRewardSupplier('rewardSupplier')"
          color="primary"
          :loading="loading"
          elevation="0"
          >Save</v-btn>
      </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    rewardSupplierId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => 'Reward Supplier title is required'
        },
        status: {
          required: () => 'Status is required'
        }
      }
    },
    statuses: [
      { text: 'Active', value: 'Active' },
      { text: 'Inactive', value: 'Inactive' }
    ],
    formTitle: 'Reward Supplier',
    formIcon: 'storefront',
    showAlert: false,
    selectedStatus: 'Active',
    title: '',
    accountKey: '',
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
    name: '',
    phone: '',
    email: ''
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    rewardSuppliers () {
      return this.$store.state.rewardsupplier.rewardSuppliers
    },
    companyTags () {
      return this.$store.state.companytag.companyTags
    },
    memberTags () {
      return this.$store.state.membertag.memberTags
    },
  },
  methods:{
    clear () {
      this.title = ''
      this.accountKey = ''
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.name = ''
      this.phone = ''
      this.email = ''
      this.selectedStatus = ''
      this.showAlert = false
      this.$validator.reset()
    },
    handleClose () {
      this.clear()
      this.$emit('onClose')
    },
    saveRewardSupplier (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        this.$store.dispatch('setLoading', true)
        const payload = {
          // Add database fields here
          title: this.title,
          accountKey: this.accountKey,
          status: this.selectedStatus,
          titleUppercase: this.title.toUpperCase(),
          name: this.name,
          phone: this.phone,
          email: this.email,
        };
        if (this.rewardSupplierId) {
          // Update rewardSupplier
          payload.id = this.rewardSupplierId;
          this.$store.dispatch('updateRewardSupplier', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create reward Supplier
          this.$store.dispatch('createRewardSupplier', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        }
      });
    },
  },
  watch: {
    rewardSupplierId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedRewardSupplier = this.rewardSuppliers.find(item => item.id === newVal);
          // Add database fields here
          const {
            title, status, created, updated, createdBy, updatedBy, phone, name, email, accountKey
          } = selectedRewardSupplier;
          this.selectedStatus = status;
          this.title = title;
          this.accountKey = accountKey;
          this.name = name;
          this.phone = phone;
          this.email = email;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
        }
      },
    },
  },
}
</script>
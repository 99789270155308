<template>
  <v-card v-if="settings.value" tile elevation="0" :class="grid.outer">
    <v-row
      no-gutters
      :justify="settings.justify"
      :style="
        'min-height: ' +
        settings.boxHeight +
        'px;' +
        'margin-left: ' +
        marginLeftResponsive +
        'px;' +
        'margin-right: ' +
        marginRightResponsive +
        'px;'
      "
      :align="settings.align"
    >
      <v-col xs="12" :md="settings.cols">
        <v-card tile elevation="0" :class="grid.inner">
          <div
            :style="
              'font-family: ' +
              settings.font +
              ' !important;' +
              'color: ' +
              settings.color +
              ';' +
              'font-size: ' +
              settings.size +
              'px;' +
              'font-weight: ' +
              settings.weight +
              ';' +
              'line-height: ' +
              settings.lineHeight +
              ';' +
              'font-style: ' +
              settings.style +
              ';'
            "
            :class="settings.floatText"
          >
            <v-clamp autoresize :max-lines="lines">
              {{ text }}
            </v-clamp>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VClamp from "vue-clamp";

export default {
  components: {
    VClamp,
  },
  // Props
  // Settings = the typography settings
  // Value = the actual text to be displayed
  // Lines = the number of lines to truncate if required. No value passed will display all lines
  // Suffix = soem text to display after the value
  props: ["settings", "value", "lines", "suffix"],
  created() {
    // this.locale = this.$i18n.locale
    // console.log(this.text);
  },
  computed: {
    text() {
      let val;
      if (!this.value) {
        val = this.settings.value;
      } else {
        val = this.value;
      }

      if (this.suffix) {
        return this.$t(val) + " " + this.$t(this.suffix);
      } else {
        return this.$t(val);
      }
    },
    grid() {
      return this.$store.getters.grid;
    },
    marginLeftResponsive() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 8;
        case "sm":
          return 8;
        case "md":
          return 8;
        case "lg":
          return this.settings.marginLeft;
        case "xl":
          return this.settings.marginLeft;
      }
      return this.settings.marginLeft;
    },
    marginRightResponsive() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 8;
        case "sm":
          return 8;
        case "md":
          return 8;
        case "lg":
          return this.settings.marginRight;
        case "xl":
          return this.settings.marginRight;
      }
      return this.settings.marginRight;
    },
  },
  methods: {},
};
</script>

<style scoped></style>

import { db } from "@/firebase";

const state = {
  hasWebThemePendingUpdates: false,
  selectedPageTemplate: "",
  newComponent: null,
  designDrawer: false,
  siteDrawer: false,
  openDesignDrawer: false,
  grid: {
    block: "grid-block",
    outer: "grid-outer",
    inner: "grid-inner",
  },
  editingField: {},
  editingComponent: {},
  selectedTheme: {},
  side: "left",
};

const actions = {
  setSelectedPageTemplate({ commit }, payload) {
    commit("setSelectedPageTemplate", payload);
  },
  setHasWebThemePendingUpdates({ commit }, payload) {
    commit("setHasWebThemePendingUpdates", payload);
  },
  setNewComponent({ commit }, payload) {
    commit("setNewComponent", payload);
  },
  setDesignDrawer({ commit }, payload) {
    commit("setDesignDrawer", payload);
  },
  setOpenDesignDrawer({ commit }, payload) {
    commit("setOpenDesignDrawer", payload);
  },
  setSiteDrawer({ commit }, payload) {
    commit("setSiteDrawer", payload);
  },
  editingField({ commit }, payload) {
    commit("setEditingField", payload);
  },
  editingComponent({ commit }, payload) {
    commit("setEditingComponent", payload);
  },
  setGrid({ commit }, payload) {
    commit("setGrid", payload);
  },
  setSide({ commit }, payload) {
    commit("setSide", payload);
  },
  setSelectedTheme({ commit }, id) {
    const docRef = db.collection("programs").doc(id);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const tempThemeObj = doc.data().webTheme;
          const themeObj = {
            ...tempThemeObj,
            selectedThemeId: id,
          };
          commit("setSelectedTheme", themeObj);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  },

  async updateWebTheme({ commit, dispatch, getters }, payload) {
    commit("setLoading", true);
    var id = getters.programId;
    const programRef = db.collection("programs").doc(id);
    // console.log(payload)
    const data = {
      webTheme: {
        ...payload,
        updated: new Date(),
      },
    };
    try {
      await programRef.update(data);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating program theme.";
    }
    commit("patchCurrentProgram", data);
    dispatch("loadCurrentProgram", id);
    commit("setLoading", false);
    dispatch("setSnackbar", "Theme Updated");
  },
};

const mutations = {
  setSelectedPageTemplate(state, payload) {
    state.selectedPageTemplate = payload;
  },
  setHasWebThemePendingUpdates(state, payload) {
    state.hasWebThemePendingUpdates = payload;
  },
  setNewComponent(state, payload) {
    state.newComponent = payload;
  },
  setDesignDrawer(state, payload) {
    state.designDrawer = payload;
  },
  setSiteDrawer(state, payload) {
    state.siteDrawer = payload;
  },
  setOpenDesignDrawer(state, payload) {
    state.openDesignDrawer = payload;
  },
  setGrid(state, payload) {
    state.grid = payload;
  },
  setSide(state, payload) {
    state.side = payload;
  },
  setEditingField(state, payload) {
    state.editingField = payload;
  },
  setEditingComponent(state, payload) {
    state.editingComponent = payload;
  },
  patchCurrentProgram(state, payload) {
    state.currentProgram = {
      ...state.currentProgram,
      ...payload,
    };
  },
  setSelectedTheme(state, payload) {
    state.selectedTheme = payload;
  },
};

const getters = {
  selectedPageTemplate(state) {
    return state.selectedPageTemplate;
  },
  hasWebThemePendingUpdates(state) {
    return state.hasWebThemePendingUpdates;
  },
  grid(state) {
    return state.grid;
  },
  newComponent(state) {
    return state.newComponent;
  },
  siteDrawer(state) {
    return state.siteDrawer;
  },
  designDrawer(state) {
    return state.designDrawer;
  },
  openDesignDrawer(state) {
    return state.openDesignDrawer;
  },
  side(state) {
    return state.side;
  },
  editingField(state) {
    return state.editingField;
  },
  editingComponent(state) {
    return state.editingComponent;
  },
  selectedTheme(state) {
    return state.selectedTheme;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div>
    <v-card elevation="0">
      <v-toolbar flat dense>
        <div class="incentable-form-heading">Subscription</div>
        <v-spacer></v-spacer>
        <div v-if="currentProgram.status === 'Demo'">
          <v-icon class="green--text pr-1" size="21">check_circle</v-icon>
          <span class="green--text dash-card">Demo</span>
        </div>
      </v-toolbar>
      <div class="px-4 pb-4 pt-2">
        <template>
          <div>
            <v-row no-gutters>
              <v-col cols="auto" class="capitalize label pr-3"> Plan: </v-col>
              <v-col class="incentable-form-heading"> Demo </v-col>
            </v-row>
          </div>
        </template>
      </div>

      <v-card-actions> </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: [
    "programId",
    "currentSubscription",
    "currentProgram",
    "trial",
    "expired",
  ],
  data: function () {
    return {
      status: "",
      ended_at: "",
    };
  },
  computed: {},
  methods: {
    upgrade() {
      this.$store.dispatch("setPlansTable", true);
    },
  },
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
.status {
  text-transform: capitalize;
  font-family: "Roboto", sans-serif !important;
  font-size: 17px;
  font-weight: 500 !important;
  color: #4d4d4d !important;
}
.label {
  font-size: 13px;
  font-weight: 300;
}
</style>

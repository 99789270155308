<template>
  <div class="px-4">
    <v-card class="pa-6 el mr-6">
      <v-row no-gutters class="incentable-form-heading mt-0 mb-n4">Open</v-row>
      <v-row>
        <v-col cols="3">
          <v-menu
            v-model="openDateMenu"
            :close-on-content-click="false"
            :nudge-right="32"
            :nudge-top="22"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(openDate)"
                @input="changed()"
                clearable
                label="Date"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="clearOpenDate()"
                hint="Leave blank to open now"
                persistent-hint
                prepend-icon="calendar_month"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="openDate"
              @change="openDateMenu = false"
              @input="changed()"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="openTimeMenu"
            :close-on-content-click="false"
            :nudge-right="32"
            :nudge-top="22"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatTime(openTime)"
                @input="changed()"
                clearable
                label="Time"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="clearOpenTime()"
                hint="No time will be considered start of day"
                persistent-hint
                prepend-icon="schedule"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="openTime"
              @change="openTimeMenu = false"
              @input="changed()"
              full-width
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters class="incentable-form-heading mt-9 mb-n4">Close</v-row>
      <v-row>
        <v-col cols="3">
          <v-menu
            v-model="closeDateMenu"
            :close-on-content-click="false"
            :nudge-right="32"
            :nudge-top="22"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(closeDate)"
                @input="changed()"
                clearable
                label="Date"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="clearCloseDate()"
                hint="Leave blank to not close"
                persistent-hint
                prepend-icon="calendar_month"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="closeDate"
              @change="closeDateMenu = false"
              @input="changed()"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="closeTimeMenu"
            :close-on-content-click="false"
            :nudge-right="32"
            :nudge-top="22"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatTime(closeTime)"
                @input="changed()"
                clearable
                label="Time"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="clearCloseTime()"
                hint="No time will be considered end of day"
                persistent-hint
                prepend-icon="schedule"
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="closeTime"
              @change="closeTimeMenu = false"
              @input="changed()"
              full-width
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters class="incentable-form-heading mt-9 mb-n4"
        >Timezone</v-row
      >
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="timezone"
            :items="timezones"
            label="Selected Timezone"
            value="timezone"
            @input="updateSelectedTimezone"
            prepend-icon="public"
          ></v-select>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  props: ["quizId"],
  data: function () {
    return {
      availableStatus: [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" },
        { label: "Archive", value: "Archive" },
      ],
      openDeleteDialog: false,
      isProcessing: false,
      openDate: "",
      openTime: "",
      closeDate: "",
      closeTime: "",
      timezone: "",
      redeemable: false,
      openDateMenu: false,
      openTimeMenu: false,
      closeDateMenu: false,
      closeTimeMenu: false,
      timezones: [],
    };
  },

  created() {
    if (this.quizId) {
      this.fetchTimezones();
      this.openDate = this.quiz.timing.openDate;
      this.openTime = this.quiz.timing.openTime;
      this.closeDate = this.quiz.timing.closeDate;
      this.closeTime = this.quiz.timing.closeTime;
      this.timezone = this.quiz.timing.timezone;
    }
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    quiz() {
      return this.$store.state.quiz.quiz;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    quizAuthorMap() {
      return this.$store.getters.admins.reduce((result, item) => {
        return {
          ...result,
          [item.userId]: item.name,
        };
      }, {});
    },
    quizChanged() {
      return this.$store.state.quiz.quizChanged;
    },
    quizUpdates() {
      return this.$store.state.quiz.quizUpdates;
    },
  },
  methods: {
    handleDelete() {
      const id = this.quiz.id;
      this.isProcessing = true;
      this.openDeleteDialog = false;
      this.$store.dispatch("deleteQuiz", id).then(() => {
        this.isProcessing = false;
        this.$router.push(`/quizzes/${this.programId}`);
      });
    },
    getAuthorName(adminId) {
      return this.quizAuthorMap[adminId];
    },
    changed() {
      const update = {
        ...this.quizUpdates,
        timing: {
          openDate: this.openDate,
          openTime: this.openTime,
          closeDate: this.closeDate,
          closeTime: this.closeTime,
          timezone: this.timezone,
        },
      };
      console.log(update);
      this.$store.dispatch("setQuizChanged", true);
      this.$store.dispatch("patchQuiz", update);
    },
    formatDate(date) {
      return date ? moment(date).format("ll") : "";
    },
    formatTime(time) {
      if (time !== "") {
        const [hoursStr, minutesStr] = time.split(":");
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        const ampm = hours >= 12 ? "PM" : "AM";
        const hours12 = hours % 12 || 12;
        const time12 = `${hours12}:${minutes
          .toString()
          .padStart(2, "0")} ${ampm}`;
        return time12;
      }
      return "";
    },
    clearOpenTime() {
      this.openTime = "";
      this.openTimeMenu = false;
    },
    clearCloseTime() {
      this.closeTime = "";
      this.closeTimeMenu = false;
    },
    clearOpenDate() {
      this.openDate = "";
      this.openTime = "";
      this.openDateMenu = false;
    },
    clearCloseDate() {
      this.closeDate = "";
      this.closeTime = "";
      this.closeDateMenu = false;
    },
    updateSelectedTimezone(value) {
      this.timezone = value;
      this.changed();
    },
    fetchTimezones() {
      // Fetch the list of timezones from the API
      axios
        .get("https://worldtimeapi.org/api/timezone")
        .then((response) => {
          this.timezones = response.data;
        })
        .catch((error) => {
          console.error("Error fetching timezones: " + error);
        });
    },
  },
  watch: {},
};
</script>

<style scoped></style>

<template>
  <div>
    <v-row v-if="!sample && !vertical" align="center" class="pb-8">
      <v-col>
        <input
          ref="csv"
          type="file"
          name="csv"
          accept=".csv"
          @change="triggerValidation"
        />
      </v-col>
      <v-col>
        <v-row>
          <v-checkbox
            id="hasHeaders"
            v-model="hasHeaders"
            label="My CSV File has header row"
            :disabled="checkDisabled"
          ></v-checkbox>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="20"
                class="ml-1"
                color="grey"
                v-on="on"
                v-bind="attrs"
                >help</v-icon
              >
            </template>
            <span
              >Checking this box will prevent the first row, the header row,
              being imported. <br />Having a header row in your CSV makes field
              matching easier for you.
            </span>
          </v-tooltip>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-btn elevation="0" color="primary" @click.prevent="getSample"
          >upload csv</v-btn
        >
      </v-col>
    </v-row>

    <v-row v-if="vertical" justify="start" no-gutters>
      <v-col>
        <v-row justify="start" no-gutters>
          <input
            ref="csv"
            type="file"
            name="csv"
            accept=".csv"
            @change="triggerValidation"
            class="ml-1 mb-0"
          />
        </v-row>
        <v-row no-gutters>
          <v-checkbox
            id="hasHeaders"
            v-model="hasHeaders"
            label="My CSV File has header row"
            :disabled="checkDisabled"
            class="ml-1 mt-6"
          ></v-checkbox>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="20"
                class="ml-1"
                color="grey"
                v-on="on"
                v-bind="attrs"
                >help</v-icon
              >
            </template>
            <span
              >Checking this box will prevent the first row, the header row,
              being imported. <br />Having a header row in your CSV makes field
              matching easier for you.
            </span>
          </v-tooltip>
        </v-row>
        <v-row justify="start" no-gutters>
          <v-btn
            elevation="0"
            color="primary"
            @click.prevent="getSample"
            v-if="!disableUploadCsvBtn"
            >upload csv</v-btn
          >
        </v-row>
        <v-row v-if="csv !== null" justify="start" no-gutters class="mb-6">
          <v-icon color="green">check_circle</v-icon>
          <span class="green--text mt-1 ml-2">{{ csvLength }} Rows in CSV</span>
        </v-row>
      </v-col>
    </v-row>

    <template v-if="sample">
      <v-row dense>
        <v-col lg="3">
          <v-sheet flat outlined class="pa-2 grey lighten-4">
            Incentable Field
          </v-sheet>
        </v-col>
        <v-col lg="4">
          <v-sheet flat outlined class="pa-2 grey lighten-4">
            CSV Column
          </v-sheet>
        </v-col>
        <v-col lg="4">
          <v-sheet flat outlined class="pa-2 grey lighten-4">
            Preview Row
          </v-sheet>
        </v-col>
      </v-row>
      <div v-for="(field, key) in fieldsToMap" :key="key">
        <v-row dense>
          <v-col lg="3">
            <v-sheet flat outlined class="pa-2 grey lighten-4">
              {{ getPrettyLabel(field.label) }}
              {{
                !requiredFields.length || requiredFields.includes(field.key)
                  ? "*"
                  : ""
              }}
            </v-sheet>
          </v-col>

          <v-col lg="4">
            <v-sheet flat outlined>
              <div class="styled-select slate">
                <select v-model="map[field.key]">
                  <option
                    v-for="(column, key) in firstRow"
                    :key="key"
                    :value="key"
                  >
                    {{ column }}
                  </option>
                </select>
              </div>
            </v-sheet>
          </v-col>

          <v-col lg="4">
            <v-sheet flat outlined class="pa-2">
              <select v-model="map[field.key]" readonly>
                <option
                  v-for="(column, key) in secondRow"
                  :key="key"
                  :value="key"
                >
                  {{ column }}
                </option>
              </select>
            </v-sheet>
          </v-col>

          <v-col sm="auto">
            <v-btn v-if="map[field.key] >= 0" icon @click="clear(field)" small>
              <v-icon color="red">backspace</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import axios from "axios";
import Papa from "papaparse";
export default {
  props: {
    // value: Array,
    url: {
      type: String,
    },
    vertical: {
      type: Boolean,
    },
    mapFields: {
      required: true,
    },
    requiredFields: {
      // if missed, all mapping fields are required
      type: Array,
      default: () => [],
    },
    callback: {
      type: Function,
      default: () => {},
    },
    catch: {
      type: Function,
      default: () => {},
    },
    finally: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    form: {
      csv: null,
    },
    fieldsToMap: [],
    map: {},
    label: "",
    hasHeaders: true,
    csv: null,
    sample: null,
    checkDisabled: false,
    validFile: false,
    labels: [
      { id: "firstname", text: "First Name" },
      { id: "lastname", text: "Last Name" },
      { id: "dataKey", text: "Data Key" },
      { id: "email", text: "Email" },
      { id: "points", text: "Points" },
    ],
  }),
  created() {
    if (_.isArray(this.mapFields)) {
      this.fieldsToMap = _.map(this.mapFields, (item) => {
        return {
          key: item,
          label: item,
        };
      });
    } else {
      this.fieldsToMap = _.map(this.mapFields, (label, key) => {
        return {
          key: key,
          label: label,
        };
      });
    }
  },
  methods: {
    clear(field) {
      //eslint-disable-line
      Vue.delete(this.map, field.key);
      this.submit();
    },

    triggerValidation(el) {
      const regex = new RegExp("(.*?)\.(csv)$"); //eslint-disable-line
      if (!regex.test(el.target.value.toLowerCase())) {
        el.target.value = "";
        this.$store.dispatch(
          "setSnackbar",
          "Data import files must be in CSV format"
        );
      }
      this.validFile = true;
    },

    submit() {
      const _this = this;
      this.form.csv = this.buildMappedCsv();
      console.log(this.form.csv);
      this.$emit("input", this.form.csv);
      if (this.url) {
        axios
          .post(this.url, this.form)
          .then((response) => {
            _this.callback(response);
          })
          .catch((response) => {
            _this.catch(response);
          })
          .finally((response) => {
            _this.finally(response);
          });
      }
    },
    buildMappedCsv() {
      const _this = this;
      let csv = this.hasHeaders ? _.drop(this.csv) : this.csv;
      return _.map(csv, (row) => {
        let newRow = {};
        _.forEach(_this.map, (column, field) => {
          _.set(newRow, field, _.get(row, column));
        });
        return newRow;
      });
    },
    getSample() {
      this.checkDisabled = true;
      const _this = this;
      this.readFile((output) => {
        _this.sample = _.get(Papa.parse(output, { preview: 2 }), "data");
        _this.csv = _.get(Papa.parse(output), "data");
      });
    },
    readFile(callback) {
      let file = this.$refs.csv.files[0];
      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = function (evt) {
          // Note: remove starting and trailing enter space
          const content = evt.target.result.replace(/^\s+|\s+$/g, "");
          callback(content);
        };
        reader.onerror = function () {};
      }
    },
    getPrettyLabel(label) {
      let result;
      const labels = this.labels;
      const pretty = labels.find((el) => el.id === label);
      if (pretty) {
        result = pretty.text;
      } else {
        result = label;
      }
      return result;
    },
  },
  watch: {
    map: {
      handler: function (newVal) {
        if (!this.url) {
          const _this = this;
          var hasAllRequiredKeys = this.mapFields.every(function (item) {
            const fieldRequired = _this.requiredFields.length
              ? _this.requiredFields.includes(item)
              : true;

            // eslint-disable-next-line no-prototype-builtins
            return !fieldRequired || newVal.hasOwnProperty(item);
          });
          if (hasAllRequiredKeys) {
            this.submit();
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    firstRow() {
      return _.get(this, "sample.0");
    },
    secondRow() {
      return _.get(this, "sample.1");
    },
    disableUploadCsvBtn() {
      if (this.csv !== null || !this.validFile) {
        return true;
      }
      return false;
    },
    csvLength() {
      if (this.csv !== null) {
        if (this.hasHeaders) {
          return this.csv.length - 1;
        }
        return this.csv.length;
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.styled-select.slate {
  background: url("~@/assets/down-arrow.png") no-repeat right center;
  height: 38px;
  width: 330px;
  border-color: transparent;
}
.styled-select.slate select {
  font-size: 14px;
  height: 38px;
  width: 332px;
  padding-left: 10px;
  border-color: transparent;
}

/* -------------------- Colors: Background */
.slate {
  background-color: #4e4e4e;
}

/* -------------------- Colors: Text */
.slate select {
  color: #4e4e4e;
}
</style>

<template>
  <div>
    <v-card
      class="el rounded-lg"
      height="220"
    >      
      <v-progress-linear
        indeterminate
        :active="loadingMembers"
        :color="orgTheme.primary"
        >
      </v-progress-linear>
      <v-card-text>
        <v-row no-gutters>
          <span class="incentable-form-heading">Registered</span><br>
        </v-row>
        <v-row no-gutters>
          <span class="dash-card">Members that joined via the registration form</span>
        </v-row>
        
        <v-row no-gutters class="mt-4">
          <v-col cols="auto">
            <v-avatar
              size="26"
              color="amber"
              class="mt-1"
            >
            <v-icon small color="white">how_to_reg</v-icon>
            </v-avatar>
            
          </v-col>
            <v-col class="ml-2">
              <v-row no-gutters>
                <span class="incentable-form-heading">{{ awaitingMembers.length }} Awaiting Approval</span><br>
              </v-row>
          <div class="vertical-line ml-3">
          </div>
              <v-row no-gutters>
                <span class="dash-card">Registered but not yet approved</span>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-progress-circular :value="getPercentage(awaitingMembers.length)" color="amber" size="45" rotate="90" width="5">
                <span class="guage-value">{{ getPercentage(awaitingMembers.length) }}%</span>
              </v-progress-circular>
            </v-col>
        </v-row>

          <div class="vertical-line ml-3">
            <v-row no-gutters justify="center">
              <v-btn
                outlined
                class="ml-n4"
                text
                :loading="isProcessing"
                :disabled="isProcessing || !awaitingMembers.length"
                color="primary"
                small
                elevation="0"
                @click="handleReviewAwaitingApproval()"
              >
                <v-icon small class="mr-1">how_to_reg</v-icon>
                Review Awaiting Approval
              </v-btn>
            </v-row>
          </div>
      
        <v-row no-gutters class="mt-2">
          <v-col cols="auto">
            <v-avatar
              size="26"
              color="green"
              class="mt-1"
            >
            <v-icon small color="white">check</v-icon>
            </v-avatar>
            
          </v-col>
          <v-col class="ml-2">
            <v-row no-gutters>
              <span class="incentable-form-heading">{{ activeMembers.length }} Active</span><br>
            </v-row>
            <v-row no-gutters>
              <span class="dash-card">Accepted your invitation and activated</span>
            </v-row>
          </v-col>
          <v-progress-circular :value="getPercentage(activeMembers.length)" color="green" size="45" rotate="90" width="5">
            <span class="guage-value">{{ getPercentage(activeMembers.length) }}%</span>
          </v-progress-circular>
        </v-row>
      </v-card-text>
    
    </v-card>
  </div>
</template>

<script>
export default {
  props: [ 'orgTheme', 'members' ],
  data: function () {
    return {
      isProcessing: false,
    }
  },
  created () {
  },
  computed: {
    loadingMembers () {
      return this.$store.getters.loadingMembers
    },
    awaitingMembers() {
      return this.members.filter(m => m.status === 'Awaiting Approval')
    },
    activeMembers() {
      return this.members.filter(m => m.status === 'Active')
    },
    programId () {
      return this.$store.getters.programId;
    },
  },
  methods: {
    getPercentage(val) {
      const num = val / this.members.length * 100
      return Math.round(num * 10) / 10 || 0
    },
    handleInvite(status) {
      this.isProcessing = true;
      this.$store.dispatch('inviteByStatus', status)
        .then(() => {
          this.isProcessing = false;
        })
        .catch(() => {
          // handle error?
        })
    },
    handleReviewAwaitingApproval () {
      console.log('router')
      this.$router.push({ path: '/members/' + this.programId })
    }
  }
}
</script>

<style>
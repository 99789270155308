<template>
  <div>
    <v-dialog v-model="open" max-width="400px" persistent>
      <v-card>
        <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="red"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row dense>
            <v-col align="center">
              You are about to delete the <span class="incentable-form-bold">{{ title }}</span> Table
            </v-col>
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" text>Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            elevation="0"
            @click="handleDelete()"
            :loading="isProcessing === true"
            :disabled="demo"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['open', 'title'],
  data: function () {
    return {
      isProcessing: false,
      openDeleteDialog: false,
      availableStatus: [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
        { label: 'Archive', value: 'Archive' }
      ],
    };
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    programId() {
      return this.$store.getters.programId;
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
  },
  methods: {
    handleDelete() {
      this.isProcessing = true
      this.$store.dispatch('deleteTable')
        .then(() => {
          this.$router.push(`/tables/${this.programId}`)
          setTimeout(() => this.isProcessing = false, 3000)
          
        })
        .catch(() => {
          setTimeout(() => this.isProcessing = false, 3000)
        });
    },
    cancel() {
      this.$emit('onClose');
    }
  },
}
</script>

<style scoped>

</style>

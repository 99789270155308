/* eslint-disable no-console */
<template>
  <div>
    <v-row no-gutters align="center">
      <v-col cols="2" v-if="options.search">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field>
      </v-col>

      <v-col sm="3" v-if="options.filter">
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter"
          prepend-inner-icon="filter_list"
          class="mr-4"
        ></v-select>
      </v-col>

      <v-col>
        <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
        <v-btn v-if="options.download" class="primary--text" text
          >Download</v-btn
        >
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="subscriptions && !loadingTable"
      :headers="headers"
      :items="subscriptions"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
      class="px-0"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="handleEdit(item)">
              <v-list-item-title> Plan Details </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="handleInvoicesDialog(item)">
                Invoices
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status"></status-icon>
        <span class="capitalize ml-1">{{ item.status }}</span>
      </template>

      <template v-slot:[`item.currency`]="{ item }">
        <span class="uppercase ml-1">{{ item.currency }}</span>
      </template>

      <template v-slot:[`item.createdBy`]="{ item }">
        {{ adminName(item.createdBy) }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        ${{ item.total | number }}
      </template>

      <template v-if="isIncentableAdmin" v-slot:[`item.program`]="{ item }">
        {{ programName(item.program) }}
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <span v-if="item.created">{{ item.created | convertTimestamp }}</span>
      </template>

      <template v-slot:[`item.current_period_end`]="{ item }">
        <div v-if="item.status === 'active'">
          <v-row v-if="item.cancel_at_period_end === false" no-gutters>
            <v-icon color="green" class="mr-1">autorenew</v-icon>
            <span class="status ml-1"
              >Auto Renews
              {{ item.current_period_end | convertTimestamp }}</span
            >
          </v-row>

          <v-row v-else no-gutters>
            <v-icon color="red">cancel</v-icon>
            <span class="status ml-1"
              >Ends {{ item.current_period_end | convertTimestamp }}</span
            >
          </v-row>
        </div>
      </template>
    </v-data-table>

    <subscription-edit
      :subscriptionId="selectedSubscriptionId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <subscription-invoices
      :subscriptionId="selectedSubscriptionId"
      :open="dialogInvoicesOpen"
      @onClose="handleCloseInvoicesDialog"
    />
  </div>
</template>

<script>
import SubscriptionEdit from "./SubscriptionEdit.vue";
import SubscriptionInvoices from "./SubscriptionInvoices.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: ["subscriptions"],
  components: {
    SubscriptionEdit,
    SubscriptionInvoices,
  },
  data() {
    return {
      // Component settings unique to this component
      tableTitle: "Subscriptions",
      selectedSubscriptionId: "",
      search: "",
      from: "",
      program: "",
      total: 0,
      currency: "",
      plan: "",

      // Component settings common to all components
      dialogNewOrEditOpen: false,
      dialogDeleteOpen: false,
      dialogInvoicesOpen: false,

      // Table settings
      options: {
        sortDesc: [true],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: false,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
    };
  },
  created() {},
  computed: {
    // Functions specific to this table //
    admins() {
      return this.$store.getters.admins;
    },
    programs() {
      return this.$store.getters.programsQuickList;
    },
    // Functions common to all tables //
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    snackbar() {
      return this.$store.getters.snackbar;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    headers() {
      if (this.isIncentableAdmin) {
        return [
          { text: "Status", value: "status", align: "left" },
          { text: "Created", value: "created", align: "left" },
          { text: "Program", value: "program", align: "left" },
          { text: "Plan", value: "plan", align: "left" },
          {
            text: "Next Billing Date",
            value: "current_period_end",
            align: "left",
          },
          { text: "Created By", value: "createdBy", align: "left" },
          { text: "Amount", value: "total", align: "left" },
          { text: "Currency", value: "currency", align: "left" },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            sortable: false,
          },
        ];
      }
      return [
        { text: "Status", value: "status", align: "left" },
        { text: "Created", value: "created", align: "left" },
        { text: "Plan", value: "plan", align: "left" },
        {
          text: "Next Billing Date",
          value: "current_period_end",
          align: "left",
        },
        { text: "Created By", value: "createdBy", align: "left" },
        { text: "Amount", value: "total", align: "left" },
        { text: "Currency", value: "currency", align: "left" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
      ];
    },
  },
  methods: {
    handleNew() {
      // Open New form
      this.selectedSubscriptionId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedSubscriptionId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleInvoicesDialog(item) {
      // Open Edit form
      this.$store.dispatch("loadStripeInvoices", item.id);
      this.selectedSubscriptionId = item.id;
      this.dialogInvoicesOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedSubscriptionId = "";
      this.dialogNewOrEditOpen = false;
    },
    handleCloseInvoicesDialog() {
      // Close New Or Edit form
      this.selectedSubscriptionId = "";
      this.dialogInvoicesOpen = false;
    },
    adminName(id) {
      const admin = this.admins.find((el) => el.userId === id);
      if (admin) {
        return admin.name;
      }
      return "** Admin deleted **";
    },
    programName(id) {
      const program = this.programs.find((el) => el.id === id);
      if (program) {
        return program.title;
      }
      return "** Program deleted **";
    },
  },
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
</style>

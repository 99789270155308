<template>
  <v-container
    fluid
    tile
    class="pa-0"
    :style="'background-color: ' + this.webTheme.colors.canvas.hexa"
  >
    <v-row justify="center" no-gutters>
      <v-col cols="12" :sm="component.settings.cols">
        <v-card
          elevation="0"
          :height="height"
          tile
          :color="component.settings.color.hexa"
          :img="
            component.settings.mode === 'image'
              ? component.background.image
              : ''
          "
        >
          <v-row
            no-gutters
            :justify="component.block.justify"
            :align="component.block.align"
            class="fill-height"
          >
            <v-col xs="12" sm="10" md="8" :lg="component.block.cols">
              <v-col>
                <v-card
                  :class="classes"
                  :style="styles + ';' + gradient"
                  :height="component.card.height"
                  :elevation="component.card.elevation"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="auto"
                      v-if="
                        component.logo.placement === 'left' &&
                        component.logo.image
                      "
                    >
                      <view-logo
                        :style="logoMargin"
                        :settings="component.logo"
                      />
                    </v-col>
                    <v-col>
                      <view-logo
                        :style="logoMargin"
                        v-if="
                          component.logo.placement === 'top' &&
                          component.logo.image
                        "
                        :settings="component.logo"
                      />
                      <view-text :settings="component.title"></view-text>
                      <view-text :settings="component.subtitle"></view-text>

                      <v-text-field
                        :disabled="!signinOpen"
                        id="email"
                        name="Email"
                        label="Email"
                        :outlined="true"
                        :dense="true"
                        background-color="white"
                        color="grey"
                        filled
                      />

                      <v-text-field
                        :disabled="!signinOpen"
                        id="password"
                        Name="Password"
                        label="Password"
                        :outlined="true"
                        :dense="true"
                        background-color="white"
                        color="grey"
                      />

                      <v-row no-gutters :justify="component.button.justify">
                        <v-btn
                          :disabled="!signinOpen"
                          :color="component.button.color.hex"
                          :block="component.button.block"
                          :dark="!component.button.dark"
                          class="ma-0"
                          elevation="0"
                          :width="component.button.width"
                          :height="component.button.height"
                        >
                          {{ signinOpen ? "Sign In" : "not available" }}
                        </v-btn>
                      </v-row>

                      <!-- forgot password -->
                      <a v-if="signinOpen" @click="forgotPassword()">
                        <view-text
                          :settings="component.forgot"
                          :value="component.value"
                        ></view-text>
                      </a>
                      <view-text
                        v-else
                        :settings="component.forgot"
                        :value="component.value"
                      ></view-text>

                      <!-- sign up -->
                      <template v-if="registrationOpen">
                        <div>
                          <view-text
                            :settings="component.account"
                            :value="component.value"
                          ></view-text>

                          <a @click="signUp()">
                            <view-text
                              :settings="component.signup"
                              :value="component.value"
                            ></view-text>
                          </a>
                        </div>
                      </template>

                      <!-- agree to terms -->
                      <view-text
                        :settings="component.signing"
                        :value="component.value"
                      ></view-text>

                      <a>
                        <view-text
                          :settings="component.terms"
                          :value="component.value"
                        ></view-text>
                      </a>

                      <view-logo
                        :style="logoMargin"
                        v-if="
                          component.logo.placement === 'bottom' &&
                          component.logo.image
                        "
                        :settings="component.logo"
                      />
                    </v-col>
                    <v-col
                      cols="auto"
                      v-if="
                        component.logo.placement === 'right' &&
                        component.logo.image
                      "
                    >
                      <view-logo
                        :style="logoMargin"
                        :settings="component.logo"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-toolbar flat absolute bottom color="transparent" class="mb-6">
        <footnote />
      </v-toolbar>
    </v-row>
  </v-container>
</template>

<script>
import ViewLogo from "../Logo.vue";
import ViewText from "../Text.vue";
import Footnote from "../../../Footnote.vue";
export default {
  components: {
    ViewLogo,
    ViewText,
    Footnote,
  },
  data() {
    return {
      termsText: "By signing in, you agree to the",
      termsLink: "Terms and Conditions",
      forgotText: "Forgot Password",
      signupText: "Don't have an account? Sign Up Here",
    };
  },
  props: ["component", "index"],
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    registrationOpen() {
      return this.$store.getters.currentProgram.registrationOpen || false;
    },
    signinOpen() {
      return this.$store.getters.currentProgram.signinOpen || false;
    },
    gradient() {
      if (this.component.card.color.type === "gradient") {
        var color = this.component.card.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return "background: " + this.component.card.color.solidColor.hexa + ";";
      }
    },
    styles() {
      var styles =
        "margin-bottom: " +
        this.component.card.margin.bottom +
        "px ;" +
        "margin-top: " +
        this.component.card.margin.top +
        "px ;" +
        "margin-left: " +
        this.component.card.margin.left +
        "px ;" +
        "margin-right: " +
        this.component.card.margin.right +
        "px ;" +
        "padding-bottom: " +
        this.component.card.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.card.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.card.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.card.padding.right +
        "px ;" +
        "border: " +
        this.component.card.borderSize +
        "px solid " +
        this.component.card.borderColor.hexa +
        "; ";
      return styles;
    },
    classes() {
      var classes = this.component.card.rounded;
      return classes;
    },
    grid() {
      return this.$store.getters.grid;
    },
    logoMargin() {
      const val =
        "margin-bottom: " +
        this.component.logo.marginBottom +
        "px ;" +
        "margin-top: " +
        this.component.logo.marginTop +
        "px ;" +
        "margin-left: " +
        this.component.logo.marginLeft +
        "px ;" +
        "margin-right: " +
        this.component.logo.marginRight +
        "px ;";
      return val;
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates;
    },
    height() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + "px";
      } else {
        return this.component.heightValue.vh + "vh";
      }
    },
    imageHeight() {
      if (this.component.settings.heightContext == "px") {
        return this.component.heightValue.px + this.component.overlap.px + "px";
      } else {
        return this.component.heightValue.vh + this.component.overlap.vh + "vh";
      }
    },
    image() {
      if (this.component.settings.mode === "color") {
        return "";
      } else {
        return this.component.settings.image;
      }
    },
  },
  methods: {
    handleEditor(val) {
      this.editing = val;
      if (val === "preview") {
        this.previewCols = 11;
      } else {
        this.previewCols = 8;
      }
    },
    handleSave() {
      this.$store.dispatch("updateWebTheme", this.webTheme);
      this.$store.dispatch("setHasWebThemePendingUpdates", false);
      this.$store.dispatch("editingField", {});
    },
    forgotPassword() {
      console.log("forgot password");
    },
    signUp() {
      console.log("sign up");
    },
  },
};
</script>

<style scoped>
.theme-page {
  padding: 0px 10px 0px 0px !important;
}
.sticky-footer {
  position: fixed;
  background: transparent;
  bottom: 0px;
  left: 290px;
  margin-bottom: 10px;
  z-index: 1000 !important;
}
</style>

import Vue from "vue";
import Router from "vue-router";
import Multiguard from "vue-router-multiguard";
import Blog from "@/components/Blog/Blog";
import ContactUs from "@/components/ContactUs/ContactUs";
import Communications from "@/components/Communicate/Communications";
import Companies from "@/components/Company/Companies";
import Pages from "@/components/Page/Pages";
import Dashboard from "@/components/Dashboard/Dashboard";
import Databuckets from "@/components/Databucket/Databuckets";
import Databucket from "@/components/Databucket/Databucket";
import Quizzes from "@/components/Quiz/Quizzes";
import Quiz from "@/components/Quiz/Quiz";
import Leaderboards from "@/components/Leaderboard/Leaderboards";
import Leaderboard from "@/components/Leaderboard/Leaderboard";
import Tables from "@/components/Table/Tables";
import Table from "@/components/Table/Table";
import Design from "@/components/Design/Design";
import SystemAdmins from "@/components/SystemAdmin/SystemAdmins";
import Sites from "@/components/Site/Site";
import Log from "@/components/Log/Log";
import Points from "@/components/RedeemablePoints/Import/Points";
import Tiers from "@/components/Tiers/Tiers";
import Awards from "@/components/Award/Awards";
import Offers from "@/components/Offer/Offers";
import Offer from "@/components/Offer/Offer";
import Menus from "@/components/Menu/Menus";
import Members from "@/components/Member/Members";
import Main from "@/components/Program/Main";
import Billing from "@/components/Billing/Billing";
import Subscription from "@/components/Subscription/Subscription";
import Organisations from "@/components/Organisation/Organisations";
import Organisation from "@/components/Organisation/Organisation";
import Orders from "@/components/Order/Orders";
import Rewards from "@/components/Reward/Rewards";
import Settings from "@/components/Setting/Settings";
import Reports from "@/components/Report/Reports";
import Signup from "@/components/Authentication/Signup";
import Signin from "@/components/Authentication/Signin";
import Translate from "@/components/Translate/Translations";
import Forgot from "@/components/Authentication/Forgot";
import Academy from "@/components/Academy/Academy";
import Support from "@/components/Support/Support";
import Profile from "@/components/Profile/Profile";
import Activation from "@/components/Authentication/Activation";
import MemberResetPassword from "@/components/MemberArea/ResetPassword";
import MemberActivation from "@/components/MemberArea/Activation";
import Error404 from "@/components/Error/404";
import Error500 from "@/components/Error/500";
import AuthGuard from "./auth-guard";
import BrowserGuard from "./browser-guard";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/signup",
      name: "Signup",
      component: Signup,
      beforeEnter: Multiguard([BrowserGuard]),
    },
    {
      path: "/signin",
      name: "Signin",
      component: Signin,
      beforeEnter: Multiguard([BrowserGuard]),
    },
    {
      path: "/forgot",
      name: "Forgot",
      component: Forgot,
      beforeEnter: Multiguard([BrowserGuard]),
    },
    {
      path: "/academy",
      name: "Academy",
      component: Academy,
      beforeEnter: Multiguard([BrowserGuard]),
    },
    {
      path: "/support",
      name: "Support",
      component: Support,
      beforeEnter: Multiguard([BrowserGuard]),
    },
    {
      path: "/activation",
      name: "Activation",
      component: Activation,
      beforeEnter: Multiguard([BrowserGuard]),
    },
    {
      path: "/member-area/reset-password",
      name: "MemberResetPassword",
      component: MemberResetPassword,
      beforeEnter: Multiguard([BrowserGuard]),
    },
    {
      path: "/member-area/activate",
      name: "MemberActivation",
      component: MemberActivation,
    },
    {
      path: "/404",
      name: "Error404",
      component: Error404,
      beforeEnter: Multiguard([BrowserGuard]),
    },
    {
      path: "/500",
      name: "Error500",
      component: Error500,
      beforeEnter: Multiguard([BrowserGuard]),
    },
    {
      path: "/blog/:id",
      props: true,
      name: "Blog",
      component: Blog,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Articles" }], requireProgramId: true },
    },
    {
      path: "/communicate/:id",
      props: true,
      name: "Communicate",
      component: Communications,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Communicate" }], requireProgramId: true },
    },
    {
      path: "/companies/:id",
      props: true,
      name: "Companies",
      component: Companies,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Companies" }], requireProgramId: true },
    },
    {
      path: "/pages/:id",
      props: true,
      name: "Pages",
      component: Pages,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Pages" }], requireProgramId: true },
    },

    {
      path: "/dashboard/:id",
      props: true,
      name: "Dashboard",
      component: Dashboard,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Dashboard" }], requireProgramId: true },
    },

    {
      path: "/leaderboards/:id",
      props: true,
      name: "Leaderboards",
      component: Leaderboards,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Leaderboards" }], requireProgramId: true },
    },

    {
      path: "/leaderboards/:id/leaderboard/:leaderboardId",
      props: true,
      name: "Leaderboard",
      component: Leaderboard,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: {
        breadCrumb: [{ text: "Leaderboards" }],
        requireProgramId: true,
      },
    },

    {
      path: "/tables/:id",
      props: true,
      name: "Tables",
      component: Tables,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Tables" }], requireProgramId: true },
    },

    {
      path: "/tables/:id/table/:tableId",
      props: true,
      name: "Table",
      component: Table,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: {
        breadCrumb: [{ text: "Tables" }],
        requireProgramId: true,
      },
    },

    {
      path: "/offers/:id",
      props: true,
      name: "Offers",
      component: Offers,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: {
        breadCrumb: [{ text: "Claims Manager" }],
        requireProgramId: true,
      },
    },

    {
      path: "/points/:id",
      props: true,
      name: "Points",
      component: Points,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Points" }], requireProgramId: true },
    },

    {
      path: "/tiers/:id",
      props: true,
      name: "Tiers",
      component: Tiers,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Tiers" }], requireProgramId: true },
    },

    {
      path: "/awards/:id",
      props: true,
      name: "Awards",
      component: Awards,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Awards" }], requireProgramId: true },
    },

    {
      path: "/offers/:id/offer/:offerId",
      props: true,
      name: "Offer",
      component: Offer,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: {
        breadCrumb: [{ text: "Claims Manager" }],
        requireProgramId: true,
      },
    },

    {
      path: "/quizzes/:id",
      props: true,
      name: "Quizzes",
      component: Quizzes,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Quizzes" }], requireProgramId: true },
    },

    {
      path: "/quizzes/:id/quiz/:quizId",
      props: true,
      name: "Quiz",
      component: Quiz,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: {
        breadCrumb: [{ text: "Quizzes" }],
        requireProgramId: true,
      },
    },
    {
      path: "/databuckets/:id",
      props: true,
      name: "Data Buckets",
      component: Databuckets,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Data Buckets" }], requireProgramId: true },
    },

    {
      path: "/databuckets/:id/databucket/:databucketId",
      props: true,
      name: "Data Bucket",
      component: Databucket,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: {
        breadCrumb: [{ text: "Data Buckets" }],
        requireProgramId: true,
      },
    },

    {
      path: "/billing",
      name: "Billing",
      component: Billing,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    {
      path: "/subscriptions",
      name: "Subscriptions",
      component: Subscription,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    {
      path: "/",
      name: "Main",
      component: Main,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    {
      path: "/members/:id",
      props: true,
      name: "Members",
      component: Members,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Members" }], requireProgramId: true },
    },
    {
      path: "/orders/:id",
      props: true,
      name: "Orders",
      component: Orders,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Orders" }], requireProgramId: true },
    },
    {
      path: "/rewards/:id",
      props: true,
      name: "Rewards",
      component: Rewards,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Rewards" }], requireProgramId: true },
    },
    {
      path: "/design/:id",
      props: true,
      name: "Design",
      component: Design,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Design" }], requireProgramId: true },
    },
    {
      path: "/menus/:id",
      props: true,
      name: "Menus",
      component: Menus,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Menus" }], requireProgramId: true },
    },
    {
      path: "/settings/:id",
      props: true,
      name: "Settings",
      component: Settings,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Settings" }], requireProgramId: true },
    },
    {
      path: "/reports/:id",
      props: true,
      name: "Reports",
      component: Reports,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Reports" }], requireProgramId: true },
    },
    {
      path: "/translate/:id",
      props: true,
      name: "Translate",
      component: Translate,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { breadCrumb: [{ text: "Translate" }], requireProgramId: true },
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    {
      path: "/admins",
      name: "Admins",
      component: SystemAdmins,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    {
      path: "/log",
      name: "Log",
      component: Log,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    {
      path: "/sites",
      name: "Sites",
      component: Sites,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    {
      path: "/messages",
      name: "Inbox",
      component: ContactUs,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    {
      path: "/organisations",
      name: "Organisations",
      component: Organisations,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    {
      path: "/organisation/:id",
      props: true,
      name: "Organisation",
      component: Organisation,
      beforeEnter: Multiguard([AuthGuard, BrowserGuard]),
      meta: { requireProgramId: false },
    },
    { path: "*", beforeEnter: Multiguard([BrowserGuard]), redirect: "/404" },
  ],
  mode: "history",
});

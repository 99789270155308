<template>
  <div>
    <v-card class="el rounded-lg" style="height: 316px">
      <v-progress-linear
        indeterminate
        :active="loadingMembers"
        :color="orgTheme.primary"
      >
      </v-progress-linear>
      <v-card-text>
        <v-row no-gutters>
          <span class="incentable-form-heading">Invited </span><br />
        </v-row>
        <v-row no-gutters>
          <span class="dash-card">Members that joined via invitation</span>
        </v-row>

        <v-row no-gutters class="mt-4">
          <v-col cols="auto">
            <v-avatar size="26" color="amber" class="mt-1">
              <v-icon small color="white">pending</v-icon>
            </v-avatar>
          </v-col>
          <v-col class="ml-2">
            <v-row no-gutters>
              <span class="incentable-form-heading"
                >{{ pendingMembers.length }} Pending</span
              ><br />
            </v-row>
            <v-row no-gutters>
              <span class="dash-card">Added but not yet invited</span>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-progress-circular
              :value="getPercentage(pendingMembers.length)"
              color="amber"
              size="45"
              rotate="90"
              width="5"
            >
              <span class="guage-value"
                >{{ getPercentage(pendingMembers.length) }}%</span
              >
            </v-progress-circular>
          </v-col>
        </v-row>
        <div class="vertical-line ml-3">
          <v-row no-gutters justify="center">
            <v-btn
              outlined
              class="ml-n4"
              text
              :loading="isProcessing"
              :disabled="isProcessing || !pendingMembers.length"
              color="primary"
              small
              elevation="0"
              @click="handleInvite('Pending')"
            >
              <v-icon small class="mr-1">send</v-icon>
              Send Invite(s)
            </v-btn>
          </v-row>
        </div>

        <v-row no-gutters class="mt-2">
          <v-col cols="auto">
            <v-avatar size="26" color="blue" class="mt-1">
              <v-icon small color="white">mail</v-icon>
            </v-avatar>
          </v-col>
          <v-col class="ml-2">
            <v-row no-gutters>
              <span class="incentable-form-heading"
                >{{ invitedMembers.length }} Invited</span
              ><br />
            </v-row>
            <v-row no-gutters>
              <span class="dash-card"
                >Members invited that have not activated</span
              >
            </v-row>
          </v-col>
          <v-progress-circular
            :value="getPercentage(invitedMembers.length)"
            color="blue"
            size="45"
            rotate="90"
            width="5"
          >
            <span class="guage-value"
              >{{ getPercentage(invitedMembers.length) }}%</span
            >
          </v-progress-circular>
        </v-row>
        <div class="vertical-line ml-3">
          <v-row no-gutters justify="center">
            <v-btn
              outlined
              class="ml-n4"
              text
              :loading="isProcessing"
              :disabled="isProcessing || !invitedMembers.length"
              color="primary"
              small
              elevation="0"
              @click="handleInvite('Invited')"
            >
              <v-icon small class="mr-1">send</v-icon>
              Resend Invite(s)
            </v-btn>
          </v-row>
        </div>

        <v-row no-gutters class="mt-2">
          <v-col cols="auto">
            <v-avatar size="26" color="green" class="mt-1">
              <v-icon small color="white">check</v-icon>
            </v-avatar>
          </v-col>
          <v-col class="ml-2">
            <v-row no-gutters>
              <span class="incentable-form-heading"
                >{{ activeMembers.length }} Active</span
              ><br />
            </v-row>
            <v-row no-gutters>
              <span class="dash-card"
                >Accepted your invitation and activated</span
              >
            </v-row>
          </v-col>
          <v-progress-circular
            :value="getPercentage(activeMembers.length)"
            color="green"
            size="45"
            rotate="90"
            width="5"
          >
            <span class="guage-value"
              >{{ getPercentage(activeMembers.length) }}%</span
            >
          </v-progress-circular>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["orgTheme", "members"],
  data: function () {
    return {
      isProcessing: false,
    };
  },
  computed: {
    loadingMembers() {
      return this.$store.getters.loadingMembers;
    },
    pendingMembers() {
      return this.members.filter((item) => item.status === "Pending");
    },
    activeMembers() {
      return this.members.filter((m) => m.status === "Active");
    },
    invitedMembers() {
      return this.members.filter((m) => m.status === "Invited");
    },
  },
  methods: {
    getPercentage(val) {
      const num = (val / this.members.length) * 100;
      return Math.round(num * 10) / 10 || 0;
    },
    handleInvite(status) {
      this.isProcessing = true;
      this.$store
        .dispatch("inviteByStatus", status)
        .then(() => {
          this.isProcessing = false;
        })
        .catch(() => {
          // handle error?
        });
    },
  },
};
</script>

<style>
.guage-value {
  font-size: 10px;
  color: #414141;
  font-weight: 300;
}
.vertical-line {
  border-left: 1px solid #b3b3b3;
}
</style>

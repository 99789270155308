/* eslint-disable no-console */
<template>
  <div class="mx-n3">
    <v-card elevation="0">
        <v-row
          no-gutters
          align="center"
          >
          <v-col sm="3">
            <v-text-field
              v-model="search"
              solo-inverted
              dense
              flat
              hide-details
              label="Search"
              prepend-inner-icon="search"
              class="mr-4"
            ></v-text-field> 
          </v-col>
          
          <v-col
            sm="3"
            v-if="options.filter"
            >
            <v-select
              solo-inverted
              dense
              flat
              hide-details
              label="Filter By Status"
              prepend-inner-icon="filter_list"
              class="mr-4"
              multiple
              :items="actionList"
              v-model="filteredAction"
            ></v-select> 
          </v-col>
          
          <v-col>
            <v-btn
              @click="handleOpenImportDialog()"
              v-if="options.upload"
              class="primary--text"
              text
              >Upload</v-btn>
            <v-btn 
              v-if="options.download"
              class="primary--text"
              text
              :href="logsCsv"
              :download="getFilename()"
              >Download</v-btn> 
            <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
          </v-col>
          
          <v-col sm="auto">
            
          </v-col>

        </v-row>

      <!-- Data table -->
  
      <v-data-table
        :headers="headers"
        :items="logs"
        :search="search"
        :options.sync="options"
        :loading="loadingTable"
        no-data-text="No Records..."
        class="px-6 pt-6"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
            close-on-content-click
          >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>

                <v-list-item
                  link
                  :disabled="!isRoleSatisfied('Editor')"
                  >
                  <v-icon class="mr-2">edit</v-icon>
                  <v-list-item-title
                    @click="handleEdit(item)"
                    >Edit
                  </v-list-item-title>
                </v-list-item>
                
                <v-list-item
                  link
                  :disabled="!isRoleSatisfied('Editor')"
                  >
                  <v-icon class="mr-2">delete</v-icon>
                  <v-list-item-title 
                    @click="handleDeleteConfirmation(item)"
                    >Delete
                  </v-list-item-title>
                </v-list-item>

              </v-list>
          </v-menu>
        </template>

        <template v-slot:[`item.created`]="{ item }">
          {{ item.created | date }}
        </template>

        <template v-slot:[`item.activated`]="{ item }">
          <div v-if="item.activated">{{ item.activated | date }}</div>
        </template>
        
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
          <div class="caption">{{ item.email }}</div>
        </template>
      
      </v-data-table>
    </v-card>
  </div>

</template>

<script>
  import { LOG_STATUS } from '@/constants/log';
  export default {
    data: () => ({
      // Component settings unique to this component
      tableTitle: 'Logs',
      tableSubtitle: 'Create and manage your program logs',
      tableIcon: 'account_circle',
      dialogImportOpen: false,
      search: '',
      name: '',
      selectedLogId: '',
      actionList: LOG_STATUS,
      filteredAction: [],
      statusChangingLog: null,
      
      // Table columns
      headers: [
        { text: 'Created', value: 'created', align: 'left'},
        { text: 'Action', value: 'action', align: 'left'},
        { text: 'Name', value: 'name', align: 'left'},
        { text: 'From', value: 'from', align: 'left' },
        { text: 'To', value: 'to', align: 'left' },
      ],

      // Component settings common to all components
      dialogNewOrEditOpen: false,
      dialogDeleteOpen: false,

      // Table settings
      options: {
        sortBy: ['created'],
        sortDesc: [true],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: true,
        filter: true,
        upload: false,
        download: false,
        more: false,
      },
      
    }),
    computed: {
      // Functions specific to this component
      logs () {
        return this.$store.state.log.logs
      },
      filteredLogs() {
        return this.filteredAction.length
          ? this.logs.filter(m => this.filteredAction.includes(m.action))
          : this.logs;
      },


      // Functions common to all components
      loadingTable () {
      return this.$store.getters.loadingTable
      },
      loading () {
      return this.$store.getters.loading
      },
      orgTheme () {
        return this.$store.getters.orgTheme
      },
      systemTheme () {
        return this.$store.getters.systemTheme
      },
    },

    created () {
    },

    methods: {
    }
  }
</script>

<style>
</style>

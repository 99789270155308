import Vue from "vue";
import Vuetify from "vuetify/lib";
import { store } from "../store";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        // primary: '#8803fc', // purple
        primary: store.getters.orgTheme.primary,
        secondary: store.getters.orgTheme.secondary,
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
      light: {
        // primary: '#8803fc', // purple
        primary: store.getters.orgTheme.primary,
        secondary: store.getters.orgTheme.secondary,
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
  icons: {
    iconfont: "md" || "fa",
  },
});

export default vuetify;

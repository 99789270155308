<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="rewardCatalogue">
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          {{ rewardCatalogueId ? 'Edit ' : 'Add New '}}
          {{ formTitle }}
        </v-card-title>
      </v-row>

      <v-card-text class="px-16">
        
        <v-row dense>
          <v-col>
            <v-text-field
              name="title"
              v-model="title"
              label="Reward Catalogue Title"
              v-validate="'required'"
              :error-messages="errors.collect('rewardCatalogue.title')"
            ></v-text-field>   
          </v-col>
        </v-row>
        
        <v-row dense>
          <v-col>
            <v-select
              name="status"
              v-model="selectedStatus"
              label="Status"
              v-validate="'required'"
              :error-messages="errors.collect('rewardCatalogue.status')"
              :items="statuses"
              item-text="text"
              item-value="value"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-select
              v-model="selectedCompanyTags"
              :items="companyTags"
              item-text="tag"
              item-value="id"
              label="Company Tags"
              chips
              multiple
              small-chips
              deletable-chips
            >
            </v-select>
          </v-col>
        </v-row>
        
        <v-row dense>
          <v-col>
            <v-select
              v-model="selectedMemberTags"
              :items="memberTags"
              item-text="tag"
              item-value="id"
              label="Member Tags"
              chips
              multiple
              small-chips
              deletable-chips
            >
            </v-select>  
          </v-col>
        </v-row>
        
        <v-row dense>
          <v-col>
            <v-alert
              type="warning"
              v-model="showAlert"
              >
              That rewardCatalogue is already registered
            </v-alert>
          </v-col>         
        </v-row>
      
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="handleClose()"
          >Cancel</v-btn>
        <v-btn
          :class="orgTheme.buttonTextColor"
          @click="saveRewardCatalogue('rewardCatalogue')"
          color="primary"
          :loading="loading"
          elevation="0"
          >Save</v-btn>
      </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    rewardCatalogueId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => 'Reward Catalogue title is required'
        },
        status: {
          required: () => 'Status is required'
        }
      }
    },
    statuses: [
      { text: 'Active', value: 'Active' },
      { text: 'Inactive', value: 'Inactive' }
    ],
    formTitle: 'Reward Catalogue',
    formIcon: 'storefront',
    showAlert: false,
    selectedStatus: '',
    title: '',
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
    selectedCompanyTags: [],
    selectedMemberTags: [],
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    rewardCatalogues () {
      return this.$store.state.rewardcatalogue.rewardCatalogues
    },
    companyTags () {
      return this.$store.state.companytag.companyTags
    },
    memberTags () {
      return this.$store.state.membertag.memberTags
    },
  },
  methods:{
    clear () {
      this.title = ''
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.selectedStatus = ''
      this.selectedCompanyTags = []
      this.selectedMemberTags = []
      this.showAlert = false;
      this.$validator.reset();
    },
    handleClose () {
      this.clear();
      this.$emit('onClose');
    },
    saveRewardCatalogue (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        this.$store.dispatch('setLoading', true)
        const payload = {
          // Add database fields here
          title: this.title,
          status: this.selectedStatus,
          titleUppercase: this.title.toUpperCase(),
          companyTags: this.selectedCompanyTags,
          memberTags: this.selectedMemberTags,
        };
        if (this.rewardCatalogueId) {
          // Update rewardCatalogue
          payload.id = this.rewardCatalogueId;
          this.$store.dispatch('updateRewardCatalogue', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create reward Catalogue
          this.$store.dispatch('createRewardCatalogue', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        }
      });
    },
  },
  watch: {
    rewardCatalogueId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedRewardCatalogue = this.rewardCatalogues.find(item => item.id === newVal);
          // Add database fields here
          const {
            title, status, created, updated, createdBy, updatedBy, companyTags, memberTags
          } = selectedRewardCatalogue;
          this.selectedStatus = status;
          this.title = title;
          this.selectedCompanyTags = companyTags;
          this.selectedMemberTags = memberTags;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
        }
      },
    },
  },
}
</script>
<template>
  <v-dialog v-model="open" max-width="1400px" persistent>
    <v-card>
      <form data-vv-scope="site">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
        absolute
        color="primary"
        :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            {{ siteId ? 'Configure ' : 'Add New '}}
            {{ formTitle }}
          </v-card-title>
        </v-row>
        
        
        <v-row
          v-if="selectedPod"
          justify="center"
          no-gutters
          class="mb-6"
        >
          <span 
            v-if="selectedStatus === 'Live'"
            class="incentable-form-title">
            <a 
              :href="'https://' + getActiveDomain()"
              target="_blank"
            >
            {{ getActiveDomain() }}
            </a>
          </span>
          <span 
            v-else
            class="incentable-form-title">
            {{ getActiveDomain() }}
          </span>
        </v-row>
        <v-card-text class="px-12">
          <v-row>
            <v-col cols="3" class="mr-6">
              <v-select
                name="status"
                label="Status"
                v-model="selectedStatus"
                :items="statuses"
                append-icon="power_settings_new"
              >
              </v-select>
              <v-select
                name="pod"
                label="Pod"
                v-validate="'required'"
                :error-messages="errors.collect('site.pod')"
                v-model="selectedPod"
                :items="pods"
                item-text="text"
                item-value="value"
                append-icon="dns"
              ></v-select>
              <v-select
                v-if="siteId"
                name="program"
                :items="programs"
                label="Program"
                v-model="selectedProgram"
                item-text="title"
                item-value="id"
                clearable
              ></v-select>
              
              <div class="program-id mt-n2">Program Id:{{ program }}<span class="ml-1 remove primary--text" @click="remove">Remove</span></div>

              <v-radio-group v-model="domainType" class="mt-9">
                <v-radio label="Temporary URL" value="Temp"></v-radio>
                <v-radio label="Incentable Subdomain" value="Sub"></v-radio>
                <v-radio label="Client URL" value="Client"></v-radio>
              </v-radio-group>
              <v-text-field
                  v-if="domainType === 'Temp'"
                  :value="getTempSubdomain()"
                  name="tempSubdomain"
                  label="Temporary Subdomain"
                  id="tempSubdomain"
                  readonly
                  hint="Auto generated and cannot be edited"
                ></v-text-field>
                
                <v-text-field
                  v-if="domainType === 'Sub'"
                  v-model="incentableSubdomain"
                  name="incentableSubdomain"
                  label="Incentable Subdomain"
                ></v-text-field>
                <v-text-field
                  v-if="domainType === 'Client'"
                  v-model="clientDomain"
                  name="clientDomain"
                  label="Client Domain"
                ></v-text-field>
              
            </v-col>
            
            <v-col>
              <v-card elevation="0" color="grey lighten-2" class="px-7 pt-7 mr-2">
              <v-row no-gutters class="page-heading">
                <v-col>
                  Hosting Set Up
                </v-col> 
              </v-row>
              <v-row no-gutters class="incentable-form-heading mt-2">
                Firebase
              </v-row>
              <v-row no-gutters>
                <v-col>
                  1. Go to Firebase Project indicated below, in the 
                  <a href="https://console.firebase.google.com/" target="_blank">
                  Firebase Console
                  </a>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="ml-6 incentable-form-heading my-3 primary--text" v-if="selectedPod">
                  {{ podName(selectedPod).text }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="mt-2">
                  2. From the 'Hosting' menu, click the [Add Custom Domain] button'. Copy & Paste the URL below...
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="ml-6 incentable-form-heading my-3 primary--text">
                  {{ getActiveDomain() }}
                </v-col>
              </v-row>
              <v-row no-gutters class="incentable-form-heading mt-2">
                DNS Set Up
              </v-row>
                <v-row v-if="domainType === 'Temp' || domainType === 'Sub'" no-gutters >
                  <v-col class="mt-2">
                    1. Go to  
                    <a href="https://cloud.digitalocean.com/" target="_blank" class="mr-1">
                      Digital Ocean
                    </a>
                    and add DNS records provided by Firebase Hosting
                  </v-col>
                </v-row>
                <v-row v-else no-gutters >
                  <v-col>
                    Record the DNS settings provided by Firebase Hosting below. Provide these settings to the client to configure their own domain
                  </v-col>
                </v-row>


                <v-row no-gutters>
                  <v-col>
                    <v-textarea
                      name="dns"
                      label="DNS Settings"
                      v-model="dns"
                      outlined
                      filled
                      background-color="white"
                      class="mt-2"
                    ></v-textarea>
                  </v-col>
                </v-row>

              </v-card>
            </v-col>
            
            <v-col cols="3">
                
              <div v-if="siteId">
                <v-row no-gutters class="incentable-form-heading">
                  <v-col>
                    More
                  </v-col> 
                </v-row>
                <v-row no-gutters class="incentable-form-subheading">
                  <v-col>
                    Additional information
                  </v-col>
                </v-row>

                <v-text-field
                  :value="created | date"
                  label="Created"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  :value="getAuthorName(createdBy)"
                  label="Created By"
                  readonly
                >
                </v-text-field>
                <v-text-field
                  :value="updated | date"
                  label="Updated"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  :value="getAuthorName(updatedBy)"
                  label="Updated By"
                  readonly
                >
                </v-text-field>
              </div>
            </v-col> 
          </v-row>


          <v-row>
            <v-col>
              <v-alert
                v-model="showDomainAlert"
                color="primary"
                border="left"
                text
                type="info"
                >
                {{ domainAlert }}
              </v-alert>
            </v-col>         
          </v-row>
          
          <v-row>
            <v-col>
              <v-alert
                v-model="showAlert"
                color="primary"
                border="left"
                text
                type="warning"
                >
                That domain is already registered...
              </v-alert>
            </v-col>         
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4 px-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleClose()"
            >Cancel
          </v-btn>
          <v-btn
            @click="saveSite('site')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            elevation="0"
            :disabled="showDomainAlert"
            dense
            >Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { PODS } from '@/constants/pod'
export default {
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    siteId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    nanoid: {
      type: String,
      required: true,
    },
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  created () {
  },
  data: () => ({
    dictionary: {
      custom: {
        pod: {
          required: () => 'A Pod is required'
        },
      }
    },
    formTitle: 'Domain',
    formIcon: 'dns',
    statuses: [
      { text: 'Draft', value: 'Draft' },
      { text: 'Available', value: 'Available' },
      { text: 'Processing', value: 'Processing' },
      { text: 'Live', value: 'Live' },
      { text: 'Deactivated', value: 'Deactivated' }
    ],
    dns: '',
    showAlert: false,
    pods: PODS,
    podLimit: 18,
    selectedStatus: 'Draft',
    selectedPod: '',
    selectedProgram: '',
    domainType: 'Temp',
    tempSubdomain: '',
    program: '',
    incentableSubdomain: '',
    clientDomain: '',
    activeDomain: '',
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    sites () {
      return this.$store.state.site.sites
    },
    programs () {
      return this.$store.state.program.programs
    },
    demoPrograms () {
      return this.$store.state.program.demoPrograms
    },
    allPrograms () {
      return this.programs.concat(this.demoPrograms)
    },
    siteAuthorMap() {
      return this.$store.getters.systemAdmins.reduce((result, item) => {
        return {
          ...result,
          [item.userId]: item.name,
        };
      }, {});
    },
    showDomainAlert() {
      if (this.domainType === 'Sub' && this.incentableSubdomain === '') {
        return true
      } else if (this.domainType === 'Client' && this.clientDomain === '') {
        return true
      } else {
        return false
      }
    },
    domainAlert() {
      if (this.domainType === 'Sub' && this.incentableSubdomain === '') {
        return 'Incentable subdomain is required'
      } else if (this.domainType === 'Client' && this.clientDomain === '') {
        return 'Client domain is required'
      } else {
        return ''
      }
    }
  },
  methods:{
    remove() {
      this.program = ''
      this.selectedProgram = ''
    },
    podName (val) {
      const arr = PODS
      const found = arr.find(el => el.value === val)
      return found
    },
    getTempSubdomain () {
      if (this.siteId) {
        return this.tempSubdomain
      } else {
        return this.nanoid
      }
    },
    getActiveDomain () {
      if (this.domainType === 'Sub') {
        return this.incentableSubdomain
      } else if  (this.domainType === 'Client') {
        return this.clientDomain
      } else {
        return this.getTempSubdomain() +'.'+ this.selectedPod +'.incentable.com'
      }
    },
    getAuthorName(id) {
      return this.siteAuthorMap[id];
    },
    clear () {
      this.selectedStatus = 'Draft'
      this.domainType = 'Temp'
      this.selectedPod = ''
      this.selectedProgram = ''
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.incentableSubdomain = ''
      this.dns = ''
      this.program = ''
      this.id = ''
      this.tempSubdomain = ''
      this.clientDomain = ''
      this.activeDomain = ''
      this.showAlert = false
      this.$validator.reset()
      this.$store.dispatch('setLoading', false)
    },
    handleClose () {
      this.clear();
      this.$emit('onClose');
    },
    saveSite (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return
        }
        this.$store.dispatch('setLoading', true)

        const payload = {
          // Add database fields here
          status: this.selectedStatus,
          program: this.selectedProgram || '',
          pod: this.selectedPod,
          dns: this.dns,
          tempSubdomain: this.getTempSubdomain(),
          incentableSubdomain: this.incentableSubdomain || '',
          clientDomain: this.clientDomain || '',
          activeDomain: this.getActiveDomain(),
          domainType: this.domainType,
        }
        if (this.siteId) {
          // Update Site
          payload.id = this.siteId;
          this.$store.dispatch('updateSite', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        } else {
          // Create Site
          this.$store.dispatch('createSite', payload)
            .then((createResult) => {
              this.$store.dispatch('setLoading', false);
              if (createResult === 'ok') {
                this.handleClose();
              } else if (createResult === 'duplication') {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch('setLoading', false);
            })
        }
      });
    },
  },
  watch: {
    siteId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedSite = this.sites.find(item => item.id === newVal);
          const {
            status, program, created, updated, createdBy, updatedBy, id,
            pod, tempSubdomain, domainType, incentableSubdomain, clientDomain, activeDomain, dns
          } = selectedSite;
          this.selectedStatus = status;
          this.selectedProgram = program;
          this.program = program;
          this.dns = dns
          this.id = id
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
          this.selectedPod = pod;
          this.tempSubdomain = tempSubdomain;
          this.domainType = domainType;
          this.clientDomain = clientDomain;
          this.activeDomain = activeDomain;
          this.incentableSubdomain = incentableSubdomain;
        }
      },
    },
  },
}
</script>

<style>
.remove {
  cursor: pointer;
  text-decoration: underline;
}
.program-id {
  font-size: 11px !important;
  float: right;
}
</style>
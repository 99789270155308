<template>
  <div class="mt-4">
    <draggable
      v-model="localSections"
      class="w-100"
      :group="{ name: 'sections', pull: true, put: true }"
      ghost-class="ghost-section"
      :move="handleMove"
    >
      <v-row v-for="(section, index) in localSections" :key="index" no-gutters>
        <v-hover v-slot="{ hover }">
          <v-sheet
            :class="{ 'on-hover': hover }"
            class="section w-100 drag mb-0"
            elevation="0"
          >
            <v-row no-gutters>
              <Columns
                :columns="section.columns"
                :pageIdx="pageIdx"
                :sectionIdx="index"
              ></Columns>

              <v-sheet v-if="hover" class="hover-actions">
                <v-sheet class="action-sheet">
                  <v-icon class="action-icon">drag_indicator</v-icon>

                  <v-icon class="action-icon" @click="deleteSection(index)"
                    >delete_forever</v-icon
                  >
                </v-sheet>
              </v-sheet>
            </v-row>
          </v-sheet>
        </v-hover>
      </v-row>
      <v-row no-gutters justify="end">
        <v-btn v-if="showBack" elevation="0" color="secondary" @click="backPage"
          >back</v-btn
        >
        <v-btn
          elevation="0"
          color="secondary"
          @click="nextPage"
          v-if="!showSubmit"
          >next</v-btn
        >
        <v-btn v-if="showSubmit" elevation="0" color="secondary">submit</v-btn>
      </v-row>
    </draggable>
  </div>
</template>

<script>
// import { nanoid } from "nanoid";
import Columns from "./Columns.vue";

export default {
  components: { Columns },
  props: {
    pageIdx: {
      type: Number,
      required: true,
    },
    sections: {
      type: Array,
      required: true,
    },
    showSubmit: {
      type: Boolean,
      required: false,
    },
    showBack: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({}),
  created() {},
  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    localSections: {
      get() {
        return this.sections;
      },
      set(change) {
        this.currentOffer.form.pages[this.pageIdx].sections = change;
        this.patchCurrentOffer();
      },
    },
  },
  methods: {
    deleteSection(index) {
      this.currentOffer.form.pages[this.pageIdx].sections.splice(index, 1);
      this.patchCurrentOffer();
    },
    handleMove(event) {
      const itemMovedGroup = event.draggedContext.element.group;
      const destinationGroup = event.relatedContext.component.$attrs.group.name;
      if (itemMovedGroup === destinationGroup) {
        this.patchCurrentOffer();
        return true;
      } else {
        return false;
      }
    },
    patchCurrentOffer() {
      const update = {
        form: this.currentOffer.form,
      };
      this.$store.dispatch("patchCurrentOffer", update);
    },
    backPage() {
      this.$emit("onBackPage");
    },
    nextPage() {
      this.$emit("onNextPage");
    },
  },
  watch: {},
};
</script>

<style scoped>
.drag {
  cursor: all-scroll !important;
}
.ghost-section {
  border: 2px dotted #797979 !important;
  border-radius: 5px;
}
.ghost {
  border: 2px dotted rgb(134, 134, 134);
}
.section {
  min-height: 80px !important;
  border: 1px dotted rgb(134, 134, 134);
}
.w-100 {
  width: 100%;
}
.drag-icon {
  color: #2b2b2b !important;
  font-size: 22px !important;
  margin-left: 3px;
}
.delete-icon {
  color: #2b2b2b !important;
  font-size: 22px !important;
  margin-right: 3px;
}
.v-sheet {
  cursor: all-scroll;
  border: 2px solid #3f3f3f !important;
  position: relative; /* Ensure it remains in flow for absolute children */
}
.v-sheet:not(.on-hover) {
  border: 2px solid transparent !important;
}
.hover-actions {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent !important;
}
.action-sheet {
  background: #3f3f3f !important;
  margin-bottom: -4px !important;
}
.action-icon {
  color: #ffffff !important;
  font-size: 20px !important;
  margin: 3px;
}
</style>

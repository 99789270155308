/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  rewardCatalogues: [],
};

const actions = {
  async loadRewardCatalogues({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewardCatalogues")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const rewardCatalogues = [];
    querySnapshot.forEach(function (doc) {
      // Add database fields here to customise for specific table
      rewardCatalogues.push({
        id: doc.id,
        status: doc.data().status,
        title: doc.data().title,
        titleUppercase: doc.data().titleUppercase,
        created: doc.data().created.toDate(),
        createdBy: doc.data().createdBy,
        updated: doc.data().updated.toDate(),
        updatedBy: doc.data().updatedBy,
        memberTags: doc.data().memberTags,
        companyTags: doc.data().companyTags,
      });
    });

    commit("setRewardCatalogues", rewardCatalogues);
    commit("setLoadingTable", false);
  },

  async createRewardCatalogue({ commit, getters }, payload) {
    const rewardCataloguesCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("rewardCatalogues");

    let existingRewardCatalogueResult;
    try {
      existingRewardCatalogueResult = await rewardCataloguesCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing rewardCatalogue";
    }

    if (existingRewardCatalogueResult.size > 0) {
      return "duplication";
    }

    const rewardCatalogue = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newRewardCatalogueRef;
    try {
      newRewardCatalogueRef = await rewardCataloguesCollection.add(
        rewardCatalogue
      );
    } catch (e) {
      throw "Error when creating a new Reward Catalogue";
    }

    // Note: server time is unavailable until we refetch.
    const tempRewardCatalogue = {
      ...rewardCatalogue,
      id: newRewardCatalogueRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createRewardCatalogue", tempRewardCatalogue);
    commit("setSnackbar", "rewardCatalogue added");

    return "ok";
  },

  async updateRewardCatalogue({ commit, getters }, payload) {
    const rewardCataloguesCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("rewardCatalogues");

    let existingRewardCatalogueResult;
    try {
      existingRewardCatalogueResult = await rewardCataloguesCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing rewardCatalogue";
    }

    let duplicated = false;
    existingRewardCatalogueResult.forEach((doc) => {
      if (doc.id !== payload.id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    const rewardCatalogue = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await rewardCataloguesCollection.doc(payload.id).update(rewardCatalogue);
    } catch (e) {
      throw "Error when updating a Reward Catalogue";
    }

    const rewardCatalogueBeforeUpdate = getters.rewardCatalogues.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempRewardCatalogue = {
      ...rewardCatalogueBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updateRewardCatalogue", tempRewardCatalogue);
    commit("setSnackbar", "rewardCatalogue updated");

    return "ok";
  },

  async deleteRewardCatalogue({ commit, getters }, id) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewardCatalogues")
        .doc(id)
        .delete();
    } catch (e) {
      throw "Error when deleting an Reward Catalogue";
    }
    commit("deleteRewardCatalogue", id);
    commit("setSnackbar", "rewardCatalogue deleted");
  },
};

const mutations = {
  setRewardCatalogues(state, payload) {
    state.rewardCatalogues = payload;
  },

  createRewardCatalogue(state, payload) {
    state.rewardCatalogues = [...state.rewardCatalogues, payload];
  },

  updateRewardCatalogue(state, payload) {
    state.rewardCatalogues = state.rewardCatalogues.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteRewardCatalogue(state, payload) {
    state.rewardCatalogues = state.rewardCatalogues.filter(
      (item) => item.id !== payload
    );
  },
};

const getters = {
  rewardCatalogues(state) {
    return state.rewardCatalogues;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

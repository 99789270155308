<template>
  <v-dialog
    v-model="isVisible"
    :width="dialogWidth"
    :fullscreen="component.dialog.fullscreen"
    persistent
    :scrollable="scrollable"
    class="pa-0 ma-0"
  >
    <v-row v-if="play" justify="end" no-gutters>
      <v-btn
        absolute
        color="#21212185"
        @click="onClose() + stopVideo()"
        class="mt-n8 mr-n8"
        fab
        x-small
      >
        <v-icon color="white" size="20">clear</v-icon>
      </v-btn>
    </v-row>

    <v-card v-if="!play" :class="classes" :style="styles + ';' + gradient">
      <v-img
        v-if="component.dialog.image.show === true && !play"
        :height="component.dialog.image.height + 'px'"
        :lazy-src="imageUrl"
        :src="imageUrl"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-row
          v-if="article.videoUrl"
          justify="center"
          align="center"
          class="fill-height"
          no-gutters
        >
          <v-btn
            @click="playVideo(article.videoUrl)"
            fab
            large
            dark
            color="#21212185"
            ><v-icon :color="primary" size="50">play_arrow</v-icon></v-btn
          >
        </v-row>
      </v-img>

      <v-btn
        v-if="component.dialog.fullscreen === true"
        top
        right
        absolute
        small
        icon
        @click="onClose"
        style="z-index: 100"
      >
        <v-icon :color="component.dialog.title.color">clear</v-icon>
      </v-btn>

      <div v-if="!play" :style="textArea">
        <v-row no-gutters v-if="article.title">
          <v-col>
            <view-text :settings="component.heading" :value="article.title" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <view-text
              :settings="component.date"
              :value="date(article.updated)"
            />
          </v-col>
          <v-col>
            <view-text
              :settings="component.author"
              :value="getAuthorName(article.updatedBy)"
            />
          </v-col>
        </v-row>
      </div>

      <v-card-text :style="textArea">
        <v-row no-gutters v-if="article.body">
          <v-col>
            <view-text :settings="component.body" :value="article.body" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="!play">
        <v-spacer></v-spacer>
        <v-btn
          v-if="component.dialog.fullscreen !== true"
          @click="onClose() + stopVideo()"
          :color="component.dialog.buttonColor.hexa"
          :class="component.dialog.buttonText.color"
          elevation="0"
          >close</v-btn
        >
      </v-card-actions>
    </v-card>

    <iframe
      v-if="play"
      :src="videoCode"
      width="720"
      height="405"
      allow="autoplay"
      frameborder="0"
      allowfullscreen
      class="rounded ma-0 pa-0"
    >
    </iframe>
  </v-dialog>
</template>

<script>
import ViewText from "../Text.vue";
import moment from "moment";

export default {
  components: {
    ViewText,
  },
  props: ["isVisible", "onClose", "article", "component"],
  created() {
    console.log(this.article);
    console.log(this.component);
  },
  data: function () {
    return {
      play: false,
      videoCode: null,
    };
  },
  computed: {
    imageUrl() {
      return this.article.image.mediumUrl;
      //return "";
    },
    scrollable() {
      if (this.play) {
        return false;
      }
      return true;
    },
    classes() {
      if (this.component.dialog.fullscreen === true) return "rounded-0";
      return this.component.card.rounded;
    },
    dialogWidth() {
      if (this.play) {
        return 720;
      } else {
        return this.component.dialog.width;
      }
    },
    grid() {
      return this.$store.getters.grid;
    },
    rowBorders() {
      return (
        this.component.dialog.rowBorderSize +
        "px" +
        " solid " +
        this.component.dialog.rowBorderColor.hexa
      );
    },
    iconAlign() {
      return this.component.dialog.content.align;
    },
    iconSize() {
      return this.component.dialog.iconSize;
    },
    primary() {
      return this.webTheme.colors.primary.hex;
    },
    gradient() {
      if (this.component.dialog.color.type === "gradient") {
        var color = this.component.dialog.color;
        var gradient =
          "background: linear-gradient(" +
          color.direction +
          ", rgba(" +
          color.gradientFrom.rgba.r +
          ", " +
          color.gradientFrom.rgba.g +
          ", " +
          color.gradientFrom.rgba.b +
          ", " +
          color.gradientFrom.rgba.a +
          ") " +
          ", rgba(" +
          color.gradientTo.rgba.r +
          ", " +
          color.gradientTo.rgba.g +
          ", " +
          color.gradientTo.rgba.b +
          ", " +
          color.gradientTo.rgba.a +
          ")" +
          color.gradientHeight +
          "%)";
        return gradient;
        // return 'background: linear-gradient(to top, rgba(0, 0, 255, 100), rgba(255, 0, 0, 100) 50%)'
      } else {
        return (
          "background: " + this.component.dialog.color.solidColor.hexa + ";"
        );
      }
    },
    styles() {
      var styles =
        "padding-bottom: " +
        this.component.dialog.padding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.dialog.padding.top +
        "px ;" +
        "padding-left: " +
        this.component.dialog.padding.left +
        "px ;" +
        "padding-right: " +
        this.component.dialog.padding.right +
        "px ;" +
        "border: " +
        this.component.dialog.borderSize +
        "px solid " +
        this.component.dialog.borderColor.hexa +
        "; ";
      return styles;
    },
    textArea() {
      var styles =
        "padding-bottom: " +
        this.component.dialog.textAreaPadding.bottom +
        "px ;" +
        "padding-top: " +
        this.component.dialog.textAreaPadding.top +
        "px ;" +
        "padding-left: " +
        this.component.dialog.textAreaPadding.left +
        "px ;" +
        "padding-right: " +
        this.component.dialog.textAreaPadding.right +
        "px ;";
      return styles;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    articles() {
      return this.$store.state.article.articles;
    },
    blogAuthorMap() {
      return this.$store.getters.admins.reduce((result, item) => {
        return {
          ...result,
          [item.userId]: item.name,
        };
      }, {});
    },
  },
  methods: {
    playVideo(id) {
      this.videoCode =
        "https://www.youtube-nocookie.com/embed/" + id + "?autoplay=1";
      this.play = true;
      console.log(id);
    },
    stopVideo() {
      this.videoCode = null;
      this.play = false;
    },
    date(date) {
      return moment(date).format("ddd MMMM Do YYYY");
    },
    getAuthorName(adminId) {
      return this.blogAuthorMap[adminId];
    },
  },
};
</script>

<style>
.scroll {
  position: relative;
  margin: auto;
  height: auto;
}
</style>

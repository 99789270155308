<template>
  <v-dialog :value="true" width="450px" persistent>
    <v-container fluid fill-height class="pa-0">
      <v-card width="450px" class="py-6">
        <v-row align="center" justify="center" no-gutters>
          <v-col align="center">
            <v-row
              v-if="!syncSuccess"
              no-gutters
              class="page-heading mt-6 mb-6"
              justify="center"
            >
              Updating Tables & Leaderboards...
            </v-row>
            <v-row
              v-if="syncSuccess"
              no-gutters
              class="page-heading mt-6 mb-6 green--text"
              justify="center"
            >
              Success!
            </v-row>
            <v-icon
              v-if="syncSuccess"
              size="100"
              color="green"
              class="mb-6 mt-0"
              >task_alt</v-icon
            >
            <v-progress-circular
              v-if="!syncSuccess"
              indeterminate
              color="primary"
              width="8"
              size="100"
            ></v-progress-circular>
            <v-row
              v-if="!syncSuccess"
              no-gutters
              class="page-heading primary--text mt-6 mb-6"
              justify="center"
            >
              Do Not Close Browser
            </v-row>
            <v-row no-gutters v-for="log in logs" :key="log" class="mr-4 ml-4">
              <v-icon class="mr-1" small>check</v-icon
              ><span class="caption">{{ log }}</span>
            </v-row>

            <v-row v-if="errorMessage" no-gutters class="pa-3">
              <v-alert type="error" :value="true">
                {{ errorMessage }}
              </v-alert>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data: function () {
    return {
      title: "",
      errorMessage: "",
    };
  },
  props: {
    logs: {
      type: Array,
      required: true,
    },
    syncSuccess: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },
};
</script>

<style scoped></style>

export const DEMO_USER = {
  _id: "gkYoLjedSbujCpHS3AEW",
  email: "ketch@incentable.com",
  phone: "+613 5555 12345",
  fullname: "Ketch Miller",
  accountKey: "123456",
  firstname: "Ketch",
  lastname: "Miller",
  balance: "15250",
  awarded: "35980",
  redeemed: "20730",
  tags: [],

  cartValue: 1100,
  tierImage:
    "https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/system%2Femerald_76x76.png?alt=media&token=79cc839e-0169-4507-8dc9-308d99a7c4ed",
  tierImageSize: 24,
  tierLabel: "Emerald",
  cart: [
    {
      description: "$1,000 Gift Card",
      points: 1000,
      each: 1000,
      subtotal: 1000,
      quantity: 1,
      image:
        "https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/uploads%2FdxkKiRMcpF2rd2ikRbv7%2FrewardBanners%2F1613789791185_apple.png?alt=media&token=8b5e772a-41cc-4abd-bc52-c33da2c24b28",
    },
    {
      description: "$50 Gift Card",
      points: 100,
      each: 50,
      subtotal: 100,
      quantity: 2,
      image:
        "https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/uploads%2FdxkKiRMcpF2rd2ikRbv7%2FrewardBanners%2F1613789791185_apple.png?alt=media&token=8b5e772a-41cc-4abd-bc52-c33da2c24b28",
    },
  ],
  transactions: [
    {
      created: "Aug 03, 2021, 06:56 AM",
      status: "Adjustment",
      description: "Admin",
      notes: "Demo",
      points: 500,
    },
    {
      created: "Aug 09, 2021, 06:56 AM",
      status: "Redeemed",
      description: "Rewards",
      notes: "$50 Gift Card",
      points: -500,
    },
    {
      created: "Sep 03, 2021, 06:56 AM",
      status: "Adjustment",
      description: "Admin",
      notes: "Demo",
      points: 1500,
    },
    {
      created: "Oct 05, 2021, 06:56 AM",
      status: "Adjustment",
      description: "Admin",
      notes: "Demo",
      points: 4500,
    },
    {
      created: "Nov 04, 2021, 06:56 AM",
      status: "Adjustment",
      description: "Admin",
      notes: "Demo",
      points: 6500,
    },
    {
      created: "Nov 13, 2021, 06:56 AM",
      status: "Adjustment",
      description: "Admin",
      notes: "Demo",
      points: 500,
    },
    {
      created: "Dec 09, 2021, 06:56 AM",
      status: "Redeemed",
      description: "Rewards",
      notes: "$50 Gift Card",
      points: -500,
    },
    {
      created: "Jan 16, 2022, 06:56 AM",
      status: "Adjustment",
      description: "Admin",
      notes: "Demo",
      points: 2750,
    },
  ],
  image: {
    mediumUrl:
      "https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/media%2FdxkKiRMcpF2rd2ikRbv7%2F2WVlHSKw9a7mXrqB7C2c4_christian-buehner-DItYlc26zVI-unsplash_600x600.jpg?alt=media&token=8e9c5631-9167-4e77-a2b7-70b537770edc",
  },
  companies: ["xXsKLOd49V7t0RbFFik1"], // Vandelay Industries
};

/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
import _ from "lodash";

const state = {
  translations: [],
  translationData: [],
};

const actions = {
  async saveDefaultLocale({ dispatch, getters }, payload) {
    const data = {
      translate: {
        locale: payload.locale,
        hide: payload.hide,
        updated: timestamp,
      },
    };
    const programRef = db.collection("programs").doc(getters.programId);

    try {
      await programRef.update(data);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating language.";
    }
    dispatch("setSnackbar", "Default language updated");
  },

  async loadTranslations({ commit, getters }, programId = getters.programId) {
    //console.log(programId);
    commit("setLoadingTable", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(programId)
        .collection("translations")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const translations = [];
    querySnapshot.forEach(function (doc) {
      translations.push({
        id: doc.id,
        title: doc.data().title,
        titleUppercase: doc.data().titleUppercase,
        locale: doc.data().locale,
        directory: doc.data().directory,
        status: doc.data().status,
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
        createdBy: doc.data().createdBy,
        updatedBy: doc.data().updatedBy,
      });
    });
    //console.log(translations);
    commit("setTranslations", translations);
    commit("setLoadingTable", false);
  },

  async loadTranslationData(
    { commit, getters },
    programId = getters.programId
  ) {
    commit("setLoadingTable", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(programId)
        .collection("translations")
        .get();
    } catch (e) {
      querySnapshot = [];
    }
    const translationData = [];
    querySnapshot.forEach(function (doc) {
      translationData.push({
        locale: doc.data().locale,
        directory: { ...doc.data().directory },
      });
    });
    const result = Object.keys(translationData).reduce(
      (translationObj, key) => {
        const { directory, locale } = translationData[key];
        const directories = Object.keys(directory).reduce(
          (directoryObj, directoryKey) => {
            const { english, value } = directory[directoryKey];
            return { ...directoryObj, [english]: value };
          },
          {}
        );
        return { ...translationObj, [locale]: directories };
      },
      {}
    );
    //console.log(result);
    commit("setTranslationData", result);
    commit("setLoadingTable", false);
  },

  async createTranslation({ dispatch, commit, getters }, payload) {
    //console.log(payload);
    const translationsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("translations");

    let titleDupSnapshot;

    try {
      titleDupSnapshot = await translationsRef
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking title";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Language is already registered.";
    }

    // eslint-disable-next-line no-unused-vars
    const { id, ...translationInput } = payload;

    const translation = {
      ...translationInput,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newTranslationRef;
    try {
      newTranslationRef = await translationsRef.add(translation);
    } catch (e) {
      console.error(e);
      throw "Error when creating a new translation";
    }

    // Note: server time is unavailable until we refetch.
    const tempTranslation = {
      ...translation,
      id: newTranslationRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createTranslation", tempTranslation);
    dispatch("setSnackbar", "Translation Created.");
  },

  async updateTranslation({ dispatch, commit, getters }, payload) {
    const translationsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("translations");

    let storedTranslation;
    try {
      const translationDoc = await translationsRef.doc(payload.id).get();
      storedTranslation = translationDoc.data();
    } catch (e) {
      storedTranslation = null;
    }

    if (!storedTranslation) {
      throw "Error occured when fetching the translation info";
    }

    let dupCheckRequests;
    let titleDupSnapshot;

    if (payload.accountKey) {
      dupCheckRequests = [
        translationsRef
          .where("titleUppercase", "==", payload.titleUppercase)
          .get(),
      ];
    } else {
      dupCheckRequests = [
        translationsRef
          .where("titleUppercase", "==", payload.titleUppercase)
          .get(),
      ];
    }

    try {
      [titleDupSnapshot] = await Promise.all(dupCheckRequests);
    } catch (e) {
      throw "Error occured when checking title and account key.";
    }

    if (titleDupSnapshot.size > 0) {
      let duplicated = false;
      titleDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Translation Name is already registered.";
      }
    }

    const { id, ...translationInput } = payload;

    //end
    try {
      await translationsRef.doc(id).update(translationInput);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a translation";
    }

    const tempTranslation = {
      ...storedTranslation,
      ...translationInput,
      id,
      created: storedTranslation.created.toDate(),
      updated: new Date(),
    };

    commit("updateTranslation", tempTranslation);
    dispatch("setSnackbar", "Translation Updated.");
  },

  async deleteTranslation({ dispatch, commit, getters }, translationId) {
    const translationsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("translations");

    let storedTranslation;
    try {
      const translationDoc = await translationsRef.doc(translationId).get();
      storedTranslation = translationDoc.data();
    } catch (e) {
      storedTranslation = null;
    }

    if (!storedTranslation) {
      throw "Error occured when fetching the translation info.";
    }

    try {
      await translationsRef.doc(translationId).delete();
    } catch (e) {
      console.error(e);
      throw "Error occured when deleting a translation.";
    }

    commit("deleteTranslation", translationId);
    dispatch("setSnackbar", "Translation Deleted.");
  },

  async importTranslations({ getters }, newTranslations) {
    // Note: Firebase is counting serverTimestamp as one write.
    // We need to reduce the write counts because there is Max 500 writes limit.
    //

    // Note: Due to firebase's limit of Max 500 writes per batch
    // We are gonna split translations array into several chunks having 500 max translations
    const translationsChunks = _.chunk(newTranslations, 240);
    for (let i = 0; i < translationsChunks.length; i += 1) {
      const chunk = translationsChunks[i];
      const newTranslationsBatch = db.batch();
      chunk.forEach((translation) => {
        const { isUpdate, id, ...data } = translation;
        if (!isUpdate) {
          const dataWithTimestamp = {
            ...data,
            created: new Date(),
            updated: new Date(),
          };
          const newTranslationRef = db
            .collection("programs")
            .doc(getters.programId)
            .collection("translations")
            .doc();
          newTranslationsBatch.set(newTranslationRef, dataWithTimestamp);
        } else {
          const dataWithTimestamp = {
            ...data,
            updated: new Date(),
          };
          const updateTranslationRef = db
            .collection("programs")
            .doc(getters.programId)
            .collection("translations")
            .doc(id);
          newTranslationsBatch.update(updateTranslationRef, dataWithTimestamp);
        }
      });

      try {
        await newTranslationsBatch.commit();
      } catch (e) {
        console.error(e);
        throw `translations import batch failed - ${i + 1}st batch`;
      }
    }
  },
};

const mutations = {
  setTranslations(state, payload) {
    state.translations = payload;
  },

  setTranslationData(state, payload) {
    state.translationData = payload;
  },

  setLoadingTable(state, payload) {
    state.loadingTable = payload;
  },

  createTranslation(state, payload) {
    state.translations = [...state.translations, payload];
  },

  updateTranslation(state, payload) {
    state.translations = state.translations.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteTranslation(state, payload) {
    state.translations = state.translations.filter(
      (item) => item.id !== payload
    );
  },
};

const getters = {
  locale(state) {
    return state.locale;
  },

  translationData(state) {
    return state.translationData;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

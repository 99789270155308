<template>
  <div>
    <v-card class="el pa-3 rounded-lg">
      <v-progress-linear
        v-if="loading"
        indeterminate
        :active="loading"
        :color="orgTheme.primary"
      >
      </v-progress-linear>

      <v-row no-gutters>
        <v-col cols="auto">
          <v-card :color="iconCardColor" class="el pa-1">
            <v-icon class="count-icon" size="40" :color="iconColor">{{
              icon
            }}</v-icon>
          </v-card>
        </v-col>
        <v-col class="pl-4 mt-n2">
          <v-row no-gutters class="text">
            <span v-if="title === 'Contingent Liability'">$</span>
            <span v-if="value">{{ value | number }}</span>
            <span v-else>0</span>
          </v-row>
          <v-row class="sub-text mt-n1 mb-n1" no-gutters>
            {{ title }}
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["value", "orgTheme", "icon", "iconColor", "iconCardColor", "title"],
  data: function () {
    return {};
  },
  computed: {
    loading() {
      return this.$store.getters.loadingMembers;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },
  methods: {},
};
</script>

<style scoped>
.sub-text {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px;
  font-weight: 400 !important;
  color: #505050 !important;
}
.text {
  font-family: "Montserrat", sans-serif !important;
  font-size: 28px;
  font-weight: 700 !important;
  color: #505050 !important;
}
</style>

<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="tag">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
            absolute
            color="primary"
            :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            {{ memberTagId ? "Edit " : "Add New " }}
            {{ formTitle }}
          </v-card-title>
        </v-row>

        <v-card-text class="px-16">
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="tag"
                label="Member Tag"
                v-validate="'required'"
                :error-messages="errors.collect('tag.tag')"
                name="tag"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert type="error" :value="true">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Cancel</v-btn>
          <v-btn
            :class="orgTheme.buttonTextColor"
            color="primary"
            @click="handleSave('tag')"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Save</v-btn
          >
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  created() {},
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    memberTagId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dictionary: {
      custom: {
        tag: {
          required: () => "Tag is required",
        },
      },
    },
    tag: "",
    errorMessage: "",
    formTitle: "Member Tag",
    formIcon: "label",
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
  },

  watch: {
    memberTagId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedMemberTag = this.memberTags.find(
            (item) => item.id === newVal
          );
          const { tag } = selectedMemberTag;
          this.tag = tag;
        }
      },
    },
  },

  methods: {
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },

    clear() {
      this.tag = "";
      this.$validator.reset();
      this.$store.dispatch("setLoading", false);
    },

    handleSave(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }

        this.$store.dispatch("setLoading", true);

        const payload = {
          id: this.memberTagId || "",
          tag: this.tag || "",
          tagUppercase: this.tag.toUpperCase() || "",
        };

        const action = this.memberTagId ? "updateMemberTag" : "createMemberTag";

        this.$store
          .dispatch(action, payload)
          .then(() => {
            this.$store.dispatch("setLoading", false);
            this.handleClose();
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch("setLoading", false);
          });
      });
    },
  },
};
</script>

<style></style>

/* eslint-disable no-console */
import { db } from "@/firebase";

const state = {
  validationDataSets: [],
  loadingValidationDataSets: false,
};

const actions = {
  async loadValidationDataSets({ commit, getters }) {
    console.log("loading validation data sets");
    commit("setLoadingValidationDataSets", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("validationDataSets")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const validationDataSets = [];
    for (const doc of querySnapshot.docs) {
      const hasData = await checkForRecords(doc.id);

      validationDataSets.push({
        id: doc.id,
        hasData,
        ...doc.data(),
      });
    }

    async function checkForRecords(datasetId) {
      //console.log("checking for data validation records");
      const dataRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("validationDataSets")
        .doc(datasetId)
        .collection("data");

      const snapshot = await dataRef.limit(1).get();
      return snapshot.empty ? false : true;
    }

    commit("setValidationDataSets", validationDataSets);
    commit("setLoadingValidationDataSets", false);
  },

  async createValidationDataSet({ dispatch, commit, getters }, payload) {
    dispatch("setLoadingValidationDataSets", true);
    const titlesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("validationDataSets");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await titlesRef
        .where("dataSetTitleUppercase", "==", payload.dataSetTitleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking if the title has been used";
    }

    if (titleDupSnapshot.size > 0) {
      dispatch("setLoadingValidationDataSets", false);
      throw "Data Set is already registered";
    }

    const { id, ...validationDataSetInput } = payload; // eslint-disable-line no-unused-vars
    const title = {
      ...validationDataSetInput,
    };

    let newTitleRef;
    try {
      newTitleRef = await titlesRef.add(title);
    } catch (e) {
      dispatch("setLoadingValidationDataSets", false);
      throw "Error occured when creating a new Data Set";
    }

    commit("createValidationDataSet", {
      ...validationDataSetInput,
      id: newTitleRef.id,
    });
    dispatch("setLoadingValidationDataSets", false);
    dispatch("setSnackbar", "Data Set Created");
  },

  async updateValidationDataSet({ dispatch, commit, getters }, payload) {
    dispatch("setLoadingValidationDataSets", true);
    const validationDataSetRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("validationDataSets");

    let storedValidationDataSet;
    try {
      const validationDataSetDoc = await validationDataSetRef
        .doc(payload.id)
        .get();
      storedValidationDataSet = validationDataSetDoc.data();
    } catch (e) {
      storedValidationDataSet = null;
    }

    if (!storedValidationDataSet) {
      dispatch("setLoadingValidationDataSets", false);
      throw "Error occured when fetching the Data Set";
    }

    let validationDataSetDupSnapshot;
    try {
      validationDataSetDupSnapshot = await validationDataSetRef
        .where("dataSetTitleUppercase", "==", payload.dataSetTitleUppercase)
        .get();
    } catch (e) {
      dispatch("setLoadingValidationDataSets", false);
      throw "Error occured when checking if the Data Set exists.";
    }

    if (validationDataSetDupSnapshot.size > 0) {
      let duplicated = false;
      validationDataSetDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        dispatch("setLoadingValidationDataSets", false);
        throw "Data set title is already registered.";
      }
    }

    const { id, ...validationDataSetInput } = payload;
    const dataset = {
      ...validationDataSetInput,
    };

    try {
      await validationDataSetRef.doc(id).update(dataset);
    } catch (e) {
      console.error(e);
      dispatch("setLoadingValidationDataSets", false);
      throw "Error occured when updating a Data Set";
    }

    commit("updateValidationDataSet", payload);
    dispatch("setLoadingValidationDataSets", false);
    dispatch("setSnackbar", "Validation Data Set Updated.");
  },

  async deleteValidationDataSet({ dispatch, commit, getters }, titleId) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("validationDataSets")
        .doc(titleId)
        .delete();
    } catch (e) {
      throw "Error occured when deleting a title";
    }
    commit("deleteValidationDataSet", titleId);
    dispatch("setSnackbar", "Data set deleted.");
  },

  setLoadingValidationDataSets({ commit }, payload) {
    commit("setLoadingValidationDataSets", payload);
  },
};

const mutations = {
  setValidationDataSets(state, payload) {
    state.validationDataSets = payload;
  },

  setLoadingValidationDataSets(state, payload) {
    state.loadingValidationDataSets = payload;
  },

  createValidationDataSet(state, payload) {
    state.validationDataSets = [...state.validationDataSets, payload];
  },

  updateValidationDataSet(state, payload) {
    state.validationDataSets = state.validationDataSets.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteValidationDataSet(state, payload) {
    state.validationDataSets = state.validationDataSets.filter(
      (item) => item.id !== payload
    );
  },
};

const getters = {
  validationDataSets(state) {
    return state.validationDataSets;
  },
  loadingValidationDataSets(state) {
    return state.loadingValidationDataSets;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

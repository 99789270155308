/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
import firebase from "firebase/compat/app";
import _ from "lodash";
import * as utils from "@/utils";

const state = {
  companies: [],
  loadingCompanies: false,
};

const actions = {
  async loadCompanies({ commit, getters }) {
    commit("setLoadingTable", true);
    commit("setLoadingCompanies", true);

    /// new

    const dbRef = db.collection("programs").doc(getters.programId);

    const companiesData = dbRef
      .collection("companies")
      .orderBy("titleUppercase")
      .onSnapshot(
        (querySnapshot) => {
          let companies = [];
          querySnapshot.forEach((doc) => {
            companies.push({
              id: doc.id,
              ...doc.data(),
              accountKey: doc.data().accountKey || "",
              created: doc.data().created,
              updated: doc.data().updated,
            });
          });
          commit("setCompanies", companies);
        },
        (error) => {
          console.log(error);
          if (companiesData) {
            companiesData();
          }
        }
      );

    commit("setLoadingTable", false);
    commit("setLoadingCompanies", false);
  },

  async createCompany({ dispatch, getters }, payload) {
    const companiesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("companies");

    let dupCheckRequests;
    let titleDupSnapshot, accountKeyDupSnapshot;

    if (payload.accountKey) {
      dupCheckRequests = [
        companiesRef
          .where("titleUppercase", "==", payload.titleUppercase)
          .get(),
        companiesRef.where("accountKey", "==", payload.accountKey).get(),
      ];
    } else {
      dupCheckRequests = [
        companiesRef
          .where("titleUppercase", "==", payload.titleUppercase)
          .get(),
      ];
    }

    try {
      [titleDupSnapshot, accountKeyDupSnapshot] = await Promise.all(
        dupCheckRequests
      );
    } catch (e) {
      throw "Error occured when checking title and account key.";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Company name is already registered.";
    }

    if (accountKeyDupSnapshot && accountKeyDupSnapshot.size > 0) {
      throw "The account key is already registered.";
    }

    // eslint-disable-next-line no-unused-vars
    const { id, ...companyInput } = payload;

    const company = {
      ...companyInput,
      created: timestamp,
      updated: timestamp,
    };

    let newCompanyRef;
    try {
      newCompanyRef = await companiesRef.add(company);
    } catch (e) {
      console.error(e);
      throw "Error when creating a new company";
    }

    const memberChanges = payload.members.reduce((result, memberId) => {
      const change = {
        memberId,
        companyId: newCompanyRef.id,
        type: "add",
      };
      return [...result, change];
    }, []);

    // Note: server time is unavailable until we refetch.
    //const tempCompany = {
    //  ...company,
    //  id: newCompanyRef.id,
    //  created: timestamp,
    //  updated: timestamp,
    //};

    try {
      await Promise.all([dispatch("syncMemberCompanies", memberChanges)]);
    } catch (e) {
      console.error(e);
      throw "Error occured when syncing members";
    }

    // commit("createCompany", tempCompany);
    dispatch("setSnackbar", "Company Created");
  },

  async updateCompany({ dispatch, commit, getters }, payload) {
    const companiesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("companies");

    let storedCompany;
    try {
      const companyDoc = await companiesRef.doc(payload.id).get();
      storedCompany = companyDoc.data();
    } catch (e) {
      storedCompany = null;
    }

    if (!storedCompany) {
      throw "Error occured when fetching the company info";
    }

    let dupCheckRequests;
    let titleDupSnapshot, accountKeyDupSnapshot;

    if (payload.accountKey) {
      dupCheckRequests = [
        companiesRef
          .where("titleUppercase", "==", payload.titleUppercase)
          .get(),
        companiesRef.where("accountKey", "==", payload.accountKey).get(),
      ];
    } else {
      dupCheckRequests = [
        companiesRef
          .where("titleUppercase", "==", payload.titleUppercase)
          .get(),
      ];
    }

    try {
      [titleDupSnapshot, accountKeyDupSnapshot] = await Promise.all(
        dupCheckRequests
      );
    } catch (e) {
      throw "Error occured when checking title and account key.";
    }

    if (titleDupSnapshot.size > 0) {
      let duplicated = false;
      titleDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Company Name is already registered.";
      }
    }

    if (accountKeyDupSnapshot && accountKeyDupSnapshot.size > 0) {
      let duplicated = false;
      accountKeyDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      });
      if (duplicated) {
        throw "Account Key is already registered.";
      }
    }

    const { id, ...companyInput } = payload;

    //end
    try {
      await companiesRef.doc(id).update(companyInput);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a company";
    }

    const addedMembers = utils.getNewArrayItems(
      storedCompany.members,
      companyInput.members
    );
    const deletedMembers = utils.getNewArrayItems(
      companyInput.members,
      storedCompany.members
    );
    const addedMemberChanges = addedMembers.map((memberId) => {
      return { companyId: id, memberId, type: "add" };
    });
    const deletedMemberChanges = deletedMembers.map((memberId) => {
      return { companyId: id, memberId, type: "delete" };
    });
    const memberChanges = [...addedMemberChanges, ...deletedMemberChanges];

    try {
      await Promise.all([dispatch("syncMemberCompanies", memberChanges)]);
    } catch (e) {
      console.error(e);
      throw "Error occured when syncing members";
    }

    const tempCompany = {
      ...storedCompany,
      ...companyInput,
      id,
      created: storedCompany.created,
      updated: new Date(),
    };

    commit("updateCompany", tempCompany);
    dispatch("setSnackbar", "Company Updated");
  },

  async deleteCompany({ dispatch, commit, getters }, companyId) {
    const companiesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("companies");

    let storedCompany;
    try {
      const companyDoc = await companiesRef.doc(companyId).get();
      storedCompany = companyDoc.data();
    } catch (e) {
      storedCompany = null;
    }

    if (!storedCompany) {
      throw "Error occured when fetching the company info.";
    }

    const memberChanges = storedCompany.members.map((memberId) => {
      return { companyId, memberId, type: "delete" };
    });

    try {
      await Promise.all([dispatch("syncMemberCompanies", memberChanges)]);
    } catch (e) {
      console.error(e);
      throw "Error occured when syncing with members";
    }

    try {
      await companiesRef.doc(companyId).delete();
    } catch (e) {
      console.error(e);
      throw "Error occured when deleting a company";
    }

    commit("deleteCompany", companyId);
    dispatch("setLoading", false);
    dispatch("setSnackbar", "Company Deleted");
  },

  async syncMemberCompanies({ getters }, payload) {
    const memberUpdatesBatch = db.batch();
    payload.forEach((change) => {
      const { companyId, memberId } = change;
      const updateMemberRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("members")
        .doc(memberId);
      const update = {
        companies:
          change.type === "add"
            ? firebase.firestore.FieldValue.arrayUnion(companyId)
            : firebase.firestore.FieldValue.arrayRemove(companyId),
      };
      memberUpdatesBatch.update(updateMemberRef, update);
    });

    await memberUpdatesBatch.commit();
  },

  async importCompanies({ getters }, newCompanies) {
    // Note: Firebase is counting serverTimestamp as one write.
    // We need to reduce the write counts because there is Max 500 writes limit.
    //

    // Note: Due to firebase's limit of Max 500 writes per batch
    // We are gonna split companies array into several chunks having 500 max companies
    const companiesChunks = _.chunk(newCompanies, 240);
    for (let i = 0; i < companiesChunks.length; i += 1) {
      const chunk = companiesChunks[i];
      const newCompaniesBatch = db.batch();
      chunk.forEach((company) => {
        const { isUpdate, id, ...data } = company;
        if (!isUpdate) {
          const dataWithTimestamp = {
            ...data,
            created: timestamp,
            updated: timestamp,
          };
          const newCompanyRef = db
            .collection("programs")
            .doc(getters.programId)
            .collection("companies")
            .doc();
          newCompaniesBatch.set(newCompanyRef, dataWithTimestamp);
        } else {
          const dataWithTimestamp = {
            ...data,
            updated: timestamp,
          };
          const updateCompanyRef = db
            .collection("programs")
            .doc(getters.programId)
            .collection("companies")
            .doc(id);
          newCompaniesBatch.update(updateCompanyRef, dataWithTimestamp);
        }
      });

      try {
        await newCompaniesBatch.commit();
      } catch (e) {
        console.error(e);
        throw `companies import batch failed - ${i + 1}st batch`;
      }
    }
  },

  async updateCompanyMembers({ getters }, companyUpdates) {
    // Note: Firebase is counting serverTimestamp as one write.
    // We need to reduce the write counts because there is Max 500 writes limit.

    // Note: Due to firebase's limit of Max 500 writes per batch
    // We are gonna split companies array into several chunks having 500 max companies
    const companiesChunks = _.chunk(companyUpdates, 240);
    for (let i = 0; i < companiesChunks.length; i += 1) {
      const chunk = companiesChunks[i];
      const companyUpdatesBatch = db.batch();

      chunk.forEach((company) => {
        const { companyId, memberId } = company;
        const updateCompanyRef = db
          .collection("programs")
          .doc(getters.programId)
          .collection("companies")
          .doc(companyId);
        const updateInfo = {
          members: firebase.firestore.FieldValue.arrayUnion(memberId),
          updated: new Date(),
        };
        companyUpdatesBatch.update(updateCompanyRef, updateInfo);
      });

      try {
        await companyUpdatesBatch.commit();
        console.log("Finished updateCompanyMembers");
      } catch (e) {
        console.log("Error updating Company Member in updateCompanyMembers", e);
        throw "companies update batch failed";
      }
    }
  },
};

const mutations = {
  setCompanies(state, payload) {
    state.companies = payload;
  },

  setLoadingTable(state, payload) {
    state.loadingTable = payload;
  },

  setLoadingCompanies(state, payload) {
    state.loadingCompanies = payload;
  },

  createCompany(state, payload) {
    state.companies = [...state.companies, payload];
  },

  updateCompany(state, payload) {
    state.companies = state.companies.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteCompany(state, payload) {
    state.companies = state.companies.filter((item) => item.id !== payload);
  },
};

const getters = {
  companies(state) {
    return state.companies;
  },

  loadingCompanies(state) {
    return state.loadingCompanies;
  },
  companiesMap(state) {
    return state.companies.reduce((result, item) => {
      return {
        ...result,
        [item.id]: item.title,
      };
    }, {});
  },
  companyAccountKeysMap(state) {
    return state.companies.reduce((result, company) => {
      return company.accountKey
        ? {
            ...result,
            [company.accountKey]: {
              id: company.id,
              title: company.title,
              titleUppercase: company.titleUppercase,
              tags: company.tags,
            },
          }
        : result;
    }, {});
  },
  companyAccountKeysMapFiltered(state) {
    const filters = ["P5MhkDog9ULQAs34zJpn"];
    const arr = state.companies.filter((x) =>
      x.tags.some((g) => filters.includes(g))
    );
    return arr.reduce((result, company) => {
      return company.accountKey
        ? {
            ...result,
            [company.accountKey]: {
              id: company.id,
              title: company.title,
              titleUppercase: company.titleUppercase,
              tags: company.tags,
            },
          }
        : result;
    }, {});
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

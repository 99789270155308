/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  messages: [],
};

const actions = {
  async loadMessages({ commit, dispatch }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db.collection("messages").get();
    } catch (e) {
      querySnapshot = [];
    }

    const messages = [];
    querySnapshot.forEach(function (doc) {
      const data = doc.data();
      const { created, updated } = data;
      messages.push({
        id: doc.id,
        message: data.message,
        subject: data.subject,
        from: data.from,
        name: data.name,
        phone: data.phone,
        company: data.company,
        read: data.read,
        plan: data.plan,
        location: data.location,
        signedIn: {
          name: data.signedIn.name || "",
          email: data.signedIn.email || "",
        },
        created: created && created.toDate ? created.toDate() : created,
        updated: updated && updated.toDate ? updated.toDate() : updated,
        createdBy: data.createdBy,
        updatedBy: data.updatedBy,
      });
    });

    commit("setMessages", messages);
    dispatch("loadNewMessageCount");
    commit("setLoadingTable", false);
  },

  async loadNewMessageCount({ commit }) {
    let querySnapshot;
    try {
      querySnapshot = await db.collection("messages").get();
    } catch (e) {
      querySnapshot = [];
    }

    const messages = [];
    querySnapshot.forEach(function (doc) {
      const data = doc.data();
      messages.push({
        read: data.read,
      });
    });
    const count = messages.filter((el) => el.read === false).length;
    commit("setNewMessageCount", count);
  },

  async createMessage({ commit, getters, dispatch }, payload) {
    //console.log(payload)
    const messagesCollection = db.collection("messages");

    const message = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newMessageRef;
    try {
      newMessageRef = await messagesCollection.add(message);
    } catch (e) {
      throw "Error when creating a new message";
    }

    // Note: server time is unavailable until we refetch.
    const tempMessage = {
      ...message,
      id: newMessageRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createMessage", tempMessage);
    commit("setSnackbar", "Message sent");
    dispatch("setLoading", false);

    return "ok";
  },

  async updateMessage({ commit, getters }, payload) {
    const messagesCollection = db.collection("messages");

    const message = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await messagesCollection.doc(payload.id).update(message);
    } catch (e) {
      throw "Error when updating a message";
    }

    const messageBeforeUpdate = getters.messages.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempMessage = {
      ...messageBeforeUpdate,
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    commit("updateMessage", tempMessage);
    commit("setSnackbar", "Message updated");

    return "ok";
  },

  async markRead({ commit, getters, dispatch }, payload) {
    const messagesCollection = db.collection("messages");

    const message = {
      ...payload,
      read: true,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await messagesCollection.doc(payload.id).update(message);
    } catch (e) {
      throw "Error when updating a message";
    }

    const messageBeforeUpdate = getters.messages.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempMessage = {
      ...messageBeforeUpdate,
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    commit("updateMessage", tempMessage);
    dispatch("loadNewMessageCount");
    // dispatch('loadMessages')

    return "ok";
  },

  async deleteMessage({ commit }, id) {
    try {
      await db.collection("messages").doc(id).delete();
    } catch (e) {
      throw "Error when deleting an message";
    }
    commit("deleteMessage", id);
    commit("setSnackbar", "Message deleted");
  },
};

const mutations = {
  setMessages(state, payload) {
    state.messages = payload;
  },

  setNewMessageCount(state, payload) {
    state.newMessageCount = payload;
  },

  createMessage(state, payload) {
    state.messages = [...state.messages, payload];
  },

  updateMessage(state, payload) {
    state.messages = state.messages.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteMessage(state, payload) {
    state.messages = state.messages.filter((item) => item.id !== payload);
  },
};

const getters = {
  messages(state) {
    return state.messages;
  },

  newMessageCount(state) {
    return state.newMessageCount;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

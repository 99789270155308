<template>
  <div>
    <v-btn
      fab
      fixed
      absolute
      bottom
      right
      color="#ff93c1"
      class="live-site-btn"
      elevation="0"
      rounded
      width="150px"
      height="57px"
      @click="toggleLiveSiteDialog"
    >
      <v-icon left size="22">open_in_new</v-icon>
      Live Site
    </v-btn>
    <live-site-dialog
      :openLiveSiteDialog="openLiveSiteDialog"
      @onClose="onClose()"
    />
  </div>
</template>

<script>
import LiveSiteDialog from "./LiveSiteDialog.vue";
export default {
  components: {
    LiveSiteDialog,
  },
  data() {
    return {
      openLiveSiteDialog: false,
    };
  },
  computed: {},
  methods: {
    toggleLiveSiteDialog() {
      if (this.openLiveSiteDialog == false) {
        this.$store.dispatch("loadMembers");
        this.openLiveSiteDialog = true;
      } else {
        this.openLiveSiteDialog = false;
      }
    },
    onClose() {
      this.openLiveSiteDialog = false;
    },
  },
  watch: {},
};
</script>

<style scoped>
.live-site-btn {
  z-index: 200 !important;
  margin-bottom: 36px;
  margin-right: -15px;
  color: #e91e63 !important;
  background-color: #eed9e0 !important;
}
</style>

<template>
  <div>
    <v-col cols="4">
      <v-card
        height="150"
        color="secondary"
        elevation="0"
      >
        <img
          v-if="iconMappings.svgFile"
          :src="'/img/icons/' + iconMappings.svgFile"
          class="card-icon"
        >
        <v-card-text class="pa-6">
          <v-row no-gutters style="height: 105px" align="end">
            <v-col class="white--text">
              <div class="card-title mb-0 mt-n2"> {{ currentPrefix }} </div>
              <div class="card-title"> {{ displayValue }} </div>
              <div v-if='currentSuffix' class="card-title"> {{currentSuffix}} </div>
              <div class="card-title"> {{currentLeaderboard.displayTitle}} </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-select
        v-model="selectedAccountKey"
        placeholder="Select ID"
        no-data-text="No data imported"
        :items="accountsMap"
        item-text="label"
        item-value="value"
        label="Preview Data Of"
        class="mt-6"
      />
      <v-text-field
        v-model="currentPrefix"
        placeholder="Add a Prefix"
        label="Prefix"
      />
      <v-text-field
        v-model="currentSuffix"
        placeholder="Add a Suffix"
        label="Suffix"
      />
    </v-col>
  </div>
</template>

<script>
export default {
  data: function () {
    const accountsMap = this.$store.getters.leaderboardAccountsMap;
    return {
      selectedAccountKey: accountsMap.length ? accountsMap[0].value : '',
    }
  },
  created() {
    this.rankActive = false
  },
  computed: {
    currentLeaderboard() {
      return this.$store.state.leaderboard.currentLeaderboard;
    },

    systemHeaders() {
      return this.$store.getters.leaderboardSystemHeaders;
    },

    csvHeaders() {
      return this.$store.getters.leaderboardCsvHeaders;
    },

    headerData() {
      return this.$store.getters.leaderboardHeaderData;
    },

    accountsMap() {
      return this.$store.getters.leaderboardAccountsMap;
    },

    homeMappings() {
      return this.currentLeaderboard.homeMappings;
    },

    headerMappings() {
      return this.currentLeaderboard.headerMappings;
    },

    iconMappings() {
      return this.currentLeaderboard.iconMappings;
    },

    currentPrefix: {
      get() {
        return this.homeMappings.prefix;
      },

      set(value) {
        const newHomeMappings = {
          ...this.homeMappings,
          prefix: value,
        };

        this.$store.dispatch('patchCurrentLeaderboard', {
          homeMappings: newHomeMappings,
        });
      }
    },

    currentSuffix: {
      get() {
        return this.homeMappings.suffix;
      },

      set(value) {
        const newHomeMappings = {
          ...this.homeMappings,
          suffix: value,
        };

        this.$store.dispatch('patchCurrentLeaderboard', {
          homeMappings: newHomeMappings,
        });
      },
    },

    selectedPreviewData() {
      return this.headerData.find(item => {
        return item.accountKey === this.selectedAccountKey
      });
    },

    displayValue() {
      const rankHeader = this.headerMappings.find(header => header.column === 'Rank');
      if (!rankHeader) {
        return '*Please Configure A Header Rank'
      }

      const ranking = this.selectedPreviewData.values[rankHeader.uid];

      let suffix;
      if (ranking === 1) {
        suffix = 'st';
      } else if (ranking === 2) {
        suffix = 'nd';
      } else if (ranking === 3) {
        suffix = 'rd'
      } else {
        suffix = 'th';
      }

      return `${ranking}${suffix}`;
    },

    previewCardBodyClass() {
      if (this.currentPrefix && !this.currentSuffix) {
        return 'card-top-prefix';
      } else if (!this.currentPrefix && this.currentSuffix) {
        return 'card-top-suffix';
      } else if (this.currentPrefix && this.currentSuffix) {
        return 'card-top-both';
      }
      return 'card-top-none';
    }
  },
}
</script>

<style scoped>
.card-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100px;
  opacity: 0.2;
}
</style>
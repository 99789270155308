<template>
  <v-row>
    <v-col lg="9">
      <v-row class="page-heading">   
        {{ pageTitle }}
      </v-row>
    <v-row class="incentable-page-subheading pb-4">{{ pageSubTitle }}</v-row>
    </v-col>
    <v-col lg="3">
      <v-row align="end" style="height: 50px;" class="pr-1">
        <v-text-field
          v-if="searchActive"
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
        ></v-text-field> 
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    search: {
      type: String,
      required: true,
    },
    pageSubTitle: {
      type: String,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
    },
    searchActive: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

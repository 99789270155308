<template>
  <div class="mt-8 mr-8 mb-8">
    <v-card 
      class="grey lighten-4 rounded-t-lg rounded-b-0"
      elevation="8"
      >
      <v-card-title>
        <div class="table-title"><v-icon class="pr-1">star</v-icon>Incentable Settings</div>
      </v-card-title>
      <v-card-subtitle>
        <div class="table-subtitle">Only visible to Incentable admins. Internal use only</div>
      </v-card-subtitle> 
    </v-card>
        
    <v-card 
      class="rounded-t-0 rounded-b-lg"
      elevation="8"
      >

      <v-card-text class="px-16">

        <v-row no-gutters class="incentable-form-heading">  
          Subdomain Settings            
        </v-row>
        <v-row no-gutters class="incentable-form-subheading">
          Set your company primary color
        </v-row>

        <v-row dense>
          <v-col lg="4">
            <v-checkbox
              label="Sudomain Connected"
              v-model="currentOrganisation.subdomainConnected"
            ></v-checkbox>
          </v-col>
          <v-col lg="4">
            <v-text-field
              label="Subdomain"
              v-model="currentOrganisation.orgSubdomain"
              suffix=".incentive.studio"
            />
          </v-col>
          <v-row dense v-if="errorMessage">
          <v-alert
            type="error"
            :value="true"
            dismissible
            >
              {{errorMessage}}
            </v-alert>
          </v-row>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          @click="handleSave"
          :loading="loading"
          color="primary"
          >Save</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  created () {
   this.$store.dispatch('initialize')

   this.subdomainConnected = this.currentOrganisation.subdomainConnected;
   this.orgSubdomain = this.currentOrganisation.orgSubdomain;
   this.title = this.currentOrganisation.title;
   this.titleUppercase = this.currentOrganisation.titleUppercase;

  },
  data: () => ({
    subdomainConnected: false,
    orgSubdomain: '',
    title: '',
    titleUppercase: '',
    isProcessing: false,
    errorMessage: '',
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    organisationId () {
      return this.$store.getters.organisationId
    },
    currentOrganisation () {
      return this.$store.getters.currentOrganisation
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
  },
  methods: {
    handleSave() {
      this.errorMessage = false
      const payload = {
        id: this.organisationId,
        orgSubdomain: this.currentOrganisation.orgSubdomain,
        subdomainConnected: this.currentOrganisation.subdomainConnected,
      };
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('updateIncentableSettings', payload)
        .then(() => {
          this.$store.dispatch('setLoading', false)
        })
        .catch((e) => {
          this.errorMessage = e;
          this.$store.dispatch('setLoading', false)
        })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <v-dialog
      persistent
      v-model="uploadsDialog"
      max-width="700px"
      transition="dialog-transition"
      z-index="3000"
    >
      <v-card>
        <v-row no-gutters justify="space-between">
          <v-col class="pt-4">
            <span class="incentable-card-title ml-5">Media Uploads</span>
          </v-col>
          <v-col cols="auto">
            <v-btn
              :disabled="loading"
              elevation="0"
              fab
              small
              color="transparent"
              @click="handleUploadsDialog(false)"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-3 pb-3">
          <v-col cols="4">
            <media-upload
              class="ma-2"
              :showMetaData="true"
              :isUploads="true"
              :externalImageUrl="externalImageUrl"
              :currentImageOnFile="currentImageOnFile"
              @onImageSaved="handleImageSaved"
              @onImageProcessing="handleImageProcessing"
            />
          </v-col>
          <v-col
            cols="4"
            class="d-flex child-flex"
            v-for="(image, key) in uploads"
            :key="key"
          >
            <v-img
              :src="image.mediumUrl"
              aspect-ratio="1"
              class="ma-2"
              @mouseover="hover = key"
              @mouseleave="hover = null"
            >
              <v-overlay v-if="hover === key" absolute opacity="0.2">
                <v-row no-gutters justify="center">
                  <v-btn
                    small
                    icon
                    @click="openImageView(image.largeUrl)"
                    class="primary"
                  >
                    <v-icon>visibility</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    @click="handleDelete(image)"
                    class="primary"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-row>
                <v-row no-gutters class="mt-3">
                  <v-btn
                    :disabled="demo"
                    small
                    @click="handleApplyImage(saveTo, image)"
                    class="primary"
                    elevation="0"
                  >
                    Apply this image
                  </v-btn>
                </v-row>
              </v-overlay>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-skeleton-loader
                    min-height="100px"
                    min-width="100%"
                    type="card"
                  >
                  </v-skeleton-loader>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>

        <v-dialog
          v-model="dialogImageView"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-img :src="viewImage">
              <template v-slot:placeholder>
                <v-row
                  no-gutters
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                </v-row>
              </template>
              <v-btn
                :disabled="demo"
                @click="handleApplyImage(saveTo, image)"
                class="primary ma-4"
                elevation="0"
              >
                apply this image
              </v-btn>
              <v-btn
                icon
                class="primary float-right ma-4"
                @click="closeImageView()"
              >
                <v-icon :color="orgTheme.buttonIconColor">clear</v-icon>
              </v-btn>
            </v-img>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { IMAGE_DEFAULTS } from "@/constants/program";
import MediaUpload from "../../../../Shared/MediaUpload.vue";

export default {
  props: ["saveTo"],
  components: {
    MediaUpload,
  },
  data: () => ({
    dialogImageView: false,
    viewImage: null,
    hover: null,
    selectedImageId: "",
    // new
    name: "",
    updated: "",
    smallUrl: "",
    mediumUrl: "",
    largeUrl: "",
    smallStoragePath: "",
    mediumStoragePath: "",
    largeStoragePath: "",
    currentImageOnFile: {},
    externalImageUrl: "",
  }),
  created() {
    this.$store.dispatch("setUploadsDialog", false);
    this.$store.dispatch("loadUploads");
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loading() {
      return this.$store.state.loading;
    },
    hasWebThemePendingUpdates() {
      return this.$store.getters.hasWebThemePendingUpdates;
    },
    editingComponent() {
      return this.$store.getters.editingComponent;
    },
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
    programId() {
      return this.$store.getters.programId;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    uploads() {
      return this.$store.state.uploads.uploads;
    },
    uploadsDialog() {
      return this.$store.state.uploads.uploadsDialog;
    },
  },
  methods: {
    handleImageSaved(image) {
      // new uploader
      this.$store.dispatch("createImageUpload", image);
    },
    handleImageProcessing(val) {
      // new uploader
      this.$store.dispatch("setLoading", val);
    },
    handleChange() {
      this.$store.dispatch("setHasWebThemePendingUpdates", true);
    },
    handleUploadsDialog(val) {
      // new uploader
      this.$store.dispatch("setUploadsDialog", val);
    },
    openImageView(image) {
      this.image = image;
      this.dialogImageView = true;
      this.viewImage = image.largeUrl;
    },
    closeImageView() {
      this.dialogImageView = false;
      this.viewImage = null;
    },
    handleApplyImage(saveTo, image) {
      console.log(saveTo, image);
      if (saveTo === "bannerLogo") {
        this.editingComponent.bannerLogo.image = image.largeUrl;
        this.editingComponent.bannerLogo.previewImage = image.smallUrl;
      } else if (saveTo === "background") {
        this.editingComponent.background.image = image.largeUrl;
        this.editingComponent.background.previewImage = image.smallUrl;
      } else if (saveTo === "logoColOne") {
        this.editingComponent.logoColOne.image = image.largeUrl;
        this.editingComponent.logoColOne.previewImage = image.smallUrl;
      } else if (saveTo === "logoColTwo") {
        this.editingComponent.logoColTwo.image = image.largeUrl;
        this.editingComponent.logoColTwo.previewImage = image.smallUrl;
      } else if (saveTo === "logoColThree") {
        this.editingComponent.logoColThree.image = image.largeUrl;
        this.editingComponent.logoColThree.previewImage = image.smallUrl;
      } else if (saveTo === "logo") {
        this.editingComponent.logo.image = image.largeUrl;
        this.editingComponent.logo.previewImage = image.smallUrl;
      } else if (saveTo === "mobileLogo") {
        this.editingComponent.mobileLogo.image = image.largeUrl;
        this.editingComponent.mobileLogo.previewImage = image.smallUrl;
      } else if (saveTo === "tier") {
        // console.log(saveTo, image)
        this.$emit("onImageSelected", image);
        this.handleUploadsDialog(false);
      } else if (saveTo === "award") {
        // console.log(saveTo, image)
        this.$emit("onImageSelected", image);
        this.handleUploadsDialog(false);
      }
      this.dialogImageView = false;
      this.handleChange();
    },
    handleNew() {
      // Open New form
      this.selectedImageId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedImageId = "";
      this.dialogNewOrEditOpen = false;
    },
    handleDelete(payload) {
      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("deleteMediaFilesFromStorage", payload)
        .then(() => {
          // Check if delected image was in use and replace with default
          if (payload.largeUrl === this.editingComponent.settings.image) {
            this.editingComponent.settings.image = IMAGE_DEFAULTS.image;
            this.editingComponent.settings.previewImage =
              IMAGE_DEFAULTS.previewImage;
            this.handleChange();
          } else {
            return;
          }
          // console.log('file removed from storage')
        })
        .catch((error) => {
          console.log("there was an error: ", error);
        });
      // Delete image record from database
      this.$store.dispatch("deleteImageUpload", payload);
    },
  },
  watch: {},
};
</script>

<style></style>

/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
import { DEFAULT_BANNER_URL, REPLY_TO_EMAIL } from "@/constants/email";

const state = {
  notifications: [],
  dialogNotificationPreview: false, // can be false, 'email', 'notification', 'list'
  dialogNotificationRecipients: false, // can be false, 'email', 'notification', 'list'
};

const actions = {
  async loadNotifications({ commit, getters }) {
    commit("setLoadingTable", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("notifications")
        .orderBy("created", "desc")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const notifications = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      notifications.push({
        id: doc.id,
        imageUrl: data.imageUrl,
        type: data.type,
        status: data.status,
        subject: data.subject,
        heading: data.heading,
        subheading: data.subheading,
        body: data.body,
        memberStatus: data.memberStatus,
        memberTags: data.memberTags,
        companyTags: data.companyTags,
        sender: data.sender,
        replyEmail: data.replyEmail,
        memberIds: data.memberIds,
        memberEmails: data.memberEmails,
        created: data.created.toDate(),
        updated: data.updated.toDate(),
      });
    });

    commit("setNotifications", notifications);
    commit("setLoadingTable", false);
  },

  async createNotification({ commit, getters }, payload) {
    commit("setLoading", true);

    const allCompanies = getters.companies;

    const allMembersByStatus = payload.memberStatus.length
      ? getters.members.filter((member) => {
          return payload.memberStatus.includes(member.status);
        })
      : getters.members;

    const memberIdsFromCompanyTags = allCompanies
      .filter((company) => {
        return !!company.tags.find((tag) => payload.companyTags.includes(tag));
      })
      .reduce((result, company) => {
        return result.concat(company.members || []);
      }, []);

    const { targetMemberIds, targetMemberEmails } = allMembersByStatus
      .filter((member) => {
        const memTagsCondition = !!(
          payload.memberTags.length < 1 ||
          (member.tags &&
            member.tags.findIndex((tag) => payload.memberTags.includes(tag)) >
              -1)
        );

        const compTagsCondition = !!(
          payload.companyTags.length < 1 ||
          memberIdsFromCompanyTags.includes(member.id)
        );

        return memTagsCondition && compTagsCondition;
      })
      .reduce(
        (result, member) => {
          return {
            targetMemberIds: [...result.targetMemberIds, member.id],
            targetMemberEmails: [...result.targetMemberEmails, member.email],
          };
        },
        {
          targetMemberIds: [],
          targetMemberEmails: [],
        }
      );

    if (!targetMemberIds.length) {
      commit("setLoading", false);
      commit("setSnackbar", {
        status: true,
        text: "No recipients found. Nothing sent",
      });
      throw "no recipients";
    }

    const currentProgram = getters.currentProgram;
    const { bannerUrl } = currentProgram.notificationSettings || {};

    const notification = {
      ...payload,
      memberIds: targetMemberIds,
      memberEmails: targetMemberEmails,
      sender: currentProgram.title,
      replyEmail: getters.replyEmail || REPLY_TO_EMAIL,
      imageUrl: payload.imageUrl || bannerUrl || DEFAULT_BANNER_URL,
      created: timestamp,
      updated: timestamp,
    };

    let newNotificationRef;
    try {
      newNotificationRef = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("notifications")
        .add(notification);
    } catch (e) {
      commit("setLoading", false);
      throw "sending failed";
    }

    // server timestamp is not available until we reload
    // use temporary frontend javascript date
    commit("createNotification", {
      ...notification,
      id: newNotificationRef.id,
      created: timestamp,
      updated: timestamp,
    });
    commit("setLoading", false);
    commit("setSnackbar", {
      status: true,
      text:
        notification.type === "email"
          ? "Success. Email sent"
          : "Success. Notification sent",
    });
  },

  setDialogNotificationPreview({ commit }, payload) {
    commit("setDialogNotificationPreview", payload);
  },

  setDialogNotificationRecipients({ commit }, payload) {
    commit("setDialogNotificationRecipients", payload);
  },
};

const mutations = {
  setNotifications(state, payload) {
    state.notifications = payload;
  },

  createNotification(state, payload) {
    state.notifications = [...state.notifications, payload];
  },

  setDialogNotificationPreview(state, payload) {
    state.dialogNotificationPreview = payload;
  },

  setDialogNotificationRecipients(state, payload) {
    state.dialogNotificationRecipients = payload;
  },
};

const getters = {
  notifications(state) {
    return state.notifications;
  },

  dialogNotificationPreview(state) {
    return state.dialogNotificationPreview;
  },

  dialogNotificationRecipients(state) {
    return state.dialogNotificationRecipients;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

export const HEADING_DEFAULTS = {
  settings: {
    heightContext: 'px',
    mode: 'color',
    cols: 12,
    color: { 
      alpha: 0, 
      hex: '#000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
  },
  intro: {
    align: 'center',
    boxHeight: 10,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'intro',
    floatText: 'text-center',
    font: 'Work Sans, sans-serif',
    justify: 'center',
    lineHeight: 1,
    size: 12,
    style: 'normal',
    value: '',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  pretitle: {
    align: 'center',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'pretitle',
    floatText: 'text-center',
    font: 'Work Sans, sans-serif',
    justify: 'center',
    lineHeight: 1.2,
    size: 22,
    style: 'normal',
    value: '',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  title: {
    align: 'center',
    boxHeight: 10,
    color: '#FFFFFFFF',
    cols: 12,
    field: 'title',
    floatText: 'text-center',
    font: 'Roboto, sans-serif',
    justify: 'center',
    lineHeight: 1.3,
    size: 24,
    style: 'normal',
    value: 'Heading',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  subtitle: {
    align: 'end',
    boxHeight: 8,
    color: '#FFFFFFFF',
    cols: 6,
    field: 'subtitle',
    floatText: 'text-center',
    font: 'Work Sans, sans-serif',
    justify: 'center',
    size: 22,
    lineHeight: 1.1,
    style: 'normal',
    value: '',
    weight: 'normal',
    marginLeft: 0,
    marginRight: 0,
  },
  screen: {
    height: 85,
    type: 'screen',
    direction: 'to top',
    color: { 
      alpha: 0, 
      hex: '#00000000' ,
      hexa: '#00000000',
      hsla: {
        a: 0,
        h: 0,
        l: 0,
        s: 0,
      },
      hsva: {
        a: 0,
        h: 0,
        s: 0,
        v: 0,
        hue: 0,
      },
      rgba: {
        a: 0,
        b: 0,
        g: 0,
        r: 0
      } 
    },
    fromColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
    toColor: { 
      alpha: 0, 
      hex: '#000000', 
      hexa: '#00000000', 
      hsla: { h: 0, s: 0, l: 0, a: 0 }, 
      hsva: { h: 0, s: 0, v: 0, a: 0 }, 
      hue: 0, 
      rgba: { r: 0, g: 0, b: 0, a: 0 }  
    },
  },
  heightValue: {
    px: 250,
    vh: 25,
  },
  overlap: {
    px: 0,
    vh: 0,
  },
  block: {
    cols: 6,
    align: 'center',
    justify: 'center',
    padding: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 20,
    }
  },
  bannerLogo: {
    align: 'center',
    placement: 'top',
    justify: 'center',
    height: 50,
    boxHeight: 0,
    image: '',
    previewImage: ''
  },
  background: {
    image: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_2560x2560.png?alt=media&token=62cd0b3e-a963-42ef-a633-cf9a063f99f1',
    previewImage: 'https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/gallery%2FGTY6yKMZdJCuj0oTgr5Ep_starburst%20(1)_600x600.png?alt=media&token=3a2aa0cd-b5fe-45a5-9466-ec39af90630b'
  }
};

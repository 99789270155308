<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="databucket">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
            absolute
            color="primary"
            :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Add New
            {{ formTitle }}
          </v-card-title>
        </v-row>
        <v-card-text class="px-16">
          <v-text-field
            v-model="title"
            label="Databucket Name"
            v-validate="'required'"
            :error-messages="errors.collect('databucket.title')"
            name="title"
            type="text"
          />
          <v-radio-group
            v-model="participantType"
            label="Data Type"
            v-validate="'required'"
            :error-messages="errors.collect('databucket.participantType')"
            name="participantType"
          >
            <v-radio class="mt-4" label="Member" value="member" />
            <v-radio label="Company" value="company" />
          </v-radio-group>

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert
                color="primary"
                border="left"
                text
                type="error"
                :value="true"
              >
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Cancel </v-btn>
          <v-btn
            @click="handleSave('databucket')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            elevation="0"
            :loading="loading"
            :disabled="demo"
            >Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      dictionary: {
        custom: {
          title: {
            required: () => "Databucket Name is required",
          },
          participantType: {
            required: () => "Participant Type is required",
          },
        },
      },
      formTitle: "Data Bucket",
      formIcon: "fa-solid fa-database",
      title: "",
      participantType: null,
      pointsRedeemable: false,
      hasTargets: true,
      errorMessage: "",
    };
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },
  methods: {
    reset() {
      this.title = "";
      this.participantType = null;
      this.pointsRedeemable = false;
      this.hasTargets = true;
      this.$validator.reset();
    },

    handleClose() {
      this.reset();
      this.$emit("onClose");
    },

    handleSave(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        if (!validationResult) {
          return;
        }

        this.$store.dispatch("setLoading", true);

        const payload = {
          status: "Active",
          actualMapping: {},
          title: this.title,
          titleUppercase: this.title.toUpperCase(),
          displayTitle: this.title,
          displayTitleUppercase: this.title.toUpperCase(),
          hasTargets: this.hasTargets,
          pointsRedeemable: this.pointsRedeemable,
          participantType: this.participantType,
          // previewColumns: [],
          targetCodes: [],
          targetMappring: {},
          quizzes: [],
          offers: [],
          pointsFormula: [],
        };

        this.$store
          .dispatch("createDatabucket", payload)
          .then(() => {
            this.$store.dispatch("setLoading", false);
            this.handleClose();
          })
          .catch((msg) => {
            this.$store.dispatch("setLoading", false);
            this.errorMessage = msg;
          });
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <v-row no-gutters align="center">
      <v-breadcrumbs :items="breadCrumbs">
        <template v-slot:item="{ item, index }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            exact
            link
            class="bc"
          >
            <span> {{ item.text }} </span>
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon color="#858585">chevron_right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    breadCrumbs() {
      var arr = this.$route.meta.breadCrumb || [];
      if (this.breadCrumbDetail) {
        arr = arr.concat({
          text: this.breadCrumbDetail,
        });
      }
      return arr;
    },
    breadCrumbDetail() {
      return this.$store.state.breadCrumbDetail;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    navTitle() {
      return this.$store.state.navTitle;
    },
    programId() {
      return this.$store.state.program.programId;
    },
  },
};
</script>

<style scoped>
.bc {
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  color: #858585 !important;
  letter-spacing: 1px;
  margin-top: -2px !important;
}
</style>

<template>
  <v-dialog v-model="open" max-width="1200px" persistent>
    <v-card>
      <v-card-text class="pt-5">
        <v-row
          no-gutters
          class="incentable-card-title mb-5"
          justify="space-between"
        >
          <v-col>
            <v-row no-gutters class="page-heading">
              Points Transactions For {{ firstname }} {{ lastname }}
            </v-row>
          </v-col>
          <v-col cols="auto"> </v-col>
          <v-col cols="auto">
            <v-row no-gutters class="primary--text">
              {{ memberPoints.balance | number }} points
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" class="mb-3">
          <v-col sm="3">
            <v-text-field
              v-model="search"
              solo-inverted
              dense
              flat
              hide-details
              label="Search"
              prepend-inner-icon="search"
              class="mr-4"
            ></v-text-field>
          </v-col>

          <v-col sm="3" v-if="options.filter">
            <v-select
              solo-inverted
              dense
              flat
              hide-details
              label="Filter"
              prepend-inner-icon="filter_list"
              class="mr-4"
              multiple
              :items="typeList"
              v-model="filteredType"
            ></v-select>
          </v-col>

          <v-col>
            <v-btn
              @click="handleOpenImportDialog()"
              v-if="options.upload"
              class="primary--text"
              text
              :disabled="demo"
              >Upload</v-btn
            >
            <v-btn
              v-if="options.download"
              class="primary--text"
              text
              :disabled="demo"
              >Download</v-btn
            >
            <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
          </v-col>

          <v-col sm="auto">
            <v-btn
              v-if="options.new"
              @click="handlePoints('subtract')"
              color="primary"
              elevation="0"
              class="mr-2"
            >
              <v-icon left>remove</v-icon>subtract points
            </v-btn>
            <v-btn
              v-if="options.new"
              @click="handlePoints('add')"
              color="primary"
              elevation="0"
              class="mr-n1"
            >
              <v-icon left>add</v-icon>add points
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="filteredTransactions"
          :search="search"
          :options.sync="options"
          :loading="loadingPointsTable"
          no-data-text="No Transactions..."
        >
          <template v-slot:[`item.type`]="{ item }">
            <status-icon :status="item.type" class="pr-3"></status-icon>
            {{ item.type }}
          </template>
          <template v-slot:[`item.created`]="{ item }">
            {{ item.created | convertTimestamp }}
          </template>
          <template v-slot:[`item.points`]="{ item }">
            {{ item.points | number }}
          </template>
          <template v-slot:[`item.notes`]="{ item }">
            {{ item.notes | truncate(45) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="item.type === 'Adjust' || item.type === 'Award'"
              color="red"
              @click="handleDeleteConfirmation(item)"
              >delete</v-icon
            >
          </template>
        </v-data-table>

        <transaction-new-or-edit
          :transactionId="selectedTransactionId"
          :open="dialogNewOrEditOpen"
          :memberId="memberId"
          :action="pointsAction"
          :firstname="firstname"
          :lastname="lastname"
          @onClose="handleCloseNewOrEditDialog"
        />

        <transaction-delete
          :transactionId="selectedTransactionId"
          :open="dialogDeleteOpen"
          :memberId="memberId"
          :firstname="firstname"
          :lastname="lastname"
          @onClose="handleCloseDeleteDialog"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="primary" @click="handleClose()"
          >close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { TRANSACTION_TYPE } from "@/constants/transaction";
import TransactionNewOrEdit from "./TransactionNewOrEdit.vue";
import TransactionDelete from "./TransactionDelete.vue";

export default {
  created() {
    this.$store.dispatch("setProcessingPoints", false);
  },
  props: ["open", "memberId"],
  components: {
    TransactionNewOrEdit,
    TransactionDelete,
  },

  data: () => ({
    // Table columns
    headers: [
      { text: "Type", value: "type", align: "left" },
      { text: "Date", value: "created", align: "left" },
      { text: "Description", value: "description", align: "left" },
      { text: "Notes", value: "notes", align: "left" },
      { text: "Points", value: "points", align: "right", sortable: false },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    closeDialog: false,
    typeList: TRANSACTION_TYPE,
    filteredType: [],
    search: "",
    selectedTransactionId: "",
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,
    notes: "",
    description: "",
    points: "",
    pointsAction: "",
    firstname: "",
    lastname: "",
    balance: "",
    awarded: "",
    redeemed: "",

    // Table settings
    options: {
      sortBy: ["created"],
      sortDesc: [true],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: false,
      download: false,
      more: false,
      new: true,
    },
  }),

  computed: {
    transactions() {
      return this.$store.state.transaction.transactions;
    },
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loadingPointsTable() {
      return this.$store.getters.loadingPointsTable;
    },
    processingPoints() {
      return this.$store.getters.processingPoints;
    },
    filteredTransactions() {
      return this.filteredType.length
        ? this.transactions.filter((m) => this.filteredType.includes(m.type))
        : this.transactions;
    },
    arrBalance() {
      const arr = this.transactions;
      return arr.reduce((el, { points }) => el + points, 0);
    },
    variance() {
      const transBalance = this.transactions.reduce(
        (n, { points }) => n + points,
        0
      );
      return this.memberPoints - transBalance;
    },
    members() {
      return this.$store.state.member.members;
    },
    memberPoints() {
      var member = this.members.find((el) => el.id === this.memberId);
      if (member) {
        return {
          balance: member.balance ? member.balance : "",
          awarded: member.awarded ? member.awarded : "",
          redeemed: member.redeemed ? member.redeemed : "",
        };
      }
      return {
        balance: "",
        awarded: "",
        redeemed: "",
      };
    },
  },

  watch: {
    memberId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedMember = this.members.find(
            (item) => item.id === newVal
          );
          const { balance, awarded, redeemed, firstname, lastname } =
            selectedMember;
          this.firstname = firstname;
          this.lastname = lastname;
          this.awarded = awarded;
          this.redeemed = redeemed;
          this.balance = balance;
        }
      },
    },
  },
  methods: {
    handleClose() {
      this.$store.commit("setTransactions", []);
      this.$emit("onClose");
    },
    handlePoints(action) {
      // Open New form
      this.pointsAction = action;
      this.selectedTransactionId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedTransactionId = item.id;
      this.dialogNewOrEditOpen = true;
      this.pointsAction = "";
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedTransactionId = "";
      this.dialogNewOrEditOpen = false;
      this.pointsAction = "";
    },
    handleDeleteConfirmation(item) {
      // Delete record
      this.selectedTransactionId = item.id;
      this.points = item.points;
      this.description = item.description;
      this.notes = item.notes;
      this.dialogDeleteOpen = true;
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedTransactionId = "";
      this.dialogDeleteOpen = false;
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return new Date(timestamp.seconds * 1000).toLocaleDateString(
          ["en-US"],
          {
            month: "short",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }
        );
      }
      return "";
    },
  },
};
</script>

<style></style>

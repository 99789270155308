<template>
  <div>
    <form-builder></form-builder>
  </div>
</template>

<script>
import FormBuilder from "./FormBuilder.vue";
export default {
  components: { FormBuilder },
  data: function () {
    return {
      isProcessing: false,
    };
  },
  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  methods: {},
};
</script>

<style scoped></style>

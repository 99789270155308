<template>
  <div class="px-2">
    <v-col cols="4">
      <span class="incentable-form-heading">Leaderboard Icon</span>
      <v-hover>
        <v-card
          class="pt-3 mb-6"
          width="100px"
          height="100px"
          flat
          ripple
          hover
          elevation="0"
          v-on:click="handleOpenIconSelector"
          slot-scope="{ hover }"
        >
          <v-fade-transition>
            <v-overlay v-if="hover" opacity="0.3" absolute>
              <v-btn icon class="primary" fab elevation="0" large>
                <v-icon>find_replace</v-icon>
              </v-btn>
            </v-overlay>
          </v-fade-transition>
          <div>
            <v-row class="pt-3" justify="center">
              <img
                :src="'/img/icons/black/' + iconMappings.svgFile"
                width="75px"
              />
            </v-row>
          </div>
          <v-row no-gutters justify="center" class="white--text mt-9">
            Choose Icon
          </v-row>
        </v-card>
      </v-hover>

      <v-text-field
        :value="rankMappings.winRows"
        type="number"
        label="Winning Places"
        append-icon="emoji_events"
        @input="handleChangeWinRows"
      />

      <v-select
        :value="rankMappings.sortColumn"
        label="Rank Leaderboard By"
        :items="csvHeaders"
        item-text="label"
        item-value="value"
        append-icon="format_list_numbered"
        @input="handleChangeSortColumn"
      />

      <v-select
        :value="rankMappings.sortDir"
        label="Rank Direction"
        :items="sortDirs"
        item-text="label"
        item-value="value"
        append-icon="swap_vert"
        @input="handleChangeSortDir"
      />
      <v-text-field
        v-model="rankMappings.maxRows"
        label="Limit Rows Displayed"
        type="number"
        persistent-hint
        append-icon="rule"
        hint="Zero (0) to show all rows"
        @input="handleChangeMaxRows"
      />
    </v-col>

    <icon-selector
      :open="openIconSelector"
      @onClose="handleCloseIconSelector"
      @onSave="handleSaveIconSelector"
    />
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isProcessing: false,
      sortDirs: [
        { label: "Ascending", value: "asc" },
        { label: "Descending", value: "desc" },
      ],
      openIconSelector: false,
    };
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    rankMappings() {
      return this.currentLeaderboard.rankMappings;
    },
    csvHeaders() {
      return this.$store.getters.leaderboardCsvHeaders;
    },
    programId() {
      return this.$store.getters.programId;
    },
    currentLeaderboard() {
      return this.$store.state.leaderboard.currentLeaderboard;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    iconMappings() {
      return this.currentLeaderboard.iconMappings;
    },

    sourceColumns() {
      return [
        { header: "System Fields" },
        ...this.systemHeaders,
        { header: "Data Bucket Fields" },
        ...this.csvHeaders,
      ];
    },
    title: {
      get() {
        return this.currentLeaderboard.title;
      },
      set(value) {
        this.$store.dispatch("patchCurrentLeaderboard", { title: value });
      },
    },
    order: {
      get() {
        return this.currentLeaderboard.order;
      },
      set(value) {
        this.$store.dispatch("patchCurrentLeaderboard", {
          order: Number(value),
        });
      },
    },
  },
  methods: {
    handleChangeWinRows(val) {
      this.handleChangeRankMappings("winRows", parseFloat(val));
    },

    handleChangeMaxRows(val) {
      this.handleChangeRankMappings("maxRows", parseFloat(val));
    },

    handleChangeSortColumn(val) {
      this.handleChangeRankMappings("sortColumn", val);
    },

    handleChangeSortDir(val) {
      this.handleChangeRankMappings("sortDir", val);
    },
    handleChangeRankMappings(key, value) {
      const newRankMappings = {
        ...this.rankMappings,
        [key]: value,
      };

      this.$store.dispatch("patchCurrentLeaderboard", {
        rankMappings: newRankMappings,
      });
    },
    handleOpenIconSelector() {
      this.openIconSelector = true;
    },

    handleCloseIconSelector() {
      this.openIconSelector = false;
    },

    handleSaveIconSelector(icon) {
      this.openIconSelector = false;
      this.$store.dispatch("patchCurrentLeaderboard", {
        iconMappings: icon,
      });
    },
  },
};
</script>

<style></style>

<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="transaction">
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon v-if="transactionId" size="80" color="primary">{{ 'pending' }}</v-icon>
        <v-icon v-else size="80" color="primary">{{ action === 'add' ? 'add_circle' : 'remove_circle' }}</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading capitalize">
          {{ transactionId ? 'Edit Transaction' : action + ' Points' }}
        </v-card-title>
      </v-row>
      <v-row justify="center" no-gutters>
          {{ action === 'add' ? 'to' : 'from' }} {{ firstname }} {{ lastname }}
      </v-row>

      <v-card-text class="px-16">
        <v-row no-gutters dense>
          <v-text-field
            name="points"
            v-model="points"
            label="Points"
            v-validate="'required|integer|min_value:1'"
            :error-messages="errors.collect('transaction.points')"
          ></v-text-field>   
        </v-row>
        <v-row no-gutters dense>
          <v-text-field
            name="notes"
            v-model="notes"
            label="Notes"
            v-validate="'required'"
            :error-messages="errors.collect('transaction.notes')"
          ></v-text-field>   
        </v-row>
      
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="handleClose()"
          >Cancel</v-btn>
        <v-btn
          :class="orgTheme.buttonTextColor"
          @click="saveTransaction('transaction')"
          color="primary"
          :loading="loading"
          elevation="0"
          :disabled="demo"
          >Save</v-btn>
      </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  props: [ 'memberId', 'firstname', 'transactionId', 'open', 'action', 'lastname' ],
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data: () => ({
    dictionary: {
      custom: {
        points: {
          required: () => 'Points are required',
          integer: () => 'Points must be a whole number that is positive (greater than 0)',
          min_value: () => 'The is no need to enter `-` as we automatically convert the value to a negative amount. Please enter a postive value.'
        },
        notes: {
          required: () => 'Notes are required'
        }
      }
    },
    formIcon: 'stars',
    showAlert: false,
    points: '',
    type: 'Adjust',
    description: 'Admin',
    notes: '',
    created: '',
    updated: '',
    createdBy: '',
    updatedBy: '',
  }),
  computed: {
    formTitle() {
      return this.action + ' Points'
    },
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    transactions () {
      return this.$store.state.transaction.transactions
    },
    blogs () {
      return this.$store.state.blog.blogs
    },
    companyTags () {
      return this.$store.state.companytag.companyTags
    },
    memberTags () {
      return this.$store.state.membertag.memberTags
    },
  },
  methods:{
    clear () {
      this.points = ''
      this.type = 'Adjust'
      this.description = 'Admin'
      this.notes = ''
      this.created = ''
      this.updated = ''
      this.createdBy = ''
      this.updatedBy = ''
      this.showAlert = false;
      this.$validator.reset();
    },
    handleClose () {
      this.clear();
      this.$emit('onClose');
    },
    saveTransaction (scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        this.$store.dispatch('setLoading', true)
        const action = this.action === 'add' ? 'addMemberPoints' : 'subtractMemberPoints'
        
        const payload = {
          points: parseInt(this.points, 0),
          type: this.type,
          description: this.description,
          notes: this.notes,
          memberId: this.memberId
        }

        this.$store.dispatch(action, payload)
          .then((createResult) => {
            this.$store.dispatch('setLoading', false);
            if (createResult === 'ok') {
              this.handleClose();
            } else if (createResult === 'duplication') {
              this.showAlert = true;
            }
          })
          .catch(() => {
            this.$store.dispatch('setLoading', false);
          })
      })
    },
  },
  watch: {
    transactionId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedTransaction = this.transactions.find(item => item.id === newVal);
          const {
            title, created, updated, createdBy, updatedBy, points, type, description, notes
          } = selectedTransaction;
          this.title = title;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
          this.points = points;
          this.type = type;
          this.description = description;
          this.notes = notes;
        }
      },
    },
  },
}
</script>
/* eslint-disable no-console */
<template>
  <div class="mt-8 mr-8 mb-8">
    <v-row
      no-gutters
      justify="space-between"
      align="center"
      cols="12"
      >
      <v-col sm="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field> 
      </v-col>
      
      <v-col
        sm="3"
        v-if="options.filter"
        >
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter"
          prepend-inner-icon="filter_list"
          class="mr-4"
        ></v-select> 
      </v-col>
      
      <v-col>
        <v-btn
          @click="handleOpenImportDialog()"
          v-if="options.upload"
          class="primary--text"
          text
          >Upload</v-btn>
        <v-btn 
          v-if="options.download"
          class="primary--text"
          text
          :href="companiesCsv"
          :download="getFilename()"
          >Download</v-btn> 
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
      </v-col>
      
      <v-col sm="auto">
        <v-btn
          @click="handleNew()"
          color="primary"
          elevation="0"
          >
          <v-icon>add</v-icon>new
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="adminTableData"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          left
          close-on-content-click
        >
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor')"
                >
                <v-list-item-title
                  @click="handleEdit(item)"
                  >Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor')"
                >
                <v-list-item-title 
                  @click="handleDeleteConfirmation(item)"
                  >Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | date }}
      </template>

      <template v-slot:[`item.updated`]="{ item }">
        {{ item.updated | date }}
      </template>
      
      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status" class="pr-3"></status-icon>
        {{ item.status }}
      </template>
      
      <template v-slot:[`item.title`]="{ item }">
        <div
          v-if="isRoleSatisfied('Editor')"
          @click="handleEdit(item)"
          class="primary--text"
          style="cursor:pointer"
          >
          {{ item.title }}
          </div>
        <div
          v-else
          >
          {{ item.title }}
        </div>
      </template>
    
    </v-data-table>

  <!-- New or Edit dialog -->

    <admin-add-or-edit
      v-if="dialogNewOrEditOpen"
      :editingAdmin="editingAdmin"
      :activeCount="activeCount"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />
  
  <!-- Delete dialog -->
    
    <admin-delete
      v-if="dialogDeleteOpen"
      :deletingAdmin="deletingAdmin"
      :activeCount="activeCount"
      :open="dialogDeleteOpen"
      @onClose="handleCloseDeleteDialog"
    />
    
  </div>
</template>

<script>
import AdminAddOrEdit from './AdminAddOrEdit.vue'
import AdminDelete from './AdminDelete.vue'
export default {
  components: {
    AdminAddOrEdit,
    AdminDelete
  },
  data: () => ({
    // Component settings unique to this component
    tableTitle: 'Administrators',
    tableSubtitle: 'Create and manage administrators of this program',
    tableIcon: 'admin_panel_settings',
    search: '',
    title: '',
    editingAdmin: null,
    deletingAdmin: null,

    // Table columns
    headers: [
      { text: 'Status', value: 'status', align: 'left' },
      { text: 'Name', value: 'name', align: 'left' },
      { text: 'Email', value: 'email', align: 'left' },
      { text: 'Role', value: 'role', align: 'left' },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],

    // Component settings common to all components
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,

    // Table settings
    options: {
      sortBy: ['title'],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
  }),
  computed: {
    // Functions common to all components
    loadingTable () {
      return this.$store.getters.loadingTable
    },
    loading () {
      return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    currentProgram () {
      return this.$store.state.program.currentProgram
    },
    admins () {
      return this.$store.getters.admins
    },
    programAdmins () {
      return this.currentProgram.admins || []
    },
    adminTableData () {
      return this.programAdmins.reduce((acc, item) => {
        const foundAdmin = this.admins.find(admin => admin.email === item.adminEmail)
        if (foundAdmin) {
          return [
            ...acc,
            {
              ...foundAdmin,
              role: item.adminRole,
              status: 'Active'
            },
          ];
        }
        return [
          ...acc,
          {
            role: item.adminRole,
            email: item.adminEmail,
            name: '',
            userId: '',
            status: 'Invited'
          },
        ];
      }, []);
    },
    activeCount () {
      const filt = this.adminTableData.filter((item) => {
        return item.role === 'Admin' && item.status === 'Active'
      })
      return filt.length
    },
  },

  methods: {
    handleNew() {
      // Open New form
      this.editingAdmin = {};
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.editingAdmin = item;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.editingAdmin = null;
      this.dialogNewOrEditOpen = false;
    },
    handleDeleteConfirmation (item) {
      // Open delete form
      this.deletingAdmin = item;
      this.dialogDeleteOpen = true;
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.dialogDeleteOpen = false;
      this.deletingAdmin = null;
    },
  }
}
</script>

<style>


</style>

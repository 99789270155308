<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab class="tab" @click="clearSearch()">Pages</v-tab>
    </v-tabs>
    <v-row no-gutters justify="start">
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <pages-list></pages-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      pageTitle: "Pages",
      pageSubTitle: "Create and manage pages",
      search: null,
      tab: null,
    };
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadPages");
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadMemberTags");
    this.$store.dispatch("setNavMenu", true);
  },
};
</script>

<style scoped></style>

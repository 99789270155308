<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form @submit.prevent="handleSave('offer')" data-vv-scope="offer">
        <v-row no-gutters>
          <v-card-title class="page-heading">
            Add New {{ formTitle }}
          </v-card-title>
        </v-row>
        <v-card-text class="px-16">
          <v-row>
            <v-text-field
              v-model="title"
              label="Offer Name"
              v-validate="'required'"
              :error-messages="errors.collect('offer.title')"
              name="title"
              type="text"
            />

            <v-select
              v-model="selectedStatus"
              :items="statuses"
              item-text="tag"
              item-value="id"
              label="Status"
              prepend-icon="power_settings_new"
            />

            <v-select
              v-model="selectedCompanyTags"
              :items="companyTags"
              item-text="tag"
              item-value="id"
              label="Company Tags"
              chips
              multiple
              deletable-chips
              prepend-icon="local_offer"
            />

            <v-select
              v-model="selectedMemberTags"
              :items="memberTags"
              item-text="tag"
              item-value="id"
              label="Member Tags"
              chips
              multiple
              deletable-chips
              prepend-icon="local_offer"
            ></v-select>
          </v-row>

          <template>
            <v-col v-if="errorMessage">
              <v-alert type="warning" :value="true">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </template>
        </v-card-text>
        <v-card-actions class="pb-4 pr-4">
          <v-spacer></v-spacer>
          <v-btn text @click="handleCancel"> Cancel </v-btn>
          <v-btn
            color="primary"
            type="submit"
            :loading="isProcessing"
            elevation="0"
            :disabled="demo"
          >
            Save
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      dictionary: {
        custom: {
          title: {
            required: () => "Offer Name is required",
          },
          selectedStatus: {
            required: () => "Status is required",
          },
        },
      },
      formTitle: "Offer",
      formIcon: "how_to_vote",
      title: "",
      selectedCompanyTags: [],
      selectedMemberTags: [],
      selectedStatus: "Active",
      statuses: ["Active", "Inactive", "Archive"],
      isProcessing: false,
      errorMessage: "",
    };
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },
  methods: {
    reset() {
      this.title = "";
      this.errorMessage = "";
      this.selectedCompanyTags = [];
      this.selectedMemberTags = [];
      this.selectedStatus = "Active";
      this.$validator.reset();
    },

    handleCancel() {
      this.reset();
      this.$emit("onClose");
    },

    handleSave(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        if (!validationResult) {
          return;
        }

        this.isProcessing = true;
        const date = new Date();
        const data = {
          status: this.selectedStatus,
          title: this.title,
          order: 0,
          form: {
            pages: [],
            stepper: false,
          },
          titleUppercase: this.title.toUpperCase(),
          created: date,
          updated: date,
          companyTags: this.selectedCompanyTags,
          memberTags: this.selectedMemberTags,
          createdBy: this.$store.getters.user.id,
        };

        this.$store
          .dispatch("addOffer", data)
          .then((this.isProcessing = false), this.handleCancel())
          .catch((msg) => {
            (this.isProcessing = false), (this.errorMessage = msg);
          });
      });
    },
  },
};
</script>

<style scoped></style>

/* eslint-disable no-console */
import { storage } from "@/firebase";

const state = {};

const actions = {
  async deleteMediaFilesFromStorage({ dispatch }, image) {
    console.log(image);
    try {
      var storageRef = storage.ref();
      var storageRefSmall = storageRef.child(image.smallStoragePath);
      var storageRefMedium = storageRef.child(image.mediumStoragePath);
      var storageRefLarge = storageRef.child(image.largeStoragePath);

      await storageRefSmall
        .delete()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });

      await storageRefMedium
        .delete()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });

      await storageRefLarge
        .delete()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      throw "error when deleting an image";
    }
    dispatch("setLoading", false);
    return "ok";
  },

  async deleteResourceFilesFromStorage(state, resource) {
    console.log(resource.storagePath);
    try {
      var storageRef = storage.ref();
      var storageFileRef = storageRef.child(resource.storagePath);

      await storageFileRef
        .delete()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      throw "error when deleting a resource";
    }
    return "ok";
  },
};

const mutations = {};

const getters = {};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-container v-if="!loading" fluid fill-height class="activation-page">
    <v-row justify="center">
      <v-col sm="3">
        <v-card flat class="pa-14">
          <v-alert
            color="red"
            v-if="loadingError"
            class="mb-9"
            dark
            text
            border="left"
          >
            {{ loadingError }}
          </v-alert>
          <v-form @submit.prevent="handleForm" v-if="!loadingError" ref="form">
            <div v-if="!success">
              <v-row class="auth-title" justify="center">
                {{ activeProgram.displayTitle }}
              </v-row>
              <v-row justify="center" class="my-10">
                {{ $t("Set a password to activate your account") }}
              </v-row>
              <v-row no-gutters class="py-2">
                <v-col>
                  <v-text-field
                    v-model="activatingMember.email"
                    v-validate="'required|email'"
                    :error-messages="errors.collect('email')"
                    id="email"
                    name="Email"
                    :label="$t('Email')"
                    type="email"
                    readonly
                  />
                  <v-text-field
                    v-model="password"
                    :rules="rules.password"
                    id="password"
                    name="password"
                    :label="$t('Password')"
                    type="password"
                    required
                    :color="primaryColor"
                  />
                  <v-btn
                    type="submit"
                    :color="primaryColor"
                    elevation="0"
                    v-if="!success"
                    :loading="isProcessing"
                    block
                    large
                    dark
                    class="ml-0"
                  >
                    {{ $t("Activate") }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div v-if="success">
              <v-row no-gutters justify="center" class="goto-heading mb-2">
                <v-icon :color="primaryColor" size="100" class="pt-0 pb-3"
                  >fa-regular fa-circle-check</v-icon
                >
              </v-row>
              <v-row no-gutters justify="center" class="goto-heading mb-6 mt-6">
                {{ $t("Activation complete!") }}
              </v-row>
              <v-row no-gutters justify="center">
                <v-btn
                  @click="gotToSignin(domain)"
                  :color="primaryColor"
                  elevation="0"
                  dark
                  >{{ $t("Go to the sign in page") }}</v-btn
                >
              </v-row>
            </div>

            <v-row
              v-if="
                activeProgram.status === 'Active' ||
                activeProgram.status === 'Demo' ||
                activeProgram.status === 'Trial'
              "
              justify="center"
              class="incentable-page-subheading mt-6"
            >
              <a
                :href="'https://' + domain"
                target="_blank"
                class="text--secondary"
                >{{ domain }}
              </a>
            </v-row>
            <v-row class="d-flex justify-space-around px-6">
              <a
                v-if="activeProgram.iosAppStatus === 'Active'"
                :href="activeProgram.appleStoreUrl"
                target="_blank"
              >
                <img
                  src="/img/apple.png"
                  alt="Download on the App Store"
                  height="40px"
                  class="mt-6"
                />
              </a>
              <a
                v-if="activeProgram.androidAppStatus === 'Active'"
                :href="activeProgram.googleStoreUrl"
                target="_blank"
              >
                <img
                  src="/img/google.png"
                  alt="Get it on Google Play"
                  height="40px"
                  class="mt-6"
                />
              </a>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else fluid fill-height class="activation-page">
    <v-row justify="center">
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
        size="130"
        width="10"
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
import jwt from "jsonwebtoken";
import axios from "axios";
import { webApi } from "@/firebase";
import { DEFAULT_BANNER_URL } from "../../constants/email";
export default {
  async created() {
    const { token } = this.$route.query;
    const json = jwt.verify(token, "shh");
    const url = `${webApi}members/getMemberById/?programId=${json.programId}&memberId=${json.memberId}`;
    var programId = "";
    await axios
      .get(url)
      .then((result) => {
        const { program, member } = result.data;
        this.$i18n.locale = program.translate.locale;
        programId = program.id;
        this.defaultColor = program.webTheme.colors.primary.hex;
        this.activeProgram = program;
        this.activatingMember = member;
      })
      .catch((e) => {
        this.loadingError = e.response.data;
        this.loading = false;
      });
    await this.$store.dispatch("loadTranslations", programId);
    await this.$store.dispatch("loadTranslationData", programId);
    await this.$store.dispatch("loadSites");
    this.getDomain(programId);
    if (this.$store.getters.firstTimeLoad) {
      await this.$store.dispatch("setFirstTimeLoad", false);
      await this.$router.go(0);
    }
    this.loading = false;
  },
  beforeRouteEnter(to, from, next) {
    const { token } = to.query;
    try {
      jwt.verify(token, "shh");
      next();
    } catch (e) {
      next("/404");
    }
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
    this.$store.commit("setNavMenu", false);
    this.$store.dispatch("setToolbar", false);
    this.$store.dispatch("setFooter", false);
  },
  data() {
    return {
      success: false,
      activeProgram: null,
      activatingMember: null,
      password: "",
      isProcessing: false,
      loading: true,
      loadingError: "",
      processingResult: "",
      defaultColor: "#212121",
      domain: "",
      rules: {
        password: [
          (v) => !!v || this.$t("Password is required"),
          (v) =>
            (v && v.length >= 6) ||
            this.$t("Password must be less than 6 characters"),
        ],
      },
    };
  },
  computed: {
    bannerUrl() {
      const notificationSettings =
        this.activeProgram.notificationSettings || {};
      return notificationSettings.bannerUrl || DEFAULT_BANNER_URL;
    },
    primaryColor() {
      return this.defaultColor;
    },
  },
  methods: {
    gotToSignin(domain) {
      const path = "https://" + domain + "/signin";
      window.location.href = path;
    },
    async handleForm() {
      const check = await this.$refs.form.validate();
      console.log(check);
      if (!check) {
        console.log("invalid");
        return;
      }
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        this.isProcessing = true;

        const url = `${webApi}auth/create`;
        const payload = {
          programId: this.activeProgram.id,
          memberId: this.activatingMember.id,
          password: this.password,
        };
        axios
          .post(url, payload)
          .then(() => {
            this.isProcessing = false;
            this.processingResult = this.$t(
              "Account activated. You can now login with your credentials"
            );
            this.$store.dispatch("setSnackbar", this.processingResult);
            this.success = true;
          })
          .catch((e) => {
            this.processingResult = e.response.data;
            this.$store.dispatch("setSnackbar", this.processingResult);
            this.isProcessing = false;
          });
      });
    },
    async getDomain(id) {
      const arr = this.$store.state.site.sites;
      this.domain = arr.find((el) => el.program === id).activeDomain || "";
    },
  },
};
</script>

<style>
.activation-page {
  background-color: #f6f6f6; /* For browsers that do not support gradients */
}
.auth-title {
  font-size: 1.2em;
  font-weight: 600;
}
.auth-subtitle {
  font-size: 0.8em;
  font-weight: 300;
}
.goto-heading {
  font-size: 16px;
  margin-bottom: 18px;
}
</style>

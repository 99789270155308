<template>
  <div>
    <v-card
      v-if="mapping !== 'home'"
      class="pl-3"
      outlined
    >
      <v-row no-gutters align="center" style="min-height: 55px">
        
        <v-col sm="auto" class="pr-2">
          <v-icon class="rotate" color="grey">reorder</v-icon>
        </v-col>
        <v-col sm="1">
          <div class="incentable-form-heading">Columns</div>
          <div 
            v-if="mapping ==='column'"
            class="incentable-databucket-table-subtitle mt-n1"
          >Main Body</div>
          <div 
            v-else-if="mapping ==='header'"
            class="incentable-databucket-table-subtitle mt-n1"
          >Header</div>
        </v-col>

        <v-col sm="auto">
          <v-menu
            offset-y
            :close-on-content-click="false"
            v-model="columnForm"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="grey lighten-4 ml-n4 mr-3"
                icon
                elevation="0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">add</v-icon>
              </v-btn>
            </template>
              <v-card>
                <v-card-text class="px-6 pt-6">
                  <div class="incentable-form-heading mb-3">Add Column</div>
                    <v-select
                      v-model="currentColumn"
                      placeholder="Select Content"
                      :items="sourceColumns"
                      label="Column Content"
                      prepend-icon="fa-solid fa-database"
                      :error-messages="mappingError.column"
                      @change="handleChangeColumn"
                    >
                      <template slot="item" slot-scope="data">
                        <v-row no-gutters v-if="getColumnMeta(data.item).icon">
                          <v-col sm="3">
                            <v-icon>{{ getColumnMeta(data.item).icon }}</v-icon>
                          </v-col>
                          <v-col sm="9">
                            {{ data.item }}
                          </v-col>
                        </v-row>

                        <v-row v-else>
                          <v-col sm="3">
                            <v-icon>fa-solid fa-file-csv</v-icon>
                          </v-col>
                          <v-col sm="9">
                            {{ data.item }}
                          </v-col>
                        </v-row>
                      </template>
                    </v-select>
                  <div sm="2" v-if="currentColumn">
                    <v-text-field
                      v-model="currentLabel"
                      v-if="mapping !== 'home'"
                      placeholder="Add Label"
                      label="Column Label"
                      prepend-icon="fas fa-heading"
                      :error-messages="mappingError.label"
                    />
                  </div>

                  <template v-if="currentColumn === 'Percentage'">
                    <v-card
                      elevation="0"
                      class="grey lighten-3"
                    >
                    <v-row justify="center">
                      <v-col>
                        <v-select
                          v-model="currentLeftOperator"
                          placeholder="Select Column"
                          :items="csvColumns"
                          class="px-12"
                          :error-messages="mappingError.leftOperator"
                        />
                        <v-row justify="center"> As A Percentage Of</v-row>
                        <v-select
                          v-model="currentRightOperator"
                          placeholder="Select Column"
                          :items="csvColumns"
                          class="px-12"
                          :error-messages="mappingError.rightOperator"
                        />
                      </v-col>
                    </v-row>
                    </v-card>
                  </template>

                    <!-- only show extra columns for non incentable fields-->
                  <template v-else>
                    <v-select
                      v-if="currentLabel"
                      v-model="currentType"
                      placeholder="Select Format"
                      label="Column Format"
                      prepend-icon="fas fa-question-circle"
                      :items="dataTypes"
                      item-text="label"
                      item-value="value"
                        :error-messages="mappingError.type"
                    >
                        <template slot="item" slot-scope="data">
                          <v-icon v-if="data.item.label === 'Qualifier'" class="mr-3">{{ getIcon(data.item).icon }}</v-icon>
                          <span v-else v-html="getIcon(data.item).iconCode"></span>
                          {{ data.item.label }}
                        </template>
                      </v-select>
                    <!-- number specific fields -->
                    <template v-if="currentType === 'number'">
                      <v-select
                        v-model="currentNumberFormat"
                        placeholder="Select Prefix/Suffix"
                        label="Prefix/Suffix"
                        :items="numberFormats"
                        item-text="label"
                        item-value="value"
                        :error-messages="mappingError.format"
                        prepend-icon="settings_ethernet"
                      />
                      <v-select
                        v-model="currentNumberRounding"
                        placeholder="Select Number Rounding"
                        :items="numberRoundings"
                        item-text="label"
                        item-value="value"
                        label="Number Rounding"
                        prepend-icon="multiple_stop"
                        :error-messages="mappingError.rounding"
                      />
                    </template>
                  </template>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!currentColumn || demo"
                  @click="handleAddColumn(mapping)"
                  color="primary"
                  elevation="0"
                  >add</v-btn>
              </v-card-actions>

            </v-card>
          </v-menu>
        </v-col>
      
        <v-divider vertical></v-divider>
          <v-col sm="auto" class="mb-2 ml-2">
            <v-chip
              class="mr-1 mt-2"
              v-for="(header, colIndex) of getMapping()"
              :key="header.uid"
              @click:close="handleRemoveColumn(colIndex, mapping)"
              :close="!demo"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="18"
                      left
                      color="grey"
                      v-bind="attrs"
                      v-on="on"
                      >{{ getColumnMeta(header.column).icon }}</v-icon>
                  </template>
                  <span>{{ getColumnMeta(header.column).tooltip }}</span>
                </v-tooltip>
              {{ header.label }}
            </v-chip>
          </v-col>
      </v-row>
    </v-card>
    
    <v-card
      v-else-if="mapping === 'home'"
      class="grey lighten-3 px-2 pt-2"
      elevation="0"
    >
      <v-card-text>
        <v-row
          no-gutters
          class="incentable-form-heading mb-4"
        >
          Set Card Data Source
        </v-row>
        <v-row
          no-gutters
          class="mb-4"
        >
          Current Field: {{ current }}
        </v-row>
        <v-select
          v-model="currentColumn"
          placeholder="Select Data Source"
          :items="sourceColumns"
          label="Data Source"
          prepend-icon="fa-solid fa-database"
          :error-messages="mappingError.column"
          @change="handleChangeColumn"
        >
          <template slot="item" slot-scope="data">
            <v-row no-gutters v-if="getColumnMeta(data.item).icon">
              <v-col sm="3">
                <v-icon>{{ getColumnMeta(data.item).icon }}</v-icon>
              </v-col>
              <v-col sm="9">
                {{ data.item }}
              </v-col>
            </v-row>

            <v-row v-else>
              {{ data.item }}
            </v-row>
          </template>
        </v-select>
        <template v-if="currentColumn === 'Percentage'">
          <v-card
            elevation="0"
            class="grey lighten-2"
          >
          <v-row justify="center">
            <v-col>
              <v-select
                v-model="currentLeftOperator"
                placeholder="Select data field"
                :items="csvColumns"
                class="px-12"
                :error-messages="mappingError.leftOperator"
              />
              <v-row justify="center"> As A Percentage Of</v-row>
              <v-select
                v-model="currentRightOperator"
                placeholder="Select data field"
                :items="csvColumns"
                class="px-12"
                :error-messages="mappingError.rightOperator"
              />
            </v-col>
          </v-row>
        </v-card>
      </template>

        <!-- only show extra columns for non incentable fields-->
      <template v-else>
        <v-select
          v-if="currentColumn"
          v-model="currentType"
          placeholder="Select Format"
          label="Data Format"
          prepend-icon="fas fa-question-circle"
          :items="dataTypes"
          item-text="label"
          item-value="value"
            :error-messages="mappingError.type"
        >
            <template slot="item" slot-scope="data">
              <v-icon v-if="data.item.label === 'Qualifier'" class="mr-3">{{ getIcon(data.item).icon }}</v-icon>
              <span v-else v-html="getIcon(data.item).iconCode"></span>
              {{ data.item.label }}
            </template>
          </v-select>
        <!-- number specific fields -->
        <template v-if="currentType === 'number'">
          <v-select
            v-model="currentNumberFormat"
            placeholder="Select Prefix/Suffix"
            label="Prefix/Suffix"
            :items="numberFormats"
            item-text="label"
            item-value="value"
            :error-messages="mappingError.format"
            prepend-icon="settings_ethernet"
          />
          <v-select
            v-model="currentNumberRounding"
            placeholder="Select Number Rounding"
            :items="numberRoundings"
            item-text="label"
            item-value="value"
            label="Number Rounding"
            prepend-icon="multiple_stop"
            :error-messages="mappingError.rounding"
          />
        </template>
      </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!currentColumn || demo"
          @click="handleAddColumn()"
          color="primary"
          elevation="0"
          >set</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
//import shortid from 'shortid';

import { DATA_TYPES, NUMBER_FORMATS, NUMBER_ROUNDINGS } from '@/constants/table';
export default {
  props: {
    sourceColumns: {
      type: Array,
      required: true,
    },
    mapping: {
      type: String,
      required: true,
    },
    current: {
      type: String,
      required: false,
    },
    csvColumns: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      columnForm: false,
      specialColumns: [
        'Company Title',
        'Full Name',
      ],
      icons: [
        { label: 'Text', iconCode: '<span class="text-icon mr-3">ABC</span>', tooltip: 'Data Bucket field' },
        { label: 'Number', iconCode: '<span class="text-icon mr-3">123</span>', tooltip: 'Data Bucket field' },
        { label: 'Qualifier', icon: 'flaky', tooltip: 'Leaderboard configuration' },
        { label: 'Boolean', icon: 'flaky', tooltip: 'Leaderboard configuration' },
        { label: 'True/False', icon: 'flaky', tooltip: 'Leaderboard configuration' },
        { label: 'Yes/No', icon: 'flaky', tooltip: 'Leaderboard configuration' },
        { label: 'Icon', icon: 'fas fa-medal', tooltip: 'Leaderboard configuration' },
        { label: 'Rank', icon: 'fas fa-sort-numeric-down', tooltip: 'Leaderboard configuration' },
        { label: 'Full Name', icon: 'person', tooltip: 'System field' },
        { label: 'Company Title', icon: 'business', tooltip: 'System field' },
        { label: 'Account Id', icon: 'vpn_key', tooltip: 'System field' },
        { label: 'Target Code', icon: 'vpn_key', tooltip: 'Data Bucket field' },
        { label: 'Target', icon: 'fa-solid fa-bullseye', tooltip: 'Data Bucket field' },
        { label: 'Actual', icon: 'fa-solid fa-database', tooltip: 'Data Bucket field' },
        { label: 'Difference', icon: 'functions', tooltip: 'Data Bucket field' },
        { label: 'Percentage', icon: 'functions', tooltip: 'Data Bucket field' },
        { label: 'Percentage ', icon: 'functions', tooltip: 'Data Bucket field' }, // Note: This one has an added space so not the same as record above
        { label: 'Points', icon: 'stars', tooltip: 'Data Bucket field' },
        { label: 'Claim Points', icon: 'stars', tooltip: 'Data Bucket field' },
        { label: 'Target Points', icon: 'stars', tooltip: 'Data Bucket field' },
        { label: 'Quiz Points', icon: 'stars', tooltip: 'Data Bucket field' },
        { label: 'Total Points', icon: 'stars', tooltip: 'Data Bucket field' }
      ],
      dataTypes: DATA_TYPES,
      numberFormats: NUMBER_FORMATS,
      numberRoundings: NUMBER_ROUNDINGS,
      currentColumn: '',
      currentLabel: '',
      currentType: '',
      currentNumberRounding: '',
      currentNumberFormat: '',
      currentLeftOperator: '',
      currentRightOperator: '',
      mappingError: {},
    };
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    currentTable() {
      return this.$store.state.table.currentTable;
    },
    cardHeaderMappings: {
      get() {
        return this.currentTable.cardHeaderMappings
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { 
          cardHeaderMappings: value })
      }
    },
    resultsHeaderMappings: {
      get() {
        return this.currentTable.resultsHeaderMappings;
      },
      set(value) {
        this.$store.dispatch('patchCurrentTable', { 
          resultsHeaderMappings: value });
      }
    },
  },
  methods: {
    getMapping () {
      if (this.mapping === 'column') {
        return this.resultsHeaderMappings
      } else {
        return this.cardHeaderMappings
      }
    },
    handleRemoveColumn(index, mapping) {
      if (mapping === 'column') {
        const resultsHeaderMappings = [
        ...this.resultsHeaderMappings.slice(0, index),
        ...this.resultsHeaderMappings.slice(index + 1)
      ]

      this.$store.dispatch('patchCurrentTable', { 
        resultsHeaderMappings });
      } else {
        const cardHeaderMappings = [
        ...this.cardHeaderMappings.slice(0, index),
        ...this.cardHeaderMappings.slice(index + 1)
      ]

      this.$store.dispatch('patchCurrentTable', { cardHeaderMappings });
      }
    },
    getIcon (item) {
      const arr = this.icons
      const found = arr.find(el => el.label === item.label)
      return found || ''
    },
    getColumnMeta (item) {
      const arr = this.icons
      const found = arr.find(el => el.label === item)
      return found || ''
    },
    handleChangeColumn () {
      this.currentType = '';
      this.currentLabel = '';
      this.currentNumberFormat = '';
      this.currentNumberRounding ='';
      this.currentQualifierFormat = '';
      this.currentQualifierComp = '';
    },
    handleAddColumn() {
      this.mappingError = {};
      if (!this.currentColumn) {
        this.mappingError = {
          ...this.mappingError,
          column: "Column required.",
        };
        return;
      }

      if (!this.currentLabel && this.mapping !== 'home') {
        this.mappingError = {
          ...this.mappingError,
          label: "Label required.",
        };
        return;
      }

      if (this.currentColumn === 'Percentage' && !this.currentLeftOperator) {
        this.mappingError = {
          ...this.mappingError,
          leftOperator: "Required field.",
        };
        return;
      }

      if (this.currentColumn === 'Percentage' && !this.currentRightOperator) {
        this.mappingError = {
          ...this.mappingError,
          rightOperator: "Required field.",
        };
        return;
      }

      if (
        this.currentColumn !== 'Percentage' &&
        !this.currentType
      ) {
        this.mappingError = {
          ...this.mappingError,
          type: "Type required.",
        };
        return;
      }

      if (this.currentType === 'number' && !this.currentNumberRounding) {
        this.mappingError = {
          ...this.mappingError,
          rounding: "Rounding required.",
        };
        return;
      }

      const newMapping = {
        column: this.currentColumn,
        label: this.currentLabel,
        type: this.currentType,

      };

      if (this.currentColumn === 'Percentage') {
        newMapping.leftOperator = this.currentLeftOperator;
        newMapping.rightOperator = this.currentRightOperator;
      }

      if (this.currentType === 'number') {
        newMapping.format = this.currentNumberFormat;
        newMapping.rounding = this.currentNumberRounding;
      }

      this.$emit('onAdd', newMapping);

      this.currentColumn = '';
      this.currentLabel = '';
      this.currentNumberFormat = '';
      this.currentNumberRounding = '';
      this.currentType = '';
    },
  },
}

</script>

<style scoped>

</style>


<template>
  <v-dialog v-model="open" max-width="1400px" persistent>
    <v-card>
      <form data-vv-scope="tier">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
            absolute
            color="primary"
            :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>
        <v-row justify="start" no-gutters>
          <v-col cols="4" class="mt-n9 mb-6">
            <v-hover v-slot="{ hover }">
              <template>
                <v-row justify="center">
                  <v-avatar
                    size="200"
                    :color="image.mediumUrl ? '' : 'grey lighten-3'"
                    :tile="image.mediumUrl ? true : false"
                  >
                    <v-img
                      :src="image.mediumUrl ? image.mediumUrl : ''"
                      max-height="200"
                      max-width="200"
                      contain
                    >
                      <v-row
                        v-if="hover"
                        justify="center"
                        align="center"
                        class=""
                      >
                        <v-btn
                          fab
                          @click="handleGalleryDialog(true)"
                          elevation="0"
                          color="primary"
                        >
                          <v-icon>image_search</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          @click="handleUploadsDialog(true)"
                          elevation="0"
                          color="primary"
                        >
                          <v-icon>cloud_upload</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          elevation="0"
                          color="secondary"
                          :disabled="!image.mediumUrl"
                          @click="deleteIcon()"
                          ><v-icon>clear</v-icon></v-btn
                        >
                      </v-row>
                      <v-icon v-if="!hover && !image.mediumUrl" size="60"
                        >image</v-icon
                      >
                    </v-img>
                  </v-avatar>
                  <Gallery
                    :saveTo="'tier'"
                    @onImageSelected="handleImageSaved"
                  />
                  <Uploads
                    :saveTo="'tier'"
                    @onImageSelected="handleImageSaved"
                  />
                </v-row>
              </template>
            </v-hover>
          </v-col>
          <v-col class="mt-6">
            <v-row no-gutters>
              <v-col class="incentable-member-name mb-3">
                {{ tier }}
              </v-col>
              <v-col align="end">
                <v-chip
                  v-show="isDefault"
                  class="mr-10 white--text"
                  color="green"
                  >Default</v-chip
                >
              </v-col>
            </v-row>
            <v-row
              v-show="selectedDatabucket"
              no-gutters
              class="mb-1"
              align="center"
            >
              <v-icon color="green" size="18" class="mr-2"
                >fa-solid fa-database</v-icon
              >
              {{ getDatabucketName(selectedDatabucket) }}
            </v-row>
            <v-row
              v-show="conditions.length"
              no-gutters
              class="mb-1"
              align="center"
            >
              <v-icon size="22" class="ml-n1 mr-1">functions</v-icon>
              {{ reformatConditions(conditions) }}
            </v-row>
            <v-row no-gutters class="mb-1" align="center">
              <v-icon
                v-if="selectedParticipantType === 'Member'"
                size="22"
                class="ml-n1 mr-1"
                >person</v-icon
              >
              <v-icon v-else size="22" class="ml-n1 mr-1">business</v-icon>
              {{ selectedParticipantType }}
            </v-row>
          </v-col>
        </v-row>

        <v-tabs v-model="tab" background-color="grey lighten-4" class="mt-4">
          <v-tab class="tab"> Settings </v-tab>
          <v-tab class="tab"> Eligibility </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <v-row class="px-10 pt-6">
              <v-col cols="4" class="pr-6">
                <v-text-field
                  v-model="tier"
                  label="Tier Name"
                  v-validate="'required'"
                  :error-messages="errors.collect('tier.tier')"
                  name="tier"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pr-6">
                <v-select
                  v-model="selectedParticipantType"
                  :items="types"
                  label="Applies to"
                  :disabled="demo"
                ></v-select>
              </v-col>
              <v-col v-if="!isDefault" cols="4" class="pr-6">
                <v-select
                  v-model="selectedDatabucket"
                  :items="databucketsFiltered"
                  item-text="title"
                  item-value="id"
                  label="Select data bucket"
                  :disabled="demo"
                  clearable
                ></v-select>
              </v-col>
            </v-row>

            <div v-if="!isDefault">
              <v-row
                v-for="(cond, condIdx) in conditions"
                :key="condIdx"
                no-gutters
              >
                <v-col class="px-10 mb-3">
                  <v-card
                    outlined
                    elevation="0"
                    width="100%"
                    class="pa-4"
                    color="grey lighten-3"
                  >
                    <v-row no-gutters class="mb-0">
                      <v-col class="incentable-formula-heading">Rules</v-col>
                      <v-col cols="auto" class="mr-n3 mt-n3"
                        ><v-btn
                          @click="handleDeleteCondition(condIdx)"
                          icon
                          :disabled="demo"
                          ><v-icon color="red" size="30">delete_forever</v-icon>
                        </v-btn></v-col
                      >
                    </v-row>
                    <v-row
                      v-for="(subCond, subCondIdx) in cond.subConditions"
                      :key="subCondIdx"
                      no-gutters
                      class="mx-3 mb-n2"
                    >
                      <v-col class="pr-2" sm="2">
                        <v-select
                          @change="changed(true)"
                          v-model="subCond.leftOperand"
                          :items="activeLeftOperandList"
                          item-text="label"
                          item-value="type"
                          outlined
                          dense
                          background-color="white"
                          :error-messages="
                            getSubConditionError(
                              condIdx,
                              subCondIdx,
                              'leftOperand'
                            )
                          "
                          :disabled="demo"
                        />
                      </v-col>
                      <v-col class="mr-2" sm="2">
                        <v-select
                          @change="changed(true)"
                          v-model="subCond.operator"
                          :items="operatorList"
                          item-text="label"
                          item-value="type"
                          outlined
                          dense
                          background-color="grey lighten-2"
                          :error-messages="
                            getSubConditionError(
                              condIdx,
                              subCondIdx,
                              'operator'
                            )
                          "
                          :disabled="demo"
                        />
                      </v-col>
                      <v-col
                        class="mr-2"
                        sm="2"
                        v-if="!subCond.rightOperandVal"
                      >
                        <v-select
                          @change="changed(true)"
                          v-model="subCond.rightOperand"
                          :items="rightOperandsList"
                          item-text="label"
                          item-value="type"
                          outlined
                          dense
                          background-color="white"
                          :error-messages="
                            getSubConditionError(
                              condIdx,
                              subCondIdx,
                              'rightOperand'
                            )
                          "
                          :disabled="demo"
                        />
                      </v-col>
                      <v-col
                        class="mr-2"
                        v-if="subCond.rightOperand === 'number'"
                        sm="2"
                      >
                        <v-text-field
                          @input.native="changed(true)"
                          v-model="subCond.rightOperandVal"
                          placeholder="Enter Number"
                          label="Number"
                          outlined
                          dense
                          background-color="white"
                          clearable
                          :error-messages="
                            getSubConditionError(
                              condIdx,
                              subCondIdx,
                              'rightOperandVal'
                            )
                          "
                          :disabled="demo"
                        />
                      </v-col>
                      <v-col
                        v-if="subCondIdx !== cond.subConditions.length - 1"
                        sm="2"
                      >
                        <v-select
                          @change="changed(true)"
                          v-model="cond.logicalOperators[subCondIdx]"
                          :items="logicOperatorList"
                          item-text="label"
                          item-value="type"
                          placeholder="And/Or"
                          dense
                          outlined
                          background-color="grey lighten-2"
                          :disabled="demo"
                        />
                      </v-col>
                      <v-col sm="auto">
                        <v-btn
                          @change="changed(true)"
                          small
                          icon
                          color="red"
                          :disabled="demo"
                          @click="handleRemoveSubCondition(cond, subCondIdx)"
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row no-gutters class="ml-2">
                      <v-btn
                        @change="changed(true)"
                        @click="handleAddSubCondition(cond)"
                        :disabled="demo"
                        color="primary"
                        elevation="0"
                        icon
                        class="mr-n2"
                      >
                        <v-icon>add</v-icon>
                      </v-btn>
                      <v-btn
                        @change="changed(true)"
                        @click="handleAddSubCondition(cond)"
                        :disabled="demo"
                        text
                      >
                        Add Condition
                      </v-btn>
                    </v-row>

                    <v-card-actions>
                      <v-btn
                        v-show="change"
                        :loading="loading"
                        :disabled="demo"
                        color="primary"
                        elevation="0"
                        @click="handleSave"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>

                  <v-row
                    no-gutters
                    v-if="condIdx !== conditions.length - 1"
                    justify="center"
                    class="mt-6 mb-2"
                  >
                    <v-chip class="incentable-formula-heading pa-4"
                      >ELSE</v-chip
                    >
                  </v-row>
                </v-col>
              </v-row>

              <v-row no-gutters class="px-10 mb-6 mt-3">
                <v-card class="grey lighten-3 pa-6" elevation="0" width="100%">
                  <v-row justify="center">
                    <v-btn
                      @click="handleAddCondition"
                      icon
                      :class="selectedDatabucket ? 'primary mr-n3' : 'mr-n3'"
                      :disabled="demo || !selectedDatabucket"
                    >
                      <v-icon color="white">add</v-icon>
                    </v-btn>
                    <v-btn
                      @click="handleAddCondition"
                      text
                      :disabled="demo || !selectedDatabucket"
                      :class="
                        selectedDatabucket ? 'primary--text mr-n3' : 'mr-n3'
                      "
                    >
                      add formula
                    </v-btn>
                  </v-row>
                  <v-row
                    v-show="!selectedDatabucket"
                    no-gutters
                    justify="center"
                    class="mt-2 mb-n2 incentable-page-subheading"
                  >
                    Select a Data Bucket before creating a formula
                  </v-row>
                </v-card>
              </v-row>

              <v-row dense>
                <v-col v-if="errorMessage">
                  <v-alert type="error" :value="true">
                    {{ errorMessage }}
                  </v-alert>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <v-row class="px-10 pt-6" no-gutters>
              <v-col>
                <v-data-table
                  :headers="
                    selectedParticipantType === 'Member'
                      ? headersMember
                      : headersCompany
                  "
                  :items="qualifiedTierData"
                  :search="search"
                  :options.sync="options"
                  :loading="loadingTable"
                  no-data-text="No Records..."
                >
                  <template
                    v-if="selectedParticipantType === 'Member'"
                    v-slot:[`item.fullname`]="{ item }"
                  >
                    <v-row no-gutters>
                      {{ item.fullname }}
                    </v-row>
                    <v-row no-gutters class="table-subtext">
                      {{ item.email }}
                    </v-row>
                  </template>
                  <template v-slot:[`item.target`]="{ item }">
                    {{ item.target | numberTwoDecimal }}
                  </template>
                  <template v-slot:[`item.actual`]="{ item }">
                    {{ item.actual | numberTwoDecimal }}
                  </template>
                  <template v-slot:[`item.difference`]="{ item }">
                    {{ item.difference | numberTwoDecimal }}
                  </template>
                  <template v-slot:[`item.percentage`]="{ item }">
                    {{ item.percentage | numberTwoDecimal }}
                  </template>
                  <template v-slot:[`item.targetPoints`]="{ item }">
                    {{ item.targetPoints | number }}
                  </template>
                  <template v-slot:[`item.claimPoints`]="{ item }">
                    {{ item.claimPoints | number }}
                  </template>
                  <template v-slot:[`item.quizPoints`]="{ item }">
                    {{ item.quizPoints | number }}
                  </template>
                  <template v-slot:[`item.totalPoints`]="{ item }">
                    {{ item.totalPoints | number }}
                  </template>
                  <template v-slot:[`item.qualified`]="{ item }">
                    <status-icon
                      :status="item.qualified"
                      class="pr-3"
                    ></status-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-btn
            v-if="isDefault"
            @click="setAsDefault(false)"
            color="primary"
            elevation="0"
            :disabled="demo"
            >unset as default</v-btn
          >
          <v-btn
            v-if="!isDefault"
            @click="setAsDefault(true)"
            color="primary"
            elevation="0"
            :disabled="demo"
            >set as default</v-btn
          >

          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Cancel</v-btn>
          <v-btn
            :class="orgTheme.buttonTextColor"
            color="primary"
            @click="handleSave('tier')"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Save</v-btn
          >
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
import Gallery from "../Design/Editor/Shared/Gallery/Gallery.vue";
import Uploads from "../Design/Editor/Shared/Uploads/Uploads.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  created() {},
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    tierId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Gallery,
    Uploads,
  },
  data: () => ({
    dictionary: {
      custom: {
        tier: {
          required: () => "Tier is required",
        },
      },
    },
    search: "",
    tab: null,
    tier: "",
    types: ["Member", "Company"],
    selectedParticipantType: "Member",
    selectedDatabucket: "",
    statement: "",
    isDefault: false,
    errorMessage: "",
    externalImageUrl: "",
    image: {
      name: "",
      updated: "",
      smallUrl: "",
      mediumUrl: "",
      largeUrl: "",
      smallStoragePath: "",
      mediumStoragePath: "",
      largeStoragePath: "",
    },
    formTitle: "Tier",
    formIcon: "fa-solid fa-ranking-star",
    // formula
    expression: "",
    expressionResult: null,
    change: false,
    editingRules: false,
    // Table
    options: {
      sortBy: ["tier"],
      sortDesc: [false],
      itemsPerPage: 5,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
    headingsMember: [
      { text: "Member", value: "fullname", align: "left", required: true },
      { text: "Target", value: "target", align: "right" },
      { text: "Actual", value: "actual", align: "right" },
      { text: "Difference", value: "difference", align: "right" },
      { text: "Percentage", value: "percentage", align: "right" },
      { text: "Target Points", value: "targetPoints", align: "right" },
      { text: "Claim Points", value: "claimPoints", align: "right" },
      { text: "Quiz Points", value: "quizPoints", align: "right" },
      { text: "Total Points", value: "totalPoints", align: "right" },
      {
        text: "Qualified",
        value: "qualified",
        align: "center",
        required: true,
      },
    ],
    headingsCompany: [
      { text: "Company", value: "title", align: "left", required: true },
      { text: "Target", value: "target", align: "right" },
      { text: "Actual", value: "actual", align: "right" },
      { text: "Difference", value: "difference", align: "right" },
      { text: "Percentage", value: "percentage", align: "right" },
      { text: "Target Points", value: "targetPoints", align: "right" },
      { text: "Claim Points", value: "claimPoints", align: "right" },
      { text: "Quiz Points", value: "quizPoints", align: "right" },
      { text: "Total Points", value: "totalPoints", align: "right" },
      {
        text: "Qualified",
        value: "qualified",
        align: "center",
        required: true,
      },
    ],
    operatorList: [
      { label: "=", type: "===" },
      { label: ">=", type: ">=" },
      { label: ">", type: ">" },
      { label: "<=", type: "<=" },
      { label: "<", type: "<" },
    ],
    leftOperandsList: [
      { type: "target", label: "Target" },
      { type: "actual", label: "Actual" },
      { type: "diff", label: "Difference" },
      { type: "percent", label: "Percentage" },
      { type: "points", label: "Target Points" },
      { type: "offerPoints", label: "Claim Points" },
      { type: "quizPoints", label: "Quiz Points" },
      { type: "totalPoints", label: "Total Points" },
    ],
    rightOperandsList: [
      { type: "target", label: "Target" },
      { type: "actual", label: "Actual" },
      { type: "diff", label: "Difference" },
      { type: "percent", label: "Percentage" },
      { type: "number", label: "Number" },
    ],
    logicOperatorList: [
      { label: "And", type: "&&" },
      { label: "Or", type: "||" },
    ],
    conditions: [],
    conditionErrors: [],
    openGallery: false,
    // end formula
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    members() {
      return this.$store.state.member.members;
    },
    companies() {
      return this.$store.state.company.companies;
    },
    tiers() {
      return this.$store.state.tier.tiers;
    },
    headersMember() {
      const filters = this.activeLeftOperandList;
      const headings = this.headingsMember;
      const filteredHeadings = headings.filter(
        (heading) =>
          filters.find(
            (filter) => filter.label === heading.text && filter.show
          ) || heading.required
      );
      return filteredHeadings;
    },
    headersCompany() {
      const filters = this.activeLeftOperandList;
      const headings = this.headingsCompany;
      const filteredHeadings = headings.filter(
        (heading) =>
          filters.find(
            (filter) => filter.label === heading.text && filter.show
          ) || heading.required
      );
      return filteredHeadings;
    },
    activeLeftOperandList() {
      if (this.open && this.selectedDatabucket !== "") {
        const databucket = this.databuckets.find(
          (el) => el.id === this.selectedDatabucket
        );
        const finalArr = databucket.previewColumns
          .filter((el) => el.show === true)
          .map((el) => {
            return {
              type: el.column,
              label: el.label,
              show: el.show,
            };
          });
        return finalArr;
      }
      return [];
    },
    qualifiedTierData() {
      this.$store.dispatch("setLoadingTable", true);
      const tierData = this.$store.state.tier.allTierData.filter(
        (el) => el.tierId === this.tierId
      );
      // console.log(tierData)
      let results = [];
      tierData.forEach((tier) => {
        results.push({
          ...tier,
          qualified: this.checkQualified(tier),
        });
      });
      // console.log(results)
      const isMember = results.some((obj) =>
        Object.values(obj).includes("Member")
      );
      // console.log(isMember)

      const matchedArray = [];

      if (isMember) {
        results.forEach((tier) => {
          const matchingMember = this.members.find(
            (member) => member.accountKey === tier.accountId
          );
          if (matchingMember) {
            const matchedItem = {
              ...tier,
              fullname: matchingMember.fullname,
              email: matchingMember.email,
              memberId: matchingMember.id,
            };
            matchedArray.push(matchedItem);
          }
        });
      }

      if (!isMember) {
        results.forEach((tier) => {
          const matchingMember = this.companies.find(
            (company) => company.accountKey === tier.accountId
          );
          if (matchingMember) {
            const matchedItem = {
              ...tier,
              title: matchingMember.title,
              companyId: matchingMember.id,
            };
            matchedArray.push(matchedItem);
          }
        });
      }

      this.$store.dispatch("setLoadingTable", false);
      return matchedArray;
    },
    databuckets() {
      return this.$store.state.databuckets.databuckets;
    },
    databucketsFiltered() {
      const selected = this.selectedParticipantType
        ? this.selectedParticipantType.toLowerCase()
        : "";
      return this.databuckets.filter((el) => el.participantType === selected);
    },
  },

  watch: {
    tierId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedTier = this.tiers.find((item) => item.id === newVal);
          const {
            tier,
            conditions,
            selectedDatabucket,
            isDefault,
            externalImageUrl,
            image,
            selectedParticipantType,
          } = selectedTier;
          this.tier = tier;
          this.selectedParticipantType = selectedParticipantType;
          this.conditions = conditions;
          this.selectedDatabucket = selectedDatabucket;
          this.isDefault = isDefault;
          this.externalImageUrl = externalImageUrl;
          this.image = image || {
            name: "",
            updated: "",
            smallUrl: "",
            mediumUrl: "",
            largeUrl: "",
            smallStoragePath: "",
            mediumStoragePath: "",
            largeStoragePath: "",
          };
        }
      },
    },
  },

  methods: {
    handleGalleryDialog(val) {
      this.$store.dispatch("setGalleryDialog", val);
    },

    handleUploadsDialog(val) {
      this.$store.dispatch("setUploadsDialog", val);
    },

    deleteIcon() {
      this.image = {
        name: "",
        updated: "",
        smallUrl: "",
        mediumUrl: "",
        largeUrl: "",
        smallStoragePath: "",
        mediumStoragePath: "",
        largeStoragePath: "",
      };
    },

    checkQualified(rec) {
      // console.log(rec);
      let found = false;
      const conditions = rec.conditions;
      conditions.forEach((condition) => {
        condition;
        const targetVal = rec.target;
        const actualVal = rec.actual;
        const diffVal = rec.difference;
        const percentVal = rec.percentage;
        const pointsVal = rec.targetPoints;
        const claimPointsVal = rec.claimPoints;
        const quizPointsVal = rec.quizPoints;
        const totalPointsVal = rec.totalPoints;

        const realStatement = condition.statement
          .replace(/__actual/gi, actualVal)
          .replace(/__target/gi, targetVal)
          .replace(/__percent/gi, percentVal)
          .replace(/__diff/gi, diffVal)
          .replace(/__points/gi, pointsVal)
          .replace(/__offerPoints/gi, claimPointsVal)
          .replace(/__quizPoints/gi, quizPointsVal)
          .replace(/__totalPoints/gi, totalPointsVal);

        // console.log(realStatement);
        const qualified = Function("return" + realStatement)();
        // console.log(qualified)
        if (qualified === true) {
          found = true;
          return;
        }
      });
      return found;
    },

    reformatConditions(conditions) {
      if (!this.editingRules) {
        const reformattedStatements = conditions.map((condition) =>
          condition.statement
            .replace(/__percent/gi, "Percentage")
            .replace(/__actual/gi, "Actual")
            .replace(/__target/gi, "Target")
            .replace(/__points/gi, "Target Points")
            .replace(/__offerPoints/gi, "Claim Points")
            .replace(/__quizPoints/gi, "Quiz Points")
            .replace(/__totalPoints/gi, "Total Points")
            .replace(/&&/g, " And ")
            .replace("||", " Or ")
        );
        const joinedStatements = reformattedStatements.join(" Else ");
        return joinedStatements;
      }
      return "...editing";
    },
    getDatabucketName(id) {
      if (id) {
        return this.databuckets.find((el) => el.id === id).title;
      }
      return null;
    },
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    handleImageSaved(image) {
      // new uploader
      // console.log(image)
      const updated = Date.now();
      (this.image = {
        updated: updated,
        name: image.name || "",
        smallUrl: image.smallUrl || "",
        mediumUrl: image.mediumUrl || "",
        largeUrl: image.largeUrl || "",
        smallStoragePath: image.smallStoragePath || "",
        mediumStoragePath: image.mediumStoragePath || "",
        largeStoragePath: image.largeStoragePath || "",
      }),
        this.handleSave();
    },
    clear() {
      (this.editingRules = false), (this.tier = "");
      (this.tab = null), (this.conditions = []);
      this.conditionErrors = [];
      this.selectedDatabucket = "";
      this.isDefault = false;
      this.externalImageUrl = "";
      this.selectedParticipantType = "Member";
      this.image = {
        // new uploader
        name: "",
        updated: "",
        smallUrl: "",
        mediumUrl: "",
        largeUrl: "",
        smallStoragePath: "",
        mediumStoragePath: "",
        largeStoragePath: "",
      };
      this.$validator.reset();
      this.$store.dispatch("setLoading", false);
    },

    changed(val) {
      this.change = val;
    },
    setAsDefault(val) {
      this.isDefault = val;
      this.handleSave();
    },
    getConditionError(condIdx, key) {
      if (!this.conditionErrors) {
        return;
      }
      if (!this.conditionErrors[condIdx]) {
        return;
      }
      return this.conditionErrors[condIdx][key];
    },
    getSubConditionError(condIdx, subCondIdx, key) {
      if (!this.conditionErrors) {
        return;
      }
      if (!this.conditionErrors[condIdx]) {
        return;
      }
      if (!this.conditionErrors[condIdx].subConditions) {
        return;
      }
      if (!this.conditionErrors[condIdx].subConditions[subCondIdx]) {
        return;
      }
      return this.conditionErrors[condIdx].subConditions[subCondIdx][key];
    },

    handleAddCondition() {
      this.editingRules = true;
      this.conditions = [
        ...this.conditions,
        {
          subConditions: [],
          logicalOperators: [],
        },
      ];
      this.changed(true);
    },

    handleAddSubCondition(cond) {
      this.editingRules = true;
      cond.subConditions = [
        ...cond.subConditions,
        {
          leftOperand: "",
          operator: "",
          rightOperand: "",
        },
      ];

      cond.logicalOperators = [...cond.logicalOperators, "&&"];
      this.changed(true);
    },

    handleRemoveSubCondition(cond, subCondIdx) {
      this.editingRules = true;
      cond.subConditions = [
        ...cond.subConditions.slice(0, subCondIdx),
        ...cond.subConditions.slice(subCondIdx + 1),
      ];

      cond.logicalOperators = [
        ...cond.logicalOperators.slice(0, subCondIdx),
        ...cond.logicalOperators.slice(subCondIdx + 1),
      ];
      this.changed(true);
    },

    handleDeleteCondition(condIdx) {
      this.editingRules = true;
      this.conditions = [
        ...this.conditions.slice(0, condIdx),
        ...this.conditions.slice(condIdx + 1),
      ];
      this.changed(true);
    },

    handleSave(scope) {
      this.$validator.validateAll(scope).then((result) => {
        const defaultErrorMsg = "Required Field.";
        const errors = [];
        let hasErrors = false;

        if (!result) {
          return;
        }

        this.conditions.forEach((condition, idx) => {
          errors[idx] = {
            subConditions: [],
          };

          // if (!condition.result) {
          //   errors[idx].result = defaultErrorMsg;
          //   hasErrors = true;
          // }

          condition.subConditions.forEach((subcondition, subidx) => {
            errors[idx].subConditions[subidx] = {};
            if (!subcondition.leftOperand) {
              errors[idx].subConditions[subidx].leftOperand = defaultErrorMsg;
              hasErrors = true;
            }
            if (!subcondition.operator) {
              errors[idx].subConditions[subidx].operator = defaultErrorMsg;
              hasErrors = true;
            }
            if (!subcondition.rightOperand) {
              errors[idx].subConditions[subidx].rightOperand = defaultErrorMsg;
              hasErrors = true;
            }
            if (
              subcondition.rightOperand === "number" &&
              !subcondition.rightOperandVal
            ) {
              errors[idx].subConditions[subidx].rightOperandVal =
                defaultErrorMsg;
              hasErrors = true;
            }
          });
        });

        if (hasErrors) {
          this.conditionErrors = errors;
          return;
        }
        let conditions = [];
        conditions = this.conditions.map((condition) => {
          let conditionTxtRepr = "";
          condition.subConditions.forEach((subCondition, subConditionIdx) => {
            const lo = `__${subCondition.leftOperand}`;
            const op = subCondition.operator;
            const ro =
              subCondition.rightOperand === "number"
                ? subCondition.rightOperandVal
                : `__${subCondition.rightOperand}`;
            const subConditionTxtRepr = `(${lo} ${op} ${ro})`;

            conditionTxtRepr = `${conditionTxtRepr} ${subConditionTxtRepr}`;

            if (subConditionIdx !== condition.subConditions.length - 1) {
              conditionTxtRepr = `${conditionTxtRepr} ${condition.logicalOperators[subConditionIdx]}`;
            }
          });
          return {
            ...condition,
            statement: conditionTxtRepr,
          };
        });
        this.changed(false);
        // end formula

        this.$store.dispatch("setLoading", true);

        const payload = {
          id: this.tierId || "",
          tier: this.tier || "",
          selectedDatabucket: this.selectedDatabucket || "",
          conditions: conditions || [],
          tierUppercase: this.tier.toUpperCase() || "",
          isDefault: this.isDefault || false,
          externalImageUrl: this.externalImageUrl || "",
          selectedParticipantType: this.selectedParticipantType || "Member",
          image: this.image || {
            name: "",
            updated: "",
            smallUrl: "",
            mediumUrl: "",
            largeUrl: "",
            smallStoragePath: "",
            mediumStoragePath: "",
            largeStoragePath: "",
          },
        };

        const action = this.tierId ? "updateTier" : "createTier";

        this.$store
          .dispatch(action, payload)
          .then(() => {
            this.$store.dispatch("loadAllTierData");
            this.$store.dispatch("setLoading", false);
            this.handleClose();
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch("setLoading", false);
          });
      });
    },
  },
};
</script>

<style></style>

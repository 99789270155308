<template>
  <v-container class="page" fluid>
    <v-tabs>
      <v-tab class="tab"> Points Import Batches </v-tab>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <ImportBatchList />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import ImportBatchList from "./ImportBatchList.vue";
export default {
  components: {
    ImportBatchList,
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadBatches");
    this.$store.dispatch("setNavMenu", true);
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>

<template>
  <v-dialog v-model="open" max-width="400px" persistent>
    <v-card>
      <form data-vv-scope="blog">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
            absolute
            color="primary"
            :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            {{ blogId ? "Edit " : "Add New " }}
            {{ formTitle }}
          </v-card-title>
        </v-row>

        <v-card-text class="px-16">
          <v-row dense>
            <v-col>
              <v-text-field
                name="title"
                v-model="title"
                label="Blog Title"
                v-validate="'required'"
                :error-messages="errors.collect('blog.title')"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-select
                name="status"
                v-model="selectedStatus"
                label="Status"
                v-validate="'required'"
                :error-messages="errors.collect('blog.status')"
                :items="statuses"
                item-text="text"
                item-value="value"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-select
                v-model="selectedCompanyTags"
                :items="companyTags"
                item-text="tag"
                item-value="id"
                label="Company Tags"
                chips
                multiple
                small-chips
                deletable-chips
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-select
                v-model="selectedMemberTags"
                :items="memberTags"
                item-text="tag"
                item-value="id"
                label="Member Tags"
                chips
                multiple
                small-chips
                deletable-chips
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-alert type="warning" v-model="showAlert">
                That Blog is already registered
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Cancel</v-btn>
          <v-btn
            :class="orgTheme.buttonTextColor"
            @click="saveBlog('blog')"
            color="primary"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Save</v-btn
          >
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    blogId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => "Blog title is required",
        },
        status: {
          required: () => "Status is required",
        },
      },
    },
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    formTitle: "Blog",
    formIcon: "book",
    showAlert: false,
    selectedStatus: "",
    title: "",
    created: "",
    updated: "",
    createdBy: "",
    updatedBy: "",
    selectedCompanyTags: [],
    selectedMemberTags: [],
  }),
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    blogs() {
      return this.$store.state.blog.blogs;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
  },
  methods: {
    clear() {
      this.title = "";
      this.created = "";
      this.updated = "";
      this.createdBy = "";
      this.updatedBy = "";
      this.selectedStatus = "";
      this.selectedCompanyTags = [];
      this.selectedMemberTags = [];
      this.showAlert = false;
      this.$validator.reset();
    },
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    saveBlog(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }
        this.$store.dispatch("setLoading", true);
        const payload = {
          // Add database fields here
          title: this.title,
          status: this.selectedStatus,
          titleUppercase: this.title.toUpperCase(),
          companyTags: this.selectedCompanyTags,
          memberTags: this.selectedMemberTags,
        };
        if (this.blogId) {
          // Update Blog
          payload.id = this.blogId;
          this.$store
            .dispatch("updateBlog", payload)
            .then((createResult) => {
              this.$store.dispatch("setLoading", false);
              if (createResult === "ok") {
                this.handleClose();
              } else if (createResult === "duplication") {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch("setLoading", false);
            });
        } else {
          // Create Blog
          this.$store
            .dispatch("createBlog", payload)
            .then((createResult) => {
              this.$store.dispatch("setLoading", false);
              if (createResult === "ok") {
                this.handleClose();
              } else if (createResult === "duplication") {
                this.showAlert = true;
              }
            })
            .catch(() => {
              this.$store.dispatch("setLoading", false);
            });
        }
      });
    },
  },
  watch: {
    blogId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedBlog = this.blogs.find((item) => item.id === newVal);
          const {
            title,
            status,
            created,
            updated,
            createdBy,
            updatedBy,
            companyTags,
            memberTags,
          } = selectedBlog;
          this.selectedStatus = status;
          this.title = title;
          this.selectedCompanyTags = companyTags;
          this.selectedMemberTags = memberTags;
          this.created = created;
          this.updated = updated;
          this.createdBy = createdBy;
          this.updatedBy = updatedBy;
        }
      },
    },
  },
};
</script>

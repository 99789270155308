export const MENU_DEFAULTS = {
  menu: {
    align: 'center',
    color: '#BDBDBDFF',
    field: 'menu',
    floatText: 'text-left',
    font: 'Roboto, sans-serif',
    justify: 'start',
    size: 14,
    boxHeight: 27,
    lineHeight: 1,
    marginLeft: 0,
    marginRight: 0,
    cols: 12,
    style: 'normal',
    value: 'Home',
    weight: 'normal',
  },
  settings: {
    cols: 11,
    lineHeight: 1,
  },
  group: {
    justify: 'start'
  },
  block: {
    cols: 4,
    padding: {
      left: 50,
      right: 0,
      top: 80,
      bottom: 40,
    }
  }
};

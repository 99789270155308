const state = {
  dialogDelete: false,
  dialogS: false,
  dialogM: false,
  dialogL: false,
  dialogXl: false,
  sectionTable: false,
  newOrEdit: false,
};

const actions = {
  setDialogDelete({ commit }, payload) {
    commit("setDialogDelete", payload);
  },
  setDialogS({ commit }, payload) {
    commit("setDialogS", payload);
  },
  setDialogM({ commit }, payload) {
    commit("setDialogM", payload);
  },
  setDialogL({ commit }, payload) {
    commit("setDialogL", payload);
  },
  setDialogXl({ commit }, payload) {
    commit("setDialogXl", payload);
  },
  setSectionTable({ commit }, payload) {
    commit("setSectionTable", payload);
  },
  setNewOrEdit({ commit }, payload) {
    commit("setNewOrEdit", payload);
  },
};

const mutations = {
  setDialogDelete(state, payload) {
    state.dialogDelete = payload;
  },
  setDialogS(state, payload) {
    state.dialogS = payload;
  },
  setDialogM(state, payload) {
    state.dialogM = payload;
  },
  setDialogL(state, payload) {
    state.dialogL = payload;
  },
  setDialogXl(state, payload) {
    state.dialogXl = payload;
  },
  setSectionTable(state, payload) {
    state.sectionTable = payload;
  },
  setNewOrEdit(state, payload) {
    state.newOrEdit = payload;
  },
};

const getters = {
  dialogDelete(state) {
    return state.dialogDelete;
  },
  dialogS(state) {
    return state.dialogS;
  },
  dialogM(state) {
    return state.dialogM;
  },
  dialogL(state) {
    return state.dialogL;
  },
  dialogXl(state) {
    return state.dialogXl;
  },
  sectionTable(state) {
    return state.sectionTable;
  },
  newOrEdit(state) {
    return state.newOrEdit;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

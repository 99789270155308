/* eslint-disable no-console */
import { db } from "@/firebase";
import moment from "moment";

const state = {
  allUsersTransactions: [],
  loadingPointsAwarded: false,
  allUsersRedeemedTransactions: [],
  loadingPointsRedeemed: false,
};

const actions = {
  async loadAllUsersRedeemedTransactions({ commit, getters }) {
    commit("setAllUsersRedeemedTransactions", []);
    commit("setLoadingPointsRedeemed", true);
    const transactions = [];
    try {
      const dbRef = db.collection("programs").doc(getters.programId);

      const transactionQuerySnapshot = await dbRef
        .collection("transactionHistory")
        .where("type", "in", ["Redeem", "Reorder", "Refund"])
        .get();

      transactionQuerySnapshot.forEach((transactionDoc) => {
        const transactionData = transactionDoc.data();
        const createdDate = moment(transactionData.created.toDate());
        const monthYear = createdDate.format("MMM-YY");
        const points = 0 - transactionData.points;

        // Check if there is an existing subtotal entry for the monthYear
        const existingSubtotal = transactions.find(
          (subtotal) => subtotal.monthYear === monthYear
        );

        if (existingSubtotal) {
          // Add points to the existing subtotal
          existingSubtotal.points += points;
        } else {
          // Create a new subtotal entry
          transactions.push({
            monthYear,
            points: points,
          });
        }
      });

      commit("setAllUsersRedeemedTransactions", transactions);
      commit("setLoadingPointsRedeemed", false);
    } catch (error) {
      console.error(error);
    }
  },
  async loadAllUsersTransactions({ commit, getters }) {
    commit("setAllUsersTransactions", []);
    commit("setLoadingPointsAwarded", true);

    const transactions = [];
    try {
      const dbRef = db.collection("programs").doc(getters.programId);

      const transactionQuerySnapshot = await dbRef
        .collection("transactionHistory")
        .where("type", "in", ["Award", "Adjust"])
        .get();

      transactionQuerySnapshot.forEach((transactionDoc) => {
        const transactionData = transactionDoc.data();
        const createdDate = moment(transactionData.created.toDate());
        const monthYear = createdDate.format("MMM-YY");
        const points = transactionData.points;

        // Check if there is an existing subtotal entry for the monthYear
        const existingSubtotal = transactions.find(
          (subtotal) => subtotal.monthYear === monthYear
        );

        if (existingSubtotal) {
          // Add points to the existing subtotal
          existingSubtotal.points += points;
        } else {
          // Create a new subtotal entry
          transactions.push({
            monthYear,
            points: points,
          });
        }
      });
      commit("setAllUsersTransactions", transactions);
      commit("setLoadingPointsAwarded", false);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setAllUsersTransactions(state, payload) {
    state.allUsersTransactions = payload;
  },
  setAllUsersRedeemedTransactions(state, payload) {
    state.allUsersRedeemedTransactions = payload;
  },
  setLoadingPointsAwarded(state, payload) {
    state.loadingPointsAwarded = payload;
  },
  setLoadingPointsRedeemed(state, payload) {
    state.loadingPointsRedeemed = payload;
  },
};

const getters = {
  allUsersTransactions(state) {
    return state.allUsersTransactions;
  },
  loadingPointsAwarded(state) {
    return state.loadingPointsAwarded;
  },
  allUsersRedeemedTransactions(state) {
    return state.allUsersRedeemedTransactions;
  },
  loadingPointsRedeemed(state) {
    return state.loadingPointsRedeemed;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

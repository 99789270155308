<template>
  <div :style="'background-color: ' + webTheme.colors.canvas.hexa">
    <div v-if="designDrawer !== 'Auth'">
      <!-- Header components view -->
      <component
        :is="component.id"
        v-for="component in header"
        :key="component.idx"
        :component="component"
      ></component>
      <v-row no-gutters justify="center" v-if="header.length === 0">
        <v-col>
          <v-card class="pa-3 grey-lighten-4" elevation="0">
            <v-card class="mt-3 mx-3 pa-6 dotted" elevation="0" height="29vh">
              <v-row no-gutters justify="center" class="page-heading mb-3">
                Header
              </v-row>
              <v-row no-gutters justify="center" class="page-subheading mb-3">
                There are no active header components.
              </v-row>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <!-- Content components view -->
      <component
        :is="component.id"
        v-for="component in content"
        :key="component.idx"
        :component="component"
      ></component>
      <v-row no-gutters justify="center" v-if="content.length === 0">
        <v-col>
          <v-card class="pa-3 grey lighten-4" elevation="0">
            <v-card class="mt-3 mx-3 pa-6 dotted" elevation="0" height="29vh">
              <v-row no-gutters justify="center" class="page-heading mb-3">
                Content
              </v-row>
              <v-row no-gutters justify="center" class="page-subheading mb-3">
                There are no active content components.
              </v-row>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <!-- Footer components view -->
      <component
        :is="component.id"
        v-for="component in footer"
        :key="component.idx"
        :component="component"
      ></component>
      <v-row no-gutters justify="center" v-if="footer.length === 0">
        <v-col>
          <v-card class="pa-3 grey lighten-4" elevation="0">
            <v-card
              class="mt-3 mx-3 mb-3 pa-6 dotted"
              elevation="0"
              height="29vh"
            >
              <v-row no-gutters justify="center" class="page-heading mb-3">
                Footer
              </v-row>
              <v-row no-gutters justify="center" class="page-subheading mb-3">
                There are no active footer components
              </v-row>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <footnote />
    </div>

    <div v-else>
      <!-- Authentication components view -->
      <component
        :is="component.id"
        v-for="component in authentication"
        :key="component.idx"
        :component="component"
      ></component>
      <v-row no-gutters justify="center" v-if="authentication.length === 0">
        <v-col>
          <v-card class="pa-3 grey lighten-4" elevation="0">
            <v-card class="mt-3 mx-3 pa-6 dotted" elevation="0" height="100vh">
              <v-row no-gutters justify="center" class="page-heading mb-3">
                Authentication
              </v-row>
              <v-row no-gutters justify="center" class="page-subheading mb-3">
                There are no active authentication components.
              </v-row>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import MenuBar from "./ViewComponents/Header/MenuBar.vue";
import Banner from "./ViewComponents/Header/Banner.vue";
import TextOneCol from "./ViewComponents/Shared/TextOneCol.vue";
import TextTwoCol from "./ViewComponents/Shared/TextTwoCol.vue";
import TextThreeCol from "./ViewComponents/Shared/TextThreeCol.vue";
import ImageComponent from "./ViewComponents/Shared/Image.vue";
import ProgressComponent from "./ViewComponents/Shared/Progress/Progress.vue";
import AwardsComponent from "./ViewComponents/Shared/Awards/Awards.vue";
import ArticlesPreviewComponent from "./ViewComponents/Shared/Articles/ArticlesPreview.vue";
import ArticlesComponent from "./ViewComponents/Shared/Articles/Articles.vue";
import RewardsPreviewComponent from "./ViewComponents/Shared/Rewards/RewardsPreview.vue";
import RewardsComponent from "./ViewComponents/Shared/Rewards/Rewards.vue";
import ClaimsComponent from "./ViewComponents/Shared/Claims/Claims.vue";
import QuizzesComponent from "./ViewComponents/Shared/Quizzes/Quizzes.vue";
import FooterMenu from "./ViewComponents/Footer/Menu.vue";
import HeadingSection from "./ViewComponents/Shared/HeadingSection.vue";
import PageHeadings from "./ViewComponents/Shared/BasicPage/PageHeadings.vue";
import PageText from "./ViewComponents/Shared/BasicPage/PageText.vue";
import Avatar from "./ViewComponents/Shared/Profile/Avatar.vue";
import ProfileDetail from "./ViewComponents/Shared/Profile/ProfileDetail.vue";
import AuthSignIn from "./ViewComponents/Shared/Authentication/SignIn.vue";
import AuthSignUp from "./ViewComponents/Shared/Authentication/SignUp.vue";
import Footnote from "./Footnote.vue";

export default {
  components: {
    MenuBar,
    Banner,
    ProgressComponent,
    AwardsComponent,
    FooterMenu,
    ArticlesPreviewComponent,
    ArticlesComponent,
    ImageComponent,
    RewardsComponent,
    RewardsPreviewComponent,
    ClaimsComponent,
    QuizzesComponent,
    TextOneCol,
    TextTwoCol,
    TextThreeCol,
    HeadingSection,
    PageHeadings,
    PageText,
    Avatar,
    ProfileDetail,
    AuthSignIn,
    AuthSignUp,
    Footnote,
  },
  mounted() {},
  data: () => ({}),
  computed: {
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    selectedPageTemplate() {
      return this.$store.getters.selectedPageTemplate;
    },
    designDrawer() {
      return this.$store.getters.designDrawer;
    },
    authentication() {
      switch (this.selectedPageTemplate) {
        case "Signup":
          return this.webTheme.auth.signup.components;
        default:
          return this.webTheme.auth.signin.components;
      }
    },
    header() {
      return this.webTheme.header.components;
    },
    content() {
      switch (this.selectedPageTemplate) {
        case "Articles":
          return this.webTheme.content.articles.components;
        case "Home":
          return this.webTheme.content.home.components;
        case "Claims":
          return this.webTheme.content.claims.components;
        case "Quizzes":
          return this.webTheme.content.quizzes.components;
        case "Basic Page":
          return this.webTheme.content.pages.components;
        case "Rewards":
          return this.webTheme.content.rewards.components;
        case "Profile":
          return this.webTheme.content.profile.components;
        default:
          return this.webTheme.content.home.components;
      }
    },
    footer() {
      return this.webTheme.footer.components;
    },
  },
};
</script>

<style scoped>
.dotted {
  border: 1px dashed rgb(184, 184, 184) !important;
}
</style>

<template>
  <div>
    <RulesTable />
  </div>
</template>

<script>
import RulesTable from "./RulesTable.vue";
export default {
  components: {
    RulesTable,
  },
  created() {
    this.$store.dispatch("setLastRuleDeleted", false);
  },
  data: function () {
    return {};
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },
  methods: {},
  watch: {},
};
</script>

<style scoped>
.col-adjust-then {
  padding-left: 110px !important;
}
</style>

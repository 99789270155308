/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
import { customAlphabet } from "nanoid";

const state = {
  sites: [],
};

const actions = {
  async loadSites({ commit }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db.collection("sites").get();
    } catch (e) {
      querySnapshot = [];
    }

    const sites = [];
    querySnapshot.forEach(function (doc) {
      sites.push({
        ...doc.data(),
        id: doc.id,
        programId: doc.data().program,
        created: doc.data().created.toDate(),
        updated: !doc.data().updated ? "" : doc.data().updated.toDate(),
      });
    });

    commit("setSites", sites);
    commit("setLoadingTable", false);
  },

  async createSite({ commit, getters }, payload) {
    // console.log(payload)
    const sitesCollection = db.collection("sites");

    let existingSiteResult;
    try {
      existingSiteResult = await sitesCollection
        .where("activeDomain", "==", payload.activeDomain)
        .get();
    } catch (e) {
      throw "error when fetching existing site";
    }

    if (existingSiteResult.size > 0) {
      return "duplication";
    }

    const site = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
    };

    let newSiteRef;
    try {
      newSiteRef = await sitesCollection.add(site);
    } catch (e) {
      throw "error when creating a new site";
    }

    // Note: server time is unavailable until we refetch.
    const tempSite = {
      ...site,
      id: newSiteRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createSite", tempSite);
    commit("setSnackbar", "Site added");

    return "ok";
  },

  async updateSite({ commit, getters }, payload) {
    const sitesCollection = db.collection("sites");

    let existingSiteResult;
    try {
      existingSiteResult = await sitesCollection
        .where("activeDomain", "==", payload.activeDomain)
        .get();
    } catch (e) {
      throw "error when fetching existing site in site.js line 96";
    }

    let duplicated = false;
    existingSiteResult.forEach((doc) => {
      if (doc.id !== payload.id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    const site = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id,
    };

    try {
      await sitesCollection.doc(payload.id).update(site);
    } catch (e) {
      throw "error when updating an site";
    }

    const siteBeforeUpdate = getters.sites.find(
      (item) => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempSite = {
      ...siteBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id,
    };

    commit("updateSite", tempSite);
    commit("setSnackbar", "Program updated");

    return "ok";
  },

  async deleteSite({ commit }, id) {
    try {
      await db.collection("sites").doc(id).delete();
    } catch (e) {
      throw "error when deleting an site";
    }
    commit("deleteSite", id);
    commit("setSnackbar", "Domain deleted");
  },

  async assignSite({ getters, dispatch }, payload) {
    // console.log(payload)
    await dispatch("loadSites");
    // Link a temp subdomain
    const sites = getters.sites;
    const site = sites.find(
      (el) => el.status === "Available" && el.program === ""
    );
    const tempDomain = {
      ...site,
      status: "Live",
      program: payload.id,
      updated: new Date(),
      updatedBy: getters.user.id,
    };
    // console.log(site)
    await dispatch("updateSite", tempDomain);

    // create the incentable subdomain
    const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 5);
    const tempSub = nanoid();
    const subDomain = {
      activeDomain: payload.incentableSubdomain,
      clientDomian: "",
      dns: "",
      domainType: "Sub",
      incentableSubdomain: payload.incentableSubdomain,
      pod: site.pod,
      program: payload.id,
      status: "Processing",
      tempSubdomain: tempSub || "",
    };
    await dispatch("createSite", subDomain);
  },
};

const mutations = {
  setSites(state, payload) {
    state.sites = payload;
  },

  createSite(state, payload) {
    state.sites = [...state.sites, payload];
  },

  updateSite(state, payload) {
    state.sites = state.sites.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteSite(state, payload) {
    state.sites = state.sites.filter((item) => item.id !== payload);
  },
};

const getters = {
  sites(state) {
    return state.sites;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

/* eslint-disable no-console */
<template>
  <div class="px-4 pt-4">
    <form data-vv-scope="email">
      <v-row no-gutters>
        <v-col lg="5" class="pr-16">
          <v-row class="incentable-form-heading" no-gutters> To </v-row>
          <v-row no-gutters class="incentable-form-subheading">
            Filter recipients by Status and Tag(s)
          </v-row>
          <v-row no-gutters>
            <v-select
              v-model="selectedMemberStatus"
              :items="statusList"
              label="Member Status"
              chips
              multiple
              deletable-chips
              append-icon="person"
            />
          </v-row>
          <v-row no-gutters>
            <v-col class="mr-3">
              <v-select
                v-model="selectedCompanyTags"
                :items="companyTags"
                item-text="tag"
                item-value="id"
                label="Company Tags"
                chips
                multiple
                deletable-chips
                append-icon="label"
              ></v-select>
            </v-col>
            <v-col class="ml-3">
              <v-select
                v-model="selectedMemberTags"
                :items="memberTags"
                item-text="tag"
                item-value="id"
                label="Member Tags"
                persistent-hint
                chips
                multiple
                deletable-chips
                append-icon="label"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters class="incentable-form-heading"> From </v-row>
          <v-row no-gutters class="incentable-form-subheading">
            The email address members will see. This cannot be changed due to
            anti SPAM restrictions
          </v-row>
          <v-row no-gutters>
            <v-text-field
              readonly
              v-model="replyEmail"
              label="Reply Email"
              type="text"
              name="subject"
            />
          </v-row>
          <v-row no-gutters class="incentable-form-heading"> Subject </v-row>
          <v-row no-gutters class="incentable-form-subheading">
            The email subject line will show in the members inbox
          </v-row>
          <v-row no-gutters>
            <v-text-field
              v-validate="'required'"
              :error-messages="errors.collect('email.subject')"
              v-model="subject"
              label="Subject"
              type="text"
              name="subject"
            />
          </v-row>
          <v-row no-gutters class="incentable-form-heading"> Content </v-row>
          <v-row no-gutters class="incentable-form-subheading">
            Enter content below. Click on the image preview on the right to
            change the email banner
          </v-row>
          <v-row no-gutters>
            <v-text-field
              v-validate="'required'"
              :error-messages="errors.collect('email.heading')"
              v-model="heading"
              type="text"
              name="heading"
              label="Heading"
            />
          </v-row>
          <v-row no-gutters>
            <v-text-field
              v-validate="'required'"
              :error-messages="errors.collect('email.subheading')"
              v-model="subheading"
              type="text"
              name="subheading"
              label="Sub Heading"
            />
          </v-row>
          <v-row no-gutters>
            <v-textarea
              v-validate="'required'"
              :error-messages="errors.collect('email.body')"
              v-model="body"
              name="body"
              type="text"
              label="Email Body"
            />
          </v-row>
          <v-row no-gutters justify="end">
            <v-btn text @click="clear()">Clear</v-btn>
            <v-btn
              @click="sendEmail('email')"
              color="primary"
              :loading="loading"
              elevation="0"
              :disabled="disabled"
            >
              Send
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="12" lg="7">
          <v-row no-gutters>
            <v-card color="grey lighten-3 rounded-0" flat width="100%">
              <v-row justify="center">
                <v-card flat class="pa-5 mt-10 mb-12 rounded-0" width="645px">
                  <v-progress-linear
                    v-if="imageUploading"
                    v-model="imageUploadingProgress"
                  >
                  </v-progress-linear>

                  <v-hover>
                    <v-card
                      slot-scope="{ hover }"
                      flat
                      class="mb-5 pt-0 mt-0 rounded-0"
                      transition="fade-transition"
                    >
                      <v-img
                        :src="imageUrl || defaultBannerUrl"
                        :lazy-src="require('@/assets/placeholder.png')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                        <v-fade-transition>
                          <v-overlay v-if="hover" opacity="0.3" absolute>
                            <v-btn
                              icon
                              class="primary mr-6"
                              fab
                              elevation="0"
                              large
                              @click="pickFile"
                            >
                              <v-icon>find_replace</v-icon>
                            </v-btn>
                          </v-overlay>
                        </v-fade-transition>
                      </v-img>

                      <div
                        v-if="bannerSize"
                        class="float-right incentable-form-subheading"
                      >
                        File size: {{ bannerSize }} KB
                      </div>
                    </v-card>
                  </v-hover>

                  <div>
                    <input
                      type="file"
                      style="display: none"
                      ref="image"
                      accept="image/*"
                      @change="onFilePicked"
                    />
                  </div>
                  <v-card-text class="mx-n1">
                    <v-row class="e-heading">
                      {{ heading || "Heading" }}
                    </v-row>
                    <v-row class="e-subheading">
                      {{ subheading || "Sub Heading" }}
                    </v-row>
                    <v-row class="e-body">
                      {{ body || "Email Body" }}
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-row>
              <v-row justify="center" class="e-footer mb-3 mt-n5">
                Sent By {{ currentProgram.title }}
              </v-row>
              <v-row justify="center" class="e-footer mb-6">
                Powered by Incentable
              </v-row>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import { storage, timestamp } from "@/firebase";
import { MEMBER_STATUS } from "@/constants/member";
import {
  MAX_BANNER_FILE_SIZE,
  DEFAULT_BANNER_URL,
  REPLY_TO_EMAIL,
} from "@/constants/email";

export default {
  $_veeValidate: {
    validator: "new",
  },

  mounted() {
    this.$validator.localize("en", this.dictionary);
  },

  created() {
    const { replyEmail } = this.currentProgram.notificationSettings || {};
    if (!replyEmail) {
      this.$store.dispatch("setReplyEmail", REPLY_TO_EMAIL);
    } else {
      this.$store.dispatch("setReplyEmail", replyEmail);
    }
  },

  data: () => ({
    tableTitle: "New Email",
    tableSubtitle: "Send bulk email to members",
    tableIcon: "mail",
    imageName: "",
    imageUrl: "",
    bannerSize: "",
    imageUploadTask: null,
    imageUploadingProgress: 0,
    imageUploading: false,
    heading: "",
    body: "",
    subheading: "",
    subject: "",
    selectedCompanyTags: [],
    selectedMemberTags: [],
    dictionary: {
      custom: {
        SUBJECT: {
          required: () => "Email subject is required.",
        },
        subheading: {
          required: () => "The subheading is required.",
        },
        heading: {
          required: () => "The heading is required.",
        },
        body: {
          required: () => "Body cannot be blank.",
        },
      },
    },
    statusList: MEMBER_STATUS,
    selectedMemberStatus: ["Active"],
  }),

  computed: {
    disabled() {
      if (this.subscription.active || this.isIncentableAdmin) {
        return false; // not disabled
      } else {
        return true; // disabled
      }
    },
    subscription() {
      return this.$store.state.plans.subscription;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loading() {
      return this.$store.getters.loading;
    },
    programId() {
      return this.$store.getters.programId;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    replyEmail() {
      return this.$store.getters.replyEmail;
    },
    defaultBannerUrl() {
      const { bannerUrl } = this.currentProgram.notificationSettings || {};
      return bannerUrl || DEFAULT_BANNER_URL;
    },
    previewObj() {
      return {
        type: "email",
        imageUrl: this.imageUrl,
        heading: this.heading,
        subheading: this.subheading,
        body: this.body,
        sender: this.currentProgram.title,
        memberStatus: this.selectedMemberStatus,
      };
    },
  },

  methods: {
    clear() {
      this.subject = "";
      this.heading = "";
      this.subheading = "";
      this.body = "";
      this.imageUrl = "";
      this.bannerSize = "";
      this.imageName = "";
      this.selectedCompanyTags = [];
      this.selectedMemberTags = [];
      this.selectedMemberStatus = ["Active"];
      this.imageUploadTask = null;
      this.imageUploadingProgress = 0;
      this.imageUploading = false;
      this.$validator.reset();
      this.clearFile();
    },

    openPreviewDialog() {
      this.$store.dispatch("setDialogNotificationPreview", "email");
    },
    sendEmail(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }

        const email = {
          imageUrl: this.imageUrl,
          replyEmail: this.replyEmail,
          subject: this.subject,
          heading: this.heading,
          subheading: this.subheading,
          body: this.body,
          companyTags: this.selectedCompanyTags,
          memberTags: this.selectedMemberTags,
          type: "email",
          status: "new",
          memberStatus: this.selectedMemberStatus,
        };
        this.$store
          .dispatch("createNotification", email)
          .then(() => this.clear())
          .catch((err) => {
            console.log(err);
          });
      });
    },
    pickFile() {
      this.$refs.image.click();
    },
    clearFile() {
      this.imageName = "";
      this.imageUrl = "";
      this.imageUploadTask = null;
      this.imageUploading = false;
    },
    onFilePicked(e) {
      const file = e.target.files[0];

      if (!file || file.size > MAX_BANNER_FILE_SIZE) {
        return;
      }

      this.imageName = file.name;

      this.imageUploadTask = storage
        .ref(
          `uploads/${this.programId}/emailBanners/${timestamp}_${this.imageName}`
        )
        .put(file);

      this.imageUploadingProgress = 0;

      this.imageUploading = true;
    },
  },
  watch: {
    imageUploadTask: function () {
      if (!this.imageUploadTask) {
        return;
      }

      this.imageUploadTask.on(
        "state_changed",
        (sp) => {
          if (sp.totalBytes <= 0) {
            return;
          }
          this.imageUploadingProgress = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
          this.bannerSize = Math.round(sp.bytesTransferred / 1000);
        },
        () => {
          this.imageUploading = false;
        },
        () => {
          this.imageUploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              this.imageUrl = downloadURL;
              this.imageUploading = false;
            });
        }
      );
    },
    replyEmail: {
      handler(newVal) {
        if (newVal) {
          const { replyEmail } = this.currentProgram.notificationSettings || {};
          if (replyEmail) {
            return REPLY_TO_EMAIL;
          } else {
            return this.$store.getters.replyEmail;
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.e-heading {
  font-family: Sans-Serif !important;
  font-weight: bold !important;
  font-size: 25px !important;
  color: #616161 !important;
}
.e-subheading {
  font-family: Sans-Serif !important;
  font-weight: bold !important;
  font-size: 18px !important;
  color: #616161 !important;
  line-height: 3 !important;
  margin-top: 10px !important;
}
.e-body {
  font-family: Sans-Serif !important;
  font-size: 14px !important;
  overflow-wrap: break-word;
  color: #616161 !important;
  line-height: 1.5 !important;
}
.e-footer {
  font-family: Sans-Serif !important;
  font-size: 12px !important;
  color: #616161 !important;
}
</style>

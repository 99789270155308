<template>
  <div class="page">
    <v-container v-if="loadingQuiz" fluid fill-height>
      <v-row>
        <v-col align="center">
          <v-progress-circular
            :size="100"
            :width="8"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <v-card v-else class="el rounded-lg mt-1 mr-6 mb-6">
      <v-row no-gutters justify="center" align="center">
        <v-col>
          <v-tabs v-model="tab">
            <v-tab class="tab"> Questions </v-tab>
            <v-tab class="tab"> Points Rules </v-tab>
            <v-tab class="tab"> Media </v-tab>
            <v-tab class="tab"> Schedule </v-tab>
            <v-tab class="tab"> Settings </v-tab>
          </v-tabs>
        </v-col>
        <v-col>
          <v-row no-gutters align="center" justify="end">
            <v-chip
              v-if="isQuizOpen"
              class="capitalize table-subtitle mr-2"
              color="grey lighten-2"
            >
              <v-avatar left>
                <v-icon color="green">check_circle</v-icon>
              </v-avatar>
              Open
            </v-chip>
            <v-chip
              v-else
              class="capitalize table-subtitle mr-2"
              color="grey lighten-2"
            >
              <v-avatar left>
                <v-icon color="red">cancel</v-icon>
              </v-avatar>
              Closed
            </v-chip>
            <span class="page-title mr-6">{{ quiz.title }}</span>
          </v-row>
        </v-col>
      </v-row>

      <v-row no-gutters justify="center">
        <v-col cols="12" lg="12">
          <v-tabs-items v-model="tab">
            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <questions :quizId="quizId" />
            </v-tab-item>

            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <points-rules :quizId="quizId" />
            </v-tab-item>

            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <quiz-media :quizId="quizId" />
            </v-tab-item>

            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <dates :quizId="quizId" />
            </v-tab-item>

            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <settings :quizId="quizId" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>

    <v-alert
      type="info"
      text
      color="primary"
      border="left"
      class="mr-6 mt-n1 mb-16"
    >
      <span class="incentable-alert"
        >Would you like to test drive this quiz?</span
      >
      <v-btn
        color="primary"
        outlined
        class="my-n1 ml-6 float-right"
        @click="handlePreview"
        >preview this quiz</v-btn
      >
    </v-alert>

    <preview-quiz
      :previewDialog="previewDialog"
      @onClosePreview="handleClosePreview"
    ></preview-quiz>

    <div class="sticky-footer">
      <v-btn
        :disabled="demo || !quizChanged"
        :loading="savingQuiz"
        color="primary"
        @click="handleSave"
        elevation="0"
      >
        Save Quiz</v-btn
      >
      <span class="incentable-form-subheading ml-3 mr-1">
        Last Saved {{ quiz.updated | date }}
      </span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { store } from "@/store";
import axios from "axios";
import Dates from "./Components/Dates/Dates.vue";
import Settings from "./Components/Settings/Settings.vue";
import Questions from "./Components/Questions/Questions.vue";
import PointsRules from "./Components/Points/PointsRules.vue";
import QuizMedia from "./Components/Media/Media.vue";
import PreviewQuiz from "./Components/Preview/PreviewQuiz.vue";

export default {
  beforeRouteEnter(to, from, next) {
    const { quizId } = to.params;
    Promise.all([
      store.dispatch("setQuizChanged", false),
      store.dispatch("loadQuiz", quizId),
      store.dispatch("setNavMenu", true),
      store.dispatch("loadDatabuckets"),
    ])
      .then(() => {
        next();
      })
      .catch(() => {
        next("/404");
      });
  },
  components: {
    Settings,
    Questions,
    PointsRules,
    QuizMedia,
    PreviewQuiz,
    Dates,
  },
  data: function () {
    return {
      tab: null,
      isProcessing: false,
      quizId: null,
      previewDialog: false,
      isQuizOpen: false,
      currentTime: new Date().toLocaleTimeString(),
    };
  },
  mounted() {
    this.timer = setInterval(this.checkTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    this.$store.dispatch("setBreadCrumbDetail", this.quiz.title);
    this.$store.dispatch("resetQuizUpdates");
    this.$store.dispatch("setSavingQuiz", false);
    this.$store.dispatch("setReorderingQuiz", false);
    this.quizId = this.quiz.id;
    this.checkQuizStatus(true);
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loadingQuiz() {
      return this.$store.state.quiz.loadingQuiz;
    },
    savingQuiz() {
      return this.$store.state.quiz.savingQuiz;
    },
    quiz() {
      return this.$store.getters.quiz;
    },
    timing() {
      return this.$store.getters.quiz.timing;
    },
    quizHasUpdates() {
      return Object.keys(this.quizUpdates).length;
    },
    quizUpdates() {
      return this.$store.state.quiz.quizUpdates;
    },
    quizChanged() {
      return this.$store.state.quiz.quizChanged;
    },
  },

  methods: {
    async handleSave() {
      await this.$store.dispatch("updateQuiz", {
        ...this.quiz,
        ...this.quizUpdates,
      });
      this.checkQuizStatus("save");
      this.$store.dispatch("setQuizChanged", false);
    },
    handlePreview() {
      this.previewDialog = true;
    },
    handleClosePreview() {
      this.previewDialog = false;
    },
    formatDate(date) {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },
    checkTime() {
      // console.log("check time");
      const now = new Date();
      const seconds = now.getSeconds();

      if (seconds === 10) {
        this.checkQuizStatus();
      }

      this.currentTime = now.toLocaleTimeString();
    },
    async checkQuizStatus(param) {
      // console.log("check status");
      // param is a boolean. True forces recheck of the quiz status immediately,
      // whilst false or null waits until the start of the next minute.
      // This prevents the api being called every second or at all when quiz has expired
      if (param || this.isQuizOpen) {
        try {
          const response = await axios.get(
            `https://worldtimeapi.org/api/timezone/${this.timing.timezone}`
          );
          const { datetime } = response.data;

          const currentTime = new Date(datetime);

          const tempOpenDate = moment(datetime)
            .subtract(1, "d")
            .format("YYYY-MM-DD");

          const tempCloseDate = moment(datetime)
            .add(1, "d")
            .format("YYYY-MM-DD");

          if (this.timing.openDate === "" && this.timing.closeDate === "") {
            this.isQuizOpen = true;
            return;
          }

          let openTime = "00:00:00";
          let closeTime = "24:00:00";

          if (this.timing.openDate === "") {
            this.timing.openDate = tempOpenDate;
          }
          if (this.timing.closeDate === "") {
            this.timing.closeDate = tempCloseDate;
          }
          if (this.timing.openTime !== "") {
            openTime = this.timing.openTime;
          }
          if (this.timing.closeTime !== "") {
            closeTime = this.timing.closeTime;
          }

          const openDatetime = new Date(`${this.timing.openDate}T${openTime}`);
          const closeDatetime = new Date(
            `${this.timing.closeDate}T${closeTime}`
          );
          const timeDiff = closeDatetime - currentTime;
          // console.log(timeDiff);

          if (timeDiff > 0 && currentTime >= openDatetime) {
            this.isQuizOpen = true;
          } else {
            this.isQuizOpen = false;
          }
        } catch (error) {
          console.error("Error fetching and evaluating quiz status:", error);
          this.dispatch(
            "setSnackbar",
            "There was an error checking the closing time of this quiz. Please contact support"
          );
          this.isQuizOpen = false;
        }
      }
      return;
    },
  },

  watch: {},
};
</script>

<style scoped>
.sticky-footer {
  position: fixed;
  background: transparent;
  bottom: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  z-index: 1 !important;
  padding: 10px;
  width: 100%;
}
.margin-bottom {
  margin-bottom: 100px;
}
</style>

<template>
  <v-dialog v-model="newOrganisationDialog" max-width="500px" persistent>
    <v-card>
      <v-img
        :src="require('@/assets/background.png')"
        :height="systemTheme.cardImageHeight"
      >
      <v-overlay
      absolute
      :color="primary"
      :opacity="systemTheme.cardOpacity"
      >
      </v-overlay>
      </v-img>
      <v-row justify="center" no-gutters class="mt-n12">
        <v-avatar
          size="100"
          color="white"
          outline
        >
        <v-icon size="99" :color="primary">add_circle</v-icon>
        </v-avatar>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-card-title class="page-heading">
          Add Organisation
        </v-card-title>
      </v-row>

      <v-card-text class="px-16 py-10">
        
        <v-text-field
          v-model="title"
          label="Organisation Name"
          v-validate="'required'"
          :error-messages="errors.collect('title')"
          data-vv-name="title"
          type="text"
          required
        ></v-text-field>

        <v-row class="incentable-form-heading" no-gutters>  
          Primary Color            
        </v-row>
        <v-row class="incentable-form-subheading" no-gutters>
          Set your company primary color
        </v-row>
        <v-row justify="center">
          <v-color-picker
            mode="hexa"
            v-model="primary"
          ></v-color-picker>
        </v-row>
        <v-row class="incentable-form-heading" no-gutters>  
          Button Text Color            
        </v-row>
        <v-row class="incentable-form-subheading" no-gutters>
          Choose the button text color to best suit your primary color
        </v-row>
        
        <v-row no-gutters>
          <v-col lg="4">
            <v-radio-group 
              v-model="buttonTextColor">
              <v-radio label="White" value="white--text"></v-radio>
              <v-radio label="Black" value="black--text"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col lg="8 mt-6">
            <v-btn 
              :color="primary"
              :class="buttonTextColor"
              >Button Color Sample
            </v-btn>
          </v-col>
        </v-row>
              
        <v-row class="incentable-form-heading" no-gutters>  
          Sub Domian            
        </v-row>
        <v-row class="incentable-form-subheading" no-gutters>
          Create a custom sub domain for your organisation dashboard
        </v-row>
        <v-card width="100%" class="px-6 pt-4 mt-4" flat color="grey lighten-4">
          <v-card-text>
            <v-text-field
              v-model="orgSubdomain"
              label="Subdomain"
              v-validate="'required|alpha_dash|min:3|max:30'"
              :error-messages="errors.collect('orgSubdomain')"
              data-vv-name="orgSubdomain"
              type="text"
              suffix=".incentive.studio"
              @focus="resetSubcheck()"
            />

            <v-row no-gutters>
              <v-icon v-if="subCheck === null" class="icon" color="amber darken-4">help</v-icon>
              <v-icon v-if="subCheck === false" class="icon" color="red">cancel</v-icon>
              <v-icon v-if="subCheck === true" class="icon" color="green">check_circle</v-icon>
                <span class="example ml-2">
                  https://{{ formatSubdomain(orgSubdomain) }}.incentive.studio
                </span>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              text
              color="primary"
              :disabled="!orgSubdomain"
              @click="subdomainCheck(orgSubdomain)"
            >Check Availability</v-btn>
          </v-card-actions>

        </v-card>

        <v-row>
          <v-alert type="warning" v-model="titleError">
            That Organisation name is already registered
          </v-alert>
          <v-alert type="warning" v-model="orgSubdomainError">
            That Subdomain is unavailable
          </v-alert>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="close"
          >Cancel</v-btn>
        <v-btn
          :class="buttonTextColor"
          :color="primary"
          @click="newOrganisation"
          :disabled="errors.any() || !subCheck === true"
          :loading="loading"
          >Save</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    formTitle: 'Add Organisation',
    valid: false,
    id: '',
    subCheck: null,
    title: '',
    primary: '#E91E63',
    buttonTextColor: 'white--text',
    orgSubdomain: '',
    reservedSubdomains: ['my', 'our', 'the', 'new', 'demo', 'app', 'admin', 'myadmin', 'incentable', 'reward', 'loyalty', 'website', 'host'
    , 'rewards', 'leaderboard', 'leaderboards', 'results', 'marketing', 'travel', 'mytravel', 'studio', 'mydemo', 'dashboard', 'mydashboard'
    , 'example', 'sample', 'test', 'demonstration', 'domain'],
    isProcessing: false,
    dictionary: {
      custom: {
        title: {
          required: () => 'Organisation name is required'
        },
        orgSubdomain: {
          required: () => 'Subdomain is required',
          alpha_dash: () => 'Subdomain must be between 3 and 30 characters long, and can only contain alphanumeric characters with no spaces',
          min: () => 'Subdomain cannot be less than 3 characters long',
          max: () => 'Subdomain must be between 3 and 30 characters long'
        },
      }
    }
  }),
  created () {
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    subdomain () {
      return this.$store.getters.subdomain
    },
    userEmail () {
      return this.$store.getters.email
    },
    organisations () {
      return this.$store.state.organisation.organisations
    },
    titleError () {
      return this.$store.getters.orgTitleExists
    },
    orgSubdomainError () {
      return this.$store.getters.orgSubdomainExists
    },
    newOrganisationDialog () {
      return this.$store.getters.newOrganisationDialog
    },
    isIncentableAdmin () {
      return this.$store.getters.isIncentableAdmin
    },
    orgTheme () {
      return this.$store.getters.orgTheme;
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
  },
  methods: {
    formatSubdomain(subdomain) {
      if (!subdomain) {
        return 'example'
      } else
        return subdomain.toLowerCase().trim()
    },
    subdomainCheck(sub) {
      const orgData = this.$store.getters.organisations
      const reservedData = this.reservedSubdomains
      const filtered = orgData
        .filter(el => el.orgSubdomain.toLowerCase() === sub.toLowerCase())
        .length
      const reserved = reservedData
        .filter(el => el.toLowerCase() === sub.toLowerCase())
        .length
      const count = filtered + reserved
      if (count > 0) {
        this.subCheck = false
        } else {
        this.subCheck = true
      }
    },
    resetSubcheck () {
      this.subCheck = null
    },
    newOrganisation () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          // eslint-disable-next-line
          console.log('Form not valid')
        }
        if (result) {
          const organisationData = {
            title: this.title,
            titleUppercase: this.title.toUpperCase(),
            orgSubdomain: this.orgSubdomain.toLowerCase(),
            primary: this.primary,
            buttonTextColor: this.buttonTextColor,
            createdAt: new Date(),
            admins: [{ 
              adminEmail: this.userEmail, 
              adminRole: 'Owner'
            }],
          }
          this.$store.dispatch('createOrganisation', organisationData)
          this.close()
        }
      })
    },
       
    close () {
      this.title = ''
      this.orgSubdomain = ''
      this.primary = '#E91E63',
      this.buttonTextColor = 'white--text',
      this.subCheck = null,
      this.createdAt = ''
      this.createdBy = ''
      this.errorMessage = ''
      this.admins = []
      this.$validator.reset()
      this.$store.dispatch('setOrgTitleExists', false)
      this.$store.dispatch('setOrgSubdomainExists', false)
      this.$store.dispatch('setNewOrganisationDialog', false)
    },
  }
}
</script>

<style>
.icon {
  margin-top: -2px !important;
}
</style>
<template>
  <div class="mt-8 mr-8 mb-8">
    <form data-vv-scope="program">
      <v-row>
        <v-col lg="3">
          <v-text-field
            name="title"
            label="Program Name"
            v-model="title"
            v-validate="'required'"
            :error-messages="errors.collect('program.title')"
            hint="The program name in your admin view"
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="3">
          <v-text-field
            name="displayTitle"
            label="Display Title"
            v-model="displayTitle"
            v-validate="'required'"
            :error-messages="errors.collect('program.displayTitle')"
            hint="The program name on the member website sign in and sign up pages"
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="3">
          Looking for the member website main banner title? This can be added or edited in the Design menu
        </v-col>
      </v-row>
      <v-row>
        <v-btn
          class="ml-3 mt-3"
          @click="save('program')"
          color="primary"
          elevation="0"
          :loading="loading"
          >Save
        </v-btn>
      </v-row>
    </form>
  </div>
</template>

<script>
export default {
  created () {
    const { currentProgram } = this.$store.state.program;

    this.demo = currentProgram.demo;
    this.title = currentProgram.title;
    this.displayTitle = currentProgram.displayTitle
    this.titleUppercase = currentProgram.titleUppercase;
    this.contactPage = currentProgram.contactPage || {};
    this.termsPage = currentProgram.termsPage || {};
    this.homeBlogs = currentProgram.homeBlogs || {};

    this.$store.dispatch('loadBlogs')
  },
  mounted () {
    this.$validator.localize('en', this.dictionary)
  },
  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => 'Title is required'
        },
        displayTitle: {
          required: () => 'Display Title is required'
        }
      }
    },
    title: '',
    displayTitle: '',
    demo: false,
    webTheme: {},
    titleUppercase: '',
    contactPage: {},
    termsPage: {},
    homeBlogs: {},
    isProcessing: false,
  }),
  computed: {
    loading () {
      return this.$store.getters.loading
    },
    programId () {
      return this.$store.getters.programId
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    isIncentableAdmin () {
      return this.$store.getters.isIncentableAdmin
    },
    blogs () {
      return this.$store.state.blog.blogs
    },
  },
  watch: {
  },
  methods: {
    save(scope) {
      this.$validator.validateAll(scope).then(result => {
        if (!result) {
          return;
        }
        
        const payload = {
          demo: this.demo,
          homeBlogs: {
            previews: this.homeBlogs.previews || '',
            blogs: this.homeBlogs.blogs || [],
          },
          contactPage: {
            email: this.contactPage.email || '',
            name: this.contactPage.name || '',
            phone: this.contactPage.phone || '',
            heading: this.contactPage.heading || '',
            body: this.contactPage.body || '',
          },
          termsPage: {
            body: this.termsPage.body || '',
          },
          title: this.title || '',
          displayTitle: this.displayTitle || '',
          titleUppercase: this.title.toUpperCase() || '',
        };

        this.$store.dispatch('setLoading', true)
        this.$store.dispatch('updateCurrentProgram', payload)
          .then(() => {
            this.$store.dispatch('setLoading', false)
          })
          .catch(() => {
            this.$store.dispatch('setLoading', false)
          })
      })
    }
  }
}
</script>

<style scoped>
.no-padding{
  padding: 0px !important;
  padding-top: 24px !important;
}
</style>
